<button *ngIf="!params.Jobworkorder && !params.UnreleaseReleaseButton && !params.stockJournal2" #myButton class="common_button d-flex align-items-center justify-content-center invoiceButtonRenderer"
    [class]="buttonClass"
    [type]="buttonType"
    [ngClass]="ngClass" 
    (click)="navitageToReceive(params)"
    container="body"
    [ngbTooltip]="sendDataService?.noPermissionTooltip"
    [disableTooltip]="!(params?.hasAddPermission === false)"
    [ngClass]="{'cursor-not-allowed opacity-50': (params?.hasAddPermission === false || params?.data?.jobWorkInvoiceVoucherNo)}"
    [id]="buttonId"
    [disabled]="(params?.hasAddPermission === false || params?.data?.jobWorkInvoiceVoucherNo)">
    <svg xmlns="http://www.w3.org/2000/svg" width="13.899" height="13.897" viewBox="0 0 13.899 13.897">
        <g id="Group_41442" data-name="Group 41442" transform="translate(-1970.959 5382.91)">
          <path id="Path_3271" data-name="Path 3271" d="M1984.855-5191.141c0-.045,0-.09-.008-.139a.648.648,0,0,0-.069-.007q-.969,0-1.939,0a.522.522,0,0,0-.179.04c-.561.209-1.12.421-1.68.631-.521.2-1.042.39-1.534.573a1.484,1.484,0,0,0-.172-.127c-.372-.189-.745-.377-1.121-.559a.636.636,0,0,0-.264-.062c-1.256,0-2.512,0-3.769,0h-.19v-.478c-.021-.009-.03-.016-.039-.016-.693,0-1.385,0-2.078,0a.234.234,0,0,0-.143.072c-.215.208-.429.419-.635.637a.3.3,0,0,0-.075.187q-.006,2.079,0,4.157a.323.323,0,0,0,.084.2c.195.207.395.41.6.6a.369.369,0,0,0,.225.094c.635.008,1.271,0,1.907,0h.159v-4.467h.178c1.05,0,2.1.01,3.148-.006a1.761,1.761,0,0,1,.973.227.268.268,0,0,1,.169.269c0,.14-.03.234-.172.281a.905.905,0,0,0-.208.1.894.894,0,0,1-.515.125c-.636-.007-1.271,0-1.907,0h-.171v.992h1.527a4.751,4.751,0,0,0,1.066-.022,7.116,7.116,0,0,0,.962-.45c.3-.122.485-.283.406-.632,0-.009,0-.02.008-.036.049-.021.1-.044.153-.064.96-.359,1.929-.7,2.878-1.087a3.155,3.155,0,0,1,1.428-.181c0,.2,0,.391,0,.582a.181.181,0,0,1-.105.183q-2.194,1.312-4.386,2.625a.558.558,0,0,1-.274.073q-2.241.007-4.482,0h-.177v.985c.033,0,.057,0,.082,0q2.466,0,4.932,0a.389.389,0,0,0,.185-.06q2.551-1.527,5.1-3.057a.207.207,0,0,0,.115-.208C1984.853-5189.818,1984.855-5190.479,1984.855-5191.141Zm-11.922,4.817c-.242,0-.474,0-.706,0a.365.365,0,0,1-.276-.287q0-1.7,0-3.4a.369.369,0,0,1,.275-.288c.232,0,.464,0,.707,0Z" transform="translate(0 -183.681)" fill="#fff"/>
          <path id="Path_3272" data-name="Path 3272" d="M2054.736-5379.437c0,.854,0,1.706,0,2.559a.347.347,0,0,0,.085.214c.2.212.4.415.613.614a.319.319,0,0,0,.2.084q2.574.006,5.148,0a.3.3,0,0,0,.188-.072c.218-.206.43-.419.636-.636a.305.305,0,0,0,.073-.188q.006-2.574,0-5.148a.3.3,0,0,0-.073-.187q-.309-.327-.635-.636a.3.3,0,0,0-.188-.072q-2.574-.007-5.148,0a.324.324,0,0,0-.2.084c-.2.19-.395.395-.6.588a.316.316,0,0,0-.108.256C2054.738-5381.131,2054.736-5380.284,2054.736-5379.437Zm.991-2.126a.335.335,0,0,1,.348-.355h1.635v1.478h.994v-1.478h1.578c.291,0,.409.116.409.4v4.2a.334.334,0,0,1-.358.36h-4.248a.336.336,0,0,1-.359-.36Q2055.727-5379.438,2055.728-5381.562Z" transform="translate(-80.303 0)" fill="#fff"/>
        </g>
    </svg>
    <!-- [class.opacity-50]="params?.data?.jobWorkInvoiceVoucherNo" -->

    <span>{{ params?.valueFormatted ||'Invoice' }}</span>
</button>

<button *ngIf="params.Jobworkorder" #myButton class="common_button f-12 d-flex align-items-center justify-content-center invoiceButtonRenderer ml-rem"
    [class]="buttonClass"
    [type]="buttonType"
    [ngClass]="ngClass" 
    (click)="navitageToJobWorkOrder(params)"
    [id]="buttonId"
    [class.opacity-50]="params?.data?.statusId === jobWorkStatus.Processing || params?.data.jobWorkOrderVoucherNo?true:false"
    [disabled]="params?.data?.statusId === jobWorkStatus.Processing || params?.data.jobWorkOrderVoucherNo?true:false"
   >
    <span>{{ params?.valueFormatted ||'JobWork Order' }}</span>
</button>

<button *ngIf="params.UnreleaseReleaseButton" #myButton class="common_button d-flex align-items-center justify-content-center invoiceButtonRenderer"
[class]="buttonClass"
[type]="buttonType"
[ngClass]="ngClass" 
(click)="openPopup(params)"
[id]="buttonId">
<svg xmlns="http://www.w3.org/2000/svg" width="13.902" height="15.451" viewBox="0 0 13.902 15.451">
    <path id="Union_264" data-name="Union 264" d="M17675.99,4450.317c-.59,0-1.186,0-1.775,0a.351.351,0,0,1-.209-.089c-.191-.178-.377-.369-.555-.559a.284.284,0,0,1-.08-.187q-.006-1.924,0-3.857a.29.29,0,0,1,.07-.173c.191-.2.391-.4.592-.59a.21.21,0,0,1,.133-.067c.643,0,1.287,0,1.926,0a.079.079,0,0,1,.035.014v.444h.178q1.752,0,3.5,0a.558.558,0,0,1,.244.053c.35.168.7.346,1.043.519a1.882,1.882,0,0,1,.16.12c.457-.173.941-.351,1.424-.533l1.559-.586a.445.445,0,0,1,.168-.036c.6,0,1.2,0,1.8,0a.57.57,0,0,1,.066.009c0,.044,0,.084,0,.129,0,.612,0,1.229,0,1.842a.2.2,0,0,1-.105.195c-1.58.941-3.16,1.891-4.736,2.836a.355.355,0,0,1-.174.053c-1.525,0-3.049,0-4.58,0-.021,0-.045,0-.076,0v-.918h.164c1.391,0,2.779,0,4.164,0a.559.559,0,0,0,.254-.067q2.036-1.218,4.07-2.437a.172.172,0,0,0,.1-.173c-.006-.177,0-.351,0-.537a2.986,2.986,0,0,0-1.328.165c-.883.359-1.779.679-2.672,1.012-.049.018-.1.04-.143.058a.144.144,0,0,0,0,.036c.07.324-.1.471-.377.586a6.5,6.5,0,0,1-.893.417,2.443,2.443,0,0,1-.674.027c-.107,0-.213-.009-.316,0h-1.42v-.923h.16c.59,0,1.18,0,1.771,0a.854.854,0,0,0,.479-.115.772.772,0,0,1,.191-.093c.133-.044.16-.133.16-.262a.242.242,0,0,0-.156-.249,1.644,1.644,0,0,0-.906-.213c-.971.013-1.943,0-2.92,0h-.164v4.15Zm-1.447-4.607a.354.354,0,0,0-.258.266v3.152a.347.347,0,0,0,.258.266c.217,0,.432,0,.656,0v-3.684l-.441,0Zm4.426-2.344v-5.051l-1.758,1.758-.848-.852,3.146-3.147,3.146,3.147-.852.852-1.637-1.638v4.931Z" transform="translate(-17672.867 -4435.366)" fill="#fff" stroke="rgba(0,0,0,0)" stroke-miterlimit="10" stroke-width="1"/>
  </svg>  
              

    <span>{{ params?.valueFormatted  }}</span>
</button>

<button *ngIf="params.stockJournal2" #myButton class="common_button w-100 px-2 d-flex align-items-center justify-content-center invoiceButtonRenderer"
  [class]="buttonClass"
  [type]="buttonType"
  [ngClass]="ngClass"
  (click)="onClickStockjournal(params)"
  [id]="buttonId">            
    <span>{{ params?.data.id ? 'Update Stock Journal' : 'Create Stock Journal' }}</span>
</button>

