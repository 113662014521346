import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';


@Component({
  selector: 'app-custom-expandable',
  templateUrl: './custom-expandable.component.html',
  styleUrls: ['./custom-expandable.component.scss']
})
export class CustomExpandableComponent implements OnInit, ICellRendererAngularComp{

  
  registerRowDragger: (rowDraggerElement: HTMLElement, dragStartPixels?: number, value?: string, suppressVisibilityChange?: boolean) => void;
  show: boolean = false;
  showSics: boolean = false;
  message: any;
  params: any;
  currentTab = ''
  listLength
  
receiptDetails:any[] =[];
manufacturingProcessDetails:any[] =[];
LoanDetails: any[]=[];
  agInit(params: any): void 
  {
    this.params = params;
    this.receiptDetails = 
    this.params?.data?.receiptDetailsModels ?? this.params?.data?.paymentDetailsModel  ?? this.params?.data?.materialInDetailsModels ?? this.params?.data?.materialOutDetailsModel ?? null;
    this.manufacturingProcessDetails = this.params?.data?.expandedRows;
    this.LoanDetails = this.params?.data?.loansItemModel;
    // loanGridHere
  }
  refresh(params: ICellRendererParams<any, any>): boolean {
    return true;
  }

  ngOnInit(): void
  {
  }

  getResponse($event) 
  {
    this.message = $event;
  }

  clickIcon() 
  {
    this.show = !this.show;
  }
  clickSicsIcon()
  {
    this.showSics = !this.showSics;
  }
}
