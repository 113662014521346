import { Injectable } from "@angular/core";
import { NgbDate } from "@ng-bootstrap/ng-bootstrap";
import { defaultPageLimit, Filters, PaginationModel } from "../common-components/ag-grid/ag-grid.model";
import { AgGridService } from "../common-components/ag-grid/ag-grid.service";
import { StorageService } from "../services/storage.service";
import { DataService } from "../services/data.service";

@Injectable({
  providedIn: 'root'
})
export class CommonFilterService {

  constructor(public service: DataService,
    public storageService: StorageService,
    public ggGridService: AgGridService) {
  }

  performFilterColumnwise(valueColumnName, filterData, textInput, filterStorageName, cellDisplayType): any {
    // for decimal conversion
    let previousFilterList = this.storageService.retrieve(filterStorageName);
    // for decimal conversion
    var filterList: any = [];
    let index = previousFilterList?.findIndex(a => a.name == valueColumnName);
    if (previousFilterList != undefined)
      filterList = previousFilterList;
    if (cellDisplayType == "dateFilter")
      textInput = this.formatNgbDateToString(textInput);
    if (index != undefined && index != -1) {
      filterList[index].type = cellDisplayType == undefined ? "textFilter" : cellDisplayType;
      filterList[index].textFilter = textInput;
      filterList[index].FilterData = filterData?.length == 0 ? undefined : filterData;
    }
    else {
      if (filterData?.length == 0)
        filterData = undefined;
      filterList.push({ type: cellDisplayType == undefined ? "textFilter" : cellDisplayType, name: valueColumnName, textFilter: textInput, FilterData: filterData });

    }
    this.storageService.store(filterStorageName, filterList);
  }

  public processFilterData(filterStorageName): any {
    var filterList = this.storageService.retrieve(filterStorageName);
    let filters = new Filters();

    if (filterList != undefined && filterList != null)
      filterList.forEach(element => {
        if (element.textFilter != null)
          filters = this.ggGridService.modifyFilter(filters, element.textFilter.toString(), element.name, "cn", false);
        if (element.FilterData != null) {
          const selectedIds = element.FilterData;
          var filterIds = selectedIds.join(',');
          filters = this.ggGridService.modifyFilter(filters, filterIds, element.name, "eq", false);
        }
      });
    return filters;
  }

  public getPreviousAppliedFilter(previousAppliedFilters, columnName, dropdownData, cellType) {
    var appliedFilter = previousAppliedFilters.filter(a => a.name == columnName)[0];
    if (appliedFilter != undefined) {
      switch (cellType) {
        case "multiselectDropdown":
          const ids = appliedFilter.FilterData;
          return dropdownData?.filter(a =>
            ids?.some(b => a?.id == b)
          );
        case "input":
        case "dropdown":
          return appliedFilter.textFilter;
        // case "datePicker":
        //   return this.parseDateStringToNgbDate(appliedFilter.textFilter);
      }
    }
  }

  processServersideApiResponse(res, grid, gridApi, pagination, getAll: boolean) {
    if (res.isSuccess) {
      setTimeout(() => {
        this.hideLoading(gridApi);
        var response = res?.response?.data;
        const allRows = response.filter((x) => x.isSumRow != true);
        const firstTabEntry = response.filter((x) => x.isSumRow == true);
        gridApi?.api.paginationSetPageSize(pagination == undefined || pagination.pageSize == undefined ? defaultPageLimit : pagination.pageSize);
        gridApi?.api?.setRowData(allRows);
        if (getAll == true)
          grid.updateServersidePaginationgetallData(pagination.page, allRows.length);
        else
          grid.updateServersidePaginationData(firstTabEntry == undefined || firstTabEntry == null ? 0 : firstTabEntry[0].total_rows, firstTabEntry == undefined || firstTabEntry == null ? 0 : firstTabEntry[0].total_pages, pagination == undefined ? 1 : pagination.page, pagination.pageSize == undefined ? defaultPageLimit : pagination.pageSize);
      }, 100);
    } else {
      this.hideLoading(gridApi);
      grid?.updateServersidePaginationgetallData(1, 0);
      gridApi?.api?.setRowData([]);
    }
  }

  storeChangedPagination(paginationModel: PaginationModel, pagination, paginationStorageName, apiRequestStorageName): any {
    var apiRequestModel = this.storageService.retrieve(apiRequestStorageName);
    pagination.page = paginationModel.page;
    pagination.reloadGrid = paginationModel.reloadGrid;
    pagination.reloadGridFilters = false;
    pagination.pageSize = paginationModel.pageSize;
    if (pagination.pageSize == 1) {
      apiRequestModel.getAll = true;
      apiRequestModel.pageSize = 0;
    } else {
      apiRequestModel.pageSize = pagination?.pageSize;
      apiRequestModel.getAll = false;
    }
    apiRequestModel.page = pagination.page;
    this.storageService.store(apiRequestStorageName, apiRequestModel);
    this.storageService.store(paginationStorageName, apiRequestModel);
    return pagination;
  }

  showLoading(params) {
    setTimeout(() => {
      params?.api.showLoadingOverlay();
    }, 0);
  }
  hideLoading(params) {
    params?.api?.hideOverlay();
  }


  formatNgbDateToString(ngbDate: any): string {
    if (!ngbDate.year)
      return ngbDate;

    const year = ngbDate.year;
    const month = ngbDate.month < 10 ? '0' + ngbDate.month : ngbDate.month; // Add leading zero if needed
    const day = ngbDate.day < 10 ? '0' + ngbDate.day : ngbDate.day;         // Add leading zero if needed

    return `${year}-${month}-${day}`;
  }

  parseDateStringToNgbDate(dateString: string): NgbDate {
    const dateParts = dateString.split('-');
    return new NgbDate(+dateParts[0], +dateParts[1], +dateParts[2]);
  }
}
