import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { Icons } from '../edit-delete-icon/edit-delete-icon.component';
import { StorageService } from 'src/app/shared/services/storage.service';

@Component({
  selector: 'app-scan-print-icon',
  templateUrl: './scan-print-icon.component.html',
  styleUrls: ['./scan-print-icon.component.scss']
})
export class ScanPrintIconComponent implements ICellRendererAngularComp {
  public params: ICellRendererParams & Icons;

  constructor(   
    public storageService : StorageService,
    ){

  }

  agInit(params: ICellRendererParams & Icons): void {
    this.params = params;
  }

  refresh(): boolean {
    return false;
  }


  handlePrintClick(params): void {
    const customEvent = new CustomEvent('printButtonClick', {
      detail: { data: params },
    });
    this.params.api.dispatchEvent(customEvent);
  }
}
