import { Injectable } from '@angular/core';
import {DataService} from '../../services/data.service';
import { tap } from 'rxjs';
import {ResponseModel} from '../../models/response-model.model'
import { GetAllJewelryCatalogueRequestModel } from './jewelry-catalogue-common.model';
@Injectable({
  providedIn: 'root'
})
export class JewelryCatalogueCommonService {

  constructor(public service:DataService) { }

  
  getAlljewelryCatalogue(data ,anonymous?:any){
    let url = 'api/app/jewelrycatalogue/getalljewelrycatalogue';
    if (anonymous) {
      url = anonymous;
    }
    console.log('jewelery catalougue all',JSON.stringify(data))
    return this.service.post(url,data,true).pipe<ResponseModel>(tap((response: any) => {
    return response;
      })
    );
  };
}
