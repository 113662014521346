import { Component, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GridOptions } from 'ag-grid-community';
import { ToastrService } from 'ngx-toastr';
import { AgGridI } from 'src/app/shared/common-components/ag-grid/ag-grid.model';
import { CommonService } from 'src/app/shared/services/common.service';
import { DateService } from 'src/app/shared/services/date.service';

@Component({
  selector: 'app-manufacturing-out',
  templateUrl: './manufacturing-out.component.html',
  styleUrls: ['./manufacturing-out.component.scss']
})
export class ManufacturingOutComponent  extends CommonService implements OnInit {
  @ViewChild("ManufacturingOutForm") ManufacturingOutForm!: NgForm;
  toogleExchangeIcon                          : boolean=false
  @Input() modalTitle             : string;
  agGridDataModelProductList                  : AgGridI;
  gridApiManufacturingProcessOutList:any;
  getAllProductListRowData: any[] = [
    {metalDetailId:'Gold' , productDetailId:'Gold Necklace' , quantity:'10' ,wt:'20.000'},
    {metalDetailId:'Gold' , productDetailId:'Gold Necklace' , quantity:'10' ,wt:'20.000'},
    {metalDetailId:'Gold' , productDetailId:'Gold Necklace' , quantity:'10' ,wt:'20.000'},
    {metalDetailId:'Gold' , productDetailId:'Gold Necklace' , quantity:'10' ,wt:'20.000'},
    {metalDetailId:'Gold' , productDetailId:'Gold Necklace' , quantity:'10' ,wt:'20.000'},
    {metalDetailId:'Gold' , productDetailId:'Gold Necklace' , quantity:'10' ,wt:'20.000'},
    {metalDetailId:'Gold' , productDetailId:'Gold Necklace' , quantity:'10' ,wt:'20.000'},
    {metalDetailId:'Gold' , productDetailId:'Gold Necklace' , quantity:'10' ,wt:'20.000'},
    {metalDetailId:'Gold' , productDetailId:'Gold Necklace' , quantity:'10' ,wt:'20.000'},
    {metalDetailId:'Gold' , productDetailId:'Gold Necklace' , quantity:'10' ,wt:'20.000'},
    {metalDetailId:'Gold' , productDetailId:'Gold Necklace' , quantity:'10' ,wt:'20.000'},
    {metalDetailId:'Gold' , productDetailId:'Gold Necklace' , quantity:'10' ,wt:'20.000'},
    {metalDetailId:'Gold' , productDetailId:'Gold Necklace' , quantity:'10' ,wt:'20.000'},
  ];
  constructor(
    public activeModal    : NgbActiveModal,
    public renderer       : Renderer2,
    private toster        : ToastrService,
    private dateService           : DateService

  ) {
    super(renderer);
  }
  ngOnInit(): void {
    this.dateReceived();

    // this.agGridDataModelProductList = AgGridDataModelProductList;
  }
  dateReceived(){
    this.dateService.getSelectedDate().subscribe(date => {
     // this.invoiceDate = date;
    });
  }
  
  gridOptionsProductList: GridOptions = {
    singleClickEdit: true,
    getRowStyle: this.getRowStyle,
  };

  getRowStyle(params: any) {
    return {
      background: params.node.rowIndex % 2 === 0 ? '#EEF1F7' : '#FFFFFF',
    };
  }
  onGridReady(event){
    this.gridApiManufacturingProcessOutList  = event; 
    // this.getAllProductListRowData();
  }
  selectedRangeDates(event?: any) {
    // this.insertTransactionModel.issueDate = event;
  }

  exchangeData(){
  }


  close(){
    this.activeModal.close();
    // this.activeModal.close({response:this.data,isSuccess:false})
  }
  save(){
    this.activeModal.close();
    // this.activeModal.close({response:this.data,isSuccess:true})
  }
}
