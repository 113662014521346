<div class="row ">
  <div class="col-12">
    <app-icon-with-input [inputName]="'customerAddress1'"
      [inputType]="'text'" [inputId]="'customerAddress1'"
      (keydown.enter)="onKeydown($event, 'customerAddress2')"
      [inputValue]="customerDetails?.billingAddressModel?.addressLine1"
      (inputValueEmit)="customerDetails.billingAddressModel.addressLine1 = $event"
      [inputPlaceholder]="'Address 1'" [InputLabel]="'Address 1'" labelName="true" [className]="'w-90px'"></app-icon-with-input>
  </div>
</div>

<div class="row mt-2 ">
  <div class="col-12">
    <app-icon-with-input [inputName]="'customerAddress2'" 
      [inputType]="'text'" [inputId]="'customerAddress2'" 
      (keydown.enter)="onKeydown($event, 'billingAddressCountry')"
      [inputValue]="customerDetails?.billingAddressModel?.addressLine2"
      (inputValueEmit)="customerDetails.billingAddressModel.addressLine2 = $event" [inputPlaceholder]="'Address 2'"
      [InputLabel]="'Address 2'" labelName="true" [className]="'w-90px'"></app-icon-with-input>
  </div>
</div>

<div class="row mt-2">
  <div class="col-12 d-flex">
    <div class="col-6 pe-1 country">
      <app-select-with-icon [options]="getAllDropdownModel?.countryDropdown"
      [selectedValue]="customerDetails?.billingAddressModel?.countryId" (valueChanged)="customerDetails.billingAddressModel.countryId = convertToNum($event); onChangeCountryName($event);" 
      [id]="'billingAddressCountry'" [name]="'billingAddressCountry'" [isDisabled]="false"
      (keydown.enter)="onKeydown($event, 'billingAddressState')"
      [selectPlaceholder]="'Maharashtra'" [SelectLabel]="'Country'"
      [isRequired]="true"
      labelName="true" [className]="'w-90px'">
      <!-- [isRequired]="isFromAccountLedger || LedgerId?false:true" -->
    </app-select-with-icon>
    </div>
    <div class="col-6 ps-1">
      <app-select-with-icon [options]="getAllDropdownModel?.billingAddressStateList"
      [selectedValue]="customerDetails?.billingAddressModel?.stateId" (valueChanged)="customerDetails.billingAddressModel.stateId = convertToNum($event);"
      [id]="'billingAddressState'" [name]="'billingAddressState'" [isDisabled]="false"
      (keydown.enter)="onKeydown($event, 'customerPinCode')"
      [isRequired]="true"
      [selectPlaceholder]="'Maharashtra'" [SelectLabel]="'State'"
      labelName="true" [className]="'w-90px'">
      <!-- [isRequired]="isFromAccountLedger || LedgerId?false:true"  -->
    </app-select-with-icon>
    </div>
  </div>
</div>

<div class="row mt-2">
  <div class="col-6">
    <app-icon-with-input [inputName]="'customerPinCode'" [inputMaxlength]="9" [isOnlyAlphabetsAndNumbers]="true"
      [inputType]="'text'" [inputId]="'customerPinCode'"
      [inputValue]="customerDetails?.billingAddressModel?.pinCode"
      (keydown.enter)="onKeydown($event, '')" [isAllowDecimal]="false"
      (inputValueEmit)="customerDetails.billingAddressModel.pinCode = $event" [isRequired]="false"
      [inputPlaceholder]="'Zip Code'" [InputLabel]="'Zip Code'" labelName="true" [className]="'w-90px'"></app-icon-with-input>
  </div>
</div>

