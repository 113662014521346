import { Component, OnInit, Renderer2, ViewChild } from '@angular/core';
import { CommonService } from '../../services/common.service';
import { AdministrationService } from 'src/app/components/administration/administration.service';
import { ToastrService } from 'ngx-toastr';
import { NgForm } from '@angular/forms';
import { catchError } from 'rxjs';
import { GetAllShapeResponseModel } from 'src/app/components/administration/administration.model';
import { SendDataService } from '../../services/send-data.service';

@Component({
  selector: 'app-shape',
  templateUrl: './shape.component.html',
  styleUrls: ['./shape.component.scss']
})
export class ShapeComponent extends CommonService implements OnInit {
  isAdd                           : boolean = false;
  getAllShapeData              : any[]=[];

  copyGetAllShapeData     : any[]=[];
  editShapeData           = new GetAllShapeResponseModel();
  @ViewChild('administrationFormData') administrationFormData! : NgForm ;

constructor(
  public renderer:Renderer2,
  public administrationService : AdministrationService,
  public toaster: ToastrService,
  public sendDataService : SendDataService,
) 
{
  super(renderer)
}
ngOnInit(): void {
  this.getAllShapeList();
  }

add(){
  if(!this.sendDataService?.permissionVM?.mastersCanAdd) return;
  this.isAdd = true;
    const obj = {id:0, name:'',active:true}
    this.getAllShapeData?.push(obj); 
    this.copyGetAllShapeData?.push(obj);
    setTimeout(()=>{
      const element = document.getElementById(`Shape${obj.id}`);
      element?.focus();
    },0)
}


cancel(){
  if(this.isAdd)
  {
    this.isAdd = false;
    this.getAllShapeData = this.getAllShapeData.filter((x)=>x.id != 0)
    this.copyGetAllShapeData = this.copyGetAllShapeData.filter((x)=>x.id != 0);
  }
  else if(this.editShapeData?.id && this.editShapeData?.isEdit){
    const index = this.copyGetAllShapeData.findIndex((x)=>x.id == this.editShapeData?.id);
    const singleData =JSON.parse(JSON.stringify(this.copyGetAllShapeData))[index];
    this.getAllShapeData[index] = singleData;
    this.editShapeData = new GetAllShapeResponseModel();
  }  
}

edit(item,formData){
  if(!this.sendDataService?.permissionVM?.mastersCanUpdate) return;
  item.isEdit = true;
  const controlObj = formData?.form?.controls;
  if(controlObj){
    const id = Object.keys(controlObj)[0];
    const element = document.getElementById(id);
    element?.focus();
  }
  this.editShapeData = {...item};
}


checkIsEdit(arr:any[]):boolean{
  return arr?.some((x)=>x.isEdit)
 }
 

    // *********** Shape API START *********
    // **********get all Shape *********
    getAllShapeList() {
      this.administrationService.getAllShape().subscribe({
        next:(res)=>{
          if(res.isSuccess){
            this.editShapeData = new GetAllShapeResponseModel();
            this.getAllShapeData = res.response??[];
            this.copyGetAllShapeData = JSON.parse(JSON.stringify(this.getAllShapeData))??[];
          }else{           
            this.getAllShapeData = []; 
            this.copyGetAllShapeData = [];
          }  
        },
        error:(err)=>{
          this.getAllShapeData = []; 
          this.copyGetAllShapeData = [];
        }
      })
    }

    // *************insert Shape **************
       InserShapeList(item) {
        const {id , ...rest}= item;
        this.administrationService.insertShape(rest)
            .pipe(catchError((err) => this.handleError(err)))
            .subscribe((res) => {
              if(res.isSuccess){
                this.isAdd = false;
                this.getAllShapeList();
                this.toaster.success(res.message);
              }else{
                this.toaster.error(res.errors[0]);
              }  
          });
        }
    
    // **********update Shape *********
    UpdateShapeList(item) {
      const {isEdit , ...rest} = item;
      this.administrationService.updateShape(rest)
          .pipe(catchError((err) => this.handleError(err)))
          .subscribe((res) => {
            if(res.isSuccess){
              this.isAdd = false;
              this.getAllShapeList();
              this.toaster.success(res.message);
            }else{
              this.toaster.error(res.errors[0]);
            }  
        });
      } 

      // **********delete Shape *********
      DeleteShapeList(id) {
        if(!this.sendDataService?.permissionVM?.mastersCanDelete) return;
        this.administrationService.deleteShape(id)
            .pipe(catchError((err) => this.handleError(err)))
            .subscribe((res) => {
              if(res.isSuccess){
                this.getAllShapeList();
                this.toaster.success(res.message);
              }else{
                this.toaster.error(res.errors[0]);
              }  
        });
      }
}

