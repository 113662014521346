import { Component, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { OpenImagePopupComponent } from 'src/app/shared/common-components/common-voucher/popup/customer-details/popup/open-image-popup/open-image-popup.component';
import { commonModalOptions } from 'src/app/shared/models/common.model';
import { CommonService } from 'src/app/shared/services/common.service';
import { CompressImageService } from 'src/app/shared/services/compress-image.service';
import { IconHover } from '../../../enum/common-enum';

@Component({
  selector: 'app-add-image',
  templateUrl: './add-image.component.html',
  styleUrls: ['./add-image.component.scss']
})
export class AddImageComponent extends CommonService implements OnInit{
  @Input() displayImage;
  @Input() imagesList = [];
  @Input() alldata:any;
  selectedImage : any;
  currentHoverEffect = IconHover.Pink;
  constructor(
    public activeModal        : NgbActiveModal,
    public renderer           : Renderer2,
    public toaster            : ToastrService,
    public compressImageService :CompressImageService,
    private modalService: NgbModal,
    )
    {
      super(renderer)
    }
  ngOnInit(): void {
    this.alldata
    const updatedArray = this.imagesList.map(obj => {
      if(obj.imageUrl){
        return { base64Resource: obj.imageUrl};
      }else {
        return obj;
      }
    });

    this.imagesList  =  updatedArray;
    this.displayImage = this.imagesList[this.imagesList?.length - 1]?.base64Resource;
  }

  showPreviewImage(img){
    this.displayImage = img?.base64Resource;
  }
  

  removeImage(index) {
  this.imagesList?.splice(index,1);
   if(this.imagesList?.length){
    this.displayImage = this.imagesList[this.imagesList.length-1].base64Resource;
   }else{
    this.displayImage = null;
   }
  }

  saveImages(){
    this.activeModal.close(this.imagesList ?? []);
  }

  onSelect(event) {
    if(event.addedFiles.length>1){
      this.resourceMultipleFile(event.addedFiles)
      }else{
        this.resourceFile(event.addedFiles[0])
      }
  }
  
  resourceFile(item:any){
    this.processFile(item);
  }
  
  resourceMultipleFile(items:any){
    for(const file of items)
    this.processFile(file)
  }
  
  processFile(file: any) {
    console.log(file);
    this.compressImageService.compress(file).subscribe(
      compressedFile => {
     this.readThis(compressedFile).then((base64String) => {
        if (base64String) {
          console.log(compressedFile);
          const img = {
            base64Resource : base64String
          }
          this.imagesList.push(img);
        } else {
          console.error("Failed to get base64 string.");
        }
      })
      .catch((error) => {
        console.error("Error processing file:", error);
      });
    },
    error => {
      console.error('Error compressing file:', error);
    }
  );
  }

  openImgPopUp() {
    if(!this.imagesList.length) return;
    const modalOption: NgbModalOptions = {
      ...commonModalOptions,
      size: "sm",
      fullscreen:true,
      centered:true,
      modalDialogClass: 'image_popup_fullscreen'
    };
    const modalRef = this.modalService.open(OpenImagePopupComponent, modalOption);
      modalRef.componentInstance.jewelryCatalogue = [... this.imagesList];
  }
}
