<ng-container >
    <div class="modal-body p-1">
      <div  class="d-flex justify-content-end"><app-svg-icon tabindex="0" [name]="'cross'" class="cross-button custom_focus cursorPointer" (keydown.enter)="onKeyDown($event)" (click)="activeModal.dismiss()"></app-svg-icon></div>
      
        <div class="save_icon text-center d-flex justify-content-center align-items-center">
            <!-- <svg xmlns="http://www.w3.org/2000/svg" width="105.437" height="110.837" viewBox="0 0 105.437 110.837">
                <g id="Group_40875" data-name="Group 40875" transform="translate(-641.831 -231.219)">
                  <path id="Path_2958" data-name="Path 2958" d="M719.018,434.193a37.439,37.439,0,0,1-3.666-1.077c-2.945-1.139-5.855-2.373-8.76-3.613-1.558-.666-1.675-1.456-.381-2.588a51.92,51.92,0,0,0,10.565-12.484c1.561-2.616.27-5.538-2.6-5.841a3.878,3.878,0,0,0-3.883,2.2,41.946,41.946,0,0,1-9.761,11.2c-4.126,3.31-2.759,3.021-6.962.5-2.788-1.672-5.462-3.538-8.148-5.374-.431-.295-.977-.941-.906-1.32a2.046,2.046,0,0,1,1.18-1.248,26.08,26.08,0,0,0,7.776-4.6c1.809-1.533,2.133-3.605.833-5.266a3.709,3.709,0,0,0-5.427-.509c-3.666,3.235-8.119,4.411-12.742,5.186a2.287,2.287,0,0,1-1.674-.53q-4.522-4.1-8.932-8.318a6.019,6.019,0,0,1-1.748-6.571,6.612,6.612,0,0,1,5.337-4.687,50.924,50.924,0,0,0,25.836-12.877,1.889,1.889,0,0,1,2.519-.316q21.824,11.478,43.688,22.88a1.557,1.557,0,0,1,.9,2.275,151.3,151.3,0,0,1-14.7,28.675C725.538,432.667,723.006,434.141,719.018,434.193Z" transform="translate(-13.779 -92.138)" fill="#1492e6"/>
                  <path id="Path_2959" data-name="Path 2959" d="M815.892,231.219c10.016,0,16.181,9.412,12.184,17.612-4.237,8.69-8.77,17.242-13.41,25.724a4.076,4.076,0,0,0-.124,4.59,19.124,19.124,0,0,1,1.264,14.88c-.47,1.555-1.049,3.078-1.6,4.609-.568,1.578-1,1.74-2.5.954q-12.9-6.747-25.8-13.5-7.018-3.674-14.037-7.347c-1.7-.888-1.774-1.382-.493-2.852,2.346-2.695,4.628-5.444,7.735-7.347a22.442,22.442,0,0,1,11.259-3.448,2.027,2.027,0,0,0,2.053-1.283c4.34-8.491,8.732-16.955,13.107-25.428C807.9,233.8,811.65,231.4,815.892,231.219Z" transform="translate(-82.041)" fill="#0c71b4"/>
                  <path id="Path_2960" data-name="Path 2960" d="M693.14,323.25a5.384,5.384,0,1,1-10.767.022,5.457,5.457,0,0,1,5.453-5.426A5.4,5.4,0,0,1,693.14,323.25Z" transform="translate(-25.85 -55.234)" fill="#1492e6"/>
                  <path id="Path_2961" data-name="Path 2961" d="M647.2,374.51a5.387,5.387,0,1,1,5.426-5.406A5.4,5.4,0,0,1,647.2,374.51Z" transform="translate(0 -84.494)" fill="#1492e6"/>
                </g>
              </svg> -->
              <svg  *ngIf="!barcodeSvg" xmlns="http://www.w3.org/2000/svg" width="58.725" height="61.641" viewBox="0 0 58.725 61.641">
                <g id="Group_41769" data-name="Group 41769" transform="translate(869.997 823.201)">
                  <path id="Path_3319" data-name="Path 3319" d="M-820.53-606.235c-.844-.845-1.843-1.5-2.75-2.273-.439-.373-.9-.716-1.362-1.065-.247-.188-.222-.448-.222-.708q0-3.509,0-7.017a.856.856,0,0,1,.583-.272,8.933,8.933,0,0,0,6.416-5.063c.118-.246.247-.462.551-.5a5.143,5.143,0,0,1,.782-.033h21.9a5.438,5.438,0,0,1,.719.026c.326.032.46.269.585.533a8.846,8.846,0,0,0,6.4,5.034.837.837,0,0,1,.579.274c0,2.1-.072,4.2.029,6.295a2.261,2.261,0,0,1-1.128,2.146c-1.121.816-2.161,1.741-3.236,2.62h-.241c-1.381-.847-2.672-1.826-4.007-2.74-.846-.578-.854-.581-1.684.009-.978.695-1.958,1.388-2.926,2.1a8.59,8.59,0,0,1-1.013.636h-.241a18.87,18.87,0,0,1-2.631-1.772q-.888-.615-1.752-1.266a.622.622,0,0,0-.859.006c-1.172.873-2.379,1.7-3.556,2.564a5.371,5.371,0,0,1-.832.467h-.241c-1.414-.87-2.717-1.9-4.074-2.85-.678-.475-.677-.5-1.349-.02-1.105.792-2.238,1.543-3.339,2.341a8.4,8.4,0,0,1-.868.529Z" transform="translate(-35.044 -155.325)" fill="#d6d5eb"/>
                  <path id="Path_3320" data-name="Path 3320" d="M-787.021-794.926q-.006,9.749-.012,19.5,0,1.294,0,2.588c-.124.267-.392.249-.618.3a8.656,8.656,0,0,0-5.7,3.843c-.129.2-.238.423-.492.5-.52-.011-1.039-.056-1.56-.036a1.906,1.906,0,0,0-.477.046c-.221.066-.425.173-.428.437,0,.289.2.426.452.478a5.813,5.813,0,0,0,.9.036.8.8,0,0,1,.619.17,14.172,14.172,0,0,1-.374,2.047,9.077,9.077,0,0,0,0,2.261c.044.311.224.666-.1.952a2.089,2.089,0,0,1-.889.1q-10.588,0-21.177,0a2.2,2.2,0,0,1-.89-.1c-.26-.163-.205-.42-.163-.648a8.624,8.624,0,0,0-.3-4.088c-.06-.2-.148-.428.065-.611a2.1,2.1,0,0,1,1-.116q7.91,0,15.819,0a6.535,6.535,0,0,0,.841-.026c.238-.031.438-.113.444-.393a.458.458,0,0,0-.392-.465,2.914,2.914,0,0,0-.778-.057h-16.421a7.724,7.724,0,0,1-.96-.026.878.878,0,0,1-.611-.437,8.893,8.893,0,0,0-5.937-3.907c-.162-.027-.382,0-.405-.259l-.009-22.086a.907.907,0,0,1-.179-.679c-.009-1.283-.014-2.566,0-3.849.009-.764.156-.9.927-.922.18-.006.361,0,.541,0h35.966c1.468,0,1.474.006,1.474,1.467,0,1.063,0,2.125,0,3.188A1.085,1.085,0,0,1-787.021-794.926Z" transform="translate(-34.353 -17.72)" fill="#d5d5eb"/>
                  <path id="Path_3321" data-name="Path 3321" d="M-807.383-812.646c-.009-1.564-.038-3.128-.014-4.691.008-.487-.148-.6-.61-.6q-18.649.017-37.3,0c-.462,0-.617.109-.61.6.024,1.563-.005,3.128-.014,4.691-1.243,0-2.487.01-3.73,0a2.11,2.11,0,0,1-2.314-2.316q-.012-2.978,0-5.957a2.093,2.093,0,0,1,2.334-2.285h45.965a2.092,2.092,0,0,1,2.335,2.284q.014,2.978,0,5.957a2.111,2.111,0,0,1-2.314,2.317C-804.9-812.636-806.14-812.644-807.383-812.646Z" transform="translate(-13.991 0)" fill="#4153a0"/>
                  <path id="Path_3322" data-name="Path 3322" d="M-686.857-672.694a9,9,0,0,1,6.613-4.506.837.837,0,0,0,.168-.065,9.658,9.658,0,0,1,5.762,1.275,9.032,9.032,0,0,1,4.21,9.308,9.053,9.053,0,0,1-7.215,7.237,17.338,17.338,0,0,1-2.753.119,9.241,9.241,0,0,1-7.341-5.67,1.426,1.426,0,0,1-.382-1.312,9.359,9.359,0,0,1,.363-5.184A1.683,1.683,0,0,1-686.857-672.694Z" transform="translate(-141.313 -113.295)" fill="#45b397"/>
                  <path id="Path_3323" data-name="Path 3323" d="M-852.557-671.473a9.715,9.715,0,0,1,.374,5.192,1.6,1.6,0,0,1-.4,1.3,9.22,9.22,0,0,1-7.325,5.673,9.942,9.942,0,0,1-4.588-.666,8.755,8.755,0,0,1-5.406-7.046,8.909,8.909,0,0,1,6.482-9.921,10.8,10.8,0,0,1,3.51-.3c.668.174,1.353.3,2,.531a8.933,8.933,0,0,1,4.461,3.547c.088.13.191.251.287.375A2.045,2.045,0,0,1-852.557-671.473Z" transform="translate(0 -113.318)" fill="#ffc470"/>
                  <path id="Path_3324" data-name="Path 3324" d="M-792.091-626.841l.4-1.3,23.076,0,.382,1.312Z" transform="translate(-60.492 -151.455)" fill="#938cc2"/>
                  <path id="Path_3325" data-name="Path 3325" d="M-787.419-598.634q4.45,0,8.9,0c.444,0,.889-.007.877.613-.012.594-.437.707-.914.707q-8.9,0-17.8,0c-.493,0-.862-.246-.875-.711-.013-.489.4-.616.852-.615C-793.392-598.63-790.406-598.634-787.419-598.634Z" transform="translate(-56.502 -174.369)" fill="#938cc3"/>
                  <path id="Path_3326" data-name="Path 3326" d="M-693.57-597.328a5.8,5.8,0,0,1-.775-.006.67.67,0,0,1-.658-.713c.016-.431.281-.616.72-.6.517.022,1.035.007,1.553.005a.63.63,0,0,1,.7.584.658.658,0,0,1-.647.72A7.071,7.071,0,0,1-693.57-597.328Z" transform="translate(-135.879 -174.363)" fill="#938cc3"/>
                  <path id="Path_3327" data-name="Path 3327" d="M-788.574-765.568c-1.684,0-3.368-.013-5.052.009-.431.005-.6-.093-.593-.563q.035-3.248,0-6.5c0-.435.151-.535.556-.533q5.113.02,10.225,0c.466,0,.568.156.564.59q-.029,3.218,0,6.436c0,.464-.155.574-.591.568C-785.166-765.581-786.87-765.568-788.574-765.568Z" transform="translate(-58.84 -38.861)" fill="#938cc2"/>
                  <path id="Path_3328" data-name="Path 3328" d="M-706.506-716.07a1.792,1.792,0,0,1,0-.3c.121-.687-.141-1.018-.841-1.219a2.01,2.01,0,0,1-1.565-2.078,2.394,2.394,0,0,1,1.892-2.076c.424-.074.539-.238.517-.623-.02-.346.13-.61.512-.589.344.019.479.281.439.6-.055.431.117.568.54.644a2.6,2.6,0,0,1,1.371.7c.241.237.368.469.113.78a.563.563,0,0,1-.842.137,3.231,3.231,0,0,0-.8-.407c-.265-.091-.378-.015-.377.262,0,.5.013,1,0,1.5-.009.271.163.325.359.395a5.945,5.945,0,0,1,.944.377,2.117,2.117,0,0,1,1.1,2.5,2.259,2.259,0,0,1-1.917,1.749c-.407.048-.519.186-.485.554.032.334-.07.63-.448.653-.4.023-.5-.308-.5-.616.007-.412-.156-.532-.558-.588a3.128,3.128,0,0,1-1.7-.834c-.264-.243-.412-.5-.127-.825a.6.6,0,0,1,.9-.1,3.408,3.408,0,0,0,1.051.569c.273.073.463.057.424-.318A7.991,7.991,0,0,1-706.506-716.07Z" transform="translate(-124.99 -77.838)" fill="#938cc2"/>
                  <path id="Path_3329" data-name="Path 3329" d="M-794.069-655.959l-.6-1.316q9.079,0,18.158-.009c.486,0,.836.151.859.691.026.613-.4.634-.856.634Q-785.291-655.963-794.069-655.959Z" transform="translate(-58.488 -128.831)" fill="#938cc2"/>
                  <path id="Path_3330" data-name="Path 3330" d="M-789.574-686.4h6.675c.431,0,.884-.017.915.592.026.5-.278.73-.938.731q-6.645,0-13.29,0a2.035,2.035,0,0,1-.42-.014c-.371-.08-.648-.3-.606-.7a.633.633,0,0,1,.688-.614c.461.008.922,0,1.383,0Z" transform="translate(-56.493 -106.224)" fill="#938cc3"/>
                  <path id="Path_3331" data-name="Path 3331" d="M-789.584-715.515q3.366,0,6.733,0c.444,0,.889-.006.875.614-.013.593-.439.7-.914.706-1.7.006-3.406,0-5.11,0h-8c-.12,0-.24,0-.361,0-.494.008-.861-.246-.873-.711-.013-.488.4-.615.852-.613C-794.113-715.509-791.848-715.515-789.584-715.515Z" transform="translate(-56.503 -83.613)" fill="#938cc3"/>
                  <path id="Path_3332" data-name="Path 3332" d="M-716.464-772.313c-1.222,0-2.444-.005-3.666,0-.423,0-.77-.061-.786-.588-.014-.461.27-.732.815-.733,2.464-.005,4.928,0,7.393,0,.475,0,.849.144.914.659.051.407-.341.662-.942.663Q-714.6-772.311-716.464-772.313Z" transform="translate(-115.758 -38.487)" fill="#938cc3"/>
                  <path id="Path_3333" data-name="Path 3333" d="M-716.417-743.77c-1.264,0-2.527,0-3.791,0-.381,0-.678-.08-.723-.53-.048-.487.242-.7.655-.775a4.516,4.516,0,0,1,.781-.017h6.5a1.337,1.337,0,0,1,.76.122c.241.154.5.334.425.659a.653.653,0,0,1-.7.539C-713.809-743.773-715.113-743.77-716.417-743.77Z" transform="translate(-115.743 -60.646)" fill="#938cc3"/>
                  <path id="Path_3334" data-name="Path 3334" d="M-692.325-657.232l-.575,1.2c-.459,0-.918-.013-1.376,0a.666.666,0,0,1-.763-.591.6.6,0,0,1,.64-.706A5.57,5.57,0,0,1-692.325-657.232Z" transform="translate(-135.845 -128.758)" fill="#938cc3"/>
                  <path id="Path_3335" data-name="Path 3335" d="M-664.133-645.9a.937.937,0,0,1-.592-.257c-.555-.459-1.1-.926-1.673-1.368a.664.664,0,0,1-.2-.99c.274-.383.651-.323,1-.051.189.148.372.3.56.453.855.682.86.686,1.575-.1q1.763-1.928,3.515-3.865c.135-.148.272-.294.41-.439.5-.526.834-.61,1.156-.259.411.446.129.794-.19,1.138-.557.6-1.1,1.217-1.654,1.823q-1.6,1.754-3.2,3.506A1.048,1.048,0,0,1-664.133-645.9Z" transform="translate(-157.822 -132.173)" fill="#feffff"/>
                  <path id="Path_3336" data-name="Path 3336" d="M-840.527-652.333c0-.746-.008-1.445,0-2.144,0-.254-.122-.343-.34-.409a5.282,5.282,0,0,1-.84-.32,2.048,2.048,0,0,1-.214-3.648,2.079,2.079,0,0,1,.7-.333c.481-.094.779-.255.723-.834a.428.428,0,0,1,.508-.465.465.465,0,0,1,.417.561c-.043.425.077.634.551.7a2.547,2.547,0,0,1,1.373.7.557.557,0,0,1,.113.8.566.566,0,0,1-.8.172c-.37-.191-.818-.63-1.111-.435-.322.215-.057.815-.123,1.24-.087.561.123.83.679,1.023,1.346.467,1.765,1.068,1.744,2.257a2.158,2.158,0,0,1-1.806,2.226c-.43.1-.693.23-.622.731a.393.393,0,0,1-.4.486c-.319.021-.559-.169-.523-.445.078-.606-.292-.671-.726-.766a3.33,3.33,0,0,1-1.524-.8c-.3-.267-.4-.557-.127-.888a.567.567,0,0,1,.893-.057A3.163,3.163,0,0,0-840.527-652.333Z" transform="translate(-20.937 -126.342)" fill="#fff"/>
                  <path id="Path_3337" data-name="Path 3337" d="M-693.514-694.879a.849.849,0,0,1,0-.237c.076-.254-.2-.7.2-.739a.934.934,0,0,1,.846.655,1.056,1.056,0,0,1-.653,1.344c-.253.091-.423.1-.4-.249C-693.5-694.362-693.514-694.62-693.514-694.879Z" transform="translate(-137.02 -98.879)" fill="#d5d4eb"/>
                  <path id="Path_3338" data-name="Path 3338" d="M-701.508-711.585a.753.753,0,0,1,0,.179c-.061.243.166.589-.19.72-.247.09-.841-.463-.888-.776a1.072,1.072,0,0,1,.875-1.057c.177-.007.2.1.2.218.006.239,0,.478,0,.717Z" transform="translate(-129.984 -85.941)" fill="#d5d4eb"/>
                  <path id="Path_3339" data-name="Path 3339" d="M-827.6-632.233a1.945,1.945,0,0,1,0-.3c.038-.242-.129-.662.12-.692a.942.942,0,0,1,.908.581.941.941,0,0,1-.055.924c-.194.292-.481.6-.816.52s-.092-.482-.155-.734A1.275,1.275,0,0,1-827.6-632.233Z" transform="translate(-32.899 -147.509)" fill="#ffc673"/>
                  <path id="Path_3340" data-name="Path 3340" d="M-835.532-648.836a.922.922,0,0,1,0,.179c-.045.237.145.569-.143.695-.217.095-.453-.106-.64-.25a.661.661,0,0,1-.261-.739,1.079,1.079,0,0,1,.606-.7c.3-.145.454-.081.44.274C-835.538-649.195-835.532-649.016-835.532-648.836Z" transform="translate(-25.928 -134.704)" fill="#ffc572"/>
                </g>
              </svg>
              <svg *ngIf="barcodeSvg" xmlns="http://www.w3.org/2000/svg" width="110" height="108.493" viewBox="0 0 122.88 108.493">
                <g id="Group_45373" data-name="Group 45373" transform="translate(13435 -14607)">
                  <g id="barcode-scan-icon_1_" data-name="barcode-scan-icon (1)" transform="translate(-13435 14607)">
                    <path id="Path_35294" data-name="Path 35294" d="M2.38,0H20.71V4.76H4.76V17.2H0V2.38A2.386,2.386,0,0,1,2.38,0ZM17.92,16.23h8.26V80.81H17.92V16.23Zm51.49,0h5.9V61.81l-5.9-2V16.23Zm-11.43,0H62.4V58.81H57.98V16.23Zm-24.79,0H35.7V80.81H33.19V16.23Zm64.4,0h7.37V80.81H97.59V16.23Zm-15.27,0h8.26V80.81H85.038l.264-6.2L82.32,68.635V16.23Zm-39.61,0h8.26V60.81l-8.26,7V16.23ZM4.76,79.84V92.28H20.71v4.76H2.38A2.379,2.379,0,0,1,0,94.66V79.84ZM103.4,0h17.1a2.386,2.386,0,0,1,2.38,2.38V17.2h-4.76V4.76H103.4V0Zm19.48,79.84V94.66a2.386,2.386,0,0,1-2.38,2.38H103.4V92.28h14.72V79.84Z" fill="#1492e6"/>
                  </g>
                  <g id="plus-round-line-icon" transform="translate(-13397.865 14664.135)">
                    <path id="Path_35295" data-name="Path 35295" d="M7.411,7.411A25.3,25.3,0,1,1,0,25.3,25.227,25.227,0,0,1,7.411,7.411Zm16.335,5.431a1.373,1.373,0,0,1,2.745,0V24.12H37.769a1.373,1.373,0,0,1,0,2.745H26.491V38.143a1.373,1.373,0,0,1-2.745,0V26.865H12.468a1.373,1.373,0,1,1,0-2.745H23.746V12.843ZM25.3,2.745A22.561,22.561,0,1,0,41.256,9.353,22.488,22.488,0,0,0,25.3,2.745Z" fill="#1492e6" stroke="#1492e6" stroke-width="1.5"/>
                  </g>
                </g>
              </svg>
              
              
        </div>
        <div>
            <p class="title text-gray-color font-semibold f-16 m-0 text-center">{{title}} {{(formName == storageName.FORMNAME_TRANSACTION_STOCKJOURNAL || formName == storageName.FORMNAME_INVENTORY_BARCODE) ?'barcode?': 'bill?'}}</p>
            <p class="sub-info font-regular text-lighter-gray d-flex align-items-center f-14 mb-2 justify-content-center text-center">Do you want to {{title | lowercase  }} {{(formName == storageName.FORMNAME_TRANSACTION_STOCKJOURNAL || formName == storageName.FORMNAME_INVENTORY_BARCODE) ?'barcode?': 'invoice?'}} </p>
        </div>
    </div>
    <div class="modal-footer border-0">
        <div tabindex="0" class="col custom_focus d-flex align-items-center justify-content-center cursorPointer m-0" (keydown.enter)="onKeyDown($event)" (click)="activeModal.close(true)"><span class="font-semibold text-color-danger f-16">Yes</span></div>
        <div tabindex="0" class="col custom_focus d-flex align-items-center justify-content-center cursorPointer m-0" (keydown.enter)="onKeyDown($event)" (click)="activeModal.close(false)"><span class="text-gray-color font-semibold f-16">No</span></div>
    </div>
</ng-container>