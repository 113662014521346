import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CheckboxRendererService {

  public dataSubject = new Subject<any>();
  data$ = this.dataSubject.asObservable();

  TriggerCheckBox(data: any,) {
    this.dataSubject.next(data);
  }
}
