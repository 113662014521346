import { CheckboxRendererComponent } from "src/app/shared/common-components/ag-grid/checkbox-renderer/checkbox-renderer.component";
import { AgColDefsI, AgGridI, paginationModel } from "../../../shared/common-components/ag-grid/ag-grid.model"
import { ColumnCellType, StorageName } from "../../../shared/enum/common-enum";
import { TextRendererComponent, getCellStyleForColor } from "src/app/shared/common-components/ag-grid/text-renderer/text-renderer.component";
import { API_URL_GET_ALL_SCRAP, API_URL_GET_ALL_STOKED } from "src/app/shared/url-helper/url-helper";
import { NumericCellRendererComponent } from "src/app/shared/common-components/ag-grid/numeric-cell-renderer/numeric-cell-renderer.component";
import { CustomHeaderComponent } from "src/app/shared/common-components/ag-grid/custom-header/custom-header.component";
import { StatusCellRendererComponent } from "src/app/shared/common-components/ag-grid/status-cell-renderer/status-cell-renderer.component";
import { AgGridInnerHTMLDisplayComponent } from "src/app/shared/common-components/ag-grid/ag-grid-inner-htmldisplay/ag-grid-inner-htmldisplay.component";
import { DateCellRendererComponent } from "src/app/shared/common-components/ag-grid/date-cell-renderer/date-cell-renderer.component";
import { AgGridCustomInputComponent } from "src/app/shared/common-components/ag-grid/ag-grid-custom-input/ag-grid-custom-input.component";
import { ReceiveButtonCellComponent } from "src/app/shared/common-components/ag-grid/receive-button-cell/receive-button-cell.component";
import { EditDeleteIconComponent } from "src/app/shared/common-components/ag-grid/edit-delete-icon/edit-delete-icon.component";
import { TrackOrderCellrenderComponent } from "src/app/shared/common-components/ag-grid/track-order-cellrender/track-order-cellrender.component";
export class OldJewellery {
}

const ActionColumn: AgColDefsI = {
    headerName: "",
    headerComponentParams: { selectAll : true },
    field: "active",       
    lockPosition: "left",
    cellClass: "custom-column-group-cell",
    cellRenderer:CheckboxRendererComponent  ,
    headerClass:" ag-header-check-center custom-column-group-cell",
    minWidth: 60,
    maxWidth: 60,
    editable:false, 
  };

  const ActionColumnStocked: AgColDefsI = {
    headerName: "",
    headerComponentParams: { selectAll : true },
    // field: "active",    
    field: "isSelected",    
    lockPosition: "left",
    cellClass: "custom-column-group-cell",
    cellRenderer:CheckboxRendererComponent  ,
    headerClass:" justify-content-center px-0 custom-column-group-cell",
    minWidth: 60,
    maxWidth: 60,
    editable:false, 
    cellRendererParams: (params) => {
      const isBarcodeNo = params?.data?.barcodeNo ? true : false;
      return { showLabel: false,isReadOnly:!isBarcodeNo , isCommonCheckbox:true};
    },
  };

const VoucherNoColumn: AgColDefsI = {
    headerName: "Voucher No.",
    sortable: true,
    editable: false,
    minWidth: 70,
    resizable: true,
    headerClass: "px-0 custom-column-group-cell border-0 ",
    field: 'invoiceNo',
    cellClass: 'px-0 custom-column-group-cell',
    cellRenderer: TextRendererComponent,
    // headerComponentParams:{
    //   enableSorting: false,
    //   enableMenu: false,
    //   filterValueColumnName: "invoiceNo",
    //   filterIdColumnName: "invoiceNo",
    //   isInputFilter: true,
    // },
    cellRendererParams :{isCursor:true},
};

const VoucherTypeColumn: AgColDefsI = {
    headerName: "Voucher Type",
    field: "voucherType",
    minWidth: 70,
    resizable: true,
    headerClass: "custom-column-group-cell px-0",
    cellClass: "custom-column-group-cell",
    editable: false,
    sortable: true,
    cellRenderer: TextRendererComponent,
    
};


export const CustomerNameColumn: AgColDefsI = {
    headerName: "Customer Name",
    headerComponent: CustomHeaderComponent,
    cellRenderer: TextRendererComponent,
    headerClass:
      "px-0 custom-column-group-cell border-0  justify_content_end",
    field: "accountLedgerName",
    sortable: true,
    cellClass: " custom-column-group-cell",
    minWidth: 80,
    resizable: true,
    editable: false,
    // headerComponentParams:{
    //   enableSorting: false,
    //   filterValueColumnName: "accountLedgerName",
    //   filterIdColumnName: "accountLedgerName",
    //   isInputFilter: true,
    // },
    cellRendererParams :{isCursor:true},
  }

const MetalColumn: AgColDefsI = {
    headerName: "Metal",
    field: "metalName",
    minWidth: 60,
    resizable: true,
    sortable: true,
    editable: false,
    cellRenderer: TextRendererComponent,
    cellClass: "custom-column-group-cell px-0",
    headerClass: "custom-column-group-cell px-0",
  //   headerComponentParams:{
  //     filterValueColumnName: "metalId",
  //     filterIdColumnName: "metalName",
  //     filterParams: StorageName.METAL_DROPDOWN,
  //     floatingMultiselectDropDown: true,
  // },
  cellRendererParams :{isCursor:true},
};

const ProductNameColumn: AgColDefsI = {
    headerName: "Product Name",
    field: "productName",
    minWidth: 80,
    resizable: true,
    sortable: true,
    editable: false,
    cellRenderer: TextRendererComponent,
    cellClass: "custom-column-group-cell px-0",
    headerClass: "custom-column-group-cell px-0",
    // headerComponentParams:{
    //   enableSorting: false,
    //   filterValueColumnName: "productName",
    //   filterIdColumnName: "productName",
    //   isInputFilter: true,
    // },
    cellRendererParams :{isCursor:true},
};
const Locationcolumn: AgColDefsI = {
    
  headerName: "Location",
  field: 'locationName',
  headerClass: "custom-column-group-cell px-0",
  cellClass: "custom-column-group-cell px-2",
  minWidth: 170,
  sortable: true,
  editable: false,
  cellRenderer:TextRendererComponent,
  // headerComponentParams:{
  //   filterValueColumnName: "locationId",
  //   filterIdColumnName: "locationName",
  //   filterParams: StorageName.LOCATION_DROPDOWN,
  //   floatingMultiselectDropDown: true,
  // }
};
export const NetWtColumn: AgColDefsI = {
  headerName: "Net Wt",
    field: "netWt",
    minWidth: 60,
    resizable: true,
    cellStyle: {'text-align': 'right'},
    headerClass: "custom-column-group-cell px-0",
    cellClass: "custom-column-group-cell",
    editable: false,
    sortable: true,
    cellRenderer: NumericCellRendererComponent,
    cellRendererParams : {isWeight:true},
    // headerComponentParams:{
    //   filterValueColumnName: "netWt",
    //   filterIdColumnName: "netWt",
    //   isInputFilter: true,
    //   isAllowNumber:true
    // }
};


export const WeightColumn: AgColDefsI = {
    headerName: "Final Wt.",
    headerComponent: CustomHeaderComponent,
    cellRenderer: NumericCellRendererComponent,
    headerClass:
      "px-0 custom-column-group-cell border-0 justify_content_end ag_header_end",
    field: "finalWt",
    sortable: true,
    cellClass: " custom-column-group-cell text-end",
    minWidth: 60,
    resizable: true,
    editable:false,
    cellRendererParams: {isWeight:true},
    // headerComponentParams:{
    //   enableSorting: false,
    //   filterValueColumnName: "finalWt",
    //   filterIdColumnName: "finalWt",
    //   isInputFilter: true,
    //   isAllowNumber:true
    // },
  };

  const scrapInvoiceDateColumn: AgColDefsI = {
    headerName: "Date",
    field: "invoiceDate",
    minWidth: 60,
    resizable: true,
    headerClass: "custom-column-group-cell px-0  ag_header_end",
    cellClass: "custom-column-group-cell text-end",
    editable: false,
    sortable: true,
    cellRenderer:DateCellRendererComponent,
  };

  export const ScrapFinalWeightColumn: AgColDefsI = {
      headerName: "Final Wt.",
      headerComponent: CustomHeaderComponent,
      cellRenderer: NumericCellRendererComponent,
      headerClass:
        "px-0 custom-column-group-cell border-0  justify_content_end ag_header_end",
      field: "finalWt",
      sortable: true,
      cellClass: " custom-column-group-cell text-end",
      minWidth: 60,
      resizable: true,
      editable:false,
      cellRendererParams: {isWeight:true},
      // headerComponentParams:{
      //   enableSorting: false,
      //   filterValueColumnName: "finalWt",
      //   filterIdColumnName: "finalWt",
      //   isInputFilter: true,
      //   isAllowNumber:true
      // },
    };

  export const ScrapRateColumn: AgColDefsI = {
    headerName: "Rate",
    field: "rate",
    minWidth: 60,
    resizable: true,
    cellStyle: {'text-align': 'right'},
    headerClass: "custom-column-group-cell px-0",
    cellClass: "custom-column-group-cell",
    editable: false,
    sortable: true,
    cellRenderer: NumericCellRendererComponent,
    cellRendererParams : {isWeight:true},
    // headerComponentParams:{
    //   filterValueColumnName: "rate",
    //   filterIdColumnName: "rate",
    //   isInputFilter: true,
    //   isAllowNumber:true
    // }
    };
 
  const ScrapGrossWtColumn: AgColDefsI = {
    headerName: "Gross Wt",
    field: "grossWt",
    minWidth: 60,
    resizable: true,
    cellStyle: {'text-align': 'right'},
    headerClass: "custom-column-group-cell px-0",
    cellClass: "custom-column-group-cell",
    editable: false,
    sortable: true,
    cellRenderer: NumericCellRendererComponent,
    cellRendererParams : {isWeight:true},
    // headerComponentParams:{
    //   filterValueColumnName: "grossWt",
    //   filterIdColumnName: "grossWt",
    //   isInputFilter: true,
    //   isAllowNumber:true
    // }
  };
  const ScrapPurityColumn: AgColDefsI = {
      headerName: "Purity",
      field: "purity",
      minWidth: 60,
      resizable: true,
      cellStyle: {'text-align': 'right'},
      headerClass: "custom-column-group-cell px-0",
      cellClass: "custom-column-group-cell text-primary",
      editable: false,
      sortable: true,
      // cellRenderer: NumericCellRendererComponent,
      // cellRendererParams : {isWeight:true},
      cellRenderer: TextRendererComponent,
      // headerComponentParams:{
      //   filterValueColumnName: "purity",
      //   filterIdColumnName: "purity",
      //   isInputFilter: true,
      //   isAllowNumber:true
      // }
  };

//   const MaterialOutGrossWtColumn: AgColDefsI = {
//     headerName: "Material Out Gross Wt.",
//     field: "materialOutPurityWt",
//     minWidth: 150,
//     headerClass: "custom-column-group-cell px-0 ag_header_end",
//     cellClass: "custom-column-group-cell text-end",
//     cellStyle: getCellStyleForColor(ColumnCellType.OpeningWtColumn),
//     editable: false,
//     sortable: true,
//     cellRenderer: NumericCellRendererComponent,
//     cellRendererParams:{isWeight:true},
// };


const PurityColumn: AgColDefsI = {
    headerName: "Purity",
    headerComponentParams: { isRequired: false },
    cellStyle: getCellStyleForColor(ColumnCellType.OpeningWtColumn),
    field: "purity",
    minWidth: 60,
    resizable: true,
    sortable: true,
    editable: false,
    cellRenderer: TextRendererComponent,
    cellClass: "custom-column-group-cell px-0 text-end text-primary",
    headerClass: "custom-column-group-cell px-0 ag_header_end",
};


const GrossWtColumn: AgColDefsI = {
    headerName: "Gross Wt",
    field: "grossWt",
    minWidth: 60,
    resizable: true,
    headerClass: "custom-column-group-cell px-0 ag_header_end",
    cellClass: "custom-column-group-cell text-end",
    editable: false,
    sortable: true,
    cellRenderer: NumericCellRendererComponent,
    cellRendererParams:{isWeight:true},
}

export const StatusColumn: AgColDefsI = {
    headerName: "Status",
    headerComponent: CustomHeaderComponent,
    sortable: true,
    headerClass:
      "px-0 custom-column-group-cell border-0  justify_content_start",
    field: "statusName",
    minWidth: 180,
    maxWidth: 180,
    resizable: false,
    cellClass: "custom-column-group-cell align-items-center d-flex justify-content-center",
    editable:false,
    // cellRenderer: AgGridInnerHTMLDisplayComponent,
    cellRenderer: StatusCellRendererComponent, 
    headerComponentParams:{
     // filterValueColumnName: "statusId",
    //  filterIdColumnName: "statusName",
    //  filterParams: StorageName.JOBWORK_STATUS_DROPDOWN,
    //  floatingMultiselectDropDown: true,
    },
    cellRendererParams :{isCursor:true}
    // valueFormatter: params => {
    //   if (params.value === 0) {
    //     return "Not Initiated";
    //   } else {
    //     return params?.value; // Display the actual status ID for other values
    //   }
    // }
  };
  export const LessWtColumn: AgColDefsI = {
    headerName: "Less Wt",
    field: "lessWt",
    minWidth: 60,
    resizable: true,
    cellStyle: {'text-align': 'right'},
    headerClass: "custom-column-group-cell px-0",
    cellClass: "custom-column-group-cell",
    editable: false,
    sortable: true,
    cellRenderer: NumericCellRendererComponent,
    cellRendererParams : {isWeight:true},
    // headerComponentParams:{
    //   filterValueColumnName: "lessWt",
    //   filterIdColumnName: "lessWt",
    //   isInputFilter: true,
    //   isAllowNumber:true
    // }
  };


  export const DescriptionColumn: AgColDefsI = {
    headerName: "Description",
    headerComponent: CustomHeaderComponent,
    sortable: true,
    headerClass:
      "px-0 custom-column-group-cell border-0  justify_content_start",
    field: "description",
    minWidth: 100,
    resizable: true,
    cellClass: "custom-column-group-cell text-primary text-underline",
    editable:false,
    cellRenderer: AgGridInnerHTMLDisplayComponent,
    cellRendererParams :{isCursor:true},
    // headerComponentParams:{
    //   enableSorting: false,
    //   filterValueColumnName: "description",
    //   filterIdColumnName: "description",
    //   isInputFilter: true,
    // },
  };

  export const GroupNameColumn: AgColDefsI = {
    headerName: "Group Name",
    headerClass:
      "px-0 custom-column-group-cell  justify_content_end",
    field: "groupName",
    sortable: true,
    editable: false,
    resizable: true,
    cellRenderer: TextRendererComponent,
    cellClass: "custom-column-group-cell ",
    minWidth: 60,
    // headerComponentParams:{
    //   enableSorting: false,
    //   filterValueColumnName: "groupName",
    //   filterIdColumnName: "groupName",
    //   isInputFilter: true,
    // },
  };
  export const QuantityColumn: AgColDefsI = {
    headerName: "Quantity",
    field: "quantity",
    minWidth: 50,
    resizable: true,
    // headerComponentParams: { isRequired: true , enableSorting: false},
    headerClass: "custom-column-group-cell px-0",
    cellClass: "custom-column-group-cell text-end",
    editable: true,
    sortable: true,
    isRequired:true,
    cellRenderer: NumericCellRendererComponent,
    cellEditor:AgGridCustomInputComponent,
    cellEditorParams:{
      isOnlyNumbers:true,
    },
    // headerComponentParams:{
    //   enableSorting: false,
    //   filterValueColumnName: "quantity",
    //   filterIdColumnName: "quantity",
    //   isInputFilter: true,
    // },
    valueGetter: (param) => {
      return param.data.quantity !== undefined && param.data.quantity !== null? param.data.quantity: 0;
    }
  };
  const Amount: AgColDefsI = {
    headerName: "Amount",
    field: "amount",
    resizable: true,
    sortable: true,
    minWidth: 60,
    flex:1,
    editable: false,
    headerClass: "px-1 custom-column-group-cell",
    headerCheckboxSelection: false,
    cellRenderer: NumericCellRendererComponent,
    cellRendererParams: {isCursor: true ,isAmt:true},
    // headerComponentParams:{
    // filterValueColumnName: "amount",
    // filterIdColumnName: "amount",
    // isInputFilter: true,
    // isAllowNumber:true
    // },
    cellClass: 'px-2 cursorPointer custom-column-group-cell text-end',   
  };
  const BranchName: AgColDefsI = { 
    headerName: "Branch Name", 
    headerComponentParams: {enableMenu:false},
    sortable:true,
    headerClass: "px-0 custom-column-group-cell border-0 ",
    field: 'branchName',
    cellClass:'px-0 custom-column-group-cell',
    suppressMovable: false,
    resizable: true, 
    cellRenderer: TextRendererComponent,
    cellRendererParams :{isCursor:false},
    editable:false, 
    minWidth: 180, flex:2,
  }

const ScrapTable: AgColDefsI[] = [
    ActionColumn,
    GroupNameColumn,
    DescriptionColumn,
    VoucherNoColumn,
    CustomerNameColumn,
    MetalColumn,
    ProductNameColumn,
    Locationcolumn,
    ScrapGrossWtColumn,
    LessWtColumn,
    NetWtColumn,
    ScrapPurityColumn,
    scrapInvoiceDateColumn,
    ScrapFinalWeightColumn,
    ScrapRateColumn,
    Amount,
    QuantityColumn,
    BranchName,
   // VoucherTypeColumn,,
    // StatusColumn
]

export const AgGridScrapTable: AgGridI = {
    colDefs: ScrapTable,
    rowSelection: "multiple",
    addBlankRowOnEnter: true,
    columnSettings: true,
    singleClickEdit: true,
    storageName: StorageName.REPORT_OLD_JEWELLERY_SCRAP_GRID,
    filterStorageName: StorageName.FILTER_REPORT_OLD_JEWELLERY_SCRAP_GRID,
    sortStorageName: StorageName.SORT_REPORT_OLD_JEWELLERY_SCRAP_GRID,
    paginationStorageName : StorageName.PAGINATION_REPORT_OLD_JEWELLERY_SCRAP_GRID,
    rows: [],
    isServerSidepagination: true,
    isFilter: true,
    rowHeight:28,
    selectAll : true,
    apiUrl :  API_URL_GET_ALL_SCRAP,
};


// refine column
const RfidCodeColumn: AgColDefsI =  {
    headerName: "RFID Code.",
    headerComponent: CustomHeaderComponent,
    headerClass:
      "px-0 custom-column-group-cell  justify_content_end",
    field: "rfid",
    sortable: true,
    cellRenderer: TextRendererComponent,
    cellClass: "custom-column-group-cell text-primary text-underline",
    minWidth: 60,
    resizable: true,
    editable: false,
    cellRendererParams: { isCursor: true ,widthFull:true },
    // headerComponentParams:{
    //   enableSorting: false,
    //   filterValueColumnName: "invoiceNo",
    //   filterIdColumnName: "invoiceNo",
    //   isInputFilter: true,
    // },
  }
  const JobOrderNoColumn: AgColDefsI = {
    headerName: "Job Order No", 
    sortable:true,
    headerClass: "px-0 custom-column-group-cell",
    field: 'invoiceNo',
    cellClass:'px-0 custom-column-group-cell', flex:1,
    editable:false,
    resizable: true,
    minWidth: 60,
    cellRenderer: TextRendererComponent,
    cellRendererParams :{isCursor:true},
  }
  const RefNoColumn: AgColDefsI = {
    headerName: "Ref. No.", 
    sortable:true,
    headerClass: "px-0 custom-column-group-cell",
    field: 'refNo',
    cellClass:'px-0 custom-column-group-cell', minWidth: 60, flex:1,
    editable:false,
    resizable: true,
    cellRenderer: TextRendererComponent,
    cellRendererParams :{isCursor:true}, 
  }
  const RefineGroupNameColumn: AgColDefsI = {
    headerName: "Group Name",
    headerClass:"px-0 custom-column-group-cell  justify_content_end",
    field: "groupName",
    sortable: true,
    editable: false,
    cellRenderer: TextRendererComponent,
    cellClass: "custom-column-group-cell ",
    minWidth: 60,
    resizable: true,
    headerComponentParams:{
      enableSorting: false,
      // filterValueColumnName: "groupName",
      // filterIdColumnName: "groupName",
      // isInputFilter: true, 
    }
  }
  const RefineCustomerNameColumn: AgColDefsI = {
    headerName: "Customer Name", 
    sortable:true,
    headerClass: "px-0 custom-column-group-cell",
    field: 'customerName',
    cellClass:'px-0 custom-column-group-cell',
    minWidth: 80,
    flex:1,
    editable:false,
    autoHeight:true,
    wrapText:true,
    resizable: true,
    cellRenderer: TextRendererComponent,
    cellRendererParams :{isCursor:true},  
  }
  const RefineShortDesColumn: AgColDefsI = {
    headerName: "Short Description", 
    sortable:true,
    headerClass: "px-1 custom-column-group-cell border-0  ",
    field: 'description',
    cellClass:'px-2 custom-column-group-cell text-gray-color',
    minWidth: 60,
    resizable: true,
    flex:1,
    editable:false,
    autoHeight:true,
    wrapText:true,
    cellRenderer: AgGridInnerHTMLDisplayComponent
  }
  const RefineStatusColumn: AgColDefsI = {
    headerName: "Status", 
    sortable:true,
    headerClass: "px-1 custom-column-group-cell border-0 ag_header_center ag_header_end",
    field: 'statusName',
    cellClass:'text-center custom-column-group-cell d-flex align-items-center justify-content-center', minWidth: 170, 
    editable:false,
    pinned: "right",
    resizable: false,
    lockPinned: true,
    cellRenderer: StatusCellRendererComponent,  
    cellRendererParams :{isCursor:true},
    cellEditorParams:{
      decimalPlaces:2,
      isOnlyNumbers:true,
    }
  }
  const RefinePriorityColumn: AgColDefsI = {
    headerName: "Priority", 
    sortable:true,
    headerClass: "px-1 custom-column-group-cell border-0  ag_header_center  ag_header_end",
    field: 'priorityName',
    cellClass:'text-center custom-column-group-cell d-flex align-items-center justify-content-center', 
    editable:false,
    resizable: false,
    pinned: "right",
    lockPinned: true,
    minWidth:170,
    cellRenderer: StatusCellRendererComponent,  
    cellRendererParams :{isCursor:true},
    cellEditorParams:{
      decimalPlaces:2,
      isOnlyNumbers:true,
    }
  }
  const RefineAssignDate: AgColDefsI = {
    headerName: "Assign To", 
    sortable:true,
    headerClass: "px-0 custom-column-group-cell",
    field: 'accountLedgerName',
    cellClass:'px-0 custom-column-group-cell', 
    minWidth: 60, flex:1,
    editable:false,
    resizable: true,
    cellRenderer: TextRendererComponent,
    cellRendererParams :{isCursor:true}, 
  }
  const RefineDueToDate: AgColDefsI = {
    headerName: "Due Date", 
    sortable:true,
    headerClass: "px-1 custom-column-group-cell border-0  ag_header_end",
    field: 'dueDate',
    cellClass:'px-2 custom-column-group-cell text-gray-color text-end', minWidth: 60, flex:2,
    editable:false,
    resizable: true,
    cellRenderer: DateCellRendererComponent,
    cellRendererParams :{isCursor:true},
    cellEditor:AgGridCustomInputComponent,
    cellEditorParams:{
      decimalPlaces:2,
      isOnlyNumbers:true,
    } 
  }
  const RefineOrderDate: AgColDefsI = {
    headerName: "Order Dt.", 
    sortable:true,
    headerClass: "px-1 custom-column-group-cell border-0  ag_header_end",
    field: 'invoiceDate',
    cellClass:'px-2 custom-column-group-cell text-gray-color text-end', minWidth: 60, flex:2,
    editable:false,
    resizable: true,
    cellRenderer: DateCellRendererComponent,
    cellRendererParams :{isCursor:true},
  }
  const RefineTagNo: AgColDefsI = {
    headerName: "Tag No.",
    headerClass:"px-0 custom-column-group-cell  justify_content_end",
    field: "barcodeNo",
    sortable: true,
    cellRenderer: TextRendererComponent,
    cellClass: "custom-column-group-cell ",
    minWidth: 60,
    resizable: true,
    editable: false,
    // headerComponentParams:{
    //   enableSorting: false,
    //   filterValueColumnName: "invoiceNo",
    //   filterIdColumnName: "invoiceNo",
    //   isInputFilter: true,
    // },
  }
  const RefineInvoice: AgColDefsI = {
    headerName: "",
    pinned: "right",
    suppressNavigable: true,
    editable: false,
    lockPinned: true,
    field:'receive',
    headerComponent: CustomHeaderComponent,
    headerClass: "text-center custom-column-group-cell d-flex align-items-center justify-content-center",
    cellRenderer: ReceiveButtonCellComponent,
    headerComponentParams: { enableMenu: false, moreButton: false ,enableSorting: false, selectAll : false },
    cellClass: "text-center custom-column-group-cell d-flex align-items-center justify-content-center",
    minWidth: 120,
    maxWidth: 120,
    resizable: false,
    valueFormatter: params => {
      return 'Invoice'
   }
 }
 const RefinePrint: AgColDefsI = {
  headerName: "",
  pinned: "right",
  suppressNavigable: true,
  editable: false,
  lockPinned: true,
  field:'receive',
  headerClass: "custom-column-group-cell d-flex align-items-center justify-content-center",
  cellRenderer: EditDeleteIconComponent,
  headerComponentParams: { enableMenu: false, moreButton: false ,enableSorting: false, selectAll : false },
  cellClass: "custom-column-group-cell d-flex align-items-center justify-content-center",
  cellRendererParams: {
    editIcon: false,
    isDeleteIcon : false,
    printIcon: true,
  },
  // cellRenderer: ReceiveButtonCellComponent,
//   valueFormatter: params => {
//     return 'Print'
//  },
  minWidth: 60,
  maxWidth: 60,
  resizable: false,
}
const RefineTrackOrder: AgColDefsI =  {
  headerName: "",
  pinned: "right",
  suppressNavigable: true,
  editable: false,
  lockPinned: true,
  field:'receive',
  headerComponent: CustomHeaderComponent,
  headerClass: "text-center custom-column-group-cell d-flex align-items-center justify-content-center",
  cellRenderer: TrackOrderCellrenderComponent,
  headerComponentParams: { enableMenu: false, moreButton: false ,enableSorting: false, selectAll : false },
  cellClass: "custom-column-group-cell d-flex align-items-center justify-content-center",
  minWidth: 60,
  maxWidth: 60,
  resizable: false,
//   valueFormatter: params => {
//     return 'Invoice'
//  }
}

const RefineTable: AgColDefsI[] = [
  RfidCodeColumn,
  JobOrderNoColumn,
  RefNoColumn,
  RefineGroupNameColumn,
  RefineCustomerNameColumn,
  RefineShortDesColumn,
  ScrapPurityColumn,
  ScrapGrossWtColumn,
  ScrapFinalWeightColumn,
  RefineAssignDate,
  RefineDueToDate,
  RefineOrderDate,
  RefineTagNo,
  RefineStatusColumn,
  RefinePriorityColumn,
  RefineInvoice,
  RefinePrint,
  RefineTrackOrder,
  BranchName
]

export const AgGridRefineTable: AgGridI = {
    colDefs: RefineTable,
    rowSelection: "multiple",
    addBlankRowOnEnter: true,
    columnSettings: true,
    singleClickEdit: true,
    storageName: StorageName.REPORT_OLD_JEWELLERY_REFINE_GRID,
    filterStorageName: StorageName.FILTER_REPORT_OLD_JEWELLERY_REFINE_GRID,
    sortStorageName: StorageName.SORT_REPORT_OLD_JEWELLERY_REFINE_GRID,
    paginationStorageName : StorageName.PAGINATION_REPORT_OLD_JEWELLERY_REFINE_GRID,
    rows: [],
    isServerSidepagination: true,
    showPagination: true,
    rowHeight:28,
};

const barcodeColumn: AgColDefsI = 
    {
      headerName: "Barcode",
      field: 'barcodeNo',
      headerClass: "px-0 custom-column-group-cell border-0 ",
      cellClass: "custom-column-group-cell px-2",
      minWidth: 60,
      resizable: true,
      sortable: true,
      editable: false,
      lockPosition: "left",
      cellRenderer:TextRendererComponent,
      // headerComponentParams: { 
      //   enableSorting: false,
      //   filterValueColumnName: "barcodeno",
      //   filterIdColumnName: "barcodeno",
      //   isInputFilter: true,
      // },
    };


const StockTable: AgColDefsI[] = [
    ActionColumnStocked,
    VoucherNoColumn,
    barcodeColumn,
    VoucherTypeColumn,
    MetalColumn,
    ProductNameColumn,
    Locationcolumn,
    WeightColumn,
    GrossWtColumn,
    PurityColumn,
    BranchName
]

export const AgGridStockTable: AgGridI = {
    colDefs: StockTable,
    rowSelection: "multiple",
    addBlankRowOnEnter: true,
    columnSettings: true,
    singleClickEdit: true,
    storageName: StorageName.REPORT_OLD_JEWELLERY_STOCK_GRID,
    paginationStorageName : StorageName.PAGINATION_REPORT_OLD_JEWELLERY_STOCK_GRID,
    filterStorageName: StorageName.FILTER_REPORT_OLD_JEWELLERY_STOCK_GRID,
    sortStorageName:StorageName.SORTING_REPORT_OLD_JEWELLERY_STOCK_GRID,
    rows: [],
    isFilter:true,  
    isServerSidepagination: true,
    showPagination: true,
    rowHeight:28,
    apiUrl : API_URL_GET_ALL_STOKED
};


const ReceivedTable: AgColDefsI[] = [
  RfidCodeColumn,
  JobOrderNoColumn,
  RefNoColumn,
  RefineGroupNameColumn,
  RefineCustomerNameColumn,
  RefineShortDesColumn,
  ScrapPurityColumn,
  ScrapGrossWtColumn,
  ScrapFinalWeightColumn,
  RefineAssignDate,
  RefineDueToDate,
  RefineOrderDate,
  RefineTagNo,
  RefineStatusColumn,
  RefinePriorityColumn,
  // RefineInvoice,
  RefinePrint,
  RefineTrackOrder,
  BranchName
]

// export const AgGridReceivedTable: AgGridI = {
//     colDefs: ReceivedTable,
//     rowSelection: "multiple",
//     addBlankRowOnEnter: true,
//     columnSettings: true,
//     singleClickEdit: true,
//     storageName: StorageName.REPORT_OLD_JEWELLERY_RECEIVED_GRID,
//     paginationStorageName : StorageName.PAGINATION_REPORT_OLD_JEWELLERY_RECEIVED_GRID,
//     rows: [],
//     isServerSidepagination: true,
//     showPagination: true,
//     rowHeight:28,
// };

export const AgGridReceivedTable: AgGridI = {
  colDefs: ReceivedTable,
  rowSelection: "multiple",
  addBlankRowOnEnter: true,
  columnSettings: true,
  singleClickEdit: true,
  storageName: StorageName.REPORT_OLD_JEWELLERY_RECEIVED_GRID,
  filterStorageName: StorageName.FILTER_REPORT_OLD_JEWELLERY_RECEIVED_GRID,
  // sortStorageName: StorageName.SORT_REPORT_OLD_JEWELLERY_RECEIVED_GRID,
  paginationStorageName : StorageName.PAGINATION_REPORT_OLD_JEWELLERY_RECEIVED_GRID,
  rows: [],
  isServerSidepagination: true,
  showPagination: true,
  rowHeight:28,
};




//Report old-jewellery-scrap start

export class GetallReportOldJewelleryRequestModel extends paginationModel{
    pageSize         : number = 25;
    filters          : string ;
    sortList         : any[];
    exportAs         :number;
    statusId         :number;
    exportRequest    : exportRequest[];
    fromDate         : string;
    toDate           : string;
  }
    
  export class exportRequest {
    gridName : string;
    columnToInclude : any
  }
  

  export enum OldJewelleryScrapStatus
  {
    scrap           = 0,
    Initiate        = 1,
    Refine          = 2,
    Received        = 3,
    Stocked         = 4,  
  }
  

  export class AddDataToRefine {
    scrapInvoiceItems: ScrapInvoiceItem[]
  }
  
  export class ScrapInvoiceItem {
    id: number
    statusId: number
  }
  //Report old-jewellery-scrap end


  
