import { Component, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { ApexOptions, ChartComponent } from 'ng-apexcharts';
import { CommonService } from '../../services/common.service';

@Component({
  selector: 'app-custom-apx-chart',
  templateUrl: './custom-apx-chart.component.html',
  styleUrls: ['./custom-apx-chart.component.scss']
})
export class CustomApxChartComponent extends CommonService implements OnInit{
  @ViewChild("chart") chart: ChartComponent;
  public chartOptions: Partial<ApexOptions>;
  @Input() color: string;
  @Input() totalCustomer: string;
  @Input() ammount: string;
  @Input() series: number = 77;
  @Input() rate: string = "30%";
  @Input() backGroundColor: string;
  @Input() text: string;
  imgBlue: string   = "../../../assets/images/dashboard/hand_blue.svg";
  imgOrange: string = "../../../assets/images/dashboard/hand_orange.svg";
  imgGreen: string  = "../../../assets/images/dashboard/hand_green.svg";
  imgRed: string    = "../../../assets/images/dashboard/hand_red.svg";
  amountColor: string;


  constructor(public renderer: Renderer2,) {
    super(renderer);
  }

  ngOnInit(): void {
    this.chartOption()
    this.setAmountColor()
  }

chartOption(){
  this.chartOptions = {
 
    series: [this.series],
         chart: {
           height: 130,
           width:100,
           type: "radialBar",
           toolbar: {
             show: false
           }
         },
         plotOptions: {
           radialBar: {
             startAngle: -200,
             endAngle: 190,
             hollow: {
               margin: 15,
               size: '80%',
               image: this.text === 'BlueBar' ? this.imgBlue : 
                      this.text === 'OrangeBar' ? this.imgOrange : 
                      this.text === 'RedBar' ? this.imgRed : 
                      this.text === 'GreenBar' ? this.imgGreen : '',
               imageWidth: 110,
               imageHeight: 110,
               imageClipped: false,
               position: "front",
             },
             track: {
              background: 
              this.text === 'BlueBar' ? '#4F39CD' :
              this.text === 'OrangeBar' ? '#FCA100' :
              this.text === 'GreenBar' ? '#56B552' :
              this.text === 'RedBar' ? '#f1242e' : '' ,
               strokeWidth: "25%",
               margin: 0, 
               dropShadow: {
                 enabled: false,
                 top: -3,
                 left: 0,
                 blur: 4,
                 opacity: 0.35
               }
             },
   
             dataLabels: {
               show: false,
   
               value: {
                 formatter: function(val) {
                   return parseInt(val.toString(), 10).toString();
                 },
                 color: "#111",
                 fontSize: "36px",
                 show: true
               }
             }
           }
         },
         title:{
           text: this.text,
         },
         colors: [
          this.text === 'BlueBar' ? '#4F39CD' :
          this.text === 'OrangeBar' ? '#FCA100' :
          this.text === 'GreenBar' ? '#56B552' :
          this.text === 'RedBar' ? '#f1242e' : ''
        ],
         fill: {
         },
         stroke: {
           lineCap: "round",
           width: -8,
         },
     
  };
 }

 setAmountColor() {
  this.amountColor = this.color || (this.text === 'BlueBar' ? '#4E38CB' :
                                    this.text === 'OrangeBar' ? '#FDA100' :
                                    this.text === 'GreenBar' ? '#57B752' :
                                    this.text === 'RedBar' ? '#F82A34' : '');
}

}
