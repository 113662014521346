<div>
    <div class="modal-body custom-modal-body p-0 position-relative">
        <div class="justify-content-end d-flex cursorPointer p-2 position-absolute end-0 top-0 z-index" (click)="activeModal.close(true)">
            <svg xmlns="http://www.w3.org/2000/svg" width="15.999" height="16.002" viewBox="0 0 17.999 18.002">
                <path id="Union_4" data-name="Union 4" d="M-1051-8106l-8,8,8-8-8-8,8,8,8-8-8,8,8,8Z" transform="translate(1059.999 8115)" fill="none" stroke="#ffffff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
            </svg>
        </div>
        
        <div class="custom-carousel-image">
            <ngb-carousel >
                <ng-container *ngIf="!isBase64Resource">
                    <ng-template *ngFor="let item of jewelryCatalogue; let i = index" ngbSlide>
                            <div class="pic-img-wrapper">
                                <img class="preview" [src]="isBase64Image(item?.imageUrl)" [alt]="'Slide ' + (i + 1)" />
                            </div>
                    </ng-template>
                </ng-container>
                <ng-container *ngIf="isBase64Resource" >
                    <ng-template *ngFor="let item of jewelryCatalogue; let i = index" ngbSlide>
                        <div class="pic-img-wrapper">
                            <img class="preview" [src]="isBase64Image(item?.base64Resource)" [alt]="'Slide ' + (i + 1)" />
                        </div>
                    </ng-template> 
                </ng-container>
            </ngb-carousel>
        </div>
    </div>
</div>
