export class ProudctOrderCheckout {
   orderSummary = orderSummary;
   tax = new ProductTax();

}

const orderSummary = [
    {
        isCheck: true,
        productUrl:"https://devapi.jewelsteps.com/Uploads/JewelryCatalogue/JewelryImages/e820fbcb-ebea-42e2-824c-c1a741be071a.jpg",
        productTitle:"A Swish and Dazzle Necklace",
        shortDesc : "Mesmerize everyone with the sleek elegance of this Necklace",
        qty: 1,
        total :"30000.00"
    },
    {
        isCheck: true,
        productUrl:"https://devapi.jewelsteps.com/Uploads/JewelryCatalogue/JewelryImages/e820fbcb-ebea-42e2-824c-c1a741be071a.jpg",
        productTitle:"A Swish and Dazzle Necklace",
        shortDesc : "Mesmerize everyone with the sleek elegance of this Necklace",
        qty: 1,
        total :"30000.00"
    },
    {
        isCheck: true,
        productUrl:"https://devapi.jewelsteps.com/Uploads/JewelryCatalogue/JewelryImages/e820fbcb-ebea-42e2-824c-c1a741be071a.jpg",
        productTitle:"A Swish and Dazzle Necklace",
        shortDesc : "Mesmerize everyone with the sleek elegance of this Necklace",
        qty: 1,
        total :"30000.00"
    },
    {
        isCheck: true,
        productUrl:"https://devapi.jewelsteps.com/Uploads/JewelryCatalogue/JewelryImages/e820fbcb-ebea-42e2-824c-c1a741be071a.jpg",
        productTitle:"A Swish and Dazzle Necklace",
        shortDesc : "Mesmerize everyone with the sleek elegance of this Necklace",
        qty: 1,
        total :"30000.00"
    },
    {
        isCheck: true,
        productUrl:"https://devapi.jewelsteps.com/Uploads/JewelryCatalogue/JewelryImages/e820fbcb-ebea-42e2-824c-c1a741be071a.jpg",
        productTitle:"A Swish and Dazzle Necklace",
        shortDesc : "Mesmerize everyone with the sleek elegance of this Necklace",
        qty: 1,
        total :"30000.00"
    }
];

export class ProductTax {
    CGST = 2000.00;
    SGST  = 2000.00;
    IGST  = 2000.00;
}
