import { Injectable } from '@angular/core';
import { Observable, Subject, tap } from 'rxjs';
import Swal, { SweetAlertResult } from 'sweetalert2';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { commonModal, commonModalOptions, commonModalOptionsLg, ModalPopupSize } from '../models/common.model';
import { ErrorPopupComponent } from '../common-components/common-popup/error-popup/error-popup.component';
import { ResponseModel } from '../models/response-model.model';
import { DataService } from './data.service';
import { Ids } from '../components/header/elements/notification/notification.model';
@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  constructor(private modalService: NgbModal) { }

  sucesspopup(message) {
    Swal.fire({
      position: 'top-right',
      width: 350,
      heightAuto: false,
      icon: 'success',
      text: message,
      showConfirmButton: false,
      timer: 1500
    })
  }

  openPopup(component: any, data: any, title: string, popupSize: string = ModalPopupSize.LG, windowClass: string = "" ): Promise<any> {
    commonModal.size = popupSize;
    commonModal.windowClass = windowClass;
    const modalOption: NgbModalOptions = {
      ...commonModal,
    };
    const modalRef = this.modalService.open(component, modalOption);
    if(title != undefined)
      modalRef.componentInstance.modalTitle = title;
    modalRef.componentInstance.data = data;
    this.makeDraggable();
  
    return modalRef.result.then(
      (result) => {
        if (result) {
          return result; // Returning the result back to the calling method
        }
      },
      (reason) => {
        // Handle dismissal case if needed
        return null;
      }
    );
  }

  public makeDraggable() {
    const modalElement = document.querySelector('.modal-dialog') as HTMLElement;
    if (modalElement) {
      let offsetX: number, offsetY: number;
  
      const dragHandle = modalElement.querySelector('.modal-header') as HTMLElement;
      if (dragHandle) {
        dragHandle.addEventListener('mousedown', (e: MouseEvent) => {
          e.preventDefault(); // Prevent default browser behavior
  
          // Calculate initial offsets
          offsetX = e.clientX - modalElement.getBoundingClientRect().left;
          offsetY = e.clientY - modalElement.getBoundingClientRect().top;
  
          // Function to handle mouse movement
          const onMouseMove = (event: MouseEvent) => {
            const newLeft = event.clientX - offsetX;
            const newTop = event.clientY - offsetY;
  
            // Apply the new position, ensuring size is maintained
            // modalElement.style.position = 'absolute';
            modalElement.style.left = `${newLeft}px`;
            modalElement.style.top = `${newTop}px`;
          };
  
          // Function to handle mouse release
          const onMouseUp = () => {
            document.removeEventListener('mousemove', onMouseMove);
            document.removeEventListener('mouseup', onMouseUp);
          };
  
          // Add event listeners for mouse move and release
          document.addEventListener('mousemove', onMouseMove);
          document.addEventListener('mouseup', onMouseUp);
        });
      }
    }
  }
  
  //error with confirmbutton.
  errorpopup(message, title = null) {

    const modalOption: NgbModalOptions = {
      ...commonModalOptions,
      size            : "sm",
    };
    const modalRef = this.modalService.open(ErrorPopupComponent, modalOption);
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.message = message;
    
    // Swal.fire({
    //   title: '',
    //   html: message,
    //   icon: 'error',
    //   width: 350
    // }).then((result) => { });
    // Swal.fire({
    //   title: title,
    //   position: 'center',
    //   text: "",
    //   html: message,
    //   width: 350,
    //   heightAuto: false,
    //   // timer: 1500,
    //   // showConfirmButton: false,
    //   icon: 'error'
    // });
  }
  //
  warningpopup(message) {
    Swal.fire({
      title: '',
      html: message,
      position: 'center',
      width: 350,
      heightAuto: false,
      // timer: 1500,
      // showConfirmButton: false,
      icon: 'warning'
    });
  }
  deletepopup(): Observable<SweetAlertResult> {
    var subject = new Subject<SweetAlertResult>();
    Swal.fire({
      position: 'center',
      text: 'Are you sure you want to delete this item?',
      showDenyButton: true,
      width: 350,
      confirmButtonText: 'Yes',
      denyButtonText: `No`,
    }).then((result) => {
      subject.next(result);
    })
    return subject.asObservable();
  }
  deleteOrReset(type) {
    var subject = new Subject<SweetAlertResult>();
    var deleteTypeString = "";
    var confirmButtonString = "Yes, delete it!";
    switch (type) {
      case 1:
        deleteTypeString = "<h3 style='color:red'>You won't be able to revert this!</h3>";
        break;
      case 2:
        deleteTypeString = "This item will be temporarily deleted"
        break;
      case 3:
        deleteTypeString = "You are going to RESET temporary deleted items"
        confirmButtonString = "Yes, reset it!"
        break;
    }
    Swal.fire({
      title: 'Are you sure?',
      html: deleteTypeString,
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: confirmButtonString,
      cancelButtonText: 'No, cancel!',
      width: 350
    }).then((result) => {
      subject.next(result);
      // if (result.isConfirmed) {
      // } 
      // else if (result.isDenied) 
      //   this.notificationService.warningpopup("Changes are not saved");
    });
    return subject.asObservable();
  }

  openConfirmDialog() {
    var subject = new Subject<SweetAlertResult>();
    Swal.fire({
      // title: 'Proceed without Saving?',
      text: "Do you want to save changes ?",
      icon: 'question',
      showCloseButton: true,
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: 'Save',
      denyButtonText: `Save as draft`,
      customClass: {
        denyButton:'custom-deny-button-class' // Set a custom class for the "Save as draft" button
      },
      // denyButtonColor: '#1492E6', 
      confirmButtonColor: '#3085d6',
      cancelButtonText: 'Cancel!',
      width: 320
    }).then((result) => {
      if (result.isDismissed && result.dismiss === Swal.DismissReason.close) {
        console.log('Close button clicked');
      } else {
        subject.next(result);
      }
    });
    return subject.asObservable();
  }

  openValidationDialog(Title) {
    var subject = new Subject<SweetAlertResult>();
    Swal.fire({
      // title: 'Proceed without Saving?',
      text: Title,
      icon: 'question',
      showCloseButton: true,
      confirmButtonText: 'GO TO SETTING',
      confirmButtonColor: '#3085d6',
      width: 320
    }).then((result) => {
      if (result.isDismissed && result.dismiss === Swal.DismissReason.close) {
        console.log('Close button clicked');
      } else {
        subject.next(result);
      }
    });
    return subject.asObservable();
  }

  openValidationDialogInvo(Title) {
    var subject = new Subject<SweetAlertResult>();
    Swal.fire({
      // title: 'Proceed without Saving?',
      text: Title,
      icon: 'question',
      showCloseButton: true,
      confirmButtonText: 'GO TO INVOICE',
      confirmButtonColor: '#3085d6',
      width: 320
    }).then((result) => {
      if (result.isDismissed && result.dismiss === Swal.DismissReason.close) {
        console.log('Close button clicked');
      } else {
        subject.next(result);
      }
    });
    return subject.asObservable();
  }

  openImportSuccessDialog(msg : string) {
    var subject = new Subject<SweetAlertResult>();
    Swal.fire({
      title: 'File upload process',
      text:msg,
      icon: 'info',
      showDenyButton: false,
      showCancelButton: false,
      confirmButtonText: 'Ok',
      confirmButtonColor: '#3085d6',
      cancelButtonText: 'Cancel!',
      width: 350
    }).then((result) => {
      subject.next(result);
    });
    return subject.asObservable();
  }
  
}



