import { AgColDefsI, AgGridI } from "../../ag-grid/ag-grid.model";
import { TextRendererComponent } from "../../ag-grid/text-renderer/text-renderer.component";




const Description: AgColDefsI = {
    headerName: "Description",
    sortable:true,
    headerClass: "px-1 custom-column-group-cell border-0 f-14 theme-light-voilet-color  font-semibold",
    field: 'description',
    cellClass:'px-1 custom-column-group-cell theme-color-dark-gray', minWidth: 800, flex:2,
    editable:false,
    lockPosition:true,
    suppressMovable: false,
    resizable: false,
    cellRenderer: TextRendererComponent,
    cellRendererParams :{isCursor:true},    
};
const Tax: AgColDefsI = {
    headerName: "Tax", 
    headerComponentParams: {enableMenu:false},
    sortable:true,
    headerClass: "px-1 custom-column-group-cell ag_header_end border-0 f-14 theme-light-voilet-color font-semibold",
    field: 'taxAmount',
    cellClass:'px-1 custom-column-group-cell text-end theme-color-dark-gray', minWidth: 100, flex:2,
    editable:false,
    lockPosition:true,
    suppressMovable: false,
    resizable: false,
    cellRenderer: TextRendererComponent,
    cellRendererParams :{isCursor:true},
};
const Amt: AgColDefsI = {
    headerName: "Amt", 
    headerComponentParams: {enableMenu:false},
    sortable:true,
    headerClass: "px-1 custom-column-group-cell ag_header_end border-0 f-14 theme-light-voilet-color font-semibold",
    field: 'makingAmount',
    cellClass:'px-1 custom-column-group-cell text-end theme-color-dark-gray', minWidth: 100, flex:2,
    editable:false,
    lockPosition:true,
    suppressMovable: false,
    resizable: false,
    cellRenderer: TextRendererComponent,
    cellRendererParams :{isCursor:true},
};
const NetAmt: AgColDefsI = {
    headerName: "Net Amt", 
    headerComponentParams: {enableMenu:false},
    sortable:true,
    headerClass: "px-1 custom-column-group-cell ag_header_end border-0 f-14 theme-light-voilet-color font-semibold",
    field: 'netAmount',
    cellClass:'px-1 custom-column-group-cell text-end theme-color-dark-gray', minWidth: 100, flex:2,
    editable:false,
    lockPosition:true,
    suppressMovable: true,
    resizable: false,
    cellRenderer: TextRendererComponent,
    cellRendererParams :{isCursor:true},
};

const BillOfMaterialColumnDef: AgColDefsI[] = [
    Description,
    Tax,
    Amt,
    NetAmt
];

export const AgGridDataModelBillOfMaterial: AgGridI = {
  colDefs: BillOfMaterialColumnDef,
  rowSelection: "multiple",
  // storageName : StorageName.ADMINISTRATION_USER_MANAGEMENT_ROLES_GRID,
  rowHeight:28,
}