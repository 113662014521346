<ng-container >
    <div class="modal-body pt-4 pb-4">
        <div class="row">
            <div class="col-12 ">   
                <div class="d-flex justify-content-center">
                    <p class="theme-color-secondary f-14 w-p-200 mt-1 text-center font-regular mb-0">Are you sure you want to  {{callApi}}</p>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer d-block">
        <div class="row m-0">
            <div class="col-6 border-right d-flex justify-content-center align-items-center">
                <label class="f-14 font-semibold cursorPointer py-2 mb-0 text-gray-color" (click)="activeModal.close(false)">Cancel</label>
            </div>
            <div class="col-6 d-flex justify-content-center align-items-center">
                <label class="f-14 font-semibold cursorPointer py-2 text-nowrap mb-0 text-danger" (click)="onDelete()"> {{callApi}}</label>
            </div>
        </div>
    </div>
</ng-container>