import { Injectable } from '@angular/core';
import { DataService } from '../../shared/services/data.service';
import { ResponseModel } from '../../shared/models/response-model.model';
import { Observable, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProductService {
  public TransactionTypedata: number;
  constructor(public service:DataService) { }
  
  getAllProductDropdown(formName:string){
    let url = `api/app/dropdown/getalldropdowns?FormName=${formName}`;
    return this.service.get(url,true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

  getProductTaxByProductDetailId(id:string){
    let url = `api/app/product/getProductTaxByProductDetailId?Id=${id}`;
    return this.service.get(url,true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

  getsamplefilebypagename(data):Observable<ResponseModel>{
    let url = "api/app/samplefile/getsamplefilebypagename";
    const json =JSON.stringify(data)
    return this.service.post(url, data,true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }
}
