import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { cjAppMainUrl, cjBusinessRegistrationLogoPath, cjDarkLogoPath, cjHeaderLogoPath, cjLightLogoPath, cjLoginLogoPath, jsBusinessRegistrationLogoPath, jsHeaderLogoPath, jsLightLogoPath, jsLoginLogoPath } from '../utils/common-utils';
import { Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class LayoutService {

  public config = {
    settings: {
      layout: 'horizontal-wrapper',
      layout_type: 'box-layout',
      layout_version: 'light-only',
      sidebar_type: 'default-sidebar',
      icon: 'stroke-svg'
    },
    color: {
      primary_color: '#7366ff', 
      secondary_color: '#f73164'
    }
  }

  //#region  dynamic set svg path variable
  logoLogin  :string;
  logoMain  :string;
  logoHeader :string;
  logoBusinessRegistrationPage;
  displayDefaultCompanyIcon;

  //#endregion

  mainUrl;
  providedCompanyName;
  hideContent = false;

  constructor(private titleService: Title) { 
    if(this.config.settings.layout_type == 'rtl')
      document.getElementsByTagName('html')[0].setAttribute('dir', this.config.settings.layout_type);

    document.documentElement.style.setProperty('--theme-deafult', this.config.color.primary_color);
    document.documentElement.style.setProperty('--theme-secondary', this.config.color.secondary_color);

//#region url wise assing svg path and company name
    const{host , origin}= window.location;
    if(origin == cjAppMainUrl){
        this.hideContent = true
        document.documentElement.style.setProperty('--providedCompanyName', "'Canadian Jewellers'");
        document.documentElement.style.setProperty('--login-bg-image-url',"url('assets/images/login/cj-background-login-img.png')");
        this.providedCompanyName = "Canadian Jewellers";
        this.logoLogin  = cjLoginLogoPath;
        this.logoMain   = cjDarkLogoPath;
        this.logoHeader =  cjHeaderLogoPath;
        this.logoBusinessRegistrationPage = cjBusinessRegistrationLogoPath;
        this.displayDefaultCompanyIcon = cjHeaderLogoPath;
        this.titleService.setTitle("Canadian Jewellers");
    } else {
      this.hideContent = false;
        document.documentElement.style.setProperty('--providedCompanyName', "'Jewelsteps'");
        document.documentElement.style.setProperty('--login-bg-image-url',"url('assets/images/login/js-background-login-img.png')");
        this.providedCompanyName = "Jewelsteps";
        this.logoLogin  = jsLoginLogoPath;
        this.logoMain   = jsLightLogoPath;
        this.logoHeader =  jsHeaderLogoPath;
        this.logoBusinessRegistrationPage = jsBusinessRegistrationLogoPath;
        this.displayDefaultCompanyIcon = jsHeaderLogoPath;
        this.titleService.setTitle("Jewelsteps");
    }
  }
  //#endregion

}
