import { Directive, ElementRef, HostBinding, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[onlyNumbers]'
})
export class OnlyNumbersDirective {

  // Working

  @HostBinding('autocomplete') public autocomplete = 'off';
  @Input() allowDecimal: boolean = true; // Add a flag to control the decimal separator
  
  constructor(private el: ElementRef) {
    this.autocomplete = 'off';
  }
  
  @HostListener('keypress', ['$event']) public disableKeys(e) {
    document.all ? e.keyCode : e.keyCode;
    return (e.keyCode == 8 || (e.keyCode >= 48 && e.keyCode <= 57));
  }
  @HostListener('paste', ['$event']) public onPaste(e) {
    e.preventDefault();
    const clipboardData = e.clipboardData || (e.originalEvent && e.originalEvent.clipboardData);
    const pastedText = clipboardData.getData('text/plain').replace(/[^0-9]/g, '');
    document.execCommand('insertText', false, pastedText);
  }

  @HostListener('input', ['$event']) public onInput(e) {
    const input = e.target as HTMLInputElement;
    input.value = input.value.replace(/[^0-9]/g, '');
  }
}

// First Code (Not Worked)---------------------------------------------------------------

  // @HostBinding('autocomplete') public autocomplete;

  // constructor() {
  //   this.autocomplete = 'off';
  // }

  // @HostListener('keypress', ['$event']) public disableKeys(e) {
  //   document.all ? e.keyCode : e.keyCode;
  //   return (e.keyCode == 8 || (e.keyCode >= 48 && e.keyCode <= 57));
  // }
  
  // @HostListener('paste', ['$event']) public onPaste(event: any) {
  //   // Prevent default paste behavior
  //   event.preventDefault();

  //   // Get pasted text
  //   const pastedText = event.clipboardData.getData('text/plain');

  //   // Allow only numbers in the pasted text
  //   const onlyNumbers = pastedText.replace(/[^\d]/g, '');

  //   // Set the input value to only numbers
  //   document.execCommand('insertText', false, onlyNumbers);
  // }
 

// Second Code (Not Worked)---------------------------------------------------------------

  // @HostListener('keydown', ['$event']) public onKeyDown(e: KeyboardEvent) {
  //   const decimalSeparator = (1.1).toLocaleString().charAt(1);
  //   const value: string = this.el.nativeElement.value;
  //   const hasDecimalSeparator = value?.includes(decimalSeparator);
  
  //   // Allow backspace, delete, tab, escape, enter, numeric keys, and one decimal separator if allowed
  //   if (
  //     e.key === 'Backspace' || e.key === 'Delete' || e.key === 'Tab' ||
  //     e.key === 'Escape' || e.key === 'Enter' || 
  //     (!e.shiftKey && !e.altKey && !e.ctrlKey && '0123456789'.indexOf(e.key) !== -1) ||
  //     (this.allowDecimal && e.key === decimalSeparator && !hasDecimalSeparator)
  //   ) {
  //     return; // Let it happen, don't do anything
  //   }
  //   // Prevent the rest
  //   e.preventDefault();
  // }
  
  // @HostListener('paste', ['$event']) public onPaste(event: ClipboardEvent) {
  //   event.preventDefault();
  //   const pastedText = (event.clipboardData || window['clipboardData']).getData('text/plain');
  //   const decimalSeparator = (1.1).toLocaleString().charAt(1);
  //   const regex = this.allowDecimal 
  //     ? new RegExp(`[^0-9${decimalSeparator}]`, 'g')
  //     : new RegExp(`[^0-9]`, 'g'); // Adjust the regex based on the flag
  //   const onlyNumbersAndDecimal = pastedText.replace(regex, '');
  
  //   // Prevent multiple decimal separators if allowed
  //   const parts = onlyNumbersAndDecimal.split(decimalSeparator).filter(Boolean);
  //   const sanitizedText = parts.length > 1 && this.allowDecimal
  //     ? `${parts.shift()}${decimalSeparator}${parts.join('')}`
  //     : onlyNumbersAndDecimal;
  
  //   // Insert the modified text into the input
  //   const inputEl: HTMLInputElement = this.el.nativeElement;
  //   const start = inputEl.selectionStart;
  //   const end = inputEl.selectionEnd;
  //   inputEl.value = inputEl.value.slice(0, start) + sanitizedText + inputEl.value.slice(end);
  //   inputEl.dispatchEvent(new Event('input')); // To ensure Angular's forms API updates its state
  // }
  


