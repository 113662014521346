import { Component, PLATFORM_ID, Inject } from '@angular/core';
// import { isPlatformBrowser } from '@angular/common';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { map, delay, withLatestFrom } from 'rxjs/operators';
import { DataService } from './shared/services/data.service';
import { HttpClient } from '@angular/common/http';
import { FileReaderService } from 'src/app/shared/services/file.reader.service';
import { StorageName } from './shared/enum/common-enum';
import { StorageService }   from 'src/app/shared/services/storage.service';
import { environment } from 'src/environments/environment';
import { LayoutService } from './shared/services/layout.service';
import { DOCUMENT } from '@angular/common';
import { NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { commonModalOptions } from './shared/models/common.model';
import { TranslateService } from '@ngx-translate/core';
// import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  ipAddress: any;
  // For Progressbar
  loaders = this.loader.progress$.pipe(
    delay(1000),
    withLatestFrom(this.loader.progress$),
    map(v => v[1]),
  );
  dynamicFaviconUrl: string | undefined;
  constructor(@Inject(PLATFORM_ID) private platformId: Object,
  private layoutService: LayoutService, @Inject(DOCUMENT) private document: Document,
  private loader: LoadingBarService, public storageService: StorageService, public dataService:  DataService, private fileReaderService: FileReaderService,
  private translate: TranslateService,
) {
    this.translate.setDefaultLang('en');
    this.translate.use('en');
  }
  ngOnInit(): void {
    // const filePath = 'assets/base-urls/environment.json';
   

    // this.fileReaderService.getBaseUrlFromFile(filePath)
    // .then(config => {
    //   environment.baseUrl = config.apiBaseUrl;
    //   // Assuming 'environment' is a global object where you want to store the base URL
    //   // (window as any).environment = { baseUrl: config.apiBaseUrl };
    //   console.log('Base URL assigned:', config.apiBaseUrl);
    // })
    // .catch(error => {
    //   console.error('Error:', error);
    // });

    const url = this.storageService.retrieve(StorageName.IP_ADDRESS);
    if(url) environment.baseUrl = url;

    // for change url icon dyanamic for index.html
    this.setFavicon(this.layoutService?.displayDefaultCompanyIcon);
  }

  setFavicon(url: string | undefined): void {
    const favicon = this.document.querySelector("link[rel*='icon']") as HTMLLinkElement;
    if (favicon) {
      favicon.href = url;
    }
  }



}
