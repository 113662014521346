import { Component, OnInit, Renderer2, ViewChild } from '@angular/core';
import { CommonService } from '../../services/common.service';
import { NgForm } from '@angular/forms';
import { AdministrationService } from 'src/app/components/administration/administration.service';
import { ToastrService } from 'ngx-toastr';
import { catchError } from 'rxjs';
import { GetAllColorResponseModel } from 'src/app/components/administration/administration.model';
import { SendDataService } from '../../services/send-data.service';

@Component({
  selector: 'app-color',
  templateUrl: './color.component.html',
  styleUrls: ['./color.component.scss']
})
export class ColorComponent extends CommonService implements OnInit{
  isAdd                           : boolean = false;
  getAllColorData              : any[]=[];

  copyGetAllColorData          : any[]=[];
  editColorData                = new GetAllColorResponseModel();
  @ViewChild('administrationFormData') administrationFormData! : NgForm ;

constructor(
  public renderer:Renderer2,
  public administrationService : AdministrationService,
  public toaster: ToastrService,
  public sendDataService : SendDataService,
) 
{
  super(renderer)
}
ngOnInit(): void {
  this.getAllColorList();
  }

add(){
  if(!this.sendDataService?.permissionVM?.mastersCanAdd) return;
  this.isAdd = true;
    const obj = {id:0, name:'',active:true}
    this.getAllColorData?.push(obj);
    this.copyGetAllColorData?.push(obj);
    setTimeout(()=>{
      const element = document.getElementById(`colorname${obj.id}`);
      element?.focus();
    },0)
}

cancel(){
  if(this.isAdd)
  {
    this.isAdd = false;
    this.getAllColorData = this.getAllColorData.filter((x)=>x.id != 0);
    this.copyGetAllColorData = this.copyGetAllColorData.filter((x)=>x.id != 0);
  }
  else if(this.editColorData?.id && this.editColorData?.isEdit){
    const index = this.copyGetAllColorData.findIndex((x)=>x.id == this.editColorData?.id);
    const singleData =JSON.parse(JSON.stringify(this.copyGetAllColorData))[index];
    this.getAllColorData[index] = singleData;
    this.editColorData = new GetAllColorResponseModel();
  }  
}

edit(item,formData){
  if(!this.sendDataService?.permissionVM?.mastersCanUpdate) return;
  item.isEdit = true;
  const controlObj = formData?.form?.controls;
  if(controlObj){
    const id = Object.keys(controlObj)[0];
    const element = document.getElementById(id);
    element?.focus();
  }
  this.editColorData = {...item};
}


checkIsEdit(arr:any[]):boolean{
  return arr?.some((x)=>x.isEdit)
}


    // *********** LOCATION API START *********
    // **********get all location *********
    getAllColorList() {
      this.administrationService.getAllColor().subscribe({
        next:(res)=>{
          if(res.isSuccess){
            this.editColorData = new GetAllColorResponseModel();
            this.getAllColorData = res.response??[];
            this.copyGetAllColorData = JSON.parse(JSON.stringify(this.getAllColorData))??[];
          }else{           
            this.getAllColorData = [];  
            this.copyGetAllColorData = [];
          }  
        },
        error:(err)=>{
          this.getAllColorData = [];  
          this.copyGetAllColorData = [];
        }
      })
    }
    
    
       // *************insert location **************
       InserColorList(item) {
        const {id , ...rest}= item;
        this.administrationService.insertColor(rest)
            .pipe(catchError((err) => this.handleError(err)))
            .subscribe((res) => {
              if(res.isSuccess){
                this.isAdd = false;
                this.getAllColorList();
                this.toaster.success(res.message);
              }else{
                this.toaster.error(res.errors[0]);
              }  
          });
        }
    
    // **********update Location *********
    UpdateColorList(item) {
      const {isEdit , ...rest} = item;
      this.administrationService.updateColor(rest)
          .pipe(catchError((err) => this.handleError(err)))
          .subscribe((res) => {
            if(res.isSuccess){
              this.isAdd = false;
              this.getAllColorList();
              this.toaster.success(res.message);
            }else{
              this.toaster.error(res.errors[0]);
            }  
        });
      } 

      // **********delete Location *********
      DeleteColorList(id) {
      if(!this.sendDataService?.permissionVM?.mastersCanDelete) return;
        this.administrationService.deleteColor(id)
            .pipe(catchError((err) => this.handleError(err)))
            .subscribe((res) => {
              if(res.isSuccess){
                this.getAllColorList();
                this.toaster.success(res.message);
              }else{
                this.toaster.error(res.errors[0]);
              }  
        });
      }
          
}
