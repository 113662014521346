import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';

@Component({
  selector: 'app-input-with-icon',
  templateUrl: './input-with-icon.component.html',
  styleUrls: ['./input-with-icon.component.scss'],
  viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ],
})
export class InputWithIconComponent {

  @Input() decimalPlaces: number =0; // Default to 0, indicating no decimal places by default
  @Input() isOnlyNumbers: boolean = false; // Default to false, indicating no onlyNumbers directive by default
  @Input() applyDecimalOrPercent: boolean = false; 
  @Input() isPercentApplicable: boolean = false; 
  @Input() notNagativeDecimalNumber:boolean = false;

  @Input() inputType:string='text';
  @Input() inputName:string;
  @Input() inputPlaceholder:string='';
  @Input() inputLabel:string;
  @Input() inputId:string;
  @Input() inputValue:string='';
  @Input() isRequired:boolean;
  @Input() inputMaxlength:number;
  @Input() inputClass:string='';
  @Input() disabled :boolean;
  @Input() readOnly: boolean=false;
  @Input() isPercentIcon: boolean=false;

  @Output() inputValueEmit:EventEmitter<string> = new EventEmitter();
  @Output() keydownEmit:EventEmitter<string> = new EventEmitter();
  
  

  constructor() { }

  ngOnInit(): void {
  }

}
