import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { IconHover } from '../../../enum/common-enum';

@Component({
  selector: 'app-call-api-confirm',
  templateUrl: './call-api-confirm.component.html',
  styleUrls: ['./call-api-confirm.component.scss']
})
export class CallApiConfirmComponent {

  currentHoverEffect = IconHover.Pink;
  @Input() callApi: string = '';  
  

  constructor(public activeModal:NgbActiveModal){
  }

  onDelete(){
    this.activeModal.close(true);
  }
}
