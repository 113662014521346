import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ResponseModel } from '../../../shared/models/response-model.model';
import { DataService }  from '../../../shared/services/data.service'
import { GetOrderTrackingDetail } from './order-tracking.model';

@Injectable({
  providedIn: 'root'
})
export class OrderTrackingService {

  constructor(private service: DataService) { }

  getTimeline(applicantId:number): Observable<ResponseModel> {
    let url = ''+ applicantId;
    return this.service.get(url).pipe<ResponseModel>(tap((response: any) => {
    return response;
    }));
  }
  getOrderTrackingDetail(model:GetOrderTrackingDetail): Observable<ResponseModel> {
    let url = 'api/app/ordertracking/getordertrackingdetails';
    return this.service.post(url,model).pipe<ResponseModel>(tap((response: any) => {
      return response;
    }));
  
  }

}


