import { Component, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { DropdownModel, GetRegionalInformationResponse, RegisterUserRequestModel, RegisteredUserVM } from '../business-registration.model';
import { CommonService } from 'src/app/shared/services/common.service';
import { BusinessRegistrationService } from '../business-registration.service';
import { LayoutDetails, User } from '../../auth.model';
import { StorageService } from 'src/app/shared/services/storage.service';
import { StorageName } from 'src/app/shared/enum/common-enum';
import { DatePipe } from '@angular/common';
import { DataService } from 'src/app/shared/services/data.service';
import { NgForm } from '@angular/forms';


@Component({
  selector: 'app-company-registration',
  templateUrl: './company-registration.component.html',
  styleUrls: ['./company-registration.component.scss'],
  providers:[DatePipe]
})
export class CompanyRegistrationComponent extends CommonService {
 @Input("user") usermodel : RegisteredUserVM =  new RegisteredUserVM();
 @Input() isCridAvailabilityCheck;
 uploadImageUrl = "./assets/SVGicon/cam-2.svg";
 @Input() fromAddCompany;
 formName = StorageName.FORMNAME_BUSINESSREGISTRATION;
 storageName = StorageName;
 @Input() getAllDropdownModel : any = {}; 
 filterCountryWise: any;
 @ViewChild("CompanyForm") CompanyForm!: NgForm;
 constructor(private datePipe: DatePipe,public renderer: Renderer2, private businessRegistrationService : BusinessRegistrationService , public storageService :StorageService,)
 {
  super(renderer);
 }

  submitCompany :boolean=false;
  isRightAndCloseIcone :boolean=true;

  countryList =[];
  stateList:DropdownModel[] = []
  districList:DropdownModel[] = [];
  getRegionalInformationResponse = new GetRegionalInformationResponse();
  generatedGuidId ;

  timerId;
  user:User;
  layoutDetails:LayoutDetails;


  ngOnInit(): void {
    // this.getRegionaldetails();
    this.generatedGuidId = this.generateShortID();
   
    this.layoutDetails =this.storageService.retrieve('layoutDetails');
    if(this.layoutDetails?.isUserSuperAdmin) this.usermodel.crid = this.storageService.retrieve(StorageName.COMPANY_REGISTRATION_ID);

    // this.usermodel.registrationDate = new Date().toISOString().split('T')[0];
  }
  selectDate(event){
    this.usermodel.registrationDate = event;
  }
  
  clearImage() {
    this.usermodel.companyLogo = null;
    this.uploadImageUrl = null;
    const fileInput = document.getElementById('upload_logo') as HTMLInputElement;
    if (fileInput) {
      fileInput.value = '';
    }
  }
  

  handleFile(event) 
  {
    var binaryString = event.target.result;
    this.uploadImageUrl = btoa(binaryString);
  }


  onChangeImage(e) {
    const file = e.target.files[0];
    if (file) {
      if (file.type.startsWith('image/') && !file.name.toLowerCase().endsWith('.gif')) {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (event: any) => {
          this.uploadImageUrl = event.target.result;
          this.usermodel.companyLogo = this.uploadImageUrl;
        }
      } else {
        this.toaster.error('Please select a valid image');
      }
    }
  }


  // getRegionaldetails()
  // {
  //   this.businessRegistrationService.GetRegionaldetails().subscribe((res) =>{
  //     if(res.isSuccess)
  //   {
  //     this.getRegionalInformationResponse = res?.response;
  //     this.districList =  this.getRegionalInformationResponse?.districtList;
  //     this.stateList = this.getRegionalInformationResponse?.stateList;
  //     this.usermodel.country = this.getRegionalInformationResponse.countryName;
  //     this.usermodel.countryId = this.getRegionalInformationResponse.countryId;
      
      
  //   }
  //   })
  // } 
  // getDistricName(id){
  //   this.usermodel.district = this.districList.find(item => item.id == id).name;
  // }
 
  getStateName(id){
    this.usermodel.state = this.getAllDropdownModel?.stateDropdown?.find(item => item?.id == id).name;
  }

  //#region  generate guid id and availability

  generateShortID(): string {
    const pattern: string = 'xxxxxx';
    return pattern.replace(/x/g, function() {
      const r = Math.random() * 16 | 0;
      console.log('generateShortID',r.toString(16).toUpperCase());
      
      return r.toString(16).toUpperCase();
      // .toUpperCase();
    });
  }


  getStartingCharacters(input: string) {
    if (!input) {
        return;
    }
    const words = input.split(' ').filter(word => word.length > 0);
    if (words.length < 3) {
        const firstChars = words.map(word => word.charAt(0)).join('');
        this.usermodel.crid = (firstChars + this.generatedGuidId).toUpperCase();
    }
}

  checkIsCridAvailable(crid){
    clearTimeout(this.timerId);
    this.timerId = setTimeout(() => {
      this.isCridAvailabilityCheck.count++;
      console.log(this.isCridAvailabilityCheck.count , 'dfdf')
      this.businessRegistrationService.checkcridavailability(crid).subscribe({
        next:(res)=>{
          if(res?.isSuccess){
            this.isCridAvailabilityCheck.isCridAvailable = res?.response?.onboardingRequired
          }
          console.log(res)
        },error:(err)=>{
          console.log(err)
        }
      })
    }, 1000);
  }

  //#endregion
  
   onChangeCountryName(id){
    this.getAllDropdownModel.stateList = this.getAllDropdownModel?.stateDropdown?.filter((x)=>x?.extra1 == id);
    this.usermodel.country = this.getAllDropdownModel?.countryDropdown?.find(item => item?.id == id)?.name;
   }
}
