<div>
    <div *ngIf="isShowHeader" class="d-flex align-items-center justify-content-between"> 
       <div> 
            <h5 class="text-primary font-semibold f-14 mb-0 p-2">
                {{headerText}} <span class="text-pink">{{formModel?.balanceResult}}</span>
                <span *ngIf="fundTransferAmount" class="ms-1 text-underline cursorPointer text-primary font-semibold f-14" (click)="openFundTransferPopup()">{{formModel?.fundTransferAmountResult}}</span>
            </h5>
           
        </div>
        <div *ngIf="enableAdding" class="d-flex justify-content-end align-items-center py-1 gap-2">
            <div class="mb-0">
                <span class="text-pink f-16"> {{formModel?.balanceDescription}}</span> 
            </div>
            <app-button class="" [buttonLabel]="'Add Diamond & Stones'" (buttonOutputEvent)="openAddDiamondPopup('Add Diamond & Stones')" [buttonClass]="'blueText_white_bg_button'" [buttonType]="'button'">
            </app-button>
        </div>
    </div>
  
    <div class="position-relative"  >
        <div class=""*ngFor="let item of tabGroupList" >
        <!-- payment_list_table -->
        <app-ag-grid
        id="payment"
        [class]="'all_aggrid_tables position-relative d-block '+ gridDynamicHeightClass" 
                [dataModel]="item.dataModel"
                (cellValueChanged)="onCellValueChange($event)"
                [addBlankRowOnEnter]="true"
                [tableRowCellEditable]="false"
                [rowHeight]="rowHeight"
                [headerHeight]="headerHeight"
                (gridReady)="onGridReady($event)"
                [columnSettings]="true"
                (onDeleteIconClick)="onDeleteIconClick($event)"
                >
        </app-ag-grid>       
        </div>        
    </div>
    <div class="row d-flex flex-fill" *ngIf="isShowFooterRow">
        <div class="col-12  transactiontable">
            <table class="table table-bordered">
                <tbody>
                    <tr class="d-flex align-items-center p-0 tabel-bg-color border-bottom">
                        <td width="75%" class="text-start {{bold?'font-bold':''}} color-dark-gray font-semibold border-bottom-0 border-right-0 border-top-0 tabel-bg-color"> Total</td>
                        <td width="25%" class="text-center {{bold?'font-bold':''}} border-left-0 color-dark-gray font-regular border-bottom-0 border-top-0 px-0 tabel-bg-color"> {{TotalAmount?.toFixed(2)}} </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>