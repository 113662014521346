export class OrderTracking {
    
}
    export class GetOrderTrackingDetail {
        voucherTypeId : number  = 0;
        id            :  number = 0;
    }
    
export enum OrdetTrackingStatus {
    "Completed" = 1,
    "InProcess" = 2,
    "Withdrawn" = 3,
    "NotStarted" = 4,
    "pending"  = 5
}
export class TimelineVM {
    id: number;
    applicantId: number;
    stageId: number;
    stageName: string;
    stageStatusId: number;
    startDate: string | null;
    completionDate: string | null;
    isCurrent: boolean;
}

export class Timeline {
    stage1: TimelineVM;
    stage2: TimelineVM;
    stage3: TimelineVM;
    stage4: TimelineVM;
    stage5: TimelineVM;
    progressBarValue: number;
}
export class OrderDetails {
    orderNo :string;
    customerName :string;
    statusId :number;
    statusName :string;
    orderDate :string;
    deliveryDate :string;

}
export class JobOrderDetails {
    orderNo :string;
    customerName :string;
    assignTo :string;
    orderDate :string;
    deliveryDate :string;

}
export class OrderDetail {
    orderDetails :OrderDetails;
    jobOrderDetails :JobOrderDetails;
    imageUrls: any[];
    orderFlow: any[];
}

export enum JobWorkStatus
 {
//    [Description("Not Initiate")]
     NotInitiate = 0,

    // [Description("Pending")]
    Pending = 10,

    // [Description("Completed")]
    Completed = 20,

//    [Description("Processing")]
    Processing = 30
 }