import { Component, Input, Renderer2, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonService } from 'src/app/shared/services/common.service';

import { ToastrService } from 'ngx-toastr';
import { MastersService } from '../../masters/masters.service'
import { ResponseModel } from 'src/app/shared/models/response-model.model';
import { catchError } from 'rxjs';
import { MasterModalTitle, CompanyDropdownModelList, StateDropdownModelList, GetDistrictData, BranchMainModel, CreateUpdateBranchRequestModel, CloneDataModel } from '../../masters/masters.model';
import { CommonOperationModel } from 'src/app/shared/models/common.model';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { StorageService } from 'src/app/shared/services/storage.service';
import { StorageName } from 'src/app/shared/enum/common-enum';
import { log } from 'console';
import { LayoutDetails } from 'src/app/auth/auth/auth.model';
import { SendDataService } from 'src/app/shared/services/send-data.service';
import { IconHover } from '../../../../shared/enum/common-enum';

@Component({
  selector: 'app-branch-details',
  templateUrl: './branch-details.component.html',
  styleUrls: ['./branch-details.component.scss'],
  
})
export class BranchDetailsComponent extends CommonService{
  // @Input() editBranchDetails: CreateUpdateBranchRequestModel;
  currentHoverEffect = IconHover.Pink;
  @Input() editBranchDetails: any;
  @Input() modalTitle       : string;
  @Input() getAllDropdownModel : any = {};
  masterModalTitle = MasterModalTitle;
  @ViewChild("AddEditBranchForm") AddEditBranchForm!: NgForm;
  createUpdateBranchRequestModel = new CreateUpdateBranchRequestModel();
  cloneDataModel                 = new CloneDataModel();
  branchMainModel = new BranchMainModel();
  getDistrictData : any[]=[];
  getTalukaData : any[]=[];
  storageName=StorageName;
  public selectedTabs: string[] = []; 
  public cloneTableNames: string[] = [];
  layoutDetails= new LayoutDetails();
  filterCountryWise: any; 
  constructor(
    public activeModal: NgbActiveModal,
    public renderer: Renderer2,
    public mastersService: MastersService,
    public toaster: ToastrService,
    public storageService:StorageService ,
    public sendDataService:SendDataService ,
  ) {
    super(renderer);
  }
  ngOnInit() {
    this.layoutDetails =this.storageService.retrieve('layoutDetails');
  }

  fillDropdownData(){
    this.getAllDropdownModel.countryDropdown = this.storageService.retrieve(StorageName.COUNTRY_DROPDOWN);
    this.getAllDropdownModel.stateDropdown = this.storageService.retrieve(StorageName.STATE_DROPDOWN);
    this.getAllDropdownModel.districtDropdown = this.storageService.retrieve(StorageName.DISTRICT_DROPDOWN);
    this.getAllDropdownModel.talukaDropdown  = this.storageService.retrieve(StorageName.TALUKA_DROPDOWN);
    this.getAllDropdownModel.mastersDropdown  = this.storageService.retrieve(StorageName.MASTERS_DROPDOWN);
    
    if (this.editBranchDetails?.id) {
      this.createUpdateBranchRequestModel = this.editBranchDetails;
      const countryId = this.getCountryId(this.editBranchDetails?.stateId);
      this.createUpdateBranchRequestModel.countryId = countryId;
      this.onChangeCountryName(this.createUpdateBranchRequestModel?.countryId)
      this.getDistrictByStateID(true);
      this.getTalukaByDistrictID(true);
    } else {
      const countryId = this.getCountryId(this.layoutDetails?.stateId);
      this.createUpdateBranchRequestModel.countryId = countryId;
      this.createUpdateBranchRequestModel.stateId = this.layoutDetails?.stateId;
      this.onChangeCountryName(this.createUpdateBranchRequestModel?.countryId)
    }
  }

  getCountryId(stateId){
    const countryId = this.getAllDropdownModel?.stateDropdown?.find((x)=>x?.id == stateId)?.extra1;
    return countryId
  }

  addEditBranch() {
    if(!(this.createUpdateBranchRequestModel?.id? this.sendDataService?.permissionVM?.mastersCanUpdate : this.sendDataService?.permissionVM?.mastersCanAdd)) return;
    if (this.AddEditBranchForm.valid) {
      if (this.editBranchDetails?.id) {
        this.updateBranch();
      } else {
        this.createBranch();
      }
    }
  }

  createBranch() {
    this.branchMainModel.branchModel = this.createUpdateBranchRequestModel;
    this.branchMainModel.cloneDataModel = this.cloneDataModel;
    this.branchMainModel.cloneDataModel.cloneTableNames = this.cloneTableNames;
  
    this.mastersService.insertBranch(this.branchMainModel)
      .pipe(catchError((err) => this.handleError(err)))
      .subscribe((res: ResponseModel) => {
        if (res.isSuccess) {
          this.activeModal.close(res.isSuccess);
          this.toaster.success(res.message);
        } else {
          this.toaster.error(res.errors[0]);
        }
      });
  }
  

  updateBranch() {
    this.branchMainModel.branchModel = this.createUpdateBranchRequestModel;
    this.mastersService.updateBranch(this.branchMainModel).pipe(catchError((err) => this.handleError(err)))
      .subscribe((res: ResponseModel) => {
        if (res.isSuccess) {
          this.activeModal.close(res.isSuccess);
          this.toaster.success(res.message);
        } else {
          this.toaster.error(res.errors[0]);
        }
      });
  }

  onChangeCountryName(id){
    this.filterCountryWise = this.getAllDropdownModel?.stateDropdown?.filter((x)=>x?.extra1 == id);
  }
  getDistrictByStateID(isOnInit?:boolean) {
     this.getDistrictData=[];           
    const requestData = String( this.createUpdateBranchRequestModel?.stateId)
          this.getDistrictData = this.getAllDropdownModel?.districtDropdown.filter((x)=>x.extra1 == requestData);
        if(isOnInit == false){
          this.createUpdateBranchRequestModel.districtId = undefined;
          this.createUpdateBranchRequestModel.talukaId = undefined;
        }
    }

    getTalukaByDistrictID(isOnInit?:boolean) {    
      this.getTalukaData=[];  
      const requestData = String( this.createUpdateBranchRequestModel?.districtId)
            this.getTalukaData = this.getAllDropdownModel?.talukaDropdown.filter((x)=>x.extra1 == requestData);
          if(isOnInit == false){
            this.createUpdateBranchRequestModel.talukaId = undefined;
          }
      }

      selectTab(tabName: string, ExtraTab: any): void {
        const extraTabArray = ExtraTab.split(',').map(item => item.trim());
        const tabsArray = [tabName, ...extraTabArray].filter(item => item !== '');
        const index = this.selectedTabs.indexOf(tabName);

        if (index === -1) {
            this.selectedTabs.push(...tabsArray);
            this.cloneTableNames.push(...tabsArray);
            console.log(this.cloneTableNames);
        } else {
            this.selectedTabs.splice(index, tabsArray.length);
            this.cloneTableNames.splice(index, tabsArray.length);
        }
    }
    
    ResetBranchDetails(){
      this.createUpdateBranchRequestModel  = new CreateUpdateBranchRequestModel();
      this.cloneDataModel                 = new CloneDataModel();
      this.cloneTableNames =[];
      this.selectedTabs =[];
      
    }
    
}
