import { Component, Input } from '@angular/core';
import { RegisterUserRequestModel, RegisteredUserVM } from '../business-registration.model';

@Component({
  selector: 'app-bank-details-registration',
  templateUrl: './bank-details-registration.component.html',
  styleUrls: ['./bank-details-registration.component.scss']
})
export class BankDetailsRegistrationComponent {
  @Input("user") usermodel : RegisteredUserVM =  new RegisteredUserVM();
}
