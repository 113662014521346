import { NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Component, EventEmitter, OnInit, Output, Renderer2 } from '@angular/core';
import { GridOptions } from 'ag-grid-community';
import { AgGridI } from 'src/app/shared/common-components/ag-grid/ag-grid.model';
import { CommonService } from 'src/app/shared/services/common.service';
import { AgGridDataModelBranchesList, MasterModalTitle } from '../../masters.model';
import { ToastrService } from 'ngx-toastr';
import { Subscription, catchError } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MastersService } from '../../masters.service';
import { SendDataService } from 'src/app/shared/services/send-data.service';
import { StorageService } from 'src/app/shared/services/storage.service';
import { StorageName } from 'src/app/shared/enum/common-enum';
import { commonModalOptionsLg } from 'src/app/shared/models/common.model';
import { BranchDetailsComponent } from '../../../popups/branch-details/branch-details.component';

@Component({
  selector: 'app-branches-tab',
  templateUrl: './branches-tab.component.html',
  styleUrls: ['./branches-tab.component.scss']
})
export class BranchesTabComponent extends CommonService implements OnInit{
  @Output() cellClickEventBranch = new EventEmitter<any>();
  BranchesListRowData: any[] = [];
  gridApiBranchList:any;
  formName = StorageName.FORMNAME_ADMINISTRATION_MASTERS_BRANCHES;
  agGridDataModelBranchesList:AgGridI;
  masterModalTitle = MasterModalTitle;
  getAllDropdownModel : any = {};
  storageName = StorageName;
  constructor(
    public mastersService : MastersService,
    public toaster: ToastrService,
    public renderer:Renderer2,
    public modalservice: NgbModal,
    public storageService        : StorageService,
    private sendDataService: SendDataService,
    private modalService: NgbModal
  ) 
  {
    super(renderer);

  }

  ngOnInit(): void {
    this.agGridDataModelBranchesList = AgGridDataModelBranchesList;
    this.sendDataService.displayPageName(this.formName);
    // this.getAllBranchList();
  }

  fillDropdownData() {
    this.getAllDropdownModel.companyDropdown = this.storageService.retrieve(this.storageName.COMPANY_DROPDOWN);
    this.getAllDropdownModel.stateDropdown = this.storageService.retrieve(this.storageName.STATE_DROPDOWN);
    this.getAllDropdownModel.districtDropdown = this.storageService.retrieve(this.storageName.DISTRICT_DROPDOWN);
    this.getAllDropdownModel.talukaDropdown = this.storageService.retrieve(this.storageName.TALUKA_DROPDOWN);

  }

  gridOptionsBranches: GridOptions = {
    suppressClickEdit:true,
    getRowStyle: this.getRowStyle,
  };
 

  getRowStyle(params: any) {
    return {
      background: params.node.rowIndex % 2 === 0 ? '#EEF1F7' : '#FFFFFF',
    };
  }

  onGridReady(event){
    this.gridApiBranchList  = event; 
    this.getAllBranchList();
  }

  onDeleteRowClickedResponse(params) {
    this.deleteBranchList(params);
  }

   handleTextClick(event) {
    this.openAddEditBranchPopUp(event.data, this.masterModalTitle.EditBranchDetail);
  }
  onDeleteIconClick(params){
    this.onDeleteRowClickedResponse(params?.detail?.data);
  }
  openAddEditBranchPopUp(row,modalTitle?:string){
    const editBranchData = Object.assign({},row);
    this 
    const modalOption: NgbModalOptions = {
      ...commonModalOptionsLg,
    };
    const modalRef = this.modalService.open(BranchDetailsComponent , modalOption);
    modalRef.componentInstance.getAllDropdownModel = this.getAllDropdownModel;
    modalRef.componentInstance.modalTitle = modalTitle;
    modalRef.componentInstance.editBranchDetails =  editBranchData;
    modalRef.result.then((result) => {
      if (result) {
        this.getAllBranchList();
      }
    }, (reason) => {
    });
  }
  
  // onCellClicked(event) {
  //   if (event.colDef.field === 'name') {
  //     this.cellClickEventBranch.emit(event.data);
  //   }
  // }

   // *********** BRANCH API START *********
  // **********get all Branch *********
  getAllBranchList() {
    this.showLoading(this.gridApiBranchList);
    this.mastersService.getAllBranch().subscribe({
      next:(res)=>{
        if(res?.isSuccess){
          this.hideLoading(this.gridApiBranchList);
          this.BranchesListRowData = res?.response.branchModel??[];
        }else{  
          this.hideLoading(this.gridApiBranchList);         
          this.BranchesListRowData = [];
          this.gridApiBranchList?.api?.setRowData([]);
        }  
      },
      error:(err)=>{
        this.hideLoading(this.gridApiBranchList);
        this.BranchesListRowData = [];
        this.gridApiBranchList?.api?.setRowData([]);
      }
    })
  }

  // **********delete Branch *********
    deleteBranchList(params) {
      this.mastersService.deleteBranch(params?.data?.id)
          .pipe(catchError((err) => this.handleError(err)))
          .subscribe((res) => {
            if(res?.isSuccess){
              this.toaster.success(res?.message);
            }else{
                this.gridOptionsBranches.api.setRowData(this.BranchesListRowData);
              this.toaster.error(res?.errors[0]);
            }  
      });
    }

    retriveDeletedRow(params){
      params.node.setSelected(true);
      const selectedNode = params.api.getSelectedRows();
      const rowIndex = params.api.getRowNode(params.data.id).rowIndex;
      const data = this.BranchesListRowData;
      data.splice(rowIndex, 0, selectedNode[0]);
      params.api.applyTransaction({ add: [selectedNode[0]], addIndex: rowIndex });
    }
  
  // *********** BRANCH API END *********


  ngOnDestroy() {
  }

}
