import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import {ClosingReportEditIconService} from './closing-report-edit-icon.service'
@Component({
  selector: 'app-closing-report-edit-icon',
  templateUrl: './closing-report-edit-icon.component.html',
  styleUrls: ['./closing-report-edit-icon.component.scss']
})
export class ClosingReportEditIconComponent implements ICellRendererAngularComp {
  params 
  agInit(params: ICellRendererParams<any, any, any>): void {
    this.params = params;
  }
  refresh(params: ICellRendererParams<any, any, any>): boolean {
    return true;
  }

  constructor( private closingReportEditIconService : ClosingReportEditIconService){}

  viewDetails(params){
    this.closingReportEditIconService.triggerEditButtonClick(params);
  }
}
