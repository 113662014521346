
                <ng-container *ngIf="!isBusinessRegistrationView">
                    <div class="d-flex justify-content-center add_company_container align-items-center h-100">
                        <div class="add_company">
                            <form class="theme-form" ngNativeValidate #phoneForm="ngForm"
                                class="{{isCreateAccount == true && isProceed == false ? 'd-block' : 'd-none'}}">
                                <!-- {{isCreateAccount == true && isProceed == false ? 'd-block' : 'd-none'}} -->
                                <div class="row">
                                    <div class="col-12 mb-2">
                                        <label class="f-13 font-semibold text-gray-color mb-1" for="">Select Country:</label>
                                        <div class="input-group mb-0">
                                            <select [(ngModel)]="selectedCountryCode" (change)="onChangeCountry($event)"
                                                name="countryCode" [value]="selectedCountryCode" class="input-select fullwidth"
                                                style="border-color: #CFD3DC !important;" required #countryCodeSelect="ngModel">
                                                <option [value]="" selected></option>
                                                <option *ngFor="let item of getCountryDetailsResponseList"
                                                    [value]="item.countryCode">{{item.countryName}} ({{item.countryCode}})
                                                </option>
                                            </select>
                                        </div>
                                        <!-- <div style="position: absolute;"
                                            *ngIf="phoneNumberInput.invalid && (phoneNumberInput.dirty || phoneNumberInput.touched)">
                                            <span class="f-11 text-red" *ngIf="phoneNumberInput.errors?.pattern">
                                                please enter a {{maxLength}}-digit phone number.
                                            </span>
                                        </div> -->
                                    </div>
                                    <div class="col-12">
                                        <label class="f-13 font-semibold text-gray-color mb-1" for="">Mobile No.</label>
                                        <div class="input-group mb-0">
                                            <select [(ngModel)]="selectedDialCode" (change)="onChangeDialCode($event)"
                                                name="dialCode" [value]="selectedDialCode" class="input-select"
                                                style="border-color: #CFD3DC !important;" #dialCodeSelect="ngModel" required>
                                                <option [ngValue]="" selected></option>
                                                <option *ngFor="let item of getCountryDetailsResponseList"
                                                    [value]="item.dialCode">+{{item.dialCode}}</option>
                                            </select>
                                            <input type="text" style="height: 28px; padding-left: 10px !important;"
                                                class="f-12 form-control text-secondary" placeholder="" id="phoneNumber"
                                                name="phoneNumber" [maxlength]="maxLength"
                                                [pattern]="maxLength == 9 ? pattern2 : pattern" [(ngModel)]="phoneNumber"
                                                onlyNumbers  (keydown.enter)="getOTP()">
                                                <!-- #phoneNumberInput="ngModel" required -->
                                        </div>
                                        <!-- <div style="position: absolute;"
                                            *ngIf="phoneNumberInput.invalid && (phoneNumberInput.dirty || phoneNumberInput.touched)">
                                            <span class="f-11 text-red" *ngIf="phoneNumberInput.errors?.pattern">please enter a
                                                {{maxLength}}-digit phone number.</span>
                                        </div> -->
                                    </div>
                                    <div class="col-12 d-flex justify-content-center">
                                        <div class="d-flex flex-column">
                                            <button type="submit" class="common_button blue_button mt-5" style="width: 160px;"
                                                (click)="getOTP()" (keydown.enter)="getOTP()">Proceed</button>
                                            <button class="common_button white_button mt-2" style="width: 160px" (click)="close()">Close</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        <!-- end form-3 -->
                            <form class="{{isProceed == true ? 'd-block' : 'd-none'}}">
                                <div class="row">
                                    <div class="col-12">
                                        <label class="f-13 font-semibold text-gray-color mb-1" for="">Mobile No.</label>
                                        <div class="input-group mb-3">
                                            <select [(ngModel)]="selectedDialCode" (change)="onChangeDialCode($event)" disabled
                                                name="dialCode" [value]="selectedDialCode" class="input-select"
                                                style="border-color: #CFD3DC !important;">
                                                <option [ngValue]="" selected></option>
                                                <option *ngFor="let item of getCountryDetailsResponseList"
                                                    [value]="item.dialCode">+{{item.dialCode}}</option>
                                            </select>
                                            <input type="text" style="height: 28px; padding-left: 10px !important;"
                                                class="f-12 form-control text-secondary" placeholder=""
                                                [(ngModel)]="phoneNumber" disabled onlyNumbers
                                                [ngModelOptions]="{standalone: true}">
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <label class="f-13 font-semibold text-gray-color mb-1" for="">OTP</label>
                                        <div class="row">
                                            <div class="col-2">
                                                <app-input onlyNumbers [inputName]="'opt1'" [inputType]="'text'"
                                                    [inputId]="'opt1Id'" [inputValue]="otp1" (inputValueEmit)=" otp1 = $event"
                                                    [isRequired]="true" [maxLength]="1"
                                                    (keyup)="moveFocusToSecondInput($event,'opt2Id','opt1Id')"
                                                    [inputClass]="'text-center'"
                                                    [ngClass]="{'border-red': !otp1 && submitedOTP}"
                                                    (keydown.enter)="varifyOTP()">
                                                </app-input>
                                            </div>
                                            <div class="col-2">
                                                <app-input onlyNumbers [inputName]="'opt2'" [inputType]="'text'"
                                                    [inputId]="'opt2Id'" [inputValue]="otp2" [maxLength]="1"
                                                    (inputValueEmit)="otp2 = $event" [isRequired]="true"
                                                    (keyup)="moveFocusToSecondInput($event,'opt3Id','opt1Id')"
                                                    [inputClass]="'text-center'"
                                                    [ngClass]="{'border-red': !otp2 && submitedOTP}"
                                                    (keydown.enter)="varifyOTP()">
                                                </app-input>
                                            </div>
                                            <div class="col-2">
                                                <app-input onlyNumbers [inputName]="'opt3'" [inputType]="'text'"
                                                    [inputId]="'opt3Id'" [inputValue]="otp3" [maxLength]="1"
                                                    (inputValueEmit)="otp3 = $event" [isRequired]="true"
                                                    [inputClass]="'text-center'"
                                                    (keyup)="moveFocusToSecondInput($event,'opt4Id','opt2Id')"
                                                    [ngClass]="{'border-red': !otp3 && submitedOTP}"
                                                    (keydown.enter)="varifyOTP()">
                                                </app-input>
                                            </div>
                                            <div class="col-2">
                                                <app-input onlyNumbers [inputName]="'opt4'" [inputType]="'text'"
                                                    [maxLength]="1" [inputId]="'opt4Id'" [inputValue]="otp4"
                                                    [isRequired]="true" (inputValueEmit)="otp4 = $event"
                                                    [inputClass]="'text-center'"
                                                    (keyup)="moveFocusToSecondInput($event,'opt5Id','opt3Id')"
                                                    [ngClass]="{'border-red': !otp4 && submitedOTP}"
                                                    (keydown.enter)="varifyOTP()">
                                                </app-input>
                                            </div>
                                            <div class="col-2">
                                                <app-input onlyNumbers [inputName]="'opt5'" [inputType]="'text'"
                                                    [inputId]="'opt5Id'" [inputValue]="otp5" [maxLength]="1"
                                                    [isRequired]="true" [inputClass]="'text-center'"
                                                    (inputValueEmit)="otp5 = $event"
                                                    (keyup)="moveFocusToSecondInput($event,'opt6Id','opt4Id')"
                                                    [ngClass]="{'border-red': !otp5 && submitedOTP}"
                                                    (keydown.enter)="varifyOTP()">
                                                </app-input>
                                            </div>
                                            <div class="col-2">
                                                <app-input onlyNumbers [inputName]="'opt6'" [inputType]="'text'"
                                                    [inputId]="'opt6Id'" [maxLength]="1" [inputValue]="otp6"
                                                    [isRequired]="true" [inputClass]="'text-center'"
                                                    (inputValueEmit)="otp6 = $event"
                                                    [ngClass]="{'border-red':!otp6 && submitedOTP}"
                                                    (keyup)="moveFocusToSecondInput($event,'opt6Id','opt5Id')"
                                                    (keydown.enter)="varifyOTP()">
                                                </app-input>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 d-flex justify-content-end">
                                        <span class="text-primary f-12 mt-2  cursorPointer" (click)="reSendOTP()">Re-send
                                            OTP</span>
                                    </div>
                                    <div class="col-12 d-flex justify-content-center">
                                        <div class="d-flex flex-column">
                                            <button id="varifyOtP" class="common_button blue_button mt-4" style="width: 160px;"
                                                (click)="varifyOTP()"  (keydown.enter)="varifyOTP()">Verify</button>
                                            <button class="common_button white_button mt-2" style="width: 160px;"
                                                (click)="back()">Back</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        <!-- end form-4 -->
                            <form action="" class="{{isVarify == true ? 'd-block' : 'd-none'}}">
                                <div class="row">
                                    <div class="col-12 mb-2">
                                        <label class="f-13 font-semibold text-gray-color mb-1" for="">Product Key</label>
                                        <app-input [inputName]="'ProductKey'" [inputType]="'text'" [inputId]="'ProductKeyId'"
                                            [readOnly]="true" [inputValue]="productKeyResponseModel.productKey"
                                            (inputValueEmit)="productKeyResponseModel.productKey = $event;" [isRequired]="true">
                                        </app-input>
                                    </div>
                                    <div class="col-12">
                                        <label class="f-13 font-semibold text-gray-color mb-1" for="">License No.</label>
                                        <app-input [inputName]="'License'" [inputType]="'text'" [inputId]="'licenseId'"
                                            [inputValue]="licenseKeyVerifyModelRequest.licenseKey"
                                            (inputValueEmit)="licenseKeyVerifyModelRequest.licenseKey = $event"
                                            [isRequired]="true">
                                        </app-input>
                                    </div>
                                    <div class="col-12 d-flex justify-content-end">
                                        <span class="text-primary f-12 mt-2  cursorPointer" (click)="getLicenseKey()">Generate
                                            License Key</span>
                                    </div> 
                                    <div class="col-12 d-flex justify-content-center">
                                        <div class="d-flex flex-column">
                                            <button type="submit" class="common_button blue_button mt-5" style="width: 160px;"
                                            (click)="verifyLicenseKey()">Register</button>
                                            <button class="common_button white_button mt-2" style="width: 160px" (click)="close()">Close</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf="isBusinessRegistrationView">
                    <app-business-registration [fromAddCompany]="true"></app-business-registration>
                </ng-container>