
<select2 
(keydown.enter)="onEnterPressed($event)"
  [overlay]="overlay"
  [data]="data3"
  #select2Ref
  [value]="id"
  (update)="update('value2', $event)" 
  id="selec2-2"
  (search)="search('search1', $event)" 
  customSearchEnabled
  required
  (scroll)="handleScroll($event)"
  (keydown)="handleKeyDown($event)"
  (opened)="onDropdownOpened($event)"
 >
</select2>
