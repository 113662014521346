import { Component, ElementRef, EventEmitter, Input, Output, Renderer2 } from '@angular/core';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss']
})
export class DropdownComponent {
  @Input() options: any[];
  @Input() selectedValue: any;
  @Input() disabled : boolean;
  @Input() readonly : boolean;
  @Input() isRequired:boolean;
  @Input() dropdownName: string;
  @Input() dropdownId: string;
  @Input() dropdownClass: string;
  @Output() dropdownValueEmit:EventEmitter<string> = new EventEmitter();
  
  constructor(private renderer: Renderer2, private el: ElementRef) { }
  
  // onValueChange(newValue: any) {
  //   this.selectedValue = newValue;
  // }

}
