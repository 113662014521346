import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-save-change-popup',
  templateUrl: './save-change-popup.component.html',
  styleUrls: ['./save-change-popup.component.scss']
})
export class SaveChangePopupComponent {

  constructor(public activeModal:NgbActiveModal){

  }

  ngOnInit(){
    
  }

  onSave(){
      this.activeModal.close(true);
  }

}
