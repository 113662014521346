import { Injectable } from '@angular/core';
import { DataService } from "./../../../shared/services/data.service";
import { Observable, tap } from 'rxjs';
import { BranchMainModel,CreateUpdateBucketRequestModel, CreateUpdateCreditCardRequestModel, CreateUpdateTaxSettingRequestModel, GetAllCreditCardrequestModel } from './masters.model';
import { ResponseModel } from 'src/app/shared/models/response-model.model';
import { CreateUpdateStyleRequestModel, GetStyleRequestModel } from './tabs/style-tab/style-tab.model';

@Injectable({
  providedIn: 'root'
})
export class MastersService {

  constructor(public service: DataService) { 

  }
  // *********** Branch service start ************
   // **********get all Branch *********
   getAllBranch():Observable<ResponseModel>{
    let url = "api/app/branch/getallbranches";
    return this.service.get(url, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }
  
 // *************insert Branch **************
  insertBranch(branchMainModel:BranchMainModel):Observable<ResponseModel>{
    let url = "api/app/branch/insertbranch";
    const json = JSON.stringify(branchMainModel);
    return this.service.post(url,branchMainModel, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

   // *************update Branch **************
   updateBranch(branchMainModel:BranchMainModel):Observable<ResponseModel>{
    let url = "api/app/branch/updatebranch";
    const json = JSON.stringify(branchMainModel);
    return this.service.put(url,branchMainModel,true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

    // *************delete Branch **************
    deleteBranch(id:number):Observable<ResponseModel>{
      let url = `api/app/branch/deletebranch?Id=${id}`;
      return this.service.delete(url, true).pipe<ResponseModel>(
        tap((response: any) => {
          return response;
        })
      );
    }

    // *************get by id Branch **************
    getByIdBranch(id:number):Observable<ResponseModel>{
      let url = `api/app/branch/getbyidbranch?Id=${id}`;
      return this.service.get(url, true).pipe<ResponseModel>(
        tap((response: any) => {
          return response;
        })
      );
    }

    // *************get by id stateId **************
    getDistrictByIdState(id:number):Observable<ResponseModel>{
      let url = `api/app/dropdown/getdistrict?StateID=${id}`;
      return this.service.get(url, true).pipe<ResponseModel>(
        tap((response: any) => {
          return response;
        })
      );
    }
    // *********** Branch service end ************


    
    // *********** Bucket service start ************
   // **********get all Bucket *********
   getAllBucket():Observable<ResponseModel>{
    let url = "api/app/bucket/getallbucket";
    return this.service.get(url, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }
  
  
 // *************insert Bucket **************
  insertBucket(createUpdateBucketRequestModel:CreateUpdateBucketRequestModel):Observable<ResponseModel>{
    let url = "api/app/bucket/insertbucket";
    const json = JSON.stringify(createUpdateBucketRequestModel);
    return this.service.post(url,createUpdateBucketRequestModel, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

   // *************update Bucket **************
   updateBucket(createUpdateBucketRequestModel:CreateUpdateBucketRequestModel):Observable<ResponseModel>{
    let url = "api/app/bucket/updatebucket";
    const json = JSON.stringify(createUpdateBucketRequestModel);
    return this.service.putWithId(url,createUpdateBucketRequestModel,true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

  // *************delete Bucket **************
  deleteBucket(id:number):Observable<ResponseModel>{
    let url = `api/app/bucket/deletebucket?Id=${id}`;
    return this.service.delete(url, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

  // *************get by id Bucket **************
  getByIdBucket(id:number):Observable<ResponseModel>{
    let url = `api/app/bucket/getbyidbucket?Id=${id}`;
    return this.service.get(url, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

    // *********** Bucket service end ************



   // *********** TaxSetting service start ************
   // **********get all TaxSetting *********
    getAllTaxSetting():Observable<ResponseModel>{
      let url = "api/app/taxsetting/getalltaxsetting";
      return this.service.get(url, true).pipe<ResponseModel>(
        tap((response: any) => {
          return response;
        })
      );
    }
    
  // *************insert TaxSetting **************
    insertTaxSetting(data:CreateUpdateTaxSettingRequestModel):Observable<ResponseModel>{
      let url = "api/app/taxsetting/inserttaxsetting";
      const json = JSON.stringify(data);
      return this.service.post(url,data, true).pipe<ResponseModel>(
        tap((response: any) => {
          return response;
        })
      );
    }

    // *************update TaxSetting **************
    updateTaxSetting(data:CreateUpdateTaxSettingRequestModel):Observable<ResponseModel>{
      let url = "api/app/taxsetting/updatetaxsetting";
      const json = JSON.stringify(data);
      return this.service.putWithId(url,data,true).pipe<ResponseModel>(
        tap((response: any) => {
          return response;
        })
      );
    }

    // *************delete TaxSetting **************
    deleteTaxSetting(id:number):Observable<ResponseModel>{
      let url = `api/app/taxsetting/deletetaxsetting?Id=${id}`;
      return this.service.delete(url, true).pipe<ResponseModel>(
        tap((response: any) => {
          return response;
        })
      );
    }

    // *************get by id TaxSetting **************
    getByIdTaxSetting(id:number):Observable<ResponseModel>{
      let url = `api/app/taxsetting/getbyidtaxsetting?Id=${id}`;
      return this.service.get(url, true).pipe<ResponseModel>(
        tap((response: any) => {
          return response;
        })
      );
    }

    // *********** TaxSetting service end ************
    

  // *********** Style Service Start ************ 
  // **********get all Style *********
    getAllStyle(data:GetStyleRequestModel):Observable<ResponseModel>{
      let url = "api/app/style/getallstyle";
      return this.service.post(url,data, true).pipe<ResponseModel>(
        tap((response: any) => {
          return response;
        })
      );
    }

  // *************insert Style **************
    insertStyle(data:CreateUpdateStyleRequestModel):Observable<ResponseModel>{
      let url = "api/app/style/insertstyle";
      const json = JSON.stringify(data);
      return this.service.post(url,data, true).pipe<ResponseModel>(
        tap((response: any) => {
          return response;
        })
      );
    }

  // *************update Style **************
    updateStyle(data:CreateUpdateStyleRequestModel):Observable<ResponseModel>{
      let url = "api/app/style/updatestyle";
      const json = JSON.stringify(data);
      return this.service.post(url,data,true).pipe<ResponseModel>(
        tap((response: any) => {
          return response;
        })
      );
    }

   // **************** Style Service End ************ 
 

   // *************upload file Style  **************
  uploadFileStyle(data:any):Observable<ResponseModel>{
    let url = "api/app/style/uploadstyle";
    const json = JSON.stringify(data);
    const formData = new FormData();
    formData.append('file', data);
    console.log(json)
    return this.service.postWithFile(url,formData, true,null,true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }


    // *********** CreditCard service start ************
   // **********get all CreditCard *********
   getAllCreditCard(data:GetAllCreditCardrequestModel):Observable<ResponseModel>{
    let url = "api/app/creditcard/getallcreditcard";
    return this.service.post(url, data, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

   // *************insert CreditCard **************
   addCreditCard(createUpdateCreditCardRequestModel:CreateUpdateCreditCardRequestModel):Observable<ResponseModel>{
    let url = "api/app/creditcard/insertcreditcard";
    const json = JSON.stringify(createUpdateCreditCardRequestModel);
    return this.service.post(url,createUpdateCreditCardRequestModel, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

   // *************update CreditCard **************
   updatecreditCard(createUpdateCreditCardRequestModel:CreateUpdateCreditCardRequestModel):Observable<ResponseModel>{
    let url = "api/app/creditcard/updatecreditcard";
    const json = JSON.stringify(createUpdateCreditCardRequestModel);
    return this.service.putWithId(url,createUpdateCreditCardRequestModel,true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

//*****Import data ***** */
  getsamplefilebypagename(data):Observable<ResponseModel>{
    let url = "api/app/samplefile/getsamplefilebypagename";
    const json =JSON.stringify(data)
    return this.service.post(url, data,true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }
}
