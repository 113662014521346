import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-advanced-filter-pop-up',
  templateUrl: './advanced-filter-pop-up.component.html',
  styleUrls: ['./advanced-filter-pop-up.component.scss']
})
export class AdvancedFilterPopUpComponent {
  @ViewChild("AdvanceFilter") AdvanceFilter!: NgForm;
  @Output() applyFilter : EventEmitter<string> = new EventEmitter<string>();
  @Output() clearFilter : EventEmitter<string> = new EventEmitter<string>();
  constructor(public activeModal: NgbActiveModal){
  }

  onClickApplyFilter() {
    this.applyFilter.emit();
  }

  onClickClearFilter() {
    this.clearFilter.emit();
  }
  
}
