import { Component, Input, Renderer2 } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/shared/services/common.service';
import { KeyboardShortcutsTitle } from './keyboard-shortcuts.model';
@Component({
  selector: 'app-keyboard-shortcuts',
  templateUrl: './keyboard-shortcuts.component.html',
  styleUrls: ['./keyboard-shortcuts.component.scss']
})
export class KeyboardShortcutsComponent extends CommonService{
  keyboardShortcutsTitle = KeyboardShortcutsTitle;
  @Input() modalTitle: string;
  
  constructor(
    public activeModal: NgbActiveModal,
    public renderer: Renderer2,
    public toaster: ToastrService
  ) {
    super(renderer);
  }

  ngOnInit() {

  }
}
