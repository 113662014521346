import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ExportAs } from '../../enum/common-enum';

@Component({
  selector: 'app-export-dropdown',
  templateUrl: './export-dropdown.component.html',
  styleUrls: ['./export-dropdown.component.scss']
})
export class ExportDropdownComponent {

  @Input() requestModel;
  @Input() gridApi;
  @Input() gridName:any;
  exportAs = ExportAs
  @Output() callApiForExport = new EventEmitter()
  @Input() withoutGrid = false;
  @Input() isDisabled :boolean = false;
  // @Input() isMultipleGrid = false;
  // @Input() gridApis=[]=[];
  // @Input() gridNames=[]=[];

  downloadXlSFile(exportAs){
    if(this.withoutGrid){
      this.downloadXlSFileWithoutGrid(exportAs);
    }else {
      this.downloadXlSFileWithGrid(exportAs);
    }
  }

  // downloadXlSFileWithGrid(exportAs) {
  //   this.requestModel.exportAs = exportAs;
  //   this.requestModel.getAll = true;
  //   const columnApi  = this.gridApi?.columnApi;
  //   const allColumns = columnApi?.getAllColumns();
  //   // Filter out the column with columnName 'action'
  //   const columnsToInclude = allColumns?.filter((column, index) => {
  //     return column?.getColDef()?.field !== 'action';
  //   });
  //   let availableExportRequestInstance = new ExportRequest();
  //   availableExportRequestInstance.gridName = this.gridName;
  //   availableExportRequestInstance.columnToInclude = columnsToInclude?.map((column, index) => {
  //     return { displayName: column?.getColDef()?.headerName, columnName: column?.getColDef()?.field, columnOrder: index }
  //   });
  //   this.requestModel.exportRequest = [availableExportRequestInstance];
  //   this.callApiForExport.emit();
  //   // this.requestModel.exportAs = ExportAs.None;
  //   this.requestModel.getAll   = false;
  //   delete this.requestModel.exportAs;
  //   delete this.requestModel.exportRequest;
// }


// #region for single or Multiple grid 
downloadXlSFileWithGrid(exportAs) {
  let gridApis = this.gridApi;
  let gridNames = this.gridName;

  this.requestModel.exportAs = exportAs;
  this.requestModel.getAll = true;

  let exportRequests = [];
  gridApis.forEach((gridApi:any, index) => {
      const columnApi = gridApi?.columnApi;
      const allColumns = columnApi?.getAllColumns();
      const columnsToInclude = allColumns?.filter((column, index) => {
          return column?.getColDef()?.field !== 'action' &&
                 column?.getColDef()?.field !== "isExpandable" &&
                 column?.getColDef()?.field !== "checkbox" &&
                 column?.getColDef()?.field !== "active" &&
                 column?.getColDef()?.field !== "imageUrls" &&
                 column?.getColDef()?.field !== "" &&
                 column?.getColDef()?.field !== "SearchNo";
      });

      let exportRequestInstance = new ExportRequest();
      exportRequestInstance.gridName = gridNames[index];
      exportRequestInstance.gridData = gridApi?.api?.getRenderedNodes()?.map(node => node?.data);;
      exportRequestInstance.columnToInclude = columnsToInclude?.map((column, index) => {
          return {
              displayName: column?.getColDef()?.headerName,
              columnName: column?.getColDef()?.field,
              columnOrder: index
          };
      }).filter(column => column.displayName); // Filter out columns with empty or null displayName

      exportRequests.push(exportRequestInstance);
  });

  this.requestModel.exportRequest = exportRequests;
  this.callApiForExport.emit();

  // Reset the requestModel
  this.requestModel.getAll = false;
  delete this.requestModel.exportAs;
  delete this.requestModel.exportRequest;
}




  downloadXlSFileWithoutGrid(exportAs) {
    this.requestModel.exportAs = exportAs;
    this.requestModel.getAll = true;
    this.requestModel.exportRequest = [{columnToInclude :null}];
    this.callApiForExport.emit();
    // this.requestModel.exportAs = ExportAs.None;
    this.requestModel.getAll   = false;
    delete this.requestModel.exportAs;
    delete this.requestModel.exportRequest;
  }
}

export class ExportRequest {
  gridName       : string;
  columnToInclude : any;
  gridData:any;
}