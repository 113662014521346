import { Directive, ElementRef, HostBinding, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[notNagativeDecimalNumber]'
})
export class NotNagativeDecimalNumberDirective {
  
  @HostBinding('autocomplete') public autocomplete = 'off';
  @Input() allowDecimal: boolean = true; // Flag to control the decimal separator
  
  constructor(private el: ElementRef) {
    this.autocomplete = 'off';
  }
  
  @HostListener('keypress', ['$event']) public disableKeys(e: KeyboardEvent) {
    const input = e.target as HTMLInputElement;
    const keyCode = e.keyCode || e.which;
    const key = String.fromCharCode(keyCode);

    if (!/[\d.]/.test(key) || (key === '.' && !this.allowDecimal) || (key === '.' && input.value.includes('.'))) {
      e.preventDefault();
    }
  }

  @HostListener('paste', ['$event']) public onPaste(e: ClipboardEvent) {
    e.preventDefault();
    const clipboardData = e.clipboardData || (e as any).originalEvent.clipboardData;
    let pastedText = clipboardData.getData('text/plain');

    if (this.allowDecimal) {
      pastedText = pastedText.replace(/[^0-9.]/g, '');
    } else {
      pastedText = pastedText.replace(/[^0-9]/g, '');
    }

    const input = e.target as HTMLInputElement;
    const newValue = input.value + pastedText;

    if (newValue.split('.').length > 2) {
      pastedText = pastedText.replace(/\./g, '');
    }

    document.execCommand('insertText', false, pastedText);
  }

  @HostListener('input', ['$event']) public onInput(e: Event) {
    const input = e.target as HTMLInputElement;
    let newValue = input.value;

    if (this.allowDecimal) {
      newValue = newValue.replace(/[^0-9.]/g, '');

      const parts = newValue.split('.');
      if (parts.length > 2) {
        newValue = parts[0] + '.' + parts.slice(1).join('');
      }
    } else {
      newValue = newValue.replace(/[^0-9]/g, '');
    }

    input.value = newValue;
  }
}
