import { Component, OnInit, QueryList, Renderer2, ViewChild, ViewChildren } from '@angular/core';
import { CommonService } from '../../services/common.service';
import { NgForm } from '@angular/forms';
import { AdministrationService } from 'src/app/components/administration/administration.service';
import { ToastrService } from 'ngx-toastr';
import { catchError } from 'rxjs';
import { GetAllRemarkResponseModel } from 'src/app/components/administration/administration.model';
import { SendDataService } from '../../services/send-data.service';

@Component({
  selector: 'app-remark',
  templateUrl: './remark.component.html',
  styleUrls: ['./remark.component.scss']
})
export class RemarkComponent extends CommonService implements OnInit{
  @ViewChild('administrationFormData') administrationFormData! : NgForm ;
  getAllRemarkData              : any[]=[];

  copyGetAllRemarkData          : any[]=[];
  editRemarkData                = new GetAllRemarkResponseModel();
  isAdd                       : boolean = false;

  constructor(
    public renderer              :Renderer2,
    public administrationService : AdministrationService,
    public toaster               : ToastrService,
    public sendDataService : SendDataService,
  ) 
  {
    super(renderer)
  }
  
  ngOnInit(): void {
    this.getAllRemarkList();
  }

  add(){
    if(!this.sendDataService?.permissionVM?.mastersCanAdd) return;
    this.isAdd = true;
    const obj = {id:0, name:'', active:true}
    this.getAllRemarkData?.push(obj);
    this.copyGetAllRemarkData?.push(obj);
    setTimeout(()=>{
      const element = document.getElementById(`remarkName${obj.id}`);
      element?.focus();
    },0)
  }

  cancel(){
    if(this.isAdd)
    {
      this.isAdd = false;
      this.getAllRemarkData = this.getAllRemarkData.filter((x)=>x.id != 0);
      this.copyGetAllRemarkData = this.copyGetAllRemarkData.filter((x)=>x.id != 0);
    }
    else if(this.editRemarkData?.id && this.editRemarkData?.isEdit){
      const index = this.copyGetAllRemarkData.findIndex((x)=>x.id == this.editRemarkData?.id);
      const singleData =JSON.parse(JSON.stringify(this.copyGetAllRemarkData))[index];
      this.getAllRemarkData[index] = singleData;
      this.editRemarkData = new GetAllRemarkResponseModel();
    }  
  }


  edit(item,formData){
    if(!this.sendDataService?.permissionVM?.mastersCanUpdate) return;
    item.isEdit = true;
    const controlObj = formData?.form?.controls;
    if(controlObj){
      const id = Object.keys(controlObj)[0];
      const element = document.getElementById(id);
      element.focus();
    }
    this.editRemarkData = {...item};
  }

  
  checkIsEdit(arr:any[]):boolean{
    return arr?.some((x)=>x.isEdit)
  }

    // *********** REMARK API START *********
    // **********get all remark *********
    getAllRemarkList() {
      this.administrationService.getAllRemark().subscribe({
        next:(res)=>{
          if(res.isSuccess){
            this.editRemarkData = new GetAllRemarkResponseModel();
            this.getAllRemarkData = res.response??[];
            this.copyGetAllRemarkData = JSON.parse(JSON.stringify(this.getAllRemarkData))??[];
          }else{           
            this.getAllRemarkData = [];
            this.copyGetAllRemarkData = [];
          }  
        },
        error:(err)=>{
          this.getAllRemarkData = [];
          this.copyGetAllRemarkData = [];
        }
      })
    }
    
   // *************insert remark **************
    InserRemarkList(item) {
      const {id , ...rest} = item;
      this.administrationService.insertRemark(rest)
          .pipe(catchError((err) => this.handleError(err)))
          .subscribe((res) => {
            if(res.isSuccess){
              this.isAdd = false;
              this.getAllRemarkList();
              this.toaster.success(res.message);
            }else{
              this.toaster.error(res.errors[0]);
            }  
        });
      }
  
    // **********update remark *********
      UpdateRemarkList(item) {
      const {isEdit , ...rest} = item;
      this.administrationService.updateRemark(rest)
          .pipe(catchError((err) => this.handleError(err)))
          .subscribe((res) => {
            if(res.isSuccess){
              this.isAdd = false;
              this.getAllRemarkList();
              this.toaster.success(res.message);
            }else{
              this.toaster.error(res.errors[0]);
            }  
        });
      }  
  
      // **********delete remark *********
      DeleteRemarkList(id) {
        if(!this.sendDataService?.permissionVM?.mastersCanDelete) return;
        this.administrationService.deleteRemark(id)
            .pipe(catchError((err) => this.handleError(err)))
            .subscribe((res) => {
              if(res.isSuccess){
                this.getAllRemarkList();
                this.toaster.success(res.message);
              }else{
                this.toaster.error(res.errors[0]);
              }  
        });
      }
    
    // *********** REMARK API END *********

}
