import { Component, OnInit, Renderer2, ViewChild } from '@angular/core';
import { CommonService } from '../../services/common.service';
import { NgForm } from '@angular/forms';
import { AdministrationService } from 'src/app/components/administration/administration.service';
import { ToastrService } from 'ngx-toastr';
import { GetAllLoanReasonResponseModel } from 'src/app/components/administration/administration.model';
import { catchError } from 'rxjs';
import { SendDataService } from '../../services/send-data.service';

@Component({
  selector: 'app-loan-reason',
  templateUrl: './loan-reason.component.html',
  styleUrls: ['./loan-reason.component.scss']
})
export class LoanReasonComponent extends CommonService implements OnInit {
  [x: string]: any;
  @ViewChild('administrationFormData') administrationFormData! : NgForm ;
  getAllLoanReason           : any[]=[];
  copyGetAllLoanReason       : any[]=[]
  editLoanReason             = new GetAllLoanReasonResponseModel();
  isAdd                           : boolean = false;

  constructor(
    public renderer:Renderer2,
    public administrationService : AdministrationService,
    public toaster: ToastrService,
    public sendDataService : SendDataService,
  ) 
  {
    super(renderer)
  }
  ngOnInit(): void {
    this.getAllLoanReasonList();
  }

  add(){
    if(!this.sendDataService?.permissionVM?.mastersCanAdd) return;
    this.isAdd = true;
    const obj = {id:0, name:'',active:true}
    this.getAllLoanReason?.push(obj);
    this.copyGetAllLoanReason?.push(obj);
    setTimeout(()=>{
      const element = document.getElementById(`LoanReasonname${obj.id}`);
      element?.focus();
    },0)
  }

  cancel(){
    if(this.isAdd)
    {
      this.isAdd = false;
      this.getAllLoanReason = this.getAllLoanReason.filter((x)=>x.id != 0);
      this.copyGetAllLoanReason = this.copyGetAllLoanReason.filter((x)=>x.id != 0);
    }
    else if(this.editLoanReason?.id && this.editLoanReason?.isEdit){
      const index = this.copyGetAllLoanReason.findIndex((x)=>x.id == this.editLoanReason?.id);
      const singleData =JSON.parse(JSON.stringify(this.copyGetAllLoanReason))[index];
      this.getAllLoanReason[index] = singleData;
      this.editLoanReason = new GetAllLoanReasonResponseModel();
    }  
  }

  edit(item,formData){
    if(!this.sendDataService?.permissionVM?.mastersCanUpdate) return;
    item.isEdit = true;
    const controlObj = formData?.form?.controls;
    if(controlObj){
      const id = Object.keys(controlObj)[0];
      const element = document.getElementById(id);
      element?.focus();
    }
     this.editLoanReason = {...item};
  }

  checkIsEdit(arr:any[]):boolean{
    return arr?.some((x)=>x.isEdit)
  }


  // *********** Loan Reason API START *********

  // **********get all Loan Reason*********  
  getAllLoanReasonList() {
    this.administrationService.getAllLoanReason().subscribe({
      next:(res)=>{
        if(res.isSuccess){
            this.getAllLoanReason = res.response??[];
            this.editLoanReason = new GetAllLoanReasonResponseModel();
            this.copyGetAllLoanReason = JSON.parse(JSON.stringify(this.getAllLoanReason.filter((x)=>x.active == true)))??[];
          }else{           
          this.getAllLoanReason = [];
          this.copyGetAllLoanReason = [];
        }  
      },
      error:(err)=>{
        this.getAllLoanReason = []; 
        this.copyGetAllLoanReason = [];
      }
    })
  }

 // *************insert Loan Product Type**************
 insertLoanReason(item) {
  const {id , ...rest} = item;
  this.administrationService.insertLoanReason(rest)
      .pipe(catchError((err) => this.handleError(err)))
      .subscribe((res) => {
        if(res.isSuccess){
          this.isAdd = false;
          this.getAllLoanReasonList();
          this.toaster.success(res.message);
        }else{
          this.toaster.error(res.errors[0]);
        }  
    });
  }

  // **********update Loan Product Type *********
  updateLoanReason(item) {
    const {isEdit , ...rest} = item;
    this.administrationService.updateLoanReason(rest)
        .pipe(catchError((err) => this.handleError(err)))
        .subscribe((res) => {
          if(res.isSuccess){
            console.log(res?.message)
            this.isAdd = false;
            this.getAllLoanReasonList();
            this.toaster.success(res.message);
          }else{
            this.toaster.error(res.errors[0]);
          }  
      });
    }  

 // **********delete Loan Product Type *********
 deleteLoanReason(id) {
  if(!this.sendDataService?.permissionVM?.mastersCanDelete) return;
  this.administrationService.deleteLoanReason(id)
      .pipe(catchError((err) => this.handleError(err)))
      .subscribe((res) => {
        if(res.isSuccess){
          this.getAllLoanReasonList();
          this.toaster.success(res.message);
        }else{
          this.toaster.error(res.errors[0]);
        }  
  });
}
// *********** Loan Reason API END *********

}
