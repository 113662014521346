import {PaginationCommonRequestModel} from '../../models/pagination-base-model.model'
export class JewelryCatalogueCommon {
}
export class GetAllJewelryCatalogueRequestModel extends PaginationCommonRequestModel{
    pageSize = 25;
    page=1;
    getAll= true;
  }

  export enum Catelogue{
    InventoryCatelogue = 1,
    CustomerCatelogue = 2,
    LoggedCustomerCatelogue = 3,
    BillOfMaterialCatelogue = 4,
  }