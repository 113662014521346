import { AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, Renderer2, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { Select2Data, Select2UpdateEvent, Select2SearchEvent, Select2ScrollEvent, } from 'ng-select2-component';
import { StorageService } from '../../services/storage.service';
import { StorageName } from '../../enum/common-enum';
import { Subscription } from 'rxjs';
import { CatalogueModel } from 'src/app/components/inventory/jewelry-catalogue/jewelry-catalogue-details/jewelry-catalogue-details.model';


@Component({
  selector: 'app-custom-select2',
  templateUrl: './custom-select2.component.html',
  styleUrls: ['./custom-select2.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CustomSelect2Component implements OnInit , AfterViewInit{
  getInvoiceSubscription: Subscription;
  @ViewChild('select2Ref') select2Ref: any; 
  getAllDropdownModel : any = {};
  @Input() formModel: any;      
  storageName = StorageName;
  @Input() whichDropdown = StorageName.ACCOUNTLEDGER_DROPDOWN;
  dropdown: any[] = [];
  id: any;
  data3: Select2Data = [];
  private keydownListener: () => void;
  overlay = false;
  @Input() displayText: string;
  @Input() selec2: string;
  @Input() selectedValue: any;
  @Output() valueChanged: EventEmitter<any> = new EventEmitter<any>();
  @Output() keyEnter: EventEmitter<any> = new EventEmitter<any>();
  searchSelectedName:string;
  private listeners: (() => void)[] = [];
  private listenersAdded = false;
  private debounceTimer: any;
  highlightedIndex: number = -1;

  // Function to handle keydown events
  // @HostListener('document:keydown', ['$event'])
  // handleKeyboardEvent(event: KeyboardEvent) {
  //   if (event.key === 'ArrowDown' || event.key === 'ArrowUp') {
  //     event.preventDefault(); // Prevent default scrolling behavior
  //     this.navigateOptions(event.key === 'ArrowDown' ? 1 : -1); // Navigate options based on key pressed
  //   } else if (event.key === 'Enter') {
  //     this.selectHighlightedOption(); // Select highlighted option on Enter key press
  //   }
  // }

  // // Function to navigate options based on arrow key pressed
  // navigateOptions(step: number) {
  //   const options = document.querySelectorAll('.select2-results__option');
  //   if (options.length === 0) return; // Return if no options available

  //   // Remove highlight from current option
  //   if (this.highlightedIndex >= 0) {
  //     options[this.highlightedIndex].classList.remove('highlighted');
  //   }

  //   // Calculate index of next option to highlight
  //   this.highlightedIndex = (this.highlightedIndex + step + options.length) % options.length;

  //   // Highlight the next option
  //   options[this.highlightedIndex].classList.add('highlighted');
  // }

  // Function to select the highlighted option
  // selectHighlightedOption() {
  //   const options = document.querySelectorAll('.select2-results__option');
  //   if (this.highlightedIndex >= 0 && options.length > 0) {
  //     options[this.highlightedIndex].dispatchEvent(new MouseEvent('click')); // Simulate click event to select the option
  //   }
  // }
  
  constructor(public storageService: StorageService,private renderer: Renderer2, private el: ElementRef
  ) {
    this.getInvoiceSubscription = this.storageService.setSelect2Dropdown.subscribe((result) => {
      if (result != undefined) {
        this.getInvoiceResponse(result);
      }
    });
  }

  onEnterPressed(event)
  {
    this.keyEnter.emit();
  }

  onDropdownOpened(event) {
    // Focus on the first item in the dropdown
    const options = document.querySelectorAll('.select2-results__option');

    const event2 = new MouseEvent('mouseenter', {
      bubbles: true,
      cancelable: true,
      view: window
    });
    if(options.length > 0)
    options[0].dispatchEvent(event2);
  }

  ngAfterViewInit() {
    const targetDiv = this.el.nativeElement.querySelector('.select2-results');
    this.select2Ref.open.subscribe(res => {
      this.onDropdownOpened('opened')
    })
    this.highlightedIndex = -1;
    if (targetDiv) {
      const newDiv = this.renderer.createElement('div');
      if(this.whichDropdown == StorageName.ACCOUNTLEDGER_DROPDOWN){
      newDiv.innerHTML = `
        <div class="template_header">
          <div class="row mx-0">
            <div class="col-8 ps-1 text-truncate">Name</div>
            <div class="col-4 pe-0 ps-0 text-truncate">Contact</div>
          </div>
        </div>
      `;
      }else if(this.whichDropdown == StorageName.PRODUCT_DROPDOWN){
        newDiv.innerHTML = `
        <div class="template_header">
          <div class="row mx-0">
            <div class="col-8 pe-0 ps-0 text-truncate">Product</div>
          </div>
        </div>
      `;
      }
      else if(this.whichDropdown == StorageName.SALEPERSON_DROPDOWN){
        newDiv.innerHTML = `
        <div class="template_header">
          <div class="row mx-0">
            <div class="col-12 px-1 text-truncate">Sale Person</div>
          </div>
        </div>
      `;
      }
      else if(this.whichDropdown == StorageName.INVOICE_NO_DROPDOWN){
        newDiv.innerHTML = `
        <div class="template_header">
          <div class="row mx-0">
            <div class="col-12 px-1 text-truncate">Invoice No.</div>
          </div>
        </div>
      `;
      }
      else if(this.whichDropdown == StorageName.PURCHASEINVOICENO_DROPDOWN){
        newDiv.innerHTML = `
        <div class="template_header">
          <div class="row mx-0">
            <div class="col-12 px-1 text-truncate">Purchase Invoice No</div>
          </div>
        </div>
      `;
      }
      else if(this.whichDropdown == StorageName.SALEORDERPROCESS_BARCODENO_DROPDOWN){
        newDiv.innerHTML = `
        <div class="template_header">
          <div class="row mx-0">
            <div class="col-12 px-1 text-truncate">Tag No</div>
          </div>
        </div>
      `;
      }
      else if(this.whichDropdown == StorageName.ALL_ACCOUNTLEDGER_DROPDOWN){
        newDiv.innerHTML = `
        <div class="template_header">
          <div class="row mx-0">
            <div class="col-8 ps-1 text-truncate">Name</div>
            <div class="col-4 pe-0 ps-0 text-truncate">Contact</div>
          </div>
        </div>
      `;
      }

      
  
      this.renderer.insertBefore(targetDiv, newDiv, targetDiv.firstChild);
    } 
    // this.keydownListener = this.renderer.listen('document', 'keydown', (event) => {    //need to improve
    //   this.onKeydown(event);
    // });

    this.addEnterKeydownListener('select2-search__field', (event) => {
       this.onKeydown(event);
    });
  }


  setDataSource(datasorce ,newCreatedOptionId?): void {
    this.dropdown = datasorce;
    console.log("ghsddshgdsjhdsjh", this.dropdown);

    this.data3 = this.dropdown as Select2Data;

    if(this.whichDropdown === StorageName.ACCOUNTLEDGER_DROPDOWN){
      this.data3 = this.dropdown.map(item => ({
        value: item.id,
        label: '<div class="select2-multiple-labels">' +
          '<div class="row">' +
          '<div class="col-8 pe-0 text-truncate">' + item.name + '</div>' +
          '<div class="col-4 pe-0">' + item.extra2 + '</div>' +
          '</div>' +
          '</div>'
      })) as Select2Data;
    }
    else if(this.whichDropdown === StorageName.PRODUCT_DROPDOWN){
      this.data3 = this.dropdown.map(item => ({
        value: item.id,
        label: '<div class="select2-multiple-labels">' +
          '<div class="row">' +
          '<div class="col-8 pe-0 text-truncate">' + item.name + '</div>' +
          '</div>' +
          '</div>'
      })) as Select2Data;
    }
    else if(this.whichDropdown === StorageName.SALEPERSON_DROPDOWN){
      this.data3 = this.dropdown.map(item => ({
        value: item.id,
        label: '<div class="select2-multiple-labels">' +
          '<div class="row">' +
          '<div class="col-12 pe-0 text-truncate">' + item.name + '</div>' +
          '</div>' +
          '</div>'
      })) as Select2Data;
    }
    else if(this.whichDropdown === StorageName.INVOICE_NO_DROPDOWN){
      this.data3 = this.dropdown.map(item => ({
        // value: item.id,
        value: item.name,
        label: '<div class="select2-multiple-labels">' +
          '<div class="row">' +
          '<div class="col-12 pe-0 text-truncate">' + item.name + '</div>' +
          '</div>' +
          '</div>'
      })) as Select2Data;
    }
    else if(this.whichDropdown === StorageName.PURCHASEINVOICENO_DROPDOWN){
      this.data3 = this.dropdown.map(item => ({
        value: item.id,
        label: '<div class="select2-multiple-labels">' +
          '<div class="row">' +
          '<div class="col-12 pe-0 text-truncate">' + item.name + '</div>' +
          '</div>' +
          '</div>'
      })) as Select2Data;
    }
    else if(this.whichDropdown === StorageName.SALEORDERPROCESS_BARCODENO_DROPDOWN){
      this.data3 = this.dropdown.map(item => ({
        value: item.id,
        label: '<div class="select2-multiple-labels">' +
          '<div class="row">' +
          '<div class="col-12 pe-0 text-truncate">' + item.name + '</div>' +
          '</div>' +
          '</div>'
      })) as Select2Data;
    }
    else if(this.whichDropdown === StorageName.ALL_ACCOUNTLEDGER_DROPDOWN){
      this.data3 = this.dropdown.map(item => ({
        value: item.id,
        label: '<div class="select2-multiple-labels">' +
          '<div class="row">' +
          '<div class="col-8 pe-0 text-truncate">' + item.name + '</div>' +
          '<div class="col-4 pe-0">' + item.extra2 + '</div>' +
          '</div>' +
          '</div>'
      })) as Select2Data;
    }
   
    this.data3 = [
      {
        value: '',
        label: ''
      },

      ...this.data3, // Rest of the options
    ];
    console.log(this.data3);
    if(this.whichDropdown === StorageName.ACCOUNTLEDGER_DROPDOWN){
      this.id = this.formModel?.invoiceModel?.accountLedgerId;
    }
    
    // else if(this.whichDropdown === StorageName.PRODUCT_DROPDOWN){
    //     this.id = this.catalogueModel?.productDetailId;
    // }
    if(newCreatedOptionId){
      this.setDropdownText(newCreatedOptionId)
    }
  }

  ngOnInit() {

    //  this.setAccountLedgerDropdownText();
  }

  getInvoiceResponse(result) {
    //this.selectedValue = result.invoiceModel.accountLedgerId;
    //  this.displayText = result.invoiceModel.accountLedgerName;
    this.setDropdownText(result);
  }


  update(key: string, event: Select2UpdateEvent<any>) {
    this[key] = event.value;

    const searchText = event.component.searchText?.toLowerCase();

    if (searchText) {
        // Check if the searchText exists in either dropdown or data3
      const existsInDropdown = this.dropdown.some(x => x.name.toLowerCase().includes(searchText.toLowerCase()));
      const existsInData3 = this.data3.some(x => x.label.includes(event.component.searchText));
        if (!existsInDropdown && !existsInData3) {
            // Add new entry if searchText is not found=
            const newEntry = {
                value: this.data3.length, // Assign specific value if needed
                label: `
                    <div class="select2-multiple-labels">
                        <div class="row">
                            <div class="col-8 pe-0 text-truncate">${event.component.searchText}</div>
                            <div class="col-4 pe-0"></div>
                        </div>
                    </div>`
            };

            this.data3.push(newEntry);
            this.setDropdownText(newEntry.value);
            this.valueChanged.emit(newEntry.value);
        } else {
            this.setDropdownText(event.value);
            this.valueChanged.emit(event.value);
        }
    } else {
        this.setDropdownText(event.value);
        this.valueChanged.emit(event.value);
    }

    // #region Show Title on Hover
    this.updateTooltip('selec2-2');
    // #endregion
}

// method for updating tooltip
private updateTooltip(elementId: string): void {
    const element = document.getElementById(elementId);
    if (element) {
        const renderedElement = element.querySelector('.select2-selection__rendered');
        if (renderedElement) {
            setTimeout(() => {
                const htmlString = renderedElement.getAttribute('title');
                if (htmlString) {
                    const tempDiv = document.createElement('div');
                    tempDiv.innerHTML = htmlString;
                    const name = tempDiv.querySelector('.col-8.pe-0.text-truncate')?.textContent;
                    const phoneNumber = tempDiv.querySelector('.col-4.pe-0')?.textContent;
                    const combineTitle = `Name: ${name ?? 'NA'}, Contact: ${phoneNumber ?? 'NA'}`;
                    renderedElement.setAttribute('title', combineTitle);
                }
            }, 300);
        }
    }
}

  

  setDropdownText(value) {
    this.id = value;
  }
  
  reset(){
    this.select2Ref.reset();
  }


  search(key: string, event: Select2SearchEvent) {

    event.filteredData(
      event.search
          ? event.data.filter(option => option.label.toLowerCase().includes(event.search.toLowerCase()))
          : event.data,
        );

   
}

close(event){
  console.log(event)
}

onKeydown(event: KeyboardEvent): void {
  const target = event.target as HTMLInputElement;

  if (event.key === 'Enter') {
    this.select2Ref?.toggleOpenAndClose(false, false);
    this.focusOnMatchingOption(target.value);
    console.log('Toggle');
  } 
  // else if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
  //   const way: 'up' | 'down' = event.key === 'ArrowUp' ? 'up' : 'down';
  //   const search: string = ''; // You may use the current search text if needed
  //   const data: Select2Data = this.data3; // Use the current data

  //   // Trigger the scroll event programmatically
  //   this.handleScroll({ component: this.select2Ref, way, search, data });

  //   // Prevent the default behavior of arrow keys (e.g., scrolling the page)
  //   event.preventDefault();
  // }
}




handleScroll(event: Select2ScrollEvent): void {
  // Handle the scroll event here
  console.log('Scroll event:', event);

  // Example: Load more data when scrolling down
  if (event.way === 'down') {
    // Add your logic to load more data or perform other actions
    console.log('Scrolled down. Loading more data...');
  }

  // Example: Load more data when scrolling up
  if (event.way === 'up') {
    // Add your logic to load more data or perform other actions
    console.log('Scrolled up. Loading more data...');
  }
}


focusOnMatchingOption(searchKey: string) {
  if (this.select2Ref) {
    const matchingOption:any = this.select2Ref?.filteredData.find(option => {
      return option.label.toString()?.toLowerCase()?.includes(searchKey?.toLowerCase());
    });
      if(matchingOption){
        if(this.select2Ref?.filteredData?.length == 1){
         this.setDropdownText(matchingOption?.value)
      }
    }else{
          this.keyEnter.emit(searchKey);
      }
    }
  }

  addEnterKeydownListener(className: string, callback: (event: KeyboardEvent) => void) {
    const elements = document.querySelectorAll('.' + className);
    elements.forEach((element: HTMLElement) => {
      const listener = this.renderer.listen(element, 'keydown', (event) => {
        if (event.key === 'Enter') {
          clearTimeout(this.debounceTimer);
          this.debounceTimer = setTimeout(() => {
            callback(event);
          }, 300); // Adjust the debounce delay as needed
        }
      });
    });
  }

  handleKeyDown(event: KeyboardEvent): void {
     const select2Element = document.getElementById('selec2-2');
    //   let a  = select2Element.childNodes[1];
    //   let b = a.childNodes[1]
    //   let c = b.childNodes[0];
    //   let d:any = c.childNodes[1];
    //   let e = d.childNodes[1]
    //  let  classNmae = e.className
    //   console.log();
    //   const ulElement = this.el.nativeElement.querySelector(`.${classNmae}`);
    //   if (ulElement) {
    //     const itemHeight = 5;
    //     const visibleItems = Math.floor(ulElement.clientHeight / itemHeight);
    //     if (event.key === 'ArrowDown') {
    //       ulElement.scrollTop += itemHeight;
    //     } else if (event.key === 'ArrowUp') {
    //       ulElement.scrollTop -= itemHeight;
    //     }
    //   }

      const highlightedOptions = select2Element.querySelectorAll('.select2-results__option--highlighted');
    
    if (highlightedOptions.length > 0  ) {
    } else {
       this.onDropdownOpened('oened')
    }


  }
 
  ngOnDestroy() {
    this.getInvoiceSubscription?.unsubscribe();
  }

  
}

