import { PlusIconService } from './plus-icon.service';
import { Component, Input } from '@angular/core';
import { IHeaderAngularComp } from 'ag-grid-angular';
import { IHeaderParams } from 'ag-grid-community';

@Component({
  selector: 'app-plus-icon',
  templateUrl: './plus-icon.component.html',
  styleUrls: ['./plus-icon.component.scss']
})
export class PlusIconComponent implements IHeaderAngularComp {
  public params: IHeaderParams;
  @Input() context: string;
  constructor(private plusIconService: PlusIconService){

  }

  agInit(params: IHeaderParams): void {
    this.params = params;
    this.context = params.column.getColId(); 
  }

  refresh(): boolean {
    return false;
  }

  onIconClick() {
    this.plusIconService.emitPlusIconClick(this.context); 
  }
}