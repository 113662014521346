export class BusinessRegistration {

}

export class RegisterUserResponseM 
{
  userId                  : string | null;
  productKey              : string | null = null;
  licenseKeySecurityToken : string | null = null;
  registrationStepDone    : number;
  user                    : RegisteredUserVM = new RegisteredUserVM();
}

export class RegisteredUserVM 
{
  firstname           : string | null = null;
  lastname            : string | null = null;
  dob                 : string | null = null;
  contactNo           : number | null = null;
  dialCode            : string | null = null;
  email               : string | null = null;
  username            : string | null = null;
  password            : string | null = null;
  shopName            : string | null = null;
  officeNo            : string | null = null;
  businessLicenceNo   : string | null = null;
  panCard             : string | null = null;
  registrationDate    : string | null = null;
  registrationCountry : string | null = null;
  jurisdiction        : string | null = null;
  comment             : string | null = null;
  businessEmail       : string | null = null;
  gstTrn              : string | null = null;
  companyLogo         : string | null = null;
  slogan              : string | null = null;
  installedBy         : number | null = null;
  addressLine1        : string | null = null;
  addressLine2        : string | null = null;
  countryId           : number | null = null;
  country             : string | null = null;
  stateId             : any    | null = null;
  state               : string | null = null;
  districtId          : number | null = null;
  district            : string | null = null;
  taluka              : string | null = null;
  pinCode             : number | null = null;
  bankAccountNumber   : string | null = null;
  branchName          : string | null = null;
  bankName            : string | null = null;
  accountHolderName   : string | null = null;
  ifscCode            : string | null = null;
  crid                : string | null = null;
  parentCompanyId     : number | null = null;
} 

export class RegisterUserRequestModel 
{
    licenseKeySecurityToken : string;
    userId                  : string | null = null;
    registrationStep        : number;
    user                    : RegisteredUserVM;
}

export class CompanyDetails 
{
    shopName                : string;
    businessLicenceNo       : string | null;
    panCard                 : string | null;
    registrationDate        : string | null;
    registrationCountry     : string | null;
    jurisdiction            : string | null;
    comment                 : string | null;
    officeNo                 : string | null;
    email                   : string | null;
    country                 : string | null;
    state                   : string;
    pinCode                 : string | null;
    district                : string | null;
    taluka                  : string | null;
    addressLine1            : string | null;
}
export class GetRegionalInformationResponse {
  countryName: string | null;
  countryCode: string | null;
  flag: string | null;
  stateList: DropdownModel[];
  districtList: DropdownModel[];
  countryId:any;
}

export class DropdownModel {
  id: number;
  name: string | null;
  extra1: string | null;
  extra2: string | null;
}