<div class="modal-header d-flex align-items-center justify-content-center position-relative">
    <div><span class="font-semibold text-primary">Material Receive</span></div>
    <app-svg-icon [name]="'cross_button_border'" class="cross-button-border"></app-svg-icon>
    <app-svg-icon [name]="'cross'" class="cross-button" (click)="activeModal.dismiss()"></app-svg-icon>
</div>
<form class="theme-form" ngNativeValidate #ManufacturingOutForm="ngForm" autocomplete="off">
    <div class="modal-body mx-2">
        <div class="row mt-2">
            <div class="col">
                <div class="row mb-3">
                    <div class="col-2">
                        <label for="FromDept" class="f-14 font-semibold text-gray-color text-nowrap mb-0 w-50">From
                            Dept.</label>
                    </div>
                    <div class="col-10 ps-3">
                        <app-select [options]="" [id]="'FromDept'" [name]="'FromDept'" [isDisabled]="false"
                            [isRequired]="true" (keydown.enter)="onKeydown($event, 'FromUser')">
                        </app-select>
                    </div>
                </div>
                <div class="row mb-2">
                    <div class="col-2">
                        <label for="FromUser" class="f-14 font-semibold text-gray-color text-nowrap mb-0 w-50">From
                            User.</label>
                    </div>
                    <div class="col-10 ps-3">
                        <app-select [options]="" [id]="'FromUser'" [name]="'FromUser'" [isDisabled]="false"
                            [isRequired]="true" (keydown.enter)="onKeydown($event, 'ToDept')">
                        </app-select>
                    </div>
                </div>
            </div>
            <div class="col-2 d-flex align-items-center justify-content-center">
                <div class="row">
                    <div class="col-12">
                        <app-svg-icon name="{{toogleExchangeIcon?'lefttorightArrow':'righttoleftArrow'}}"
                            class="cursorPointer"
                            (click)="exchangeData();toogleExchangeIcon=!toogleExchangeIcon"></app-svg-icon>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="row mb-3">
                    <div class="col-2">
                        <label for="ToDept" class="f-14 font-semibold text-gray-color text-nowrap mb-0 w-50">To Dept.
                        </label>
                    </div>
                    <div class="col-10">
                        <app-select [options]="" [id]="'ToDept'" [name]="'ToDept'" [isDisabled]="false"
                            [isRequired]="true" (keydown.enter)="onKeydown($event, 'ToUser')">
                        </app-select>
                    </div>
                </div>
                <div class="row mb-2">
                    <div class="col-2">
                        <label for="ToUser" class="f-14 font-semibold text-gray-color text-nowrap mb-0 w-50">To
                            User</label>
                    </div>
                    <div class="col-10">
                        <app-select [options]="" [id]="'ToUser'" [name]="'ToUser'" [isDisabled]="false"
                            [isRequired]="true">
                        </app-select>
                    </div>
                </div>
            </div>
            <div class="col-3">
                <div class="row">
                    <div class="col-6 d-flex gap-2">
                        <div class="mt-1">
                            <label for="date" class="f-14 font-semibold text-gray-color text-nowrap mb-0">Date</label>
                        </div>
                        <div class="w-100">
                            <app-input-datepicker-renderer  (dateChanged)="selectedRangeDates($event)"></app-input-datepicker-renderer>
                        </div>
                    </div>
                    <div class="col-6 d-flex gap-2">
                        <div class=" mt-1">
                            <label for="time" class="f-14 font-semibold text-gray-color text-nowrap mb-0">Time</label>
                        </div>
                        <div class="w-100">
                            <div class="input-group ps-1 time_picker">
                                <input class="form-control f-12" type="time" placeholder="" aria-label="time">
                              </div>                
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <hr class="m-0 gray-border-color">
        <div class="row mx-0 gap-2 flex-fill">
           
            <div class="col-10 d-flex flex-column h-100 p-0">
                <h5 class="text-primary font-semibold f-16 mb-0 p-2">
                    Product List
                </h5>
            <div class="h-100 position-relative">
              <app-ag-grid
                id="manufacturing_out"
                class="all_aggrid_tables" 
                [dataModel]="agGridDataModelProductList"
                [rowData]="getAllProductListRowData"
                [gridOptions]="gridOptionsProductList" 
                (gridReady)="onGridReady($event)"
                [columnSettings]="true"
               ></app-ag-grid>
              </div>
            </div>
            <div class="col">
                <h5 class="text-primary font-semibold f-16 mb-0 p-2">
                    Extra
                </h5>
                <div class="row border-gray-2">
                    <div class="col-12 d-flex justify-content-between my-1">
                        <div class="d-flex col-5 align-items-center w-50">
                            <label for="" class="text-gray-color font-regular mb-0 f-12 mb-0">Purity %</label>
                        </div>
                        <div class="d-flex col justify-content-end">
                            <app-input 
                                [inputClass]="'text-end'" 
                                [inputName]="''"
                                [decimalPlaces]="2" 
                                [inputType]="'text'"
                                (keydown.enter)="onKeydown($event, 'R_invoice2')" 
                                [inputId]="'R_invoice'"
                                [inputValue]="'0.00'"
                                >
                            </app-input>
                        </div>
                    </div>
                    <div class="col-12 d-flex justify-content-between my-1">
                        <div class="d-flex col-5 align-items-center w-50">
                            <label for="" class="text-gray-color font-regular mb-0 f-12 mb-0">Purity %</label>
                        </div>
                        <div class="d-flex col justify-content-end">
                            <app-input 
                                [inputClass]="'text-end'" 
                                [inputName]="''"
                                [decimalPlaces]="2" 
                                [inputType]="'text'"
                                (keydown.enter)="onKeydown($event, 'R_invoice2')" 
                                [inputId]="'R_invoice'"
                                [inputValue]="'0.00'"
                                >
                            </app-input>
                        </div>
                    </div>
                    <div class="col-12 d-flex justify-content-between my-1">
                        <div class="d-flex col-5 align-items-center w-50">
                            <label for="" class="text-gray-color font-regular mb-0 f-12 mb-0">Purity Wt</label>
                        </div>
                        <div class="d-flex col justify-content-end">
                            <app-input 
                                [inputClass]="'text-end'" 
                                [inputName]="''"
                                [decimalPlaces]="2" 
                                [inputType]="'text'"
                                (keydown.enter)="onKeydown($event, 'R_invoice2')" 
                                [inputId]="'R_invoice'"
                                [inputValue]="'0.00'"
                                >
                            </app-input>
                        </div>
                    </div>
                    <div class="col-12 d-flex justify-content-between my-1">
                        <div class="d-flex col-5 align-items-center w-50">
                            <label for="" class="text-gray-color font-regular mb-0 f-12 mb-0">Purity Rq. %</label>
                        </div>
                        <div class="d-flex col justify-content-end">
                            <app-input 
                                [inputClass]="'text-end'" 
                                [inputName]="''"
                                [decimalPlaces]="2" 
                                [inputType]="'text'"
                                (keydown.enter)="onKeydown($event, 'R_invoice2')" 
                                [inputId]="'R_invoice'"
                                [inputValue]="'0.00'"
                                >
                            </app-input>
                        </div>
                    </div>
                    <div class="col-12 d-flex justify-content-between my-1">
                        <div class="d-flex col-5 align-items-center w-50">
                            <label for="" class="text-gray-color font-regular mb-0 f-12 mb-0">Std. Wt</label>
                        </div>
                        <div class="d-flex col justify-content-end">
                            <app-input 
                                [inputClass]="'text-end'" 
                                [inputName]="''"
                                [decimalPlaces]="2" 
                                [inputType]="'text'"
                                (keydown.enter)="onKeydown($event, 'R_invoice2')" 
                                [inputId]="'R_invoice'"
                                [inputValue]="'0.00'"
                                >
                            </app-input>
                        </div>
                    </div>
                    <div class="col-12 d-flex justify-content-between my-1">
                        <div class="d-flex col-5 align-items-center w-50">
                            <label for="" class="text-gray-color font-regular mb-0 f-12 mb-0">Final Wt</label>
                        </div>
                        <div class="d-flex col justify-content-end">
                            <app-input 
                                [inputClass]="'text-end'" 
                                [inputName]="''"
                                [decimalPlaces]="2" 
                                [inputType]="'text'"
                                (keydown.enter)="onKeydown($event, 'R_invoice2')" 
                                [inputId]="'R_invoice'"
                                [inputValue]="'0.00'"
                                >
                            </app-input>
                        </div>
                    </div>
                    <div class="col-12 d-flex justify-content-between my-1">
                        <div class="d-flex col-5 align-items-center w-50">
                            <label for="" class="text-gray-color font-regular mb-0 f-12 mb-0">Actual Purity %</label>
                        </div>
                        <div class="d-flex col justify-content-end">
                            <app-input 
                                [inputClass]="'text-end'" 
                                [inputName]="''"
                                [decimalPlaces]="2" 
                                [inputType]="'text'"
                                (keydown.enter)="onKeydown($event, 'R_invoice2')" 
                                [inputId]="'R_invoice'"
                                [inputValue]="'0.00'"
                                >
                            </app-input>
                        </div>
                    </div>
                    <div class="col-12 d-flex justify-content-between my-1">
                        <div class="d-flex col-5 align-items-center w-50">
                            <label for="" class="text-gray-color font-regular mb-0 f-12 mb-0">Alloy Wt</label>
                        </div>
                        <div class="d-flex col justify-content-end">
                            <app-input 
                                [inputClass]="'text-end'" 
                                [inputName]="''"
                                [decimalPlaces]="2" 
                                [inputType]="'text'"
                                (keydown.enter)="onKeydown($event, 'R_invoice2')" 
                                [inputId]="'R_invoice'"
                                [inputValue]="'0.00'"
                                >
                            </app-input>
                        </div>
                    </div>
                    <div class="col-12 d-flex justify-content-between my-1">
                        <div class="d-flex col-5 align-items-center w-50">
                            <label for="" class="text-gray-color font-regular mb-0 f-12 mb-0">Notional Melt</label>
                        </div>
                        <div class="d-flex col justify-content-end">
                            <app-input 
                                [inputClass]="'text-end'" 
                                [inputName]="''"
                                [decimalPlaces]="2" 
                                [inputType]="'text'"
                                (keydown.enter)="onKeydown($event, 'R_invoice2')" 
                                [inputId]="'R_invoice'"
                                [inputValue]="'0.00'"
                                >
                            </app-input>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    </div>
    <div class="modal-footer mx-2">
        <div class="d-flex justify-content-end text-end gap-3 my-2">
            <app-button class="" [buttonLabel]="'Close'" [buttonClass]="'white_button'" [buttonType]="'button'"  (buttonOutputEvent)="close()">
            </app-button>
            <app-button class="" [buttonLabel]="'Save'" [buttonClass]="'blue_button'" [buttonType]="'button'" (buttonOutputEvent)="save()">
            </app-button>
        </div>
    </div>
</form>