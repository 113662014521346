import { Component, NgZone } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { AgGridService } from '../ag-grid.service';

export interface Controls{
  isReadonly:boolean;
}

@Component({
  selector: 'app-radio-renderer',
  templateUrl: './radio-renderer.component.html',
  styleUrls: ['./radio-renderer.component.scss']
})
export class RadioRendererComponent implements ICellRendererAngularComp{
  params: any;
  labelName:string ='';
  showLabel: boolean = true; 
  isReadonly:boolean=true;
  constructor(private zone: NgZone , public agGridService : AgGridService) {}

  agInit(params: any): void {
    this.params = params;
    this.labelName = this.params.data.name;
    this.showLabel = this.params.showLabel !== undefined ? this.params.showLabel : true;
    this.isReadonly = this.params.isReadonly !== undefined ? this.params.isReadonly : true;
  }
  refresh(params: any): boolean {
    this.params = params;
    this.labelName = this.params.data.name;
    this.showLabel = this.params.showLabel !== undefined ? this.params.showLabel : true;
    this.isReadonly = this.params.isReadonly !== undefined ? this.params.isReadonly : true;
    
    return true;
  }

  // onRadioChange(): void {
  //   const newValue = !this.params.value; // Toggle the boolean value
  //   this.params.data.isSelected = newValue;
  //   this.params.api.refreshCells();
  // }


  onRadioChange(){
    this.agGridService.onChangeRadioSelected(this.params);
  }
  
}
