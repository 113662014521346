import { Directive, HostListener, ElementRef } from '@angular/core';
import emojiRegex from 'emoji-regex';

@Directive({
  selector: '[noEmoji]'
})
export class NoEmojiDirective {
  // Emoji regex pattern
  private emojiRegex = emojiRegex();

  constructor(private el: ElementRef) {}

  @HostListener('input', ['$event']) onInput(event: InputEvent) {
    this.removeEmojis(event);
  }

  @HostListener('paste', ['$event']) onPaste(event: ClipboardEvent) {
    setTimeout(() => this.removeEmojis(event));
  }

  private removeEmojis(event: Event) {
    const input = this.el.nativeElement;
    const originalValue = input.value;
    const newValue = originalValue.replace(this.emojiRegex, '');

    if (originalValue !== newValue) {
      input.value = newValue;
      event.preventDefault();
      event.stopImmediatePropagation();
    }
  }
}
