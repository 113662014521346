import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { OrderTrackingComponent } from 'src/app/components/orders/order-tracking/order-tracking.component';
import { SaleOrderProcessService } from 'src/app/components/orders/sale-order-process/sale-order-process.service';
import { StorageName } from 'src/app/shared/enum/common-enum';
import { StorageService } from 'src/app/shared/services/storage.service';
export interface Icons{
  Barcode : boolean;
  isZebra: boolean;
  Loanssvg: boolean;
 }
@Component({
  selector: 'app-track-order-cellrender',
  templateUrl: './track-order-cellrender.component.html',
  styleUrls: ['./track-order-cellrender.component.scss']
})
export class TrackOrderCellrenderComponent {
  params:any;
  PrintSvg:boolean = false
  constructor( 
    private storageService    : StorageService,
    private router                : Router, 
    private modalService          : NgbModal,
    private saleOrderProcessService : SaleOrderProcessService,
    ){}


  agInit(params: any): void {
    this.params = params;

  }
 
  refresh(params: any): boolean {
    return false;
  }

  navitageToOrderTracking(params)
  {;
     this.openOrderTrackingPopUp(params)
  }

  openOrderTrackingPopUp(params){
    const modalOption: NgbModalOptions = {
      centered: true,
      backdrop: "static",
      keyboard: false,
      size:'xl'
    };
    const modalRef = this.modalService.open(OrderTrackingComponent, modalOption);
    modalRef.componentInstance.hide_Header = true;
    modalRef.componentInstance.data = params?.data;
    modalRef.result.then((result) => {
      if (result) 
        console.log(result);
    },);
  }

  onClickBarcodeIcon(params){
    const customEvent = new CustomEvent('barcodeIconClick', {
      detail: { data: params },
    });
    this.params.api.dispatchEvent(customEvent);
   // this.storageService.cellRendererIconClicked.next(this.params)
  }
  onClickZebraprintIcon(params){
    const customEvent = new CustomEvent('printButtonClick', {
      detail: { data: params },
    });
    this.params.api.dispatchEvent(customEvent);
  }
  onClickExpandLoans(params){
    const customEvent = new CustomEvent('expandableIconClick', {
      detail: { data: params },
    });
    this.params.api.dispatchEvent(customEvent);
  }
  AddOnLoans(){
    this.storageService.AddOnLoansClick.next(this.params)
  }
  EditLoans(params){
    const customEvent = new CustomEvent('editIconClick', {
      detail: { data: params },
    });
    this.params.api.dispatchEvent(customEvent);
  }
  onClickPayInterest(){
    this.storageService.PayInterestClick.next(this.params)
  }
  onClickPrintIcon(params) {
    console.log(params.data);
    const customEvent = new CustomEvent('printButtonClick', {
      detail: { data: params },
    });
    this.params.api.dispatchEvent(customEvent);
  }

  openDeleteConfirmationPopup(content,params)
  {
    let pay = params
    this.modalService.open(content, { windowClass: "delet-confirm-popup-w" , centered:true,  backdrop: "static",
    });
  }

  deleterowdata(params,modal)
  {
    params.api.deselectAll();
    params.node.setSelected(true);
     const selectedNode = params.api.getSelectedRows();
     modal?.dismiss('Cross click');
     this.params.api.applyTransaction({ remove: selectedNode});
     params.api.deselectAll();

     const custoDeleteEvent = new CustomEvent('deleteButtonClick', {
      detail: { data: params },
    });
    this.params.api.dispatchEvent(custoDeleteEvent);
  }
  
}
