<div class="d-flex gap-3 commonInputContainer align-items-center w-100 position-relative">
    <label *ngIf="inputLabel" for="exampleFormControlInput1" class="text-nowrap mb-0 w-50">{{inputLabel}}</label>
    <input 
        autocomplete="off"
        [type]="inputType" 
        [id]="inputId" 
        [name]="inputName" 
        [placeholder]="inputPlaceholder"
        [maxlength]="inputMaxlength"
        [(ngModel)]="inputValue" 
        [class]="inputClass +' form-control'"
        (ngModelChange)="inputValueEmit.emit(inputValue)"
        [required]="isRequired"
        [disabled]="disabled"
        [readOnly]="readOnly" 
        [pattern]="isPassPattern?passPattern :null"
        [email]="inputType == 'email' ? true :false" 
        *ngIf="(decimalPlaces == 0 && !isOnlyNumbers && !applyDecimalOrPercent)"
    >
    <input 
        autocomplete="off"
        [type]="inputType" 
        [id]="inputId" 
        [name]="inputName" 
        [placeholder]="inputPlaceholder"
        [maxlength]="inputMaxlength"
        [(ngModel)]="inputValue" 
        [class]="inputClass +' form-control'"
        (ngModelChange)="inputValueEmit.emit(inputValue)"
        [required]="isRequired"
        [disabled]="disabled"
        [readOnly]="readOnly"
        [email]="inputType == 'email' ? true :false" 
        onlyNumbers *ngIf="isOnlyNumbers"
        >
    <input 
        autocomplete="off"
        [type]="inputType" 
        [id]="inputId" 
        [name]="inputName" 
        [placeholder]="inputPlaceholder"
        [maxlength]="inputMaxlength"
        [(ngModel)]="inputValue" 
        [class]="inputClass +' form-control'"
        (ngModelChange)="inputValueEmit.emit(inputValue)"
        [required]="isRequired"
        [disabled]="disabled"
        [readOnly]="readOnly"
        [email]="inputType == 'email' ? true :false" 
        appNumberWithDecimal *ngIf="decimalPlaces > 0"
        [decimalPlaces]="decimalPlaces"
        >
    <input 
        autocomplete="off"
        [type]="inputType" 
        [id]="inputId" 
        [name]="inputName" 
        [placeholder]="inputPlaceholder"
        [maxlength]="inputMaxlength"
        [(ngModel)]="inputValue" 
        [class]="inputClass +' form-control'"
        (ngModelChange)="inputValueEmit.emit(inputValue)"
        [required]="isRequired"
        [disabled]="disabled"
        [readOnly]="readOnly" 
        [pattern]="isPassPattern?passPattern :null"
        [email]="inputType == 'email' ? true :false" 
        [ngClass]="{'readonly-field': readOnly}"
        AppTwoDigitDecimaNumber
        [isPercentApplicable]="isPercentApplicable"
        *ngIf="applyDecimalOrPercent"
        >   
        <button *ngIf="isPercentIcon" class="Icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="20" viewBox="0 0 14 20">
                <text id="_" data-name="%" transform="translate(7 16)" fill="#455472" font-size="16" font-family="SourceSansPro-Semibold, Source Sans Pro" font-weight="100" letter-spacing="0.01em"><tspan x="-6.728" y="0">%</tspan></text>
            </svg>
        </button>
        <button *ngIf="!isPercentIcon" class="Icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="10" height="20" viewBox="0 0 10 21">
                <text id="_" data-name="₹" transform="translate(5 17)" fill="#455472" font-size="16" font-family="SegoeUI, Segoe UI" letter-spacing="0.01em"><tspan x="-4.313" y="0">₹</tspan></text>
            </svg>
        </button>
        <button id="{{'hidden'+inputName}}" class="position-absolute top-0 end-0 opacity-0"></button>
</div>


