<!-- page-wrapper Start-->
<div [ngClass]="layout.config.settings.layout_type">
  <div class="page-wrapper px-3 pt-1" id="canvas-bookmark" [ngClass]="layoutClass">
   
    <!-- Page Body Start-->
    <div class="page-body-wrapper flex-fill d-flex flex-column">
      <div class="maximize_wrapper" [ngClass]="{'d-none': isSidebarMaximized == true}">
        <div class="px-2 pt-2">
          <app-header></app-header>
        </div>
        <div *ngIf="!this.currentUrl?.includes('/add-update-profile')"
        class="sidebar-wrapper" [attr.sidebar-layout]="layout.config.settings.sidebar_type" [attr.icon]="layout.config.settings.icon" [class.close_icon]="navServices.collapseSidebar">
          <app-sidebar></app-sidebar>
        </div>
      </div>
      <div class="page-body flex-fill page-scroll" >
        <main class="h-100" [@fadeInAnimation]="getRouterOutletState(o)">
          <router-outlet #o="outlet"></router-outlet>
        </main>
      </div>
    </div>
    <!-- Page Body End-->
    <!-- footer start-->
    <footer class="footer px-0" [ngClass]="{'d-none': isSidebarMaximized == true}">
      <app-footer></app-footer>
    </footer>
    <!-- footer End-->
  </div>
</div>
<div class="bg-overlay1"></div>
<!-- page-wrapper End-->
