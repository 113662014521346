
export enum TransactionType{
    CR=1,
    DR=2
}

export enum MakingChargeType
{
    FIX = 1,
    PG,
    PP,
    PKg,
    PER_Percent,
    MRP,
    MKT,
}


export enum NotificationServiceTypes
{
    SMS = 1,
    WhatsApp = 2,
    Email = 3,
}

export enum CalculationType
{
    Fix = 10,
    CaratRateWise = 20
}

export enum SettingTypeKeys
{
    //PrinterSetting
    DefaultInvoicePrinter = 1,
    DefaultBarcodePrinter = 2,
    DirectInvoicePrint = 3,
    DirectBarcodePrint = 4,
    PrintFormat = 30,

    //VoucherSetting
    InvoiceSeriesMetalWise = 5,
    InvoiceSeriesAutomatic = 6,
    HallmarkQuantityWise = 7,
    HallmarkFixAmount = 8,
    ItemAsPopup = 9,
    // SendInvoiceOnEmail = 10,
    // SendInvoiceOnWhatsapp = 11,

    //Accounting
    FinancialCalculationMode = 12,

    //Making
    MakingDiscount = 13,
    MakingOn = 14,
    MinimumAllowedDiscount = 15,
    MaximumAllowedDiscount = 16,

    //Adjust Amount On
    // AdjustAmountForReverseOn = 17,
    NoOfDecimal = 18,

    //Ecommerce Integration
    WooCommerce = 19,
    Shopify = 20,
    DefaultBarcodeLabelSize = 21,
    MailConfiguration = 22,
    SMSConfiguration = 23,
    WhatsAppConfiguration = 24,
    PlanetPayment = 26,
    
     //Payment Integration
    PaymentIntegration = 27,
    ZebraZPL = 28,
    Barcode = 29
}

export enum CalculateOn
{
    ProductAmount = 1,

    InvoiceAmount,

    HallmarkAmount,

    MakingCharge,

    MetalExchange,

    GoldAmount
}


export enum AgGridDropdown{
    UNIT = "Unit",
    CARAT = "Carat",
    METAL ="Metal",
    PRODUCT ="PRODUCT"
}


export enum CountryCode{
    IN="IN"
  }

export enum RoleName{  
    Admin = 'Admin',
    // BranchManager = 'Branch Manager',
    // SalesPerson = 'Sales Person',
    // SalePerson = 'Sale Person',
    // Manager = 'Manager',
    // TableHost = 'Table Host',
    // Custom = 'Custom',
    // Buyer = 'Buyer',
}

export enum LabelSize
{
    SingleBarcode = 1,
    DoubleBarcode = 2,
    XLSingleBarcode = 3,
    ZebraZpl = 4,
}

export enum FieldFrom
{
    SystemField = 1,
    Barcode = 2,
    QRCode = 3
}

export enum PaymentMethod
{
    Cash = 10,
    Bank = 20,
    Cheque = 30,
    UPI = 40,
    Card = 50,
    M2A = 60,
    A2M = 70,
    MetalExchange = 80,
    OldJewellery = 90
}

export enum DepositInto
{
    Bank_OD_AC= 'Bank OD A/C',
    Cash_in_Hand= 'Cash-in Hand',
    Bank_Account= 'Bank Account'
}

export enum ColumnCellType
{
    DefaultColumn = "DefaultColumn",
    WtColumn = "WtColumn",
    AmountColumn = "AmountColumn",
    ClosingWtColumn = "ClosingWtColumn",
    OpeningWtColumn = "OpeningWtColumn",
    ClosingAmountColumn = "ClosingAmountColumn",
    OpeningAmountColumn = "OpeningAmountColumn",
}






export enum StorageName {
  STORED_VOUCHERTYPE_FOR_UPDATE_METALS = "STORED_VOUCHERTYPE_FOR_UPDATE_METALS",
  BANK_RECONCILATION = "Bank Reconcilation",
  USER_INFO = "User Information",
  LAYOUTDETAILS="Layout Details",
  USER_PERMISSIONS = "User Permissions",
  STORAGE_SYSTEM_FIELDS = "STORAGE_SYSTEM_FIELDS",
  PRINTER_SETTING = "PRINTER_SETTING",
//   FORMNAME_MANUFACTURER_MANUFACTURING_PROCESS = "FORMNAME_MANUFACTURER_MANUFACTURING_PROCESS",
  STORED_STOCKREPORT_CELLDATA_FORAVAILABILITY = "STORED_STOCKREPORT_CELLDATA_FORAVAILABILITY",
  STORED_JOBWORK_ORDERID = "JobworkOrderId",
  STORED_MATERIAL_ISSUEID = "STORED_MATERIAL_ISSUEID",
  STORED_MATERIAL_RECEIVEID = "STORED_MATERIAL_RECEIVEID",
  STORED_JOBWORK_ORDERID_FORRECEIVE = "STORED_JOBWORK_ORDERID_FORRECEIVE",
  STORED_STOCK_DETAILS_REPORTDATA="STORED_STOCK_DETAILS_REPORTDATA",
  STORED_JOBWORK_ORDER="STORED_JOBWORK_ORDER",
  STORED_JOBWORK_ORDER_DATA="STORED_JOBWORK_ORDER_DATA",
  STORED_JOBWORK_ITEMS_FORINVOICE = "STORED_JOBWORK_ITEMS_FORINVOICE",
  STORED_JOBWORK_INVOICEID = "STORED_JOBWORK_INVOICEID",
  STORED_STOCKJOURNAL_MOVEMENT = "STORED_STOCKJOURNAL_MOVEMENT",
  STORED_SELECTED_SALESORDER = "STORED_SELECTED_SALESORDER",
  STORED_JOBCARD_DATA="STORED_JOBCARD_DATA",
  STORED_SELECTED_SCRAP = "STORED_SELECTED_SCRAP",
  STORED_SELECTED_RECEIVED = "STORED_SELECTED_RECEIVED",
  STORED_SELECTED_REFINE = "STORED_SELECTED_REFINE",
  STORED_SELECTED_SALESORDER_FORSALEINVOICE = "STORED_SELECTED_SALESORDER_FORSALEINVOICE",
  STORED_SELECTED_MEMOREPORT_FORCONSIGNMENTIN = "STORED_SELECTED_MEMOREPORT_FORCONSIGNMENTIN",
  STORED_SELECTED_REPORT_VIEW_ALL_LEDGERAMT_LEDGERID  = "STORED_SELECTED_REPORT_VIEW_ALL_LEDGERAMT_LEDGERID",  
  STORED_SELECTED_SALESORDER_FORCONSIGNMENTOUT = "STORED_SELECTED_SALESORDER_FORCONSIGNMENTOUT",
  STORED_SELECTED_SALESORDER_FORCONSIGNMENTIN = "STORED_SELECTED_SALESORDER_FORCONSIGNMENTIN",
  STORED_TRANSACTION_MANUFACTURING_TAB_ID = "STORED_TRANSACTION_MANUFACTURING_TAB_ID",
  STORED_TRANSACTION_REPAIRINVOICEID = "STORED_TRANSACTION_REPAIRINVOICEID",
  STORED_MANUFACTURER_JOBWORK_QUEUEID = "STORED_MANUFACTURER_JOBWORK_QUEUEID",
  STORED_MANUFACTURER_JOBWORK_QUEUE_FORTRANSFER = "STORED_MANUFACTURER_JOBWORK_QUEUE_FORTRANSFER",
  STORED_SELECTED_MEMOREPORT_FORSALERETURN = "STORED_SELECTED_MEMOREPORT_FORSALERETURN",
  STORED_SELECTED_DIAMOND_STONE_STOCK="STORED_SELECTED_DIAMOND_STONE_STOCK",
  STORED_SELECTED_BILL_OD_MATERIAL="STORED_SELECTED_BILL_OD_MATERIAL",
  STORED_SELECTED_CATALOG_INFO_DATA="STORED_SELECTED_CATALOG_INFO_DATA",
  STORED_CLOSING_REPORT="STORED_CLOSING_REPORT",
  STORED_ZEBRAZPL_DROPDOWN="STORED_ZEBRAZPL_DROPDOWN",
  STORED_SALE_INVOICE_DATA="STORED_SALE_INVOICE_DATA",
  STORED_PERCHSE_INVOICE_DATA="STORED_PURCHASE_INVOICE_DATA",
  STORED_PRODUCTDETAILID_FOR_FIXING = "STORED_PRODUCTDETAILID_FOR_FIXING",
  STORAGE_STATIC_DROPDOWN = "STORAGE_STATIC_DROPDOWN",
  STORED_TRANSACTION_SALESQUOTATION_ID = "STORED_TRANSACTION_SALESQUOTATION_ID",
  STORED_TRANSACTION_DELIVERYNOTE_ID = "STORED_TRANSACTION_DELIVERYNOTE_ID",


  //#region FORMNAME
  FORMNAME_RETAILER = 'Retailer Dashboard',
  FORMNAME_CUSTOMER = 'Customer Dashboard',
  FORMNAME_MANUFACTURING = 'Manufacturing',
  FORMNAME_STOCK = 'Stock Dashboard',
  FORMNAME_SALEPERSON = 'Saleperson Dashboard',

  FORMNAME_DASHBOARD = 'Dashboard',

  FORMNAME_BUSINESSREGISTRATION = 'Business Registration',

  FORMNAME_TRANSACTION_SALESINVOICE = 'Sales Invoice',
  FORMNAME_TRANSACTION_SALES_QUOTATION = 'Sales Quotation',
  FORMNAME_TRANSACTION_DELIVERY_NOTE = 'Delivery Note',
  FORMNAME_TRANSACTION_PURCHASEINVOICE = 'Purchase Invoice',
  FORMNAME_TRANSACTION_MEMO_CONSIGNMENTIN = "Memo / Consignment In",
  FORMNAME_TRANSACTION_MEMO_CONSIGNMENTOUT = "Memo / Consignment Out",
  FORMNAME_TRANSACTION_CONSIGNMENTIN="Consignment In",
  FORMNAME_TRANSACTION_CONSIGNMENTOUT="Consignment Out",
  FORMNAME_TRANSACTION_OLDJEWELRY_SCRAPINVOICE = 'Old Jewelry - Scrap Invoice',
  FORMNAME_TRANSACTION_SALESORDER = 'Sales Order',
  FORMNAME_TRANSACTION_RECEIPTVOUCHER = "Receipt Voucher",
  FORMNAME_TRANSACTION_PAYMENTVOUCHER = "Payment Voucher",
  FORMNAME_TRANSACTION_MATERIALIN = "Material In",
  FORMNAME_TRANSACTION_MATERIALOUT = "Material Out",
  FORMNAME_TRANSACTION_PURCHASEORDER = "Purchase Order",
//   FORMNAME_TRANSACTION_SALESRETURN = "Credit Note",
//   FORMNAME_TRANSACTION_PURCHASERETURN = "Debit Note",
  FORMNAME_TRANSACTION_CREDITNOTE = "Credit Note",
  FORMNAME_TRANSACTION_DEBITNOTE = "Debit Note",
  FORMNAME_TRANSACTION_SALESRETURN = "Sales Return",
  FORMNAME_TRANSACTION_PURCHASERETURN = "Purchase Return",

  FORMNAME_TRANSACTION_STOCKJOURNAL = "Stock Journal Option - 1",
  FORMNAME_TRANSACTION_STOCKJOURNAL2="Stock Journal Option - 2",
  FORMNAME_TRANSACTION_STOCKJOURNALMOVEMENT = "Stock Journal-Movement",

  FORMNAME_TRANSACTION_STOCKJOURNAL_MOVEMENTSTOCK = "Movement Stock",
  FORMNAME_TRANSACTION_STOCKJOURNAL_MOVEMENTSTOCK_SOURCE = "Movement Stock Source",
  FORMNAME_TRANSACTION_STOCKJOURNAL_MOVEMENTSTOCK_DESTINATION = "Movement Stock Destination",
  FORMNAME_TRANSACTION_STOCKJOURNAL_TRANSFER = "Transfer",
  FORMNAME_TRANSACTION_STOCKJOURNAL_TRANSFER_SOURCE = "Transfer Source",
  FORMNAME_TRANSACTION_STOCKJOURNAL_TRANSFER_DESTINATION = "Transfer Destination",
  FORMNAME_TRANSACTION_STOCKJOURNAL_STOCKOUT = "Stock Out",
  FORMNAME_TRANSACTION_PDCENTRY = "PDC Entry",
  FORMNAME_TRANSACTION_CONTRAVOUCHER = "Contra Voucher",
  FORMNAME_TRANSACTION_LOANS="Loans",
  APIREQUEST_TRANSACTION_LOANS = "APIREQUEST_TRANSACTION_LOANS",
  APIREQUEST_TRANSACTION_STOCKJOURNAL2_LIST_GRID="APIREQUEST_TRANSACTION_STOCKJOURNAL2_LIST_GRID",
  FORMNAME_TRANSACTION_BANK_RECONCILIATION = "Bank Reconciliation",
  FORMNAME_TRANSACTION_JOURNALVOUCHER = "Journal Voucher",
  FORMNAME_TRANSACTION_PURCHASEFIXING = "PurchaseFixing",
  FORMNAME_TRANSACTION_SALEFIXING = "SaleFixing",
  FORMNAME_TRANSACTION_JOBORDERLIST = "Job Order List",
  FORMNAME_TRANSACTION_JOBWORKINVOICE = "Jobwork Invoice",
  FORMNAME_TRANSACTION_JOBWORK_ORDER = "Jobwork Order",
  FORMNAME_TRANSACTION_MATERIAL_ISSUE = "Material Issue",
  FORMNAME_TRANSACTION_MATERIAL_RECEIVE = "Material Receive",
  FORMNAME_TRANSACTION_POS = "POS",
  FORMNAME_TRANSACTION_TRANSACTION_MANUFACTURING = "Jobwork Order",
  FORMNAME_TRANSACTION_SALEORDERLIST = "Sale Order Process",
  FORMNAME_TRANSACTION_JOBWORKORDERLIST = "Jobwork Order List",
  FORMNAME_TRANSACTION_REPAIRINVOICE = 'Repair Invoice',
  FORMNAME_TRANSACTION_INVESTMENTFUND = 'Investment Fund',
  FORMNAME_TRANSACTION_EXPENSES = 'Expense Invoice',
  FORMNAME_TRANSACTION_NEW_LOAN="New Loan",
  LOANS_PAY_INTEREST_GRID = "LOANS_PAY_INTEREST_GRID",
  LOANS_ADDONLIST_GRID = "LOANS_ADDONLIST_GRID",
  TRANSACTION_LOANS_LOAN_GRID="TRANSACTION_LOANS_LOAN_GRID",
  SORT_TRANSACTION_LOANS_LOAN_GRID="SORT_TRANSACTION_LOANS_LOAN_GRID",
  PAGINATION_TRANSACTION_LOANS_LOAN_GRID="PAGINATION_TRANSACTION_LOANS_LOAN_GRID",
  FORMNAME_PRODUCT_PRODUCTCKECKOUT = "Product Checkout",
  FORMNAME_PRODUCT_PRODUCTOPENING = "Product Opening",
  FORMNAME_PRODUCT_LEDGEROPENING = "Ledger Opening",
  FORMNAME_PRODUCT_ACCOUNT_LEDGER = "Account Ledger",
  FORMNAME_PRODUCT_PARTIES = "Parties",


  FORMNAME_ORDERS_CART = "Cart",
  FORMNAME_ORDERS_ORDERLIST = "Order List",
  FORMNAME_ORDERS_ORDERTRACKING = "Order Tracking",
  FORMNAME_ORDERS_JOB_ORDER = "Job Order",
  FORMNAME_ORDERS_REPAIRORDER = 'Repair Order',


  FORMNAME_FINANCIALSTATEMENT_TRAILBALANCE = "Trial Balance",
  FORMNAME_FINANCIALSTATEMENT_BALANCESHEET = "Balance Sheet",
  FORMNAME_FINANCIALSTATEMENT_PROFITLOSS = "Profit Loss",
  FORMNAME_FINANCIALSTATEMENT_CASHFLOW = "Cash Flow",
  FORMNAME_FINANCIALSTATEMENT_FUNDFLOW = "Fund Flow",
  FORMNAME_FINANCIALSTATEMENT_CHARTOFACCOUNT = "Chart Of Account",
  FORMNAME_FINANCIALSTATEMENT_TRANSACTION_REPORT="Transaction Report",
  FORMNAME_FINANCIALSTATEMENT_SALE_ANALYSIS="Sale Analysis",
  FORMNAME_FINANCIALSTATEMENT_PURCHASE_ANALYSIS="Purchase Analysis",
  APIREQUEST_FINANCIALSTATEMENT_PURCHASE_ANALYSIS="APIREQUEST_FINANCIALSTATEMENT_PURCHASE_ANALYSIS", 
  FORMNAME_FINANCIALSTATEMENT_TAXRETURN = "Tax Return",
  FORMNAME_FINANCIALSTATEMENT_TAXREPORT = "Tax Report",

  
  FORMNAME_REPORT="Report",
  FORMNAME_REPORT_INVOICE = "Invoice",
  FORMNAME_REPORT_INVOICE_INVOICES = "Transactions Report",
  FORMNAME_REPORT_INVOICE_DRAFT = "Transactions Report",
  FORMNAME_REPORT_ACCOUNTLEDGERREPORT_AMOUNT = "Acocunt Ledger Amount Report",
  FORMNAME_REPORT_ACCOUNTLEDGERREPOR ="Acocunt Ledger Report",
  FORMNAME_REPORT_ACCOUNTLEDGERREPORT_BALANCEAMOUNT = "Balance Amount",
  FORMNAME_REPORT_ACCOUNTLEDGERREPORT_BALANCEMETAL = "Balance Metal",
  FORMNAME_REPORT_ACCOUNTLEDGERREPORT_VIEWALLLEDGERAMT = "View All Ledger Amt",
  FORMNAME_REPORT_DAYREPORT = "Day Report",
  FORMNAME_REPORT_CASHBANKBOOK = "Cashbank Book",
  FORMNAME_REPORT_AGEINGREPORT = "Ageing Report",
  FORMNAME_REPORT_KYCREPORT = "Kyc Report",
//   FORMNAME_REPORT_STOCKREPORT = "Stock Report",
//   FORMNAME_REPORT_DIAMOND_STONE_STOCK = "Diamond / Stone Stock",
//   FORMNAME_REPORT_BARCODE_STOCK = "Barcode Stock",
//   FORMNAME_REPORT_RFID_STOCK = "RFID Stock",
//   FORMNAME_REPORT_STOCKREORT_CURRENTSTOCK = "Current Stock",
//   FORMNAME_REPORT_STOCKREORT_STOCKHISTORY = "Stock History",
//   FORMNAME_REPORT_STOCKREORT_STOCKAVAILABILITY ="Stock Availability",
//   FORMNAME_REPORT_STOCKREORT_STOCKAVAILABILITYWEIGHT ="Stock Availability Weight",
//   FORMNAME_REPORT_STOCKREORT_STOCKDETAILS = "Stock Details",
//   FORMNAME_REPORT_STOCKREORT_STOCKAVAILABILITY_WEIGHTWISE_STOCK = "Stock availability weightwise",
  FORMNAME_REPORT_SALEORDERLIST = "Sale Order List",
  FORMNAME_REPORT_JOBWORK_ORDER = "Jobwork Order",
  FORMNAME_REPORT_DAILY_SALES_AND_COLLECTION_SUMMARY = "Daily Sales And Collection Summary",
  FORMNAME_REPORT_CONSIGNMENTITEMS = "Consignment Items",
  FORMNAME_REPORT_DEPARTMENTREPORT = "Department Report",
  FORMNAME_REPORT_BORROWERS_REPORT ="Borrowers Report",
  FORMNAME_INVENTORY_JEWELRY_CATALOGUE = "Jewellery Catalogue",
  FORMNAME_INVENTORY_JEWELRY_CATALOGUE_INFO = "Jewellery Catalogue - Info",
  FORMNAME_INVENTORY_JEWELRY_CATALOGUE_BILL_OF_MATERIAL = "Bill Of Material",
  FORMNAME_INVENTORY_JEWELRY_CATALOGUE_PRODUCT_DETAILS = "Product Details",
  FORMNAME_INVENTORY_JEWELRY_CATALOGUE_TRACKING = "Jewellery Catalogue Tracking",
  FORMNAME_INVENTORY_PHYSICALSTOCK = 'Physical Stock',
  FORMNAME_INVENTORY_GOLD_SILVER_ANALYSIS = "Gold / Silver Analysis",
  FORMNAME_INVENTORY_DIAMOND_CARATWISE = "Diamond & Stone Weight Report",
  FORMNAME_INVENTORY_DIAMOND_STONE_IMITATION = 'Diamond & Stone / Imitation',
  FORMNAME_INVENTORY_GOLD_AND_SILVER = 'Gold & Silver',
  FORMNAME_INVENTORY_DIAMOND_AND_STONES = 'Diamond & Stone',
  FORMNAME_INVENTORY_IMITATION_AND_WATCHES = 'Imitation & Watches',
  FORMNAME_INVENTORY_BARCODE = "Barcode",
  FORMNAME_INVENTORY_RFID = "RFID",
  FORMNAME_INVENTORY_GOLDSILVER_CURRENTSTOCK = "Current Stock",
  FORMNAME_INVENTORY_DIAMONDCARATWISE_CURRENTSTOCK = "Diamond Current Stock",
  FORMNAME_INVENTORY_GOLDSILVER_STOCKHISTORY = "Stock History",
  FORMNAME_INVENTORY_GOLDSILVER_STOCKAVAILABILITYWEIGHT ="Stock Availability Weight",
  FORMNAME_INVENTORY_DIAMONDS_STOCKAVAILABILITYWEIGHT ="Diamond Stock Availability Weight",
  FORMNAME_INVENTORY_GOLDSILVER_STOCKDETAILS = "Stock Details",
  FORMNAME_INVENTORY_GOLDSILVER_STOCKAVAILABILITY_WEIGHTWISE_STOCK = "Stock availability weightwise",
  FORMNAME_INVENTORY_STOCK_VALUATION = "Stock Valuation",
  FORMNAME_INVENTORY_STOCK_SUMMARY_REPORT = "Stock Summary Report",
  FORMNAME_MEMO_CONSIGNMENT_ITEMS_REPORT = "Memo / Consignment Items",
  FORMNAME_INVENTORY_CREATEMEMO ="Memo Voucher",
  FORMNAME_REPORT_OLD_JEWELLERY = "Old Jewellery-Scrap",
  FORMNAME_REPORT_OLD_JEWELLERY_SCRAP = "Old Jewellery-Scrap",
  FORMNAME_REPORT_OLD_JEWELLERY_REFINE = "Old Jewellery-Scrap / Refine",
  FORMNAME_REPORT_OLD_JEWELLERY_STOCKED = "Old Jewellery-Scrap / stocked",
  APIREQUEST_REPORT_OLD_JEWELLERY_STOCKED_GRID = "APIREQUEST_REPORT_OLD_JEWELLERY_STOCKED_GRID",
  FORMNAME_REPORT_OLD_JEWELLERY_RECEIVED = "Old Jewellery-Scrap / Received",

  FORMNAME_MANUFACTURER_DEPARTMENT = "Department",
    FORMNAME_MANUFACTURER_JOBWORK_QUEUE = "Jobwork Queue",
    FORMNAME_MANUFACTURER_JOBWORK_QUEUE_MASTER = "Jobwork Queue Master",
  FORMNAME_MANUFACTURER_JOBWORK_QUEUE_REPORT = "Jobwork Queue Report",
  FORMNAME_MANUFACTURER_MANUFACTURING_PROCESS_MATERIAL_ISSUE = "Material Issue",
  FORMNAME_MANUFACTURER_TRANSACTION = "Transaction",
  FORMNAME_MANUFACTURER_MANUFACTURING_PROCESS = "Manufacturing Process",
  FORMNAME_MANUFACTURER_LOSSTRACKING ="Loss Tracking",
  FORMNAME_MANUFACTURER_CLOSING_DETAILS ="Closing Details",
  FORMNAME_MANUFACTURER_WORKLOG_REPORT = "Worklog Report",

  FORMNAME_SETTINGS_SET_SOFTWARE = "Set Software",
  FORMNAME_SETTINGS_SET_SOFTWARE_PRINTERSETTING = "Printer Setting",
  FORMNAME_SETTINGS_SET_SOFTWARE_VOUCHER_SETTING = "Voucher Setting",
  FORMNAME_SETTINGS_SET_SOFTWARE_SCHEDULETASK = "Schedule Task",
  FORMNAME_SETTINGS_SET_SOFTWARE_ACCOUNTING = "Accounting",
  FORMNAME_SETTINGS_SET_SOFTWARE_BARCODE_SETTING = "Barcode Setting",
  FORMNAME_SETTINGS_SET_SOFTWARE_RDLC_DESIGN = "RDLC Design",
  FORMNAME_SETTINGS_SET_SOFTWARE_INSURANCE = "Insurance",
  FORMNAME_SETTINGS_SET_SOFTWARE_DEVICES = "Devices",
  FORMNAME_SETTINGS_SET_SOFTWARE_BILL_SERIES = "Bill Series",
  FORMNAME_SETTINGS_SET_SOFTWARE_E_WAY_BILL = "E Way Bill",
  FORMNAME_SETTINGS_SET_SOFTWARE_TALLY_INTEGRATION = "Tally Integration",
  FORMNAME_SETTINGS_SET_SOFTWARE_REWARD_POINT = "Reward Point",
  FORMNAME_SETTINGS_SET_SOFTWARE_DATA_BACKUP = "Data Backup / Update",
  FORMNAME_SETTINGS_SET_SOFTWARE_TEMPLATE = "Template",
  FORMNAME_SETTINGS_SET_SOFTWARE_SYSTEM_FIELDS = "System Fields",
  FORMNAME_SETTINGS_VOUCHERTYPE = "Voucher Type",
  FORMNAME_SETTINGS_DATA_IMPORT_EXPORT = "Data Import Export",
  FORMNAME_SETTINGS_DATA_EXPORT_IN_TALLY = "Data Export In Tally",
  FORMNAME_SETTINGS_WHATSAPP_CAMPAIGN_MARKETING = "WhatsApp Campaign",
  FORMNAME_SETTINGS_EMAIL_CAMPAIGN_MARKETING = "Email Campaign",
  FORMNAME_SETTINGS_CAMPAIGN_MANAGER = "Campaign Manager",
  FORMNAME_SETTINGS_EMAIL = "Email",
  FORMNAME_SETTINGS_WHATSAPP_MARKETING_GREETINGS = "Greetings",
  FORMNAME_SETTINGS_WHATSAPP_MARKETING_TRENDING = "Trending",
  FORMNAME_SETTINGS_WHATSAPP_MARKETING_BUSINESS = "Business",
  FORMNAME_SETTINGS_WHATSAPP_MARKETING_OFFERS = "Offers",
  FORMNAME_SETTINGS_ECOMMERCE_INTERGRATION = "E-Commerce Integration",
  FORMNAME_SETTINGS_PAYMENT_INTERGRATION = "Payment Integration",
  FORMNAME_SETTINGS_INVOICE_REPORT_SETTING = "Invoice / Diamond Certificate",

  FORMNAME_SETTINGS_NOTIFICATION_CONFIGURATION = "Notification Configuration",
  FORMNAME_SETTINGS_NOTIFICATION_SCHEDULARTASKS = "Schedular Tasks",
  FORMNAME_SETTINGS_TRANSACTION_MESSAGE = "Transaction Message",
  FORMNAME_SETTINGS_DISABLE_NOTIFICATIONS_CONFIGURE = "Enable / Disable Notifications Configure",
  FORMNAME_SETTINGS_GRID_CONFIGURATION = "Grid Configuration",
  FORMNAME_SETTINGS_CREADENTIALS = "Credentials",
  FORMNAME_ADMINISTRATION_USER_MANAGEMENT = "User Managment",
  FORMNAME_ADMINISTRATION_USER_MANAGEMENT_USER = "User",
  FORMNAME_ADMINISTRATION_USER_MANAGEMENT_ROLES = "Roles",
  FORMNAME_ADMINISTRATION_USER_MANAGEMENT_PERMISSION = "Permission",
  FORMNAME_ADMINISTRATION_MASTERS = "Masters",
  FORMNAME_ADMINISTRATION_MASTERS_MASTERS = "Masters",
  FORMNAME_ADMINISTRATION_MASTERS_BRANCHES = "Branches",
  FORMNAME_ADMINISTRATION_MASTERS_BUCKET = "Bucket",
  FORMNAME_ADMINISTRATION_MASTERS_TAXSETTING = "Tax Setting",
  FORMNAME_ADMINISTRATION_MASTERS_STYLE = "Style",
  FORMNAME_ADMINISTRATION_MASTERS_CREDIT_CARD = "Credit Card",
  FORMNAME_ADMINISTRATION_MASTERS_USER_DEFINED_FIELDS = "User Defined Fields",
  FORMNAME_ADMINISTRATION_ACTIVITY_LOG = "Activity Log",
  FORMNAME_ADMINISTRATION_TASK_EVENT = "Task / Event",
  FORMNAME_ClOSING_REPORTS = "Closing Report",
  FORMNAME_EDIT_PROFILE = "Edit Profile",

  FORMNAME_COMPANY_LIST = "Company List",

  FORMNAME_JEWELRY_EMPTY_CART = "Jewelry Cart",

  FORMNAME_HEADER_UPDATE_RATE ="Rate",
  //#endregion FORMNAME                  
  //#region GRIDNAME
  REPORT_STOCKLREPORT_STOCKAVAILABILITY_WEIGHTWISE_INWARD_GRID = "REPORT_STOCKLREPORT_STOCKAVAILABILITY_WEIGHTWISE_INWARD_GRID",
  REPORT_STOCKLREPORT_STOCKAVAILABILITY_WEIGHTWISE_OUTWARD_GRID = "REPORT_STOCKLREPORT_STOCKAVAILABILITY_WEIGHTWISE_OUTWARD_GRID",

  COLLATERAL_DETAILS_LOAN_GRID="COLLATERAL_DETAILS_LOAN_GRID",
  TRANSACTION_SALEINVOICE_GOLD_ITEM_GRID = "TRANSACTION_SALEINVOICE_GOLD_ITEM_GRID",
  TRANSACTION_SALEINVOICE_SILVER_ITEM_GRID = "TRANSACTION_SALEINVOICE_SILVER_ITEM_GRID",
  TRANSACTION_SALEINVOICE_DIAMOND_ITEM_GRID = "TRANSACTION_SALEINVOICE_DIAMOND_ITEM_GRID",
  TRANSACTION_SALEINVOICE_STONE_GEMS_ITEM_GRID = "TRANSACTION_SALEINVOICE_STONE_GEMS_ITEM_GRID",
  TRANSACTION_SALEINVOICE_IMITATION_ITEM_GRID = "TRANSACTION_SALEINVOICE_IMITATION_ITEM_GRID",
  TRANSACTION_SALEINVOICE_PLATINUM_ITEM_GRID = "TRANSACTION_SALEINVOICE_PLATINUM_ITEM_GRID",
  TRANSACTION_SALEINVOICE_OTHER_ITEM_GRID = "TRANSACTION_SALEINVOICE_OTHER_ITEM_GRID",
  TRANSACTION_SALEINVOICE_PRODUCTLIST_GRID = "TRANSACTION_SALEINVOICE_PRODUCTLIST_GRID",
  TRANSACTION_SALEINVOICE_TAX_GRID = "TRANSACTION_SALEINVOICE_TAX_GRID",
  TRANSACTION_SALEINVOICE_PAYMENT_GRID = "TRANSACTION_SALEINVOICE_PAYMENT_GRID",
  TRANSACTION_SALEINVOICE_ADDITIONALAMOUNT_GRID = "TRANSACTION_SALEINVOICE_ADDITIONALAMOUNT_GRID",
  TRANSACTION_SALEINVOICE_SALEORDERPOPUP_GRID = "TRANSACTION_SALEINVOICE_SALEORDERPOPUP_GRID",

  TRANSACTION_CONSIGNMENTOUT_GOLD_ITEM_GRID = "TRANSACTION_CONSIGNMENTOUT_GOLD_ITEM_GRID",
  TRANSACTION_CONSIGNMENTOUT_SILVER_ITEM_GRID = "TRANSACTION_CONSIGNMENTOUT_SILVER_ITEM_GRID",
  TRANSACTION_CONSIGNMENTOUT_DIAMOND_ITEM_GRID = "TRANSACTION_CONSIGNMENTOUT_DIAMOND_ITEM_GRID",
  TRANSACTION_CONSIGNMENTOUT_STONE_GEMS_ITEM_GRID = "TRANSACTION_CONSIGNMENTOUT_STONE_GEMS_ITEM_GRID",
  TRANSACTION_CONSIGNMENTOUT_IMITATION_ITEM_GRID = "TRANSACTION_CONSIGNMENTOUT_IMITATION_ITEM_GRID",
  TRANSACTION_CONSIGNMENTOUT_PLATINUM_ITEM_GRID = "TRANSACTION_CONSIGNMENTOUT_PLATINUM_ITEM_GRID",
  TRANSACTION_CONSIGNMENTOUT_OTHER_ITEM_GRID = "TRANSACTION_CONSIGNMENTOUT_OTHER_ITEM_GRID",
  TRANSACTION_CONSIGNMENTOUT_PRODUCTLIST_GRID = "TRANSACTION_CONSIGNMENTOUT_PRODUCTLIST_GRID",
  TRANSACTION_CONSIGNMENTOUT_TAX_GRID = "TRANSACTION_CONSIGNMENTOUT_TAX_GRID",
  TRANSACTION_CONSIGNMENTOUT_PAYMENT_GRID = "TRANSACTION_CONSIGNMENTOUT_PAYMENT_GRID",
  TRANSACTION_CONSIGNMENTOUT_ADDITIONALAMOUNT_GRID = "TRANSACTION_CONSIGNMENTOUT_ADDITIONALAMOUNT_GRID",
  TRANSACTION_CONSIGNMENTOUT_SALEORDERPOPUP_GRID = "TRANSACTION_CONSIGNMENTOUT_SALEORDERPOPUP_GRID",

  TRANSACTION_PURCHASEINVOICE_GOLD_ITEM_GRID = "TRANSACTION_PURCHASEINVOICE_GOLD_ITEM_GRID",
  TRANSACTION_PURCHASEINVOICE_SILVER_ITEM_GRID = "TRANSACTION_PURCHASEINVOICE_SILVER_ITEM_GRID",
  TRANSACTION_PURCHASEINVOICE_DIAMOND_ITEM_GRID = "TRANSACTION_PURCHASEINVOICE_DIAMOND_ITEM_GRID",
  TRANSACTION_PURCHASEINVOICE_STONE_GEMS_ITEM_GRID = "TRANSACTION_PURCHASEINVOICE_STONE_GEMS_ITEM_GRID",
  TRANSACTION_PURCHASEINVOICE_IMITATION_ITEM_GRID = "TRANSACTION_PURCHASEINVOICE_IMITATION_ITEM_GRID",
  TRANSACTION_PURCHASEINVOICE_PLATINUM_ITEM_GRID = "TRANSACTION_PURCHASEINVOICE_PLATINUM_ITEM_GRID",
  TRANSACTION_PURCHASEINVOICE_PRODUCTLIST_GRID = "TRANSACTION_PURCHASEINVOICE_PRODUCTLIST_GRID",
  TRANSACTION_PURCHASEINVOICE_TAX_GRID = "TRANSACTION_PURCHASEINVOICE_TAX_GRID",
  TRANSACTION_PURCHASEINVOICE_PAYMENT_GRID = "TRANSACTION_PURCHASEINVOICE_PAYMENT_GRID",
  TRANSACTION_PURCHASEINVOICE_ADDITIONALAMOUNT_GRID = "TRANSACTION_PURCHASEINVOICE_ADDITIONALAMOUNT_GRID",

  TRANSACTION_SALESQUOTATION_GOLD_ITEM_GRID = "TRANSACTION_SALESQUOTATION_GOLD_ITEM_GRID",
  TRANSACTION_SALESQUOTATION_SILVER_ITEM_GRID = "TRANSACTION_SALESQUOTATION_SILVER_ITEM_GRID",
  TRANSACTION_SALESQUOTATION_DIAMOND_ITEM_GRID = "TRANSACTION_SALESQUOTATION_DIAMOND_ITEM_GRID",
  TRANSACTION_SALESQUOTATION_STONE_GEMS_ITEM_GRID = "TRANSACTION_SALESQUOTATION_STONE_GEMS_ITEM_GRID",
  TRANSACTION_SALESQUOTATION_IMITATION_ITEM_GRID = "TRANSACTION_SALESQUOTATION_IMITATION_ITEM_GRID",
  TRANSACTION_SALESQUOTATION_PLATINUM_ITEM_GRID = "TRANSACTION_SALESQUOTATION_PLATINUM_ITEM_GRID",
  TRANSACTION_SALESQUOTATION_PRODUCTLIST_GRID = "TRANSACTION_SALESQUOTATION_PRODUCTLIST_GRID",
  TRANSACTION_SALESQUOTATION_TAX_GRID = "TRANSACTION_SALESQUOTATION_TAX_GRID",
  TRANSACTION_SALESQUOTATION_PAYMENT_GRID = "TRANSACTION_SALESQUOTATION_PAYMENT_GRID",
  TRANSACTION_SALESQUOTATION_ADDITIONALAMOUNT_GRID = "TRANSACTION_SALESQUOTATION_ADDITIONALAMOUNT_GRID",
  
  TRANSACTION_DELIVERYNOTE_GOLD_ITEM_GRID = "TRANSACTION_DELIVERYNOTE_GOLD_ITEM_GRID",
  TRANSACTION_DELIVERYNOTE_SILVER_ITEM_GRID = "TRANSACTION_DELIVERYNOTE_SILVER_ITEM_GRID",
  TRANSACTION_DELIVERYNOTE_DIAMOND_ITEM_GRID = "TRANSACTION_DELIVERYNOTE_DIAMOND_ITEM_GRID",
  TRANSACTION_DELIVERYNOTE_STONE_GEMS_ITEM_GRID = "TRANSACTION_DELIVERYNOTE_STONE_GEMS_ITEM_GRID",
  TRANSACTION_DELIVERYNOTE_IMITATION_ITEM_GRID = "TRANSACTION_DELIVERYNOTE_IMITATION_ITEM_GRID",
  TRANSACTION_DELIVERYNOTE_PLATINUM_ITEM_GRID = "TRANSACTION_DELIVERYNOTE_PLATINUM_ITEM_GRID",
  TRANSACTION_DELIVERYNOTE_PRODUCTLIST_GRID = "TRANSACTION_DELIVERYNOTE_PRODUCTLIST_GRID",
  TRANSACTION_DELIVERYNOTE_TAX_GRID = "TRANSACTION_DELIVERYNOTE_TAX_GRID",
  TRANSACTION_DELIVERYNOTE_PAYMENT_GRID = "TRANSACTION_DELIVERYNOTE_PAYMENT_GRID",
  TRANSACTION_DELIVERYNOTE_ADDITIONALAMOUNT_GRID = "TRANSACTION_DELIVERYNOTE_ADDITIONALAMOUNT_GRID",

  TRANSACTION_CONSIGNMENTIN_GOLD_ITEM_GRID = "TRANSACTION_CONSIGNMENTIN_GOLD_ITEM_GRID",
  TRANSACTION_CONSIGNMENTIN_SILVER_ITEM_GRID = "TRANSACTION_CONSIGNMENTIN_SILVER_ITEM_GRID",
  TRANSACTION_CONSIGNMENTIN_DIAMOND_ITEM_GRID = "TRANSACTION_CONSIGNMENTIN_DIAMOND_ITEM_GRID",
  TRANSACTION_CONSIGNMENTIN_STONE_GEMS_ITEM_GRID = "TRANSACTION_CONSIGNMENTIN_STONE_GEMS_ITEM_GRID",
  TRANSACTION_CONSIGNMENTIN_IMITATION_ITEM_GRID = "TRANSACTION_CONSIGNMENTIN_IMITATION_ITEM_GRID",
  TRANSACTION_CONSIGNMENTIN_PLATINUM_ITEM_GRID = "TRANSACTION_CONSIGNMENTIN_PLATINUM_ITEM_GRID",
  TRANSACTION_CONSIGNMENTIN_OTHER_ITEM_GRID = "TRANSACTION_CONSIGNMENTIN_OTHER_ITEM_GRID",
  TRANSACTION_CONSIGNMENTIN_PRODUCTLIST_GRID = "TRANSACTION_CONSIGNMENTIN_PRODUCTLIST_GRID",
  TRANSACTION_CONSIGNMENTIN_TAX_GRID = "TRANSACTION_CONSIGNMENTIN_TAX_GRID",
  TRANSACTION_CONSIGNMENTIN_PAYMENT_GRID = "TRANSACTION_CONSIGNMENTIN_PAYMENT_GRID",
  TRANSACTION_CONSIGNMENTIN_ADDITIONALAMOUNT_GRID = "TRANSACTION_CONSIGNMENTIN_ADDITIONALAMOUNT_GRID",
  TRANSACTION_CONSIGNMENTIN_SALEORDERPOPUP_GRID = "TRANSACTION_CONSIGNMENTIN_SALEORDERPOPUP_GRID",
 
  TRANSACTION_CREDITNOTE_GOLD_ITEM_GRID = "TRANSACTION_CREDITNOTE_GOLD_ITEM_GRID",
  TRANSACTION_CREDITNOTE_SILVER_ITEM_GRID = "TRANSACTION_CREDITNOTE_SILVER_ITEM_GRID",
  TRANSACTION_CREDITNOTE_DIAMOND_ITEM_GRID = "TRANSACTION_CREDITNOTE_DIAMOND_ITEM_GRID",
  TRANSACTION_CREDITNOTE_STONE_GEMS_ITEM_GRID = "TRANSACTION_CREDITNOTE_STONE_GEMS_ITEM_GRID",
  TRANSACTION_CREDITNOTE_IMITATION_ITEM_GRID = "TRANSACTION_CREDITNOTE_IMITATION_ITEM_GRID",
  TRANSACTION_CREDITNOTE_PLATINUM_ITEM_GRID = "TRANSACTION_CREDITNOTE_PLATINUM_ITEM_GRID",
  TRANSACTION_CREDITNOTE_PRODUCTLIST_GRID = "TRANSACTION_CREDITNOTE_PRODUCTLIST_GRID",
  TRANSACTION_CREDITNOTE_TAX_GRID = "TRANSACTION_CREDITNOTE_TAX_GRID",
  TRANSACTION_CREDITNOTE_PAYMENT_GRID = "TRANSACTION_CREDITNOTE_PAYMENT_GRID",
   
  TRANSACTION_DEBITNOTE_GOLD_ITEM_GRID = "TRANSACTION_DEBITNOTE_GOLD_ITEM_GRID",
  TRANSACTION_DEBITNOTE_SILVER_ITEM_GRID = "TRANSACTION_DEBITNOTE_SILVER_ITEM_GRID",
  TRANSACTION_DEBITNOTE_DIAMOND_ITEM_GRID = "TRANSACTION_DEBITNOTE_DIAMOND_ITEM_GRID",
  TRANSACTION_DEBITNOTE_STONE_GEMS_ITEM_GRID = "TRANSACTION_DEBITNOTE_STONE_GEMS_ITEM_GRID",
  TRANSACTION_DEBITNOTE_IMITATION_ITEM_GRID = "TRANSACTION_DEBITNOTE_IMITATION_ITEM_GRID",
  TRANSACTION_DEBITNOTE_PLATINUM_ITEM_GRID = "TRANSACTION_DEBITNOTE_PLATINUM_ITEM_GRID",
  TRANSACTION_DEBITNOTE_PRODUCTLIST_GRID = "TRANSACTION_DEBITNOTE_PRODUCTLIST_GRID",
  TRANSACTION_DEBITNOTE_TAX_GRID = "TRANSACTION_DEBITNOTE_TAX_GRID",
  TRANSACTION_DEBITNOTE_PAYMENT_GRID = "TRANSACTION_DEBITNOTE_PAYMENT_GRID",

  TRANSACTION_OLDJEWELRY_SCRAPINVOICE_GOLD_ITEM_GRID = "TRANSACTION_OLDJEWELRY_SCRAPINVOICE_GOLD_ITEM_GRID",
  TRANSACTION_OLDJEWELRY_SCRAPINVOICE_SILVER_ITEM_GRID = "TRANSACTION_OLDJEWELRY_SCRAPINVOICE_SILVER_ITEM_GRID",
  TRANSACTION_OLDJEWELRY_SCRAPINVOICE_DIAMOND_ITEM_GRID = "TRANSACTION_OLDJEWELRY_SCRAPINVOICE_DIAMOND_ITEM_GRID",
  TRANSACTION_OLDJEWELRY_SCRAPINVOICE_STONE_GEMS_ITEM_GRID = "TRANSACTION_OLDJEWELRY_SCRAPINVOICE_STONE_GEMS_ITEM_GRID",
  TRANSACTION_OLDJEWELRY_SCRAPINVOICE_IMITATION_ITEM_GRID = "TRANSACTION_OLDJEWELRY_SCRAPINVOICE_IMITATION_ITEM_GRID",
  TRANSACTION_OLDJEWELRY_SCRAPINVOICE_PLATINUM_ITEM_GRID = "TRANSACTION_OLDJEWELRY_SCRAPINVOICE_PLATINUM_ITEM_GRID",
  TRANSACTION_OLDJEWELRY_SCRAPINVOICE_OTHER_ITEM_GRID = "TRANSACTION_OLDJEWELRY_SCRAPINVOICE_OTHER_ITEM_GRID",
  TRANSACTION_OLDJEWELRY_SCRAPINVOICE_PRODUCTLIST_GRID = "TRANSACTION_OLDJEWELRY_SCRAPINVOICE_PRODUCTLIST_GRID",
  TRANSACTION_OLDJEWELRY_SCRAPINVOICE_TAX_GRID = "TRANSACTION_OLDJEWELRY_SCRAPINVOICE_TAX_GRID",
  TRANSACTION_OLDJEWELRY_SCRAPINVOICE_PAYMENT_GRID = "TRANSACTION_OLDJEWELRY_SCRAPINVOICE_PAYMENT_GRID",
  TRANSACTION_OLDJEWELRY_SCRAPINVOICE_ADDITIONALAMOUNT_GRID = "TRANSACTION_OLDJEWELRY_SCRAPINVOICE_ADDITIONALAMOUNT_GRID",

  MANUFACTURER_JOBWORK_QUEUE_ITEM_GRID = "MANUFACTURER_JOBWORK_QUEUE_ITEM_GRID",

  TRANSACTION_JOBWORKINVOICE_GOLD_ITEM_GRID = "TRANSACTION_JOBWORKINVOICE_GOLD_ITEM_GRID",
  TRANSACTION_JOBWORKINVOICE_SILVER_ITEM_GRID = "TRANSACTION_JOBWORKINVOICE_SILVER_ITEM_GRID",
  TRANSACTION_JOBWORKINVOICE_DIAMOND_ITEM_GRID = "TRANSACTION_JOBWORKINVOICE_DIAMOND_ITEM_GRID",
  TRANSACTION_JOBWORKINVOICE_STONE_GEMS_ITEM_GRID = "TRANSACTION_JOBWORKINVOICE_STONE_GEMS_ITEM_GRID",
  TRANSACTION_JOBWORKINVOICE_IMITATION_ITEM_GRID = "TRANSACTION_JOBWORKINVOICE_IMITATION_ITEM_GRID",
  TRANSACTION_JOBWORKINVOICE_PLATINUM_ITEM_GRID = "TRANSACTION_JOBWORKINVOICE_PLATINUM_ITEM_GRID",
  TRANSACTION_JOBWORKINVOICE_OTHER_ITEM_GRID = "TRANSACTION_JOBWORKINVOICE_OTHER_ITEM_GRID",
  TRANSACTION_JOBWORKINVOICE_PRODUCTLIST_GRID = "TRANSACTION_JOBWORKINVOICE_PRODUCTLIST_GRID",
  TRANSACTION_JOBWORKINVOICE_TAX_GRID = "TRANSACTION_JOBWORKINVOICE_TAX_GRID",
  TRANSACTION_JOBWORKINVOICE_PAYMENT_GRID = "TRANSACTION_JOBWORKINVOICE_PAYMENT_GRID",
  TRANSACTION_JOBWORKINVOICE_ADDITIONALAMOUNT_GRID = "TRANSACTION_JOBWORKINVOICE_ADDITIONALAMOUNT_GRID",

  TRANSACTION_SALESORDER_GOLD_ITEM_GRID = "TRANSACTION_SALESORDER_GOLD_ITEM_GRID",
  TRANSACTION_SALESORDER_SILVER_ITEM_GRID = "TRANSACTION_SALESORDER_SILVER_ITEM_GRID",
  TRANSACTION_SALESORDER_DIAMOND_ITEM_GRID = "TRANSACTION_SALESORDER_DIAMOND_ITEM_GRID",
  TRANSACTION_SALESORDER_STONE_GEMS_ITEM_GRID = "TRANSACTION_SALESORDER_STONE_GEMS_ITEM_GRID",
  TRANSACTION_SALESORDER_IMITATION_ITEM_GRID = "TRANSACTION_SALESORDER_IMITATION_ITEM_GRID",
  TRANSACTION_SALESORDER_PLATINUM_ITEM_GRID = "TRANSACTION_SALESORDER_PLATINUM_ITEM_GRID",
  TRANSACTION_SALESORDER_OTHER_ITEM_GRID = "TRANSACTION_SALESORDER_OTHER_ITEM_GRID",
  TRANSACTION_SALESORDER_PRODUCTLIST_GRID = "TRANSACTION_SALESORDER_PRODUCTLIST_GRID",
  TRANSACTION_SALESORDER_TAX_GRID = "TRANSACTION_SALESORDER_TAX_GRID",
  TRANSACTION_SALESORDER_PAYMENT_GRID = "TRANSACTION_SALESORDER_PAYMENT_GRID",

  TRANSACTION_RECEIPTVOUCHER_PAYMENT_GRID = "TRANSACTION_RECEIPTVOUCHER_PAYMENT_GRID",
  TRANSACTION_RECEIPTVOUCHER_PAYMENTLIST_GRID = "TRANSACTION_RECEIPTVOUCHER_PAYMENTLIST_GRID",

  TRANSACTION_PAYMENTVOUCHER_PAYMENT_GRID = "TRANSACTION_PAYMENTVOUCHER_PAYMENT_GRID",
  TRANSACTION_PAYMENTVOUCHER_PAYMENTLIST_GRID = "TRANSACTION_PAYMENTVOUCHER_PAYMENTLIST_GRID",

  TRANSACTION_MATERIALIN_PAYMENT_GRID = "TRANSACTION_MATERIALIN_PAYMENT_GRID",
  TRANSACTION_MATERIALIN_PAYMENTLIST_GRID = "TRANSACTION_MATERIALIN_PAYMENTLIST_GRID",

  TRANSACTION_MATERIALOUT_PAYMENT_GRID = "TRANSACTION_MATERIALOUT_PAYMENT_GRID",
  TRANSACTION_MATERIALOUT_PAYMENTLIST_GRID = "TRANSACTION_MATERIALOUT_PAYMENTLIST_GRID",

  TRANSACTION_ADD_DIAMOND_IN_PAYMENT_GRID = "TRANSACTION_ADD_DIAMOND_IN_PAYMENT_GRID",
  TRANSACTION_ADD_STONEGEMS_IN_PAYMENT_GRID = "TRANSACTION_ADD_STONEGEMS_IN_PAYMENT_GRID",
  TRANSACTION_ADD_DIAMOND_IN_PAYMENT_BYAPI_GRID = "TRANSACTION_ADD_DIAMOND_IN_PAYMENT_BYAPI_GRID",
  TRANSACTION_ADD_STONEGEMS_IN_PAYMENT_BYAPI_GRID = "TRANSACTION_ADD_STONEGEMS_IN_PAYMENT_BYAPI_GRID",

  TRANSACTION_PURCHASEORDER_GOLD_ITEM_GRID = "TRANSACTION_PURCHASEORDER_GOLD_ITEM_GRID",
  TRANSACTION_PURCHASEORDER_SILVER_ITEM_GRID = "TRANSACTION_PURCHASEORDER_SILVER_ITEM_GRID",
  TRANSACTION_PURCHASEORDER_DIAMOND_ITEM_GRID = "TRANSACTION_PURCHASEORDER_DIAMOND_ITEM_GRID",
  TRANSACTION_PURCHASEORDER_STONE_GEMS_ITEM_GRID = "TRANSACTION_PURCHASEORDER_STONE_GEMS_ITEM_GRID",
  TRANSACTION_PURCHASEORDER_IMITATION_ITEM_GRID = "TRANSACTION_PURCHASEORDER_IMITATION_ITEM_GRID",
  TRANSACTION_PURCHASEORDER_PLATINUM_ITEM_GRID = "TRANSACTION_PURCHASEORDER_PLATINUM_ITEM_GRID",
  TRANSACTION_PURCHASEORDER_OTHER_ITEM_GRID = "TRANSACTION_PURCHASEORDER_OTHER_ITEM_GRID",
  TRANSACTION_PURCHASEORDER_PRODUCTLIST_GRID = "TRANSACTION_PURCHASEORDER_PRODUCTLIST_GRID",
  TRANSACTION_PURCHASEORDER_TAX_GRID = "TRANSACTION_PURCHASEORDER_TAX_GRID",
  TRANSACTION_PURCHASEORDER_PAYMENT_GRID = "TRANSACTION_PURCHASEORDER_PAYMENT_GRID",

  TRANSACTION_SALESRETURN_GOLD_ITEM_GRID = "TRANSACTION_SALESRETURN_GOLD_ITEM_GRID",
  TRANSACTION_SALESRETURN_SILVER_ITEM_GRID = "TRANSACTION_SALESRETURN_SILVER_ITEM_GRID",
  TRANSACTION_SALESRETURN_DIAMOND_ITEM_GRID = "TRANSACTION_SALESRETURN_DIAMOND_ITEM_GRID",
  TRANSACTION_SALESRETURN_STONE_GEMS_ITEM_GRID = "TRANSACTION_SALESRETURN_STONE_GEMS_ITEM_GRID",
  TRANSACTION_SALESRETURN_IMITATION_ITEM_GRID = "TRANSACTION_SALESRETURN_IMITATION_ITEM_GRID",
  TRANSACTION_SALESRETURN_PLATINUM_ITEM_GRID = "TRANSACTION_SALESRETURN_PLATINUM_ITEM_GRID",
  TRANSACTION_SALESRETURN_OTHER_ITEM_GRID = "TRANSACTION_SALESRETURN_OTHER_ITEM_GRID",
  TRANSACTION_SALESRETURN_PRODUCTLIST_GRID = "TRANSACTION_SALESRETURN_PRODUCTLIST_GRID",
  TRANSACTION_SALESRETURN_TAX_GRID = "TRANSACTION_SALESRETURN_TAX_GRID",
  TRANSACTION_SALESRETURN_PAYMENT_GRID = "TRANSACTION_SALESRETURN_PAYMENT_GRID",

  TRANSACTION_JOBWORKORDER_GOLD_ITEM_GRID = "TRANSACTION_JOBWORKORDER_GOLD_ITEM_GRID",
  TRANSACTION_JOBWORKORDER_SILVER_ITEM_GRID = "TRANSACTION_JOBWORKORDER_SILVER_ITEM_GRID",
  TRANSACTION_JOBWORKORDER_DIAMOND_ITEM_GRID = "TRANSACTION_JOBWORKORDER_DIAMOND_ITEM_GRID",
  TRANSACTION_JOBWORKORDER_STONE_GEMS_ITEM_GRID = "TRANSACTION_JOBWORKORDER_STONE_GEMS_ITEM_GRID",
  TRANSACTION_JOBWORKORDER_IMITATION_ITEM_GRID = "TRANSACTION_JOBWORKORDER_IMITATION_ITEM_GRID",
  TRANSACTION_JOBWORKORDER_PLATINUM_ITEM_GRID = "TRANSACTION_JOBWORKORDER_PLATINUM_ITEM_GRID",
  TRANSACTION_JOBWORKORDER_OTHER_ITEM_GRID = "TRANSACTION_JOBWORKORDER_OTHER_ITEM_GRID",
  TRANSACTION_JOBWORKORDER_PRODUCTLIST_GRID = "TRANSACTION_JOBWORKORDER_PRODUCTLIST_GRID",
  TRANSACTION_JOBWORKORDER_PAYMENT_GRID = "TRANSACTION_JOBWORKORDER_PAYMENT_GRID",


  TRANSACTION_MATERIAL_ISSUE_GOLD_ITEM_GRID = "TRANSACTION_MATERIAL_ISSUE_GOLD_ITEM_GRID",
  TRANSACTION_MATERIAL_ISSUE_SILVER_ITEM_GRID = "TRANSACTION_MATERIAL_ISSUE_SILVER_ITEM_GRID",
  TRANSACTION_MATERIAL_ISSUE_DIAMOND_ITEM_GRID = "TRANSACTION_MATERIAL_ISSUE_DIAMOND_ITEM_GRID",
  TRANSACTION_MATERIAL_ISSUE_STONE_GEMS_ITEM_GRID = "TRANSACTION_MATERIAL_ISSUE_STONE_GEMS_ITEM_GRID",
  TRANSACTION_MATERIAL_ISSUE_IMITATION_ITEM_GRID = "TRANSACTION_MATERIAL_ISSUE_IMITATION_ITEM_GRID",
  TRANSACTION_MATERIAL_ISSUE_PLATINUM_ITEM_GRID = "TRANSACTION_MATERIAL_ISSUE_PLATINUM_ITEM_GRID",
  TRANSACTION_MATERIAL_ISSUE_OTHER_ITEM_GRID = "TRANSACTION_MATERIAL_ISSUE_OTHER_ITEM_GRID",
  TRANSACTION_MATERIAL_ISSUE_PRODUCTLIST_GRID = "TRANSACTION_MATERIAL_ISSUE_PRODUCTLIST_GRID",
  TRANSACTION_MATERIAL_ISSUE_PAYMENT_GRID = "TRANSACTION_MATERIAL_ISSUE_PAYMENT_GRID",

  TRANSACTION_MATERIAL_RECEIVE_GOLD_ITEM_GRID = "TRANSACTION_MATERIAL_RECEIVE_GOLD_ITEM_GRID",
  TRANSACTION_MATERIAL_RECEIVE_SILVER_ITEM_GRID = "TRANSACTION_MATERIAL_RECEIVE_SILVER_ITEM_GRID",
  TRANSACTION_MATERIAL_RECEIVE_DIAMOND_ITEM_GRID = "TRANSACTION_MATERIAL_RECEIVE_DIAMOND_ITEM_GRID",
  TRANSACTION_MATERIAL_RECEIVE_STONE_GEMS_ITEM_GRID = "TRANSACTION_MATERIAL_RECEIVE_STONE_GEMS_ITEM_GRID",
  TRANSACTION_MATERIAL_RECEIVE_IMITATION_ITEM_GRID = "TRANSACTION_MATERIAL_RECEIVE_IMITATION_ITEM_GRID",
  TRANSACTION_MATERIAL_RECEIVE_PLATINUM_ITEM_GRID = "TRANSACTION_MATERIAL_RECEIVE_PLATINUM_ITEM_GRID",
  TRANSACTION_MATERIAL_RECEIVE_OTHER_ITEM_GRID = "TRANSACTION_MATERIAL_RECEIVE_OTHER_ITEM_GRID",
  TRANSACTION_MATERIAL_RECEIVE_PRODUCTLIST_GRID = "TRANSACTION_MATERIAL_RECEIVE_PRODUCTLIST_GRID",
  TRANSACTION_MATERIAL_RECEIVE_PAYMENT_GRID = "TRANSACTION_MATERIAL_RECEIVE_PAYMENT_GRID",
  TRANSACTION_EXPENSES_PAYMENT_GRID = "TRANSACTION_EXPENSES_PAYMENT_GRID",
  TRANSACTION_NEW_LOAN_PAYMENT_GRID="TRANSACTION_NEW_LOAN_PAYMENT_GRID",
  TRANSACTION_PURCHASERETURN_GOLD_ITEM_GRID = "TRANSACTION_PURCHASERETURN_GOLD_ITEM_GRID",
  TRANSACTION_PURCHASERETURN_SILVER_ITEM_GRID = "TRANSACTION_PURCHASERETURN_SILVER_ITEM_GRID",
  TRANSACTION_PURCHASERETURN_DIAMOND_ITEM_GRID = "TRANSACTION_PURCHASERETURN_DIAMOND_ITEM_GRID",
  TRANSACTION_PURCHASERETURN_STONE_GEMS_ITEM_GRID = "TRANSACTION_PURCHASERETURN_STONE_GEMS_ITEM_GRID",
  TRANSACTION_PURCHASERETURN_IMITATION_ITEM_GRID = "TRANSACTION_PURCHASERETURN_IMITATION_ITEM_GRID",
  TRANSACTION_PURCHASERETURN_PLATINUM_ITEM_GRID = "TRANSACTION_PURCHASERETURN_PLATINUM_ITEM_GRID",
  TRANSACTION_PURCHASERETURN_OTHER_ITEM_GRID = "TRANSACTION_PURCHASERETURN_OTHER_ITEM_GRID",
  TRANSACTION_PURCHASERETURN_PRODUCTLIST_GRID = "TRANSACTION_PURCHASERETURN_PRODUCTLIST_GRID",
  TRANSACTION_PURCHASERETURN_TAX_GRID = "TRANSACTION_PURCHASERETURN_TAX_GRID",
  TRANSACTION_PURCHASERETURN_PAYMENT_GRID = "TRANSACTION_PURCHASERETURN_PAYMENT_GRID",
  TRANSACTION_STOCKJOURNAL2_PRODUCTLIST_GRID="TRANSACTION_STOCKJOURNAL2_PRODUCTLIST_GRID",
  INVENTORY_CREATEMEMO_GOLD_ITEM_GRID="INVENTORY_CREATEMEMO_GOLD_ITEM_GRID",
  INVENTORY_CREATEMEMO_SILVER_ITEM_GRID="INVENTORY_CREATEMEMO_SILVER_ITEM_GRID",
  INVENTORY_CREATEMEMO_DIAMOND_ITEM_GRID="INVENTORY_CREATEMEMO_DIAMOND_ITEM_GRID",
  INVENTORY_CREATEMEMO_STONE_GEMS_ITEM_GRID="INVENTORY_CREATEMEMO_STONE_GEMS_ITEM_GRID",
  INVENTORY_CREATEMEMO_IMITATION_ITEM_GRID="INVENTORY_CREATEMEMO_IMITATION_ITEM_GRID",
  INVENTORY_CREATEMEMO_PRODUCT_LIST_GRID="INVENTORY_CREATEMEMO_PRODUCT_LIST_GRID",
  INVENTORY_STOCK_VALUATION_GRID="INVENTORY_STOCK_VALUATION_GRID",

  TRANSACTION_STOCKJOURNAL_MOVEMENTSTOCK_GOLD_ITEM_GRID = "TRANSACTION_STOCKJOURNAL_MOVEMENTSTOCK_GOLD_ITEM_GRID",
  TRANSACTION_STOCKJOURNAL_MOVEMENTSTOCK_SILVER_ITEM_GRID = "TRANSACTION_STOCKJOURNAL_MOVEMENTSTOCK_SILVER_ITEM_GRID",
  TRANSACTION_STOCKJOURNAL_MOVEMENTSTOCK_DIAMOND_ITEM_GRID = "TRANSACTION_STOCKJOURNAL_MOVEMENTSTOCK_DIAMOND_ITEM_GRID",
  TRANSACTION_STOCKJOURNAL_MOVEMENTSTOCK_STONE_GEMS_ITEM_GRID = "TRANSACTION_STOCKJOURNAL_MOVEMENTSTOCK_STONE_GEMS_ITEM_GRID",
  TRANSACTION_STOCKJOURNAL_MOVEMENTSTOCK_IMITATION_ITEM_GRID = "TRANSACTION_STOCKJOURNAL_MOVEMENTSTOCK_IMITATION_ITEM_GRID",
  TRANSACTION_STOCKJOURNAL_MOVEMENTSTOCK_PLATINUM_ITEM_GRID = "TRANSACTION_STOCKJOURNAL_MOVEMENTSTOCK_PLATINUM_ITEM_GRID",
  TRANSACTION_STOCKJOURNAL_MOVEMENTSTOCK_OTHER_ITEM_GRID = "TRANSACTION_STOCKJOURNAL_MOVEMENTSTOCK_OTHER_ITEM_GRID",
  TRANSACTION_STOCKJOURNAL_MOVEMENTSTOCK_SOURCE_GOLD_ITEM_GRID = "TRANSACTION_STOCKJOURNAL_MOVEMENTSTOCK_SOURCE_GOLD_ITEM_GRID",
  TRANSACTION_STOCKJOURNAL_MOVEMENTSTOCK_SOURCE_SILVER_ITEM_GRID = "TRANSACTION_STOCKJOURNAL_MOVEMENTSTOCK_SOURCE_SILVER_ITEM_GRID",
  TRANSACTION_STOCKJOURNAL_MOVEMENTSTOCK_SOURCE_DIAMOND_ITEM_GRID = "TRANSACTION_STOCKJOURNAL_MOVEMENTSTOCK_SOURCE_DIAMOND_ITEM_GRID",
  TRANSACTION_STOCKJOURNAL_MOVEMENTSTOCK_SOURCE_STONE_GEMS_ITEM_GRID = "TRANSACTION_STOCKJOURNAL_MOVEMENTSTOCK_SOURCE_STONE_GEMS_ITEM_GRID",
  TRANSACTION_STOCKJOURNAL_MOVEMENTSTOCK_SOURCE_IMITATION_ITEM_GRID = "TRANSACTION_STOCKJOURNAL_MOVEMENTSTOCK_SOURCE_IMITATION_ITEM_GRID",
  TRANSACTION_STOCKJOURNAL_MOVEMENTSTOCK_SOURCE_PLATINUM_ITEM_GRID = "TRANSACTION_STOCKJOURNAL_MOVEMENTSTOCK_SOURCE_PLATINUM_ITEM_GRID",
  TRANSACTION_STOCKJOURNAL_MOVEMENTSTOCK_SOURCE_OTHER_ITEM_GRID = "TRANSACTION_STOCKJOURNAL_MOVEMENTSTOCK_SOURCE_OTHER_ITEM_GRID",
  TRANSACTION_STOCKJOURNAL_MOVEMENTSTOCK_DESTINATION_GOLD_ITEM_GRID = "TRANSACTION_STOCKJOURNAL_MOVEMENTSTOCK_DESTINATION_GOLD_ITEM_GRID",
  TRANSACTION_STOCKJOURNAL_MOVEMENTSTOCK_DESTINATION_SILVER_ITEM_GRID = "TRANSACTION_STOCKJOURNAL_MOVEMENTSTOCK_DESTINATION_SILVER_ITEM_GRID",
  TRANSACTION_STOCKJOURNAL_MOVEMENTSTOCK_DESTINATION_DIAMOND_ITEM_GRID = "TRANSACTION_STOCKJOURNAL_MOVEMENTSTOCK_DESTINATION_DIAMOND_ITEM_GRID",
  TRANSACTION_STOCKJOURNAL_MOVEMENTSTOCK_DESTINATION_STONE_GEMS_ITEM_GRID = "TRANSACTION_STOCKJOURNAL_MOVEMENTSTOCK_DESTINATION_STONE_GEMS_ITEM_GRID",
  TRANSACTION_STOCKJOURNAL_MOVEMENTSTOCK_DESTINATION_IMITATION_ITEM_GRID = "TRANSACTION_STOCKJOURNAL_MOVEMENTSTOCK_DESTINATION_IMITATION_ITEM_GRID",
  TRANSACTION_STOCKJOURNAL_MOVEMENTSTOCK_DESTINATION_PLATINUM_ITEM_GRID = "TRANSACTION_STOCKJOURNAL_MOVEMENTSTOCK_DESTINATION_PLATINUM_ITEM_GRID",
  TRANSACTION_STOCKJOURNAL_MOVEMENTSTOCK_DESTINATION_OTHER_ITEM_GRID = "TRANSACTION_STOCKJOURNAL_MOVEMENTSTOCK_DESTINATION_OTHER_ITEM_GRID",

  TRANSACTION_STOCKJOURNAL_MANUFACTURING_GOLD_ITEM_GRID = "TRANSACTION_STOCKJOURNAL_MANUFACTURING_GOLD_ITEM_GRID",
  TRANSACTION_STOCKJOURNAL_MANUFACTURING_SILVER_ITEM_GRID = "TRANSACTION_STOCKJOURNAL_MANUFACTURING_SILVER_ITEM_GRID",
  TRANSACTION_STOCKJOURNAL_MANUFACTURING_DIAMOND_ITEM_GRID = "TRANSACTION_STOCKJOURNAL_MANUFACTURING_DIAMOND_ITEM_GRID",
  TRANSACTION_STOCKJOURNAL_MANUFACTURING_STONE_GEMS_ITEM_GRID = "TRANSACTION_STOCKJOURNAL_MANUFACTURING_STONE_GEMS_ITEM_GRID",
  TRANSACTION_STOCKJOURNAL_MANUFACTURING_IMITATION_ITEM_GRID = "TRANSACTION_STOCKJOURNAL_MANUFACTURING_IMITATION_ITEM_GRID",
  TRANSACTION_STOCKJOURNAL_MANUFACTURING_PLATINUM_ITEM_GRID = "TRANSACTION_STOCKJOURNAL_MANUFACTURING_PLATINUM_ITEM_GRID",
  TRANSACTION_STOCKJOURNAL_MANUFACTURING_OTHER_ITEM_GRID = "TRANSACTION_STOCKJOURNAL_MANUFACTURING_OTHER_ITEM_GRID",

  TRANSACTION_STOCKJOURNAL_TRANSFER_SOURCE_GOLD_ITEM_GRID = "TRANSACTION_STOCKJOURNAL_TRANSFER_SOURCE_GOLD_ITEM_GRID",
  TRANSACTION_STOCKJOURNAL_TRANSFER_SOURCE_SILVER_ITEM_GRID = "TRANSACTION_STOCKJOURNAL_TRANSFER_SOURCE_SILVER_ITEM_GRID",
  TRANSACTION_STOCKJOURNAL_TRANSFER_SOURCE_DIAMOND_ITEM_GRID = "TRANSACTION_STOCKJOURNAL_TRANSFER_SOURCE_DIAMOND_ITEM_GRID",
  TRANSACTION_STOCKJOURNAL_TRANSFER_SOURCE_STONE_GEMS_ITEM_GRID = "TRANSACTION_STOCKJOURNAL_TRANSFER_SOURCE_STONE_GEMS_ITEM_GRID",
  TRANSACTION_STOCKJOURNAL_TRANSFER_SOURCE_IMITATION_ITEM_GRID = "TRANSACTION_STOCKJOURNAL_TRANSFER_SOURCE_IMITATION_ITEM_GRID",
  TRANSACTION_STOCKJOURNAL_TRANSFER_SOURCE_PLATINUM_ITEM_GRID = "TRANSACTION_STOCKJOURNAL_TRANSFER_SOURCE_PLATINUM_ITEM_GRID",
  TRANSACTION_STOCKJOURNAL_TRANSFER_SOURCE_OTHER_ITEM_GRID = "TRANSACTION_STOCKJOURNAL_TRANSFER_SOURCE_OTHER_ITEM_GRID",
  TRANSACTION_STOCKJOURNAL_TRANSFER_DESTINATION_GOLD_ITEM_GRID = "TRANSACTION_STOCKJOURNAL_TRANSFER_DESTINATION_GOLD_ITEM_GRID",
  TRANSACTION_STOCKJOURNAL_TRANSFER_DESTINATION_SILVER_ITEM_GRID = "TRANSACTION_STOCKJOURNAL_TRANSFER_DESTINATION_SILVER_ITEM_GRID",
  TRANSACTION_STOCKJOURNAL_TRANSFER_DESTINATION_DIAMOND_ITEM_GRID = "TRANSACTION_STOCKJOURNAL_TRANSFER_DESTINATION_DIAMOND_ITEM_GRID",
  TRANSACTION_STOCKJOURNAL_TRANSFER_DESTINATION_STONE_GEMS_ITEM_GRID = "TRANSACTION_STOCKJOURNAL_TRANSFER_DESTINATION_STONE_GEMS_ITEM_GRID",
  TRANSACTION_STOCKJOURNAL_TRANSFER_DESTINATION_IMITATION_ITEM_GRID = "TRANSACTION_STOCKJOURNAL_TRANSFER_DESTINATION_IMITATION_ITEM_GRID",
  TRANSACTION_STOCKJOURNAL_TRANSFER_DESTINATION_PLATINUM_ITEM_GRID = "TRANSACTION_STOCKJOURNAL_TRANSFER_DESTINATION_PLATINUM_ITEM_GRID",
  TRANSACTION_STOCKJOURNAL_TRANSFER_DESTINATION_OTHER_ITEM_GRID = "TRANSACTION_STOCKJOURNAL_TRANSFER_DESTINATION_OTHER_ITEM_GRID",


  FILTER_TRANSACTION_STOCKJOURNAL_TRANSFER_SOURCE_SILVER_ITEM_GRID = "FILTER_TRANSACTION_STOCKJOURNAL_TRANSFER_SOURCE_SILVER_ITEM_GRID",
  FILTER_TRANSACTION_STOCKJOURNAL_TRANSFER_SOURCE_GOLD_ITEM_GRID = "FILTER_TRANSACTION_STOCKJOURNAL_TRANSFER_SOURCE_GOLD_ITEM_GRID",
  FILTER_TRANSACTION_STOCKJOURNAL_TRANSFER_SOURCE_DIAMOND_ITEM_GRID = "FILTER_TRANSACTION_STOCKJOURNAL_TRANSFER_SOURCE_DIAMOND_ITEM_GRID",
  FILTER_TRANSACTION_STOCKJOURNAL_TRANSFER_SOURCE_STONE_GEMS_ITEM_GRID = "FILTER_TRANSACTION_STOCKJOURNAL_TRANSFER_SOURCE_STONE_GEMS_ITEM_GRID",
  FILTER_TRANSACTION_STOCKJOURNAL_TRANSFER_SOURCE_IMITATION_ITEM_GRID = "FILTER_TRANSACTION_STOCKJOURNAL_TRANSFER_SOURCE_IMITATION_ITEM_GRID",
  FILTER_TRANSACTION_STOCKJOURNAL_TRANSFER_SOURCE_PLATINUM_ITEM_GRID = "FILTER_TRANSACTION_STOCKJOURNAL_TRANSFER_SOURCE_PLATINUM_ITEM_GRID",
  FILTER_TRANSACTION_STOCKJOURNAL_TRANSFER_SOURCE_OTHER_ITEM_GRID = "FILTER_TRANSACTION_STOCKJOURNAL_TRANSFER_SOURCE_OTHER_ITEM_GRID",
  FILTER_TRANSACTION_STOCKJOURNAL_TRANSFER_DESTINATION_GOLD_ITEM_GRID = "FILTER_TRANSACTION_STOCKJOURNAL_TRANSFER_DESTINATION_GOLD_ITEM_GRID",
  FILTER_TRANSACTION_STOCKJOURNAL_TRANSFER_DESTINATION_SILVER_ITEM_GRID = "FILTER_TRANSACTION_STOCKJOURNAL_TRANSFER_DESTINATION_SILVER_ITEM_GRID",
  FILTER_TRANSACTION_STOCKJOURNAL_TRANSFER_DESTINATION_DIAMOND_ITEM_GRID = "FILTER_TRANSACTION_STOCKJOURNAL_TRANSFER_DESTINATION_DIAMOND_ITEM_GRID",
  FILTER_TRANSACTION_STOCKJOURNAL_TRANSFER_DESTINATION_STONE_GEMS_ITEM_GRID = "FILTER_TRANSACTION_STOCKJOURNAL_TRANSFER_DESTINATION_STONE_GEMS_ITEM_GRID",
  FILTER_TRANSACTION_STOCKJOURNAL_TRANSFER_DESTINATION_IMITATION_ITEM_GRID = "FILTER_TRANSACTION_STOCKJOURNAL_TRANSFER_DESTINATION_IMITATION_ITEM_GRID",
  FILTER_TRANSACTION_STOCKJOURNAL_TRANSFER_DESTINATION_PLATINUM_ITEM_GRID = "FILTER_TRANSACTION_STOCKJOURNAL_TRANSFER_DESTINATION_PLATINUM_ITEM_GRID",
  FILTER_TRANSACTION_STOCKJOURNAL_TRANSFER_DESTINATION_OTHER_ITEM_GRID = "FILTER_TRANSACTION_STOCKJOURNAL_TRANSFER_DESTINATION_OTHER_ITEM_GRID",
  FILTER_TRANSACTION_ADD_DIAMOND_IN_PAYMENT_GRID = "FILTER_TRANSACTION_ADD_DIAMOND_IN_PAYMENT_GRID",
  FILTER_TRANSACTION_PAYMENT_LIST_GRID ="FILTER_TRANSACTION_PAYMENT_LIST_GRID",
  
  SORT_TRANSACTION_STOCKJOURNAL_TRANSFER_SOURCE_SILVER_ITEM_GRID = "SORT_TRANSACTION_STOCKJOURNAL_TRANSFER_SOURCE_SILVER_ITEM_GRID",
  SORT_TRANSACTION_STOCKJOURNAL_TRANSFER_SOURCE_GOLD_ITEM_GRID = "SORT_TRANSACTION_STOCKJOURNAL_TRANSFER_SOURCE_GOLD_ITEM_GRID",
  SORT_TRANSACTION_STOCKJOURNAL_TRANSFER_SOURCE_DIAMOND_ITEM_GRID = "SORT_TRANSACTION_STOCKJOURNAL_TRANSFER_SOURCE_DIAMOND_ITEM_GRID",
  SORT_TRANSACTION_STOCKJOURNAL_TRANSFER_SOURCE_STONE_GEMS_ITEM_GRID = "SORT_TRANSACTION_STOCKJOURNAL_TRANSFER_SOURCE_STONE_GEMS_ITEM_GRID",
  SORT_TRANSACTION_STOCKJOURNAL_TRANSFER_SOURCE_IMITATION_ITEM_GRID = "SORT_TRANSACTION_STOCKJOURNAL_TRANSFER_SOURCE_IMITATION_ITEM_GRID",
  SORT_TRANSACTION_STOCKJOURNAL_TRANSFER_SOURCE_PLATINUM_ITEM_GRID = "SORT_TRANSACTION_STOCKJOURNAL_TRANSFER_SOURCE_PLATINUM_ITEM_GRID",
  SORT_TRANSACTION_STOCKJOURNAL_TRANSFER_SOURCE_OTHER_ITEM_GRID = "SORT_TRANSACTION_STOCKJOURNAL_TRANSFER_SOURCE_OTHER_ITEM_GRID",
  SORT_TRANSACTION_STOCKJOURNAL_TRANSFER_DESTINATION_GOLD_ITEM_GRID = "SORT_TRANSACTION_STOCKJOURNAL_TRANSFER_DESTINATION_GOLD_ITEM_GRID",
  SORT_TRANSACTION_STOCKJOURNAL_TRANSFER_DESTINATION_SILVER_ITEM_GRID = "SORT_TRANSACTION_STOCKJOURNAL_TRANSFER_DESTINATION_SILVER_ITEM_GRID",
  SORT_TRANSACTION_STOCKJOURNAL_TRANSFER_DESTINATION_DIAMOND_ITEM_GRID = "SORT_TRANSACTION_STOCKJOURNAL_TRANSFER_DESTINATION_DIAMOND_ITEM_GRID",
  SORT_TRANSACTION_STOCKJOURNAL_TRANSFER_DESTINATION_STONE_GEMS_ITEM_GRID = "SORT_TRANSACTION_STOCKJOURNAL_TRANSFER_DESTINATION_STONE_GEMS_ITEM_GRID",
  SORT_TRANSACTION_STOCKJOURNAL_TRANSFER_DESTINATION_IMITATION_ITEM_GRID = "SORT_TRANSACTION_STOCKJOURNAL_TRANSFER_DESTINATION_IMITATION_ITEM_GRID",
  SORT_TRANSACTION_STOCKJOURNAL_TRANSFER_DESTINATION_PLATINUM_ITEM_GRID = "SORT_TRANSACTION_STOCKJOURNAL_TRANSFER_DESTINATION_PLATINUM_ITEM_GRID",
  SORT_TRANSACTION_STOCKJOURNAL_TRANSFER_DESTINATION_OTHER_ITEM_GRID = "SORT_TRANSACTION_STOCKJOURNAL_TRANSFER_DESTINATION_OTHER_ITEM_GRID",
  SORT_TRANSACTION_ADD_DIAMOND_IN_PAYMENT_GRID = "SORT_TRANSACTION_ADD_DIAMOND_IN_PAYMENT_GRID",
  SORT_TRANSACTION_PAYMENT_LIST_GRID ="SORT_TRANSACTION_PAYMENT_LIST_GRID",

  TRANSACTION_ADD_DIAMOND_IN_PAYMENT_GRID_INITIAL_ROWS = "TRANSACTION_ADD_DIAMOND_IN_PAYMENT_GRID_INITIAL_ROWS",

  PAGINATION_TRANSACTION_STOCKJOURNAL_TRANSFER_SOURCE_SILVER_ITEM_GRID = "PAGINATION_TRANSACTION_STOCKJOURNAL_TRANSFER_SOURCE_SILVER_ITEM_GRID",
  PAGINATION_TRANSACTION_STOCKJOURNAL_TRANSFER_SOURCE_GOLD_ITEM_GRID = "PAGINATION_TRANSACTION_STOCKJOURNAL_TRANSFER_SOURCE_GOLD_ITEM_GRID",
  PAGINATION_TRANSACTION_STOCKJOURNAL_TRANSFER_SOURCE_DIAMOND_ITEM_GRID = "PAGINATION_TRANSACTION_STOCKJOURNAL_TRANSFER_SOURCE_DIAMOND_ITEM_GRID",
  PAGINATION_TRANSACTION_STOCKJOURNAL_TRANSFER_SOURCE_STONE_GEMS_ITEM_GRID = "PAGINATION_TRANSACTION_STOCKJOURNAL_TRANSFER_SOURCE_STONE_GEMS_ITEM_GRID",
  PAGINATION_TRANSACTION_STOCKJOURNAL_TRANSFER_SOURCE_IMITATION_ITEM_GRID = "PAGINATION_TRANSACTION_STOCKJOURNAL_TRANSFER_SOURCE_IMITATION_ITEM_GRID",
  PAGINATION_TRANSACTION_STOCKJOURNAL_TRANSFER_SOURCE_PLATINUM_ITEM_GRID = "PAGINATION_TRANSACTION_STOCKJOURNAL_TRANSFER_SOURCE_PLATINUM_ITEM_GRID",
  PAGINATION_TRANSACTION_STOCKJOURNAL_TRANSFER_SOURCE_OTHER_ITEM_GRID = "PAGINATION_TRANSACTION_STOCKJOURNAL_TRANSFER_SOURCE_OTHER_ITEM_GRID",
  PAGINATION_TRANSACTION_STOCKJOURNAL_TRANSFER_DESTINATION_GOLD_ITEM_GRID = "PAGINATION_TRANSACTION_STOCKJOURNAL_TRANSFER_DESTINATION_GOLD_ITEM_GRID",
  PAGINATION_TRANSACTION_STOCKJOURNAL_TRANSFER_DESTINATION_SILVER_ITEM_GRID = "PAGINATION_TRANSACTION_STOCKJOURNAL_TRANSFER_DESTINATION_SILVER_ITEM_GRID",
  PAGINATION_TRANSACTION_STOCKJOURNAL_TRANSFER_DESTINATION_DIAMOND_ITEM_GRID = "PAGINATION_TRANSACTION_STOCKJOURNAL_TRANSFER_DESTINATION_DIAMOND_ITEM_GRID",
  PAGINATION_TRANSACTION_STOCKJOURNAL_TRANSFER_DESTINATION_STONE_GEMS_ITEM_GRID = "PAGINATION_TRANSACTION_STOCKJOURNAL_TRANSFER_DESTINATION_STONE_GEMS_ITEM_GRID",
  PAGINATION_TRANSACTION_STOCKJOURNAL_TRANSFER_DESTINATION_IMITATION_ITEM_GRID = "PAGINATION_TRANSACTION_STOCKJOURNAL_TRANSFER_DESTINATION_IMITATION_ITEM_GRID",
  PAGINATION_TRANSACTION_STOCKJOURNAL_TRANSFER_DESTINATION_PLATINUM_ITEM_GRID = "PAGINATION_TRANSACTION_STOCKJOURNAL_TRANSFER_DESTINATION_PLATINUM_ITEM_GRID",
  PAGINATION_TRANSACTION_STOCKJOURNAL_TRANSFER_DESTINATION_OTHER_ITEM_GRID = "PAGINATION_TRANSACTION_STOCKJOURNAL_TRANSFER_DESTINATION_OTHER_ITEM_GRID",
  PAGINATION_TRANSACTION_PAYMENT_LIST_GRID ="PAGINATION_TRANSACTION_PAYMENT_LIST_GRID",

  TRANSACTION_STOCKJOURNAL_STOCKOUT_GOLD_ITEM_GRID = "TRANSACTION_STOCKJOURNAL_STOCKOUT_GOLD_ITEM_GRID",
  TRANSACTION_STOCKJOURNAL_STOCKOUT_SILVER_ITEM_GRID = "TRANSACTION_STOCKJOURNAL_STOCKOUT_SILVER_ITEM_GRID",
  TRANSACTION_STOCKJOURNAL_STOCKOUT_DIAMOND_ITEM_GRID = "TRANSACTION_STOCKJOURNAL_STOCKOUT_DIAMOND_ITEM_GRID",
  TRANSACTION_STOCKJOURNAL_STOCKOUT_STONE_GEMS_ITEM_GRID = "TRANSACTION_STOCKJOURNAL_STOCKOUT_STONE_GEMS_ITEM_GRID",
  TRANSACTION_STOCKJOURNAL_STOCKOUT_IMITATION_ITEM_GRID = "TRANSACTION_STOCKJOURNAL_STOCKOUT_IMITATION_ITEM_GRID",
  TRANSACTION_STOCKJOURNAL_STOCKOUT_PLATINUM_ITEM_GRID = "TRANSACTION_STOCKJOURNAL_STOCKOUT_PLATINUM_ITEM_GRID",
  TRANSACTION_STOCKJOURNAL_STOCKOUT_OTHER_ITEM_GRID = "TRANSACTION_STOCKJOURNAL_STOCKOUT_OTHER_ITEM_GRID",
  TRANSACTION_STOCKJOURNAL_STOCKOUT_ITEM_GRID = "TRANSACTION_STOCKJOURNAL_STOCKOUT_ITEM_GRID",

  TRANSACTION_STOCKJOURNAL2_POPUP_GOLD_ITEM_GRID="TRANSACTION_STOCKJOURNAL2_POPUP_GOLD_ITEM_GRID",
  TRANSACTION_STOCKJOURNAL2_POPUP_SILVER_ITEM_GRID="TRANSACTION_STOCKJOURNAL2_POPUP_SILVER_ITEM_GRID",
  TRANSACTION_STOCKJOURNAL2_POPUP_DIAMOND_ITEM_GRID="TRANSACTION_STOCKJOURNAL2_POPUP_DIAMOND_ITEM_GRID",
  TRANSACTION_STOCKJOURNAL2_POPUP_STONE_GEMS_ITEM_GRID="TRANSACTION_STOCKJOURNAL2_POPUP_STONE_GEMS_ITEM_GRID",
  TRANSACTION_STOCKJOURNAL2_POPUP_IMITATION_ITEM_GRID="TRANSACTION_STOCKJOURNAL2_POPUP_IMITATION_ITEM_GRID",
  TRANSACTION_PDCENTRY_GRID = "TRANSACTION_PDCENTRY_GRID",
  TRANSACTION_PDCENTRY_PAYMENTLIST_GRID = "TRANSACTION_PDCENTRY_PAYMENTLIST_GRID",
  TRANSACTION_CONTRAVOUCHER_GRID = "TRANSACTION_CONTRAVOUCHER_GRID",
  TRANSACTION_JOURNALVOUCHER_GRID = "TRANSACTION_JOURNALVOUCHER_GRID",
  TRANSACTION_PURCHASEFIXING_GRID = "TRANSACTION_PURCHASEFIXING_GRID",
  TRANSACTION_SALEFIXING_GRID = "TRANSACTION_SALEFIXING_GRID",
  TRANSACTION_REPAIRINVOICE_GOLD_ITEM_GRID = "TRANSACTION_REPAIRINVOICE_GOLD_ITEM_GRID",
  TRANSACTION_REPAIRINVOICE_SILVER_ITEM_GRID = "TRANSACTION_REPAIRINVOICE_SILVER_ITEM_GRID",
  TRANSACTION_REPAIRINVOICE_DIAMOND_ITEM_GRID = "TRANSACTION_REPAIRINVOICE_DIAMOND_ITEM_GRID",
  TRANSACTION_REPAIRINVOICE_STONE_GEMS_ITEM_GRID = "TRANSACTION_REPAIRINVOICE_STONE_GEMS_ITEM_GRID",
  TRANSACTION_REPAIRINVOICE_IMITATION_ITEM_GRID = "TRANSACTION_REPAIRINVOICE_IMITATION_ITEM_GRID",
  TRANSACTION_REPAIRINVOICE_PLATINUM_ITEM_GRID = "TRANSACTION_REPAIRINVOICE_PLATINUM_ITEM_GRID",
  TRANSACTION_REPAIRINVOICE_OTHER_ITEM_GRID = "TRANSACTION_REPAIRINVOICE_OTHER_ITEM_GRID",
  TRANSACTION_REPAIRINVOICE_PRODUCTLIST_GRID = "TRANSACTION_REPAIRINVOICE_PRODUCTLIST_GRID",
  TRANSACTION_REPAIRINVOICE_TAX_GRID = "TRANSACTION_REPAIRINVOICE_TAX_GRID",
  TRANSACTION_REPAIRINVOICE_PAYMENT_GRID = "TRANSACTION_REPAIRINVOICE_PAYMENT_GRID",
  TRANSACTION_REPAIRINVOICE_ADDITIONALAMOUNT_GRID = "TRANSACTION_REPAIRINVOICE_ADDITIONALAMOUNT_GRID",
  TRANSACTION_REPAIRINVOICE_SALEORDERPOPUP_GRID = "TRANSACTION_REPAIRINVOICE_SALEORDERPOPUP_GRID",

  TRANSACTION_JOBORDERLIST_GRID = "TRANSACTION_JOBORDERLIST_GRID",
  TRANSACTION_POC_GRID = "TRANSACTION_POC_GRID",

  PAGINATION_INVESTMENT_FUND_CREATE_SHECEM_GRID="PAGINATION_INVESTMENT_FUND_CREATE_SHECEM_GRID",
  INVESTMENT_FUND_CREATE_SHECEM_GRID="INVESTMENT_FUND_CREATE_SHECEM_GRID",
  FILTER_INVESTMENT_FUND_CREATE_SHECEM_GRID="FILTER_INVESTMENT_FUND_CREATE_SHECEM_GRID",
  SORT_INVESTMENT_FUND_CREATE_SHECEM_GRID="SORT_INVESTMENT_FUND_CREATE_SHECEM_GRID",
  INVENTORY_PHYSICALSTOCK_GOLD_ITEM_GRID = "INVENTORY_PHYSICALSTOCK_GOLD_ITEM_GRID",
  INVENTORY_PHYSICALSTOCK_SILVER_ITEM_GRID = "INVENTORY_PHYSICALSTOCK_SILVER_ITEM_GRID",
  INVENTORY_PHYSICALSTOCK_DIAMOND_ITEM_GRID = "INVENTORY_PHYSICALSTOCK_DIAMOND_ITEM_GRID",
  INVENTORY_PHYSICALSTOCK_STONE_GEMS_ITEM_GRID = "INVENTORY_PHYSICALSTOCK_STONE_GEMS_ITEM_GRID",
  INVENTORY_PHYSICALSTOCK_IMITATION_ITEM_GRID = "INVENTORY_PHYSICALSTOCK_IMITATION_ITEM_GRID",
  INVENTORY_PHYSICALSTOCK_PLATINUM_ITEM_GRID = "INVENTORY_PHYSICALSTOCK_PLATINUM_ITEM_GRID",
  INVENTORY_PHYSICALSTOCK_OTHER_ITEM_GRID = "INVENTORY_PHYSICALSTOCK_OTHER_ITEM_GRID",
  INVENTORY_PHYSICALSTOCK_PAYMENT_TABLE_GRID = "INVENTORY_PHYSICALSTOCK_PAYMENT_TABLE_GRID",
  INVENTORY_PHYSICALSTOCK_PRODUCTLIST_GRID = "INVENTORY_PHYSICALSTOCK_PRODUCTLIST_GRID",
  INVENTORY_JEWELRY_CATALOGUE_BILL_OF_MATERIAL_GRID = "INVENTORY_JEWELRY_CATALOGUE_BILL_OF_MATERIAL_GRID",
  INVENTORY_CONSIGNMENT_IN_PRODUCTLIST_GRID = "INVENTORY_CONSIGNMENT_IN_PRODUCTLIST_GRID",
  INVENTORY_CONSIGNMENT_IN_PAYMENT_LIST_GRID = "INVENTORY_CONSIGNMENT_IN_PAYMENT_LIST_GRID",
  INVENTORY_CONSIGNMENT_OUT_PRODUCTLIST_GRID = "INVENTORY_CONSIGNMENT_OUT_PRODUCTLIST_GRID",
  INVENTORY_CONSIGNMENT_OUT_PAYMENT_LIST_GRID = "INVENTORY_CONSIGNMENT_OUT_PAYMENT_LIST_GRID",

  INVENTORY_MEMOREPORT_LEDGERLIST_GRID="INVENTORY_MEMOREPORT_LEDGERLIST_GRID",
  FILTER_INVENTORY_MEMOREPORT_LEDGERLIST_GRID="FILTER_INVENTORY_MEMOREPORT_LEDGERLIST_GRID",
  SORT_INVENTORY_MEMOREPORT_LEDGERLIST_GRID="SORT_INVENTORY_MEMOREPORT_LEDGERLIST_GRID",
  PAGINATION_INVENTORY_MEMOREPORT_LEDGERLIST_GRID="PAGINATION_INVENTORY_MEMOREPORT_LEDGERLIST_GRID",
  INVENTORY_MEMOREPORT_LEDGERLIST_GRID_INITIAL_ROWS="INVENTORY_MEMOREPORT_LEDGERLIST_GRID_INITIAL_ROWS",
  INVENTORY_MEMOREPORT_DETAILS_GRID = "INVENTORY_MEMOREPORT_DETAILS_GRID",
  INVENTORY_STOCK_SUMMARY_REPORT_GRID = " INVENTORY_STOCK_SUMMARY_REPORT_GRID",
  TRANSACTION_PAYMENT_LIST_GRID ="TRANSACTION_PAYMENT_LIST_GRID",
  APIREQUEST_TRANSACTION_PAYMENT_LIST_GRID ="APIREQUEST_TRANSACTION_PAYMENT_LIST_GRID",
  TRANSACTION_STOCKJOURNAL2_LIST_GRID="TRANSACTION_STOCKJOURNAL2_LIST_GRID",
  FILTER_TRANSACTION_STOCKJOURNAL2_LIST_GRID="FILTER_TRANSACTION_STOCKJOURNAL2_LIST_GRID",
  PRODUCT_PRODUCTOPENING_TAX_GRID = "PRODUCT_PRODUCTOPENING_TAX_GRID",
  TRANSACTION_EXPENSES_GRID = "TRANSACTION_EXPENSES_GRID",

  INVENTORY_IMMITATION_AND_WATCHES_GRID = "INVENTORY_IMMITATION_AND_WATCHES_GRID",
  FILTER_INVENTORY_IMMITATION_AND_WATCHES_GRID = "FILTER_INVENTORY_IMMITATION_AND_WATCHES_GRID",
  SORT_INVENTORY_IMMITATION_AND_WATCHES_GRID = "SORT_INVENTORY_IMMITATION_AND_WATCHES_GRID",
  PAGINATION_INVENTORY_IMMITATION_AND_WATCHES_GRID = "PAGINATION_INVENTORY_IMMITATION_AND_WATCHES_GRID",
  APIREQUEST_INVENTORY_IMMITATION_AND_WATCHES_GRID = "APIREQUEST_INVENTORY_IMMITATION_AND_WATCHES_GRID",

  PRODUCT_PRODUCTOPENING_PRODUCTLIST_GRID = "PRODUCT_PRODUCTOPENING_PRODUCTLIST_GRID",
  FILTER_PRODUCT_PRODUCTOPENING_PRODUCTLIST_GRID = "FILTER_PRODUCT_PRODUCTOPENING_PRODUCTLIST_GRID",
  SORT_PRODUCT_PRODUCTOPENING_PRODUCTLIST_GRID = "SORT_PRODUCT_PRODUCTOPENING_PRODUCTLIST_GRID",
  PAGINATION_PRODUCT_PRODUCTOPENING_PRODUCTLIST_GRID = "PAGINATION_PRODUCT_PRODUCTOPENING_PRODUCTLIST_GRID",
  PRODUCT_PRODUCTOPENING_PRODUCTLIST_GRID_INITIAL_ROWS ="PRODUCT_PRODUCTOPENING_PRODUCTLIST_GRID_INITIAL_ROWS",
  
  PRODUCT_PRODUCTOPENING_PRODUCT_CHARACTERISTIC_GRID = "PRODUCT_PRODUCTOPENING_PRODUCT_CHARACTERISTIC_GRID",
  PRODUCT_PRODUCTOPENING_PRODUCT_CHARGES_GRID = "PRODUCT_PRODUCTOPENING_PRODUCT_CHARGES_GRID",
  PRODUCT_PRODUCTOPENING_MANUFACTURING_GOLD_ITEM_GRID = "PRODUCT_PRODUCTOPENING_MANUFACTURING_GOLD_ITEM_GRID",
  PRODUCT_PRODUCTOPENING_MANUFACTURING_GOLD_DESCRIPTION_GRID = "PRODUCT_PRODUCTOPENING_MANUFACTURING_GOLD_DESCRIPTION_GRID",
  PRODUCT_PRODUCTOPENING_MANUFACTURING_SILVER_ITEM_GRID = "PRODUCT_PRODUCTOPENING_MANUFACTURING_SILVER_ITEM_GRID",
  PRODUCT_PRODUCTOPENING_MANUFACTURING_SILVER_DESCRIPTION_GRID = "PRODUCT_PRODUCTOPENING_MANUFACTURING_SILVER_DESCRIPTION_GRID",
  PRODUCT_PRODUCTOPENING_MANUFACTURING_DIAMOND_ITEM_GRID = "PRODUCT_PRODUCTOPENING_MANUFACTURING_DIAMOND_ITEM_GRID",
  PRODUCT_PRODUCTOPENING_MANUFACTURING_DIAMOND_DESCRIPTION_GRID = "PRODUCT_PRODUCTOPENING_MANUFACTURING_DIAMOND_DESCRIPTION_GRID",
  PRODUCT_PRODUCTOPENING_MANUFACTURING_STONE_GEMS_ITEM_GRID = "PRODUCT_PRODUCTOPENING_MANUFACTURING_STONE_GEMS_ITEM_GRID",
  PRODUCT_PRODUCTOPENING_MANUFACTURING_STONE_GEMS_DESCRIPTION_GRID = "PRODUCT_PRODUCTOPENING_MANUFACTURING_STONE_GEMS_DESCRIPTION_GRID",
  PRODUCT_PRODUCTOPENING_MANUFACTURING_IMITATION_ITEM_GRID = "PRODUCT_PRODUCTOPENING_MANUFACTURING_IMITATION_ITEM_GRID",
  PRODUCT_PRODUCTOPENING_MANUFACTURING_IMITATION_DESCRIPTION_GRID = "PRODUCT_PRODUCTOPENING_MANUFACTURING_IMITATION_DESCRIPTION_GRID",
  PRODUCT_PRODUCTOPENING_MANUFACTURING_PLATINUM_ITEM_GRID = "PRODUCT_PRODUCTOPENING_MANUFACTURING_PLATINUM_ITEM_GRID",
  PRODUCT_PRODUCTOPENING_MANUFACTURING_PLATINUM_DESCRIPTION_GRID = "PRODUCT_PRODUCTOPENING_MANUFACTURING_PLATINUM_DESCRIPTION_GRID",
  PRODUCT_PRODUCTOPENING_MANUFACTURING_OTHER_ITEM_GRID = "PRODUCT_PRODUCTOPENING_MANUFACTURING_OTHER_ITEM_GRID",
  PRODUCT_PRODUCTOPENING_MANUFACTURING_OTHER_DESCRIPTION_GRID = "PRODUCT_PRODUCTOPENING_MANUFACTURING_OTHER_DESCRIPTION_GRID",
  PRODUCT_LEDGEROPENING_METALWISE_OPENING_GRID = "PRODUCT_LEDGEROPENING_METALWISE_OPENING_GRID",
  PRODUCT_ACCOUNT_LEDGER_GRID = "PRODUCT_ACCOUNT_LEDGER_GRID",
  PRODUCT_LEDGEROPENING_METALTYPETAX_GRID="PRODUCT_LEDGEROPENING_METALTYPETAX_GRID",

  PRODUCT_PARTIES_LEDGERLIST_GRID = "PRODUCT_PARTIES_LEDGERLIST_GRID",
  FILTER_PRODUCT_PARTIES_LEDGERLIST_GRID = "FILTER_PRODUCT_PARTIES_LEDGERLIST_GRID",
  SORT_PRODUCT_PARTIES_LEDGERLIST_GRID = "SORT_PRODUCT_PARTIES_LEDGERLIST_GRID",
  PAGINATION_PRODUCT_PARTIES_LEDGERLIST_GRID = "PAGINATION_PRODUCT_PARTIES_LEDGERLIST_GRID",
  PRODUCT_PARTIES_LEDGERLIST_GRID_INITIAL_ROWS = "PRODUCT_PARTIES_LEDGERLIST_GRID_INITIAL_ROWS",
 
  PRODUCT_PARTIES_TRANSACTION_GRID = "PRODUCT_PARTIES_TRANSACTION_GRID",

  REPORT_INVOICES_METAL_FIXING_GRID = "REPORT_INVOICES_METAL_FIXING_GRID",
  REPORT_ACCOUNTLEDGERREPORT_AMOUNT_GRID = "REPORT_ACCOUNTLEDGERREPORT_AMOUNT_GRID",
  REPORT_SCHEDULAR_TASKS_GRID = "REPORT_SCHEDULAR_TASKS_GRID",
  REPORT_ACCOUNTLEDGERREPORT_METAL_GRID = "REPORT_ACCOUNTLEDGERREPORT_METAL_GRID",
  REPORT_ACCOUNTLEDGERREPORT_VIEWALLLEDGERAMT_GRID_TEMP = "REPORT_ACCOUNTLEDGERREPORT_VIEWALLLEDGERAMT_GRID_TEMP",

  REPORT_CONSIGNMENT_ITEMS_GRID = "REPORT_CONSIGNMENT_ITEMS_GRID",
  FUND_TRANSFER_GRID="FUND_TRANSFER_GRID",
  REPORT_DAYREPORT_GRID = "REPORT_DAYREPORT_GRID",
  REPORT_KYCREPORT_GRID = "REPORT_KYCREPORT_GRID",
  REPORT_KYC_LEDGER_DETAILS_GRID = "REPORT_KYC_LEDGER_DETAILS_GRID",
  REPORT_STOCKREPORT_CURRENTSTOCK_GRID = "REPORT_STOCKREPORT_CURRENTSTOCK_GRID",
  REPORT_STOCKREPORT_DIAMONDCURRENTSTOCK_GRID = "REPORT_STOCKREPORT_DIAMONDCURRENTSTOCK_GRID",
  REPORT_STOCKREPORT_STOCKHISTORY_GOLD_GRID = "REPORT_STOCKREPORT_STOCKHISTORY_GOLD_GRID",
  REPORT_STOCKREPORT_STOCKHISTORY_SILVER_GRID = "REPORT_STOCKREPORT_STOCKHISTORY_SILVER_GRID",
  REPORT_STOCKREPORT_STOCKHISTORY_DIAMOND_GRID = "REPORT_STOCKREPORT_STOCKHISTORY_DIAMOND_GRID",
  REPORT_STOCKREPORT_STOCKHISTORY_STONEGEMS_GRID = "REPORT_STOCKREPORT_STOCKHISTORY_STONEGEMS_GRID",
  REPORT_STOCKREPORT_STOCKHISTORY_IMITATIONORWATCHES_GRID = "REPORT_STOCKREPORT_STOCKHISTORY_IMITATIONORWATCHES_GRID",
  REPORT_STOCKREPORT_STOCKDETAILS_GRID = "REPORT_STOCKREPORT_STOCKDETAILS_GRID",
  REPORT_STOCKREPORT_STOCKDHISTORY_REPORT_GRID = "REPORT_STOCKREPORT_STOCKDHISTORY_REPORT_GRID",
  REPORT_STOCKREPORT_STOCKAVAILABILITY_INWARDWEIGHT_GRID = "REPORT_STOCKREPORT_STOCKAVAILABILITY_INWARDWEIGHT_GRID",
  REPORT_STOCKREPORT_STOCKAVAILABILITY_OUTWEIGHT_GRID = "REPORT_STOCKREPORT_STOCKAVAILABILITY_OUTWARDWEIGHT_GRID",
  REPORT_STOCKREPORT_RFID_STOCK_SCANNEDSTOCK_ITEM_GRID = "REPORT_STOCKREPORT_RFID_STOCK_SCANNEDSTOCK_ITEM_GRID",
  REPORT_STOCKREPORT_RFID_STOCK_UNKNOWNSTOCK_ITEM_GRID = "REPORT_STOCKREPORT_RFID_STOCK_UNKNOWNSTOCK_ITEM_GRID",
  REPORT_STOCKREPORT_RFID_STOCK_AVAILABLESTOCK_ITEM_GRID = "REPORT_STOCKREPORT_RFID_STOCK_AVAILABLESTOCK_ITEM_GRID",
  REPORT_STOCKAGEING_GRID = "REPORT_STOCKAGEING_GRID",
  REPORT_BALANCEAGEING_GRID = "REPORT_BALANCEAGEING_GRID",
  REPORT_STOCKREPORT_STOCKANALYSISREPORTBYPRODUCT_GRID="REPORT_STOCKREPORT_STOCKANALYSISREPORTBYPRODUCT_GRID",
  FILTER_REPORT_STOCKREPORT_STOCKANALYSISREPORTBYPRODUCT_GRID="FILTER_REPORT_STOCKREPORT_STOCKANALYSISREPORTBYPRODUCT_GRID",
  SORT_REPORT_STOCKREPORT_STOCKANALYSISREPORTBYPRODUCT_GRID="SORT_REPORT_STOCKREPORT_STOCKANALYSISREPORTBYPRODUCT_GRID",
  PAGINATION_REPORT_STOCKREPORT_STOCKANALYSISREPORTBYPRODUCT_GRID="PAGINATION_REPORT_STOCKREPORT_STOCKANALYSISREPORTBYPRODUCT_GRID",
  REPORT_DIAMOND_STOCK_AVAILABILITY_OUTWARD_GRID ="REPORT_DIAMOND_STOCK_AVAILABILITY_OUTWARD_GRID",
  FILTER_REPORT_DIAMOND_STOCK_AVAILABILITY_OUTWARD_GRID ="FILTER_REPORT_DIAMOND_STOCK_AVAILABILITY_OUTWARD_GRID",
  SORT_REPORT_DIAMOND_STOCK_AVAILABILITY_OUTWARD_GRID ="SORT_REPORT_DIAMOND_STOCK_AVAILABILITY_OUTWARD_GRID",
  PAGINATION_REPORT_DIAMOND_STOCK_AVAILABILITY_OUTWARD_GRID ="PAGINATION_REPORT_DIAMOND_STOCK_AVAILABILITY_OUTWARD_GRID",
  REPORT_DIAMOND_STOCK_AVAILABILITY_INWARD_GRID="REPORT_DIAMOND_STOCK_AVAILABILITY_INWARD_GRID",
  FILTER_DIAMOND_STOCK_AVAILABILITY_INWARD_GRID="FILTER_DIAMOND_STOCK_AVAILABILITY_INWARD_GRID",
  SORT_DIAMOND_STOCK_AVAILABILITY_INWARD_GRID="SORT_DIAMOND_STOCK_AVAILABILITY_INWARD_GRID",
  PAGINATION_DIAMOND_STOCK_AVAILABILITY_INWARD_GRID="PAGINATION_DIAMOND_STOCK_AVAILABILITY_INWARD_GRID",

REPORT_DIAMOND_STONE_STONEGEMS_GRID="REPORT_DIAMOND_STONE_STONEGEMS_GRID",
FILTER_REPORT_DIAMOND_STONE_STONEGEMS_GRID="FILTER_REPORT_DIAMOND_STONE_STONEGEMS_GRID",
SORT_REPORT_DIAMOND_STONE_STONEGEMS_GRID="SORT_REPORT_DIAMOND_STONE_STONEGEMS_GRID",
PAGINATION_DIAMOND_STONE_STONEGEMS_GRID="PAGINATION_DIAMOND_STONE_STONEGEMS_GRID",

  REPORT_STOCKREPORT_BARCODE_STOCK_ITEM_GRID = "REPORT_STOCKREPORT_BARCODE_STOCK_ITEM_GRID",
  REPORT_STOCKREPORT_BARCODE_STOCK_ITEM_POPUP_GRID="REPORT_STOCKREPORT_BARCODE_STOCK_ITEM_POPUP_GRID",
  APIREQUEST_INVENTORY_BARCODE_PROCESS_GRID = "APIREQUEST_INVENTORY_BARCODE_PROCESS_GRID",

  ORDERS_REPAIRORDER_PAYMENT_GRID = "ORDERS_REPAIRORDER_PAYMENT_GRID",
  ORDERS_REPAIRORDER_PRODUCTLIST_GRID = "ORDERS_REPAIRORDER_PRODUCTLIST_GRID",
  ORDERS_REPAIRORDER_TAX_GRID = "ORDERS_REPAIRORDER_TAX_GRID",
  ORDERS_REPAIRORDER_GOLD_ITEM_GRID = "ORDERS_REPAIRORDER_GOLD_ITEM_GRID",
  ORDERS_REPAIRORDER_SILVER_ITEM_GRID="ORDERS_REPAIRORDER_SILVER_ITEM_GRID",
  ORDERS_REPAIRORDER_DIAMOND_ITEM_GRID = "ORDERS_REPAIRORDER_DIAMOND_ITEM_GRID",
  ORDERS_REPAIRORDER_STONE_GEMS_ITEM_GRID = "ORDERS_REPAIRORDER_STONE_GEMS_ITEM_GRID",
  ORDERS_REPAIRORDER_IMITATION_ITEM_GRID = "ORDERS_REPAIRORDER_IMITATION_ITEM_GRID",
  ORDER_SALEORDERPROCESS_JOBCARD_GRID="ORDER_SALEORDERPROCESS_JOBCARD_GRID",
  APIREQUEST_ORDER_SALEORDERPROCESS_JOBCARD_GRID="APIREQUEST_ORDER_SALEORDERPROCESS_JOBCARD_GRID",
  REPORT_OLD_JEWELLERY_SCRAP_GRID = "REPORT_OLD_JEWELLERY_SCRAP_GRID",
  FILTER_REPORT_OLD_JEWELLERY_SCRAP_GRID = "FILTER_REPORT_OLD_JEWELLERY_SCRAP_GRID",
  FILTER_REPORT_OLD_JEWELLERY_RECEIVED_GRID = "FILTER_REPORT_OLD_JEWELLERY_RECEIVED_GRID",
  SORT_REPORT_OLD_JEWELLERY_SCRAP_GRID = "SORT_REPORT_OLD_JEWELLERY_SCRAP_GRID",
  PAGINATION_REPORT_OLD_JEWELLERY_SCRAP_GRID = "PAGINATION_REPORT_OLD_JEWELLERY_SCRAP_GRID",
  REPORT_OLD_JEWELLERY_RECEIVED_GRID = "REPORT_OLD_JEWELLERY_RECEIVED_GRID",
  PAGINATION_REPORT_OLD_JEWELLERY_RECEIVED_GRID = "PAGINATION_REPORT_OLD_JEWELLERY_RECEIVED_GRID",
  REPORT_OLD_JEWELLERY_STOCK_GRID = "REPORT_OLD_JEWELLERY_STOCK_GRID",
  FILTER_REPORT_OLD_JEWELLERY_STOCK_GRID="FILTER_REPORT_OLD_JEWELLERY_STOCK_GRID",
  SORTING_REPORT_OLD_JEWELLERY_STOCK_GRID="SORTING_REPORT_OLD_JEWELLERY_STOCK_GRID",
  PAGINATION_REPORT_OLD_JEWELLERY_STOCK_GRID = "PAGINATION_REPORT_OLD_JEWELLERY_STOCK_GRID",
  REPORT_OLD_JEWELLERY_REFINE_GRID = "REPORT_OLD_JEWELLERY_REFINE_GRID",
  PAGINATION_REPORT_OLD_JEWELLERY_REFINE_GRID = "PAGINATION_REPORT_OLD_JEWELLERY_REFINE_GRID",

  REPORT_SALEORDER_PROCESS_LIST = "REPORT_SALEORDER_PROCESS_LIST",
  FILTER_REPORT_SALEORDER_PROCESS_GRID = "FILTER_REPORT_SALEORDER_PROCESS_GRID",
  SORT_REPORT_SALEORDER_PROCESS_GRID = "SORT_REPORT_SALEORDER_PROCESS_GRID",
  PAGINATION_REPORT_SALEORDER_PROCESS_GRID = "PAGINATION_REPORT_SALEORDER_PROCESS_GRID",
  REPORT_SALEORDER_PROCESS_GRID_INITIAL_ROWS = "REPORT_SALEORDER_PROCESS_GRID_INITIAL_ROWS",
  FILTER_REPORT_OLD_JEWELLERY_REFINE_GRID = "FILTER_REPORT_OLD_JEWELLERY_REFINE_GRID",
  SORT_REPORT_OLD_JEWELLERY_REFINE_GRID = "SORT_REPORT_OLD_JEWELLERY_REFINE_GRID",
  REPORT_ACCOUNT_LEDGER_REPORT_INITIAL_GRID = "REPORT_ACCOUNT_LEDGER_REPORT_INITIAL_GRID",
  APIREQUEST_REPORT_SALEORDER_PROCESS_GRID = "APIREQUEST_REPORT_SALEORDER_PROCESS_GRID",

  REPORT_CONSIGNMENTINLIST_GRID = "REPORT_CONSIGNMENTINLIST_GRID ",
  FILTER_REPORT_CONSIGNMENTINLIST_GRID = "FILTER_REPORT_CONSIGNMENTINLIST_GRID ",
  SORT_REPORT_CONSIGNMENTINLIST_GRID = "SORT_REPORT_CONSIGNMENTINLIST_GRID ",
  PAGINATION_REPORT_CONSIGNMENTINLIST_GRID = "PAGINATION_REPORT_CONSIGNMENTINLIST_GRID ",

  REPORT_STOCK_AGEING_GRID = "REPORT_STOCK_AGEING_GRID",
  PAGINATION_REPORT_STOCK_AGEING_GRID = "PAGINATION_REPORT_STOCK_AGEING_GRID",
  REPORT_BALANCE_AGEING_GRID = "REPORT_BALANCE_AGEING_GRID",
  PAGINATION_REPORT_BALANCE_AGEING_GRID = "PAGINATION_REPORT_BALANCE_AGEING_GRID",
  FILTER_STATEMENT_TRANSACTION_REPORT_SALE_GRID = "FILTER_STATEMENT_TRANSACTION_REPORT_SALE_GRID",
  SORT_STATEMENT_TRANSACTION_REPORT_SALE_GRID = "SORT_STATEMENT_TRANSACTION_REPORT_SALE_GRID",
  
  FILTER_FINANCIAL_STATEMENT_TRANSACTION_REPORT_PURCHASE_GRID="FILTER_FINANCIAL_STATEMENT_TRANSACTION_REPORT_PURCHASE_GRID",
  FINANCIAL_STATEMENT_TRANSACTION_REPORT_SALE_GRID ="FINANCIAL_STATEMENT_TRANSACTION_REPORT_SALE_GRID",
  FINANCIAL_STATEMENT_TRANSACTION_REPORT_PURCHASE_GRID ="FINANCIAL_STATEMENT_TRANSACTION_REPORT_PURCHASE_GRID",
  PAGINATION_FINANCIAL_STATEMENT_TRANSACTION_REPORT_SALE_GRID="PAGINATION_FINANCIAL_STATEMENT_TRANSACTION_REPORT_SALE_GRID",
  FINANCIAL_STATEMENT_TAX_REPORT_GRID = "FINANCIAL_STATEMENT_TAX_REPORT_GRID",
  APIREQUEST_FINANCIAL_STATEMENT_TRANSACTION_REPORT_SALE_GRID="APIREQUEST_FINANCIAL_STATEMENT_TRANSACTION_REPORT_SALE_GRID",

  FINANCIAL_STATEMENT_PURCHASE_ANALYSIS_GRID="FINANCIAL_STATEMENT_PURCHASE_ANALYSIS_GRID",
  FINANCIAL_STATEMENT_SALE_ANALYSIS_GRID="FINANCIAL_STATEMENT_SALE_ANALYSIS_GRID",

  FINANCIAL_STATEMENT_INPUTTAX_REPORT_GRID = "FINANCIAL_STATEMENT_INPUTTAX_REPORT_GRID",
  FILTER_FINANCIAL_STATEMENT_INPUTTAX_REPORT_GRID = "FILTER_FINANCIAL_STATEMENT_INPUTTAX_REPORT_GRID",
  SORT_FINANCIAL_STATEMENT_INPUTTAX_REPORT_GRID = "SORT_FINANCIAL_STATEMENT_INPUTTAX_REPORT_GRID",
  PAGINATION_FINANCIAL_STATEMENT_INPUTTAX_REPORT_GRID = "PAGINATION_FINANCIAL_STATEMENT_INPUTTAX_REPORT_GRID",
  FINANCIAL_STATEMENT_INPUTTAX_REPORT_GRID_INITIAL_ROWS = "FINANCIAL_STATEMENT_INPUTTAX_REPORT_GRID_INITIAL_ROWS",
  
  FINANCIAL_STATEMENT_OUTPUTTAX_REPORT_GRID = "FINANCIAL_STATEMENT_OUTPUTTAX_REPORT_GRID",
  FILTER_FINANCIAL_STATEMENT_OUTPUTTAX_REPORT_GRID = "FILTER_FINANCIAL_STATEMENT_OUTPUTTAX_REPORT_GRID",
  SORT_FINANCIAL_STATEMENT_OUTPUTTAX_REPORT_GRID = "SORT_FINANCIAL_STATEMENT_OUTPUTTAX_REPORT_GRID",
  PAGINATION_FINANCIAL_STATEMENT_OUTPUTTAX_REPORT_GRID = "PAGINATION_FINANCIAL_STATEMENT_OUTPUTTAX_REPORT_GRID",
  FINANCIAL_STATEMENT_OUTPUTTAX_REPORT_GRID_INITIAL_ROWS = "FINANCIAL_STATEMENT_OUTPUTTAX_REPORT_GRID_INITIAL_ROWS",

  FINANCIAL_STATEMENT_ACCOUNT_GROUPS_POPUP_ITEM_GRID = "FINANCIAL_STATEMENT_ACCOUNT_GROUPS_POPUP_ITEM_GRID",
  FINANCIAL_STATEMENT_LEDGER_DETAILS_POPUP_ITEM_GRID = "FINANCIAL_STATEMENT_LEDGER_DETAILS_POPUP_ITEM_GRID",
  FILTER_FINANCIAL_STATEMENT_ACCOUNT_GROUPS_POPUP_ITEM_GRID = "FILTER_FINANCIAL_STATEMENT_ACCOUNT_GROUPS_POPUP_ITEM_GRID",
  FILTER_FINANCIAL_STATEMENT_LEDGER_DETAILS_POPUP_ITEM_GRID = "FILTER_FINANCIAL_STATEMENT_LEDGER_DETAILS_POPUP_ITEM_GRID",
  SORT_FINANCIAL_STATEMENT_ACCOUNT_GROUPS_POPUP_ITEM_GRID = "SORT_FINANCIAL_STATEMENT_ACCOUNT_GROUPS_POPUP_ITEM_GRID",
  SORT_FINANCIAL_STATEMENT_LEDGER_DETAILS_POPUP_ITEM_GRID = "SORT_FINANCIAL_STATEMENT_LEDGER_DETAILS_POPUP_ITEM_GRID",

  PAGINATION_FINANCIAL_STATEMENT_TRANSACTION_REPORT_PURCHASE_GRID="PAGINATION_FINANCIAL_STATEMENT_TRANSACTION_REPORT_PURCHASE_GRID",
  MANUFACTURER_DEPARTMENT_PROCESS_DEPARTMENTLIST_GRID = "MANUFACTURER_DEPARTMENT_PROCESS_DEPARTMENTLIST_GRID",
  MANUFACTURER_DEPARTMENT_BREAK_TIME__GRID = "MANUFACTURER_DEPARTMENT_BREAK_TIME__GRID",
  MANUFACTURER_DEPARTMENT_PROCESS_USERLIST_GRID = "MANUFACTURER_DEPARTMENT_PROCESS_USERLIST_GRID",
  MANUFACTURER_MANUFACTURING_PROCESS_GRID = "MANUFACTURER_MANUFACTURING_MATERIALISSUE_PROCESS_GRID",
  MANUFACTURER_MANUFACTURING_PROCESS_MATERIALISSUE_GRID = "MANUFACTURER_MANUFACTURING_PROCESS_MATERIALISSUE_GRID",
  MANUFACTURER_MANUFACTURING_PROCESS_MATERIALISSUE_ITEMLIST_GRID = "MANUFACTURER_MANUFACTURING_PROCESS_MATERIALISSUE_ITEMLIST_GRID",
  MANUFACTURER_MANUFACTURING_PROCESS_MATERIALISSUE_GOLD_ITEM_GRID = "MANUFACTURER_MANUFACTURING_PROCESS_MATERIALISSUE_GOLD_ITEM_GRID",
  MANUFACTURER_MANUFACTURING_PROCESS_MATERIALISSUE_SILVER_ITEM_GRID = "MANUFACTURER_MANUFACTURING_PROCESS_MATERIALISSUE_SILVER_ITEM_GRID",
  MANUFACTURER_MANUFACTURING_PROCESS_MATERIALISSUE_DIAMOND_ITEM_GRID = "MANUFACTURER_MANUFACTURING_PROCESS_MATERIALISSUE_DIAMOND_ITEM_GRID",
  MANUFACTURER_MANUFACTURING_PROCESS_MATERIALISSUE_STONE_GEMS_ITEM_GRID = "MANUFACTURER_MANUFACTURING_PROCESS_MATERIALISSUE_STONE_GEMS_ITEM_GRID",
  MANUFACTURER_MANUFACTURING_PROCESS_MATERIALISSUE_IMITATION_ITEM_GRID = "MANUFACTURER_MANUFACTURING_PROCESS_MATERIALISSUE_IMITATION_ITEM_GRID",
  MANUFACTURER_MANUFACTURING_PROCESS_MATERIALISSUE_PLATINUM_ITEM_GRID = "MANUFACTURER_MANUFACTURING_PROCESS_MATERIALISSUE_PLATINUM_ITEM_GRID",
  MANUFACTURER_MANUFACTURING_PROCESS_MATERIALISSUE_OTHER_ITEM_GRID = "MANUFACTURER_MANUFACTURING_PROCESS_MATERIALISSUE_OTHER_ITEM_GRID",
  MANUFACTURER_MANUFACTURING_PROCESS_MATERIALRECEIVED_PRODUCTLIST_GRID = "MANUFACTURER_MANUFACTURING_PROCESS_MATERIALRECEIVED_PRODUCTLIST_GRID",
  MANUFACTURER_TRANSACTION_ALLOY_MIXING_IN_GRID = "MANUFACTURER_TRANSACTION_ALLOY_MIXING_IN_GRID",
  MANUFACTURER_JOBWORKQUEUE_SUMMARY_GRID = "MANUFACTURER_JOBWORKQUEUE_SUMMARY_GRID",

  MANUFACTURER_MANUFACTURINGPROCESS_INWARD_GRID = "MANUFACTURER_MANUFACTURINGPROCESS_INWARD_GRID",
  FILTER_MANUFACTURER_MANUFACTURINGPROCESS_INWARD_GRID = "FILTER_MANUFACTURER_MANUFACTURINGPROCESS_INWARD_GRID",
  SORT_MANUFACTURER_MANUFACTURINGPROCESS_INWARD_GRID = "SORT_MANUFACTURER_MANUFACTURINGPROCESS_INWARD_GRID",
  PAGINATION_MANUFACTURER_MANUFACTURINGPROCESS_INWARD_GRID = "PAGINATION_MANUFACTURER_MANUFACTURINGPROCESS_INWARD_GRID",
  INWARDSTOCK_MANUFACTURER_MANUFACTURINGPROCESS_INWARD_GRID = "INWARDSTOCK_MANUFACTURER_MANUFACTURINGPROCESS_INWARD_GRID",
  MANUFACTURER_MANUFACTURINGPROCESS_INWARD_GRID_INITIAL_ROWS ="MANUFACTURER_MANUFACTURINGPROCESS_INWARD_GRID_INITIAL_ROWS",
  
  MANUFACTURER_MANUFACTURINGPROCESS_OUTWARD_GRID = "MANUFACTURER_MANUFACTURINGPROCESS_OUTWARD_GRID",
  FILTER_MANUFACTURER_MANUFACTURINGPROCESS_OUTWARD_GRID = "FILTER_MANUFACTURER_MANUFACTURINGPROCESS_OUTWARD_GRID",
  SORT_MANUFACTURER_MANUFACTURINGPROCESS_OUTWARD_GRID = "SORT_MANUFACTURER_MANUFACTURINGPROCESS_OUTWARD_GRID",
  PAGINATION_MANUFACTURER_MANUFACTURINGPROCESS_OUTWARD_GRID = "PAGINATION_MANUFACTURER_MANUFACTURINGPROCESS_OUTWARD_GRID",
  OUTWARDSTOCK_MANUFACTURER_MANUFACTURINGPROCESS_OUTWARD_GRID = "OUTWARDSTOCK_MANUFACTURER_MANUFACTURINGPROCESS_OUTWARD_GRID",

  MANUFACTURER_CLOSINGDETAILS_INWARD_GRID = "MANUFACTURER_CLOSINGDETAILS_INWARD_GRID",
  FILTER_MANUFACTURER_CLOSINGDETAILS_INWARD_GRID = "FILTER_MANUFACTURER_CLOSINGDETAILS_INWARD_GRID",
  SORT_MANUFACTURER_CLOSINGDETAILS_INWARD_GRID = "SORT_MANUFACTURER_CLOSINGDETAILS_INWARD_GRID",
  PAGINATION_MANUFACTURER_CLOSINGDETAILS_INWARD_GRID = "PAGINATION_MANUFACTURER_CLOSINGDETAILS_INWARD_GRID",
  INWARDSTOCK_MANUFACTURER_CLOSINGDETAILS_INWARD_GRID = "INWARDSTOCK_MANUFACTURER_CLOSINGDETAILS_INWARD_GRID",
  MANUFACTURER_CLOSINGDETAILS_INWARD_GRID_INITIAL_ROWS ="MANUFACTURER_CLOSINGDETAILS_INWARD_GRID_INITIAL_ROWS",

  MANUFACTURER_CLOSINGDETAILS_OUTWARD_GRID = "MANUFACTURER_CLOSINGDETAILS_OUTWARD_GRID",
  FILTER_MANUFACTURER_CLOSINGDETAILS_OUTWARD_GRID = "FILTER_MANUFACTURER_CLOSINGDETAILS_OUTWARD_GRID",
  SORT_MANUFACTURER_CLOSINGDETAILS_OUTWARD_GRID = "SORT_MANUFACTURER_CLOSINGDETAILS_OUTWARD_GRID",
  PAGINATION_MANUFACTURER_CLOSINGDETAILS_OUTWARD_GRID = "PAGINATION_MANUFACTURER_CLOSINGDETAILS_OUTWARD_GRID",
  OUTWARDSTOCK_MANUFACTURER_CLOSINGDETAILS_OUTWARD_GRID = "OUTWARDSTOCK_MANUFACTURER_CLOSINGDETAILS_OUTWARD_GRID",
  MANUFACTURER_CLOSINGDETAILS_OUTWARD_GRID_INITIAL_ROWS ="MANUFACTURER_CLOSINGDETAILS_OUTWARD_GRID_INITIAL_ROWS",

  MANUFACTURER_MANUFACTURINGPROCESS_INWARD_OUTWARD_GRID = "MANUFACTURER_MANUFACTURINGPROCESS_INWARD_OUTWARD_GRID",
  FILTER_MANUFACTURINGPROCESS_INWARD_OUTWARD_GRID = "FILTER_MANUFACTURINGPROCESS_INWARD_OUTWARD_GRID",
  SORT_MANUFACTURINGPROCESS_INWARD_OUTWARD_GRID = "SORT_MANUFACTURINGPROCESS_INWARD_OUTWARD_GRID",
  PAGINATION_MANUFACTURINGPROCESS_INWARD_OUTWARD_GRID = "PAGINATION_MANUFACTURINGPROCESS_INWARD_OUTWARD_GRID",
  INWARD_OUTWARD_STOCK_MANUFACTURINGPROCESS_INWARD_OUTWARD_GRID = "INWARD_OUTWARD_STOCK_MANUFACTURINGPROCESS_INWARD_OUTWARD_GRID",

  MANUFACTURE_JOBWORK_QUEUE_REPORT_GRID = "MANUFACTURE_JOBWORK_QUEUE_REPORT_GRID",
  FILTER_MANUFACTURE_JOBWORK_QUEUE_REPORT_GRID = "FILTER_MANUFACTURE_JOBWORK_QUEUE_REPORT_GRID",
  SORT_MANUFACTURE_JOBWORK_QUEUE_REPORT_GRID = "SORT_MANUFACTURE_JOBWORK_QUEUE_REPORT_GRID",
  PAGINATION_MANUFACTURE_JOBWORK_QUEUE_REPORT_GRID = "PAGINATION_MANUFACTURE_JOBWORK_QUEUE_REPORT_GRID",

  MANUFACTURE_WORKLOG_REPORT_GRID = "MANUFACTURE_WORKLOG_REPORT_GRID",
  FILTER_MANUFACTURE_WORKLOG_REPORT_GRID = "FILTER_MANUFACTURE_WORKLOG_REPORT_GRID",
  SORT_MANUFACTURE_WORKLOG_REPORT_GRID = "SORT_MANUFACTURE_WORKLOG_REPORT_GRID",
  PAGINATION_MANUFACTURE_WORKLOG_REPORT_GRID = "PAGINATION_MANUFACTURE_WORKLOG_REPORT_GRID",

  MANUFACTURER_JOBWORK_ORDER_PRODUCT_LIST_GRID = "MANUFACTURER_JOBWORK_ORDER_PRODUCT_LIST_GRID",
  MANUFACTURER_JOBWORK_ORDER_PAYMENT_LIST_GRID = "MANUFACTURER_JOBWORK_ORDER_PAYMENT_LIST_GRID",
  MANUFACTURER_JOBWORK_QUEUE_PAYMENT_LIST_GRID ="MANUFACTURER_JOBWORK_QUEUE_PAYMENT_LIST_GRID",
  TRANSACTION_EXPENSES_PAYMENT_LIST_GRID="TRANSACTION_EXPENSES_PAYMENT_LIST_GRID",
  MANUFACTURER_LOSS_TRACKING_GRID ="MANUFACTURER_LOSS_TRACKING_GRID",
  PAGINATION_MANUFACTURER_LOSS_TRACKING_GRID ="PAGINATION_MANUFACTURER_LOSS_TRACKING_GRID",
  SETTINGS_SET_SOFTWARE_ACCOUNTING_GRID = "SETTING_SET_SOFTWARE_ACCOUNTING_GRID",
  SETTINGS_SET_SOFTWARE_RDLCREPORT_UPLOAD_GRID = "SETTING_SET_SOFTWARE_RDLCREPORT_UPLOAD_GRID",
  SETTINGS_SET_SOFTWARE_DEVICES_SERIESLIST_GRID = "SETTING_SET_SOFTWARE_DEVICES_SERIESLIST_GRID",
  SETTINGS_SET_SOFTWARE_BILL_SERIES_SERIESLIST_GRID = "SETTING_SET_SOFTWARE_BILL_SERIES_SERIESLIST_GRID",
  SETTINGS_SET_SOFTWARE_SYSTEM_FIELDS_GRID = "SETTINGS_SET_SOFTWARE_SYSTEM_FIELDS_GRID",
  SETTINGS_WHATSAPP_CAMPAIGN_GRID = "SETTINGS_WHATSAPP_CAMPAIGN_GRID",
  SETTINGS_EMAIL_CAMPAIGN_GRID = "SETTINGS_EMAIL_CAMPAIGN_GRID",
  SETTINGS_VOUCHERTYPE_TAX_GRID = "SETTINGS_VOUCHERTYPE_TAX_GRID",
  SETTINGS_VOUCHERTYPE_METAL_GRID = "SETTINGS_VOUCHERTYPE_METAL_GRID",
  SETTINGS_VOUCHERTYPE_VOUCHERTYPE_GRID = "SETTINGS_VOUCHERTYPE_VOUCHERTYPE_GRID",
  SETTINGS_DISABLE_NOTIFICATIONS_CONFIGURE_GRID ="SETTINGS_DISABLE_NOTIFICATIONS_CONFIGURE_GRID",
  SETTINGS_DISABLE_GRID_CONFIGURATION_GRID ="SETTINGS_DISABLE_GRID_CONFIGURATION_GRID",
  SQLEXECUTION_GRID="SQLEXECUTION_GRID",
  SORT_SQLEXECUTION_GRID="SORT_SQLEXECUTION_GRID",
  FILTER_SQLEXECUTION_GRID="FILTER_SQLEXECUTION_GRID",
  SQLEXECUTION_GRID_INITIAL_ROWS ="SQLEXECUTION_GRID_INITIAL_ROWS",
  PAGINATION_DISABLE_GRID_CONFIGURATION_GRID ="PAGINATION_DISABLE_GRID_CONFIGURATION_GRID",
  ADMINISTRATION_USER_MANAGEMENT_USER_GRID = "ADMINISTRATION_USER_MANAGEMENT_USER_GRID",
  ADMINISTRATION_USER_MANAGEMENT_ROLES_GRID = "ADMINISTRATION_USER_MANAGEMENT_ROLES_GRID",
  SORT_ADMINISTRATION_USER_MANAGEMENT_ROLES_GRID ="SORT_ADMINISTRATION_USER_MANAGEMENT_ROLES_GRID",
  ADMINISTRATION_USER_MANAGEMENT_PERMISSIONS_GRID = "ADMINISTRATION_USER_MANAGEMENT_PERMISSIONS_GRID",
  ADMINISTRATION_MASTERS_BRANCHES_GRID = "ADMINISTRATION_MASTERS_BRANCHES_GRID",
  ADMINISTRATION_MASTERS_BUCKET_GRID = "ADMINISTRATION_MASTERS_BUCKET_GRID",
  ADMINISTRATION_MASTERS_TAXSETTING_GRID = "ADMINISTRATION_MASTERS_TAXSETTING_GRID",
  ADMINISTRATION_MASTERS_STYLES_GRID = "ADMINISTRATION_MASTERS_STYLES_GRID",
  ADMINISTRATION_MASTERS_CREDITCARD_GRID = "ADMINISTRATION_MASTERS_CREDITCARD_GRID",
  ADMINISTRATION_MASTERS_FIELDTYPE_GRID = "ADMINISTRATION_MASTERS_FIELDTYPE_GRID",
  ADMINISTRATION_MASTERS_USER_DEFINED_FIELDS_GRID = "ADMINISTRATION_MASTERS_USER_DEFINED_FIELDS_GRID",
  ADMINISTRATION_ACTIVITY_LOG_GRID = "ADMINISTRATION_ACTIVITY_LOG_GRID",
  ADMINISTRATION_TASK_EVENT_GRID = "ADMINISTRATION_TASK_EVENT_GRID",

  ORDERS_PURCHASE_ORDER = "ORDERS_PURCHASE_ORDER",
  SELECT_PRINT_FORMAT_POPUP_GRID = "SELECT_PRINT_FORMAT_POPUP_GRID",
  
  //#endregion GRIDNAME
  //#region DROPDOWN
  TYPE_DROPDOWN = "TYPE_DROPDOWN",
  JOBWORK_PRIORITY_DROPDOWN = "JOBWORK_PRIORITY_DROPDOWN",
  JOBWORK_STATUS_DROPDOWN = "JOBWORK_STATUS_DROPDOWN",
  PURCHASEINVOICENO_DROPDOWN = "PURCHASEINVOICENO_DROPDOWN",
  ACCOUNTLEDGER_DROPDOWN = "ACCOUNTLEDGER_DROPDOWN",
  PURPOSE_ACCOUNTLEDGER_DROPDOWN = "PURPOSE_ACCOUNTLEDGER_DROPDOWN",
  APPLICATIONON_DROPDOWN = "APPLICATIONON_DROPDOWN",
  BRANCH_DROPDOWN = "BRANCH_DROPDOWN",
  CAMPAIGN_GROUP_DROPDOWN = "CAMPAIGN_GROUP_DROPDOWN",
  BUCKET_DROPDOWN = "BUCKET_DROPDOWN",
  CALCULATION_DROPDOWN = "CALCULATION_DROPDOWN",
  CALCULATION_MODETYPE_DROPDOWN="CALCULATION_MODETYPE_DROPDOWN",
  TAX_GROUP_DROPDOWN="TAX_GROUP_DROPDOWN",
  CATEGORY_DROPDOWN = "CATEGORY_DROPDOWN",
  COLLECTION_DROPDOWN = "COLLECTION_DROPDOWN",
  COMPANY_DROPDOWN = "COMPANY_DROPDOWN",
  CUSTOMER_DROPDOWN = "CUSTOMER_DROPDOWN",
  CUSTOMER_NAME_DROPDOWN = "CUSTOMER_NAME_DROPDOWN",
  BREAK_TYPE_DROPDOWN = "BREAK_TYPE_DROPDOWN",
  DISTRICT_DROPDOWN = "DISTRICT_DROPDOWN",
  METAL_DROPDOWN = "METAL_DROPDOWN",
  MAKINGCHARGESTYPE_DROPDOWN = "MAKINGCHARGESTYPE_DROPDOWN",
  PAYMENTTYPE_DROPDOWN = "PAYMENTTYPE_DROPDOWN",
  PURITYGROUP_DROPDOWN = "PURITYGROUP_DROPDOWN",
  REGISTRATIONTYPE_DROPDOWN = "REGISTRATIONTYPE_DROPDOWN",
  REMARK_DROPDOWN = "REMARK_DROPDOWN",
  ROLE_DROPDOWN = "ROLE_DROPDOWN",
  SALEMODE_DROPDOWN = "SALEMODE_DROPDOWN",
  SALEPERSON_DROPDOWN = "SALEPERSON_DROPDOWN",
  SCHEME_NAME_DROPDOWN = "SCHEME_NAME_DROPDOWN",
  ACCOUNTLEDGER_DROPDOWNS="ACCOUNTLEDGER_DROPDOWNS",
  STATE_DROPDOWN = "STATE_DROPDOWN",
  TALUKA_DROPDOWN = "TALUKA_DROPDOWN",
  SUBCATEGORY_DROPDOWN = "SUBCATEGORY_DROPDOWN",
  UNIT_DROPDOWN = "UNIT_DROPDOWN",
  USER_DROPDOWN = "USER_DROPDOWN",
  USER_STATUS_DROPDOWN = "USER_STATUS_DROPDOWN",
  VOUCHER_TYPE_DROPDOWN = "VOUCHER_TYPE_DROPDOWN",
  METHOD_OF_VOUCHERING_NUMBER_DROPDOWN = "METHOD_OF_VOUCHERING_NUMBER_DROPDOWN",
  DEPARTMENT_DROPDOWN = "DEPARTMENT_DROPDOWN",
  PRODUCT_DROPDOWN = "PRODUCT_DROPDOWN",
  DOCUMENT_TYPE_DROPDOWN = "DOCUMENT_TYPE_DROPDOWN",
  FINANCIAL_DROPDOWN = "FINANCIAL_DROPDOWN",
  ORDER_NO_DROPDOWN = "ORDER_NO_DROPDOWN",
  JOBWORKORDER_NO_DROPDOWN = "JOBWORKORDER_NO_DROPDOWN",
  SALEORDER_NO_DROPDOWN ="SALEORDER_NO_DROPDOWN",
  ROTATION_DROPDOWN = "ROTATION_DROPDOWN",
  IMPLEMENTATIONON_DROPDOWN = "IMPLEMENTATIONON_DROPDOWN",
  BAUD_DROPDOWN = "BAUD_DROPDOWN",
  DATABITS_DROPDOWN = "DATABITS_DROPDOWN",
  PARITY_DROPDOWN = "PARITY_DROPDOWN",
  STOPBITS_DROPDOWN = "STOPBITS_DROPDOWN",
  PORT_DROPDOWN = "PORT_DROPDOWN",
  AGAINST_DROPDOWN = "AGAINST_DROPDOWN",
  AGAINST_JWQ_DROPDOWN = "AGAINST_JWQ_DROPDOWN",
  INWORD_OUTWORD_DROPDOWN = "INWORD_OUTWORD_DROPDOWN",
  REDEEM_ON_DROPDOWN = "REDEEM_ON_DROPDOWN",
  BARCODE_TOOLBOXKEYDROPDOWN = "BARCODE_TOOLBOXKEYDROPDOWN",
  TEMPLATE_TOOLBOXKEYDROPDOWN = "TEMPLATE_TOOLBOXKEYDROPDOWN",
  FONTSIZE_DROPDOWN = "FONTSIZE_DROPDOWN",
  FONTFAMILY_DROPDOWN = "FONTFAMILY_DROPDOWN",
  CURRENCY_EXCHANGE = "CURRENCY_EXCHANGE",
  SHAPE_DROPDOWN = "SHAPE_DROPDOWN",
  GRIDNAME_DROPDOWN = "GRIDNAME_DROPDOWN",
  NOTIFICATION_SERVICETYPE_DROPDOWN ="NOTIFICATION_SERVICETYPE_DROPDOWN",
  CLOSING_TIME_DROPDOWN ="CLOSING_TIME_DROPDOWN",
  // QUALITY_DROPDOWN               ="QUALITY_DROPDOWN",
  CUT_DROPDOWN = "CUT_DROPDOWN",
  UDF_PROPERTY_DROPDOWN = "UDF_PROPERTY_DROPDOWN",
  DEPARTMENT_PROCESS_DROPDOWN ="DEPARTMENT_PROCESS_DROPDOWN",
  DEPARTMENT_TYPE_DROPDOWN ="DEPARTMENT_TYPE_DROPDOWN",
  LABEL_POSITION_DROPDOWN ="LABEL_POSITION_DROPDOWN",
  UDF1_DROPDOWN = "UDF1_DROPDOWN",
  UDF2_DROPDOWN = "UDF2_DROPDOWN",
  UDF3_DROPDOWN = "UDF3_DROPDOWN",
  UDF4_DROPDOWN = "UDF4_DROPDOWN",
  UDF5_DROPDOWN = "UDF5_DROPDOWN",
  UDF11_DROPDOWN ="UDF11_DROPDOWN",
  UDF12_DROPDOWN ="UDF12_DROPDOWN",
  UDF13_DROPDOWN="UDF13_DROPDOWN",
  UDF14_DROPDOWN="UDF14_DROPDOWN",
  UDF15_DROPDOWN="UDF15_DROPDOWN",
  UDF21_DROPDOWN ="UDF21_DROPDOWN",
  UDF22_DROPDOWN="UDF22_DROPDOWN",
  UDF23_DROPDOWN="UDF23_DROPDOWN",
  UDF24_DROPDOWN="UDF24_DROPDOWN",
  UDF25_DROPDOWN="UDF25_DROPDOWN",
  UDF26_DROPDOWN="UDF26_DROPDOWN",
  UDF27_DROPDOWN="UDF27_DROPDOWN",
  UDF28_DROPDOWN="UDF28_DROPDOWN",
  UDF29_DROPDOWN="UDF29_DROPDOWN",
  UDF30_DROPDOWN="UDF30_DROPDOWN",
  COLOR_DROPDOWN = "COLOR_DROPDOWN",
  SEIVE_SIZE_DROPDOWN = "SEIVE_SIZE_DROPDOWN",
  SIZE_DROPDOWN = "SIZE_DROPDOWN",
  TAX_DROPDOWN = "TAX_DROPDOWN",
  UNIT_CONVERSION_DROPDOWN = "UNIT_CONVERSION_DROPDOWN",
  FONT_SIZE_DROPDOWN = "FONT_SIZE_DROPDOWN",
  METAL_TYPE_DROPDOWN = "METAL_TYPE_DROPDOWN",
  REGISTRATION_TYPE_DROPDOWN = "REGISTRATION_TYPE_DROPDOWN",
  APPLICABLE_ON_DROPDOWN = "APPLICABLE_ON_DROPDOWN",
  CITY_DROPDOWN = "CITY_DROPDOWN",
  IMPLEMENTATION_ON_DROPDOWN = "IMPLEMENTATION_ON_DROPDOWN",
  DATA_BITS_DROPDOWN = "DATA_BITS_DROPDOWN",
  STOP_BITS_DROPDOWN = "STOP_BITS_DROPDOWN",
  FINANCIAL_YEAR_DROPDOWN = "FINANCIAL_YEAR_DROPDOWN",
  MAKING_CHARGE_TYPE_DROPDOWN = "MAKING_CHARGES_TYPE_DROPDOWN",
  SALES_MODE_DROPDOWN = "SALES_MODE_DROPDOWN",
  LEDGER_NAME_DROPDOWN = "LEDGER_NAME_DROPDOWN",
  TRANSACTION_TYPE_DROPDOWN = "TRANSACTION_TYPE_DROPDOWN",
  INVOICE_NO_DROPDOWN = "INVOICE_NO_DROPDOWN",
  CURRENCY_DROPDOWN = "CURRENCY_DROPDOWN",
  CURRENCY_EXCHANGE_DROPDOWN = "CURRENCY_EXCHANGE_DROPDOWN",
  CARAT_DROPDOWN = "CARAT_DROPDOWN",
  ACCOUNTGROUP_DROPDOWN = "ACCOUNTGROUP_DROPDOWN",
  DEPOSIT_INTO_DROPDOWN = "DEPOSIT_INTO_DROPDOWN",
  CALCULATION_TYPE_DROPDOWN = "CALCULATION_TYPE_DROPDOWN",
  DIAMOND_CATEGORY_DROPDOWN = "DIAMOND_CATEGORY_DROPDOWN",
  PDC_STATUS_DROPDOWN = "PDCSTATUSDROPDOWN",
  PDC_VOUCHER_DROPDOWN = "PDCVOUCHERDROPDOWN",
  BANK_ACCOUNT_DROPDOWN = "BANKACCOUNTDROPDOWN",
  REPORT_INVOICE_DROPDOWN = "REPORT_INVOICE_DROPDOWN",
  MAKING_ON_DROPDOWN = "MAKING_ON_DROPDOWN",
  INVOICE_PRINTER_DROPDOWN = "INVOICE_PRINTER_DROPDOWN",
  BARCODE_PRINTER_DROPDOWN = "BARCODE_PRINTER_DROPDOWN",
  LABEL_SIZE_DROPDOWN = "LABEL_SIZE_DROPDOWN",
  TEMPLATE_TOOLBOX_DROPDOWN = "TEMPLATE_TOOLBOX_DROPDOWN",
  VOUCHERTYPE_DROPDOWN = "VOUCHERTYPE_DROPDOWN",
  NO_OF_COPY_DROPDOWN = "NO_OF_COPY_DROPDOWN",
  SALEORDERPROCESS_ORDERNO_DROPDOWN="SALEORDERPROCESS_ORDERNO_DROPDOWN",
  SALEORDERPROCESS_BARCODENO_DROPDOWN="SALEORDERPROCESS_BARCODENO_DROPDOWN",
  PRODUCT_CART_DROPDOWN = "PRODUCT_CART_DROPDOWN",
  ALL_ACCOUNTLEDGER_DROPDOWN = "ALL_ACCOUNTLEDGER_DROPDOWN",
  PAGENAME_DROPDOWN = "PAGENAME_DROPDOWN",
  STATUS_DROPDOWN = "STATUS_DROPDOWN",
  CAMPAIGN_STATUS_DROPDOWN = "CAMPAIGN_STATUS_DROPDOWN",
  EMAILCAMPAIGN_TEMPLATE_DROPDOWN = "EMAILCAMPAIGN_TEMPLATE_DROPDOWN",
  DURATIONTYPE_DROPDOWN ="DURATIONTYPEDROPDOWN",
  EVENTTYPE_DROPDOWN = "EVENTTYPE_DROPDOWN",
  MASTERS_DROPDOWN = "MASTERS_DROPDOWN",
  STYLE_DROPDOWN = "STYLE_DROPDOWN",
  ITEM_CODE_DROPDOWN = "ITEM_CODE_DROPDOWN",
  CLARITY_DROPDOWN = "CLARITY_DROPDOWN",
  FIELDTYPES_DROPDOWN = "FIELDTYPES_DROPDOWN",
  USERDEFINEFIELD_DROPDOWN = "USERDEFINEFIELD_DROPDOWN",
  WEBSITE_URL_DROPDOWN = "WEBSITE_URL_DROPDOWN",
  STATUSID_ENUM_DROPDOWN = "STATUSID_ENUM_DROPDOWN",
  SQL_TYPE_DROPDOWN ="SQL_TYPE_DROPDOWN",
  LEDGERTYPE_DROPDOWN = "LEDGERTYPE_DROPDOWN",
  CAMPAIGNGROUP_DROPDOWN = "CAMPAIGNGROUP_DROPDOWN",
  CREDITCARD_DROPDOWN ="CREDITCARD_DROPDOWN",
  ASSIGNTO_DROPDOWN ="ASSIGNTO_DROPDOWN",
  PRIORITY_DROPDOWN ="PRIORITY_DROPDOWN",
  COUNTRY_DROPDOWN ="COUNTRY_DROPDOWN",
  DIALCODE_DROPDOWN ="DIALCODE_DROPDOWN",
  MIXED_METAL_DROPDOWN="MIXED_METAL_DROPDOWN",
  ACTIVITYTYPE_DROPDOWN ="ACTIVITYTYPE_DROPDOWN",
  METAL_VALUATION_DROPDOWN="METAL_VALUATION_DROPDOWN",
  EXPENSES_CATEGORY_DROPDOWN="EXPENSES_CATEGORY_DROPDOWN",
  CLOSINGDATE_DROPDOWN="CLOSINGDATE_DROPDOWN",
  LOAN_REASON_DROPDOWN ="LOAN_REASON_DROPDOWN",
  LOAN_PRODUCT_TYPE_DROPDOWN ="LOAN_PRODUCT_TYPE_DROPDOWN",
  INTEREST_TYPE_DROPDOWN ="INTEREST_TYPE_DROPDOWN",
  INTEREST_METHOD_DROPDOWN ="INTEREST_METHOD_DROPDOWN",
  LOCATION_DROPDOWN="LOCATION_DROPDOWN",
  EXPENSES_LEDGER_CATEGORY_DROPDOWN="EXPENSES_LEDGER_CATEGORY_DROPDOWN",
  SALEQUOTATION_NODROPDOWN="SALEQUOTATION_NODROPDOWN",
  DELIVERYNOTE_NODROPDOWN="DELIVERYNOTE_NODROPDOWN",

  //#endregion DROPDOWN
  //  ****Filter and sort*****
  FILTER_AVAILABLE_STOCK_PRODUCTNAME = "FILTER_AVAILABLE_STOCK_PRODUCTNAME",
  SORT_AVAILABLE_STOCK_PRODUCTNAME = "SORT_AVAILABLE_STOCK_PRODUCTNAME",
  FILTER_AVAILABLE_STOCK_RFID = "FILTER_AVAILABLE_STOCK_RFID",
  SORT_AVAILABLE_STOCK_RFID = "SORT_AVAILABLE_STOCK_RFID",
  FILTER_AVAILABLE_STOCK_VOUCHERTYPEDROPDOWN = "FILTER_AVAILABLE_STOCK_VOUCHERTYPEDROPDOWN",
  SORT_AVAILABLE_STOCK_VOUCHERTYPEDROPDOWN = "SORT_AVAILABLE_STOCK_VOUCHERTYPEDROPDOWN",
  FILTER_AVAILABLE_STOCK_BARCODE = "FILTER_AVAILABLE_STOCK_BARCODE",
  SORT_AVAILABLE_STOCK_BARCODE = "SORT_AVAILABLE_STOCK_BARCODE",
  FILTER_AVAILABLE_STOCK_QUANTITY = "FILTER_AVAILABLE_STOCK_QUANTITY",
  SORT_AVAILABLE_STOCK_QUANTITY = "SORT_AVAILABLE_STOCK_QUANTITY",
  FILTER_AVAILABLE_STOCK_NETWT = "FILTER_AVAILABLE_STOCK_NETWT",
  SORT_AVAILABLE_STOCK_NETWT = "SORT_AVAILABLE_STOCK_NETWT",
  FILTER_AVAILABLE_STOCK_WT = "FILTER_AVAILABLE_STOCK_WT",
  SORT_AVAILABLE_STOCK_WT = "SORT_AVAILABLE_STOCK_WT",

  FILTER_ACCOUNT_LEDGER_REPORT_AMOUNT_GRID = "FILTER_ACCOUNT_LEDGER_REPORT_AMOUNT_GRID",
  FILTER_ACCOUNTLEDGERREPORT_VIEWALLLEDGERAMT_GRID = "FILTER_ACCOUNTLEDGERREPORT_VIEWALLLEDGERAMT_GRID",
  FILTER_ACCOUNT_LEDGER_REPORT_METAL_GRID = "FILTER_ACCOUNT_LEDGER_REPORT_METAL_GRID",
  SORT_ACCOUNT_LEDGER_REPORT_AMOUNT_GRID = "SORT_ACCOUNT_LEDGER_REPORT_AMOUNT_GRID",
  SORT_ACCOUNTLEDGERREPORT_VIEWALLLEDGERAMT_GRID = "SORT_ACCOUNTLEDGERREPORT_VIEWALLLEDGERAMT_GRID",
  SORT_ACCOUNT_LEDGER_REPORT_METAL_GRID = "SORT_ACCOUNT_LEDGER_REPORT_METAL_GRID",

  FILTER_SCHEDULAR_TASKS_GRID = "FILTER_SCHEDULAR_TASKS_GRID",
  SORT_SCHEDULAR_TASKS_GRID = "SORT_SCHEDULAR_TASKS_GRID",

  //  FILTER_DAY_REPORT_VOUCHERTYPE ="FILTER_DAY_REPORT_VOUCHERTYPE",
  //  SORT_DAY_REPORT_VOUCHERTYPE ="SORT_DAY_REPORT_VOUCHERTYPE",
  //  FILTER_DAY_REPORT_INVOICENO ="FILTER_DAY_REPORT_INVOICENO",
  //  SORT_DAY_REPORT_INVOICENO="SORT_DAY_REPORT_INVOICENO",
  //  FILTER_DAY_REPORT_DEBIT ='FILTER_DAY_REPORT_DEBIT',
  //  SORT_DAY_REPORT_DEBIT ="SORT_DAY_REPORT_DEBIT",
  //  FILTER_DAY_REPORT_CREDIT="FILTER_DAY_REPORT_CREDIT",
  //  SORT_DAY_REPORT_CREDIT ="SORT_DAY_REPORT_CREDIT",
  //  FILTER_DAY_REPORT_INWARDWT="FILTER_DAY_REPORT_INWARDWT",
  //  SORT_DAY_REPORT_INWARDWT="SORT_DAY_REPORT_INWARDWT",
  //  FILTER_DAY_REPORT_OUTWARDWT="FILTER_DAY_REPORT_OUTWARDWT",
  //  SORT_DAY_REPORT_OUTWARDWT="SORT_DAY_REPORT_OUTWARDWT",
  //  FILTER_CURRENT_STOCK_PRODUCTNAME="FILTER_CURRENT_STOCK_PRODUCTNAME",
  //  SORT_CURRENT_STOCK_PRODUCTNAME="SORT_CURRENT_STOCK_PRODUCTNAME",
  //  FILTER_CURRENT_STOCK_METALNAME="FILTER_CURRENT_STOCK_METALNAME",
  //  SORT_CURRENT_STOCK_METALNAME="SORT_CURRENT_STOCK_METALNAME",
  //  FILTER_CURRENT_STOCK_QUANTITY="FILTER_CURRENT_STOCK_QUANTITY",
  //  SORT_CURRENT_STOCK_QUANTITY="SORT_CURRENT_STOCK_QUANTITY",
  //  FILTER_CURRENT_STOCK_WT="FILTER_CURRENT_STOCK_WT",
  //  SORT_CURRENT_STOCK_WT="SORT_CURRENT_STOCK_WT",
  //  FILTER_CURRENT_STOCK_NETWT="FILTER_CURRENT_STOCK_NETWT",
  //  SORT_CURRENT_STOCK_NETWTT="SORT_CURRENT_STOCK_NETWTT",
  //  FILTER_CURRENT_STOCK_STOCKVALUE="FILTER_CURRENT_STOCK_STOCKVALUE",
  //  SORT_CURRENT_STOCK_STOCKVALUE="SORT_CURRENT_STOCK_STOCKVALUE",
  //  FILTER_STOCK_HISTORY_PRODUCTNAME="FILTER_STOCK_HISTORY_PRODUCTNAME",
  //  SORT_STOCK_HISTORY_PRODUCTNAME='SORT_STOCK_HISTORY_PRODUCTNAME',
  //  FILTER_STOCK_HISTORY_METALNAME="FILTER_STOCK_HISTORY_METALNAME",
  //  SORT_STOCK_HISTORY_METALNAME="SORT_STOCK_HISTORY_METALNAME",
  //  FILTER_STOCK_HISTORY_VOUCHERTYPE="FILTER_STOCK_HISTORY_VOUCHERTYPE",
  //  SORT_STOCK_HISTORY_VOUCHERTYPE="SORT_STOCK_HISTORY_VOUCHERTYPE",
  //  FILTER_STOCK_HISTORY_INVOICENO="FILTER_STOCK_HISTORY_INVOICENO",
  //  SORT_STOCK_HISTORY_INVOICENO="SORT_STOCK_HISTORY_INVOICENO",
  //  FILTER_STOCK_HISTORY_BRANCH="FILTER_STOCK_HISTORY_BRANCH",
  //  SORT_STOCK_HISTORY_BRANCH="SORT_STOCK_HISTORY_BRANCH",
  //  FILTER_STOCK_HISTORY_INWARDWT="FILTER_STOCK_HISTORY_INWARDWT",
  //  SORT_STOCK_HISTORY_INWARDWT="SORT_STOCK_HISTORY_INWARDWT",
  //  FILTER_STOCK_HISTORY_OUTWARDWT="FILTER_STOCK_HISTORY_OUTWARDWT",
  //  SORT_STOCK_HISTORY_OUTWARDWT="SORT_STOCK_HISTORY_OUTWARDWT",
  //  FILTER_STOCK_DETAILS_PRODUCTNAME="FILTER_STOCK_DETAILS_PRODUCTNAME",
  //  SORT_STOCK_DETAILS_PRODUCTNAME="SORT_STOCK_DETAILS_PRODUCTNAME",
  //  FILTER_STOCK_DETAILS_METALNAME="FILTER_STOCK_DETAILS_METALNAME",
  //  SORT_STOCK_DETAILS_METALNAME='SORT_STOCK_DETAILS_METALNAME',
  //  FILTER_STOCK_DETAILS_OPENINGWT="FILTER_STOCK_DETAILS_OPENINGWT",
  //  SORT_STOCK_DETAILS_OPENINGWT="SORT_STOCK_DETAILS_OPENINGWT",
  //  FILTER_STOCK_DETAILS_WTIN="FILTER_STOCK_DETAILS_WTIN",
  //  SORT_STOCK_DETAILS_WTIN="SORT_STOCK_DETAILS_WTIN",
  //  FILTER_STOCK_DETAILS_WTOUT="FILTER_STOCK_DETAILS_WTOUT",
  //  SORT_STOCK_DETAILS_WTOUT="SORT_STOCK_DETAILS_WTOUT",
  //  FILTER_STOCK_DETAILS_CLOSINGWT="FILTER_STOCK_DETAILS_CLOSINGWT",
  //  SORT_STOCK_DETAILS_CLOSINGWT="SORT_STOCK_DETAILS_CLOSINGWT",
  //  FILTER_SCANNEDSTOCK_PRODUCTNAME="FILTER_SCANNEDSTOCK_PRODUCTNAME",
  //  SORT_SCANNEDSTOCK_PRODUCTNAME="SORT_SCANNEDSTOCK_PRODUCTNAME",
  //  FILTER_SCANNEDSTOCK_RFID="FILTER_SCANNEDSTOCK_RFID",
  //  SORT_SCANNEDSTOCK_RFID="SORT_SCANNEDSTOCK_RFID",
  //  FILTER_SCANNEDSTOCK_BARCODE="FILTER_SCANNEDSTOCK_BARCODE",
  //  SORT_SCANNEDSTOCK_BARCODE="SORT_SCANNEDSTOCK_BARCODE",
  //  FILTER_SCANNEDSTOCK_QUANTITY="FILTER_SCANNEDSTOCK_QUANTITY",
  //  SORT_SCANNEDSTOCK_QUANTITY="SORT_SCANNEDSTOCK_QUANTITY",
  //  FILTER_SCANNEDSTOCK_WT="FILTER_SCANNEDSTOCK_WT",
  //  SORT_SCANNEDSTOCK_WT="SORT_SCANNEDSTOCK_WT",
  //  FILTER_SCANNEDSTOCK_NETWT="FILTER_SCANNEDSTOCK_NETWT",
  //  SORT_SCANNEDSTOCK_NETWT="SORT_SCANNEDSTOCK_NETWT",
  //  FILTER_SCANNEDSTOCK_VOUCHERTYPEDROPDOWN='FILTER_SCANNEDSTOCK_VOUCHERTYPEDROPDOWN',
  //  SORT_SCANNEDSTOCK_VOUCHERTYPEDROPDOWN="SORT_SCANNEDSTOCK_VOUCHERTYPEDROPDOWN",
  //  FILTER_USER_FULLNAME="FILTER_USER_FULLNAME",
  //  SORT_USER_FULLNAME="SORT_USER_FULLNAME",
  //  FILTER_USER_BRANCHIDS="FILTER_USER_BRANCHIDS",
  //  SORT_USER_BRANCHIDS="SORT_USER_BRANCHIDS",
  //  FILTER_USER_EMAILID="FILTER_USER_EMAILID",
  //  SORT_USER_EMAILID="SORT_USER_EMAILID",
  //  FILTER_USER_CONTACTNO="FILTER_USER_CONTACTNO",
  //  SORT_USER_CONTACTNO="SORT_USER_CONTACTNO",
  //  FILTER_USER_ROLENAME="FILTER_USER_ROLENAME",
  //  SORT_USER_ROLENAME="SORT_USER_ROLENAME",
  FILTER_PAYMENTLIST_RECIEPT_PAYMENT_VOUCHER_LEDGERNAME = "FILTER_PAYMENTLIST_RECIEPT_PAYMENT_VOUCHER_LEDGERNAME",
  SORT_PAYMENTLIST_RECIEPT_PAYMENT_VOUCHER_LEDGERNAME = "SORT_PAYMENTLIST_RECIEPT_PAYMENT_VOUCHER_LEDGERNAME",
  FILTER_PAYMENTLIST_RECIEPT_PAYMENT_VOUCHER_DATE = "FILTER_PAYMENTLIST_RECIEPT_PAYMENT_VOUCHER_DATE",
  SORT_PAYMENTLIST_RECIEPT_PAYMENT_VOUCHER_DATE = "SORT_PAYMENTLIST_RECIEPT_PAYMENT_VOUCHER_DATE",
  FILTER_PAYMENTLIST_RECIEPT_PAYMENT_VOUCHER_VOUCHERNO = 'FILTER_PAYMENTLIST_RECIEPT_PAYMENT_VOUCHER_VOUCHERNO',
  SORT_PAYMENTLIST_RECIEPT_PAYMENT_VOUCHER_VOUCHERNO = "SORT_PAYMENTLIST_RECIEPT_PAYMENT_VOUCHER_VOUCHERNO",


  // FILTER_PDC_ENTRY_VOUCHERNO = "FILTER_PDC_ENTRY_VOUCHERNO",
  // SORT_PDC_ENTRY_VOUCHERNO = "SORT_PDC_ENTRY_VOUCHERNO",
  // FILTER_PDC_ENTRY_BANKNAME = "FILTER_PDC_ENTRY_BANKNAME",
  // SORT_PDC_ENTRY_BANKNAME = "SORT_PDC_ENTRY_BANKNAME",
  // FILTER_PDC_ENTRY_CHEQUENO = "FILTER_PDC_ENTRY_CHEQUENO",
  // SORT_PDC_ENTRY_CHEQUENO = "SORT_PDC_ENTRY_CHEQUENO",
  // FILTER_PDC_ENTRY_CHEQUEDATE = "FILTER_PDC_ENTRY_CHEQUEDATE",
  // SORT_PDC_ENTRY_CHEQUEDATE = "SORT_PDC_ENTRY_CHEQUEDATE",
  // FILTER_PDC_ENTRY_DATE = 'FILTER_PDC_ENTRY_DATE',
  // SORT_PDC_ENTRY_DATE = 'SORT_PDC_ENTRY_DATE',
  // FILTER_PDC_ENTRY_AMOUNT = "FILTER_PDC_ENTRY_AMOUNT",
  // SORT_PDC_ENTRY_AMOUNT = "SORT_PDC_ENTRY_AMOUNT",
  //  FILTER_VOUCHER_TYPE_NAME ="FILTER_VOUCHER_TYPE_NAME",
  //  SORT_VOUCHER_TYPE_NAME="SORT_VOUCHER_TYPE_NAME",
  //  FILTER_VOUCHER_TYPE_TYPEOFVOUCHER="FILTER_VOUCHER_TYPE_TYPEOFVOUCHER",
  //  VOUCHER_TYPE_TYPEOFVOUCHER="VOUCHER_TYPE_TYPEOFVOUCHER",
  FILTER_PDC_ENTRY_VOUCHERNO = "FILTER_PDC_ENTRY_VOUCHERNO",
  SORT_PDC_ENTRY_VOUCHERNO = "SORT_PDC_ENTRY_VOUCHERNO",
  FILTER_PDC_ENTRY_BANKNAME = "FILTER_PDC_ENTRY_BANKNAME",
  SORT_PDC_ENTRY_BANKNAME = "SORT_PDC_ENTRY_BANKNAME",
  FILTER_PDC_ENTRY_CHEQUENO = "FILTER_PDC_ENTRY_CHEQUENO",
  SORT_PDC_ENTRY_CHEQUENO = "SORT_PDC_ENTRY_CHEQUENO",
  FILTER_PDC_ENTRY_CHEQUEDATE = "FILTER_PDC_ENTRY_CHEQUEDATE",
  SORT_PDC_ENTRY_CHEQUEDATE = "SORT_PDC_ENTRY_CHEQUEDATE",
  FILTER_PDC_ENTRY_DATE = 'FILTER_PDC_ENTRY_DATE',
  SORT_PDC_ENTRY_DATE = 'SORT_PDC_ENTRY_DATE',
  FILTER_PDC_ENTRY_AMOUNT = "FILTER_PDC_ENTRY_AMOUNT",
  SORT_PDC_ENTRY_AMOUNT = "SORT_PDC_ENTRY_AMOUNT",

  FILTER_VOUCHER_TYPE_NAME = "FILTER_VOUCHER_TYPE_NAME",
  SORT_VOUCHER_TYPE_NAME = "SORT_VOUCHER_TYPE_NAME",
  FILTER_VOUCHER_TYPE_TYPEOFVOUCHER = "FILTER_VOUCHER_TYPE_TYPEOFVOUCHER",
  VOUCHER_TYPE_TYPEOFVOUCHER = "VOUCHER_TYPE_TYPEOFVOUCHER",

  // FILTER_ACTIVITY_LOG_VOUCHER_TYPE_NAME ="FILTER_ACTIVITY_LOG_VOUCHER_TYPE_NAME",
  // SORT_ACTIVITY_LOG_VOUCHER_TYPE_NAME   ="SORT_ACTIVITY_LOG_VOUCHER_TYPE_NAME",
  // FILTER_ACTIVITY_LOG_EVENT_TYPE_NAME   ="FILTER_ACTIVITY_LOG_EVENT_TYPE_NAME", 
  // SORT_ACTIVITY_LOG_EVENT_TYPE_NAME     ="SORT_ACTIVITY_LOG_EVENT_TYPE_NAME",
  // FILTER_ACTIVITY_LOG_USERNAME_NAME     ="FILTER_ACTIVITY_LOG_USERNAME_NAME",
  // SORT_ACTIVITY_LOG_USERNAME_NAME       ="SORT_ACTIVITY_LOG_USERNAME_NAME",
  // FILTER_ACTIVITY_LOG_BRANCH_NAME       ="FILTER_ACTIVITY_LOG_BRANCH_NAME",
  // SORT_ACTIVITY_LOG_BRANCH_NAME         ="SORT_ACTIVITY_LOG_BRANCH_NAME",
  // FILTER_ACTIVITY_LOG_DATE_AND_TIME     ="FILTER_ACTIVITY_LOG_DATE_AND_TIME",
  // SORT_ACTIVITY_LOG_DATE_AND_TIME       ="SORT_ACTIVITY_LOG_DATE_AND_TIME",
  // FILTER_ACTIVITY_LOG_STATUS            ="FILTER_ACTIVITY_LOG_STATUS",
  // SORT_ACTIVITY_LOG_STATUS              ="SORT_ACTIVITY_LOG_STATUS",
  // FILTER_ACTIVITY_LOG_MESSAGE           ="FILTER_ACTIVITY_LOG_MESSAGE",
  // SORT_ACTIVITY_LOG_MESSAGE             ="SORT_ACTIVITY_LOG_MESSAGE",
  FILTER_ADMINISTRATION_ACTIVITY_LOG_GRID = "FILTER_ADMINISTRATION_ACTIVITY_LOG_GRID",
  FILTER_ADMINISTRATION_TASK_EVENT_GRID = "FILTER_ADMINISTRATION_TASK_EVENT_GRID",
  SORT_ADMINISTRATION_ACTIVITY_LOG_GRID = "SORT_ADMINISTRATION_ACTIVITY_LOG_GRID",
  SORT_ADMINISTRATION_TASK_EVENT_GRID = "SORT_ADMINISTRATION_TASK_EVENT_GRID",
  PAGINATION_ADMINISTRATION_ACTIVITY_LOG_GRID = "PAGINATION_ADMINISTRATION_ACTIVITY_LOG_GRID",
  APIREQUEST_ADMINISTRATION_ACTIVITY_LOG_GRID = "APIREQUEST_ADMINISTRATION_ACTIVITY_LOG_GRID",
  
  PAGINATION_ADMINISTRATION_TASK_EVENT_GRID = "PAGINATION_ADMINISTRATION_TASK_EVENT_GRID",
  APIREQUEST_ADMINISTRATION_TASK_EVENT_GRID = "APIREQUEST_ADMINISTRATION_TASK_EVENT_GRID",
  APIREQUEST_ADMINISTRATION_TASK_EVENT_COMPLETED_GRID = "APIREQUEST_ADMINISTRATION_TASK_EVENT_COMPLETED_GRID",
  
  PAGINATION_ADMINISTRATION_TASK_EVENT_COMPLATED_GRID ="PAGINATION_ADMINISTRATION_TASK_EVENT_COMPLATED_GRID",
  ADMINISTRATION_TASK_EVENT_COMPLATED_GRID = "ADMINISTRATION_TASK_EVENT_COMPLATED_GRID",
  FILTER_ADMINISTRATION_TASK_EVENT_COMPLATED_GRID = "FILTER_ADMINISTRATION_TASK_EVENT_COMPLATED_GRID",
  SORT_ADMINISTRATION_TASK_EVENT_COMPLATED_GRID = "SORT_ADMINISTRATION_TASK_EVENT_COMPLATED_GRID",

  //filter and sord end
  //#region PAGINATION STORAGE
  PAGINATION_ACCOUNT_LEDGER_REPORT_AMOUNT_GRID = "PAGINATION_ACCOUNT_LEDGER_REPORT_AMOUNT_GRID",
  PAGINATION_ACCOUNTLEDGERREPORT_VIEWALLLEDGERAMT_GRID = "PAGINATION_ACCOUNTLEDGERREPORT_VIEWALLLEDGERAMT_GRID",
  PAGINATION_ACCOUNT_LEDGER_REPORT_METAL_GRID = "PAGINATION_ACCOUNT_LEDGER_REPORT_METAL_GRID",
  PAGINATION_PRODUCT_ACCOUNT_LEDGER_GRID = "PAGINATION_PRODUCT_ACCOUNT_LEDGER_GRID",
  APIREQUEST_PRODUCT_ACCOUNT_LEDGER_GRID = "APIREQUEST_PRODUCT_ACCOUNT_LEDGER_GRID",
  APIREQUEST_PRODUCT_LOSS_TRACKING_GRID = "PIREQUEST_PRODUCT_LOSS_TRACKING_GRID",
  APIREQUEST_ACCOUNT_LEDGER_REPORT_AMOUNT_GRID = "APIREQUEST_ACCOUNT_LEDGER_REPORT_AMOUNT_GRID",
  PAGINATION_PRODUCT_PARTIES_GRID = "PAGINATION_PRODUCT_PARTIES_GRID",
  APIREQUEST_PRODUCT_PARTIES_GRID = "APIREQUEST_PRODUCT_PARTIES_GRID",
  APIREQUEST_REPORT_STOCKREPORT_STOCKANALYSISREPORTBYPRODUCT_GRID="APIREQUEST_REPORT_STOCKREPORT_STOCKANALYSISREPORTBYPRODUCT_GRID",
  // PAGINATION_PRODUCT_PARTIES_GRID = "PAGINATION_TRANSACTION_PDCENTRY_PAYMENTLIST_GRID",
  APIREQUEST_TRANSACTION_PARTIES_GRID = "APIREQUEST_TRANSACTION_PDCENTRY_PAYMENTLIST_GRID",
  APIREQUEST_DIAMOND_CURRENTSTOCK_GRID="apiRequestStorageName = StorageName.APIREQUEST_REPORT_JOBWORKQUEUE_GRID;",
  APIREQUEST_ACCOUNTLEDGERREPORT_VIEWALLLEDGERAMT_GRID = "APIREQUEST_ACCOUNTLEDGERREPORT_VIEWALLLEDGERAMT_GRID",
  APIREQUEST_REPORT_JOBWORKQUEUE_GRID="APIREQUEST_REPORT_JOBWORKQUEUE_GRID",

  APIREQUEST_REPORT_DAYREPORT_GRID="APIREQUEST_REPORT_DAYREPORT_GRID",
  APIREQUEST_FINANCIAL_STATEMENT_LEDGER_DETAILS_GRID="APIREQUEST_FINANCIAL_STATEMENT_LEDGER_DETAILS_GRID",
  APIREQUEST_FINANCIAL_STATEMENT_ACCOUNT_GROUPS_GRID="APIREQUEST_FINANCIAL_STATEMENT_ACCOUNT_GROUPS_GRID",
  APIREQUEST_CURRENT_STOCK_GRID="APIREQUEST_CURRENT_STOCK_GRID",
  APIREQUEST_DIAMOND_STOCK_AVAILABILITY_WEIGHTWISE_INWARD = "APIREQUEST_DIAMOND_STOCK_AVAILABILITY_WEIGHTWISE_INWARD",
  APIREQUEST_DIAMOND_STOCK_AVAILABILITY_WEIGHTWISE_OUTWARD="APIREQUEST_DIAMOND_STOCK_AVAILABILITY_WEIGHTWISE_OUTWARD",
  APIREQUEST_CURRENT_STOCK_STOCK_AVAILABILITY_WEIGHTWISE = "APIREQUEST_CURRENT_STOCK_STOCK_AVAILABILITY_WEIGHTWISE",
  APIREQUEST_MANUFACTURINGPROCESS_INWARD_OUTWARD_GRID = "APIREQUEST_MANUFACTURINGPROCESS_INWARD_OUTWARD_GRID",
  APIREQUEST_REFINE_GRID ="APIREQUEST_REFINE_GRID",
  APIREQUEST_RECEIVED_GRID ="APIREQUEST_RECEIVED_GRID",
  APIREQUEST_TRANSACTION_PDCENTRY_GRID="APIREQUEST_TRANSACTION_PDCENTRY_GRID",
  PAGINATION_APIREQUEST_REFINE_GRID_GRID="PAGINATION_APIREQUEST_REFINE_GRID_GRID",
  APIREQUEST_STOCK_DETAILS_GRID=" APIREQUEST_STOCK_DETAILS_GRID",
  APIREQUEST_STOCK_JOURNAL_2_GRID = "APIREQUEST_STOCK_JOURNAL_2_GRID",
  APIREQUEST_TRANSACTION_JOBORDERLIST_GRID="APIREQUEST_TRANSACTION_JOBORDERLIST_GRID",
  APIREQUEST_MANUFACTUR_WORKLOG_REPORT_GRID="APIREQUEST_MANUFACTUR_WORKLOG_REPORT_GRID",
  APIREQUEST_REPORT_KYCREPORT_GRID="APIREQUEST_REPORT_KYCREPORT_GRID",
  PAGINATION_REPORT_DAYREPORT_GRID = "PAGINATION_REPORT_DAYREPORT_GRID",
  PAGINATION_PRODUCT_PARTIES_TRANSACTION_GRID = "PAGINATION_PRODUCT_PARTIES_TRANSACTION_GRID",
  PAGINATION_REPORT_STOCKREPORT_CURRENTSTOCK_GRID = "PAGINATION_REPORT_STOCKREPORT_CURRENTSTOCK_GRID",
  PAGINATION_REPORT_STOCKREPORT_DIAMONDCURRENTSTOCK_GRID = "PAGINATION_REPORT_STOCKREPORT_DIAMONDCURRENTSTOCK_GRID",
  PAGINATION_REPORT_STOCKREPORT_STOCKHISTORY_GOLD_GRID = "PAGINATION_REPORT_STOCKREPORT_STOCKHISTORY_GOLD_GRID",
  APIREQUEST_REPORT_STOCKREPORT_STOCKHISTORY_GOLD_GRID = "APIREQUEST_REPORT_STOCKREPORT_STOCKHISTORY_GOLD_GRID",
  PAGINATION_REPORT_STOCKREPORT_STOCKHISTORY_SILVER_GRID = "PAGINATION_REPORT_STOCKREPORT_STOCKHISTORY_SILVER_GRID",
  APIREQUEST_REPORT_STOCKREPORT_STOCKHISTORY_SILVER_GRID = "APIREQUEST_REPORT_STOCKREPORT_STOCKHISTORY_SILVER_GRID",
  PAGINATION_REPORT_STOCKREPORT_STOCKHISTORY_DIAMOND_GRID = "PAGINATION_REPORT_STOCKREPORT_STOCKHISTORY_DIAMOND_GRID",
  PAGINATION_REPORT_STOCKREPORT_STOCKHISTORY_STONEGEMS_GRID = "PAGINATION_REPORT_STOCKREPORT_STOCKHISTORY_STONEGEMS_GRID",
  PAGINATION_REPORT_STOCKREPORT_STOCKHISTORY_IMITATIONORWATCHES_GRID = "PAGINATION_REPORT_STOCKREPORT_STOCKHISTORY_IMITATIONORWATCHES_GRID",
  PAGINATION_REPORT_STOCKREPORT_STOCKDETAILS_GRID = "PAGINATION_REPORT_STOCKREPORT_STOCKDETAILS_GRID",
  PAGINATION_REPORT_STOCKREPORT_RFID_STOCK_AVAILABLESTOCK_ITEM_GRID = "PAGINATION_REPORT_STOCKREPORT_RFID_STOCK_AVAILABLESTOCK_ITEM_GRID",
  PAGINATION_REPORT_STOCKREPORT_RFID_STOCK_SCANNEDSTOCK_ITEM_GRID = "PAGINATION_REPORT_STOCKREPORT_RFID_STOCK_SCANNEDSTOCK_ITEM_GRID",
  REPORT_STOCKREPORT_RFID_STOCK_SCANNEDSTOCK_ITEM_GRID_INITIAL_ROW = "REPORT_STOCKREPORT_RFID_STOCK_SCANNEDSTOCK_ITEM_GRID_INITIAL_ROW",
  PAGINATION_REPORT_SALEORDERLIST_SALEORDERLIST_GRID = "PAGINATION_REPORT_SALEORDERLIST_SALEORDERLIST_GRID",
  PAGINATION_REPORT_STOCKREPORT_BARCODE_STOCK_GRID = "PAGINATION_REPORT_STOCKREPORT_BARCODE_STOCK_GRID",
  PAGINATION_SETTINGS_VOUCHERTYPE_VOUCHERTYPE_GRID = "PAGINATION_SETTINGS_VOUCHERTYPE_VOUCHERTYPE_GRID",
  PAGINATION_ADMINISTRATION_USER_MANAGEMENT_USER_GRID = "PAGINATION_ADMINISTRATION_USER_MANAGEMENT_USER_GRID",
  PAGINATION_TRANSACTION_PDCENTRY_PAYMENTLIST_GRID = "PAGINATION_TRANSACTION_PDCENTRY_PAYMENTLIST_GRID",
  PAGINATION_TRANSACTION_JOBORDERLIST_GRID = "PAGINATION_TRANSACTION_JOBORDERLIST_GRID",
  PAGINATION_REPORT_CONSIGNMENT_ITEMS_GRID = "PAGINATION_REPORT_CONSIGNMENT_ITEMS_GRID",
  PAGINATION_SCHEDULAR_TASKS_GRID = "PAGINATION_SCHEDULAR_TASKS_GRID",
  
  //#endregion
  FILTER_PRODUCT_ACCOUNT_LEDGER_GRID = "FILTER_PRODUCT_ACCOUNT_LEDGER_GRID",
  FILTER_TRANSECTION_LOANS_GRID = "FILTER_TRANSECTION_LOANS_GRID",
  FILTER_MANUFACTURER_LOSS_TRACKING_GRID = "FILTER_MANUFACTURER_LOSS_TRACKING_GRID",
  SORT_PRODUCT_ACCOUNT_LEDGER_GRID = 'SORT_PRODUCT_ACCOUNT_LEDGER_GRID',

  FILTER_REPORT_DAYREPORT_GRID = "FILTER_REPORT_DAYREPORT_GRID",
  SORT_REPORT_DAYREPORT_GRID = "SORT_REPORT_DAYREPORT_GRID",
  SORT_PRODUCT_PARTIES_TRANSACTION_GRID = "SORT_PRODUCT_PARTIES_TRANSACTION_GRID",

  FILTER_REPORT_KYCREPORT_GRID = "FILTER_REPORT_KYCREPORT_GRID",
  PAGINATION_REPORT_KYCREPORT_GRID = "PAGINATION_REPORT_KYCREPORT_GRID",
  SORT_REPORT_KYCREPORT_GRID = "SORT_REPORT_KYCREPORT_GRID",

  FILTER_REPORT_STOCKREPORT_CURRENTSTOCK_GRID = "FILTER_REPORT_STOCKREPORT_CURRENTSTOCK_GRID",
  FILTER_PRODUCT_PARTIES_TRANSACTION_GRID = "FILTER_PRODUCT_PARTIES_TRANSACTION_GRID",
  FILTER_REPORT_STOCKREPORT_DIAMONDCURRENTSTOCK_GRID = "FILTER_REPORT_STOCKREPORT_DIAMONDCURRENTSTOCK_GRID",
  SORT_REPORT_STOCKREPORT_CURRENTSTOCK_GRID = "SORT_REPORT_STOCKREPORT_CURRENTSTOCK_GRID",
  
  SORT_REPORT_STOCKREPORT_DIAMONDCURRENTSTOCK_GRID = "SORT_REPORT_STOCKREPORT_DIAMONDCURRENTSTOCK_GRID",

  FILTER_REPORT_STOCKREPORT_BARCODE_STOCK_ITEM_GRID ="FILTER_REPORT_STOCKREPORT_BARCODE_STOCK_ITEM_GRID",
  SORT_REPORT_STOCKREPORT_BARCODE_STOCK_ITEM_GRID ="SORT_REPORT_STOCKREPORT_BARCODE_STOCK_ITEM_GRID",

  FILTER_REPORT_STOCKREPORT_STOCKHISTORY_GOLD_GRID = "FILTER_REPORT_STOCKREPORT_STOCKHISTORY_GOLD_GRID",
  FILTER_REPORT_STOCKREPORT_STOCKHISTORY_SILVER_GRID = "FILTER_REPORT_STOCKREPORT_STOCKHISTORY_SILVER_GRID",
  FILTER_REPORT_STOCKREPORT_STOCKHISTORY_DIAMOND_GRID = "FILTER_REPORT_STOCKREPORT_STOCKHISTORY_DIAMOND_GRID",
  FILTER_REPORT_STOCKREPORT_STOCKHISTORY_STONEGEMS_GRID = "FILTER_REPORT_STOCKREPORT_STOCKHISTORY_STONEGEMS_GRID",
  FILTER_REPORT_STOCKREPORT_STOCKHISTORY_IMITATIONORWATCHES_GRID = "FILTER_REPORT_STOCKREPORT_STOCKHISTORY_IMITATIONORWATCHES_GRID",
  SORT_REPORT_STOCKREPORT_STOCKHISTORY_GOLD_GRID = "SORT_REPORT_STOCKREPORT_STOCKHISTORY_GOLD_GRID",
  SORT_REPORT_STOCKREPORT_STOCKHISTORY_SILVER_GRID = "SORT_REPORT_STOCKREPORT_STOCKHISTORY_SILVER_GRID",
  SORT_REPORT_STOCKREPORT_STOCKHISTORY_DIAMOND_GRID = "SORT_REPORT_STOCKREPORT_STOCKHISTORY_DIAMOND_GRID",
  SORT_REPORT_STOCKREPORT_STOCKHISTORY_STONEGEMS_GRID = "SORT_REPORT_STOCKREPORT_STOCKHISTORY_STONEGEMS_GRID",
  SORT_REPORT_STOCKREPORT_STOCKHISTORY_IMITATIONORWATCHES_GRID = "SORT_REPORT_STOCKREPORT_STOCKHISTORY_IMITATIONORWATCHES_GRID",
  FILTER_INVENTORY_STOCK_SUMMARY_REPORT_GRID ="FILTER_INVENTORY_STOCK_SUMMARY_REPORT_GRID",
  FILTER_REPORT_STOCKREPORT_STOCKDETAILS_GRID = "FILTER_REPORT_STOCKREPORT_STOCKDETAILS_GRID",
  SORT_REPORT_STOCKREPORT_STOCKDETAILS_GRID = "SORT_REPORT_STOCKREPORT_STOCKDETAILS_GRID",
  SORT_INVENTORY_STOCK_SUMMARY_REPORT_GRID="SORT_INVENTORY_STOCK_SUMMARY_REPORT_GRID",
  FILTER_REPORT_STOCKREPORT_RFID_STOCK_AVAILABLESTOCK_ITEM_GRID = "FILTER_REPORT_STOCKREPORT_RFID_STOCK_AVAILABLESTOCK_ITEM_GRID",
  SORT_REPORT_STOCKREPORT_RFID_STOCK_AVAILABLESTOCK_ITEM_GRID = "SORT_REPORT_STOCKREPORT_RFID_STOCK_AVAILABLESTOCK_ITEM_GRID",
  FILTER_REPORT_STOCKREPORT_RFID_STOCK_SCANNEDSTOCK_ITEM_GRID = "FILTER_REPORT_STOCKREPORT_RFID_STOCK_SCANNEDSTOCK_ITEM_GRID",
  SORT_REPORT_STOCKREPORT_RFID_STOCK_SCANNEDSTOCK_ITEM_GRID = "SORT_REPORT_STOCKREPORT_RFID_STOCK_SCANNEDSTOCK_ITEM_GRID",
  PAGINATION_INVENTORY_STOCK_SUMMARY_REPORT_GRID ="PAGINATION_INVENTORY_STOCK_SUMMARY_REPORT_GRID",
  APIREQUEST_INVENTORY_STOCK_SUMMARY_REPORT_GRID = "APIREQUEST_INVENTORY_STOCK_SUMMARY_REPORT_GRID",
  FILTER_SETTINGS_VOUCHERTYPE_VOUCHERTYPE_GRID = "FILTER_SETTINGS_VOUCHERTYPE_VOUCHERTYPE_GRID",
  SORT_SETTINGS_VOUCHERTYPE_VOUCHERTYPE_GRID = "SORT_SETTINGS_VOUCHERTYPE_VOUCHERTYPE_GRID",
  SETTINGS_VOUCHERTYPE_VOUCHERTYPE_GRID_INITIAL_ROWS = "SETTINGS_VOUCHERTYPE_VOUCHERTYPE_GRID_INITIAL_ROWS",
  PAGINATION_TAX_RETURN_VAT_IMPORT_GRID="PAGINATION_TAX_RETURN_VAT_IMPORT_GRID",
  TAX_RETURN_VAT_IMPORT_GRID="TAX_RETURN_VAT_IMPORT_GRID",
  FILTER_TAX_RETURN_VAT_IMPORT_GRID="FILTER_TAX_RETURN_VAT_IMPORT_GRID",
  SORT_TAX_RETURN_VAT_IMPORT_GRID="SORT_TAX_RETURN_VAT_IMPORT_GRID",
  PAGINATION_TAX_RETURN_VAT_INPUT_GRID="PAGINATION_TAX_RETURN_VAT_INPUT_GRID",
  PAGINATION_TAX_RETURN_VAT_OUTPUT_GRID="PAGINATION_TAX_RETURN_VAT_OUTPUT_GRID",
  TAX_RETURN_VAT_OUTPUT_GRID="TAX_RETURN_VAT_OUTPUT_GRID",
  TAX_RETURN_VAT_INPUT_GRID="TAX_RETURN_VAT_INPUT_GRID",
  FILTER_TAX_RETURN_VAT_INPUT_GRID="FILTER_TAX_RETURN_VAT_INPUT_GRID",
  FILTER_TAX_RETURN_VAT_OUTPUT_GRID="FILTER_TAX_RETURN_VAT_OUTPUT_GRID",
  SORT_TAX_RETURN_VAT_OUTPUT_GRID="SORT_TAX_RETURN_VAT_OUTPUT_GRID",
  SORT_TAX_RETURN_VAT_INPUT_GRID="SORT_TAX_RETURN_VAT_INPUT_GRID",
  FILTER_ADMINISTRATION_USER_MANAGEMENT_USER_GRID = "FILTER_ADMINISTRATION_USER_MANAGEMENT_USER_GRID",
  SORT_ADMINISTRATION_USER_MANAGEMENT_USER_GRID = "SORT_ADMINISTRATION_USER_MANAGEMENT_USER_GRID",
  SORT_SETTING_WHATSAPP_CAMPAIGN_GRID = "SORT_SETTING_WHATSAPP_CAMPAIGN_GRID",
  WHATSAPP_CAMPAIGN_GRID_INITIAL_ROWS = "WHATSAPP_CAMPAIGN_GRID_INITIAL_ROWS",
  
  SORT_SETTING_EMAIL_CAMPAIGN_GRID = "SORT_SETTING_EMAIL_CAMPAIGN_GRID",
  FILTER_SETTINGS_WHATSAPP_CAMPAIGN_GRID = "FILTER_SETTINGS_WHATSAPP_CAMPAIGN_GRID",
  FILTER_SETTINGS_EMAIL_CAMPAIGN_GRID = "FILTER_SETTINGS_EMAIL_CAMPAIGN_GRID",
  PAGINATION_SETTING_WHATSAPP_CAMPAIGN_GRID="PAGINATION_SETTING_WHATSAPP_CAMPAIGN_GRID",
  APIREQUEST_SETTING_WHATSAPP_CAMPAIGN_GRID="APIREQUEST_SETTING_WHATSAPP_CAMPAIGN_GRID",
  APIREQUEST_SETTING_EMAIL_CAMPAIGN_GRID="APIREQUEST_SETTING_EMAIL_CAMPAIGN_GRID",
  PAGINATION_SETTING_EMAIL_CAMPAIGN_GRID="PAGINATION_SETTING_EMAIL_CAMPAIGN_GRID",
  FILTER_TRANSACTION_PDCENTRY_PAYMENTLIST_GRID = "FILTER_TRANSACTION_PDCENTRY_PAYMENTLIST_GRID",
  SORT_TRANSACTION_PDCENTRY_PAYMENTLIST_GRID = "SORT_TRANSACTION_PDCENTRY_PAYMENTLIST_GRID",

  SETTINGS_EMAIL_CAMPAIGN_RECIPIENTLIST_GRID = "SETTINGS_EMAIL_CAMPAIGN_RECIPIENTLIST_GRID",
  FILTER_SETTINGS_EMAIL_CAMPAIGN_RECIPIENTLIST_GRID = "FILTER_SETTINGS_EMAIL_CAMPAIGN_RECIPIENTLIST_GRID",
  SORT_SETTING_EMAIL_CAMPAIGN_RECIPIENTLIST_GRID = "SORT_SETTING_EMAIL_CAMPAIGN_RECIPIENTLIST_GRID",
  PAGINATION_EMAIL_CAMPAIGN_RECIPIENTLIST_GRID = "PAGINATION_EMAIL_CAMPAIGN_RECIPIENTLIST_GRID",
  EMAIL_CAMPAIGN_RECIPIENTLIST_GRID_INITIAL_ROWS = "EMAIL_CAMPAIGN_RECIPIENTLIST_GRID_INITIAL_ROWS",

  APIREQUEST_MANUFACTURER_CLOSINGREPORT_GRID="APIREQUEST_MANUFACTURER_CLOSINGREPORT_GRID",
  APIREQUEST_USERMANAGEMENT_USERS_GRID="APIREQUEST_USERMANAGEMENT_USERS_GRID",
  APIREQUEST_OLDJEWELLERY_SCRAP_GRID="APIREQUEST_OLDJEWELLERY_SCRAP_GRID",
  MANUFACTURER_CLOSINGREPORT_GRID="MANUFACTURER_CLOSINGREPORT_GRID",
  PAGINATION_MANUFACTURER_CLOSINGREPORT_GRID="PAGINATION_MANUFACTURER_CLOSINGREPORT_GRID",
  FILTER_MANUFACTURER_CLOSINGREPORT_GRID="FILTER_MANUFACTURER_CLOSINGREPORT_GRID",
  SORT_MANUFACTURER_CLOSINGREPORT_GRID="SORT_MANUFACTURER_CLOSINGREPORT_GRID",
  FILTER_TRANSACTION_JOBORDERLIST_GRID ="FILTER_TRANSACTION_JOBORDERLIST_GRID",
  SORT_TRANSACTION_JOBORDERLIST_GRID ="SORT_TRANSACTION_JOBORDERLIST_GRID",

  //store Ip address
  IP_ADDRESS = 'IP_ADDRESS',
  REPORT_STOCKREPORT_STOCKAVAILABILITY_OUTWARDWEIGHT_GRID = "REPORT_STOCKREPORT_STOCKAVAILABILITY_OUTWARDWEIGHT_GRID",
 
  COMPANY_REGISTRATION_ID='COMPANY_REGISTRATION_ID',
  FILTER_ADMINISTRATION_TASK_LOG_GRID = "FILTER_ADMINISTRATION_TASK_LOG_GRID",
  PAGINATION_ADMINISTRATION_Task_LOG_GRID = "PAGINATION_ADMINISTRATION_Task_LOG_GRID",
  STORED_PURCHASE_INVOICE_DATA = "STORED_PURCHASE_INVOICE_DATA",
//for investmentFund Entry 
  INVESTMENT_FUND_INSTALL_ENTRY_INITIAL_ROWS = " INVESTMENT_FUND_INSTALL_ENTRY_INITIAL_ROWS",
  INVESTMENT_FUND_INSTALL_PARTY_ENTRY = "INVESTMENT_FUND_INSTALL_PARTY_ENTRY",
  FILTER_INVESTMENT_FUND_INSTALL_PARTY_ENTRY="FILTER_INVESTMENT_FUND_INSTALL_PARTY_ENTRY",
  SORT_INVESTMENT_FUND_INSTALL_PARTY_ENTRY="SORT_INVESTMENT_FUND_INSTALL_PARTY_ENTRY",
  INVESTMENT_FUND_INSTALLMENT_ENTRY_GRID="INVESTMENT_FUND_INSTALLMENT_ENTRY_GRID",
  FINANCIAL_STATEMENT_VAT_OUTPUT = "FINANCIAL_STATEMENT_VAT_OUTPUT",
  INVESTMENT_FUND_INSTALLMENT_ENTRY_RECEIPT_GRID="INVESTMENT_FUND_INSTALLMENT_ENTRY_RECEIPT_GRID",

  INVESTMENT_FUND_INSTALLMENT_ENTRY_WITHDRAW_GRID="INVESTMENT_FUND_INSTALLMENT_ENTRY_WITHDRAW_GRID",
  
  // FOR EXPORT
  PAGINATION_FINANCIAL_STATEMENT_TAXRETURN_EXPORT_GRID = "PAGINATION_FINANCIAL_STATEMENT_TAXRETURN_EXPORT_GRID",
  FINANCIAL_STATEMENT_TAXRETURN_EXPORT_GRID = "INVESTMENT_FINANCIAL_STATEMENT_TAXRETURN_EXPORT_GRID",
  FILTER_FINANCIAL_STATEMENT_TAXRETURN_EXPORT_GRID = "FILTER_FINANCIAL_STATEMENT_TAXRETURN_EXPORT_GRID",
  SORT_FINANCIAL_STATEMENT_TAXRETURN_EXPORT_GRID = "SORT_FINANCIAL_STATEMENT_TAXRETURN_EXPORT_GRID",
  NAME_DROPDOWN = "NAME_DROPDOWNS",
  // For Loan New Model Pop-up
  PAGINATION_LOAN_NEWMODEL_GRID="PAGINATION_LOAN_NEWMODEL_GRID",
  LOAN_NEWMODEL_GRID = "LOAN_NEWMODEL_GRID",
  FILTER_LOAN_NEWMODEL_GRID = "FILTER_LOAN_NEWMODEL_GRID",
  SORT_LOAN_NEWMODEL_GRID = "SORT_LOAN_NEWMODEL_GRID",
  TRANSACTION_NEWLOAN_PAYMENTLIST_GRID = "TRANSACTION_NEWLOAN_PAYMENTLIST_GRID",
  FILTER_TRANSACTION_NEWLOAN_PAYMENTLIST_GRID = "FILTER_TRANSACTION_NEWLOAN_PAYMENTLIST_GRID",
  SORT_TRANSACTION_NEWLOAN_PAYMENTLIST_GRID = "SORT_TRANSACTION_NEWLOAN_PAYMENTLIST_GRID",
  PAGINATION_TRANSACTION_NEWLOAN_PAYMENTLIST_GRID = "PAGINATION_TRANSACTION_NEWLOAN_PAYMENTLIST_GRID",
  FILTER_TRANSACTION_NEW_LOAN_PAYMENT_GRID = "FILTER_TRANSACTION_NEW_LOAN_PAYMENT_GRID",
  SORT_TRANSACTION_NEW_LOAN_PAYMENT_GRID = "SORT_TRANSACTION_NEW_LOAN_PAYMENT_GRID",
  PAGINATION_TRANSACTION_NEW_LOAN_PAYMENT_GRID = "PAGINATION_TRANSACTION_NEW_LOAN_PAYMENT_GRID",
  // For Bank Reconciliation
  PAGINATION_TRANSACTION_BANK_RECONCILIATION_GRID = "PAGINATION_TRANSACTION_BANK_RECONCILIATION_GRID",
  TRANSACTION_BANK_RECONCILIATION_GRID = "TRANSACTION_BANK_RECONCILIATION_GRID",
  FILTER_TRANSACTION_BANK_RECONCILIATION_GRID = "FILTER_TRANSACTION_BANK_RECONCILIATION_GRID",
  SORT_TRANSACTION_BANK_RECONCILIATION_GRID = "SORT_TRANSACTION_BANK_RECONCILIATION_GRID",
  APIREQUEST_RFID_STOCK_REPORT_SCANNEDSTOCK = "APIREQUEST_RFID_STOCK_REPORT_SCANNEDSTOCK",
  APIREQUEST_RFID_STOCK_REPORT_AVAILABLESTOCK="APIREQUEST_RFID_STOCK_REPORT_AVAILABLESTOCK",
  INVENTORY_DIAMOND_STONES_GEMSSTONES_GRID = "INVENTORY_DIAMOND_STONES_GEMSSTONES_GRID",
  INVENTORY_DIAMOND_STONES_DIAMOND_GRID = "INVENTORY_DIAMOND_STONES_DIAMOND_GRID",
  INVENTORY_DIAMOND_STONES_JEWELLERY_GRID = "INVENTORY_DIAMOND_STONES_JEWELLERY_GRID",
  INVENTORY_DIAMOND_STONES_JEWELLERY_POPUP_GRID="INVENTORY_DIAMOND_STONES_JEWELLERY_POPUP_GRID",
  FILTER_INVENTORY_DIAMOND_STONES_JEWELLERY_GRID = "FILTER_INVENTORY_DIAMOND_STONES_JEWELLERY_GRID",
  FILTER_INVENTORY_DIAMOND_STONES_DIAMOND_GRID = "FILTER_INVENTORY_DIAMOND_STONES_DIAMOND_GRID",
  FILTER_INVENTORY_DIAMOND_STONES_GEMSSTONES_GRID = "FILTER_INVENTORY_DIAMOND_STONES_GEMSSTONES_GRID",
  SORT_INVENTORY_DIAMOND_STONES_GEMSSTONES_GRID = "SORT_INVENTORY_DIAMOND_STONES_GEMSSTONES_GRID",
  SORT_INVENTORY_DIAMOND_STONES_JEWELLERY_GRID = "SORT_INVENTORY_DIAMOND_STONES_JEWELLERY_GRID",
  SORT_INVENTORY_DIAMOND_STONES_DIAMOND_GRID = "SORT_INVENTORY_DIAMOND_STONES_DIAMOND_GRID",
  PAGINATION_INVENTORY_DIAMOND_STONES_DIAMOND_GRID = "PAGINATION_INVENTORY_DIAMOND_STONES_DIAMOND_GRID",
  PAGINATION_INVENTORY_DIAMOND_STONES_GEMSSTONES_GRID = "PAGINATION_INVENTORY_DIAMOND_STONES_GEMSSTONES_GRID",
  PAGINATION_INVENTORY_DIAMOND_STONES_JEWELLERY_GRID = "PAGINATION_INVENTORY_DIAMOND_STONES_JEWELLERY_GRID",
  APIREQUEST_INVENTORY_DIAMOND_STONES_JEWELLERYGRID = "APIREQUEST_INVENTORY_DIAMOND_STONES_JEWELLERYGRID",
  APIREQUEST_INVENTORY_DIAMOND_STONES_DIAMOND_GRID = "APIREQUEST_INVENTORY_DIAMOND_STONES_DIAMOND_GRID",
  APIREQUEST_INVENTORY_DIAMOND_STONES_GEMSSTONES_GRID = "APIREQUEST_INVENTORY_DIAMOND_STONES_GEMSSTONES_GRID",
  APIREQUEST_INVENTORY_GOLD_AND_SILVER_GOLD_GRID = "APIREQUEST_INVENTORY_GOLD_AND_SILVER_GOLD_GRID",
  APIREQUEST_INVENTORY_GOLD_AND_SILVER_SILVER_GRID = "APIREQUEST_INVENTORY_GOLD_AND_SILVER_SILVER_GRID",

  INVENTORY_GOLD_AND_SILVER_GOLD_GRID ="INVENTORY_GOLD_AND_SILVER_GOLD_GRID",
  FILTER_INVENTORY_GOLD_AND_SILVER_GOLD_GRID ="FILTER_INVENTORY_GOLD_AND_SILVER_GOLD_GRID",
  SORT_INVENTORY_GOLD_AND_SILVER_GOLD_GRID ="SORT_INVENTORY_GOLD_AND_SILVER_GOLD_GRID", 
  PAGINATION_INVENTORY_GOLD_AND_SILVER_GOLD_GRID ="PAGINATION_INVENTORY_GOLD_AND_SILVER_GOLD_GRID",
  
  INVENTORY_GOLD_AND_SILVER_SILVER_GRID ="INVENTORY_GOLD_AND_SILVER_SILVER_GRID",
  FILTER_INVENTORY_GOLD_AND_SILVER_SILVER_GRID ="FILTER_INVENTORY_GOLD_AND_SILVER_SILVER_GRID",
  SORT_INVENTORY_GOLD_AND_SILVER_SILVER_GRID ="SORT_INVENTORY_GOLD_AND_SILVER_SILVER_GRID", 
  PAGINATION_INVENTORY_GOLD_AND_SILVER_SILVER_GRID ="PAGINATION_INVENTORY_GOLD_AND_SILVER_SILVER_GRID", 
}


// constant variable
export const MetalSectionNavTab = 'metalSectionNavTab';

//#region keboard keyCode
export enum KeyCode {
    Backspace = 8,
    Tab = 9,
    Enter = 13,
    Shift = 16,
    Ctrl = 17,
    Alt = 18,
    PauseBreak = 19,
    CapsLock = 20,
    Escape = 27,
    Space = 32,
    PageUp = 33,
    PageDown = 34,
    End = 35,
    Home = 36,
    LeftArrow = 37,
    UpArrow = 38,
    RightArrow = 39,
    DownArrow = 40,
    Insert = 45,
    Delete = 46,
    Key0 = 48,
    Key1 = 49,
    Key2 = 50,
    Key3 = 51,
    Key4 = 52,
    Key5 = 53,
    Key6 = 54,
    Key7 = 55,
    Key8 = 56,
    Key9 = 57,
    A = 65,
    B = 66,
    C = 67,
    D = 68,
    E = 69,
    F = 70,
    G = 71,
    H = 72,
    I = 73,
    J = 74,
    K = 75,
    L = 76,
    M = 77,
    N = 78,
    O = 79,
    P = 80,
    Q = 81,
    R = 82,
    S = 83,
    T = 84,
    U = 85,
    V = 86,
    W = 87,
    X = 88,
    Y = 89,
    Z = 90,
    LeftWindowKey = 91,
    RightWindowKey = 92,
    SelectKey = 93,
    Numpad0 = 96,
    Numpad1 = 97,
    Numpad2 = 98,
    Numpad3 = 99,
    Numpad4 = 100,
    Numpad5 = 101,
    Numpad6 = 102,
    Numpad7 = 103,
    Numpad8 = 104,
    Numpad9 = 105,
    Multiply = 106,
    Add = 107,
    Subtract = 109,
    DecimalPoint = 110,
    Divide = 111,
    F1 = 112,
    F2 = 113,
    F3 = 114,
    F4 = 115,
    F5 = 116,
    F6 = 117,
    F7 = 118,
    F8 = 119,
    F9 = 120,
    F10 = 121,
    F11 = 122,
    F12 = 123,
    NumLock = 144,
    ScrollLock = 145,
    SemiColon = 186,
    EqualSign = 187,
    Comma = 188,
    Dash = 189,
    Period = 190,
    ForwardSlash = 191,
    GraveAccent = 192,
    OpenBracket = 219,
    BackSlash = 220,
    CloseBraket = 221,
    SingleQuote = 222
}
//#endregion keboard keyCode

export enum MetalGridMoreButtonId{
    Gold        = 'GoldAddMoreItemBtn',
    Silver      = 'SilverAddMoreItemBtn',
    Diamond     = 'DiamondAddMoreItemBtn',
    ImmitationOrWatches  = 'Imitation Or WatchesAddMoreItemBtn',
    StoneGEMS = 'Stone GEMSAddMoreItemBtn',
}

export enum ExportAs {
    None = 0,
    Excel =1,
    PDF   =2,
    CUSTOM=3,
  }

  export enum ExportDropdownBindDynamicParameter {
    RequestModel = 1,
    GridApi      = 2,
    GridName     = 3
  }


//For Grid 
// For Quantiy Column WeightColWidth
export enum QuantityColWidth {
  MinWidth = 75,
  MaxWidth = 130,
}

// For Metal Column-------
export enum MetalColWidth {
  MinWidth = 80,
  MaxWidth = 130,
}

// For Weight Column--------
export enum WeightColWidth {
  MinWidth = 90,
  MaxWidth = 165,
}


// For Barcode Column
export enum BarcodeColWidth {
  MinWidth = 90,
  MaxWidth = 130,
}

// For  Amount Column--------
export enum AmountColWidth {
  MinWidth = 90,
  MaxWidth = 185,
}

// For Name Field Column Name(Branch Name ,Group Name, Name ,Comment, Ledger Type )
export enum NameColWidth {
  MinWidth = 150,
  MaxWidth = 700,
}

// For Voucher Column
export enum VoucherColWidth {
  MinWidth = 140,
  MaxWidth = 170,
}

// For Udf Col Width
export enum UDFColWidth {
  MinWidth = 140,
  MaxWidth = 180,
}
// For Product Name Column----
export enum ProductColWidth {
  MinWidth = 100,
  MaxWidth = 700,
}
// -----------------------------
// For Contact Column
export enum ContactColWidth{
  MinWidth = 200,
  MaxWidth = 250,
  }
// For Mail Column
  export enum MailColWidth{
    MinWidth = 250,
    MaxWidth = 700,
  }
  // Common enum for cross icon and Headder icon
  export enum IconHover{
   Default = 'default',
   Pink = 'pink-hover' 
  }

  