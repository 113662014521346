import { Injectable } from '@angular/core';
import { tap } from 'rxjs';
import { DataService } from '../../services/data.service';
import { ResponseModel } from '../../models/response-model.model';
import { StorageService } from '../../services/storage.service';
@Injectable({
  providedIn: 'root'
})
export class TotalSectionService {
  constructor(public service: DataService, public storageService: StorageService) { }
  getCalculatedInvoiceDetails(model: any) {
    let url = 'api/app/calculation/getCalculatedInvoiceDetails';
    return this.service.post(url, model, false, null, false).pipe<ResponseModel>(tap((response: any) => {
      return response;
    })
    );
  }
}