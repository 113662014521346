
<div class="commonContainer form-group custom-radio-ml ms-2">
    <div class="radio radio-primary">
    <input 
        type="radio" 
        [id]="id"
        tabindex="tabindex"
        [name]="name"
        [(ngModel)]="status"
        [value]="radioButtonValue"
        (change)="check.emit(radioButtonValue)"
        [required]="isRequired"
        > 
        <!-- (change)="onItemChange($event.target.checked)" -->
  
    <label [for]="id" class="ps-2 f-14 font-semibold text-gray-color m-0">{{radioButtonLabel}}<span class="digits"></span></label>
</div>  
  
