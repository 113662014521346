import { Component, Input } from '@angular/core';
import { IHeaderAngularComp } from 'ag-grid-angular';
import { IHeaderParams } from 'ag-grid-community';
import {EditNewService} from './editnew.service'
@Component({
  selector: 'app-editnew',
  templateUrl: './editnew.component.html',
  styleUrls: ['./editnew.component.scss']
})
export class EditnewComponent implements IHeaderAngularComp {

public params: IHeaderParams;
  @Input() context: string;
  constructor(private editNewService: EditNewService){

  }

  agInit(params: IHeaderParams): void {
    this.params = params;
    this.context = params.column.getColId(); 
  }

  refresh(): boolean {
    return false;
  }

  onIconClick() {
    this.editNewService.emitEditNewClick(this.context); 
  }
}

