<div class="d-flex justify-content-between align-items-center w-100">
  <div class="text-gray-color">{{params?.displayName}}</div>
  <div>
    <svg (click)="onIconClick()" class="cursorPointer mt-1 plusIcon-hover"  xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 21.963 21.963" >
      <g id="Group_1832" data-name="Group 1832" transform="translate(838 -5635)">
        <circle id="Ellipse_7" data-name="Ellipse 7" cx="10.982" cy="10.982" r="10.982" transform="translate(-838 5635)" fill="#b6c0d6"/>
        <path id="Union_4" data-name="Union 4" d="M5.177-1a.686.686,0,0,1,.686.686v4.8h4.8a.686.686,0,0,1,0,1.373h-4.8v4.8a.686.686,0,0,1-1.373,0v-4.8h-4.8A.686.686,0,0,1-1,5.177a.686.686,0,0,1,.686-.686h4.8v-4.8A.686.686,0,0,1,5.177-1Z" transform="translate(-832.196 5640.804)" fill="#fff"/>
      </g>
    </svg>
  </div>  
</div>