
<div [class]="selectClass+' input-group ps-1'" [ngClass]="{'readonly-field': readOnly,'border-primary-red': inputDateRef.invalid && (inputDateRef.dirty || inputDateRef.touched)}">
    <input
      type="date"
      [class]="inputClass +' form-control datepicker-input w-100 custom-datepicker-icon'"
      placeholder="Select date"
      aria-label="date"
      [id]="id"
      [(ngModel)]="inputDate" 
      [required]="isRequired"
      (keydown)="onkeyDown($event)" 
      (change)="onChange($event)"
      (blur)="onBlur($event)"
      data-provide="datepicker"
      [name]="inputDatepickerName"  
      [min]="minDate"
      [max]="maxDate"
      [readOnly]="readOnly"
      [disabled]="isdisabled" 
      #inputDateRef="ngModel"
      
    />
</div>