import { Component } from '@angular/core';

@Component({
  selector: 'app-no-permission-view',
  templateUrl: './no-permission-view.component.html',
  styleUrls: ['./no-permission-view.component.scss']
})
export class NoPermissionViewComponent {

}
