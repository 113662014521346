import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Input, Output, Renderer2, ViewChild, ViewContainerRef } from '@angular/core';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { ICellEditorParams } from 'ag-grid-community';
import { Subscription } from 'rxjs';
import { AgGridDropdown, StorageName } from 'src/app/shared/enum/common-enum';
import { StorageService } from 'src/app/shared/services/storage.service';
export interface DropdownIcons{
  plusIcon:boolean,
  dropdownVal:string
 }
@Component({
  selector: 'app-ag-grid-custom-status-dropdown',
  templateUrl: './ag-grid-custom-status-dropdown.component.html',
  styleUrls: ['./ag-grid-custom-status-dropdown.component.scss']
})
export class AgGridCustomStatusDropdownComponent implements ICellEditorAngularComp, AfterViewInit {
  @ViewChild("container", { read: ViewContainerRef }) public container;
  constructor( public storageService : StorageService ,private renderer: Renderer2 ) {}
  oldValue;
  values:any;
  drpdownVal:any
  value: string;
  dropdownname:string
  storageName = StorageName;
 public params: any;
  agGridDropdown = AgGridDropdown;
  valueChanged = new EventEmitter<string>();
  dropdownvalue
  tabId:any
  ngAfterViewInit(): void {
    this.params.api.addEventListener('cellEditingStarted', event => {
      const select =  document.querySelector("#custom_select_options") as HTMLSelectElement;
      if(select){
        select.focus();
      }
    })
    // for focus dropdown
  }

  agInit(params: ICellEditorParams<any> & DropdownIcons): void {
    this.params = params;
    const valuesOrFunction = params.colDef.cellEditorParams?.values;
    this.values = typeof valuesOrFunction === 'function' ? valuesOrFunction() : valuesOrFunction??[];
    this.values = params?.colDef?.cellEditorParams?.values() ?? [];
    this.value = this.params.value ?? this.params.value.toString();
  }

  getDropdown(val:string):string{
  let dropdownList =  this.storageService.retrieve(val)
    return dropdownList;
  }

  getValue() {
    return this.value ?? this.value.toString();
  }

  ngOnInit(): void {
    
  }
  onKeyDown(event): void {
    let key = event.which || event.keyCode;
    if (
      key == 37 || // left
      key == 39
    ) {
      // event.prventDefault()
      // event.stopPropagation();
    }
  }

  selcetedName:any
  selected(item) 
  {
    this.dropdownvalue = item;
  
    const valuesOrFunction = this.params.colDef.cellEditorParams?.values();
    this.selcetedName = valuesOrFunction.find(a => a.id == item).name;
    this.params?.setValue(item)
    this.valueChanged.emit(this.dropdownvalue); 
  }
  
}
