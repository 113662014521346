import { Observable, tap } from 'rxjs';
import { ResponseModel } from './../../../app/shared/models/response-model.model';
import { DataService } from './../../shared/services/data.service';
import { Injectable } from '@angular/core';
import { API_URL_CONSIGNMENTLIST, API_URL_JOBWORK_ORDER_LIST, } from 'src/app/shared/url-helper/url-helper';
import { StorageName } from 'src/app/shared/enum/common-enum';
import { GetPDCVoucherRequestModel } from './transaction.model';
import { StorageService } from 'src/app/shared/services/storage.service';


@Injectable({
  providedIn: 'root'
})
export class TransactionService {
  public isCardViewJobWorkOrder: boolean;
  constructor(public service: DataService, public storageService: StorageService) {
  }


  getPrductByBarcodeNo(barcodeNo: string, formName) {
    let url = '';
    switch (formName) {
      case (StorageName.FORMNAME_MANUFACTURER_JOBWORK_QUEUE):
        url = `api/app/jobworkqueue/GetJobworkQueueByBarcodeNo`;
        const data = { barcodeNo };
        return this.service.post(url, data, true).pipe<ResponseModel>(tap((response: any) => {
          return response;
        })
        );
      default:
        url = `api/app/purchaseinvoice/getItemDetailByBarcodeNo?BarcodeNumber=${barcodeNo}`;
        return this.service.get(url, true).pipe<ResponseModel>(tap((response: any) => {
          return response;
        })
        );
    }

  }

  insertInvoice(data, idToReset, controllerName) {
    let url = 'api/app/' + controllerName + '/insert' + controllerName;
    return this.service.post(url, data, false, undefined, true).pipe<ResponseModel>(tap((response: any) => {
      if (response.isSuccess == true) {
        this.storageService.store(idToReset, 0);
        this.storageService.resetInvoice.next(controllerName);
      }
      return response;
    })
    );
  }

  getInvoiceById(voucherTypeId, id, print = false) {
    var printModel: any = {}
    printModel.id = id;
    printModel.isPrint = print;
    printModel.voucherTypeId = voucherTypeId;
    let url = 'api/app/invoice/getInvoiceByVoucherTypeAndId';
    return this.service.post(url, printModel, false).pipe<ResponseModel>(tap((response: any) => {
      if (response.isSuccess == true) {
        if(response?.response?.invoiceModel != undefined)
          response.response.invoiceModel = response?.response?.invoiceModel[0];
        if (!(response.message == 'Print PDF')) {
          this.storageService.getInvoice.next(response);
          if (response.response.invoiceModel != undefined)
            this.storageService.setSelect2Dropdown.next(response.response.invoiceModel?.accountLedgerId?.toString())
          else
            this.storageService.setSelect2Dropdown.next(response.response.invoiceModel.accountLedgerId.toString())
        }
      }
      return response;
    })
    );
  }

  getCustomerDetailsAllDropdown(formName: any) {

    let url = `api/app/dropdown/getalldropdowns?FormName=${formName}`;
    return this.service.get(url, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

  GetAllPdcvoucher(data: GetPDCVoucherRequestModel) {
    let url = `api/app/pdcvoucher/getallpdcvoucher`;
    return this.service.post(url, data, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

  insertPDCPayable(data: any) {
    let url = `api/app/pdcvoucher/insertPDCPayable`;
    return this.service.post(url, data).pipe<ResponseModel>(tap((response: any) => {
      return response;
    })
    );
  }

  insertPDCReceivable(data: any) {
    let url = `api/app/pdcvoucher/insertPDCReceivable`;
    return this.service.post(url, data).pipe<ResponseModel>(tap((response: any) => {
      return response;
    })
    );
  }
  getbyidpdcvoucher(id: any, pdcVoucherType: any) {
    let url = `api/app/pdcvoucher/getbyidpdcvoucher?Id=${id}&PDCVoucherType=${pdcVoucherType}`;
    return this.service.get(url, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }
  Updatepdcvoucher(data: any) {
    let url = 'api/app/pdcvoucher/updatepdcvoucher';
    let datas = JSON.stringify(data)
    console.log(datas)
    return this.service.putWithId(url, data, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      }))
  }

  insertUpdatePDCVoucherData(data: any) {
    let url = `api/app/pdcvoucher/insertupdatepdcclearances`;
    console.log("api/app/pdcvoucher/insertupdatepdcclearances", JSON.stringify(data))
    return this.service.post(url, data).pipe<ResponseModel>(tap((response: any) => {
      return response;
    })
    );
  }

  deletepdcvoucher(id: any, pdcVoucherTypeId: any) {
    let url = `api/app/pdcvoucher/deletepdcvoucher?Id=${id}&PDCVoucherType=${pdcVoucherTypeId}`
    return this.service.delete(url, true).pipe<ResponseModel>(tap((response: any) => {
      return response;
    }))
  }


  getInvoicesByAccountLedgerId(id: any) {
    let url = `api/app/dropdown/getagainstinvoiceno?AccountLedgerId=${id}`
    return this.service.get(url, true, null, false).pipe<ResponseModel>(tap((response: any) => {
      return response;
    }))
  }



  //#region salereturn 
  insertSaleReturn(data: any) {
    let url = `api/app/salereturn/insertsalereturn`;
    console.log("api/app/salereturn/updatesalereturn", JSON.stringify(data))
    return this.service.post(url, data).pipe<ResponseModel>(tap((response: any) => {
      return response;
    })
    );
  }
  updateSaleReturn(data: any) {
    let url = `api/app/salereturn/updatesalereturn`;
    console.log("api/app/salereturn/updatesalereturn", JSON.stringify(data))
    return this.service.post(url, data).pipe<ResponseModel>(tap((response: any) => {
      return response;
    })
    );
  }
  getInvoiceItemWithDescription(data: any) {
    let url = 'api/app/invoiceItemDescription/getInvoiceItemWithDescription';
    const json = JSON.stringify(data)
    console.log(json);

    return this.service.post(url, data, null, false, true).pipe<ResponseModel>(tap((response: any) => {
      return response;
    })
    );
  }

  getPrintBarcodeQRWithBarcodeImageList() {
    let url = 'api/app/barcodeSetting/getPrintBarcodeQRWithBarcodeImageList';

    return this.service.get(url, null,
      false, true).pipe<ResponseModel>(tap((response: any) => {
        return response;
      })
      );
  }

  getiItemDetailByItemCode(data: any) {
    let url = 'api/app/invoiceitemdescription/getItemDetailByItemCode';
    const json = { itemCode: data };
    return this.service.post(url, json, null, false, true).pipe<ResponseModel>(tap((response: any) => {
      return response;
    })
    );
  }


  getAccountLedgerBalanceReport(accountLedgerId: any) {
    if (accountLedgerId > 0) {
      let url = 'api/app/AccountLedgerReport/getAccountLedgerBalanceReport';
      var data = { LedgerId: accountLedgerId };
      return this.service.post(url, data, false, null, false).pipe<ResponseModel>(tap((response: any) => {
        return response;
      })
      );
    }
  }


  // #journal voucher start
  getAllJournalVoucher(data: any) {
    let url = 'api/app/journalvoucher/getalljournalvoucher';
    const json = JSON.stringify(data);
    return this.service.post(url, data, true).pipe<ResponseModel>(tap((response: any) => {
      return response;
    })
    );
  }

  insertJournalVoucher(data: any) {
    let url = 'api/app/journalvoucher/insertjournalvoucher';
    const json = JSON.stringify(data);
    return this.service.post(url, data, true).pipe<ResponseModel>(tap((response: any) => {
      return response;
    })
    );
  }

  insertPurchaseFixing(data: any) {
    let url = 'api/app/PurchaseFixing/insertPurchaseFixing';
    const json = JSON.stringify(data);
    return this.service.post(url, data, true).pipe<ResponseModel>(tap((response: any) => {
      return response;
    })
    );
  }


  insertSaleFixing(data: any) {
    let url = 'api/app/SaleFixing/insertSaleFixing';
    const json = JSON.stringify(data);
    return this.service.post(url, data, true).pipe<ResponseModel>(tap((response: any) => {
      return response;
    })
    );
  }

  deleteJournalVoucher(id?: number): Observable<ResponseModel> {
    let url = `api/app/journalvoucher/deletejournalvoucherdetails?Id=${id}`;
    const json = JSON.stringify(url);
    return this.service.delete(url, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

  deletePurchaseFixing(id?: number): Observable<ResponseModel> {
    let url = `api/app/journalvoucher/deletepurchasefixingdetails?Id=${id}`;
    const json = JSON.stringify(url);
    return this.service.delete(url, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

  deleteSaleFixing(id?: number): Observable<ResponseModel> {
    let url = `api/app/journalvoucher/deletesalefixingdetails?Id=${id}`;
    const json = JSON.stringify(url);
    return this.service.delete(url, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

  getJournalVoucherById(id: number): Observable<ResponseModel> {
    let url = `api/app/journalvoucher/getjournalvoucherbyid`;
    const data = { id }
    return this.service.post(url, data, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

  getPurchaseFixingById(id: number): Observable<ResponseModel> {
    let url = `api/app/PurchaseFixing/getPurchaseFixingById`;
    const data = { id }
    return this.service.post(url, data, true).pipe<ResponseModel>(
      tap((response: any) => {
        if (response.isSuccess == true)
          this.storageService.getInvoice.next(response);
        return response;
      })
    );
  }


  getSaleFixingById(id: number): Observable<ResponseModel> {
    let url = `api/app/SaleFixing/getSaleFixingById`;
    const data = { id }
    return this.service.post(url, data, true).pipe<ResponseModel>(
      tap((response: any) => {
        if (response.isSuccess == true)
          this.storageService.getInvoice.next(response);
        return response;
      })
    );
  }

  // #journal voucher end



  // #contra voucher start
  getAllContraVoucher(data?: any) {
    let url = 'api/app/contravoucher/getallcontravoucher';
    const json = JSON.stringify(data);
    return this.service.post(url, data, true).pipe<ResponseModel>(tap((response: any) => {
      return response;
    })
    );
  }

  insertContraVoucher(data: any) {
    let url = 'api/app/contravoucher/insertcontravoucher';
    const json = JSON.stringify(data);
    return this.service.post(url, data, true).pipe<ResponseModel>(tap((response: any) => {
      return response;
    })
    );
  }

  updateContraVoucher(data: any) {
    let url = 'api/app/contravoucher/updatecontravoucher';
    return this.service.post(url, data, true).pipe<ResponseModel>(tap((response: any) => {
      return response;
    })
    );
  }

  deleteContraVoucher(id?: number): Observable<ResponseModel> {
    let url = `api/app/contravoucher/deletecontravoucherdetails?Id=${id}`;
    const json = JSON.stringify(url);
    return this.service.delete(url, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

  getContraVoucherById(id: number): Observable<ResponseModel> {
    let url = "api/app/contravoucher/getcontravoucherbyid";
    const data = { id };
    return this.service.post(url, data, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }
  // #contra voucher end




  getallConsignmentItems(data: any): Observable<ResponseModel> {
    let url = API_URL_CONSIGNMENTLIST;
    return this.service.post(url, data, true).pipe<ResponseModel>(tap((response: any) => {
      return response;
    })
    );
  }


  getJobworkOrders(data: any): Observable<ResponseModel> {
    let url = API_URL_JOBWORK_ORDER_LIST;
    const json = JSON.stringify(data)
    return this.service.post(url, data, true).pipe<ResponseModel>(tap((response: any) => {
      return response;
    })
    );
  }


  getdiamondstock() {
    let url = 'api/app/stockreport/getdiamondstock';
    return this.service.get(url, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

  getStoneStock() {
    let url = 'api/app/stockreport/getStoneStock';
    return this.service.get(url, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

  updatejobworkorderrfid(data: any) {
    let url = "api/app/jobworkorder/updatejobworkorderrfid";
    return this.service.post(url, data).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

  getallstockjournalreport(data): Observable<ResponseModel> {
    let url = `api/app/stockjournalmovement/getallstockjournalreport`;
    const json = JSON.stringify(data);
    return this.service.post(url, data, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    )
  }

  getJobworkInvoiceByJobworkOrderId(model, id: number) {
    let url = 'api/app/JobworkOrder/GetById';
    var data: any = { id: id };
    return this.service.post(url, data, false).pipe<ResponseModel>(tap((response: any) => {
      if (response.isSuccess == true) {
        response.response.paymentList = [];
        this.storageService.getInvoice.next(response);
        this.storageService.setSelect2Dropdown.next(response.response.invoiceModel.accountLedgerId != null ? response.response.invoiceModel.accountLedgerId.toString() : "0")
      }
      return response;
    })
    );
  }

  processDataForJobworkInvoice(model) {
    let url = 'api/app/JobworkInvoice/ProcessDataForJobworkInvoice';
    var data: any = { ItemsToProcess: model };
    return this.service.post(url, data).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

  updateJobworkOrderStatus(data: any) {
    let url = 'api/app/JobWorkOrder/updateStatus';
    return this.service.post(url, data, false).pipe<ResponseModel>(tap((response: any) => {
      if (response.isSuccess == true) {
      }
      return response;
    })
    );
  }

  updateJobworkOrderPriority(data: any) {
    let url = 'api/app/JobWorkOrder/updatePriority';
    return this.service.post(url, data, false).pipe<ResponseModel>(tap((response: any) => {
      if (response.isSuccess == true) {
      }
      return response;
    })
    );
  }

  getAll(data, voucher) {
    let url = 'api/app/' + voucher + '/getall' + voucher;
    return this.service.post(url, data).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }





}
