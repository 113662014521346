import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'relativeTime'
})
export class RelativeTimePipe implements PipeTransform {
  selectedLanguage: any ="en";
  constructor(){
  }
  transform(utcTimestamp: string): string {
    const utcTime = new Date().getTime() + new Date().getTimezoneOffset() * 60000;

    const cleanedString = this.removeZFromISOString(utcTimestamp);
    const diffInSeconds = Math.floor((utcTime - new Date(cleanedString).getTime()) / 1000);

    if (diffInSeconds < 60) {
      if(this.selectedLanguage?.code == 'es'){
        return `${diffInSeconds} ${diffInSeconds !== 1 ? 'hace segundos' : 'Hace segunda'}`;
      }
      return `${diffInSeconds} second${diffInSeconds !== 1 ? 's' : ''} ago`;
    } else if (diffInSeconds < 3600) {
      const minutes = Math.floor(diffInSeconds / 60);
      if(this.selectedLanguage?.code == 'es'){
        return `${minutes} ${minutes !== 1 ? 'hace minutos' : 'hace un minuto'}`;
      }
      return `${minutes} minute${minutes !== 1 ? 's' : ''} ago`;
    } else if (diffInSeconds < 86400) {
      const hours = Math.floor(diffInSeconds / 3600);
      if(this.selectedLanguage?.code == 'es'){
        return `${hours} ${hours !== 1 ? 'horas atras' : 'hace una hora'}`;
      }
      return `${hours} hour${hours !== 1 ? 's' : ''} ago`;
    } else {
      const days = Math.floor(diffInSeconds / 86400);
      if(this.selectedLanguage?.code == 'es'){
        return `${days} ${days !== 1 ? 'hace días' : 'hace un dia'}`;
      }
      return `${days} day${days !== 1 ? 's' : ''} ago`;
    }
  }


  removeZFromISOString(dateString) {
    if (dateString.endsWith('Z')) {
        return dateString.slice(0, -1);
    }
    return dateString;
  }
  
}

