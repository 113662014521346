import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-custom-ngb-dropdown',
  templateUrl: './custom-ngb-dropdown.component.html',
  styleUrls: ['./custom-ngb-dropdown.component.scss']
})
export class CustomNgbDropdownComponent {

  @Input() selectValue;
  @Input() dropdownClass:string='';
  @Input() dropdownGrid:boolean=false;
  @Input() options=[
    {
      name:'John smith',
      id  : 1
  },
    {
      name:'robert',
      id  : 2
  }
]

  onSelect(item){
    this.selectValue = item?.name
  }
}