<!-- <span>{{params.rowIndex+1}} <span> -->
<div
  *ngIf="params?.data?.isExpandable"
  class="container-fluid mt-4 pt-1 w-100 px-0 main-container-div">
  <div class="row" style="--bs-gutter-x: 2.5rem !important;">
    <div class="col-12 px-0 br-top custom-bg">
      <div class="tableFixHead ">
      <table class="table">
        <thead>
          <tr>
            <th scope="col" class="pt-1 pb-0 text-start">Payment Type</th>
            <th scope="col" class="pt-1 pb-0 text-start">Metal Name</th>
            <th scope="col" class="pt-1 pb-0 text-start">Product</th>
            <th scope="col" class="pt-1 pb-0 text-end" >Against</th>
            <th scope="col" class="pt-1 pb-0 text-end">Cheque Dt.</th>
            <th scope="col" class="pt-1 pb-0 text-end">Trans. No.</th>
            <th scope="col" class="pt-1 pb-0 text-end">Weight</th>
            <th scope="col" class="pt-1 pb-0 text-end">Purity%</th>
            <th scope="col" class="pt-1 pb-0 text-end">Fine Wt.</th>
            <th scope="col" class="pt-1 pb-0 text-end">Rate</th>
            <th scope="col" class="pt-1 pb-0 text-end">Amount</th>

          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of receiptDetails" style="line-height: 1.5;">
            <td class="pt-0 pb-1 text-start">{{item.paymentMethodId ? item.paymentTypeName : "_" }}</td>
            <td class="pt-0 pb-1 text-start">{{item.metalName ? item.metalName : "_"}}</td>
            <td class="pt-0 pb-1 text-start">{{item.item ? item.item: "_"}}</td>
            <td class="pt-0 pb-1 text-end">{{item.against ? item.against : "_"}}</td>
            <td class="pt-0 pb-1 text-end">{{item.chequeDate | date: 'dd/MM/yyyy'}}</td>
            <td class="pt-0 pb-1 text-end">{{item.transactionNo ? item.transactionNo : "_"}}</td>
            <td class="pt-0 pb-1 text-end">{{item.grossWt ? item.grossWt.toFixed(2) : "0.00"}}</td>
            <td class="pt-0 pb-1 text-end">{{item.purityPer ? item.purityPer : "0"}}</td>
            <td class="pt-0 pb-1 text-end">{{item.purityWt ? item.purityWt.toFixed(2) : "0.00"}}</td>
            <td class="pt-0 pb-1 text-end">{{item.rate  ? item.rate.toFixed(2) : "0.00"}}</td>
            <td class="pt-0 pb-1 text-end">{{item.amount ? item.amount.toFixed(2) : "0.00"}}</td>
          </tr>
          <!-- <tr *ngIf="receiptDetails.length==0">
            <td class="py-1 text-start"> _</td>
            <td class="py-1 text-start"> _</td>
            <td class="py-1 text-start"> _</td>
            <td class="py-1 text-end">   _</td>
            <td class="py-1 text-end">   _</td>
            <td class="py-1 text-end">  0.00</td>
            <td class="py-1 text-end">  0.00</td>
            <td class="py-1 text-end">  0.00</td>
            <td class="py-1 text-end">  0.00</td>
            <td class="py-1 text-end">  0.00</td>
          </tr> -->
        </tbody>
      </table>
      </div>
    </div>
  </div>
</div>

<div
  *ngIf="params?.data?.isExpandableManufacturingProcess && this.manufacturingProcessDetails"
  class="container-fluid m-t-26 w-100 px-0 main-container-div pointer_none expandable_div">
  <div class="row" style="--bs-gutter-x: 2.5rem !important;">
    <div class="col-12 px-0 br-top custom-bg">
      <div class="tableFixHead ">
      <table class="table">
        <thead>
          <tr>
            <th scope="col" width="10%" class="pt-1 pb-0 text-start">Product Name</th>
            <th scope="col" width="30%"class="pt-1 pb-0 text-end">Gross Weight</th>
            <th scope="col" width="30%"class="pt-1 pb-0 text-end">Purity Weight</th>
            <th scope="col" width="30%" class="pt-1 pb-0 text-end">Purity%</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of manufacturingProcessDetails" style="line-height: 1.5;">
            <td width="10%" class="pt-0 pb-1 text-start">{{item?.name ? item?.name: "-"}}</td>
            <td width="30%" class="pt-0 pb-1 text-end">{{item?.grossWt ? item?.grossWt.toFixed(2) : "0.00"}}</td>
            <td width="30%" class="pt-0 pb-1 text-end">{{item?.purityWt ? item?.purityWt.toFixed(2) : "0.00"}}</td>
            <td width="30%" class="pt-0 pb-1 text-end">{{item?.purityPer ? item?.purityPer : "0"}}</td>
          </tr>
        </tbody>
      </table>
      </div>
    </div>
  </div>
</div>

<div
  *ngIf="params?.data?.isExpandableLoansGrid"
  class="container-fluid mt-4 pt-1 w-100 px-0 main-container-div">
  <div class="row" style="--bs-gutter-x: 2.5rem !important;">
    <div class="col-12 px-0 br-top custom-bg">
      <div class="tableFixHead ">
      <table class="table">
        <thead>
          <tr>
            <th scope="col" class="pt-1 pb-0 text-start">Description</th>
            <th scope="col" class="pt-1 pb-0 text-end">Qty</th>
            <th scope="col" class="pt-1 pb-0 text-end">Gross Wt.</th>
            <th scope="col" class="pt-1 pb-0 text-end" >Net Wt.</th>
            <th scope="col" class="pt-1 pb-0 text-end">Wt. In %</th>
            <th scope="col" class="pt-1 pb-0 text-end">Final Wt.</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of LoanDetails" style="line-height: 1.5;">
            <td class="pt-0 pb-1 text-start">{{item?.description}}</td>
            <td class="pt-0 pb-1 text-end">{{item?.qty}}</td>
            <td class="pt-0 pb-1 text-end">{{item?.grossWt}} </td>
            <td class="pt-0 pb-1 text-end">{{item?.netWt}} </td>
            <td class="pt-0 pb-1 text-end">{{item?.wtInPercent}} </td>
            <td class="pt-0 pb-1 text-end">{{item?.finalWt }}</td>
          </tr>
        </tbody>
      </table>
      </div>
    </div>
  </div>
</div>