import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PlusIconService {
  private plusIconClickedSource = new Subject<string>();
  plusIconClicked$ = this.plusIconClickedSource.asObservable();

  emitPlusIconClick(params: any) {
    this.plusIconClickedSource.next(params);
  }
  constructor() { }
}
