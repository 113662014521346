<ng-container >
    <div class="modal-body mx-3 px-4">
        <div class="save_icon text-center d-flex justify-content-center align-items-center">
            <svg id="Component_77_1" data-name="Component 77 – 1" xmlns="http://www.w3.org/2000/svg" width="55.062" height="58.998" viewBox="0 0 55.062 58.998">
                <path id="Path_2464" data-name="Path 2464" d="M-476.463-860.477a3.329,3.329,0,0,0-.961-2.5q-4.952-4.952-9.9-9.905a3.262,3.262,0,0,0-2.439-.949q-18.524.012-37.047.007a4.438,4.438,0,0,0-4.71,4.683q0,12.412,0,24.824v24.761a4.449,4.449,0,0,0,4.734,4.724H-481.2a4.446,4.446,0,0,0,4.733-4.722Q-476.469-840.015-476.463-860.477Zm-39.331-5.79c0-1.184.485-1.657,1.692-1.659h20.21c1.211,0,1.692.491,1.693,1.721q0,3.131,0,6.266a1.626,1.626,0,0,1-1.846,1.844q-9.953,0-19.9,0a1.626,1.626,0,0,1-1.844-1.846Q-515.8-863.1-515.794-866.267Zm27.527,43.541a1.8,1.8,0,0,1-2.022,1.994q-6.846,0-13.695,0t-13.695,0a1.808,1.808,0,0,1-2.048-2.028q0-8.046,0-16.093a3.375,3.375,0,0,1,3.526-3.508q12.22,0,24.441,0a3.371,3.371,0,0,1,3.49,3.482Q-488.261-830.8-488.267-822.726Z" transform="translate(531.524 873.83)" fill="#1492e6"/>
            </svg>
        </div>
        <div>
            <p class="title text-gray-color font-semibold f-16 m-0 text-center">Save Changes</p>
            <p class="sub-info font-regular text-lighter-gray d-flex align-items-center f-14 mb-2 justify-content-center text-center">Do you want to save Invoice?</p>
        </div>
    </div>
    <div class="modal-footer border-0">
        <div class="col d-flex align-items-center justify-content-center cursorPointer m-0" (click)="onSave()"><span class="font-semibold text-color-danger f-16">Yes</span></div>
        <div class="col d-flex align-items-center justify-content-center cursorPointer m-0" (click)="activeModal.close(false)"><span class="text-gray-color font-semibold f-16">No</span></div>
        <div class="col d-flex align-items-center justify-content-center cursorPointer m-0" (click)="activeModal.close(false)"><span class="font-semibold text-color-sky-blue f-16">Save as Draft</span></div>
    </div>
</ng-container>