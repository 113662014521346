import { Component, OnInit, Renderer2, ViewChild } from '@angular/core';
import { CommonService } from '../../services/common.service';
import { AdministrationService } from 'src/app/components/administration/administration.service';
import { ToastrService } from 'ngx-toastr';
import { NgForm } from '@angular/forms';
import { catchError } from 'rxjs';
import { GetAllCutResponseModel } from 'src/app/components/administration/administration.model';
import { SendDataService } from '../../services/send-data.service';

@Component({
  selector: 'app-cut',
  templateUrl: './cut.component.html',
  styleUrls: ['./cut.component.scss']
})
export class CutComponent extends CommonService implements OnInit {
  isAdd                           : boolean = false;
  getAllCutData              : any[]=[];

  copyGetAllCutData          : any[]=[];
  editCutData                = new GetAllCutResponseModel();
  @ViewChild('administrationFormData') administrationFormData! : NgForm ;

constructor(
  public renderer:Renderer2,
  public administrationService : AdministrationService,
  public toaster: ToastrService,
  public sendDataService : SendDataService,
) 
{
  super(renderer)
}
ngOnInit(): void {
  this.getAllCutList();
  }

add(){
  if(!this.sendDataService?.permissionVM?.mastersCanAdd) return;
  this.isAdd = true;
    const obj = {id:0, name:'',active:true}
    this.getAllCutData?.push(obj);
    this.copyGetAllCutData?.push(obj);
    setTimeout(()=>{
      const element = document.getElementById(`cut${obj.id}`);
      element?.focus();
    },0)
}

cancel(){
  if(this.isAdd)
  {
    this.isAdd = false;
    this.getAllCutData = this.getAllCutData.filter((x)=>x.id != 0)
    this.copyGetAllCutData = this.copyGetAllCutData.filter((x)=>x.id != 0);
  }
  else if(this.editCutData?.id && this.editCutData?.isEdit){
    const index = this.copyGetAllCutData.findIndex((x)=>x.id == this.editCutData?.id);
    const singleData =JSON.parse(JSON.stringify(this.copyGetAllCutData))[index];
    this.getAllCutData[index] = singleData;
    this.editCutData = new GetAllCutResponseModel();
  }  
}

edit(item,formData){
  if(!this.sendDataService?.permissionVM?.mastersCanUpdate) return;
  item.isEdit = true;
  const controlObj = formData?.form?.controls;
  if(controlObj){
    const id = Object.keys(controlObj)[0];
    const element = document.getElementById(id);
    element?.focus();
  }
  this.editCutData = {...item};
}

checkIsEdit(arr:any[]):boolean{
  return arr?.some((x)=>x.isEdit)
}

    // *********** cut API START *********
    // **********get all cut *********
    getAllCutList() {
      this.administrationService.getAllCut().subscribe({
        next:(res)=>{
          if(res.isSuccess){
             this.editCutData = new GetAllCutResponseModel();
            this.getAllCutData = res.response??[];            
            this.copyGetAllCutData = JSON.parse(JSON.stringify(this.getAllCutData))??[];
          }else{           
            this.getAllCutData = [];
            this.copyGetAllCutData = [];
          }  
        },
        error:(err)=>{
          this.getAllCutData = [];
          this.copyGetAllCutData = [];
        }
      })
    }

    // *************insert cut **************
       InserCutList(item) {
        const {id , ...rest}= item;
        this.administrationService.insertCut(rest)
            .pipe(catchError((err) => this.handleError(err)))
            .subscribe((res) => {
              if(res.isSuccess){
                this.isAdd = false;
                this.getAllCutList();
                this.toaster.success(res.message);
              }else{
                this.toaster.error(res.errors[0]);
              }  
          });
        }
    
    // **********update cut *********
    UpdateCutList(item) {
      const {isEdit , ...rest} = item;
      this.administrationService.updateCut(rest)
          .pipe(catchError((err) => this.handleError(err)))
          .subscribe((res) => {
            if(res.isSuccess){
              this.isAdd = false;
              this.getAllCutList();
              this.toaster.success(res.message);
            }else{
              this.toaster.error(res.errors[0]);
            }  
        });
      } 

      // **********delete cut *********
      DeleteCutList(id) {
      if(!this.sendDataService?.permissionVM?.mastersCanDelete) return;
        this.administrationService.deleteCut(id)
            .pipe(catchError((err) => this.handleError(err)))
            .subscribe((res) => {
              if(res.isSuccess){
                this.getAllCutList();
                this.toaster.success(res.message);
              }else{
                this.toaster.error(res.errors[0]);
              }  
        });
      }
}
