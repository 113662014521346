<select2
#select2Ref
id="ag-selec2-2" 
[overlay]="overlay"
[data]="data3"
[value]="id"
id="selec2-2"
(search)="search('search1', $event)" 
customSearchEnabled
required>
</select2>