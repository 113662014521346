import { StorageName } from 'src/app/shared/enum/common-enum';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs';
import { DataService } from '../../services/data.service';
import { ResponseModel } from '../../models/response-model.model';
import { StorageService } from '../../services/storage.service';

@Injectable({
  providedIn: 'root'
})
export class LoadDropdownDataService {
  storageName = StorageName;
  constructor(public service:DataService,public storageService:StorageService) { }
  
  getInvoiceNo(FormName){
   
    let url = `api/app/VoucherNumber/getVoucherNumber?VoucherName=${FormName}`;
    return this.service.get(url,true).pipe<ResponseModel>(tap((response: any) => {
    return response;
      })
    );
  }
  getAllDropdownDataFormWise(formName:string){
    let url = `api/App/Dropdown/getAllDropdowns?formName=${formName}`;
    return this.service.get(url,true).pipe<ResponseModel>(tap((response: any) => {
        var getAllDropdownModel = response.response;
        this.storageService.store(StorageName.DEPARTMENT_DROPDOWN,getAllDropdownModel.getDepartmentDropdown);
        this.storageService.store(StorageName.UDF_PROPERTY_DROPDOWN,getAllDropdownModel.udfPropertyDropdown);
        this.storageService.store(StorageName.UDF1_DROPDOWN,getAllDropdownModel.udF1Dropdown);
        this.storageService.store(StorageName.UDF2_DROPDOWN,getAllDropdownModel.udF2Dropdown);
        this.storageService.store(StorageName.UDF3_DROPDOWN,getAllDropdownModel.udF3Dropdown);
        this.storageService.store(StorageName.UDF4_DROPDOWN,getAllDropdownModel.udF4Dropdown);
        this.storageService.store(StorageName.UDF5_DROPDOWN,getAllDropdownModel.udF5Dropdown);
        this.storageService.store(StorageName.UDF11_DROPDOWN,getAllDropdownModel.udF11Dropdown);
        this.storageService.store(StorageName.UDF12_DROPDOWN,getAllDropdownModel.udF12Dropdown);
        this.storageService.store(StorageName.UDF13_DROPDOWN,getAllDropdownModel.udF13Dropdown);
        this.storageService.store(StorageName.UDF14_DROPDOWN,getAllDropdownModel.udF14Dropdown);
        this.storageService.store(StorageName.UDF15_DROPDOWN,getAllDropdownModel.udF15Dropdown);
        this.storageService.store(StorageName.UDF21_DROPDOWN,getAllDropdownModel.udF21Dropdown);
        this.storageService.store(StorageName.UDF22_DROPDOWN,getAllDropdownModel.udF22Dropdown);
        this.storageService.store(StorageName.UDF23_DROPDOWN,getAllDropdownModel.udF23Dropdown);
        this.storageService.store(StorageName.UDF24_DROPDOWN,getAllDropdownModel.udF24Dropdown);
        this.storageService.store(StorageName.UDF25_DROPDOWN,getAllDropdownModel.udF25Dropdown);
        this.storageService.store(StorageName.UDF26_DROPDOWN,getAllDropdownModel.udF26Dropdown);
        this.storageService.store(StorageName.UDF27_DROPDOWN,getAllDropdownModel.udF27Dropdown);
        this.storageService.store(StorageName.UDF28_DROPDOWN,getAllDropdownModel.udF28Dropdown);
        this.storageService.store(StorageName.UDF29_DROPDOWN,getAllDropdownModel.udF29Dropdown);
        this.storageService.store(StorageName.UDF30_DROPDOWN,getAllDropdownModel.udF30Dropdown);
        // this.storageService.store(StorageName.DIAMOND_CATEGORY_DROPDOWN,getAllDropdownModel.diamondCategoryDropdown);
        // this.storageService.store(StorageName.JOBWORK_PRIORITY_DROPDOWN,getAllDropdownModel.jobWorkPriorityDropdown);
        // this.storageService.store(StorageName.JOBWORK_STATUS_DROPDOWN,getAllDropdownModel.jobWorkStatusDropdown);
        this.storageService.store(StorageName.PURCHASEINVOICENO_DROPDOWN,getAllDropdownModel.purchaseInvoiceNoDropdown);
        this.storageService.store(StorageName.ACCOUNTLEDGER_DROPDOWN,getAllDropdownModel.accountLedgerDropdown);
        this.storageService.store(StorageName.ACTIVITYTYPE_DROPDOWN,getAllDropdownModel.activityTypeDropdown);
        this.storageService.store(StorageName.ACCOUNTGROUP_DROPDOWN, getAllDropdownModel.accountGroupDropdown);
        this.storageService.store(StorageName.AGAINST_DROPDOWN, getAllDropdownModel.againstDropdown);
        // this.storageService.store(StorageName.APPLICATIONON_DROPDOWN, getAllDropdownModel.applicableOnDropdown);
        this.storageService.store(StorageName.BANK_ACCOUNT_DROPDOWN, getAllDropdownModel.bankAccountDropdown);
        this.storageService.store(StorageName.BARCODE_TOOLBOXKEYDROPDOWN, getAllDropdownModel.barcodeToolBoxKeyDropdown);
        // this.storageService.store(StorageName.BAUD_DROPDOWN, getAllDropdownModel.baudDropdown);
        this.storageService.store(StorageName.BRANCH_DROPDOWN, getAllDropdownModel.branchDropdown);
        this.storageService.store(StorageName.BUCKET_DROPDOWN, getAllDropdownModel.bucketDropdown);
        this.storageService.store(StorageName.CARAT_DROPDOWN, getAllDropdownModel.caratDropdown);
        this.storageService.store(StorageName.CATEGORY_DROPDOWN, getAllDropdownModel.categoryDropdown);
        this.storageService.store(StorageName.CITY_DROPDOWN, getAllDropdownModel.cityDropdown);
        this.storageService.store(StorageName.COLLECTION_DROPDOWN, getAllDropdownModel.collectionDropdown);
        this.storageService.store(StorageName.CUSTOMER_DROPDOWN,getAllDropdownModel.customerDropdown);
        // this.storageService.store(StorageName.CALCULATION_TYPE_DROPDOWN,getAllDropdownModel.calculationTypeDropdown); 
        // this.storageService.store(StorageName.CALCULATION_DROPDOWN,getAllDropdownModel.calculationDropdown);
        this.storageService.store(StorageName.COLOR_DROPDOWN, getAllDropdownModel.colorDropdown);
        this.storageService.store(StorageName.COMPANY_DROPDOWN, getAllDropdownModel.companyDropdown);
        this.storageService.store(StorageName.CURRENCY_DROPDOWN, getAllDropdownModel.currencyDropdown);
        this.storageService.store(StorageName.CURRENCY_EXCHANGE, getAllDropdownModel.currencyExchangeDropdown);
        this.storageService.store(StorageName.CUT_DROPDOWN, getAllDropdownModel.cutDropdown);
        // this.storageService.store(StorageName.DATABITS_DROPDOWN, getAllDropdownModel.dataBitsDropdown);
        this.storageService.store(StorageName.DEPARTMENT_DROPDOWN, getAllDropdownModel.departmentDropdown);
        this.storageService.store(StorageName.DISTRICT_DROPDOWN, getAllDropdownModel.districtDropdown);
        this.storageService.store(StorageName.DOCUMENT_TYPE_DROPDOWN, getAllDropdownModel.documentTypeDropdown);
        this.storageService.store(StorageName.DEPOSIT_INTO_DROPDOWN,getAllDropdownModel.depositIntoDropdown);
        this.storageService.store(StorageName.FINANCIAL_DROPDOWN, getAllDropdownModel.financialYearDropdown);
        this.storageService.store(StorageName.FONTFAMILY_DROPDOWN, getAllDropdownModel.fontFamilyDropdown);
        // this.storageService.store(StorageName.FONTSIZE_DROPDOWN, getAllDropdownModel.fontSizeDropdown);
        // this.storageService.store(StorageName.IMPLEMENTATIONON_DROPDOWN, getAllDropdownModel.implementationOnDropdown);
        this.storageService.store(StorageName.INVOICE_NO_DROPDOWN, getAllDropdownModel.invoiceNo);
        // this.storageService.store(StorageName.LABEL_SIZE_DROPDOWN, getAllDropdownModel.labelSizeDropdown);
        this.storageService.store(StorageName.LEDGER_NAME_DROPDOWN, getAllDropdownModel.ledgerNameDropdown);
        // this.storageService.store(StorageName.MAKINGCHARGESTYPE_DROPDOWN, getAllDropdownModel.makingChargesTypeDropdown);
        // this.storageService.store(StorageName.MAKING_ON_DROPDOWN, getAllDropdownModel.makingOnDropdown);
        // this.storageService.store(StorageName.METHOD_OF_VOUCHERING_NUMBER_DROPDOWN, getAllDropdownModel.methodOfVoucheringNumberDropdown);
        this.storageService.store(StorageName.METAL_DROPDOWN, getAllDropdownModel.metalDropdown);
        this.storageService.store(StorageName.NO_OF_COPY_DROPDOWN, getAllDropdownModel.noOfCopyDropdown);
        // this.storageService.store(StorageName.ORDER_NO_DROPDOWN, getAllDropdownModel.orderNoDropdown);
        this.storageService.store(StorageName.SALEORDERPROCESS_BARCODENO_DROPDOWN, getAllDropdownModel.saleOrderProcessBarcodeNoDropdown);
        this.storageService.store(StorageName.SALEORDERPROCESS_ORDERNO_DROPDOWN, getAllDropdownModel.saleOrderProcessOrderNoDropdown);
        this.storageService.store(StorageName.JOBWORKORDER_NO_DROPDOWN, getAllDropdownModel.jobworkOrderNoDropdown);
        this.storageService.store(StorageName.SALEORDER_NO_DROPDOWN, getAllDropdownModel.saleOrderNoDropdown);
        // this.storageService.store(StorageName.PARITY_DROPDOWN, getAllDropdownModel.parityDropdown);
        // this.storageService.store(StorageName.PAYMENTTYPE_DROPDOWN, getAllDropdownModel.paymentTypeDropdown);
        // this.storageService.store(StorageName.PDC_STATUS_DROPDOWN, getAllDropdownModel.pdcStatusDropdown);
        // this.storageService.store(StorageName.PDC_VOUCHER_DROPDOWN, getAllDropdownModel.pdcVoucherDropdown);
        // this.storageService.store(StorageName.PORT_DROPDOWN, getAllDropdownModel.portDropdown);
        this.storageService.store(StorageName.PURITYGROUP_DROPDOWN,getAllDropdownModel.purityDropdown);
        this.storageService.store(StorageName.PRODUCT_DROPDOWN, getAllDropdownModel.productDropdown);
        this.storageService.store(StorageName.PURPOSE_ACCOUNTLEDGER_DROPDOWN, getAllDropdownModel.purposeAccountLedgerDropdown);
        // this.storageService.store(StorageName.REDEEM_ON_DROPDOWN, getAllDropdownModel.redeemOnDropdown);
        // this.storageService.store(StorageName.REGISTRATIONTYPE_DROPDOWN, getAllDropdownModel.registrationTypeDropdown);
        this.storageService.store(StorageName.REMARK_DROPDOWN, getAllDropdownModel.remarkDropdown);
        this.storageService.store(StorageName.REPORT_INVOICE_DROPDOWN, getAllDropdownModel.voucherTypeDropdown);
        this.storageService.store(StorageName.ROLE_DROPDOWN, getAllDropdownModel.roleDropdown);
        // this.storageService.store(StorageName.ROTATION_DROPDOWN, getAllDropdownModel.rotationDropdown);
        // this.storageService.store(StorageName.SALEMODE_DROPDOWN, getAllDropdownModel.saleModeDropdown);
        this.storageService.store(StorageName.SALEPERSON_DROPDOWN, getAllDropdownModel.salePersonDropdown);
        this.storageService.store(StorageName.SEIVE_SIZE_DROPDOWN, getAllDropdownModel.seiveSizeDropdown);
        this.storageService.store(StorageName.SHAPE_DROPDOWN, getAllDropdownModel.shapeDropdown);
        this.storageService.store(StorageName.SIZE_DROPDOWN, getAllDropdownModel.sizeDropdown);
        this.storageService.store(StorageName.STATE_DROPDOWN, getAllDropdownModel.stateDropdown);
        // this.storageService.store(StorageName.STOPBITS_DROPDOWN, getAllDropdownModel.stopBitsDropdown);
        this.storageService.store(StorageName.TALUKA_DROPDOWN, getAllDropdownModel.talukaDropdown);
        this.storageService.store(StorageName.TAX_DROPDOWN, getAllDropdownModel.taxDropdown);
        this.storageService.store(StorageName.TEMPLATE_TOOLBOXKEYDROPDOWN, getAllDropdownModel.templateToolBoxKeyDropdown);
        // this.storageService.store(StorageName.TRANSACTION_TYPE_DROPDOWN, getAllDropdownModel.transactionTypeDropdown);
        this.storageService.store(StorageName.UNIT_CONVERSION_DROPDOWN, getAllDropdownModel.unitConversionDropdown);
        this.storageService.store(StorageName.UNIT_DROPDOWN, getAllDropdownModel.unitDropdown);
        this.storageService.store(StorageName.USER_DROPDOWN, getAllDropdownModel.userDropdown);
        this.storageService.store(StorageName.VOUCHER_TYPE_DROPDOWN, getAllDropdownModel.voucherTypeDropdown);
        this.storageService.store(StorageName.ALL_ACCOUNTLEDGER_DROPDOWN, getAllDropdownModel.allAccountLedgerDropdown);
        // this.storageService.store(StorageName.PAGENAME_DROPDOWN, getAllDropdownModel.pageNameDropdown);
        // this.storageService.store(StorageName.STATUS_DROPDOWN, getAllDropdownModel.statusCodeDropdown);
        // this.storageService.store(StorageName.EVENTTYPE_DROPDOWN, getAllDropdownModel.eventTypeDropdown);
        // this.storageService.store(StorageName.MASTERS_DROPDOWN, getAllDropdownModel.mastersDropdown);
        this.storageService.store(StorageName.STYLE_DROPDOWN, getAllDropdownModel.styleDropdown);
        this.storageService.store(StorageName.ITEM_CODE_DROPDOWN, getAllDropdownModel.itemCodeDropdown);
        this.storageService.store(StorageName.CLARITY_DROPDOWN, getAllDropdownModel.clarityDropdown);
        // this.storageService.store(StorageName.FIELDTYPES_DROPDOWN, getAllDropdownModel.fieldTypesDropdown);
        this.storageService.store(StorageName.USERDEFINEFIELD_DROPDOWN, getAllDropdownModel.udfDropdown);
        // this.storageService.store(StorageName.WEBSITE_URL_DROPDOWN, getAllDropdownModel.websiteURLDropdown);
        this.storageService.store(StorageName.GRIDNAME_DROPDOWN, getAllDropdownModel.gridNameDropdown);
        // this.storageService.store(StorageName.NOTIFICATION_SERVICETYPE_DROPDOWN, getAllDropdownModel.notificationServiceTypeDropdown);
        this.storageService.store(StorageName.CLOSING_TIME_DROPDOWN, getAllDropdownModel.closingTimeDrodown);
        // this.storageService.store(StorageName.LEDGERTYPE_DROPDOWN, getAllDropdownModel.ledgerTypeDropdown);
        this.storageService.store(StorageName.CREDITCARD_DROPDOWN, getAllDropdownModel.creditCardDropdown);
        this.storageService.store(StorageName.COUNTRY_DROPDOWN, getAllDropdownModel.countryDropdown);
        this.storageService.store(StorageName.STORED_ZEBRAZPL_DROPDOWN, getAllDropdownModel.zebraZPLDropdown);
        this.storageService.store(StorageName.TEMPLATE_TOOLBOX_DROPDOWN, getAllDropdownModel.barcodeToolBoxKeyDropdown);
        this.storageService.store(StorageName.VOUCHERTYPE_DROPDOWN, getAllDropdownModel.voucherTypeDropdown);
        this.storageService.store(StorageName.SCHEME_NAME_DROPDOWN,getAllDropdownModel.schemeNameDropdown);
        this.storageService.store(StorageName.EXPENSES_CATEGORY_DROPDOWN,getAllDropdownModel.expensesCategoryDropdown);
        // this.storageService.store(StorageName.LOAN_DURATIONMONTH_DROPDOWN,getAllDropdownModel.durationMonthDropdown);
        this.storageService.store(StorageName.CLOSINGDATE_DROPDOWN ,getAllDropdownModel.closingDateDropdown)
        this.storageService.store(StorageName.LOAN_REASON_DROPDOWN, getAllDropdownModel.loanReasonsDropdown)
        this.storageService.store(StorageName.LOAN_PRODUCT_TYPE_DROPDOWN, getAllDropdownModel.loanProductTypeDropdown)
        this.storageService.store(StorageName.LOCATION_DROPDOWN ,getAllDropdownModel.locationDropdown)
        this.storageService.store(StorageName.EXPENSES_LEDGER_CATEGORY_DROPDOWN ,getAllDropdownModel?.expensesLedgerCategoryDropdown)
        this.storageService.store(StorageName.SALEQUOTATION_NODROPDOWN ,getAllDropdownModel?.saleQuotationNoDropdown)
        this.storageService.store(StorageName.DELIVERYNOTE_NODROPDOWN ,getAllDropdownModel?.deliveryNoteNoDropdown)
        this.storageService.store(StorageName.AGAINST_JWQ_DROPDOWN ,getAllDropdownModel?.againstJWQDropdown)
        this.storageService.store(StorageName.CUSTOMER_NAME_DROPDOWN ,getAllDropdownModel?.customerNameDropdown)

         return response;
      })
    );
  }

  getDropdownData(storageName:string){
    let url = `api/App/Dropdown/getDropdown?storageName=${storageName}`;
    return this.service.get(url,true).pipe<ResponseModel>(tap((response: any) => {
        var list = response.response;
        switch(storageName){
          case this.storageName.ACCOUNTLEDGER_DROPDOWN:
            this.storageService.store(storageName,list);
          break;
          case this.storageName.ALL_ACCOUNTLEDGER_DROPDOWN:
            this.storageService.store(storageName,list);
          break;
        }
        return response;
      })
    );
  }

  
}
