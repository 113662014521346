import { AgColDefsI, AgGridI } from "../../../../shared/common-components/ag-grid/ag-grid.model";
import { StorageName } from "../../../../shared/enum/common-enum";
import { CheckboxColumn } from "../../../../shared/common-components/metal-section/metal-section.model";
import { TextRendererComponent } from "../../../../shared/common-components/ag-grid/text-renderer/text-renderer.component";
import { CheckboxRendererComponent } from "src/app/shared/common-components/ag-grid/checkbox-renderer/checkbox-renderer.component";
import { AgGridCustomDropdownComponent } from "src/app/shared/common-components/ag-grid/ag-grid-custom-dropdown/ag-grid-custom-dropdown.component";
import { StorageService } from "src/app/shared/services/storage.service";
import { AgGridCustomInputComponent } from "src/app/shared/common-components/ag-grid/ag-grid-custom-input/ag-grid-custom-input.component";
import { CustomHeaderComponent } from "src/app/shared/common-components/ag-grid/custom-header/custom-header.component";
import { EditDeleteIconComponent } from "src/app/shared/common-components/ag-grid/edit-delete-icon/edit-delete-icon.component";
import { MetalType } from "src/app/shared/models/common.model";
import { API_URL_GET_ALL_DIAMOND_STOCK } from "src/app/shared/url-helper/url-helper";

const ItemCodeColumn: AgColDefsI = {
    headerName: "Item Code",
    field: "itemCode",
    minWidth: 100,
    headerClass: "custom-column-group-cell px-0",
    cellClass: "custom-column-group-cell",
    editable: false,
    sortable: true,
    isRequired:true,
    cellRenderer: TextRendererComponent,
   
  };

  const ItemCode: AgColDefsI = {
    headerName: "Item Code",
    field: "itemCode",
    minWidth: 100,
    headerClass: "custom-column-group-cell px-0",
    cellClass: "custom-column-group-cell",
    editable: true,
    sortable: true,
    isRequired:true,
    cellRenderer: TextRendererComponent,
   
  };
  const BarcodeNoColumn: AgColDefsI = {
    headerName: "Barcode No",
    field: "barcodeNo",
    minWidth: 100,
    headerClass: "custom-column-group-cell px-0",
    cellClass: "custom-column-group-cell",
    editable: false,
    sortable: false,
    isRequired:true,
    cellRenderer: TextRendererComponent
  };

const RateColumn: AgColDefsI = {
    headerName: "Rate",
    field: "rate",
    minWidth: 100,
    headerClass: "custom-column-group-cell px-0",
    cellClass: "custom-column-group-cell",
    editable: false,
    sortable: false,
    isRequired:true,
    cellRenderer: TextRendererComponent
  };

const StyleColumn: AgColDefsI = {
    headerName: "Style",
    field: "style",
    minWidth: 100,
    headerClass: "custom-column-group-cell px-0",
    cellClass: "custom-column-group-cell",
    editable: false,
    sortable: true,
    isRequired:true,
    cellRenderer: TextRendererComponent
    
  };
  
const DiamondCategoryColumn: AgColDefsI = {
    headerName: "Diamond Category",
    field: "diamondCategoryName",
    minWidth: 100,
    headerClass: "custom-column-group-cell px-0",
    cellClass: "custom-column-group-cell",
    editable: false,
    sortable: true,
    isRequired:true,
    cellRenderer: TextRendererComponent,
   
  };

const CalculationTypeColumn: AgColDefsI = {
    headerName: "Calculation Type",
    field: "calculationTypeName",
    minWidth: 100,
    headerClass: "custom-column-group-cell px-0",
    cellClass: "custom-column-group-cell",
    editable: false,
    sortable: true,
    isRequired:true,
    cellRenderer: TextRendererComponent,
   
  };

const ProductColumn: AgColDefsI = {
    headerName: "Product",
    field: "product",
    minWidth: 100,
    headerClass: "custom-column-group-cell px-0",
    cellClass: "custom-column-group-cell",
    editable: false,
    sortable: true,
    isRequired:true,
 
    cellRenderer: TextRendererComponent
  };

const GoldCaratColumn: AgColDefsI = {
    headerName: "Gold Carat",
    field: "goldCarat",
    minWidth: 100,
    headerClass: "custom-column-group-cell px-0",
    cellClass: "custom-column-group-cell",
    editable: false,
    sortable: true,
    isRequired:true,
  
    cellRenderer: TextRendererComponent,
    
  };

const GrossWtColumn: AgColDefsI = {
    headerName: "Gross Wt",
    field: "grossWt",
    minWidth: 100,
    headerClass: "custom-column-group-cell px-0",
    cellClass: "custom-column-group-cell",
    editable: false,
    sortable: false,
    isRequired:true,
  
    cellRenderer: TextRendererComponent
  }; 

const WeightColumn: AgColDefsI = {
    headerName: "Weight",
    field: "weight",
    minWidth: 100,
    headerClass: "custom-column-group-cell px-0",
    cellClass: "custom-column-group-cell",
    editable: false,
    sortable: false,
    isRequired:true,
    cellRenderer: TextRendererComponent,
   
  }; 

const QuantityColumn: AgColDefsI = {
    headerName: "Quantity",
    field: "quantity",
    minWidth: 100,
    headerClass: "custom-column-group-cell px-0",
    cellClass: "custom-column-group-cell",
    editable: false,
    sortable: false,
    isRequired:true,
    cellRenderer: TextRendererComponent,
  
  }; 

const CertificateNumberColumn: AgColDefsI = {
    headerName: "Certificate Number",
    field: "certificateNumber",
    minWidth: 100,
    headerClass: "custom-column-group-cell px-0",
    cellClass: "custom-column-group-cell",
    editable: false,
    sortable: false,
    isRequired:true,
    cellRenderer: TextRendererComponent,
  
  };   
  
const CutColumn: AgColDefsI = {
    headerName: "Cut",
    field: "cut",
    minWidth: 100,
    headerClass: "custom-column-group-cell px-0",
    cellClass: "custom-column-group-cell",
    editable: false,
    sortable: true,
    isRequired:true,
  
    cellRenderer: TextRendererComponent,
  };  

const DiamondCaratColumn: AgColDefsI = {
    headerName: "Diamond Carat",
    field: "purityWt",
    minWidth: 100,
    headerClass: "custom-column-group-cell px-0",
    cellClass: "custom-column-group-cell",
    editable: false,
    sortable: false,
    isRequired:true,
    cellRenderer: TextRendererComponent,
  
  }; 

const ColorColumn: AgColDefsI = {
    headerName: "Color",
    field: "color",
    minWidth: 100,
    headerClass: "custom-column-group-cell px-0",
    cellClass: "custom-column-group-cell",
    editable: false,
    sortable: true,
    isRequired:true,
   
    cellRenderer: TextRendererComponent,
  };  

const SeiveSizeColumn: AgColDefsI = {
    headerName: "SeiveSize",
    field: "seiveSize",
    minWidth: 100,
    headerClass: "custom-column-group-cell px-0",
    cellClass: "custom-column-group-cell",
    editable: false,
    sortable: true,
    isRequired:true,
 
    cellRenderer: TextRendererComponent,
  };  


const SizeColumn: AgColDefsI = {
    headerName: "Size",
    field: "size",
    minWidth: 100,
    headerClass: "custom-column-group-cell px-0",
    cellClass: "custom-column-group-cell",
    editable: false,
    sortable: true,
    isRequired:true,
 
    cellRenderer: TextRendererComponent, 
  };  

const ShapeColumn: AgColDefsI = {
    headerName: "Shape",
    field: "shape",
    minWidth: 100,
    headerClass: "custom-column-group-cell px-0",
    cellClass: "custom-column-group-cell",
    editable: false,
    sortable: true,
    isRequired:true,
   
    cellRenderer: TextRendererComponent,
  };  

const QualityColumn: AgColDefsI = {
    headerName: "Quality",
    field: "clarity",
    minWidth: 100,
    headerClass: "custom-column-group-cell px-0",
    cellClass: "custom-column-group-cell",
    editable: false,
    sortable: true,
    isRequired:true,
    cellRenderer: TextRendererComponent,
  
  };  

export const ColumnDefForAddDiamondTable :AgColDefsI[] =[
    CheckboxColumn,
    ItemCodeColumn,
    BarcodeNoColumn,
    StyleColumn,
    DiamondCategoryColumn,
    CalculationTypeColumn,
    ProductColumn,
    GoldCaratColumn,
    GrossWtColumn,
    WeightColumn,
    DiamondCaratColumn,
    QuantityColumn,
    RateColumn,
    CertificateNumberColumn,
    CutColumn,
    ColorColumn,
    SeiveSizeColumn,
    SizeColumn,
    ShapeColumn,
    QualityColumn
  ]

  const ColumnDefForAddStoneGemsTable :AgColDefsI[] =[
    CheckboxColumn,
    ItemCodeColumn,
    BarcodeNoColumn,
    StyleColumn,
    CalculationTypeColumn,
    ProductColumn,
    GoldCaratColumn,
    GrossWtColumn,
    WeightColumn,
    DiamondCaratColumn,
    QuantityColumn,
    RateColumn,
    CertificateNumberColumn,
    CutColumn,
    ColorColumn,
    SeiveSizeColumn,
    SizeColumn,
    ShapeColumn,
    QualityColumn
  ]
//#region add diamond popup


export const agGridDataModelAddDiamondTable: AgGridI = {
    colDefs: ColumnDefForAddDiamondTable,
    rowSelection: "multiple",
    storageName : StorageName.TRANSACTION_ADD_DIAMOND_IN_PAYMENT_GRID,
    filterStorageName: StorageName.FILTER_TRANSACTION_ADD_DIAMOND_IN_PAYMENT_GRID,
    sortStorageName: StorageName.SORT_TRANSACTION_ADD_DIAMOND_IN_PAYMENT_GRID,
    initialRows: StorageName.TRANSACTION_ADD_DIAMOND_IN_PAYMENT_GRID_INITIAL_ROWS,
    addBlankRowOnEnter:true,
    columnSettings:true,
    singleClickEdit:true,
    rowHeight:28,
    headerHeight : 70,
    rows:[],
    isFilter: true, 
    isServerSidepagination : false, 
    apiUrl :  API_URL_GET_ALL_DIAMOND_STOCK,
  };

  //#endregion 

  const BarcodeNo: AgColDefsI = {
    headerName: "Barcode No.",
    headerComponentParams: { isRequired: false ,enableSorting: false},
    field: "barcodeNo",
    sortable: true,
    minWidth: 110,
    editable: false,
    isRequired: false,
    cellClass: "custom-column-group-cell px-0 text-center",
    headerClass: "custom-column-group-cell px-0",
    cellRenderer: TextRendererComponent,
  }

  export const Style: AgColDefsI = {
    headerName: "Style",
    field: "styleId",
    minWidth: 120,
    sortable: true,
    editable: true,
    cellRenderer: TextRendererComponent,
    cellEditor: AgGridCustomDropdownComponent,
    headerComponentParams: {isRequired: false, enableSorting: false},
    cellEditorParams: {
      values: () => {
        const storageService = new StorageService();
        const storage = StorageName;
        return storageService.retrieve(storage.STYLE_DROPDOWN);
      },
    },
    cellClass: "custom-column-group-cell px-0",
    headerClass: "custom-column-group-cell px-0",
  }
  const DiamondCategory: AgColDefsI = {
    headerName: "Diamond Category",
    field: "diamondCategoryId",
    minWidth: 100,
    sortable: true,
    editable: true,
    cellRenderer: TextRendererComponent,
    cellEditor: AgGridCustomDropdownComponent,
    headerComponentParams: { enableSorting: false},
    cellEditorParams: {
      values: () => {
        const storageService = new StorageService();
        const storage = StorageName;
        return storageService.retrieve(storage.DIAMOND_CATEGORY_DROPDOWN);
      },
    },
    cellClass: "custom-column-group-cell px-0",
    headerClass: "custom-column-group-cell px-0",
  };

  const CalculationType: AgColDefsI = {
    headerName: "Calculation Type",
    field: "calculationType",
    minWidth: 100,
    sortable: true,
    editable: true,
    cellRenderer: TextRendererComponent,
    cellEditor: AgGridCustomDropdownComponent,
    headerComponentParams: { enableSorting: false},
    cellEditorParams: {
      values: () => {
        const storageService = new StorageService();
        const storage = StorageName;
        return storageService.retrieve(storage.CALCULATION_TYPE_DROPDOWN);
      },
    },
    cellClass: "custom-column-group-cell px-0",
    headerClass: "custom-column-group-cell px-0",
  };
  export const ProductDiamond: AgColDefsI = {
    headerName: "Product",
    headerComponentParams:{
      filterValueColumnName: "productId",
      filterIdColumnName: "productId",
      filterParams: StorageName.PRODUCT_DROPDOWN,
      floatingMultiselectDropDown: true,
      isRequired: true, enablePlusIconLight: true , enableSorting: false
  },
  field: "productDetailId",
  minWidth: 110,
  sortable: true,
  editable: true,
  cellRenderer: TextRendererComponent,
  cellEditor: AgGridCustomDropdownComponent,
  cellEditorParams: {
    values: () => {
      const storageService = new StorageService();
      const storage = StorageName;
     var dropdownValues =  storageService.retrieve(storage.PRODUCT_DROPDOWN)
      return dropdownValues.filter(a=>a.extra1 == MetalType.Diamond.toString());
    },
  },
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0",
};

export const ProductStone: AgColDefsI = {
  headerName: "Product",
  headerComponentParams:{
    filterValueColumnName: "productId",
    filterIdColumnName: "productId",
    filterParams: StorageName.PRODUCT_DROPDOWN,
    floatingMultiselectDropDown: true,
    isRequired: true, enablePlusIconLight: true , enableSorting: false
},
field: "productDetailId",
minWidth: 110,
sortable: true,
editable: true,
cellRenderer: TextRendererComponent,
cellEditor: AgGridCustomDropdownComponent,
cellEditorParams: {
  values: () => {
    const storageService = new StorageService();
    const storage = StorageName;
    return storageService.retrieve(storage.PRODUCT_DROPDOWN).filter(a=>a.extra1 == MetalType.StoneGEMS.toString());
  },
},
cellClass: "custom-column-group-cell px-0",
headerClass: "custom-column-group-cell px-0",
};

const GoldCarat: AgColDefsI = {
  headerName: "Gold Carat",
  headerComponentParams: { isRequired: false, enablePlusIconLight: true , enableSorting: false},
  field: "goldCaratId",
  minWidth: 100,
  sortable: true,
  editable: true,
  cellRenderer: TextRendererComponent,
  cellEditor: AgGridCustomDropdownComponent,
  cellEditorParams: {
    values: () => {
      const storageService = new StorageService();
      const storage = StorageName;
      return storageService.retrieve(storage.CARAT_DROPDOWN);
    },
  },
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0",
}

const GrossWt: AgColDefsI = {
  headerName: "Gross Wt",
  field: "grossWt",
  minWidth: 120,
  sortable: true,
  editable: true,
  resizable: false,
  cellRenderer: TextRendererComponent,
  headerComponentParams: {isRequired: false, enableSorting: false},
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0",
  cellEditor:AgGridCustomInputComponent,
  cellEditorParams:{
    decimalPlaces:3,
    isOnlyNumbers:true,
    inputMaxlength:10
  }
}

export const Weight: AgColDefsI = {
  headerName: "Weight",
  field: "weight",
  minWidth: 90,
  headerClass: "custom-column-group-cell px-0",
  cellClass: "custom-column-group-cell",
  editable: true,
  sortable: true,
  cellRenderer: TextRendererComponent,
  cellEditor:AgGridCustomInputComponent,
  headerComponentParams: { enableSorting: false},
  cellEditorParams:{
    decimalPlaces:3,
    isOnlyNumbers:true,
    inputMaxlength:8
  }
}

export const DiamondCarat: AgColDefsI = {
  headerName: "Diamond Carat",
  headerComponentParams: { isRequired: false , enableSorting: false},
  field: "carat",
  sortable: true,
  minWidth: 110,
  editable: true,
  // isRequired: true,
  cellClass: "custom-column-group-cell px-0 text-center",
  headerClass: "custom-column-group-cell px-0",
  cellRenderer: TextRendererComponent,
  cellEditor:AgGridCustomInputComponent,
  cellEditorParams:{
    decimalPlaces:3,
    isOnlyNumbers:true,
    inputMaxlength:8
  }
};

export const Quantity: AgColDefsI = {
  headerName: "Quantity",
  field: "quantity",
  minWidth: 120,
  headerComponentParams: { isRequired: true , enableSorting: false},
  headerClass: "custom-column-group-cell px-0",
  cellClass: "custom-column-group-cell",
  editable: true,
  sortable: true,
  isRequired:true,
  cellRenderer: TextRendererComponent,
  cellEditor:AgGridCustomInputComponent,
  cellEditorParams:{
    isOnlyNumbers:true,
  },
  valueGetter: (param) => {
    return param.data.quantity !== undefined && param.data.quantity !== null? param.data.quantity: 1;
  }
};

export const Rate: AgColDefsI = {
  headerName: "Rate",
  field: "rate",
  minWidth: 100,
  headerComponentParams: { isRequired: true ,enableSorting: false},
  headerClass: "custom-column-group-cell px-0",
  cellClass: "custom-column-group-cell",
  editable: true,
  sortable: true,
  isRequired:true,
  cellRenderer: TextRendererComponent,
  cellEditor:AgGridCustomInputComponent,
  cellEditorParams:{
    decimalPlaces:2,
    isOnlyNumbers:true,
  }
};
const CertificateNumber: AgColDefsI = {
  headerName: "Certificate No.",
  field: "certificateNumber",
  minWidth: 90,
  headerClass: "custom-column-group-cell px-0",
  cellClass: "custom-column-group-cell",
  editable: true,
  sortable: true,
  cellRenderer: TextRendererComponent,
};
export const Cut: AgColDefsI = {
  headerName: "Cut",
  field: "cutId",
  minWidth: 100,
  sortable: true,
  editable: true,
  cellRenderer: TextRendererComponent,
  cellEditor: AgGridCustomDropdownComponent,
  headerComponentParams: { enableSorting: false},
  cellEditorParams: {
    values: () => {
      const storageService = new StorageService();
      const storage = StorageName;
      return storageService.retrieve(storage.CUT_DROPDOWN);
    },
  },
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0",
};

export const Color: AgColDefsI = {
  headerName: "Color",
  field: "colorId",
  minWidth: 100,
  sortable: true,
  editable: true,
  cellRenderer: TextRendererComponent,
  cellEditor: AgGridCustomDropdownComponent,
  headerComponentParams: { enableSorting: false},
  cellEditorParams: {
    values: () => {
      const storageService = new StorageService();
      const storage = StorageName;
      return storageService.retrieve(storage.COLOR_DROPDOWN);
    },
  },
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0",
};

const SeiveSize: AgColDefsI = {
  headerName: "SeiveSize",
  field: "seiveSizeId",
  minWidth: 100,
  sortable: true,
  editable: true,
  cellRenderer: TextRendererComponent,
  cellEditor: AgGridCustomDropdownComponent,
  headerComponentParams: { enableSorting: false},
  cellEditorParams: {
    values: () => {
      const storageService = new StorageService();
      const storage = StorageName;
      return storageService.retrieve(storage.SEIVE_SIZE_DROPDOWN);
    },
  },
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0",
};
export const Size: AgColDefsI = {
  headerName: "Size",
  field: "sizeId",
  minWidth: 100,
  sortable: true,
  editable: true,
  cellRenderer: TextRendererComponent,
  cellEditor: AgGridCustomDropdownComponent,
  headerComponentParams: { enableSorting: false},
  cellEditorParams: {
    values: () => {
      const storageService = new StorageService();
      const storage = StorageName;
      return storageService.retrieve(storage.SIZE_DROPDOWN);
    },
  },
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0",
};

const Shape: AgColDefsI = {
  headerName: "Shape",
  field: "shapeId",
  minWidth: 100,
  sortable: true,
  editable: true,
  cellRenderer: TextRendererComponent,
  cellEditor: AgGridCustomDropdownComponent,
  headerComponentParams: { enableSorting: false},
  cellEditorParams: {
    values: () => {
      const storageService = new StorageService();
      const storage = StorageName;
      return storageService.retrieve(storage.SHAPE_DROPDOWN);
    },
  },
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0",
};

const Quality: AgColDefsI = {
  headerName: "Clarity",
  field: "clarityId",
  minWidth: 100,
  sortable: true,
  editable: true,
  cellRenderer: TextRendererComponent,
  cellEditor: AgGridCustomDropdownComponent,
  cellEditorParams: {
    values: () => {
      const storageService = new StorageService();
      const storage = StorageName;
      return storageService.retrieve(storage.CLARITY_DROPDOWN);
    },
  },
  cellClass: "custom-column-group-cell px-0",
  headerComponentParams: { enableSorting: false},
  headerClass: "custom-column-group-cell px-0",
};
const Action: AgColDefsI = {
  headerName: "",
  field: "action",
  pinned: "right",
  suppressNavigable: true,
  editable: false,
  lockPinned: true,
  headerComponent: CustomHeaderComponent,
  headerClass: "justify_content_start  custom-column-group-cell border-0 px-0",
  cellRenderer: EditDeleteIconComponent,
  cellRendererFramework: EditDeleteIconComponent,
  cellRendererParams: {
    editIcon: false,
    isFirstDeleteIcon:true,
    resetIcon:true
  },
  headerComponentParams: {  moreaddButton: true  },
  cellClass: "mx-0 text-end px-1",
  minWidth: 80,
  maxWidth: 80,
  extraField:"Action"
};
  export const ColumnDefForAddDiamondModelTable :AgColDefsI[]=[
    ItemCode,
    BarcodeNo,
    Style,
    DiamondCategory,
    CalculationType,
    ProductDiamond,
    GoldCarat,
    GrossWt,
    Weight,
    DiamondCarat,
    Quantity,
    Rate,
    CertificateNumber,
    Cut,
    Color,
    SeiveSize,
    Size,
    Shape,
    Quality,
    Action
  ]
  
  export const agGridDataModelAddDiamondModelTable: AgGridI = {
    colDefs: ColumnDefForAddDiamondModelTable,
    rowSelection: "multiple",
    storageName : StorageName.TRANSACTION_ADD_DIAMOND_IN_PAYMENT_BYAPI_GRID,
    addBlankRowOnEnter:true,
    columnSettings:true,
    singleClickEdit:true,
    rowHeight:28,
    rows:[]
  };


