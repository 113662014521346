<div class="product_details_container p-3">
    <div class="products_img_product_details d-flex">
        <div class="products_img d-flex gap-2">
            <div class="img_small d-flex flex-column gap-2">
                <ng-container *ngIf="jewelryCatalougeResourceList?.length">
                    <div class="img" *ngFor="let item of jewelryCatalougeResourceList">
                        <img (click)="displayPreview(item?.base64Resource)" *ngIf="item?.base64Resource" class="w-100 h-100 cursorPointer" [src]="item?.base64Resource" alt="jewellry image">
                    </div>
                </ng-container>
                <div *ngIf="!jewelryCatalougeResourceList?.length" class="img">
                    <img class="w-100 h-100" src="./assets/svg/nopreviewImage.svg" alt="jewellry image">
                </div>
            </div>

            <div class="preview_img">
                <img *ngIf="selectedImage" class="w-100 h-100 cursorPointer"  (click)="openImgPopUp(selectedImage)" [src]="selectedImage" alt="">
                <img *ngIf="!selectedImage" class="w-100 h-100" src="./assets/svg/nopreviewImage.svg" alt="jewellry preview image">
            </div>
        </div>
        <div class="product_details w-100 py-3">
            <div class="col border-bottom d-flex flex-column justify-content-center ">
                <h4 class="f-20 color-dark-gray font-semibold">{{catalogueModel?.title??'NA'}}</h4>
                <h4 class="f-25 text-primary font-semibold mt-1">{{catalogueModel?.amount?'₹':''}}{{catalogueModel?.amount ?? 'NA'}}</h4>
                <p class="short_description text-gray-color font-regular f-12 f-14 w-75 m-b-15">
                    {{catalogueModel?.shortDescription ??'NA'}}
                </p>
            </div>
            <div class="quantity_addtocart">
                <div class="col d-flex flex-column justify-content-center">
                    <h4 class="f-18 color-dark-gray font-semibold m-t-10 m-b-10">Quantity</h4>
                    <div class="set_quantity d-flex justify-content-center align-items-center">
                        <app-button (click)="addOrRemoveQuantity('minus')" class="" [buttonLabel]="'-'" [buttonClass]="'white_button'" [buttonType]="'submit'">
                        </app-button>
                        <input onlyNumbers class="border-0 text-center input_quantity" type="text" [(ngModel)]="quantity" maxlength="4" />
                        <app-button (click)="addOrRemoveQuantity('plus')" class="" [buttonLabel]="'+'" [buttonClass]="'white_button'" [buttonType]="'submit'">
                        </app-button>
                    </div>
                    <div class="d-flex gap-3 mt-4">
                        <app-button [disabled]="this.quantity<1" (click)="AddToCart(catalogueModel , jewelryCatalougeResourceList)" [buttonLabel]="'Add to Cart'" [buttonClass]="'blue_button'" [buttonType]="'submit'">
                        </app-button>
                        <app-button  (click)="Checkout()" class=""   [buttonLabel]="'CheckOut'" [buttonClass]="'dark_pink_button text-nowrap'"  [buttonType]="'button'">
                        </app-button>
                        <!-- [ngClass]="{'pe-none opacity-50': true}" -->
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="border-top my-3 mb-2"> </div>
    <div class="descripton_billMaterial mb-5">
            <ul class="jewelaryCatalogue_tabs gap-3 d-flex">
                <li class="text-gray-color" (click)="tabchanged(1)" [ngClass]="{'active':activeTabId == descBillOfMaterial?.Desc}">
                    <span class="f-16 font-semibold cursorPointer" >Description</span>
                </li>
                
                <li class="text-gray-color"  (click)="tabchanged(2)" [ngbNavItem]="2"  [disabled]="!descBillOfMaterial?.BillOfMaterial" [ngClass]="{'active':activeTabId == descBillOfMaterial?.BillOfMaterial}">
                    <span class=" f-16 font-semibold cursorPointer"  >Bill Of Material</span>
                </li> 
            </ul>
            <div class="render_tabs_container border-top">
                <div class="mt-2" *ngIf="activeTabId == descBillOfMaterial?.Desc">
                    <div [innerHTML]="catalogueModel?.fullDescription"></div>
                </div>
                <div class="mt-2" *ngIf="activeTabId == descBillOfMaterial?.BillOfMaterial">
                    <!-- <div [innerHTML]="catalogueModel?.fullDescription"></div> -->
                   <app-ag-grid 
                   [dataModel]="agGridDataModelProductDetails"
                   [rowData]="itemList"
                   [gridOptions]="gridOptionsProductDetails" 
                   >
                   </app-ag-grid>
                </div>
            </div>
    </div>
</div>
