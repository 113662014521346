<i *ngIf="visiblefeatherIcon()" [attr.data-feather]="icon"></i>

<svg *ngIf="icon == 'downArrow'" id="downArrow" xmlns="http://www.w3.org/2000/svg" width="8.095" height="4.048" viewBox="0 0 8.095 4.048">
    <path id="Path_1294" data-name="Path 1294" d="M-1841.061,5637.15l4.047,4.048,4.048-4.048" transform="translate(1841.061 -5637.15)" fill="#7c89a4"/>
</svg>

<svg  *ngIf="icon == 'dashboard'" id="dashboard" data-name="Component 86 – 3" xmlns="http://www.w3.org/2000/svg" width="23.748" height="23.748" viewBox="0 0 29.748 29.748">
    <g id="Group_41495" data-name="Group 41495">
      <path id="Path_3279" data-name="Path 3279" d="M435.326-6172.226q-5.136-.005-10.272,0a1.457,1.457,0,0,0-1.565,1.566q0,5.113,0,10.226a1.469,1.469,0,0,0,1.59,1.59q2.557,0,5.113,0c1.72,0,3.44,0,5.159,0a1.452,1.452,0,0,0,1.519-1.52q.006-5.16,0-10.319A1.458,1.458,0,0,0,435.326-6172.226Zm-1.465,10.4h-7.366v-7.4h7.366Z" transform="translate(-423.487 6172.229)" />
      <path id="Path_3280" data-name="Path 3280" d="M556.8-6158.846q5.113,0,10.226,0a1.468,1.468,0,0,0,1.591-1.589q0-2.533,0-5.067c0-1.735,0-3.47,0-5.206a1.454,1.454,0,0,0-1.52-1.519q-5.16-.007-10.319,0a1.452,1.452,0,0,0-1.542,1.541q-.005,5.136,0,10.272A1.465,1.465,0,0,0,556.8-6158.846Zm1.444-10.377h7.372v7.4h-7.372Z" transform="translate(-538.866 6172.23)"/>
      <path id="Path_3281" data-name="Path 3281" d="M435.337-6040.484q-5.159-.005-10.319,0a1.452,1.452,0,0,0-1.531,1.552q0,5.136,0,10.272a1.463,1.463,0,0,0,1.577,1.557q5.113,0,10.226,0a1.466,1.466,0,0,0,1.579-1.6q0-2.533,0-5.066c0-1.72,0-3.44,0-5.16A1.458,1.458,0,0,0,435.337-6040.484Zm-1.46,10.386h-7.383v-7.383h7.383Z" transform="translate(-423.485 6056.849)"/>
      <path id="Path_3282" data-name="Path 3282" d="M567.08-6040.484q-5.159-.005-10.319,0a1.452,1.452,0,0,0-1.531,1.552q0,5.136,0,10.272a1.463,1.463,0,0,0,1.577,1.557q5.113,0,10.226,0a1.466,1.466,0,0,0,1.579-1.6q0-2.533,0-5.066c0-1.72,0-3.44,0-5.16A1.459,1.459,0,0,0,567.08-6040.484Zm-1.478,10.376h-7.372v-7.4H565.6Z" transform="translate(-538.866 6056.849)" />
    </g>
</svg>
  