<div>
    <div class="modal-header d-flex align-items-center justify-content-center position-relative">
      <div><span class="font-semibold text-primary">{{modalTitle}}</span></div>
      <app-svg-icon [name]="'cross_button_border'" class="cross-button-border"></app-svg-icon>
      <app-svg-icon [name]="'cross'" [ngClass]="currentHoverEffect" class="cross-button" (click)="activeModal.dismiss()"></app-svg-icon>
    </div>
    <form class="theme-form" ngNativeValidate #AddEditBucketForm="ngForm">
      <div class="modal-body mx-4 py-0">
        <div class="row py-0">
          <div class="col-12 d-flex gap-4">
              <div class="border-right pt-2 pb-4">
                    <div class="col py-2 f-14 font-semibold text-gray-color d-flex align-items-center">
                        Actions
                    </div>
                    <div class="col py-2 d-flex">
                        <div class="w-175px f-13 font-regular text-gray-color d-flex align-items-center">
                            Next
                        </div>
                        <div class="col d-flex align-items-center">
                            <app-button class="my-0 me-3" [buttonLabel]="'Enter'" [buttonClass]="'blue_button keybord_button1'" [buttonType]="'button'"></app-button>
                        </div>
                    </div>
                    <div class="col py-1 d-flex">
                        <div class="w-175px f-13 font-regular text-gray-color d-flex align-items-center">
                            Close
                        </div>
                        <div class="col d-flex align-items-center">
                            <app-button class="my-0 me-3" [buttonLabel]="'Esc'" [buttonClass]="'blue_button keybord_button1'" [buttonType]="'button'"></app-button>
                        </div>
                    </div>
                    <div class="col py-2 d-flex">
                        <div class="w-175px f-13 font-regular text-gray-color d-flex align-items-center">
                            Add Product
                        </div>
                        <div class="col d-flex align-items-center">
                            <app-button class="my-0 me-3" [buttonLabel]="'Shift'" [buttonClass]="'blue_button keybord_button1'" [buttonType]="'button'"></app-button>
                        </div>
                        <div class="col d-flex align-items-center">
                            <app-button class="my-0 me-3" [buttonLabel]="'A'" [buttonClass]="'blue_button keybord_button2'" [buttonType]="'button'"></app-button>
                        </div>
                    </div>
                    <div class="col py-2 d-flex">
                        <div class="w-175px f-13 font-regular text-gray-color d-flex align-items-center">
                            Add Image
                        </div>
                        <div class="col d-flex align-items-center">
                            <app-button class="my-0 me-3" [buttonLabel]="'Shift'" [buttonClass]="'blue_button keybord_button1'" [buttonType]="'button'"></app-button>
                        </div>
                        <div class="col d-flex align-items-center">
                            <app-button class="my-0 me-3" [buttonLabel]="'I'" [buttonClass]="'blue_button keybord_button2'" [buttonType]="'button'"></app-button>
                        </div>
                    </div>
                    <div class="col py-2 d-flex">
                        <div class="w-175px f-13 font-regular text-gray-color d-flex align-items-center">
                            Add More Row
                        </div>
                        <div class="col d-flex align-items-center">
                            <app-button class="my-0 me-3" [buttonLabel]="'Shift'" [buttonClass]="'blue_button keybord_button1'" [buttonType]="'button'"></app-button>
                        </div>
                        <div class="col d-flex align-items-center">
                            <app-button class="my-0 me-3" [buttonLabel]="'M'" [buttonClass]="'blue_button keybord_button2'" [buttonType]="'button'"></app-button>
                        </div>
                    </div>
                    <div class="col py-2 d-flex">
                        <div class="w-175px f-13 font-regular text-gray-color d-flex align-items-center">
                            Save
                        </div>
                        <div class="col d-flex align-items-center">
                            <app-button class="my-0 me-3" [buttonLabel]="'Shift'" [buttonClass]="'blue_button keybord_button1'" [buttonType]="'button'"></app-button>
                        </div>
                        <div class="col d-flex align-items-center">
                            <app-button class="my-0 me-3" [buttonLabel]="'S'" [buttonClass]="'blue_button keybord_button2'" [buttonType]="'button'"></app-button>
                        </div>
                    </div>
                    <div class="col py-2 d-flex">
                        <div class="w-175px f-13 font-regular text-gray-color d-flex align-items-center">
                            New Invoice
                        </div>
                        <div class="col d-flex align-items-center">
                            <app-button class="my-0 me-3" [buttonLabel]="'Shift'" [buttonClass]="'blue_button keybord_button1'" [buttonType]="'button'"></app-button>
                        </div>
                        <div class="col d-flex align-items-center">
                            <app-button class="my-0 me-3" [buttonLabel]="'N'" [buttonClass]="'blue_button keybord_button2'" [buttonType]="'button'"></app-button>
                        </div>
                    </div>
                    <div class="col py-2 d-flex">
                        <div class="w-175px f-13 font-regular text-gray-color d-flex align-items-center">
                            Add Payment Row
                        </div>
                        <div class="col d-flex align-items-center">
                            <app-button class="my-0 me-3" [buttonLabel]="'Shift'" [buttonClass]="'blue_button keybord_button1'" [buttonType]="'button'"></app-button>
                        </div>
                        <div class="col d-flex align-items-center">
                            <app-button class="my-0 me-3" [buttonLabel]="'P'" [buttonClass]="'blue_button keybord_button2'" [buttonType]="'button'"></app-button>
                        </div>
                    </div>
              </div>
              <div class="col-5 pt-2 pb-4">
                    <div class="col py-2 f-14 font-semibold text-gray-color d-flex align-items-cente">
                        Metal Tabs
                    </div>
                    <div class="col py-2 d-flex">
                        <div class="w-175px f-13 font-regular text-gray-color d-flex align-items-center">
                            Gold
                        </div>
                        <div class="col d-flex align-items-center">
                            <app-button class="my-0 me-3" [buttonLabel]="'Shift'" [buttonClass]="'blue_button keybord_button1'" [buttonType]="'button'"></app-button>
                        </div>
                        <div class="col d-flex align-items-center">
                            <app-button class="my-0 me-3" [buttonLabel]="'1'" [buttonClass]="'blue_button keybord_button2'" [buttonType]="'button'"></app-button>
                        </div>
                    </div>
                    <div class="col py-2 d-flex">
                        <div class="w-175px f-13 font-regular text-gray-color d-flex align-items-center">
                            Silver
                        </div>
                        <div class="col d-flex align-items-center">
                            <app-button class="my-0 me-3" [buttonLabel]="'Shift'" [buttonClass]="'blue_button keybord_button1'" [buttonType]="'button'"></app-button>
                        </div>
                        <div class="col d-flex align-items-center">
                            <app-button class="my-0 me-3" [buttonLabel]="'2'" [buttonClass]="'blue_button keybord_button2'" [buttonType]="'button'"></app-button>
                        </div>
                    </div>
                    <div class="col py-2 d-flex">
                        <div class="w-175px f-13 font-regular text-gray-color d-flex align-items-center">
                            Diamond
                        </div>
                        <div class="col d-flex align-items-center">
                            <app-button class="my-0 me-3" [buttonLabel]="'Shift'" [buttonClass]="'blue_button keybord_button1'" [buttonType]="'button'"></app-button>
                        </div>
                        <div class="col d-flex align-items-center">
                            <app-button class="my-0 me-3" [buttonLabel]="'3'" [buttonClass]="'blue_button keybord_button2'" [buttonType]="'button'"></app-button>
                        </div>
                    </div>
                    <div class="col py-2 d-flex">
                        <div class="w-175px f-13 font-regular text-gray-color d-flex align-items-center">
                            Imitation
                        </div>
                        <div class="col d-flex align-items-center">
                            <app-button class="my-0 me-3" [buttonLabel]="'Shift'" [buttonClass]="'blue_button keybord_button1'" [buttonType]="'button'"></app-button>
                        </div>
                        <div class="col d-flex align-items-center">
                            <app-button class="my-0 me-3" [buttonLabel]="'4'" [buttonClass]="'blue_button keybord_button2'" [buttonType]="'button'"></app-button>
                        </div>
                    </div>
                    <div class="col py-2 d-flex">
                        <div class="w-175px f-13 font-regular text-gray-color d-flex align-items-center">
                            Stone
                        </div>
                        <div class="col d-flex align-items-center">
                            <app-button class="my-0 me-3" [buttonLabel]="'Shift'" [buttonClass]="'blue_button keybord_button1'" [buttonType]="'button'"></app-button>
                        </div>
                        <div class="col d-flex align-items-center">
                            <app-button class="my-0 me-3" [buttonLabel]="'5'" [buttonClass]="'blue_button keybord_button2'" [buttonType]="'button'"></app-button>
                        </div>
                    </div>
              </div>
          </div>
        </div>
      </div>
    </form>
  </div>
