import { ColumnCellType, StorageName } from "../../enum/common-enum";
import { DropdownModel } from "../../models/common.model";
export class AgGridI {
    colDefs: AgColDefsI[];
    rowSelection: "single" | "multiple";
    rows?:any[];
    storageName?: StorageName;
    filterStorageName?:StorageName;
    apiUrl? : string;
    sortStorageName? : StorageName;
    initialRows?: StorageName;
    paginationStorageName? : StorageName;
    addBlankRowOnEnter?:boolean;
    columnSettings?:boolean;
    singleClickEdit?:boolean;
    tableId? : StorageName;
    isServerSidepagination?: boolean = false;
    tabIndex?: number;
    showPagination?: boolean = false;
    showFilter? : boolean = false;
    headerHeight? : number;
    rowHeight? : number;
    isFilter?:boolean=false;
    floatingDropDown?:boolean=false;
    floatingMultiselectDropDown?: boolean =false;
    tableIndex?: number =0;
    filterIdColumnName?:string;
    moreButton?:boolean=true;
    selectAll?:boolean=true;
    editable?:boolean=true;
    headerComponentParams?:{};
    columnTypes?: any;
    columnCellType? : any;
 }

 export class AgColDefsI {
   wrapText?:boolean;
   autoHeight?:boolean;
    headerName?: string;
    headerClass?:string;
    footerClass?:string;
    isRequired?:boolean;
    field?: string;
    suppressDragLeaveHidesColumns?:boolean;
    suppressMovable?:boolean;
    suppressColumnMove?:boolean;
    lockPosition?: boolean | 'left' | 'right' | null;
    lockPinned?: boolean;
    width?: number;
    valueGetter?: any;
    valueSetter?: any;
    minWidth?: number;
    maxWidth?: number;  
    resizable?: boolean | false;
    editable?: any;
    suppressNavigable?: any;
    sortable?: boolean | false;
    isfilter?: boolean | false; // to display filter icon in header
    pinned?: boolean | 'left' | 'right' | null;
    headerCheckboxSelection?: boolean | false | any;
    checkboxSelection?: boolean | false | any;
    cellClass?: string;
    hide?: boolean;
    cellClassRules?:any;
    cellStyle?:any
    cellRenderer?:any;
    cellRendererParams?:any;
    cellEditor?:any;
    cellEditorFramework?:any
    headerComponentFramework?:any;
    headerGroupComponent?:any;
    suppressMenu?:boolean;
    cellRendererFramework?:any;
    headerComponentParams?:any;
    tooltipField?:any;
    flex?:number;
    children?:any;
    marryChildren?:boolean = true;
    headerComponent?:any;
    footerGroupComponent?:any;
    footerComponent?:any;
    cellEditorParams?:any;
    extraField?:string;
    filter?:string;
    valueFormatter?: string | any;
    filterParams?: string | any;
    filterStorageName?: string | any;
    sortStorageName?:string | any;
    paginationStorageName? :string | any;
    filterValueColumnName?:string | any;
    filterIdColumnName?:string|any;
   //  suppressRowClickSelection?: boolean | false;  // to hide default icon in header
    enableMenu?: boolean | false; // to hide default icon in header
    tabId?:any
    colId?:any
    suppressPaste?:boolean;
    rowDrag?:boolean;
    suppressMoveIntoParents?:boolean;
    cellRendererSelector?:any;
    isVisibleOnColumnSetting? = true
    columnCellType? : ColumnCellType = ColumnCellType.DefaultColumn;
    valueParser?: any;
    tooltipValueGetter?: any;
 }

 

 export function   getRequiredFieldNames(columnDefs: any[]): string[] {
   let requiredFieldNames: string[] = [];
   columnDefs?.forEach(column => {
     const colDef=column?.getColDef()
     if (colDef?.field && colDef?.isRequired) {
       requiredFieldNames?.push(colDef?.field);
     }
   //   if (colDef?.children) {
   //     const childFieldNames = this.getRequiredFieldNames(column.children);
   //     requiredFieldNames = requiredFieldNames.concat(childFieldNames);
   //   }
   });
   return requiredFieldNames;
 }

 export function areFieldsRequired(array: any[], fieldNames: any[]): boolean {
   return array?.every(item => fieldNames?.every(fieldName => item?.hasOwnProperty(fieldName) && item[fieldName] !== undefined && item[fieldName] !== '' && item[fieldName] !== 0 && item[fieldName] !== null));
 }

 export function getRowDataAndRequiredFields(params):{rowdata:any,requiredColumn:string[]}{
   const allColumn = params?.columnApi?.getAllColumns();
   const rowsToDisplay = params?.api?.getRenderedNodes()?.map(node => node?.data);
   const requiredField =getRequiredFieldNames(allColumn)
   return {rowdata:rowsToDisplay,requiredColumn:requiredField}
 }

export class Updategridcolumndefinition {
  id: number=0;
  gridName?: string;
  colDefs?: string;
}

export class Pagination {
  page: number;
  count: number;
  pageSize: any;
  totalPages: number;
  fromRecordNumber: number;
  toRecordNumber: number;

  constructor(
     page?: number,
     count?: number,
     pageSize?: any,
     totalPages?: number
  ) {
     this.page = page;
     this.count = count;
     this.pageSize = pageSize;
     this.totalPages = totalPages;
  }
}


export class PaginationModel  {
  page: number = 1;
  pageSize: any = defaultPageLimit;
  totalPages: number ;
  count: number ;
  getAll: boolean = false;
  id : string = "0"
  filterId: number;
  filters: string;
  filtersFromPopup: string;
  //filterList: any[];
  sortList:any[];
  showDeleted : boolean = false;
  roleId : number = 1;
  reloadGrid : boolean = false;
  reloadGridFilters : boolean = false;
  total_rows: string ;
  total_pages: string ;
  isDeletedCheckboxFilter: boolean = false;
  fromDate?:string;
  toDate?:string;
  constructor()
  {
    this.page = 1;
    this.pageSize = 25;

  }
}

export class paginationModel 
{
  sortColumn    : string | null = "";
  sortDirection : string | null = "";
  page          : number = 1;
  pageSize      : any = 25;
  searchText    : string | null = "";
  getAll        : boolean = false;
}

export class Filter {
  name: string;
  FilterData?:DropdownModel[];
  textFilter?:string;
  type?:string;
  DropDownFilter?:string;
}

export const defaultPageLimit = 25;

export enum GroupOp {
  AND = 0,
  OR = 1
}

export enum Operations {
  eq, // "equal"
  ne, // "not equal"
  lt, // "less"
  le, // "less or equal"
  gt, // "greater"
  ge, // "greater or equal"
  bw, // "between"
  bn, // "does not begin with"
  ew, // "ends with"
  en, // "does not end with"
  cn, // "contains"
  nc  // "does not contain"
}

export class Rule {
  field: string;
  op: Operations
  data: string
}

export class Group {
  groupOp: string;
  rules: Rule[];
}

export class Filters {
  constructor() {
     this.rules = [];
     this.groups = [];
  }
  rules: Rule[];
  groups: Group[];
}

export enum UDFField {
  UDF1 = "udF1",
  UDF2 = "udF2",
  UDF3 = "udF3",
  UDF4 = "udF4",
  UDF5 = "udF5",
  UDF6 = "udF6",
  UDF7 = "udF7",
  UDF8 = "udF8",
  UDF9 = "udF9",
  UDF10 = "udF10",
  UDF11 = "udF11",
  UDF12 = "udF12",
  UDF13 = "udF13",
  UDF14 = "udF14",
  UDF15 = "udF15",
  UDF16 = "udF16",
  UDF17 = "udF17",
  UDF18 = "udF18",
  UDF19 = "udF19",
  UDF20 = "udF20",
  UDF21 = "udF21",
  UDF22 = "udF22",
  UDF23 = "udF23",
  UDF24 = "udF24",
  UDF25 = "udF25",
  UDF26 = "udF26",
  UDF27 = "udF27",
  UDF28 = "udF28",
  UDF29 = "udF29",
  UDF30 = "udF30",
}