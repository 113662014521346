import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';

@Component({
  selector: 'app-select-with-icon',
  templateUrl: './select-with-icon.component.html',
  styleUrls: ['./select-with-icon.component.scss'],
  viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ],

})
export class SelectWithIconComponent {
  @Input() id:string;
  @Input() name:string;
  @Input() isRequired:boolean;
  @Input() isDisabled:boolean;
  @Input() options: any[] = [];
  @Input() selectedValue: string = '';
  @Input() SelectLabel:string;
  @Input() selectPlaceholder:string;
  @Input() showregistrationIcon:boolean =false;
  @Input() showcampaignGroupIcon:boolean =false;
  @Input() showGroupIcon: boolean = false;
  @Input() labelName:boolean =false;
  @Input() className: string = ''; 


  @Output() valueChanged = new EventEmitter<string>();
}
