import { Component, OnInit, Renderer2, ViewChild } from '@angular/core';
import { CommonService } from '../../services/common.service';
import { NgForm } from '@angular/forms';
import { AdministrationService } from 'src/app/components/administration/administration.service';
import { ToastrService } from 'ngx-toastr';
import { catchError } from 'rxjs';
import { GetAllCounterResponseModel } from 'src/app/components/administration/administration.model';
import { SendDataService } from '../../services/send-data.service';

@Component({
  selector: 'app-counter',
  templateUrl: './counter.component.html',
  styleUrls: ['./counter.component.scss']
})
export class CounterComponent extends CommonService implements OnInit{
  isAdd                           : boolean = false;
  getAllCounterData              : any[]=[];

  copyGetAllCounterData          : any[]=[];
  editCounterData                = new GetAllCounterResponseModel();
  @ViewChild('administrationFormData') administrationFormData! : NgForm ;

constructor(
  public renderer:Renderer2,
  public administrationService : AdministrationService,
  public toaster: ToastrService,
  public sendDataService : SendDataService,
) 
{
  super(renderer)
}
ngOnInit(): void {
  this.getAllCounterList();
  }

add(){
  if(!this.sendDataService?.permissionVM?.mastersCanAdd) return; if(!this.sendDataService?.permissionVM?.mastersCanAdd) return;
  this.isAdd = true;
    const obj = {id:0, name:'',active:true}
    this.getAllCounterData?.push(obj);
    this.copyGetAllCounterData?.push(obj);
    setTimeout(()=>{
      const element = document.getElementById(`Countername${obj.id}`);
      element?.focus();
    },0)
}

cancel(){
  if(this.isAdd)
  {
    this.isAdd = false;
    this.getAllCounterData = this.getAllCounterData.filter((x)=>x.id != 0);
    this.copyGetAllCounterData = this.copyGetAllCounterData.filter((x)=>x.id != 0);
  }
  else if(this.editCounterData?.id && this.editCounterData?.isEdit){
    const index = this.copyGetAllCounterData.findIndex((x)=>x.id == this.editCounterData?.id);
    const singleData =JSON.parse(JSON.stringify(this.copyGetAllCounterData))[index];
    this.getAllCounterData[index] = singleData;
    this.editCounterData = new GetAllCounterResponseModel();
  }  
}

edit(item,formData){
  if(!this.sendDataService?.permissionVM?.mastersCanUpdate) return;
  item.isEdit = true;
  const controlObj = formData?.form?.controls;
  if(controlObj){
    const id = Object.keys(controlObj)[0];
    const element = document.getElementById(id);
    element?.focus();
  }
  this.editCounterData = {...item};
}


checkIsEdit(arr:any[]):boolean{
  return arr?.some((x)=>x.isEdit)
}


    // *********** Counter API START *********
    // **********get all Counter *********
    getAllCounterList() {
      this.administrationService.getAllCounter().subscribe({
        next:(res)=>{
          if(res.isSuccess){
            this.editCounterData = new GetAllCounterResponseModel();
            this.getAllCounterData = res.response??[];
            this.copyGetAllCounterData = JSON.parse(JSON.stringify(this.getAllCounterData))??[];
          }else{           
            this.getAllCounterData = [];  
            this.copyGetAllCounterData = [];
          }  
        },
        error:(err)=>{
          this.getAllCounterData = [];  
          this.copyGetAllCounterData = [];
        }
      })
    }
    
    
       // *************insert Counter **************
       InserCounterList(item) {
        const {id , ...rest}= item;
        this.administrationService.insertCounter(rest)
            .pipe(catchError((err) => this.handleError(err)))
            .subscribe((res) => {
              if(res.isSuccess){
                this.isAdd = false;
                this.getAllCounterList();
                this.toaster.success(res.message);
              }else{
                this.toaster.error(res.errors[0]);
              }  
          });
        }
    
    // **********update Counter *********
    UpdateCounterList(item) {
      const {isEdit , ...rest} = item;
      this.administrationService.updateCounter(rest)
          .pipe(catchError((err) => this.handleError(err)))
          .subscribe((res) => {
            if(res.isSuccess){
              this.isAdd = false;
              this.getAllCounterList();
              this.toaster.success(res.message);
            }else{
              this.toaster.error(res.errors[0]);
            }  
        });
      } 

      // **********delete Counter *********
      DeleteCounterList(id) {
        if(!this.sendDataService?.permissionVM?.mastersCanDelete) return;
        this.administrationService.deleteCounter(id)
            .pipe(catchError((err) => this.handleError(err)))
            .subscribe((res) => {
              if(res.isSuccess){
                this.getAllCounterList();
                this.toaster.success(res.message);
              }else{
                this.toaster.error(res.errors[0]);
              }  
        });
      }
          
}
