import { Component, OnInit, QueryList, Renderer2, ViewChild, ViewChildren } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AdministrationService } from 'src/app/components/administration/administration.service';
import { CommonService } from '../../services/common.service';
import { catchError } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { GetAllCaratResponseModel } from 'src/app/components/administration/administration.model';
import { SendDataService } from '../../services/send-data.service';

@Component({
  selector: 'app-carat',
  templateUrl: './carat.component.html',
  styleUrls: ['./carat.component.scss']
})

export class CaratComponent extends CommonService implements OnInit{
  @ViewChild('administrationFormData') administrationFormData! : NgForm;
  getAllCaratData             : any[]=[];
  getAllPurityData            : any[]=[];

  copyGetAllCaratData           : any[]=[]
  editCaratData                = new GetAllCaratResponseModel();
  isAdd                       : boolean = false;
  
  constructor(
    public renderer:Renderer2,
    public administrationService : AdministrationService,
    public toaster: ToastrService,
    public sendDataService : SendDataService,
  ) 
  {
    super(renderer)
  }
  
  ngOnInit(): void {
    this.getAllCaratList();
  }


add(){
  if(!this.sendDataService?.permissionVM?.mastersCanAdd) return;
  this.isAdd = true;
  const obj = {id:0, name:'',purityPer:'', description:'',active:true}
  this.getAllCaratData?.push(obj);
  this.copyGetAllCaratData?.push(obj);
  setTimeout(()=>{
    const element = document.getElementById(`caratname${obj.id}`);
    element?.focus();
  },0)
}


cancel(){
  if(this.isAdd)
  {
    this.isAdd = false;
    this.getAllCaratData = this.getAllCaratData.filter((x)=>x.id != 0);
    this.copyGetAllCaratData = this.copyGetAllCaratData.filter((x)=>x.id != 0);
  }
  else if(this.editCaratData?.id && this.editCaratData?.isEdit){
    const index = this.copyGetAllCaratData.findIndex((x)=>x.id == this.editCaratData?.id);
    const singleData =JSON.parse(JSON.stringify(this.copyGetAllCaratData))[index];
    this.getAllCaratData[index] = singleData;
    this.editCaratData = new GetAllCaratResponseModel();
  }  
}


edit(item,formData){
  if(!this.sendDataService?.permissionVM?.mastersCanUpdate) return;
  item.isEdit = true;
  const controlObj = formData?.form?.controls;
  if(controlObj){
    const id = Object.keys(controlObj)[0];
    const element = document.getElementById(id);
    element?.focus();
  }
   this.editCaratData = {...item};
}

checkIsEdit(arr:any[]):boolean{
  return arr?.some((x)=>x.isEdit)
}

  // *********** CARAT API START *********
  // **********get all carat *********
  getAllCaratList() {
    this.administrationService.getAllCaratData = [];
    this.administrationService.getAllCarat().subscribe({
      next:(res)=>{
        if(res.isSuccess){
          this.getAllCaratData = res.response??[];
          this.administrationService.getAllCaratData = JSON.parse(JSON.stringify(this.getAllCaratData.filter((x)=>x.active == true)))??[];
          this.editCaratData = new GetAllCaratResponseModel();
          this.copyGetAllCaratData = JSON.parse(JSON.stringify(this.getAllCaratData))??[];
        }else{           
          this.getAllCaratData = [];
          this.copyGetAllCaratData = [];
        }  
      },
      error:(err)=>{
        this.getAllCaratData = [];
        this.administrationService.getAllCaratData = [];
        this.copyGetAllCaratData = [];
      }
    })
  }

 // *************insert carat **************
  InserCaratList(item) {
    const {id , ...rest} = item;
    this.administrationService.insertCarat(rest)
        .pipe(catchError((err) => this.handleError(err)))
        .subscribe((res) => {
          if(res.isSuccess){
            this.isAdd = false;
            this.getAllCaratList();
            this.toaster.success(res.message);
          }else{
            this.toaster.error(res.errors[0]);
          }  
      });
    }

  // **********update carat *********
    UpdateCaratList(item) {
    const {isEdit , ...rest} = item;
    this.administrationService.updateCarat(rest)
        .pipe(catchError((err) => this.handleError(err)))
        .subscribe((res) => {
          if(res.isSuccess){
            this.isAdd = false;
            this.getAllCaratList();
            this.toaster.success(res.message);
          }else{
            this.toaster.error(res.errors[0]);
          }  
      });
    }  

    // **********delete carat *********
    DeleteCaratList(id) {
      if(!this.sendDataService?.permissionVM?.mastersCanDelete) return;
      this.administrationService.deleteCarat(id)
          .pipe(catchError((err) => this.handleError(err)))
          .subscribe((res) => {
            if(res.isSuccess){
              this.getAllCaratList();
              this.toaster.success(res.message);
            }else{
              this.toaster.error(res.errors[0]);
            }  
      });
    }
  
    // **********get all purity list *********
    getAllPurityList() {
      this.administrationService.getAllPurityData=[];
      this.administrationService.getAllPurity().subscribe({
        next:(res)=>{
          if(res.isSuccess){
            this.getAllPurityData = res.response??[];
            this.administrationService.getAllPurityData=res.response??[];
          }else{           
            this.getAllPurityData = [];
          }  
        },
        error:(err)=>{
          this.getAllPurityData = [];
        }
      })
    }
  // *********** CARAT API END *********


}
