import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class FileReaderService {

  constructor(private http: HttpClient) { }

  getBaseUrlFromFile(filePath: string): Promise<any> {
    return this.http.get(filePath, { responseType: 'json' })
      .toPromise()
      .then((data: any) => data)
      .catch(error => {
        console.error('Error reading file:', error);
        throw error;
      });
  }
}
