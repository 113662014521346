import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild }     from '@angular/core';
import {BusinessRegistrationService}        from './business-registration.service'
import { BankDetailsRegistrationComponent } from './bank-details-registration/bank-details-registration.component';
import { CompanyRegistrationComponent }     from './company-registration/company-registration.component';
import { UserDetailsRegistrationComponent } from './user-details-registration/user-details-registration.component';
import { AuthService }                      from '../auth.service';
import { Subscription } from 'rxjs';
import { StorageService } from 'src/app/shared/services/storage.service';
import { RegisterUserRequestModel, RegisterUserResponseM, RegisteredUserVM } from './business-registration.model';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { LayoutDetails } from '../auth.model';
import { StorageName } from 'src/app/shared/enum/common-enum';
import { LayoutService } from 'src/app/shared/services/layout.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-business-registration',
  templateUrl: './business-registration.component.html',
  styleUrls: ['./business-registration.component.scss']
})
export class BusinessRegistrationComponent implements OnInit,AfterViewInit,OnDestroy{
  
  @ViewChild("companyregistration") companyDetailsComponent : CompanyRegistrationComponent;
  @ViewChild("bankregistration")    bankDetailsComponent    : BankDetailsRegistrationComponent;
  @ViewChild("userregistration")    UserDetailsComponent    : UserDetailsRegistrationComponent;

  @Input() fromAddCompany;
  formName = StorageName.FORMNAME_BUSINESSREGISTRATION;
  storageName = StorageName;
  getAllDropdownModel : any = {}; 
  userForm: any;
  active:any=1;
  getlicenseKeySecurityToken : any;
  registerUserRequestModel =  new RegisterUserRequestModel();
  registerUserResponseM = new RegisterUserResponseM();
  disabledbank:boolean=true;
  disabledUser:boolean=true;
  isCridAvailabilityCheck = {
    isCridAvailable : false,
    count : 0
  }
  country;
  layoutDetails= new LayoutDetails();
  @Input() countryName;
  constructor(
    private businessRegistrationService :BusinessRegistrationService,
    public storageService :StorageService,
    public layoutService  : LayoutService,
    private toster :ToastrService,
    private router : Router, 
    private location : Location,
  ){

  }
 

  ngOnInit(): void 
  {
    this.userForm = NgForm
    this.registerUserResponseM = new RegisterUserResponseM();
    this.getlicenseKeySecurityToken= this.storageService.retrieve('storelicenseKeyToken');
    this.layoutDetails =this.storageService.retrieve('layoutDetails');
    this.countryName = this.storageService.retrieve('country');
  }

  ngAfterViewInit(): void {

    this.companyDetailsComponent.usermodel.registrationDate = new Date()?.toISOString()?.split('T')[0];
  }

  nextTabActive(nav)
  {
    console.log(nav)
    if(nav.activeId == 1)
    {
      if(this.companyDetailsComponent?.CompanyForm?.valid)
      {
        this.companyDetailsinsert();
      }
      else
      {
        this.companyDetailsComponent.submitCompany =true;
        this.markFormAsTouchedAndDirty(this.companyDetailsComponent?.CompanyForm)
      }
    }
    if(nav.activeId == 2)
    {
      this.BankDetailsinsert()
    }
  }

  companyDetailsinsert()
  {
    this.registerUserRequestModel.licenseKeySecurityToken = this.getlicenseKeySecurityToken;
    this.registerUserRequestModel.registrationStep = 1;
    if(this.companyDetailsComponent.usermodel.registrationDate =="" ){
      this.companyDetailsComponent.usermodel.registrationDate = null;
    }
    this.registerUserRequestModel.user = this.companyDetailsComponent.usermodel;
    if(this.layoutDetails?.companyId && this.layoutDetails?.isUserSuperAdmin){
      this.registerUserRequestModel.user.parentCompanyId = this.layoutDetails?.companyId;
    }
    this.businessRegistrationService.Register(this.registerUserRequestModel).subscribe(res => {
      if(res.isSuccess){
        this.registerUserResponseM = res.response;
        this.toster.success(res?.message)
        this.registerUserRequestModel.licenseKeySecurityToken = this.registerUserResponseM.licenseKeySecurityToken;
        this.registerUserRequestModel.userId = this.registerUserResponseM.userId;
        this.registerUserRequestModel.user = this.registerUserResponseM.user;
        this.active = 2
        this.disabledbank= false;
        this.disabledUser= false;
        this.registerUserResponseM.user.registrationDate = this.registerUserResponseM?.user?.registrationDate?.split('T')[0];
      }
    })
  }
  
  BankDetailsinsert()
  {
    
    this.registerUserRequestModel.registrationStep = 2;
    this.registerUserRequestModel.user = this.bankDetailsComponent.usermodel;
    this.businessRegistrationService.Register(this.registerUserRequestModel).subscribe(res => {
      if(res?.isSuccess){
        this.registerUserResponseM = res.response;
        this.toster.success(res?.message)
        this.registerUserRequestModel.licenseKeySecurityToken = this.registerUserResponseM.licenseKeySecurityToken;
        this.registerUserRequestModel.userId = this.registerUserResponseM.userId;
        this.registerUserRequestModel.user = this.registerUserResponseM.user;
        this.active = 3
        this.registerUserResponseM.user.registrationDate = this.registerUserResponseM?.user?.registrationDate?.split('T')[0];
      }
    })
  }

  userDetailsinsert()
  {

    if(this.UserDetailsComponent?.UserForm?.valid){
    if(this.UserDetailsComponent?.usermodel?.username && this.UserDetailsComponent?.usermodel?.password && this.UserDetailsComponent?.confirmPassWord && this.UserDetailsComponent?.usermodel?.email && this.UserDetailsComponent?.usermodel?.firstname && this.UserDetailsComponent?.usermodel?.lastname){
      if(this.UserDetailsComponent?.usermodel?.password !== this.UserDetailsComponent?.confirmPassWord) return
    this.registerUserRequestModel.registrationStep = 3;
    this.registerUserRequestModel.user = this.UserDetailsComponent.usermodel;
    this.businessRegistrationService.Register(this.registerUserRequestModel).subscribe(res => {
      if(res?.isSuccess){
        // this.registerUserResponseM = res.response;
        // this.registerUserRequestModel.licenseKeySecurityToken = this.registerUserResponseM.licenseKeySecurityToken;
        // this.registerUserRequestModel.userId = this.registerUserResponseM.userId;
        // this.registerUserRequestModel.user = this.registerUserResponseM.user;
        // this.registerUserResponseM.user.registrationDate = this.registerUserResponseM?.user?.registrationDate?.split('T')[0];
        if(!this.fromAddCompany){
          this.router.navigate(['/account/login']);
        } else {
          this.router.navigate(['/company-list']);
        }
      } else {
      }
    });
    
  }} else
  {
    this.UserDetailsComponent.submitUser =true;
    // this.toster.error("please fill all required fields")
    this.markFormAsTouchedAndDirty(this.UserDetailsComponent?.UserForm)
  }
    
  }

  previousTabActive()
  {   this.active--
    if( this.active == 0)
    {
      this.active = 1;
    }
  }

  close(){
    this.location.back();
  }

  fillDropdownData(event){
    this.getAllDropdownModel.countryDropdown = event?.countryDropdown;
    this.getAllDropdownModel.stateDropdown = event?.stateDropdown;

    if(this.countryName){
      setTimeout(()=>{
        this.companyDetailsComponent.usermodel.countryId = this.getAllDropdownModel?.countryDropdown?.find((x)=> x.name == this.countryName)?.id;
        this.companyDetailsComponent?.onChangeCountryName(this.companyDetailsComponent?.usermodel?.countryId);
      },100)
     
    }
   }

   public markFormAsTouchedAndDirty(form: NgForm) {
    Object.keys(form?.controls).forEach(field => {
      const control = form?.controls[field];
      control?.markAsTouched({ onlySelf: true });
      control?.markAsDirty({ onlySelf: true });
    });
  }

   ngOnDestroy(): void {
    this.countryName = this.storageService.removeItem('country');
  }
}
