
<div class="d-flex align-items-center justify-content-end position-relative">
  <app-svg-icon [name]="'cross'" class="cross-button cursorPointer" (click)="activeModal.dismiss()"></app-svg-icon>
</div>
<style>
 
</style>
<div class="modal-body ">
  <iframe #pdfIframe
      *ngIf="src"
      [src]="src"
      style="width: 100%; height: 100vh; border: none;"
      webkitallowfullscreen
      mozallowfullscreen
      allowfullscreen
></iframe>
<iframe #pdfIframe
*ngIf="printHtml"
style="width: 100%; height: 100vh; border: none;"
webkitallowfullscreen
mozallowfullscreen
allowfullscreen
></iframe>
</div>