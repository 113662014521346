import { Component, Input} from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import {AgGridButtonCellrendererService} from './ag-grid-button-cellrenderer.service'
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { StorageService } from 'src/app/shared/services/storage.service';

@Component({
  selector: 'app-ag-grid-button-cellrenderer',
  templateUrl: './ag-grid-button-cellrenderer.component.html',
  styleUrls: ['./ag-grid-button-cellrenderer.component.scss']
})
export class AgGridButtonCellrendererComponent implements ICellRendererAngularComp{
  params: any;
  runNow:boolean = false;
  viewHistory: boolean = false;
  view: boolean = false;
  viewAndDeleteIcon: boolean = false;
  payable: boolean = false;
  bounce: boolean = false;
  cancle: boolean = false;
  receive: boolean = false;
  viewAllBtn: boolean = false;
  activityLog: boolean = false;
  buttonLabel:string;
  showTooltip = false;
  tooltipPosition: boolean = false;
  dynamicActivityLogStatus: string;
  statusColorCode: string = '';
  isConfirmationPopOpen = true;
  constructor(public storageService : StorageService,private agGridButtonCellrendererService :AgGridButtonCellrendererService, private modalService : NgbModal,public activeModal:NgbActiveModal){

  }

  agInit(params: any): void {
    this.params = params;
    this.isConfirmationPopOpen = params?.isConfirmationPopOpen??true;
    this.setViewFlags();
    if (params?.data?.isSumRow) {
      this.viewHistory = false;
      this.view =false;
      this.viewAndDeleteIcon=false;
    }
  }

  refresh(params: any): boolean {
    this.params = params;
    this.setViewFlags();
    return true;
  }

  onButtonClicked(): void {
    if (this.params.onClick instanceof Function) {
      this.params.onClick(this.params.value);
    }
  }

  viewDetails(params){
    this.agGridButtonCellrendererService.triggerViewButtonClick(params);
  }
  private setViewFlags(): void {
    this.viewHistory = this.params.viewHistory || false;
    this.view = this.params.view || false;
    this.viewAndDeleteIcon = this.params.viewAndDeleteIcon || false;
    this.payable = this.params.vipayableew || false;
    this.bounce = this.params.bounce || false;
    this.cancle = this.params.cancle || false;
    this.receive = this.params.receive || false;
    this.activityLog = this.params.activityLog || false;
    this.dynamicActivityLogStatus = this.params.data.statusName  || false;
    this.statusColorCode = this.params.data.statusColorCode || false;
    this.buttonLabel =  this.params.eventType ;
    this.viewAllBtn = this.params.viewAllBtn || false;
    this.runNow = this.params.runNow || false;

  }
  
  // onSvgClicked() {
  //   this.showTooltip = !this.showTooltip;
  //  // this.checkOverflow(event)
  // }
  // checkOverflow(event) {
  //   console.log(event);
  //   const a = event.view.innerHeight - 245;
  //   if(event.clientY > a ){
  //       this.tooltipPosition = true;
  //   }else{
  //     this.tooltipPosition = false;
  //   }
  // }

  openDeleteConfirmationPopup(content,params)
  {
    let pay = params
    this.modalService.open(content, { windowClass: "delet-confirm-popup-w" , centered:true,  backdrop: "static",
    });
  }

  deleterowdata(params,modal)
  {
    params.api.deselectAll();
    params.node.setSelected(true);
     const selectedNode = params.api.getSelectedRows();
     modal?.dismiss('Cross click');
     this.params.api.applyTransaction({ remove: selectedNode});
     params.api.deselectAll();

     const custoDeleteEvent = new CustomEvent('deleteButtonClick', {
      detail: { data: params },
    });
    this.params.api.dispatchEvent(custoDeleteEvent);
   // this.storageService.onDeleteRowClicked.next(params);
  }
}
