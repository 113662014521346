import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { StorageService } from '../services/storage.service';
import { StorageName } from '../enum/common-enum';

@Injectable({
  providedIn: 'root'
})
export class PermissionGuard implements CanActivate {

  constructor(private router: Router, private storageService: StorageService) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const permissions:any = this.storageService.retrieve(StorageName.USER_PERMISSIONS) as Permissions;
    if (permissions?.dashboard) {
      return true;
    } else {
      this.router.navigate(['/dshboard']);
      return false;
    }
  }
}
