 <ag-grid-angular
  #{{tableId}}
  id="{{tableId}}"
  class="ag-theme-alpine w-100"
  [ngClass]="dataModel?.showPagination?'ag_grid_height_pagination':'h-100'"
  [defaultColDef]="tableRowCellEditable?defaultColDef:defaultColDefination"
  [columnDefs]="dataModel?.colDefs"
  [rowData]="rowData"
  [rowHeight]="rowHeight"
  [animateRows]="true"
  [suppressRowClickSelection]="true"
  [suppressDragLeaveHidesColumns]="true"
  [rowSelection]="dataModel?.rowSelection"
  (selectionChanged)="onSelectionChanged($event)"
  (cellKeyDown)="onCellKeyDown($event)"
  [components]="components"
  (gridReady)="onGridReady($event)"
  (movingChanged)="onMovingChanged($event)"
  (rowClicked)="onRowClicked($event)"
  (rowDoubleClicked)="onRowDoubleClicked($event)"
  [enableCellEditingOnBackspace]="true"
  (dragStopped)="onDrag($event)"
  (rowDragEnter)="isRowDrag = true"
  [pagination]="pagination"
  [paginationPageSize]="25"
  (cellClicked)="onCellClicked($event)" 
  (cellValueChanged)="onCellValueChanged($event)"
  [stopEditingWhenCellsLoseFocus]="true"
  stopEditingWhenGridLosesFocus="true"
  [autoGroupColumnDef]="autoGroupColumnDef"
  [getRowStyle]="getRowStyle"
  [gridOptions]="gridOptions"
  [getRowClass]="getRowClass"
  [headerHeight]="dataModel?.headerHeight"
  [rowDragMultiRow]="true"
  [rowDragManaged]="true"
  (editIconClick)="handleEditIconClick($event)"
  (deleteButtonClick)="handleDeleteButtonClick($event)"
  (customHeaderEvent)="handleCustomHeaderEvent($event)"
  (printButtonClick)="handlePrintButtonClick($event)"
  (textCellClick)="handleTextCellClick($event)"
  (innerHtmlContentClick)="handleInnerHtmlContentClick($event)"
  (billIconClick)="billIconEvent($event)"
  (downloadIconClick)="downloadIconEvent($event)"
  (cardPaymentIconClick)="handleCardPaymentIconClick($event)"
  (arrowUpDownIconClick)="handleArrowUpDownIconClick($event)"
  [suppressColumnVirtualisation]="true"
  (cellDoubleClicked)="onCellDoubleClicked($event)"
  (barcodeIconClick)="barcodeIconEvent($event)"
  (InfoIconClick)="handleInfoIconCellClick($event)"
  (labelPrintClick)="labelPrintClickEvent($event)"
  (cardPrintClick)="cardPrintClickEvent($event)"
  (expandableIconClick)="expandableIconClickEvent($event)"
  [suppressContextMenu]="false"
  (cellContextMenu)="handleCellContextMenu($event)"
  (stockJournalButtonClick)="handleStockJournalButtonClick($event)"
  (blockUnblockIconClick)="handleBlockUnblockIconClick($event)"
  >

 </ag-grid-angular>
 <div id="customMenu" class="custom-menu" *ngIf="showContextMenu" [style.top.px]="contextMenuPosition.y" [style.left.px]="contextMenuPosition.x">
    <button (click)="copyText()">Copy Text</button>
  </div>
 <div class="row pt-1 mx-0 px-0" *ngIf="dataModel?.showPagination">
    <div class="col-12 d-flex justify-content-between align-items-center pagination_div px-2 border">
        <div class="text-nowrap f-11 text-gray-color"> Showing <span class="font-semibold">{{pagination.fromRecordNumber}}</span> to <span class="font-semibold">{{pagination.toRecordNumber}}</span> of <span class="font-semibold">{{ record? record:pagination.count}}</span> entries</div>
        <div class="d-flex">
            <div class="page-size-control px-2 d-flex align-items-center">
                <select  
                    [(ngModel)]="pagination.pageSize" (ngModelChange)="onPageSizeChanged(dataModel.tabIndex)"
                    class="page-size-dropdown f-11 pe-2 py-0 text-gray-color cursorPointer "
                    >
                    <option *ngFor="let option of pageLimitOptions" [value]="option.value">
                       {{ option.value === 1 ? 'Show All Items' : 'Show ' + option.value + ' Items' }} 
                      </option> 
                </select>
                <!-- <app-svg-icon name="down_arrow" [ngClass]="{ 'arrow-up': active }"></app-svg-icon> -->
            </div>
            <ngb-pagination
            #ngbPagination
            [collectionSize]="pagination.count"
            [pageSize]="pagination.pageSize" 
            [page]="pagination.page"
            [maxSize]="5" 
            (pageChange)="onPageNumberChanged($event)"
            [boundaryLinks]="true"
            class="d-flex justify-content-end grid-pagination" >
            <ng-template ngbPaginationPrevious>
                <img src="./assets/svg/left-arrow.svg" alt="" />
            </ng-template>
            <ng-template ngbPaginationNext>
                <img src="./assets/svg/right-arrow.svg" alt="" />
            </ng-template>
            </ngb-pagination>
        </div>
    </div>
</div> 
  <!-- [suppressLoadingOverlay]="true" -->
  <!-- [groupIncludeFooter]="true" -->
  <!-- [groupIncludeTotalFooter]="true" -->
 
 <app-columns-settings *ngIf="columnSettings"  (onColumnVisibility)="onDrag($event)" [dataModel]="paramsData"></app-columns-settings>


