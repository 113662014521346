import { AfterViewInit, Component, EventEmitter, ViewChild, ViewContainerRef } from '@angular/core';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { ICellEditorParams } from 'ag-grid-community';
import { IDropdownSettings } from 'ng-multiselect-dropdown';

@Component({
  selector: 'app-multi-select-dropdown',
  templateUrl: './multi-select-dropdown.component.html',
  styleUrls: ['./multi-select-dropdown.component.scss']
})
export class MultiSelectDropdownComponent implements ICellEditorAngularComp, AfterViewInit {
  dropdownSettings                  : IDropdownSettings = {};
  dropdownList                      = [];
  selectedItems                     = [];
  @ViewChild("container", { read: ViewContainerRef }) public container;

  values: string[] = [];
  value: string;
  params: ICellEditorParams<any>;
  valueChanged = new EventEmitter<string>();

  constructor(){
    this.dropdownList = [
      { item_id: 1, item_text: 'Mumbai' },
      { item_id: 2, item_text: 'Bangaluru' },
      { item_id: 3, item_text: 'Pune' },
      { item_id: 4, item_text: 'Navsari' },
      { item_id: 5, item_text: 'New Delhi' }
    ];
    this.selectedItems = [
      { item_id: 3, item_text: 'Pune' },
      { item_id: 4, item_text: 'Navsari' }
    ];

    // Assign the properties to dropdownSettings
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 5,
      allowSearchFilter: true
    };
  }

  agInit(params: ICellEditorParams<any>): void {
    this.params = params;
    this.value = this.params.value;
    this.values = this.getValuesFromParams(this.params);
  }
  getValue() {
    return this.value;
  }
  isPopup?(): boolean {
    return true;
  }
  getPopupPosition?(): any {
    return 'over'
  }
  isCancelBeforeStart?(): boolean {
    return false;
  }
  isCancelAfterEnd?(): boolean {
    return false
  }
  focusIn?(): void {
    throw new Error("Method not implemented.");
  }
  focusOut?(): void {
    throw new Error("Method not implemented.");
  }
  afterGuiAttached?(): void {
    this.getValue()
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.container.element.nativeElement.focus();
    });
  }

  onKeyDown(event): void {
    let key = event.which || event.keyCode;
    if (
      key == 37 || // left
      key == 39
    ) {
      // right
      event.stopPropagation();
    }
  }

  cellEditingStopped(event) {
    // Logic on cell editing stopped
  }

  cellValueChanged() {
    // Logic on cell value changed
    this.valueChanged.emit(this.value); 
  }
  
  selected(item: string) {
    this.value = item;
    this.valueChanged.emit(this.value); 
  }

  private getValuesFromParams(params: ICellEditorParams<any>): string[] {
    return params?.colDef?.cellEditorParams?.values ?? [];
  }

  ngOnInit(){}

  onItemSelect(item: any) {
    console.log(item);
  }

  onSelectAll(items: any) {
    console.log(items);
  }

}
