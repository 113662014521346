import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-deactivate-confirm',
  templateUrl: './deactivate-confirm.component.html',
  styleUrls: ['./deactivate-confirm.component.scss']
})
export class DeactivateConfirmComponent {
  constructor(public modal: NgbActiveModal,private router: Router) {}


  leave()
  {
    this.modal.close(true);
  }
  saveAsDraft(result){
    this.modal.close(result);
  }
  yes(result)
  {
    this.modal.close(result);
  }
}
