<div class="modal-header d-flex align-items-center justify-content-center position-relative">
    <div><span class="font-semibold text-primary">Sales Mode</span></div>
    <app-svg-icon [name]="'cross_button_border'" class="cross-button-border"></app-svg-icon>
    <app-svg-icon [name]="'cross'" class="cross-button" (click)="activeModal.dismiss()"></app-svg-icon>
  </div>
  <form>
    <div class="modal-body mx-2">
      <div class="row mb-1">
        <div class="col-4 font-semibold text-gray-color d-flex align-items-center">Voucher Type</div>
        <div class="col-8 ps-0">
            <app-select
            [options]="voucherTypeDropdown" 
            [selectedValue]="selectedOption"
            (valueChanged)="onDropdownChange($event)"
            [id]="'salesModePopUpDropdown'"
            [name]="'salesModePopUpDropdown'"
            [isDisabled]="false"
            [isRequired]="false"
            >
          </app-select>
        </div>
      </div>
    </div>
    <div class="modal-footer pt-3 pb-2 mx-2">
      <app-button class="my-0 me-3" (buttonOutputEvent)="activeModal.close()" [buttonLabel]="'Close'" [buttonClass]="'white_button'" [buttonType]="'button'">
      </app-button>
      <app-button class="m-0" [buttonLabel]="'Save'" [buttonClass]="'blue_button'" [buttonType]="'button'">
      </app-button>
    </div>
  </form>