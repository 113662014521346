<span class="d-flex justify-content-center align-content-center images_renderer" *ngIf="!params?.data?.isFooterRow" [ngClass]="params?.data?.expandeble?'h-100':'h-25px'">
    <!-- <app-svg-icon [name]="'jewellery'" class="jewellery_img"></app-svg-icon> -->
    <img 
    (click)="params.isAddImage?onOpenAddImage():onIconClick()" 
    [src]="displayImage?(isBase64Image(displayImage)):''" 
    class="h-100 w-100 d-flex justify-content-center align-items-center cursorPointer images_renderer"  
    alt="Img"
    srcset="" 
    onerror="this.src = './assets/svg/no_image_available.svg'">
    <!-- <img *ngIf="isDisplayImageByUrl" (click)="onIconClick()" class="h-100 w-100 d-flex justify-content-center align-items-center cursorPointer" [src]="baseUrl+isDisplayImageByUrl" alt="Img" srcset="" onerror="this.src = '../../../../../assets/images/no-picture-available-icon-12.jpg'"> -->
    <!-- <img *ngIf="!imagesListData?.length" (click)="onIconClick()" class="h-100 w-100 d-flex justify-content-center align-items-center cursorPointer" src="../../../../../assets/images/no-picture-available-icon-12.jpg" alt="Img" srcset="" onerror="this.src = '../../../../../assets/images/no-picture-available-icon-12.jpg'"> -->
     <!-- Hidden file input -->
     <!-- <input  type="file" id="fileInput" accept="image/*" style="display: none" (change)="onSelect($event)"> -->
     <div style="display: none" ngx-dropzone id="fileInput" [accept]="'image/*'" (change)="onSelect($event)" class="rounded child_image d-flex align-items-center justify-content-center border-0 h-auto cursorPointer ">
          
     </div> 
</span>




