import { AgGridDataModelChargesTable, AgGridDataModelManufacturDesc, AgGridDataModelManufacturing, AgGridDataModelProductCharacteristics, AgGridDataModelProductList, AgGridDataModelTaxListTable, ProductDetailModel, ProductListModel, ProductModel, ProductOpening, ProductSpecification, TabView, TaxModel, exportRequest } from './product-opening.model';
import { Component, Input, OnInit, QueryList, Renderer2, ViewChild, ViewChildren } from '@angular/core';
import { AgGridAngular } from 'ag-grid-angular';
import { GridApi } from 'ag-grid-community';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { GridOptions } from 'ag-grid-community';
import { NgbActiveModal, NgbDropdown, NgbModal, NgbModalOptions, NgbPaginationConfig } from '@ng-bootstrap/ng-bootstrap';
import { PaginationPageSizes } from 'src/app/shared/components/common-model/common-model';
import { PlusIconService } from 'src/app/shared/common-components/ag-grid/plus-icon/plus-icon.service';
import { TaxComponent } from './popup/tax/tax.component';
import { DeleteComponent } from './popup/delete/delete.component';
import { MetalType, commonModalOptions, commonModalOptionsLg} from '../../../shared/models/common.model'
import { AgGridI, PaginationModel, defaultPageLimit } from 'src/app/shared/common-components/ag-grid/ag-grid.model';
import { CommonService } from 'src/app/shared/services/common.service';
import { Subscription, catchError } from 'rxjs';
import { NgForm } from '@angular/forms';
import {ProductOpeningService} from './product-opening.service'
import {ProductService} from '../product.service'
import { ResponseModel } from 'src/app/shared/models/response-model.model';
import { ExportAs, StorageName } from 'src/app/shared/enum/common-enum';
import { ToastrService } from 'ngx-toastr';
import { StorageService } from 'src/app/shared/services/storage.service';
import { CheckboxRendererService } from 'src/app/shared/common-components/ag-grid/checkbox-renderer/checkbox-renderer.service';
import { BranchDetailsComponent } from '../../administration/popups/branch-details/branch-details.component';
import { MasterModalTitle } from '../../administration/masters/masters.model';
import { BranchesTabComponent } from '../../administration/masters/tabs/branches-tab/branches-tab.component';
import { AddeditcategoryComponent } from './popup/addeditcategory/addeditcategory.component';
import { AdministrationService } from '../../administration/administration.service';
import { SaveComponent } from 'src/app/shared/common-components/pop-ups/save/save.component';
import { AgGridService } from 'src/app/shared/common-components/ag-grid/ag-grid.service';
import { SendDataService } from 'src/app/shared/services/send-data.service';
import { GetRequestModel } from '../../Report/report.model';
import { AgGridComponent } from 'src/app/shared/common-components/ag-grid/ag-grid.component';
import { CaratPopupComponent } from 'src/app/shared/common-components/common-popup/carat-popup/carat-popup.component';
import { MetalSectionModel } from 'src/app/shared/common-components/metal-section/metal-section.model';
import { ExcelConverterServiceService } from 'src/app/shared/services/excel-converter-service.service';
import { MastersService } from '../../administration/masters/masters.service';
import { CompressImageService } from 'src/app/shared/services/compress-image.service';
import { AddEditLocationComponent } from './popup/add-edit-location/add-edit-location.component';
import { User } from 'src/app/auth/auth/auth.model';
import { FieldValueComponent } from '../../administration/masters/tabs/user-define-fields/pop-up/field-value/field-value.component';
import { FileReaderService } from 'src/app/shared/services/file.reader.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { IconHover } from '../../../shared/enum/common-enum';

@Component({
  selector: 'app-product-opening',
  templateUrl: './product-opening.component.html', 
  styleUrls: ['./product-opening.component.scss'],
  providers: [NgbPaginationConfig]
})

export class ProductOpeningComponent  extends CommonService implements OnInit {
  private plusIconSubscription          : Subscription | undefined;
  agGridDataModelProductCharacteristics : AgGridI;
  agGridDataModelProductList            : AgGridI;
  agGridDataModelTaxListTable           : AgGridI;
  agGridDataModelManufacturing          : AgGridI;
  agGridDataModelManufacturDesc         : AgGridI;
  agGridDataModelChargesTable           : AgGridI;
  dropdownSettings                      : IDropdownSettings = {};
  taxGridApi                            : any;
  additionalCostGridApi                 : GridApi;
  additionalCostGridOptions             : GridOptions;
  billOfMaterialItemGridOptions         : GridOptions;
  productSpecificationGridOptions       : GridOptions;
  pageNumber = 1;
  totalRecords:number;
  currentHoverEffect = IconHover.Pink;
  storageName = StorageName
  taxList     :TaxModel[] = [];
  @Input() isHeightFull : false;
  taxListCopiedData :TaxModel[] = [];
  openTab: number=1;
  tabId = TabView;
  files: File[] = [];
  productSpecification :ProductSpecification[];
  ProductSpecificationModel = new ProductSpecification();
  gridApi                   : any;
  makeCopy:boolean=false;
  copyProduct:boolean=false;
  productNameObj:any;
  formModel :  any = {};
  metalSectionModel=new MetalSectionModel();
  editUser:any;
  exportAs = ExportAs;
  
  @Input() hide_Header:boolean=false;
  productName:string;
  @ViewChild(AgGridAngular) agGrid! : AgGridAngular;
  @ViewChild("ProductOpeningForm") ProductOpeningForm!: NgForm;
  @ViewChild(BranchesTabComponent)branchesTabComponent : BranchesTabComponent;
  @ViewChildren(AgGridComponent) grids: QueryList<AgGridComponent>
  @ViewChild(NgbDropdown) dropdown: NgbDropdown;
  paginationPageSizes = new PaginationPageSizes();
  getAllDropdownModel  : any = {};
  productOpening      = new ProductOpening();
  productListModel    = new ProductListModel();
  productDetailModel  = new ProductDetailModel();
  productModel        = new ProductModel();
  // billOfMaterialResourceModel = [];
  // billOfMaterialModel = new BillOfMaterialModel();
  // billOfMaterialItemDetailsModel = new BillOfMaterialItemDetailsModel()
  taxModel            = new TaxModel();
  popupTitle          = MasterModalTitle;
  allProductDropdown             : any;
  receivedDataOnCheck            : any;
  ProductCharacteristicsCoulumns : any;
  productDetails                 : any;
  branchIds: any[] = []; 
  copySelectedBranches           : any;
  AdditionalCost                 : boolean=false;
  selectedImage                  : any;
  gridApiProductOpening          : any;
  gridApiProductCharacteristics  : any;
  gridApiProductTax  : any;
  productList                    : any[];
  isLoading :boolean=false;
  // tabItems = [
  //   { id: 1, name: 'Gold',active:true},
  //   { id: 2, name: 'Silver',active:false },
  //   { id: 3, name: 'Diamond',active:false },
  //   { id: 4, name: 'Stone',active:false },
  //   { id: 5, name: 'Immitation',active:false },
  //   { id: 6, name: 'Platinum',active:false },
  //   { id: 7, name: 'Other',active:false }
  // ];
  invoiceName: any;
  formName = StorageName.FORMNAME_PRODUCT_PRODUCTOPENING;
  user:User;
  pagination = new PaginationModel();
  getallrfidAvailbaleRequestModel = new GetRequestModel();
  userData: any;
  FilePath: any;
  fileInput ='';
  fileName ="";
  selectedFile: File | null = null;
 constructor(
    config                          : NgbPaginationConfig, 
    public activeModal              : NgbActiveModal,
    private modalService            : NgbModal, 
    private plusIconService         : PlusIconService,
    public notificationService      : NotificationService,
    private storageservice          : StorageService,
    public renderer                 : Renderer2,
    private productOpeningService   : ProductOpeningService,
    public toaster                  : ToastrService,
    public storageService           :StorageService,
    private checkboxRendererService : CheckboxRendererService,
    public productService           : ProductService,
    public administrationService : AdministrationService,
    private agGridService         : AgGridService,
    public sendDataService        : SendDataService,
    public excelConverterServiceService: ExcelConverterServiceService,
    public mastersService: MastersService,
    public compressImageService :CompressImageService,
    public fileReaderService : FileReaderService,) 
    {
    config.size = 'sm';
    config.boundaryLinks = true;
    super(renderer);
    // this.getDataOnChecked();

    this.additionalCostGridOptions = {
      singleClickEdit : true,
    };
    this.billOfMaterialItemGridOptions={
      singleClickEdit : true,
    }
    this.productSpecificationGridOptions={
      singleClickEdit : true,
    }

  }


  handleTextClick(event){
    const currentId =event.data?.id;
    if (currentId !== this.productOpeningService.previouscellClickedId) {
      this.productOpeningService.previouscellClickedId = currentId;
      this.getCharacteristicsByProductId(event.data?.id);
      this.productDetails=event.data;
    } else {
      console.log('ID is the same as the previous one.');
    }
  }

  DropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'id',
    textField: 'name',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 4,
    allowSearchFilter: false
  };

  ngOnInit() {
    this.branchIds = this.getFilteredBranchIds(this.storageService, this.storageName);
    !this.hide_Header ? this.sendDataService.displayPageName(this.formName):null;
    this.getAllDropdownModel;
    this.metalSectionModel.isInternal_Fix=false;
    this.metalSectionModel.isBarcodeInput=false;
    this.isLoading =true
    this.productSpecification=[];
    this.addemtyRow();
    this.agGridDataModelProductCharacteristics = AgGridDataModelProductCharacteristics;
    this.agGridDataModelTaxListTable = AgGridDataModelTaxListTable;
    this.agGridDataModelManufacturing     = AgGridDataModelManufacturing;
    this.agGridDataModelManufacturDesc     = AgGridDataModelManufacturDesc;
    this.agGridDataModelChargesTable     = AgGridDataModelChargesTable;
    this.agGridDataModelProductList = AgGridDataModelProductList;

    this.plusIconSubscription = this.plusIconService.plusIconClicked$.subscribe((params: string) => {
             this.openPopup(params)
    });
    // this.getAllTax();
    // this.getCharacteristicsByProductId(0);
    this.getSampleFileByPageName()
  }

  onGridProdutListReady(event){
    this.gridApiProductOpening  = event; 
    this.getAllProductList()
  }
  onGridProductCharacteristicsReady(event){
    this.gridApiProductCharacteristics  = event; 
     this.getCharacteristicsByProductId(0); 
  }
  onGridProductTaxReady(event){
    this.gridApiProductTax  = event; 
     this.getAllTax(); 
  }

  onDeleteRowClickedResponse(event) {
    this.deleteProduct(event?.data?.id , event);
  }
  
  openPopup(params){
    switch (params?.displayName) {
      case 'Carat':  this.openCaratPopup(params); break;
      case 'Location': this.openLocationPopup(params);break;
    }
  }


  onCellValueChange(params) {
    console.log(params);
    if(params.data.metalId == MetalType.Diamond)
      {  this.calculation(params.data, params.node,params.column.colId);}
    else if(params.data.metalId == MetalType.Gold)
      {this.calculationGoldorSilver(params.data, params.node,params.column.colId);}
    else if (params.data.metalId == MetalType.Silver)
      {this.calculationGoldorSilver(params.data, params.node,params.column.colId);}
    else{
      console.log("error");
    }
  }

  calculationGoldorSilver(model,node,colId){


  if(model.openingQuantity){
    model.openingFinalWeight = model.openingWeight * model.openingPurity * model.openingQuantity;
  }else{
    model.openingFinalWeight = model.openingWeight * model.openingPurity ;
  }
  this.gridApiProductCharacteristics?.api?.refreshCells(); 
        }

  calculation(model,node,colId){

    switch (colId) {
      case "openingWeight":
        model.openingPurity = this.fixWeightDecimal(Number(model.openingWeight ?? 0) * 5,3);
      break; 
      case "openingPurity":
        model.openingWeight = this.fixWeightDecimal(Number(model.openingPurity ?? 0) / 5,3);
      
      break;                   
  }
  model.openingFinalWeight = this.fixWeightDecimal(model.openingWeight,3);
  this.gridApiProductCharacteristics?.api?.refreshCells(); 
      
        }
  addemtyRow()
      {
        const productSpecificationModel= new ProductSpecification();
        this.productSpecification = [...this.productSpecification, productSpecificationModel];
        this.productSpecificationGridOptions?.api?.setRowData(this.productSpecification);
        // const manufacturerDescription = new BillOfMaterialItemModel();
        // this.productOpening.billOfMaterialModel.billOfMaterialItemModel = [...this.productOpening.billOfMaterialModel.billOfMaterialItemModel, manufacturerDescription]
        // this.billOfMaterialItemGridOptions?.api?.setRowData(this.productOpening.billOfMaterialModel.billOfMaterialItemModel);
      }

  //  paymentListRowData: any[] = [
  //   {
  //     paymentType: 'Cash',
  //     metalName: 'Platinum',
  //     transactionNo: '1546468746',
  //     items: 'Ring',
  //     Weight: '20.000',
  //     PurityWt: '5.000',
  //     rate: "10000.00",
  //     amount: "20202.00",
  //     balance: "5000.00"
  //   },
  // ];

  // goldData: any[] = [
  //   {
  //     description: 'Unit P-0, Qty-1, Weight-1312.500, MC-10000.00, Amt-25000.00, Tax-1000.00, HM-100.00 Unit P-0, Qty-0, Weight-0.000, MC-0.00, Amt-0.00, Tax-0.00, HM-0.00', tax: '5000.00', 
  //   },
  //   {
  //     description: 'Unit P-0, Qty-1, Weight-1312.500, MC-10000.00, Amt-25000.00, Tax-1000.00, HM-100.00 Unit P-0, Qty-0, Weight-0.000, MC-0.00, Amt-0.00, Tax-0.00, HM-0.00', tax: '5000.00', 
  //   },

  // ];

  // chargesData: any[] = [
  //   {
  //     makingCharges: 'Making Charges', estimatedCost: '5000.00', 
  //   },
  //   {
  //     makingCharges: 'Making Charges', estimatedCost: '5000.00', 
  //   },

  // ];


gridOptionsProductCharacteristics: GridOptions = {
  // suppressClickEdit:true,
  singleClickEdit: true,
  getRowStyle: this.getRowStyle,
};

onfocus(event,id){
  const elm = document.getElementById(id) as HTMLInputElement;
  elm.focus();
  elm.select();
 }

  gridOptions: GridOptions = {
    // suppressClickEdit: true,
    getRowStyle: this.getRowStyle,
    singleClickEdit:true
  };

  getRowStyle(params: any) {
    return {
      background: params.node.rowIndex % 2 === 0 ? '#EEF1F7' : '#FFFFFF',
    };
  }
  
  getAllTax() {
    this.showLoading(this.gridApiProductTax);
    this.productOpeningService.getAllTaxSettings().subscribe({
      next:(res)=>{
        if(res.isSuccess){
          // this.taxList = res.response??[];
          // this.taxList.map((a)=>a.active=false);
        const taxData = res.response??[];
        this.taxList =  taxData.map((tax)=>({
            // id : tax.id,
          taxId  : tax.id,
          active : false,
          name   : tax.name,
          taxValue : tax.rate,
          calculationMode:tax.calculationMode,
          added : false,
          edited : false,
          deleted : false,
          isAlreadyAdded : false
        }));
        this.taxListCopiedData = JSON.parse(JSON.stringify(this.taxList))
        }else{           
          this.hideLoading(this.gridApiProductTax); 
          this.taxList = [];
          this.taxListCopiedData = []
        }  
      },
      error:(err)=>{
        this.hideLoading(this.gridApiProductTax); 
        this.taxList = [];
        this.taxListCopiedData = []
      }
    })

  }

  // ******tax popup start*****
  openTaxPopup() {
    const modalOption: NgbModalOptions = {
      ...commonModalOptions,
    };
    const modalRef = this.modalService.open(TaxComponent, modalOption);
    modalRef.componentInstance.modalTitle = this.popupTitle.AddTaxSetting;
    modalRef.componentInstance.getAllDropdownModel = this.getAllDropdownModel;
    modalRef.result.then((result) => {
      if (result) {
        this.getAllTax();
      }
    })
  }

  // ******branch popup start*****

  openBranchPopup() {
    const modalOption: NgbModalOptions = {
      ...commonModalOptionsLg,
    };
    const modalRef = this.modalService.open(BranchDetailsComponent, modalOption);
    modalRef.componentInstance.modalTitle = this.popupTitle.AddBranchDetail;
    modalRef.componentInstance.getAllDropdownModel = this.getAllDropdownModel;
    modalRef.result.then((result) => {
      if (result) {
        // this.branchesTabComponent.getAllBranchList();
        this.mastersService.getAllBranch().subscribe({
          next:(res)=>{
            if(res?.isSuccess){
              this.getAllDropdownModel.branchDropdown = res?.response??[];
            }
          },
          error:(err)=>{
          }
        })
      }
    });
  }


  // ******Delete popup start*****
  openDeletePopUp() {
    if(this.productDetails){
      const modalOption: NgbModalOptions = {
        ...commonModalOptions,
        size: "sm",
        modalDialogClass: 'delete_popup'
      };
      const modalRef = this.modalService.open(DeleteComponent, modalOption);
      modalRef.componentInstance.productName = this.productDetails?.name;
      modalRef.componentInstance.deleteConfirmed.subscribe((res) => {
        if (res) {
          this.deleteProduct(this.productDetails?.id);
        }
      });
    }else{
      this.toaster.error("Product Not Selected")
    }
   
  }
  // ******Delete popup end*****


    // ******Save popup start*****
  //   openSavePopUp() {     
  //       const modalOption: NgbModalOptions = {
  //         ...commonModalOptions,
  //         size: "sm",
  //         modalDialogClass: 'save_popup'
  //       };
  //       const modalRef = this.modalService.open(SaveComponent, modalOption);  
  //       modalRef.result.then((res)=>{
  //         if(res){
  //           this.saveData();     
  //         }
  //       })   
  // }

  openSavePopUp() {
    this.notificationService.confirmationPopup('Save Changes', 'Do you want to save changes?', 'save', false, 'Save', "Don't Save", true).subscribe((result) => {
      if (result.isDismissed) {
        console.log("Don't Save button clicked"); 
      } else if (result.isConfirmed) {
        this.saveData();  
      }
    });
  }
  // ******Save popup end*****
  
  addCategory(){
    const modalOption: NgbModalOptions = {
      ...commonModalOptions,
      size: "md",
    };
    const modalRef = this.modalService.open(AddeditcategoryComponent, modalOption);
    modalRef.result.then((result) => {
      if (result) {
        this.administrationService.getAllCategory().subscribe((res)=>{
          this.productOpening.productModel.categoryId=null;
          let categoryDropdown  =  res.response;
          this.getAllDropdownModel.categoryDropdown = categoryDropdown.filter(x=>x.active==true);
         })
      }
    })
  }
  
  // getDataOnChecked() {
  //   this.checkboxRendererService.data$.subscribe(
  //     params => {
  //       this.receivedDataOnCheck = params;
  //       if (this.receivedDataOnCheck.colDef?.headerName == 'Tax') {
  //         if (this.receivedDataOnCheck?.data.active) {
  //           let Obj:any = {
  //             id: this.receivedDataOnCheck?.data?.id,
  //             added: true,
  //             deleted: false,
  //             edited: false,
  //             none: false,
  //           };
  //           this.productOpening.productModel.taxIds= this.productOpening.productModel.taxIds??[]
  //           Obj.taxValue = params?.data?.rate;
  //           this.productOpening.productModel.taxIds.push(Obj);
  //         } else {
  //           for (let item of this.taxList) {
  //             for (let prod of this.productOpening.productModel.taxIds) {
  //               if (item.id === prod.id && item.active === false) {
  //                 prod.deleted = true;
  //               }
  //             }
  //           }
  //         }
  //       }
  //     }
  //   );
  // }

  
  saveData(){
    // if (this.ProductOpeningForm.valid) {
    
    //    const productSpecificationRowData =   this.productSpecificationGridOptions?.api?.getRenderedNodes()?.map(node => node?.data);
    //     this.productSpecification = productSpecificationRowData;
    //     let a =   JSON.stringify(this.productSpecification);
    //     console.log(a);

    //     this.productOpening.billOfMaterialModel.wt = 344;
    //     let b:any = [{productDetailId:0}]
    //     let obj = {
    //       description:this.productOpening.billOfMaterialModel.description,
    //       wt:22,
    //       detailedJson:JSON.stringify(this.productSpecification),
    //       billOfMaterialItemDetailsModel:b
    //     }
    //     this.productOpening.billOfMaterialModel.billOfMaterialItemModel.push(obj)
    //     const additionalCostRowData = this.additionalCostGridOptions?.api?.getRenderedNodes()?.map(node => node?.data);
    //     this.productOpening.billOfMaterialModel.billOfMaterialAdditionalCostModel = additionalCostRowData;
    //  this.productOpening.billOfMaterialModel.billOfMaterialResourceModel = this.billOfMaterialResourceModel?.map(({ fileName, ...rest }) => rest);

    let rowsToDisplay = this.taxGridApi?.getRenderedNodes()?.map(node => node?.data);
    rowsToDisplay = rowsToDisplay.map(data => ({
      ...data,
      calculationMode: data.calculationMode ? parseInt(data.calculationMode, 10) : 0,
   //   added: data.edited ? false : (data.active ? true : false)
        }));
    // const taxUpdatedList = rowsToDisplay.filter(itemB => !this.taxListCopiedData?.some(itemA => JSON.stringify(itemA)  === JSON.stringify(itemB)));
    // taxUpdatedList.map((x)=>x.edited = false);
    // if(this.productOpening.productModel.id){
    //   taxUpdatedList?.forEach((tax)=>{
    //     if(tax.active == false){
    //       tax.deleted = true
    //     } else if(tax.active == true){
    //       tax.added = true;
    //       if(tax.taxValue !== this.taxListCopiedData?.find((x)=> x?.id == tax?.id)?.taxValue ){
    //         if(tax?.isAlreadyAdded){
    //           tax.edited = true;
    //           tax.added = false;
    //         } else {
    //           tax.edited = false;
    //           tax.added = true;
    //         }
    //       }
    //     }
    //   })
    // } else {
    //   taxUpdatedList?.forEach((x)=>x.added = true);
    // }
    if(this.productOpening.productModel.id===0){
      // rowsToDisplay = rowsToDisplay.filter(data => data.active);
      for (const tax of rowsToDisplay) {
        tax.id =0;
      }
      this.productOpening.productModel.taxIds = rowsToDisplay;
    }else{
      const oldValues1 = this.productOpening.productModel.taxIds;
      const oldValues = oldValues1.filter(taxId => taxId !== 0);
      // rowsToDisplay = rowsToDisplay.filter(data => data.active);
      rowsToDisplay = rowsToDisplay.map(data => {
        const originalCalculationMode = oldValues.find(item => item.taxId === data.taxId)?.calculationMode;
        const originalTaxValue = oldValues.find(item => item.taxId === data.taxId)?.taxValue;
        const oldActive = oldValues.find(item => item.taxId === data.taxId);
        
        const added = !oldActive && data.active; 
        const edited = (originalCalculationMode && originalCalculationMode !== data.calculationMode) || (originalTaxValue && originalTaxValue !== data.taxValue);
        return {
          ...data,
          edited: !!edited,
          deleted: !data.active,
          added: added,
        };
      });
     
      this.productOpening.productModel.taxIds = rowsToDisplay;
    }
    // this.productOpening.productModel.taxIds = rowsToDisplay;
      const copyprod = JSON.parse(JSON.stringify(this.productOpening))
      delete copyprod.resourceProperties;
      // copyprod.productDetailModel =  copyprod.productDetailModel.filter((x)=>x.active==true);
      for(let product of copyprod.productDetailModel){
        product.deleted = product.active?false:true;
        product.added = product.id===0?true:false;
        product.edited = product.id===0 ?false:true;
        product.none = false;
    }
    if (this.productOpening?.productModel?.branchIds) {
      const branchIds = this.productOpening?.productModel?.branchIds;
      this.productOpening.productModel.branchIds = Array.isArray(branchIds) && branchIds?.length > 0 && typeof branchIds[0] === 'object' && 'id' in branchIds[0] ? branchIds.map(branch => branch?.id?.toString()) : branchIds;
    }else{
      this.productOpening.productModel.branchIds = [String(this.userData?.branchId)]
    }
      if(this.productOpening.productModel.id===0){
       
        this.addProduct(copyprod);
      }else{
        this.editProduct(copyprod);
      }
  //  }
  };
 
  addProduct(productOpening) {
    // this.billOfMaterialModel = null;
    // this.productOpening.billOfMaterialModel = null;
    if(this.copyProduct){
      productOpening.productDetailModel.map(x=>x.id=0);
    }
      this.productOpeningService.insertProduct(this.productOpening).subscribe({
        next:(res) => {
          if(res.isSuccess===true){
            this.productNameObj={name:productOpening?.productModel?.name};
            this.toaster.success(res.message);
            this.getAllProductList();
            this.getAllTax();
            this.clearDetails();
            this.ProductOpeningForm.reset();    
             } else {
            this.toaster.error(res.errors[0]);
          }
        },
        error:(err) =>{
          this.toaster.error(err?.error?.errors[0]); 
        }
      })
  }

  editProduct(productOpening){
    // this.billOfMaterialModel = null;
    // this.productOpening.billOfMaterialModel = null;
      this.productOpeningService.updateProduct(productOpening).subscribe({
        next:(res) => {
          if(res.isSuccess===true){
            this.toaster.success(res.message);
            this.getAllProductList();
            this.getAllTax();
            this.clearDetails();
            this.productOpeningService.previouscellClickedId =null;
          } else {
            this.toaster.error(res.errors[0]);
          }
        },
          error:(err) =>{
            this.toaster.error(err?.error?.errors[0]); 
          }
        })   
}

//  deleteProduct(productId){
//   this.productOpeningService.deleteProduct(productId).pipe(catchError((err)=>this.handleError(err)))
//       .subscribe((res: ResponseModel) => {
//         if(res.isSuccess===true){
//           this.toaster.success(res.message);
//           this.getAllProductList();
//           this.getAllTax();
//           this.clearDetails();
//         } else {
//           this.toaster.error(res.errors[0]);
//         }
//       })
//  }

 deleteProduct(productId ,params?){
  this.productOpeningService.deleteProduct(productId).pipe(catchError((err)=>this.handleError(err)))
      .subscribe((res: ResponseModel) => {
        if(res.isSuccess===true){
          this.toaster.success(res.message);
          this.getAllProductList();
          this.getAllTax();
          this.clearDetails();
        } else {
          const transaction = {
            add: [params?.data], 
            addIndex: params?.rowIndex 
        };
        params?.api?.applyTransaction(transaction);
        this.toaster.error(res.errors[0]);
        }
      })
 }

  // onPagination(pagination: PaginationModel) {
  //   this.storeChangedPagination(pagination);
  //   this.getAllProductList();
  //  }
 
   storeChangedPagination(pagination: PaginationModel) {
     this.pagination.page = pagination.page;
     this.pagination.reloadGrid = pagination.reloadGrid;
     this.pagination.reloadGridFilters = false;
     this.pagination.pageSize = pagination.pageSize;
     this.productListModel.page =  this.pagination.page;
     this.productListModel.pageSize = this.pagination.pageSize;
   }
 

  getAllProductList() {
    // this.productListModel = new ProductListModel();
    this.showLoading(this.gridApiProductOpening);
    this.productOpeningService.getAllProduct(this.productListModel).subscribe({
      next:(res)=>{
          if(res.isSuccess){ 
          this.totalRecords = res.totalRecords;
          this.productList = res?.response?.data;
          setTimeout(() => {
            this.hideLoading(this.gridApiProductOpening); 
            var response = res?.response?.data;
            if(response){
              var firstTabEntry = response[0];
            }
            this.gridApiProductOpening?.api.paginationSetPageSize(this.pagination == undefined ||  this.pagination.pageSize == undefined ? defaultPageLimit : this.pagination.pageSize);
            this.gridApiProductOpening?.api?.setRowData(res?.response?.data);
            let productdata = res.response.data;
            this.storageservice.store(StorageName.PRODUCT_PRODUCTOPENING_PRODUCTLIST_GRID_INITIAL_ROWS, productdata);

            this.refreshGridApi()
            this.grids.first.updateServersidePaginationData(firstTabEntry == undefined
              || firstTabEntry == null ? 0 : firstTabEntry.total_rows, firstTabEntry == undefined
                || firstTabEntry == null ? 0 : firstTabEntry.total_pages, this.pagination == undefined ? 1 : this.pagination.page, this.pagination.pageSize == undefined ? defaultPageLimit : this.pagination.pageSize);
              }, 100);
            }else{
              this.hideLoading(this.gridApiProductOpening); 
              this.gridApiProductOpening?.api?.setRowData([]);
              this.productList = [];
            }
      },
      error:(err)=>{
        this.productList = [];
        this.gridApiProductOpening?.api?.setRowData([]);
      }
    })
  }


  refreshGridApi(){
    this.gridApiProductOpening?.api.refreshHeader();
  }

getCharacteristicsByProductId(productId:number){
  this.showLoading(this.gridApiProductCharacteristics);
  this.productOpeningService.getProductById(productId).pipe(catchError((err)=>this.handleError(err)))
  .subscribe((res)=>{
    if(res.isSuccess){
      this.hideLoading(this.gridApiProductCharacteristics); 
      this.productOpening= res?.response??[];
      if(!res?.response.productModel.showInStock && productId==0){
        this.productOpening.productModel.showInStock=true;
      }
      // this.editUser = this.productOpening.productModel;
      if(productId==0){
        this.productOpening?.productDetailModel?.map(a => {
          a.active = false;
          a.unitId = 1;
      });
      }
      // this.productOpening.billOfMaterialModel.billOfMaterialAdditionalCostModel = res?.response?.billOfMaterialModel?.billOfMaterialAdditionalCostModel??[]
      // this.productOpening.billOfMaterialModel.billOfMaterialItemModel = res?.response?.billOfMaterialModel?.billOfMaterialItemModel??[];
      this.gridApiProductCharacteristics?.api?.setRowData( this.productOpening?.productDetailModel)
      if(productId !=0){
        // this.productOpeningService.branchDetails.next(this.productOpening.productModel)
        this.makeCopy=true;
        this.getBranchId(this.productOpening?.productModel);
        if (this.productOpening?.productModel?.taxIds != undefined) {
          for (const item of this?.taxList) {
            item.active = false; 
            for (const tax of this.productOpening?.productModel?.taxIds) {
              // if (item?.id === tax?.id) {
                if (item?.taxId === tax?.taxId) {
                item.active = true;
                item.id = tax.id;
                item.taxValue = tax.taxValue;
                item.calculationMode =tax.calculationMode;
                item.isAlreadyAdded = true
                break;
              }
            }
          }
          this.taxListCopiedData = JSON.parse(JSON.stringify(this.taxList));
          this.taxGridApi.setRowData(this.taxList);
        }
      }else{
        this.hideLoading(this.gridApiProductCharacteristics); 
        // this.productOpening.billOfMaterialModel.billOfMaterialItemModel=[];
      }
    }
  })
}

makeCopyProduct(){
  let productName = this.productOpening?.productModel?.name;
  this.productOpening.productModel.name = 'Copy of '.concat(productName);
  this.makeCopy=false;
  this.productOpening.productModel.id=0;
  this.productDetailModel.id=0;
  this.copyProduct=true;
}

getBranchId(item){
if (JSON.stringify(item) !== "{}") {
  this.productOpening.productModel = item;
}
const branchIds = item?.branchIds ?? [];
this.productOpening.productModel.branchIds =item?.branchIds;
 this.branchIds = this.getAllDropdownModel?.branchDropdown.filter(branch => branchIds?.includes(Number(branch?.id))).map(branch => branch);
this.copySelectedBranches?.map((a) => (a.none = true));
}

getFilteredBranchIds(storageService, storageName) {
  this.userData = storageService.retrieve('user');
  const branchIds = [{ id: String(this.userData?.branchId), deleted: false, added: true, edited: false, none: false }];
  const branchDropdown = storageService.retrieve(storageName.BRANCH_DROPDOWN);
  const filteredBranchIds = branchDropdown?.filter(item =>branchIds.some(branchId => item.id === branchId.id));
  return filteredBranchIds;
}

getAllDropdownData(){
  this.getAllDropdownModel.branchDropdown = this.storageService.retrieve(this.storageName.BRANCH_DROPDOWN);
  this.getAllDropdownModel.categoryDropdown = this.storageService.retrieve(this.storageName.CATEGORY_DROPDOWN);
  this.getAllDropdownModel.collectionDropdown = this.storageService.retrieve(this.storageName.COLLECTION_DROPDOWN);
  this.getAllDropdownModel.applicableOnDropdown = this.storageService.retrieve(this.storageName.APPLICATIONON_DROPDOWN);
  this.getAllDropdownModel.calculationDropdown = this.storageService.retrieve(this.storageName.CALCULATION_DROPDOWN);
  this.getAllDropdownModel.stateDropdown = this.storageService.retrieve(this.storageName.STATE_DROPDOWN);  
  this.getAllDropdownModel.invoiceNo = this.storageService.retrieve(this.storageName.INVOICE_NO_DROPDOWN);  
  this.getAllDropdownModel.calculationDropdown = this.storageService.retrieve(this.storageName.CALCULATION_DROPDOWN);
}

clearDetails(){
  this.makeCopy=false;
  this.productModel = new ProductModel();
  this.productDetailModel = new ProductDetailModel();
  this.taxModel = new TaxModel();
  this.productDetails = null;
  this.getCharacteristicsByProductId(0);
  this.getAllTax();
  this.branchIds = this.getFilteredBranchIds(this.storageService, this.storageName);
  this.productOpeningService.previouscellClickedId=null;
}

tabChanged(value) {
  this.openTab = value;
  switch (this.openTab) {
      case 1: {
          break;
      }
      case 2: {
          break;
      }
      case 3: {
        break;
    }
  }
}

additionalCost(){
  this.AdditionalCost = !this.AdditionalCost
}

onSelect(event) {
  if(event.addedFiles.length>1){
    this.resourceMultipleFile(event.addedFiles)
    }else{
      this.resourceFile(event.addedFiles[0])
    }
}


resourceFile(item:any){
  this.processFile(item);
}

resourceMultipleFile(items:any){
  for(const file of items)
  this.processFile(file)
}

processFile(file: any) {
  console.log(file);
  this.compressImageService.compress(file).subscribe(
    compressedFile => {
  this.readThis(compressedFile)
    .then((base64String) => {
      if (base64String) {
        console.log(compressedFile);
        const billOfMaterialResourceObj ={
          base64Resource:base64String,
          fileName:file.name
        };
        // this.billOfMaterialResourceModel.push(billOfMaterialResourceObj);
        // this.selectedImage = this.billOfMaterialResourceModel[0].base64Resource;
        // this.imgOnLoad(this.billOfMaterialResourceModel);
      } else {
        console.error("Failed to get base64 string.");
      }
    })
    .catch((error) => {
      console.error("Error processing file:", error);
    });
  },
  error => {
    console.error('Error compressing file:', error);
  }
);

}

imgOnLoad(files){
  this.selectedImage = files[0]?.base64Resource;
 }

onRemove(file){
//   this.billOfMaterialResourceModel =   this.billOfMaterialResourceModel?.filter((x)=>x.fileName!=file?.fileName)
// this.imgOnLoad(this.billOfMaterialResourceModel);
}

previewImg(file){
this.selectedImage = file.base64Resource;
}

readThis(event: any): Promise<string | null> {
  return new Promise((resolve) => {
    const file: File = event;
    const myReader: FileReader = new FileReader()
    myReader.onloadend = (e) => {
      const base64String = myReader.result as string;
      resolve(base64String);
    };
    myReader.onerror = (error) => {
      console.error("File reading failed:", error);
      resolve(null);
    };
    if (file) {
      myReader.readAsDataURL(file);
    } else {
      console.error("No file selected.");
      resolve(null);
    }
  });
}

openCaratPopup(params){
  const modalOption: NgbModalOptions = {
    ...commonModalOptions,
    size: "md",
  };
  const modalRef = this.modalService.open(CaratPopupComponent, modalOption);
  modalRef.componentInstance.getAllDropdownModel = this.getAllDropdownModel;
  modalRef.result.then((result) => {
    if (result) {
      this.agGridService.updateGridDropdownList(params,this.storageName.CARAT_DROPDOWN,'caratId');
    }
  })
}
openLocationPopup(params){
  const modalOption: NgbModalOptions = {
    ...commonModalOptions,
    size: "md",
  };
  const modalRef = this.modalService.open(AddEditLocationComponent, modalOption);
  modalRef.componentInstance.getAllDropdownModel = this.getAllDropdownModel;
  modalRef.result.then((result) => {
    if (result) {
      this.agGridService.updateGridDropdownList(params,this.storageName.LOCATION_DROPDOWN,'locationId');
    }
  })
}



callApiForExport(){
  const exportAs = this.productListModel.exportAs;
  this.productOpeningService.getAllProduct(this.productListModel).subscribe(
    {
      next: (res) => {
      this.fileReaderService.exportFile(res, exportAs);
      },error(error) {
      console.error('Error in downloading Excel/Pdf file:', error);
      },
     }
  )
}
apply(){
  // if(this.filterObject.All){
  //   this.getPartiesRequestModel.CrOrDr = '';
  // }else if(this.filterObject.toReceive){
  //   this.getPartiesRequestModel.CrOrDr = 'Cr';
  // }else if(this.filterObject.toPay){
  //   this.getPartiesRequestModel.CrOrDr = 'Dr';
  // }
  // this.getLedgerList();
  // if(this.filterObject.All || this.filterObject.toReceive || this.filterObject.toPay){
  //   this.getLedgerList();
  // }else{
  //   this.toaster.error('first select filter option');
  // }
}

clear(){
  // this.filterObject.All = false;
  // this.filterObject.toReceive = false;
  // this.filterObject.toPay= false;
}


handleAllChange(): void {
  // if (this.filterObject.All) {
  //   this.filterObject.toReceive = true;
  //   this.filterObject.toPay = true;
  // } else{
  //   this.filterObject.toReceive = false;
  //   this.filterObject.toPay = false;
  // }
}

handleIndividualChange(): void {
  // this.filterObject.All = this.filterObject.toReceive && this.filterObject.toPay;
}

toggleExportDropdown() {
  if (this.dropdown.isOpen()) {
    this.dropdown.close();
  } else {
    this.dropdown.open();
  }
}

getSampleFileByPageName(){
  const requestData = {pageName:this.formName}
   this.productService.getSampleFileByPageName(requestData).subscribe({
     next:(res)=>{
       if(res?.isSuccess){
           this.FilePath = res?.response;
       }
     },error:(err)=>{
      console.log(err)
     }
   })
 }

 downloadSampleFile() {
  this.excelConverterServiceService.downloadXlsx(this.FilePath);
 }

 onFileSelected(event: any) {
  this.selectedFile = event.target.files[0];
  this.fileName = this.selectedFile.name;
}

uploadFile() {
  if (this.selectedFile) {
    this.productOpeningService.uploadFile(this.selectedFile).subscribe({
      next:(res)=>{
        if(res?.isSuccess){
          this.toaster.success(res?.message);
          this.getAllProductList();
          this.selectedFile = null;
          this.fileInput = null;
          this.fileName =null;
        }
      },error:(err)=>{
        console.log(err)
      }
    })
  }
}
deleteIconClicked(params){
  this.onDeleteRowClickedResponse(params?.detail?.data);
  this.productOpening      = new ProductOpening();
  this.getAllTax(); 
  this.getCharacteristicsByProductId(0); 

}
onTextCellClick(event) {
  this.openFieldValuePopUp(event);
}

  openFieldValuePopUp(params) {
    const valuesOrFunction = params?.values;
    const data = typeof valuesOrFunction === 'function' ? valuesOrFunction() : valuesOrFunction??[];
    const modalOption: NgbModalOptions = {
      ...commonModalOptions,
    };
    const modalRef = this.modalService.open(FieldValueComponent, modalOption);
    modalRef.componentInstance.label = params?.colDef?.headerName;
    modalRef.componentInstance.data = data;
    modalRef.componentInstance.selectedData = params?.data?.collectionsIds
      ? data?.filter(item => params.data.collectionsIds.map(id => id.toString()).includes(item.id))
      : [];   
  modalRef.result.then((result: any) => {
      if (result?.length) {
        const value = `${result?.length}`;
        params?.node?.setDataValue('collectionsIds', value);
        const ids = Array.isArray(result) && result.length > 0 && typeof result[0] === 'object' && 'id' in result[0]
        ? result.map(item => Number(item.id))
        : [];
        params.data.collectionsIds = ids;
        params?.api?.refreshCells();
      } else {
        const value = `${0}`;
        params?.node?.setDataValue('collectionsIds', value);
        params.data.collectionsIds = [];
        params?.api?.refreshCells();
      }
    });
  }
  ngOnDestroy() {
    this.gridApiProductOpening?.api?.setRowData([]);
    this.plusIconSubscription?.unsubscribe();
    !this.hide_Header? this.sendDataService.headerPageNameAndInvoiceNum.next(undefined):null;
    this.storageservice.removeItem(StorageName.PRODUCT_PRODUCTOPENING_PRODUCTLIST_GRID_INITIAL_ROWS);
    this.productOpeningService.previouscellClickedId =null;
  }
}
