<span
[ngbTooltip]="sendDataService?.noPermissionTooltip"
[container]="'body'"
(contextmenu)="onContextMenu($event)"
[disableTooltip]="!(params?.hasViewPermission === false)"
[ngClass]="{'cursor-not-allowed opacity-50':(params?.hasViewPermission === false)}"
><span [ngClass]="{'pointer_none': (params?.hasViewPermission === false)}" class="mx-2" (click)="onClick($event)" [ngClass]="{'cursorPointer': isCursor ,'d-block h-100':params?.widthFull}">{{name}}</span>
</span>


