import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ClosingReportEditIconService {

  public editClosingReport = new Subject<number>();
  editClosingReportClicked$ = this.editClosingReport.asObservable();


  triggerEditButtonClick(params: any) {
    this.editClosingReport.next(params);
  }
  
  constructor() { }
}
