<ng-container >
    <div class="modal-body mx-3 px-4">
        <div class="del_icon text-center d-flex justify-content-center align-items-center">
            <!-- <img src="../../../../../../assets/svg/delete-pop-up-icon.svg" alt=""> -->
            <svg id="Component_19_1" data-name="Component 19 – 1" xmlns="http://www.w3.org/2000/svg" width="45" height="54.236" viewBox="0 0 47.58 54.236">
                <path id="Path_1220" data-name="Path 1220" d="M-4226.73,3031.93h-28.723a1.183,1.183,0,0,0-.2-.091,4.906,4.906,0,0,1-4.037-4.5c-.318-2.947-.52-5.906-.766-8.861-.2-2.437-.4-4.876-.6-7.314q-.365-4.4-.739-8.809-.315-3.767-.627-7.534c-.086-1.045-.166-2.09-.249-3.145h43.151c-.2,2.425-.389,4.807-.587,7.189-.278,3.343-.565,6.684-.841,10.027-.24,2.9-.467,5.8-.705,8.7-.248,3.029-.514,6.056-.749,9.086a5.669,5.669,0,0,1-1.566,3.811A6.143,6.143,0,0,1-4226.73,3031.93Zm-16.033-21.827q0,6.507,0,13.014a4.624,4.624,0,0,0,.019.611,1.663,1.663,0,0,0,2.242,1.4,1.792,1.792,0,0,0,1.082-1.885q0-13.125,0-26.251a3.242,3.242,0,0,0-.037-.609,1.643,1.643,0,0,0-2.273-1.267,1.77,1.77,0,0,0-1.035,1.805Q-4242.756,3003.512-4242.764,3010.1Zm-5.011,13.432c-.217-3.389-.434-6.682-.637-9.975q-.521-8.438-1.027-16.876a1.688,1.688,0,0,0-1.741-1.708,1.707,1.707,0,0,0-1.592,1.857c.019.481.068.961.1,1.443q.244,3.941.485,7.883.312,5.024.627,10.047c.153,2.479.292,4.96.459,7.438a1.682,1.682,0,0,0,1.736,1.6A1.685,1.685,0,0,0-4247.774,3023.535Zm18.376-26.982a1.651,1.651,0,0,0-1.575-1.573,1.74,1.74,0,0,0-1.772,1.75c-.015.3-.024.593-.042.889q-.315,5.049-.631,10.1-.256,4.078-.516,8.156c-.159,2.516-.327,5.031-.468,7.547a1.683,1.683,0,0,0,1.566,1.822,1.694,1.694,0,0,0,1.76-1.634c.124-1.793.23-3.588.338-5.382.2-3.293.388-6.586.592-9.879C-4229.909,3004.518-4229.661,3000.69-4229.4,2996.552Z" transform="translate(4264.881 -2977.693)" fill="#f92b35"/>
                <path id="Path_1221" data-name="Path 1221" d="M12.585,6.7a22.822,22.822,0,0,1,.343-3.115A4.57,4.57,0,0,1,17.237.037c4.369-.044,8.739-.055,13.108,0A4.815,4.815,0,0,1,34.88,4.833c.022.592,0,1.185,0,1.863h.581c3,0,5.991-.009,8.986.006a3.152,3.152,0,0,1,2.976,2.318,3.3,3.3,0,0,1-1.163,3.687,7.2,7.2,0,0,1-1.756.658,1.006,1.006,0,0,1-.315.007q-20.4,0-40.807,0A3.2,3.2,0,0,1,.149,11.04,3.3,3.3,0,0,1,3.131,6.7C6.108,6.678,9.086,6.7,12.064,6.7Zm19.135-.01c0-.471,0-.894,0-1.316,0-1.368-.613-2.015-1.906-2.017q-3.251,0-6.5,0c-1.885,0-3.77,0-5.656,0A1.537,1.537,0,0,0,15.981,4.5a17.556,17.556,0,0,0-.159,2.189Z" transform="translate(0 0)" fill="#f92b35"/>
            </svg>
        </div>
        <div>
            <p class="title text-gray-color font-semibold f-16 m-0 text-center">Deleting {{productName?productName:'Gold Ring'}}</p>
            <p class="sub-info font-regular text-lighter-gray d-flex align-items-center f-14 mb-2 justify-content-center text-center">Are you sure you want to delete {{productName?productName:'Gold Ring'}} from the list</p>
        </div>
    </div>
    <div class="modal-footer border-0">
        <div class="col d-flex align-items-center justify-content-center cursorPointer m-0" (click)="activeModal.close()"><span class="text-gray-color font-semibold f-16">CANCEL</span></div>
        <div class="col d-flex align-items-center justify-content-center cursorPointer m-0" (click)="delete()"><span class="font-semibold text-color-danger f-16">DELETE</span></div>
    </div>
</ng-container>