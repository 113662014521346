<app-load-dropdown-data [formName]="formName"></app-load-dropdown-data>

<div class="modal-header d-flex align-items-center justify-content-center position-relative">
    <div><span class="font-semibold text-primary">Add Carat</span></div>
    <app-svg-icon [name]="'cross_button_border'" class="cross-button-border"></app-svg-icon>
    <app-svg-icon [name]="'cross'" class="cross-button" [ngClass]="currentHoverEffect" (click)="activeModal.dismiss()"></app-svg-icon>
  </div>
  <form ngNativeValidate #CaratForm="ngForm">
    <div class="modal-body mx-2">
      <div class="row mb-1">
        <div class="col-4 font-semibold text-gray-color d-flex align-items-center">Name<span class="text-color-danger">*</span></div>
        <div class="col-6 ps-0">
            <app-input 
            [inputName]="'carat'" 
            [inputId]="'carat'" 
            [inputType]="'text'" 
            [inputValue]="insertCaratRequestModel.name"
            (inputValueEmit)="insertCaratRequestModel.name=$event"
            [isRequired]="true"
            >
          </app-input>
        </div>
        <div (click)="this.insertCaratRequestModel.active = !this.insertCaratRequestModel.active;" class="col-2 d-flex align-items-center ps-0">
            <div class="col-9 f-14 font-semibold cursorPointer text-gray-color text-center text-nowrap ps-2 mb-0">
                Active
            </div>
            <div class="col d-flex justify-content-end align-items-center">
                <app-checkbox (keydown.enter)="onKeydown($event, 'userName')" [dyanamicId]="'active'" [isRequired]="false" [status]="insertCaratRequestModel.active" (check)="insertCaratRequestModel.active = $event"></app-checkbox>
            </div>
        </div>
      </div>
      <div class="row mb-1">
        <div class="col-4 font-semibold text-gray-color d-flex align-items-center">Purity %<span class="text-color-danger">*</span></div>
        <div class="col-8 ps-0">
          <!-- <app-select 
            [id]="'purityId'"
            [name]="'Purity'"
            [options]="getAllDropdownModel?.purityDropdown"
            [selectedValue]="insertCaratRequestModel?.purityId"
            (valueChanged)="insertCaratRequestModel.purityId=$event"
            [isRequired]="true"
        >
      </app-select> -->
      <app-input 
      [inputName]="'purityPer'" 
      [inputId]="'purityPer'" 
      [inputType]="'text'" 
      [inputValue]="insertCaratRequestModel.purityPer"
      (inputValueEmit)="insertCaratRequestModel.purityPer=$event"
      [isRequired]="true"
      >
    </app-input>
        </div>
      </div>
      <div class="row">
        <div class="col-4 font-semibold text-gray-color d-flex align-items-center">Description</div>
        <div class="col-8 ps-0">
            <app-input 
            [inputName]="'purity Description'" 
            [inputId]="'purityDescription'" 
            [inputType]="'text'" 
            [inputValue]="insertCaratRequestModel.description"
            (inputValueEmit)="insertCaratRequestModel.description=$event"
            >
          </app-input>
        </div>
      </div>
    </div>
    <div class="modal-footer pt-3 pb-2 mx-2">
      <app-button class="my-0 me-3" (buttonOutputEvent)="activeModal.close()" [buttonLabel]="'Close'" [buttonClass]="'pink_button'" [buttonType]="'button'">
      </app-button>
      <app-button class="m-0" [buttonLabel]="'Save'" (buttonOutputEvent)="InserCaratList()" [buttonClass]="'blue_button'" [buttonType]="'submit'">
      </app-button>
    </div>
  </form>