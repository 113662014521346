<div *ngIf="HeaderName=='In'&& firstColumnIndex!=0" class="d-flex justify-content-between align-items-center">
  <div class="">
    <app-button 
      class="text-nowrap" 
      [buttonLabel]="'Material Issue'" 
      [buttonClass]="'blue_button height_1-6 mt_008rem'"
      [buttonType]="'button'"
      (click)="openMaterialINPopUp()"
      >
    </app-button>
  </div>
  <div class="font-semibold f-14 color-dark-gray">
   {{HeaderName}}
  </div>
  <div class="d-flex">
    <div class="f-14 mb-0 color-dark-gray font-semibold">
      Balance :
    </div>
    <span class="f-14 text-red font-semibold ms-1"> 254.000Gm</span>
  </div>

</div>
<div *ngIf="HeaderName=='Out' && firstColumnIndex==0" class="d-flex justify-content-between align-items-center">
  <div class="d-flex">
    <div class="f-14 mb-0 color-dark-gray font-semibold">
      Balance :
    </div>
    <span class="f-14 text-red font-semibold ms-1"> 254.000Gm</span>
  </div>
  <div class="font-semibold f-14 color-dark-gray">
   {{HeaderName}}
  </div>
  <div class="">
    <app-button 
      class="text-nowrap" 
      [buttonLabel]="'Material Receive'" 
      [buttonClass]="'blue_button height_1-6 mt_008rem'"
      [buttonType]="'button'"
      (buttonOutputEvent)="openMaterialOutPopUp()"
      >
    </app-button>
  </div>
</div>
<div *ngIf="HeaderName=='Out' &&  firstColumnIndex!=0" class="d-flex justify-content-between align-items-center">

  <div class="">
    <app-button 
      class="text-nowrap" 
      [buttonLabel]="'Material Receive'" 
      [buttonClass]="'blue_button height_1-6 mt_008rem'"
      [buttonType]="'button'"
      (buttonOutputEvent)="openMaterialOutPopUp()"
      >
    </app-button>
  </div>
  <div class="font-semibold f-14 color-dark-gray">
   {{HeaderName}}
  </div>
  <div class="d-flex">
    <div class="f-14 mb-0 color-dark-gray font-semibold">
      Balance :
    </div>
    <span class="f-14 text-red font-semibold ms-1"> 254.000Gm</span>
  </div>
</div>
<div *ngIf="HeaderName=='In' && firstColumnIndex==0" class="d-flex justify-content-between align-items-center">
  <div class="d-flex">
    <div class="f-14 mb-0 color-dark-gray font-semibold">
      Balance :
    </div>
    <span class="f-14 text-red font-semibold ms-1"> 254.000Gm</span>
  </div>
  <div class="font-semibold f-14 color-dark-gray">
   {{HeaderName}}
  </div>
  
  <div class="">
    <app-button 
      class="text-nowrap" 
      [buttonLabel]="'Material Issue'" 
      [buttonClass]="'blue_button height_1-6 mt_008rem'"
      [buttonType]="'button'"
      (buttonOutputEvent)="openMaterialINPopUp()"
      >
    </app-button>
  </div>
</div>


<div *ngIf="HeaderName=='AvailableStock'">
  <div class="d-flex align-items-baseline ">
    <div class="text-primary font-semibold f-16">Available Stock</div>
    &nbsp;&nbsp;(<div class="text-gray-color font-semibold f-12">Total Wt :<span class="text-red"> {{totalWeightAvailable}}</span>,</div>
    &nbsp;<div class="text-gray-color font-semibold f-12">Qty :<span class="text-red"> {{totalQtyAvailable}}</span></div>)
  </div>
</div>

<div *ngIf="HeaderName=='scannedStock'">
  <div class="d-flex align-items-baseline ">
    <div class="text-primary font-semibold f-16">Scanned Stock</div>
      &nbsp;&nbsp;(<div class="text-gray-color font-semibold f-12">Total Wt :<span class="text-red"> {{totalWeightScanned}}</span>,</div>
      &nbsp;<div class="text-gray-color font-semibold f-12">Qty :<span class="text-red"> {{totalQtyScanned}}</span></div>)
  </div>
</div>
