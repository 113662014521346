import { Component, Input, OnInit, Renderer2 } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AgGridI } from '../../../ag-grid/ag-grid.model';
import { AgGridDataModelSelectPrintFormat } from './select-print-format.model';
import { LayoutDetails, User } from 'src/app/auth/auth/auth.model';
import { SettingsService } from 'src/app/components/Settings/settings.service';
import { RoleName } from 'src/app/shared/enum/common-enum';
import { StorageService } from 'src/app/shared/services/storage.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { AgGridService } from '../../../ag-grid/ag-grid.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-select-print-format-popup',
  templateUrl: './select-print-format-popup.component.html',
  styleUrls: ['./select-print-format-popup.component.scss']
})
export class SelectPrintFormatPopupComponent extends CommonService implements OnInit {
  @Input() voucherTypeId: number;
  @Input() modalTitle: string;
  private onRadioButtonSubscription: Subscription | undefined;
  agGridDataModel: AgGridI;
  gridApi: any;
  layoutDetails = new LayoutDetails();
  user = new User();
  roleNameEnum = RoleName;
  rdlcResourceName: any;

  constructor
    (public renderer: Renderer2,
      public activeModal: NgbActiveModal,
      public settingsService: SettingsService,
      public storageService: StorageService,
      public agGridService: AgGridService,
    ) {
    super(renderer)
    this.onRadioButtonSubscription = this.agGridService.onRadioButtonChange$.subscribe((params: any) => {
      this.rdlcResourceName = params?.data?.fileName;
    });
  }

  ngOnInit(): void {
    this.agGridDataModel = AgGridDataModelSelectPrintFormat;
    this.user = this.storageService.retrieve('user');
    this.layoutDetails = this.storageService.retrieve('layoutDetails');
  }

  onGridReady(event) {
    this.gridApi = event;
    this.getByRdlc();
  }

  getByRdlc() {
    const branchId = this.layoutDetails?.roleName == this.roleNameEnum.Admin ? this.user?.branchId : 0;
    const voucherTypeId = this.voucherTypeId;
    const metalId = 0;
    this.showLoading(this.gridApi);
    this.settingsService.getRDLCVoucherTypeById(branchId, voucherTypeId, metalId).subscribe({
      next: (res) => {
        if (res?.isSuccess) {
          this.rdlcResourceName = res?.response?.resourcePropertyModel.find((x) => x?.active == true).fileName;
          this.updateGridData(res?.response?.resourcePropertyModel);
        } else {
          this.gridApi?.api?.setRowData([]);
        }
      }, error: (err) => {
        this.hideLoading(this.gridApi);
        this.gridApi?.api?.setRowData([]);
      }
    })
  }

  updateGridData(data: any) {
    if (this.gridApi) {
      this.gridApi?.api?.setRowData(data);
    } else {
      console.error('Grid API not initialized yet');
    }
  }

  continue() {
    if (this.rdlcResourceName)
      this.activeModal.close(true);
  }

  ngOnDestroy() {
    this.onRadioButtonSubscription?.unsubscribe();
  }
}