
import { Component, OnInit, Renderer2, ViewChild } from '@angular/core';
import { CommonService } from '../../services/common.service';
import { AdministrationService } from 'src/app/components/administration/administration.service';
import { ToastrService } from 'ngx-toastr';
import { NgForm } from '@angular/forms';
import { catchError } from 'rxjs';
import { GetAllDocumentTypeResponseModel } from 'src/app/components/administration/administration.model';
import { SendDataService } from '../../services/send-data.service';


@Component({
  selector: 'app-document-type',
  templateUrl: './document-type.component.html',
  styleUrls: ['./document-type.component.scss']
})
export class DocumentTypeComponent extends CommonService implements OnInit {
  isAdd                           : boolean = false;
  getAllDocumentTypeData              : any[]=[];

  copyGetAllDocumentTypeData     : any[]=[];
  editDocumentTypeData           = new GetAllDocumentTypeResponseModel();
  @ViewChild('administrationFormData') administrationFormData! : NgForm ;

constructor(
  public renderer:Renderer2,
  public administrationService : AdministrationService,
  public toaster: ToastrService,
  public sendDataService : SendDataService,
) 
{
  super(renderer)
}
ngOnInit(): void {
  this.getAllDocumentTypeList();
  }

add(){
  if(!this.sendDataService?.permissionVM?.mastersCanAdd) return; if(!this.sendDataService?.permissionVM?.mastersCanAdd) return;
  this.isAdd = true;
    const obj = {id:0, name:'',isExpired: null,active:true}
    this.getAllDocumentTypeData?.push(obj); 
    this.copyGetAllDocumentTypeData?.push(obj);
    setTimeout(()=>{
      const element = document.getElementById(`documentType${obj.id}`);
      element?.focus();
    },0)
}


cancel(){
  if(this.isAdd)
  {
    this.isAdd = false;
    this.getAllDocumentTypeData = this.getAllDocumentTypeData.filter((x)=>x.id != 0)
    this.copyGetAllDocumentTypeData = this.copyGetAllDocumentTypeData.filter((x)=>x.id != 0);
  }
  else if(this.editDocumentTypeData?.id && this.editDocumentTypeData?.isEdit){
    const index = this.copyGetAllDocumentTypeData.findIndex((x)=>x.id == this.editDocumentTypeData?.id);
    const singleData =JSON.parse(JSON.stringify(this.copyGetAllDocumentTypeData))[index];
    this.getAllDocumentTypeData[index] = singleData;
    this.editDocumentTypeData = new GetAllDocumentTypeResponseModel();
  }  
}

edit(item,formData){
  if(!this.sendDataService?.permissionVM?.mastersCanUpdate) return;
  item.isEdit = true;
  const controlObj = formData?.form?.controls;
  if(controlObj){
    const id = Object.keys(controlObj)[0];
    const element = document.getElementById(id);
    element?.focus();
  }
  this.editDocumentTypeData = {...item};
}


checkIsEdit(arr:any[]):boolean{
  return arr?.some((x)=>x.isEdit)
 }
 

    // *********** DocumentType API START *********
    // **********get all DocumentType *********
    getAllDocumentTypeList() {
      this.administrationService.getAllDocumentType().subscribe({
        next:(res)=>{
          if(res.isSuccess){
            this.editDocumentTypeData = new GetAllDocumentTypeResponseModel();
            this.getAllDocumentTypeData = res.response??[];
            this.copyGetAllDocumentTypeData = JSON.parse(JSON.stringify(this.getAllDocumentTypeData))??[];
          }else{           
            this.getAllDocumentTypeData = []; 
            this.copyGetAllDocumentTypeData = [];
          }  
        },
        error:(err)=>{
          this.getAllDocumentTypeData = []; 
          this.copyGetAllDocumentTypeData = [];
        }
      })
    }

    // *************insert DocumentType **************
       InserDocumentTypeList(item) {
        const {id , ...rest}= item;
        this.administrationService.insertDocumentType(rest)
            .pipe(catchError((err) => this.handleError(err)))
            .subscribe((res) => {
              if(res.isSuccess){
                this.isAdd = false;
                this.getAllDocumentTypeList();
                this.toaster.success(res.message);
              }else{
                this.toaster.error(res.errors[0]);
              }  
          });
        }
    
    // **********update DocumentType *********
    UpdateDocumentTypeList(item) {
      const {isEdit , ...rest} = item;
      this.administrationService.updateDocumentType(rest)
          .pipe(catchError((err) => this.handleError(err)))
          .subscribe((res) => {
            if(res.isSuccess){
              this.isAdd = false;
              this.getAllDocumentTypeList();
              this.toaster.success(res.message);
            }else{
              this.toaster.error(res.errors[0]);
            }  
        });
      } 

      // **********delete DocumentType *********
      DeleteDocumentTypeList(id) {
        if(!this.sendDataService?.permissionVM?.mastersCanDelete) return;
        this.administrationService.deleteDocumentType(id)
            .pipe(catchError((err) => this.handleError(err)))
            .subscribe((res) => {
              if(res.isSuccess){
                this.getAllDocumentTypeList();
                this.toaster.success(res.message);
              }else{
                this.toaster.error(res.errors[0]);
              }  
        });
      }
}

