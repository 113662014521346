<div class="modal-header d-flex align-items-center justify-content-center position-relative">
    <div><span class="font-semibold text-primary">{{ modalTitle }}</span></div>
</div>
<form class="theme-form" ngNativeValidate #SelectPrintFormatForm="ngForm">
    <div class="modal-body mx-0">
        <div class="row mx-0">
            <div class="h-100 position-relative">
                <app-ag-grid
                id="SelectPrintFormat"  
                [dataModel]="agGridDataModel"
                [columnSettings]="false"
                (gridReady)="onGridReady($event)"
                >
                </app-ag-grid>
            </div>
        </div>  
    </div>
    <div class="modal-footer d-flex align-items-center justify-content-center mx-2">
        <div  class="my-2">
             <app-button class="" [buttonId]="'continue'" [buttonLabel]="'Continue'" [buttonClass]="'blue_button'" [buttonType]="'submit'"  (buttonOutputEvent)="continue()">
            </app-button> 
        </div>
    </div>
</form>

