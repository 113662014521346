import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss']
})
export class SearchInputComponent {
  @Input() inputSerachValue;
  @Input() isVisibleSearchIcon = true;
  @Output() inputSerachValueEmit:EventEmitter<string> = new EventEmitter();
  @Output() inputActionIconClick:EventEmitter<string> = new EventEmitter();
  

}
