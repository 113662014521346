
<div class="modal-header d-flex align-items-center justify-content-center position-relative">
    <div><span class="font-semibold text-primary">{{label ?? 'Field Value'}}</span></div>
    <app-svg-icon [name]="'cross_button_border'" class="cross-button-border"></app-svg-icon>
    <app-svg-icon [name]="'cross'" [ngClass]="currentHoverEffect" class="cross-button" (click)="activeModal.dismiss()"></app-svg-icon>
</div>
<form autocomplete="off"  class="theme-form" ngNativeValidate #FieldValueForm="ngForm">
    <div class="modal-body px-1 pb-0">
        <div *ngIf="!label" class="col-12 d-flex gap-3">
            <app-input 
                [inputName]="'value'" 
                [inputType]="'text'" 
                [inputId]="'value'"
                [inputValue]="fieldValue" 
                (inputValueEmit)="fieldValue = $event"
                [isRequired]="true"
                >
                <!-- (keydown.enter)="onKeydown($event, 'branchId')" -->
            </app-input>
            <div class="d-flex justify-content-end align-items-center" >
              <button class="p-0 border-0 d-flex m-0 add_btn" type="button" (click)="onAddValue(fieldValue)">
                  <svg  class="cursorPointer plusIcon-hover" xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
                    <g id="Group_3496" data-name="Group 3496" transform="translate(-0.023)">
                      <circle id="Ellipse_59" data-name="Ellipse 59" cx="11" cy="11" r="11" transform="translate(0.023)" fill="#e8ecf3"/>
                      <path id="Union_4" data-name="Union 4" d="M5.187-1a.687.687,0,0,1,.687.687V4.5h4.812a.687.687,0,1,1,0,1.375H5.875v4.812a.687.687,0,1,1-1.375,0V5.875H-.313A.687.687,0,0,1-1,5.187.687.687,0,0,1-.313,4.5H4.5V-.313A.687.687,0,0,1,5.187-1Z" transform="translate(6.023 6)" fill="#a3abb8"/>
                    </g>
                </svg>
              </button>
              <button class="d-none" id="hide" type="submit"></button>
            </div>
        </div>
        <div *ngIf="label" class="col-12 d-flex gap-3">
          <app-input 
              [inputName]="'value'" 
              [inputType]="'text'" 
              [inputId]="'value'"
              [inputValue]="searchValue" 
              (inputValueEmit)="searchValue = $event"
              [isRequired]="false"
              >
              <!-- (keydown.enter)="onKeydown($event, 'branchId')" -->
          </app-input>
      </div>
        <div class="border d-flex flex-column m-t-10 mb-2 field-value-table">
          <div *ngIf="!label" class="border-bottom header p-2 py-1 f-14 font-semibold text-gray-color">
            Field Value
          </div>
          <div *ngIf="!label" class="flex-fill overflow-auto h-0">
            <div class="border-bottom p-2 py-1 f-14  font-regular text-gray-color d-flex field_value_display" *ngFor="let item of fieldValues, let i=index">
              <span class="w-100 text-truncate w-100">{{item}}</span>
              <div class="icon_edit_delete d-flex gap-2 pe-2">
                <app-svg-icon name="edit" (click)="edit(item ,i)" ></app-svg-icon>
                <app-svg-icon name="delete_red" (click)="deleteFieldValue(item ,i)"></app-svg-icon>
            </div>
            </div>
          </div>
          <div *ngIf="label" class="border d-flex flex-column field-value-table">
            <div class="border-bottom header d-flex align-items-center gap-2 p-2 py-1 f-14 font-semibold text-gray-color">
              <app-checkbox [status]="data?.length == selectedData?.length" [dyanamicId]="'selectAll'" (check)="selectAll($event)"  [lableName]="'Select All'" ></app-checkbox>
            </div>
            <div class="flex-fill overflow-auto h-0">
              <div class="border-bottom p-2 py-1 f-14 d-flex align-items-center gap-2 font-regular text-gray-color d-flex field_value_display" *ngFor="let item of data | filter: searchValue; let i=index" >
                  <app-checkbox [status]="check(item?.id)"  [dyanamicId]="'name'+i" (check)="add(item)" [lableName]="item?.name" ></app-checkbox>
              </div>
            </div>
          </div>
  
        </div>
        
        <hr class="m-0">
        <div class="d-flex py-2  align-items-center justify-content-center cursorPointer text-primary f-14 font-semibold"
            (click)="!label?OnSaveFieldsValues():apply()">
            Done
        </div>
    </div>
</form>