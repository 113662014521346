<ng-container >
    <div class="modal-body pt-4 pb-4">
        <div class="row">
            <div class="col-12 d-flex justify-content-center mb-4">
                <img src="./assets/SVGicon/delete-confirm-icon.svg" alt="">
            </div>
            <div class="col-12 ">
                <div class="d-flex justify-content-center">
                    <h5 class="text-gray-color f-16 mb-0  font-semibold ">Deleting </h5>
                </div>
                <div class="d-flex justify-content-center">
                    <p class="theme-color-secondary f-13 w-p-200 mt-1 text-center font-regular mb-0">Are you sure you want to delete {{userData?.invoiceNo}} from this list</p>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer d-block">
        <div class="row m-0">
            <div class="col-6 border-right d-flex justify-content-center align-items-center">
                <label class="f-14 font-semibold cursorPointer py-2 mb-0 text-gray-color" (click)="activeModal.close(false)">CANCEL</label>
            </div>
            <div class="col-6 d-flex justify-content-center align-items-center">
                <label class="f-14 font-semibold cursorPointer py-2 mb-0 text-danger" (click)="onDelete()">DELETE</label>
            </div>
        </div>
    </div>
</ng-container>