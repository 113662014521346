<div class="w-100 common_ngb_DropdownContainer">
    <div ngbDropdown class="d-inline-block w-100">
        <button type="button" class="btn dropdown_select d-flex align-items-center w-100 p-0 border-gray" [ngClass]="{'bordernone': dropdownGrid}" id="dropdownBasic1" ngbDropdownToggle>
            <input 
            class="form-control form-control-sm" 
            type="text" 
            [(ngModel)]="selectValue" 
            placeholder="" 
            readonly
            >   
            <div class="px-2 h-100 d-flex align-items-center p-7" [ngClass]="{'theme-bg-lightergray-color border-right': dropdownGrid}">
                <svg xmlns="http://www.w3.org/2000/svg" width="12.414" height="6.555" viewBox="0 0 12.414 6.555">
                    <path id="Path_1380" data-name="Path 1380" d="M-1841.061,5637.15l5.5,5.348,5.5-5.348" transform="translate(1841.768 -5636.443)" fill="none" stroke="#a3abb8" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
                </svg>                      
            </div>        
        </button>
        <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="w-100 p-0">
            <button *ngFor="let item of options" [value]="item.id" (click)="onSelect(item)" ngbDropdownItem>{{item?.name}}</button>
        </div>
    </div>
</div>
