import { EditableCallbackParams } from "ag-grid-community";
import { StorageName } from "../../enum/common-enum";
import { DiamondCategory } from "../../models/common.model";
import { StorageService } from "../../services/storage.service";
import { AgGridCustomDropdownComponent } from "../ag-grid/ag-grid-custom-dropdown/ag-grid-custom-dropdown.component";
import { AgGridCustomInputComponent } from "../ag-grid/ag-grid-custom-input/ag-grid-custom-input.component";
import { AgColDefsI, AgGridI } from "../ag-grid/ag-grid.model";
import { CheckboxRendererComponent } from "../ag-grid/checkbox-renderer/checkbox-renderer.component";
import { CustomHeaderComponent } from "../ag-grid/custom-header/custom-header.component";
import { EditDeleteIconComponent } from "../ag-grid/edit-delete-icon/edit-delete-icon.component";
import { TextRendererComponent } from "../ag-grid/text-renderer/text-renderer.component";
import { AlloyWtColumn, RequestedPurityColumn, RequestedWtColumn, UDF1, UDF10, UDF11, UDF12, UDF13, UDF14, UDF15, UDF16, UDF17, UDF18, UDF19, UDF2, UDF20, UDF21, UDF22, UDF23, UDF24, UDF25, UDF26, UDF27, UDF28, UDF29, UDF3, UDF30, UDF4, UDF5, UDF6, UDF7, UDF8, UDF9 } from "../metal-section/metal-section.model";
import { JwellaryImageComponent } from "../ag-grid/jwellary-image/jwellary-image.component";

const IdColumn: AgColDefsI = {
  headerName: "Id",
  field: "id",
  sortable: false,
  hide : true,
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0",
  minWidth: 80,
  flex: 1,
};

const PurchaseAmountColumn: AgColDefsI = {
  headerName: "Purchase Amount",
  field: "purchaseAmount",
  minWidth: 120,
  sortable: true,
  editable: true,
  resizable: false,
  cellRenderer: TextRendererComponent,
  headerComponentParams: {isRequired: false, enableSorting: false},
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0",
  cellEditor:AgGridCustomInputComponent,
  cellEditorParams:{
    decimalPlaces:2,
    isOnlyNumbers:true,
    inputMaxlength:8
  },
}

const SaleAmountColumn: AgColDefsI = {
  headerName: "Sale Amount",
  field: "saleAmount",
  minWidth: 120,
  sortable: true,
  editable: true,
  resizable: false,
  cellRenderer: TextRendererComponent,
  headerComponentParams: {isRequired: false, enableSorting: false},
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0",
  cellEditor:AgGridCustomInputComponent,
  cellEditorParams:{
    decimalPlaces:2,
    isOnlyNumbers:true,
    inputMaxlength:8
  },
}

const VoucherTypeIdColumn: AgColDefsI = {
  headerName: "voucherTypeId",
  field: "voucherTypeId",
  sortable: false,
  hide : true,
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0",
  minWidth: 80,
  flex: 1,
};

const CheckboxColumn: AgColDefsI = {
  headerName: "",
  headerComponentParams: { selectAll : true },
  rowDrag: true,
  field: "active",   
  resizable: false, 
  pinned: "left",
  suppressNavigable: true,
  lockPinned: true,
  cellClass: "custom-column-group-cell",
  cellRendererParams: { showLabel: false}, 
  cellRenderer:CheckboxRendererComponent  ,
  headerClass:" justify-content-center px-0  border-0",
  minWidth: 80,
  maxWidth: 80,
  editable:false, 
};


//#region  Interfaces Gold and Silver

export const ItemListImageColumn: AgColDefsI = {
  headerName: "",
  headerClass:
    "px-0 custom-column-group-cell border-0 f-14 text-gray-color font-semibold",
  field: "checkbox",
  cellRenderer: JwellaryImageComponent,
  cellClass: "px-0 custom-column-group-cell",
  minWidth: 36,
  maxWidth: 36,
  editable:false,
  lockPosition:'left',
  cellRendererParams:{fromDetailJson: true},
  headerComponentParams: { enableSorting: false},
  resizable: true,
  extraField:'JewelleryImg',
  cellStyle: { cursor: 'pointer' },
};

const ReverseColumn: AgColDefsI = {
  headerName: "Reverse",
  headerComponentParams: { isRequired: false , enableSorting: false },
  field: "reverseCalculationAmount",
  sortable: true,
  minWidth: 110,
  editable: true,
  isRequired: false,
  cellClass: "custom-column-group-cell px-0 text-center",
  headerClass: "custom-column-group-cell px-0",
  cellRenderer: TextRendererComponent,
};

const BarcodeColumn: AgColDefsI = {
  headerName: "Barcode No.",
  headerComponentParams: { isRequired: false , enableSorting: false },
  field: "barcodeNo",
  sortable: true,
  minWidth: 110,
  editable: false,
  isRequired: false,
  cellClass: "custom-column-group-cell px-0 text-center",
  headerClass: "custom-column-group-cell px-0",
  cellRenderer: TextRendererComponent,
};

const HUIDColumn: AgColDefsI = {
  headerName: "HUID No.",
  headerComponentParams: { isRequired: false , enableSorting: false},
  field: "huidNo",
  sortable: true,
  minWidth: 110,
  editable: true,
  isRequired: false,
  cellClass: "custom-column-group-cell px-0 text-center",
  headerClass: "custom-column-group-cell px-0",
  cellRenderer: TextRendererComponent
};

export const ProductColumn: AgColDefsI = {
  headerName: "Product",
  headerComponentParams:{
    filterValueColumnName: "productId",
    filterIdColumnName: "productId",
    filterParams: StorageName.PRODUCT_DROPDOWN,
    floatingMultiselectDropDown: true,
    isRequired: true, enablePlusIconLight: true , enableSorting: false
},
  field: "productDetailId",
  minWidth: 110,
  sortable: true,
  editable: true,
  cellRenderer: TextRendererComponent,
  cellEditor: AgGridCustomDropdownComponent,
  cellEditorParams: {
    values: () => {
      const storageService = new StorageService();
      const storage = StorageName;
      return storageService.retrieve(storage.PRODUCT_DROPDOWN);
    },
  },
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0",
};

const SubCategoryColumn: AgColDefsI = {
  headerName: "Category",
  headerComponentParams: { isRequired: false, enablePlusIconLight: true , enableSorting: false},
  field: "subCategoryId",
  minWidth: 140,
  sortable: true,
  editable: true,
  cellRenderer: TextRendererComponent,
  cellEditor: AgGridCustomDropdownComponent,
  cellEditorParams: {
    values: () => {
      const storageService = new StorageService();
      const storage = StorageName;
      return storageService.retrieve(storage.CATEGORY_DROPDOWN);
    },
  },
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0",
};


const CaretColumn: AgColDefsI = {
  headerName: "Carat",
  headerComponentParams: { isRequired: false, enablePlusIconLight: true , enableSorting: false},
  field: "caratId",
  colId:'caratId', // this is for custom changes..
  minWidth: 100,
  sortable: true,
  editable: true,
  cellRenderer: TextRendererComponent,
  cellEditor: AgGridCustomDropdownComponent,
  cellEditorParams: {
    values: () => {
      const storageService = new StorageService();
      const storage = StorageName;
      return storageService.retrieve(storage.CARAT_DROPDOWN);
    },
  },
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0",
};

export const PurityColumn: AgColDefsI = {
  headerName: "Purity",
  headerComponentParams: { isRequired: false , enableSorting: false},
  field: "purityPer",
  sortable: true,
  minWidth: 110,
  editable: true,
  // isRequired: true,
  cellClass: "custom-column-group-cell px-0 text-center",
  headerClass: "custom-column-group-cell px-0",
  cellRenderer: TextRendererComponent,
  cellEditor:AgGridCustomInputComponent,
  cellEditorParams:{
    decimalPlaces:3,
    isOnlyNumbers:true,
    inputMaxlength:8
  }
};

export const PurityWtColumn: AgColDefsI = {
  headerName: "Purity Wt.",
  headerComponentParams: { isRequired: false , enableSorting: false},
  field: "purityWt",
  sortable: true,
  minWidth: 110,
  editable: true,
  // isRequired: true,
  cellClass: "custom-column-group-cell px-0 text-center",
  headerClass: "custom-column-group-cell px-0",
  cellRenderer: TextRendererComponent,
  cellEditor:AgGridCustomInputComponent,
  cellEditorParams:{
    decimalPlaces:3,
    isOnlyNumbers:true,
    inputMaxlength:8
  }
};

export const DiamondCaratColumn: AgColDefsI = {
  headerName: "Carat",
  headerComponentParams: { isRequired: false , enableSorting: false},
  field: "carat",
  sortable: true,
  minWidth: 110,
  editable: true,
  // isRequired: true,
  cellClass: "custom-column-group-cell px-0 text-center",
  headerClass: "custom-column-group-cell px-0",
  cellRenderer: TextRendererComponent,
  cellEditor:AgGridCustomInputComponent,
  cellEditorParams:{
    decimalPlaces:3,
    isOnlyNumbers:true,
    inputMaxlength:8
  }
};

export const WastagePerColumn: AgColDefsI = {
  headerName: "Wastage Per.",
  headerComponentParams: { isRequired: false , enableSorting: false},
  field: "wastagePer",
  sortable: true,
  minWidth: 120,
  editable: true,
  // isRequired: true,
  cellClass: "custom-column-group-cell px-0 text-center",
  headerClass: "custom-column-group-cell px-0",
  cellRenderer: TextRendererComponent,
  cellEditor:AgGridCustomInputComponent,
  cellEditorParams:{
    decimalPlaces:2,
    isOnlyNumbers:true,
    inputMaxlength:5
  }
};

export const WastageWtColumn: AgColDefsI = {
  headerName: "Wastage Wt.",
  headerComponentParams: { isRequired: false , enableSorting: false},
  field: "wastageWt",
  sortable: true,
  minWidth: 120,
  editable: true,
  // isRequired: true,
  cellClass: "custom-column-group-cell px-0 text-center",
  headerClass: "custom-column-group-cell px-0",
  cellRenderer: TextRendererComponent,
  cellEditor:AgGridCustomInputComponent,
  cellEditorParams:{
    decimalPlaces:3,
    isOnlyNumbers:true,
    inputMaxlength:8
  }
};

export const QuantityColumn: AgColDefsI = {
  headerName: "Quantity",
  field: "quantity",
  minWidth: 120,
  headerComponentParams: { isRequired: true , enableSorting: false},
  headerClass: "custom-column-group-cell px-0",
  cellClass: "custom-column-group-cell",
  editable: true,
  sortable: true,
  isRequired:true,
  cellRenderer: TextRendererComponent,
  cellEditor:AgGridCustomInputComponent,
  cellEditorParams:{
    isOnlyNumbers:true,
  },
  valueGetter: (param) => {
    return param.data.quantity !== undefined && param.data.quantity !== null? param.data.quantity: 1;
  }
};

const PktWtColumn: AgColDefsI = {
  headerName: "Pkt. Wt.",
  field: "packetWt",
  minWidth: 90,
  headerComponentParams: { enableSorting: false},
  headerClass: "custom-column-group-cell px-0",
  cellClass: "custom-column-group-cell",
  editable: true,
  sortable: true,
  cellRenderer: TextRendererComponent,
  cellEditor:AgGridCustomInputComponent,
  cellEditorParams:{
    decimalPlaces:3,
    isOnlyNumbers:true,
    inputMaxlength:8
  }
};

const PktLessWtColumn: AgColDefsI = {
  headerName: "PKt. Less Wt.",
  field: "packetLessWt",
  headerComponentParams: { isRequired: false, enablePlusIconLight: true , enableSorting: false },
  minWidth: 140,
  headerClass: "custom-column-group-cell px-0",
  cellClass: "custom-column-group-cell",
  editable: true,
  sortable: true,
  cellRenderer: TextRendererComponent,
  cellEditor:AgGridCustomInputComponent,
  cellEditorParams:{
    decimalPlaces:3,
    isOnlyNumbers:true,
    inputMaxlength:8
  }
};

const GrossWtColumn: AgColDefsI = {
  headerName: "Gross Wt",
  field: "grossWt",
  minWidth: 90,
  headerClass: "custom-column-group-cell px-0",
  cellClass: "custom-column-group-cell",
  editable: true,
  sortable: true,
  cellRenderer: TextRendererComponent,
  cellEditor:AgGridCustomInputComponent,
  headerComponentParams: { enableSorting: false},
  cellEditorParams:{
    decimalPlaces:3,
    isOnlyNumbers:true,
    inputMaxlength:8
  }
}
export const WeightColumn: AgColDefsI = {
  headerName: "Weight",
  field: "weight",
  minWidth: 90,
  headerClass: "custom-column-group-cell px-0",
  cellClass: "custom-column-group-cell",
  editable: true,
  sortable: true,
  cellRenderer: TextRendererComponent,
  cellEditor:AgGridCustomInputComponent,
  headerComponentParams: { enableSorting: false},
  cellEditorParams:{
    decimalPlaces:3,
    isOnlyNumbers:true,
    inputMaxlength:8
  }
}


export const LessWtColumn: AgColDefsI = {
  headerName: "Less Wt.",
  field: "lessWt",
  minWidth: 100,
  headerClass: "custom-column-group-cell px-0",
  cellClass: "custom-column-group-cell",
  editable: true,
  sortable: true,
  headerComponentParams: { enableSorting: false},
  cellRenderer: TextRendererComponent,
  cellEditor:AgGridCustomInputComponent,
  cellEditorParams:{
    decimalPlaces:3,
    isOnlyNumbers:true,
    inputMaxlength:8
  }
};

export const NetWtColumn: AgColDefsI = {
  headerName: "Net Wt.",
  field: "netWt",
  minWidth: 100,
  headerClass: "custom-column-group-cell px-0",
  cellClass: "custom-column-group-cell",
  editable: false,
  sortable: true,
  headerComponentParams: { enableSorting: false},
  cellRenderer: TextRendererComponent,
  cellEditor:AgGridCustomInputComponent,
  cellEditorParams:{
    decimalPlaces:3,
    isOnlyNumbers:true,
    inputMaxlength:8
  }
};

export const FinalWtColumn: AgColDefsI = {
  headerName: "Final Wt.",
  field: "finalWt",
  minWidth: 110,
  headerClass: "custom-column-group-cell px-0",
  cellClass: "custom-column-group-cell",
  editable: false,
  sortable: true,
  headerComponentParams: { enableSorting: false},
  cellRenderer: TextRendererComponent,
  cellEditor:AgGridCustomInputComponent,
  cellEditorParams:{
    decimalPlaces:3,
    isOnlyNumbers:true,
    inputMaxlength:8
  }
};

export const RateColumn: AgColDefsI = {
  headerName: "Rate",
  field: "rate",
  minWidth: 100,
  headerComponentParams: { isRequired: true ,enableSorting: false},
  headerClass: "custom-column-group-cell px-0",
  cellClass: "custom-column-group-cell",
  editable: true,
  sortable: true,
  isRequired:true,
  cellRenderer: TextRendererComponent,
  cellEditor:AgGridCustomInputComponent,
  cellEditorParams:{
    decimalPlaces:2,
    isOnlyNumbers:true,
  }
};

const RatePerColumn: AgColDefsI = {
  headerName: "Rate %.",
  field: "ratePer",
  minWidth: 100,
  headerComponentParams: { isRequired: true  ,enableSorting: false},
  headerClass: "custom-column-group-cell px-0",
  cellClass: "custom-column-group-cell",
  editable: true,
  sortable: true,
  isRequired:true,
  cellRenderer: TextRendererComponent,
  cellEditor:AgGridCustomInputComponent,
  cellEditorParams:{
    decimalPlaces:2,
    isOnlyNumbers:true,
  }
};

export const AmountColumn: AgColDefsI = {
  headerName: "Amount",
  field: "amount",
  minWidth: 100,
  headerClass: "custom-column-group-cell px-0",
  cellClass: "custom-column-group-cell",
  headerComponentParams: { enableSorting: false},
  editable: false,
  sortable: true,
  cellRenderer: TextRendererComponent,
  cellEditor:AgGridCustomInputComponent,
  cellEditorParams:{
    decimalPlaces:2,
    isOnlyNumbers:true,
  }
};

export const MetalValueColumn: AgColDefsI = {
  headerName: "Metal Value",
  field: "metalValue",
  minWidth: 100,
  headerClass: "custom-column-group-cell px-0",
  cellClass: "custom-column-group-cell",
  headerComponentParams: { enableSorting: false},
  editable: false,
  sortable: true,
  cellRenderer: TextRendererComponent,
  cellEditor:AgGridCustomInputComponent,
  cellEditorParams:{
    decimalPlaces:2,
    isOnlyNumbers:true,
  }
};


export const GoldAmountColumn: AgColDefsI = {
  headerName: "Gold Amount",
  field: "goldAmount",
  minWidth: 100,
  headerClass: "custom-column-group-cell px-0",
  cellClass: "custom-column-group-cell",
  headerComponentFramework : 
    'app-custom-header'
  ,
  headerComponentParams: { enableSorting: false},
  sortable: true,
  cellRenderer: TextRendererComponent,
  cellEditor:AgGridCustomInputComponent,
  cellEditorParams:{
    decimalPlaces:2,
    isOnlyNumbers:true,
  },
  editable: (params) => {
    if(params.data.diamondCategoryId == DiamondCategory.LooseDiamond || params.data.diamondCategoryId == DiamondCategory.CeritifiedDiamond){
      return false;
    }else{
      return true;
    }
},
};

const MakingColumn: AgColDefsI = {
  headerName: "Making",
  field: "making",
  marryChildren: true,
  sortable: true,
  // isRequired: true,
  headerComponentParams: { isRequired: true ,enableSorting: false},
  headerClass: "custom-column-group-cell justify-content-center px-0",
  children: [
    {
      headerName: "Type",
      field: "makingTypeId",
      headerClass: "custom-column-group-cell child_header px-0",
      cellClass: "custom-column-group-cell px-0",  
      minWidth: 80,
      editable: false,
      sortable: true,
      cellRenderer: TextRendererComponent,
      cellEditor: AgGridCustomDropdownComponent,
      headerComponentParams: {enableSorting: false},
      cellEditorParams: {
        values: () => {
          const storageService = new StorageService();
          const storage = StorageName;
          return storageService.retrieve(storage.MAKINGCHARGESTYPE_DROPDOWN);
        },
      },
     valueGetter: (param) => 
        {
          const storageService = new StorageService();
          const storage = StorageName;
          const storeData =  storageService.retrieve(storage.MAKINGCHARGESTYPE_DROPDOWN);
          const selcetedId = storeData?.find(a => a.id == param.data.makingTypeId);
          return selcetedId != undefined && selcetedId.id !== undefined && selcetedId.id !== null? param.data.makingTypeId : 2;
       }
    },
    {
      field: "makingRate",
      cellClass: "customcolumn-group-cell px-0",
      headerName: "Rate",
      minWidth: 100,
      editable: false,
      sortable: true,
      headerComponentParams: { enableSorting: false},
      headerClass: "custom-column-group-cell child_header px-0",
      cellRenderer: TextRendererComponent,
      cellEditor:AgGridCustomInputComponent,
      cellEditorParams:{
        decimalPlaces:2,
        isOnlyNumbers:true,
      }
    },
    {
      field: "makingDiscount",
      cellClass: "custom-column-group-cell px-0",
      headerName: "Discount",
      minWidth: 120,
      sortable: true,      
      editable: false,
      headerComponentParams: { enableSorting: false},
      headerClass: "custom-column-group-cell child_header px-0",
      cellRenderer: TextRendererComponent,
      cellEditor:AgGridCustomInputComponent,
      cellEditorParams:{
        decimalPlaces:2,
        isOnlyNumbers:true,
      }
    },
    {
      field: "makingAmount",
      cellClass: "custom-column-group-cell px-0",
      headerName: "Amount",
      minWidth: 120,
      editable: false,
      sortable: true,
      headerComponentParams: { enableSorting: false},
      headerClass: "custom-column-group-cell child_header px-0",
      cellRenderer: TextRendererComponent,
      cellEditor:AgGridCustomInputComponent,
      cellEditorParams:{
        decimalPlaces:2,
        isOnlyNumbers:true,
      }
    },
  ],
};


const DiamondMakingColumn: AgColDefsI = {
  headerName: "Making",
  field: "making",
  marryChildren: true,
  sortable: true,
  // isRequired: true,
  headerComponentParams: { isRequired: true ,enableSorting: false},
  headerClass: "custom-column-group-cell justify-content-center px-0",
  children: [
    {
      headerName: "Type",
      field: "makingTypeId",
      headerClass: "custom-column-group-cell child_header px-0",
      cellClass: "custom-column-group-cell px-0",  
      minWidth: 80,
      editable: true,
      sortable: true,
      cellRenderer: TextRendererComponent,
      cellEditor: AgGridCustomDropdownComponent,
      headerComponentParams: {enableSorting: false},
      cellEditorParams: {
        values: () => {
          const storageService = new StorageService();
          const storage = StorageName;
          return storageService.retrieve(storage.MAKINGCHARGESTYPE_DROPDOWN);
        },
      },
     valueGetter: (param) => 
        {
          const storageService = new StorageService();
          const storage = StorageName;
          const storeData =  storageService.retrieve(storage.MAKINGCHARGESTYPE_DROPDOWN);
          const selcetedId = storeData?.find(a => a.id == param.data.makingTypeId);
          return selcetedId != undefined && selcetedId.id !== undefined && selcetedId.id !== null? param.data.makingTypeId : 2;
       }
    },
    {
      field: "makingRate",
      cellClass: "customcolumn-group-cell px-0",
      headerName: "Rate",
      minWidth: 100,
      // editable: true,
      sortable: true,
      headerComponentParams: { enableSorting: false},
      headerClass: "custom-column-group-cell child_header px-0",
      cellRenderer: TextRendererComponent,
      cellEditor:AgGridCustomInputComponent,
      cellEditorParams:{
        decimalPlaces:2,
        isOnlyNumbers:true,
      },
      editable: (params) => {
        return params.data.makingTypeId != '1'
    },
    },
    {
      field: "makingAmount",
      cellClass: "custom-column-group-cell px-0",
      headerName: "Amount",
      minWidth: 120,
      // editable: false,
      sortable: true,
      headerComponentParams: { enableSorting: false},
      headerClass: "custom-column-group-cell child_header px-0",
      cellRenderer: TextRendererComponent,
      cellEditor:AgGridCustomInputComponent,
      cellEditorParams:{
        decimalPlaces:2,
        isOnlyNumbers:true,
      },
      editable: (params) => {
        return params.data.makingTypeId == '1'
    },
    },
  ],
};

const StoneAmount: AgColDefsI = {
  headerName: "Stone Amount",
  field: "stoneAmount",
  minWidth: 140,
  headerComponentParams: { isRequired: false ,enableSorting: false},
  headerClass: "custom-column-group-cell px-0",
  cellClass: "custom-column-group-cell",
  editable: true,
  sortable: true,
  cellRenderer: TextRendererComponent,
  cellEditor:AgGridCustomInputComponent,
  cellEditorParams:{
    decimalPlaces:2,
    isOnlyNumbers:true,
  }
};

const DiamondAmount: AgColDefsI = {
  headerName: "Diamond Amount",
  field: "diamondAmount",
  minWidth: 140,
  headerComponentParams: { isRequired: false ,enableSorting: false },
  headerClass: "custom-column-group-cell px-0",
  cellClass: "custom-column-group-cell",
  editable: true,
  sortable: true,
  cellRenderer: TextRendererComponent,
  cellEditor:AgGridCustomInputComponent,
  cellEditorParams:{
    decimalPlaces:2,
    isOnlyNumbers:true,
  }
};

const NetAmountColumn: AgColDefsI = {
  headerName: "Net Amount",
  field: "netAmount",
  minWidth: 120, 
  headerComponentParams: { isRequired: false ,enableSorting: false},
  headerClass: "custom-column-group-cell px-0",
  cellClass: "custom-column-group-cell",
  editable: false,
  sortable: true,
  cellRenderer: TextRendererComponent,
  cellEditor:AgGridCustomInputComponent,
  cellEditorParams:{
    decimalPlaces:2,
    isOnlyNumbers:true,
  }
};

const NetAmountWithTaxColumn: AgColDefsI = {
  headerName: "Net Amount With Tax",
  field: "netAmountWithTax",
  minWidth: 180,
  headerComponentParams: { isRequired: false  ,enableSorting: false},
  headerClass: "custom-column-group-cell px-0",
  cellClass: "custom-column-group-cell",
  editable: false,
  sortable: true,
  cellRenderer: TextRendererComponent,
  cellEditor:AgGridCustomInputComponent,
  cellEditorParams:{
    decimalPlaces:2,
    isOnlyNumbers:true,
  }
};

const HMColumn: AgColDefsI = {
  headerName: "Hall Mark",
  field: "hallMark",
  marryChildren: true,
  sortable: true,
  // isRequired: true,
  headerComponentParams: { isRequired: true ,enableSorting: false},
  headerClass: "custom-column-group-cell justify-content-center px-0",
  children: [
    {
      field: "hallmarkAmount",
      headerClass: "custom-column-group-cell child_header px-0",
      cellClass: "custom-column-group-cell px-0",
      headerName: "Amount",
      minWidth: 90,
      // editable: true,
      sortable: true,
      headerComponentParams: { enableSorting: false},
      cellRenderer: TextRendererComponent,
      cellEditor:AgGridCustomInputComponent,
      cellEditorParams:{
        decimalPlaces:2,
        isOnlyNumbers:true,
      },
      editable: (params) => {
        return params.data.hallmarkRate == 0;
    },
    },
    {
      field: "hallmarkRate",
      cellClass: "custom-column-group-cell px-0",
      headerName: "Rate",
      minWidth: 100,
      editable: true,
      sortable: true,
      headerClass: "custom-column-group-cell child_header px-0",
      cellRenderer: TextRendererComponent,
      cellEditor:AgGridCustomInputComponent,
      headerComponentParams: { enableSorting: false},
      cellEditorParams:{
        decimalPlaces:2,
        isOnlyNumbers:true,
      }
    },
  ],
};


const ActionColumn: AgColDefsI = {
  headerName: "",
  pinned: "right",
  suppressNavigable: true,
  editable: false,
  lockPinned: true,
  field: 'action',
  headerComponent: CustomHeaderComponent,
  headerClass: "justify_content_start  custom-column-group-cell border-0 px-0",
  cellRenderer: EditDeleteIconComponent,
  cellRendererFramework: EditDeleteIconComponent,
  // Pass gridOptions to the cell renderer component
  cellRendererParams: {
    editIcon: true,
    // tableId: StorageName.TRANSACTION_SALEINVOICE_IMMITATION_ITEM_GRID,
    isFirstDeleteIcon:true,
    resetIcon:true
  },
  headerComponentParams: { enableMenu: false, enableSorting: false, selectAll : false },
  cellClass: "mx-0 text-end px-1",
  minWidth: 80,
  maxWidth: 80,
};






const RFIDCode: AgColDefsI = {
  headerName: "RFIDCode",
  field: "rfid",
  minWidth: 90,
  headerClass: "custom-column-group-cell px-0",
  cellClass: "custom-column-group-cell",
  editable: false,
  sortable: true,
  cellRenderer: TextRendererComponent,
};
const CertificateNumber: AgColDefsI = {
  headerName: "Certificate No.",
  field: "certificateNumber",
  minWidth: 90,
  headerClass: "custom-column-group-cell px-0",
  cellClass: "custom-column-group-cell",
  editable: true,
  sortable: true,
  cellRenderer: TextRendererComponent,
};
//#endregion



//#region Interfaces ********** Diamond Table start*******

const Clarity: AgColDefsI = {
  headerName: "Clarity",
  field: "clarityId",
  minWidth: 100,
  sortable: true,
  editable: true,
  cellRenderer: TextRendererComponent,
  cellEditor: AgGridCustomDropdownComponent,
  cellEditorParams: {
    values: () => {
      const storageService = new StorageService();
      const storage = StorageName;
      return storageService.retrieve(storage.CLARITY_DROPDOWN);
    },
  },
  cellClass: "custom-column-group-cell px-0",
  headerComponentParams: { enableSorting: false},
  headerClass: "custom-column-group-cell px-0",
};
export const CutId: AgColDefsI = {
  headerName: "Cut",
  field: "cutId",
  minWidth: 100,
  sortable: true,
  editable: true,
  cellRenderer: TextRendererComponent,
  cellEditor: AgGridCustomDropdownComponent,
  headerComponentParams: { enableSorting: false},
  cellEditorParams: {
    values: () => {
      const storageService = new StorageService();
      const storage = StorageName;
      return storageService.retrieve(storage.CUT_DROPDOWN);
    },
  },
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0",
};

const CalculationTypeColumn: AgColDefsI = {
  headerName: "Calculation Type",
  field: "calculationType",
  minWidth: 100,
  sortable: true,
  editable: true,
  cellRenderer: TextRendererComponent,
  cellEditor: AgGridCustomDropdownComponent,
  headerComponentParams: { enableSorting: false},
  cellEditorParams: {
    values: () => {
      const storageService = new StorageService();
      const storage = StorageName;
      return storageService.retrieve(storage.CALCULATION_TYPE_DROPDOWN);
    },
  },
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0",
};

const DiamondCategoryColumn: AgColDefsI = {
  headerName: "Diamond Category",
  field: "diamondCategoryId",
  minWidth: 100,
  sortable: true,
  editable: true,
  cellRenderer: TextRendererComponent,
  cellEditor: AgGridCustomDropdownComponent,
  headerComponentParams: { enableSorting: false},
  cellEditorParams: {
    values: () => {
      const storageService = new StorageService();
      const storage = StorageName;
      return storageService.retrieve(storage.DIAMOND_CATEGORY_DROPDOWN);
    },
  },
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0",
};

export const ColorId: AgColDefsI = {
  headerName: "Color",
  field: "colorId",
  minWidth: 100,
  sortable: true,
  editable: true,
  cellRenderer: TextRendererComponent,
  cellEditor: AgGridCustomDropdownComponent,
  headerComponentParams: { enableSorting: false},
  cellEditorParams: {
    values: () => {
      const storageService = new StorageService();
      const storage = StorageName;
      return storageService.retrieve(storage.COLOR_DROPDOWN);
    },
  },
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0",
};
const SeiveSizeId: AgColDefsI = {
  headerName: "SeiveSize",
  field: "seiveSizeId",
  minWidth: 100,
  sortable: true,
  editable: true,
  cellRenderer: TextRendererComponent,
  cellEditor: AgGridCustomDropdownComponent,
  headerComponentParams: { enableSorting: false},
  cellEditorParams: {
    values: () => {
      const storageService = new StorageService();
      const storage = StorageName;
      return storageService.retrieve(storage.SEIVE_SIZE_DROPDOWN);
    },
  },
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0",
};
export const SizeId: AgColDefsI = {
  headerName: "Size",
  field: "sizeId",
  minWidth: 100,
  sortable: true,
  editable: true,
  cellRenderer: TextRendererComponent,
  cellEditor: AgGridCustomDropdownComponent,
  headerComponentParams: { enableSorting: false},
  cellEditorParams: {
    values: () => {
      const storageService = new StorageService();
      const storage = StorageName;
      return storageService.retrieve(storage.SIZE_DROPDOWN);
    },
  },
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0",
};
const ShapeId: AgColDefsI = {
  headerName: "Shape",
  field: "shapeId",
  minWidth: 100,
  sortable: true,
  editable: true,
  cellRenderer: TextRendererComponent,
  cellEditor: AgGridCustomDropdownComponent,
  headerComponentParams: { enableSorting: false},
  cellEditorParams: {
    values: () => {
      const storageService = new StorageService();
      const storage = StorageName;
      return storageService.retrieve(storage.SHAPE_DROPDOWN);
    },
  },
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0",
};
const RhodiumAmountColumn: AgColDefsI = {
  headerName: "Rhodium Amount",
  field: "stoneAmount",
  minWidth: 120,
  headerClass: "custom-column-group-cell px-0",
  cellClass: "custom-column-group-cell",
  editable: true,
  sortable: true,
  cellRenderer: TextRendererComponent,
  headerComponentParams: { enableSorting: false},
  cellEditor:AgGridCustomInputComponent,
  cellEditorParams:{
    decimalPlaces:3,
    isOnlyNumbers:true,
    inputMaxlength:8
  }
};

const CertificateAmount: AgColDefsI = {
  headerName: "Certificate Amount",
  field: "certificateAmount",
  minWidth: 120,
  headerClass: "custom-column-group-cell px-0",
  cellClass: "custom-column-group-cell",
  headerComponentParams: { enableSorting: false},
  editable: true,
  sortable: true,
  cellRenderer: TextRendererComponent,
  cellEditor:AgGridCustomInputComponent,
  cellEditorParams:{
    decimalPlaces:3,
    isOnlyNumbers:true,
    inputMaxlength:8
  }
};

const OtherAmount: AgColDefsI = {
  headerName: "Other Amount",
  field: "otherAmount",
  minWidth: 120,
  headerClass: "custom-column-group-cell px-0",
  cellClass: "custom-column-group-cell",
  headerComponentParams: { enableSorting: false},
  editable: true,
  sortable: true,
  cellRenderer: TextRendererComponent,
  cellEditor:AgGridCustomInputComponent,
  cellEditorParams:{
    decimalPlaces:3,
    isOnlyNumbers:true,
    inputMaxlength:8
  }
};

const NetAmount: AgColDefsI = {
  headerName: "Net Amount",
  field: "netAmount",
  minWidth: 180,
  headerClass: "custom-column-group-cell px-0",
  cellClass: "custom-column-group-cell",
  editable: false,
  sortable: true,
  cellRenderer: TextRendererComponent,
  cellEditor:AgGridCustomInputComponent,
  headerComponentParams: { enableSorting: false},
  cellEditorParams:{
    decimalPlaces:2,
    isOnlyNumbers:true,
  }
};
const DiscountPer: AgColDefsI = {
  headerName: "Discount Per.",
  field: "discountPer",
  minWidth: 100,
  headerClass: "custom-column-group-cell px-0",
  cellClass: "custom-column-group-cell",
  editable: true,
  sortable: true,
  isRequired:true,
  cellRenderer: TextRendererComponent,
  cellEditor:AgGridCustomInputComponent,
  headerComponentParams: { enableSorting: false},
  cellEditorParams:{
    decimalPlaces:2,
    isOnlyNumbers:true,
  }
};
const DiscountAmount: AgColDefsI = {
  headerName: "Discount Amount",
  field: "discountAmount",
  minWidth: 100,
  headerClass: "custom-column-group-cell px-0",
  cellClass: "custom-column-group-cell",
  editable: true,
  sortable: true,
  isRequired:true,
  cellRenderer: TextRendererComponent,
  cellEditor:AgGridCustomInputComponent,
  headerComponentParams: { enableSorting: false},
  cellEditorParams:{
    decimalPlaces:2,
    isOnlyNumbers:true,
  }
};


//#endregion

//#region  Definition for Diamond 
const GoldCaratColumn: AgColDefsI = {
  headerName: "Gold Carat",
  headerComponentParams: { isRequired: false, enablePlusIconLight: true , enableSorting: false},
  field: "goldCaratId",
  minWidth: 100,
  sortable: true,
  editable: true,
  cellRenderer: TextRendererComponent,
  cellEditor: AgGridCustomDropdownComponent,
  cellEditorParams: {
    values: () => {
      const storageService = new StorageService();
      const storage = StorageName;
      return storageService.retrieve(storage.CARAT_DROPDOWN);
    },
  },
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0",
}



//#endregion ********** Diamond Table end*******

// ******************** Column Defination Immmitation start *************************

const ImmitationProductDetailId: AgColDefsI = {
  headerName: "Product Detail",
  field: "productDetailId",
  minWidth: 120,
  sortable: true,
  editable: true,
  cellRenderer: TextRendererComponent,
  cellEditor: AgGridCustomDropdownComponent,
  headerComponentParams: {isRequired: false, enableSorting: false},
  cellEditorParams: {
    values: () => {
      const storageService = new StorageService();
      const storage = StorageName;
      return storageService.retrieve(storage.PRODUCT_DROPDOWN);
    },
  },
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0",
}

const ImmitationHuidNo: AgColDefsI = {
  headerName: "Huid No",
  field: "huidNo",
  minWidth: 120,
  sortable: true,
  editable: true,
  resizable: false,
  cellRenderer: TextRendererComponent,
  headerComponentParams: {isRequired: false, enableSorting: false},
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0",
}

const ImmitationPacketWt: AgColDefsI = {
  headerName: "Packet Wt",
  field: "packetWt",
  minWidth: 120,
  sortable: true,
  editable: true,
  resizable: false,
  cellRenderer: TextRendererComponent,
  headerComponentParams: {isRequired: false, enableSorting: false},
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0",
}

const ImmitationPacketLessWt: AgColDefsI = {
  headerName: "Packet Less Wt",
  field: "packetLessWt",
  minWidth: 120,
  sortable: true,
  editable: true,
  resizable: false,
  cellRenderer: TextRendererComponent,
  headerComponentParams: {isRequired: false, enableSorting: false},
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0",
}

const ImmitationBarcodeNo: AgColDefsI = {
  headerName: "Barcode No",
  field: "barcodeNo",
  minWidth: 120,
  sortable: true,
  editable: true,
  resizable: false,
  cellRenderer: TextRendererComponent,
  headerComponentParams: {isRequired: false, enableSorting: false},
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0",
}

const ImmitationGrossWt: AgColDefsI = {
  headerName: "Gross Wt",
  field: "grossWt",
  minWidth: 120,
  sortable: true,
  editable: true,
  resizable: false,
  cellRenderer: TextRendererComponent,
  headerComponentParams: {isRequired: false, enableSorting: false},
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0",
  cellEditor:AgGridCustomInputComponent,
  cellEditorParams:{
    decimalPlaces:3,
    isOnlyNumbers:true,
    inputMaxlength:10
  }
}

const ImmitationQuantity: AgColDefsI = {
  headerName: "Quantity",
  field: "quantity",
  minWidth: 120,
  sortable: true,
  editable: true,
  resizable: false,
  cellRenderer: TextRendererComponent,
  headerComponentParams: {isRequired: false, enableSorting: false},
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0",
  cellEditor:AgGridCustomInputComponent,
  cellEditorParams:{
    decimalPlaces:1,
    isOnlyNumbers:true,
    inputMaxlength:15
  },

}

const ImmitationNetAmountWithTax: AgColDefsI = {
  headerName: "Net Amount Tax",
  field: "netAmountWithTax",
  minWidth: 120,
  sortable: true,
  editable: true,
  resizable: false,
  cellRenderer: TextRendererComponent,
  headerComponentParams: {isRequired: false, enableSorting: false},
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0",
  cellEditor:AgGridCustomInputComponent,
  cellEditorParams:{
    decimalPlaces:2,
    isOnlyNumbers:true,
  }
}


const ImmitationNetAmount: AgColDefsI = {
  headerName: "Net Amount",
  field: "netAmount",
  minWidth: 120,
  sortable: true,
  editable: true,
  resizable: false,
  cellRenderer: TextRendererComponent,
  headerComponentParams: {isRequired: false, enableSorting: false},
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0",
  cellEditor:AgGridCustomInputComponent,
  cellEditorParams:{
    decimalPlaces:2,
    isOnlyNumbers:true,
  }
}

const ImmitationDiscountPer: AgColDefsI = {
  headerName: "Discount Per",
  field: "discountPer",
  minWidth: 120,
  sortable: true,
  editable: true,
  resizable: false,
  cellRenderer: TextRendererComponent,
  headerComponentParams: {isRequired: false, enableSorting: false},
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0",
  cellEditor:AgGridCustomInputComponent,
  cellEditorParams:{
    decimalPlaces:2,
    isOnlyNumbers:true,
  }
}

const ImmitationDiscountAmount: AgColDefsI = {
  headerName: "Discount Amount",
  field: "discountAmount",
  minWidth: 120,
  sortable: true,
  editable: true,
  cellRenderer: TextRendererComponent,
  headerComponentParams: {isRequired: false, enableSorting: false},
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0",
  cellEditor:AgGridCustomInputComponent,
  cellEditorParams:{
    decimalPlaces:2,
    isOnlyNumbers:true,
  }
}


//#region  Definition for Gold and Silver Table
export const ColumnDefForGoldSilverTable: AgColDefsI[] = [
  ItemListImageColumn,
  CheckboxColumn,  
  IdColumn,
  RFIDCode, 
  VoucherTypeIdColumn, 
  BarcodeColumn,
  HUIDColumn,
  SubCategoryColumn,
  ProductColumn,
  CaretColumn,
  PktWtColumn,
  PktLessWtColumn,
  QuantityColumn,
  RequestedWtColumn,
  RequestedPurityColumn,
  GrossWtColumn,
  LessWtColumn, 
  NetWtColumn,
  PurityColumn,
  PurityWtColumn, 
  WastagePerColumn,
  WastageWtColumn,
  AlloyWtColumn,
  FinalWtColumn,
  RateColumn,
  MetalValueColumn,
  RatePerColumn,
  AmountColumn,
  MakingColumn,
  StoneAmount,
  DiamondAmount,
  PurchaseAmountColumn,
  SaleAmountColumn,
  NetAmountColumn,
  NetAmountWithTaxColumn,
  HMColumn,
  ReverseColumn,
  ActionColumn,
];

const ColumnDefForSilverTable: AgColDefsI[] = [
  ItemListImageColumn,
  IdColumn,
  VoucherTypeIdColumn,
  PurchaseAmountColumn,
  SaleAmountColumn,
  CheckboxColumn,
  ReverseColumn,
  BarcodeColumn,
  HUIDColumn,
  SubCategoryColumn,
  ProductColumn,
  CaretColumn,
  PurityColumn,
  PurityWtColumn,
  QuantityColumn,
  PktWtColumn,
  PktLessWtColumn,
  GrossWtColumn,
  LessWtColumn,
  NetWtColumn,
  WastagePerColumn,
  WastageWtColumn,
  FinalWtColumn,
  RateColumn,
  RatePerColumn,
  MetalValueColumn,
  AmountColumn,
  MakingColumn,
  StoneAmount,
  DiamondAmount,
  NetAmountColumn,
  NetAmountWithTaxColumn,
  HMColumn,
  ActionColumn,
]
//#endregion

const ColumnDefForDiamondTable: AgColDefsI[] = [
  ItemListImageColumn,
  IdColumn,
  RFIDCode,
  VoucherTypeIdColumn,
  CheckboxColumn,
  ProductColumn,
  CalculationTypeColumn,
  GoldCaratColumn,
  DiamondCategoryColumn,
  BarcodeColumn,
  HUIDColumn,
  PktWtColumn,
  PktLessWtColumn,
  GrossWtColumn,
  WeightColumn,
  // GoldWeightColumn,
  DiamondCaratColumn,
  RateColumn,
  DiamondMakingColumn,
  RhodiumAmountColumn,
  CertificateAmount,
  PurchaseAmountColumn,
  SaleAmountColumn,
  OtherAmount,
  GoldAmountColumn,
  AmountColumn,
  QuantityColumn,
  HMColumn,
  NetAmount,
  NetAmountWithTaxColumn,
  DiscountPer,
  DiscountAmount,
  CutId,
  ColorId,
  SeiveSizeId,
  SizeId,
  ShapeId,
  Clarity,
  ActionColumn,
  CertificateNumber,
  UDF1,
  UDF2,
  UDF3,
  UDF4,
  UDF5,
  UDF6,
  UDF7,
  UDF8,
  UDF9,
  UDF10, 
  UDF11,
  UDF12,
  UDF13,
  UDF14,
  UDF15,
  UDF16,
  UDF17,
  UDF18,
  UDF19,
  UDF20, 
  UDF21,
  UDF22,
  UDF23,
  UDF24,
  UDF25,
  UDF26,
  UDF27,
  UDF28,
  UDF29,
  UDF30, 
  
]

export const ColumnDefForStoneGEMSTable: AgColDefsI[] = [
  ItemListImageColumn,
  IdColumn,
  VoucherTypeIdColumn,
  PurchaseAmountColumn,
  SaleAmountColumn,
  CheckboxColumn,
  CalculationTypeColumn,
  GoldCaratColumn,
  ProductColumn,
  BarcodeColumn,
  HUIDColumn,
  DiamondCaratColumn,
  RateColumn,
  PktWtColumn,
  PktLessWtColumn,
  GrossWtColumn,
  DiamondMakingColumn,
  CertificateAmount,
  // GoldWeightColumn,
  PurchaseAmountColumn,
  SaleAmountColumn,
  OtherAmount,
  GoldAmountColumn,
  AmountColumn,
  QuantityColumn,
  HMColumn,
  NetAmount,
  NetAmountWithTaxColumn,
  DiscountPer,
  DiscountAmount,
  CutId,
  ColorId,
  SeiveSizeId,
  SizeId,
  ShapeId,
  Clarity,
  ActionColumn
]

const ColumnDefForImmitationAndWatchesTable: AgColDefsI[] = [
  ItemListImageColumn,
  IdColumn,
  VoucherTypeIdColumn,
  CheckboxColumn,
  PurchaseAmountColumn,
  SaleAmountColumn,
  ImmitationProductDetailId,
  ImmitationHuidNo,
  ImmitationBarcodeNo,
  ImmitationPacketWt,
  ImmitationPacketLessWt,
  ImmitationGrossWt,
  ImmitationQuantity,
  ImmitationNetAmount,  
  ImmitationNetAmountWithTax,
  ImmitationDiscountPer,
  ImmitationDiscountAmount,
  ActionColumn
]

//#region Gold

export const agGridDataModelGoldTopTable: AgGridI = {
  colDefs: ColumnDefForGoldSilverTable,
  rowSelection: "multiple",
  storageName : StorageName.TRANSACTION_SALEINVOICE_GOLD_ITEM_GRID,
  addBlankRowOnEnter:true,
  columnSettings:true,
  singleClickEdit:true,
  rows:[]
};

//#endregion

//#region silver
export const agGridDataModelSilverTopTable: AgGridI = {
  colDefs: ColumnDefForSilverTable,
  rowSelection: "multiple",
  storageName : StorageName.TRANSACTION_SALEINVOICE_SILVER_ITEM_GRID,
  addBlankRowOnEnter:true,
  columnSettings:true,
  singleClickEdit:true,
  rows:[]
};
//#endregion

//#region diamond
export const agGridDataModelDiamondTopTable: AgGridI = {
  colDefs: ColumnDefForDiamondTable,
  rowSelection: "multiple",
  storageName : StorageName.TRANSACTION_SALEINVOICE_DIAMOND_ITEM_GRID,
  addBlankRowOnEnter:true,
  columnSettings:true,
  singleClickEdit:true,
  rows:[]
};
//#endregion 
//#region Common


//#region StoneGEMS
export const agGridDataModelStoneGEMSTopTable: AgGridI = {
  colDefs: ColumnDefForStoneGEMSTable,
  rowSelection: "multiple",
  storageName : StorageName.TRANSACTION_SALEINVOICE_STONE_GEMS_ITEM_GRID,
  addBlankRowOnEnter:true,
  columnSettings:true,
  singleClickEdit:true,
  rows:[]
};
//#endregion

//#region ImmitationAndWatches
export const agGridDataModelImmitationAndWatchesTopTable: AgGridI = {
  colDefs: ColumnDefForImmitationAndWatchesTable,
  rowSelection: "multiple",
  storageName : StorageName.TRANSACTION_SALEINVOICE_IMITATION_ITEM_GRID,
  addBlankRowOnEnter:true,
  columnSettings:true,
  singleClickEdit:true,
  rows:[]
};
//#endregion





export class MetalSectionReadonlyModel{

  agGridDataModelGold : AgGridI = {
    colDefs: agGridDataModelGoldTopTable.colDefs.map((item, index) => ({ ...item, editable: false })),
    rowSelection: "multiple",
    moreButton : true,
    columnSettings : true,
    columnTypes: {
      editableColumn: {
        editable: (params: EditableCallbackParams<any>) => {
          return false ;
        }      
      }
    },
  }  ;
  agGridDataModelSilver  : AgGridI = {
    colDefs: agGridDataModelSilverTopTable.colDefs.map((item, index) => ({ ...item, editable: false })),
    rowSelection: "multiple",
    moreButton : true,
    columnSettings : true,
    editable : false
  } ;
  agGridDataModelDiamond  : AgGridI = {
    colDefs:agGridDataModelDiamondTopTable.colDefs.map((item, index) => ({ ...item, editable: false })),
    rowSelection: "multiple",
    moreButton : true,
    columnSettings : true,
    editable : false
  } ;
  agGridDataModelStoneGEMS  :  AgGridI = {
    colDefs:agGridDataModelStoneGEMSTopTable.colDefs.map((item, index) => ({ ...item, editable: false })),
    rowSelection: "multiple",
    moreButton : true,
    columnSettings : true,
    editable : false
  } ;
  agGridDataModelImmitationAndWatches   : AgGridI = {
    colDefs: agGridDataModelImmitationAndWatchesTopTable.colDefs.map((item, index) => ({ ...item, editable: false })),
    rowSelection: "multiple",
    moreButton : true,
    columnSettings : true,
    editable : false
  } ;

  purchaseInvoiceNoDropdown:any;
  showCunsumptionAndProductionHeaders:boolean = false;
  isInternal_Fix: boolean=true;
  isAML: boolean=true;
  isKYC: boolean=true;

  isBarcodeInput:boolean = true;
  isGridFooter :boolean = false;
  showCommentAndAddField : boolean =true;
  selectAll : boolean =false;
  editable : boolean =true;
  isFilter : boolean = false;
  apiUrl : string = "";
  isServerSidepagination : boolean = false;

  showMetalSelectionTabs : boolean = true;

  tableIndex : number = 0;
  // setVisibility(storageName : StorageName | StorageName) : any
  // {
  //   switch(storageName)
  //   {
  //     case StorageName.FORMNAME_TRANSACTION_SALESINVOICE:
  //       this.isAML =false;
  //       this.isKYC =false;
  //       this.agGridDataModelGold.storageName=StorageName.TRANSACTION_SALEINVOICE_GOLD_ITEM_GRID;
  //       this.agGridDataModelSilver.storageName=StorageName.TRANSACTION_SALEINVOICE_SILVER_ITEM_GRID;
  //       this.agGridDataModelDiamond.storageName=StorageName.TRANSACTION_SALEINVOICE_DIAMOND_ITEM_GRID;
  //       this.agGridDataModelStoneGEMS.storageName=StorageName.TRANSACTION_SALEINVOICE_STONE_GEMS_ITEM_GRID;
  //       this.agGridDataModelImmitationAndWatches.storageName=StorageName.TRANSACTION_SALEINVOICE_IMITATION_ITEM_GRID;
  //       break;
  //     case StorageName.FORMNAME_TRANSACTION_PURCHASEINVOICE:
  //       this.isAML =false;
  //       this.isKYC =false;
  //       this.agGridDataModelGold.storageName=StorageName.TRANSACTION_PURCHASEINVOICE_GOLD_ITEM_GRID;
  //       this.agGridDataModelSilver.storageName=StorageName.TRANSACTION_PURCHASEINVOICE_SILVER_ITEM_GRID;
  //       this.agGridDataModelDiamond.storageName=StorageName.TRANSACTION_PURCHASEINVOICE_DIAMOND_ITEM_GRID;
  //       this.agGridDataModelStoneGEMS.storageName=StorageName.TRANSACTION_PURCHASEINVOICE_STONE_GEMS_ITEM_GRID;
  //       this.agGridDataModelImmitationAndWatches.storageName=StorageName.TRANSACTION_PURCHASEINVOICE_IMITATION_ITEM_GRID;
  //       break;
  //       case StorageName.FORMNAME_TRANSACTION_SALESORDER:
  //         this.isAML =false;
  //         this.isKYC =false;
  //         this.isInternal_Fix =false;
  //         this.agGridDataModelGold.storageName=StorageName.TRANSACTION_SALESORDER_GOLD_ITEM_GRID;
  //         this.agGridDataModelSilver.storageName=StorageName.TRANSACTION_SALESORDER_SILVER_ITEM_GRID;
  //         this.agGridDataModelDiamond.storageName=StorageName.TRANSACTION_SALESORDER_DIAMOND_ITEM_GRID;
  //         this.agGridDataModelStoneGEMS.storageName=StorageName.TRANSACTION_SALESORDER_STONE_GEMS_ITEM_GRID;
  //         this.agGridDataModelImmitationAndWatches.storageName=StorageName.TRANSACTION_SALESORDER_IMITATION_ITEM_GRID;
  //         break;
      
  //     case StorageName.FORMNAME_TRANSACTION_PURCHASERETURN:
  //       this.isAML =false;
  //       this.isKYC =false;
  //       this.agGridDataModelGold.storageName=StorageName.TRANSACTION_PURCHASERETURN_GOLD_ITEM_GRID;
  //       this.agGridDataModelSilver.storageName=StorageName.TRANSACTION_PURCHASERETURN_SILVER_ITEM_GRID;
  //       this.agGridDataModelDiamond.storageName=StorageName.TRANSACTION_PURCHASERETURN_DIAMOND_ITEM_GRID;
  //       this.agGridDataModelStoneGEMS.storageName=StorageName.TRANSACTION_PURCHASERETURN_STONE_GEMS_ITEM_GRID;
  //       this.agGridDataModelImmitationAndWatches.storageName=StorageName.TRANSACTION_PURCHASERETURN_IMITATION_ITEM_GRID;
  //       break;
        
  //     case StorageName.FORMNAME_TRANSACTION_SALESRETURN:
  //       this.isAML =false;
  //       this.isKYC =false;
  //       this.isInternal_Fix = false;
  //       this.agGridDataModelGold.storageName=StorageName.TRANSACTION_SALESRETURN_GOLD_ITEM_GRID;
  //       this.agGridDataModelSilver.storageName=StorageName.TRANSACTION_SALESRETURN_SILVER_ITEM_GRID;
  //       this.agGridDataModelDiamond.storageName=StorageName.TRANSACTION_SALESRETURN_DIAMOND_ITEM_GRID;
  //       this.agGridDataModelStoneGEMS.storageName=StorageName.TRANSACTION_SALESRETURN_STONE_GEMS_ITEM_GRID;
  //       this.agGridDataModelImmitationAndWatches.storageName=StorageName.TRANSACTION_SALESRETURN_IMITATION_ITEM_GRID;
  //       break;

  //     case StorageName.FORMNAME_TRANSACTION_JOBWORK_ORDER:
  //       this.isAML =false;
  //       this.isKYC =false;
  //       this.isInternal_Fix = false;
  //       this.agGridDataModelGold.storageName=StorageName.TRANSACTION_JOBWORK_GOLD_ITEM_GRID;
  //       this.agGridDataModelSilver.storageName=StorageName.TRANSACTION_JOBWORK_SILVER_ITEM_GRID;
  //       this.agGridDataModelDiamond.storageName=StorageName.TRANSACTION_JOBWORK_DIAMOND_ITEM_GRID;
  //       this.agGridDataModelStoneGEMS.storageName=StorageName.TRANSACTION_JOBWORK_STONE_GEMS_ITEM_GRID;
  //       this.agGridDataModelImmitationAndWatches.storageName=StorageName.TRANSACTION_JOBWORK_IMITATION_ITEM_GRID;
  //       break;
        
  //         case StorageName.FORMNAME_TRANSACTION_STOCKJOURNAL_STOCKOUT:
  //           this.isInternal_Fix = false;
  //           this.isAML =false;
  //           this.isKYC =false;
  //           this.isBarcodeInput = false;
  //           this.isGridFooter  = true;
  //           this.showCommentAndAddField = false;
  //           this.agGridDataModelGold.storageName=StorageName.TRANSACTION_STOCKJOURNAL_STOCKOUT_GOLD_ITEM_GRID;
  //           this.agGridDataModelSilver.storageName=StorageName.TRANSACTION_STOCKJOURNAL_STOCKOUT_SILVER_ITEM_GRID;
  //           this.agGridDataModelDiamond.storageName=StorageName.TRANSACTION_STOCKJOURNAL_STOCKOUT_DIAMOND_ITEM_GRID;
  //           this.agGridDataModelStoneGEMS.storageName=StorageName.TRANSACTION_STOCKJOURNAL_STOCKOUT_STONE_GEMS_ITEM_GRID;
  //           this.agGridDataModelImmitationAndWatches.storageName=StorageName.TRANSACTION_STOCKJOURNAL_STOCKOUT_IMITATION_ITEM_GRID;

  //           break;
  //         case StorageName.FORMNAME_MANUFACTURER_MANUFACTURING_PROCESS_MATERIAL_ISSUE:
  //           this.isInternal_Fix = false;
  //           this.isAML =false;
  //           this.isKYC =false;
  //           this.isBarcodeInput = false;
  //           this.isGridFooter  = true;
  //           this.showCommentAndAddField = false;
  //           this.agGridDataModelGold.storageName=StorageName.MANUFACTURER_MANUFACTURING_PROCESS_MATERIALISSUE_GOLD_ITEM_GRID;
  //           this.agGridDataModelSilver.storageName=StorageName.MANUFACTURER_MANUFACTURING_PROCESS_MATERIALISSUE_SILVER_ITEM_GRID;
  //           this.agGridDataModelDiamond.storageName=StorageName.MANUFACTURER_MANUFACTURING_PROCESS_MATERIALISSUE_DIAMOND_ITEM_GRID;
  //           this.agGridDataModelStoneGEMS.storageName=StorageName.MANUFACTURER_MANUFACTURING_PROCESS_MATERIALISSUE_STONE_GEMS_ITEM_GRID;
  //           this.agGridDataModelImmitationAndWatches.storageName=StorageName.MANUFACTURER_MANUFACTURING_PROCESS_MATERIALISSUE_IMITATION_ITEM_GRID;
  //           break;
  //   }
  // }
  
}