<div class="clascontainer-fluid d-flex flex-column">
  <div class="row py-3 h-100">
    <div class="col-2"></div>
<div class="col-7">
  <div class="card mb-0">
    <div class="card-body px-4 py-4">
      <label class="f-16 text-blueish font-bold" for="">Order Summary</label>
      <div class="mt-2" *ngFor="let item of proudctOrderCheckout?.orderSummary ; let i = index" >
      <div class="row">
        <div class="col-10">
          <div class="d-flex gap-2">
          <div class="d-flex align-items-center">
            <app-checkbox 
            [dyanamicId]="'isCheck '" 
            [isRequired]="false" 
            [status]="item?.isCheck" 
            (check)="item.isCheck= $event;checkUncheckCartProduct(item.isCheck , item?.productId )"
            ></app-checkbox>
          </div>
          <div>
            <img *ngIf="item?.productUrls?.length"  width="100" height="100" [src]="item?.productUrls[0]?.base64Resource" onerror="this.src = './assets/svg/nopreviewImage.svg'" alt="jewelry img">
            <img *ngIf="!item?.productUrls?.length" width="100" height="100" src="./assets/svg/nopreviewImage.svg" alt="jewelry img">
          </div>
          <div>
            <span class="f-14 text text-blueish font-bold">{{item?.productTitle}}</span>
            <p class="f-13 text-gray-color mb-2">{{item?.shortDesc}}</p>
            <div class="d-flex gap-2">
              <!-- <div>
                <div class="custom-dropdown">
                  <div class="selected-option" (click)="toggleDropdown(i)">Qty: {{ quantity }} 
                    <svg class="arrow" xmlns="http://www.w3.org/2000/svg" width="11.971" height="6.339" viewBox="0 0 11.971 6.339">
                      <path id="Path_3580" data-name="Path 3580" d="M-1841.061,5637.149l5.279,5.132,5.279-5.132" transform="translate(1841.768 -5636.442)" fill="none" stroke="#455472" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
                    </svg>
                  </div>
                  <ul class="dropdown-list" *ngIf="dropdownStates[i]">
                    <li *ngFor="let option of options" (click)="updateQuantity(option, i)">{{ option }}</li>
                  </ul>
                </div>
               
              </div> -->
              <div ngbDropdown class="d-inline-block qty_dropdown">
                <div class="" id="dropdownBasic1" ngbDropdownToggle>
                  Qty : {{item.qty}}
                </div>
                <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                  <button *ngFor="let qty of quantityDropdownList" (click)="updateQty(qty ,item?.productId)" ngbDropdownItem>{{qty}}</button>
                </div>
              </div>
              <!-- <vr></vr> -->
              <!-- <div>
                <app-button class="updateBtn" (click)="placeOrder()"
                [buttonLabel]="'Update'"
                [buttonClass]="'blue_button text-nowrap'"
                [buttonType]="'button'">
               </app-button>
             
              </div> -->
              <vr></vr>
              <div>
                <span (click)="removeProductFromCart(item?.productId ,i)" class="f-12 text-blueish cursorPointer">Delete</span>
              </div>
            </div>
          </div>
        </div>
        </div>
        <div class="col-2 text-end tex-blue">
          ₹{{calculateTotal(item?.amount??0 , item?.qty??0)}}</div>
      </div>
      <hr>
    </div>
    </div>
  </div>
</div>
<div class="col-3">
<label class="f-16 mt-3 text-blueish font-bold mb-0" for="">Total</label>
<hr>
<div class="d-flex justify-content-between mb-1">
  <span class="text-blueish">Sub Total</span>
  <span class="text-blueish font-bold">₹{{calculateSubTotal()}}</span>

</div>
<div class="d-flex justify-content-between">
  <span class="text-blueish">Tax</span>
  <div>
    <div class="d-flex justify-content-end mb-1">
      <span>CGST:</span>
      <span class="text-blueish font-bold ms-1">₹{{productTax?.CGST}}</span>
    </div>
    <div class="d-flex justify-content-end mb-1">
      <span>SGST:</span>
      <span class="text-blueish font-bold ms-1">₹{{productTax?.SGST}}</span>
    </div>
    <div class="d-flex justify-content-end mb-1">
      <span>IGST:</span>
      <span class="text-blueish font-bold ms-1">₹{{productTax?.IGST}}</span>
    </div>
  </div>
</div>
<hr>
<div class="d-flex justify-content-between">
  <span class="tex-blue">Final Ammount</span>
  <span class="tex-blue">₹{{finalAmt()}}</span>

</div>
<hr>
<div class="d-flex justify-content-end"> 
  <app-button class="" (click)="placeOrder()"
   [buttonLabel]="'Place Order'"
   [buttonClass]="'blue_button text-nowrap'"
   [buttonType]="'button'">
  </app-button>
</div>

</div>
  </div>
</div>

