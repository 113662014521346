import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, Renderer2 } from '@angular/core';
import { CommonService } from '../../services/common.service';
import { AddImageComponent } from '../common-popup/add-image/add-image.component';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { commonModalOptions } from '../../models/common.model';
import { CompressImageService } from '../../services/compress-image.service';

@Component({
  selector: 'app-upload-documents',
  templateUrl: './upload-documents.component.html',
  styleUrls: ['./upload-documents.component.scss']
})
export class UploadDocumentsComponent extends  CommonService  implements OnInit{ 
@Input() requestModel : any;

constructor(
     public  renderer     : Renderer2,
      private modalService : NgbModal,
      public compressImageService     : CompressImageService,
){
  super(renderer);
}

  ngOnInit() {
}

//#region upload items images start
async onSelect(event) {
  const imagesList = []
 if (event.addedFiles.length > 1) {
     await this.resourceMultipleFile(event.addedFiles , imagesList);
 } else {
     await this.resourceFile(event.addedFiles[0] , imagesList);
 }
}

async resourceFile(item: any , list) {
 await this.processFile(item, true ,list);
}

async resourceMultipleFile(items: any ,list) {
 for (let index = 0; index < items.length; index++) {
     const isLastFile = index === items.length - 1;
     await this.processFile(items[index], isLastFile ,list);
 }
}

async processFile(file: any, isLastFile: boolean , imagesList) {
 console.log(file);
 try {
     const compressedFile = await this.compressImageService.compress(file).toPromise();
     const base64String = await this.readThis(compressedFile);
     if (base64String) {
         console.log(compressedFile);
         const img = {
             base64Resource: base64String
         };
            imagesList?.push(img)
         if (isLastFile) {
             this.openAddImagePopup(imagesList);
         }
     } else {
         console.error("Failed to get base64 string.");
     }
 } catch (error) {
     console.error("Error processing file:", error);
 }
}
//#endregion

openAddImagePopup(imgList) {
  const modalOptions: NgbModalOptions = {
    ...commonModalOptions,
    size: "md",
    windowClass: "AddImagePopUp"
  };

  const modalRef = this.modalService.open(AddImageComponent, modalOptions);
      modalRef.componentInstance.imagesList = [...imgList];
      modalRef.result.then((result: any) => {
        result === false ? null : this.requestModel.invoiceDocumentResources = [...result];
      });
}


}