import { Directive, ElementRef, HostBinding, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appMaxLength]'
})
export class MaxLengthDirective {
  // @Input('appMaxLength') maxValue: number;

  // constructor(private el: ElementRef) {}

  // @HostListener('input', ['$event']) onInput(event: any) {
  //   const currentValue = this.el.nativeElement.value;
  //   if (currentValue > this.maxValue) {
  //     this.el.nativeElement.value = Math.round(this.maxValue);
  //   }
  // }

  @Input('appMaxLength') maxValue: number;
  private previousValue: any;

  constructor(private el: ElementRef) {}

  @HostListener('ngModelChange', ['$event']) onModelChange(event: any) {
    const currentValue = event;
    if (parseFloat(currentValue) > this.maxValue) {
      // Restore the previous value if the new value is greater than the specified maximum
      this.el.nativeElement.value = this.previousValue;
    } else {
      // Update the previous value if the new value is within the allowed range
      this.previousValue = currentValue;
    }
  }


}
