export class PaginationBaseModel {
    searchText: string|null = null;
    pagination =  new Pagination();
    sort =  new Sort();
  }
  
  export class Pagination{
    pageNumber: number = 0;
    pageSize: number = 0;
  }
  
  export class Sort{
    sortColumn: string|null = null;
    sortDirection: number =0;
}

export class PaginationCommonRequestModel{
  sortColumn      : string="";
  sortDirection   : string="";
  pageSize        : number;
  searchText      : string="";
  orderBy         : string="";
  getAll          : boolean;
  page            : number;
  filters         : string="";
  total_rows      : string="";
  total_pages     : string="";
  sortList        : SortList;
  filterList      : FilterList;
}
export class SortList{
  sort      : string='';
  colId     : string='';
  sortIndex : number=0
}
export class FilterList{
    name        : string="";
    textFilter  : string="";
    filterData  : FilterData;
}
export class FilterData{
  id    : number= 0;
  name  : string=""
}

// Set default values
export const defaultPaginationCommonRequestModel: any = {
  sortColumn: "",
  sortDirection: "",
  pageSize: 0,
  searchText: "",
  orderBy: "",
  getAll: true,
  page: 0,
  filters: "",
  total_rows: "",
  total_pages: "",
  filterList: [
    {
      name: "",
      textFilter: "",
      filterData: [
        {
          id: 0,
          name: ""
        }
      ]
    }
  ],
  sortList: [
    {
      sort: "",
      colId: "",
      sortIndex: 0
    }
  ],
  branchId: 0,
  voucherTypeId: 0,
  ledgerId: 0,
  productId: 0,
  metalId: 0
};