import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss']
})
export class CheckboxComponent implements OnInit {
  @Input() status: boolean;
  @Input() isRequired: boolean;
  @Input() checkBoxClass: string;
  @Input() checkBoxLabelClass: string;
  @Output() check:EventEmitter<boolean>=new EventEmitter() ;
  @Input() lableName:string;
  @Input() dyanamicId:string;
  @Input() disabled:boolean;
  @Input() departmentValue:boolean;

  constructor() { }

  ngOnInit(): void {
  }

}
