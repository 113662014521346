import { AgColDefsI, AgGridI } from "../../../../../shared/common-components/ag-grid/ag-grid.model";
import { AmountColWidth, StorageName } from "../../../../enum/common-enum";
import { CheckboxRendererComponent } from "../../../ag-grid/checkbox-renderer/checkbox-renderer.component";
import { NumericCellRendererComponent } from "../../../ag-grid/numeric-cell-renderer/numeric-cell-renderer.component"
import { TextRendererComponent } from "../../../ag-grid/text-renderer/text-renderer.component";
export class FundTransfer {
}

const CheckboxColumn: AgColDefsI = {
  headerName: "",
  headerComponentParams: { selectAll : true },
  field: "active",       
  lockPosition: "left",
  cellClass: "custom-column-group-cell",
  cellRenderer:CheckboxRendererComponent  ,
  headerClass:"custom-column-group-cell ps-2 pe-1",
  minWidth: 60,
  maxWidth: 60,
  editable:false, 
};
export const AmountColumn: AgColDefsI = {
  headerName: "Amount",
  field: "amount",
  minWidth: AmountColWidth.MinWidth,
  maxWidth: AmountColWidth.MaxWidth,
  headerClass: "custom-column-group-cell px-0 ag_header_end",
  cellClass: "custom-column-group-cell text-end",
  editable: false,
  sortable: true,
  resizable: true,
  cellRenderer: NumericCellRendererComponent,
  cellRendererParams: { isAmt: true },
  headerComponentParams: {
    isAllowNumber: true
  }
};
const InvoiceNoColumn: AgColDefsI = {
  headerName: "Invoice No.",
  field: "invoiceNo",
  minWidth: AmountColWidth.MinWidth,
  maxWidth: AmountColWidth.MaxWidth,
  headerClass: "custom-column-group-cell px-0",
  cellClass: "custom-column-group-cell px-0 ",
  editable: false,
  sortable: true,
  resizable: true,
  cellRenderer: TextRendererComponent,
}

export const ExtraPaidAmtColumn: AgColDefsI = {
  headerName: "Extra Paid Amt.",
  field: "extraPaidAmount",
  minWidth: AmountColWidth.MinWidth,
  maxWidth: AmountColWidth.MaxWidth,
  headerClass: "custom-column-group-cell px-0 ag_header_end",
  cellClass: "custom-column-group-cell text-end",
  editable: false,
  sortable: true,
  resizable: true,
  cellRenderer: NumericCellRendererComponent,
  cellRendererParams: { isAmt: true },
  headerComponentParams: {
    isAllowNumber: true
  }
};

export const ExtraPaidPerColumn: AgColDefsI = {
  headerName: "Extra Paid Per.",
  field: "extraPaidPer",
  minWidth: AmountColWidth.MinWidth,
  maxWidth: AmountColWidth.MaxWidth,
  headerClass: "custom-column-group-cell px-0 ag_header_end",
  cellClass: "custom-column-group-cell text-end",
  editable: false,
  sortable: true,
  resizable: true,
  cellRenderer: NumericCellRendererComponent,
  headerComponentParams: {
    isAllowNumber: true
  }
};
export const TotalAmountColumn: AgColDefsI = {
  headerName: "Total Amount",
  field: "totalAmount",
  minWidth: AmountColWidth.MinWidth,
  maxWidth: AmountColWidth.MaxWidth,
  headerClass: "custom-column-group-cell px-0 ag_header_end",
  cellClass: "custom-column-group-cell text-end",
  editable: false,
  sortable: true,
  resizable: true,
  cellRenderer: NumericCellRendererComponent,
  cellRendererParams: { isAmt: true },
  headerComponentParams: {
    isAllowNumber: true
  }
};

const FundTransferColumnDef: AgColDefsI[] = [
  CheckboxColumn,
  InvoiceNoColumn,
  AmountColumn,
  ExtraPaidAmtColumn,
  ExtraPaidPerColumn,
  TotalAmountColumn,
]
export const AgGridFundTransfer: AgGridI = {
  colDefs: FundTransferColumnDef,
  rowSelection: "multiple",
  addBlankRowOnEnter: true,
  singleClickEdit: true,
  columnSettings: true,
  selectAll : true,
  storageName: StorageName.FUND_TRANSFER_GRID,
  rows: [],
};