import { AfterViewInit, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { commonModalOptions } from '../../models/common.model';
import { AddEditCommentComponent } from '../pop-ups/add-edit-comment/add-edit-comment.component';
import { NgClass } from '@angular/common';

@Component({
  selector: 'app-comment',
  templateUrl: './app-comment.component.html',
  styleUrls: ['./app-comment.component.scss']
})
export class AppCommentComponent implements OnChanges {
  @Input() formModel: any
  @Input() buttonLabel: string;
  @Input() badgeCount: any
  @Input('ngClass') ngClass: NgClass["ngClass"];
  @Input() buttonClass: string;

  constructor(private modalService: NgbModal,) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.formModel && this.formModel?.invoiceModel?.comment) {
      this.badgeCount = JSON.parse(this.formModel.invoiceModel.comment);
    }
  }
  addEditComment(formModel) {
    const modalOption: NgbModalOptions = {
      ...commonModalOptions,
    };
    const modalRef = this.modalService.open(AddEditCommentComponent, modalOption);
    if (formModel.invoiceModel.id || formModel?.invoiceModel?.comment) {
      modalRef.componentInstance.comments = JSON.parse(formModel?.invoiceModel?.comment);
      this.badgeCount = JSON.parse(formModel?.invoiceModel?.comment);
    }
    modalRef.result.then((result: []) => {
      if (result) {
        this.badgeCount = result;
        formModel.invoiceModel.comment = JSON.stringify(result);
      } else {
      }
    }, (reason) => {
    });
  }

  isCommentAvailable(comment: string) {
    if (comment) {
      let comm = JSON.parse(comment)
      return comm.length > 0 ? true : false;

    }
  }

}
