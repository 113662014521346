import { Component, OnInit, Renderer2, ViewChild } from '@angular/core';
import { CommonService } from '../../services/common.service';
import { GetAllExpensesCategoryResponseModel} from 'src/app/components/administration/administration.model';
import { NgForm } from '@angular/forms';
import { AdministrationService } from 'src/app/components/administration/administration.service';
import { ToastrService } from 'ngx-toastr';
import { catchError } from 'rxjs';
import { SendDataService } from '../../services/send-data.service';

@Component({
  selector: 'app-expenses-category',
  templateUrl: './expenses-category.component.html',
  styleUrls: ['./expenses-category.component.scss']
})
export class ExpensesCategoryComponent extends CommonService implements OnInit{
  [x: string]: any;

  @ViewChild('administrationFormData') administrationFormData! : NgForm ;
  getAllExpensesCategory          : any[]=[];
  copyGetAllExpensesCategory        : any[]=[]
  editExpensesCategory             = new GetAllExpensesCategoryResponseModel();
  isAdd                       : boolean = false;
  
constructor(
  public renderer:Renderer2,
  public administrationService : AdministrationService,
  public toaster: ToastrService,
  public sendDataService : SendDataService,
) 
{
  super(renderer)
}

ngOnInit(): void {
  this.getAllExpensesCategoryList();
}

add(){
  if(!this.sendDataService?.permissionVM?.mastersCanAdd) return; if(!this.sendDataService?.permissionVM?.mastersCanAdd) return;
  this.isAdd = true;
  const obj = {id:0, name:'',active:true}
  this.getAllExpensesCategory?.push(obj);
  this.copyGetAllExpensesCategory?.push(obj);
  setTimeout(()=>{
    const element = document.getElementById(`ExpensesCategoryname${obj.id}`);
    element?.focus();
  },0)
}


cancel(){
  if(this.isAdd)
  {
    this.isAdd = false;
    this.getAllExpensesCategory = this.getAllExpensesCategory.filter((x)=>x.id != 0);
    this.copyGetAllExpensesCategory = this.copyGetAllExpensesCategory.filter((x)=>x.id != 0);
  }
  else if(this.editExpensesCategory?.id && this.editExpensesCategory?.isEdit){
    const index = this.copyGetAllExpensesCategory.findIndex((x)=>x.id == this.editExpensesCategory?.id);
    const singleData =JSON.parse(JSON.stringify(this.copyGetAllExpensesCategory))[index];
    this.getAllExpensesCategory[index] = singleData;
    this.editExpensesCategory = new GetAllExpensesCategoryResponseModel();
  }  
}


edit(item,formData){
  if(!this.sendDataService?.permissionVM?.mastersCanUpdate) return;
  item.isEdit = true;
  const controlObj = formData?.form?.controls;
  if(controlObj){
    const id = Object.keys(controlObj)[0];
    const element = document.getElementById(id);
    element?.focus();
  }
   this.editExpensesCategory = {...item};
}

checkIsEdit(arr:any[]):boolean{
  return arr?.some((x)=>x.isEdit)
}

  // *********** Expenses Category API START *********
  // **********get all Expenses Category *********
  getAllExpensesCategoryList() {
    this.administrationService.getAllExpensesCategoryList().subscribe({
      next:(res)=>{
        if(res.isSuccess){
            this.getAllExpensesCategory = res.response??[];
            this.editExpensesCategory = new GetAllExpensesCategoryResponseModel();
            this.copyGetAllExpensesCategory = JSON.parse(JSON.stringify(this.getAllExpensesCategory.filter((x)=>x.active == true)))??[];
          }else{           
          this.getAllExpensesCategory = [];
          this.copyGetAllExpensesCategory = [];
        }  
      },
      error:(err)=>{
        this.getAllExpensesCategory = []; 
        this.copyGetAllExpensesCategory = [];
      }
    })
  }

 // *************insert Expenses Category **************
  InsertExpensesCategoryList(item) {
    const {id , ...rest} = item;
    this.administrationService.InsertExpensesCategoryList(rest).pipe(catchError((err) => this.handleError(err)))
        .subscribe((res) => {
          if(res.isSuccess){
            this.isAdd = false;
            this.toaster.success(res.message);
            this.getAllExpensesCategoryList();
          }else{
            this.toaster.error(res.errors[0]);
          }  
      });
    }

  // **********update Task Type *********
    UpdateExpensesCategory(item) {
    const {isEdit , ...rest} = item;
    this.administrationService.UpdateExpensesCategory(rest)
        .pipe(catchError((err) => this.handleError(err)))
        .subscribe((res) => {
          if(res.isSuccess){
            console.log(res?.message)
            this.isAdd = false;
            this.getAllExpensesCategoryList();
            this.toaster.success(res.message);
          }else{
            this.toaster.error(res.errors[0]);
          }  
      });
    }  

    // **********delete Task Type *********
    DeleteExpensesCategory(id) {
      if(!this.sendDataService?.permissionVM?.mastersCanDelete) return;
      this.administrationService.DeleteExpensesCategory(id)
          .pipe(catchError((err) => this.handleError(err)))
          .subscribe((res) => {
            if(res.isSuccess){
              this.getAllExpensesCategoryList();
              this.toaster.success(res.message);
            }else{
              this.toaster.error(res.errors[0]);
            }  
      });
    }

}
