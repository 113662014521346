<div
  *ngIf="params?.data?.isExpandable"
  class="container-fluid mt-4 pt-1 w-100 px-0 main-container-div">
  <div class="row" style="--bs-gutter-x: 2.5rem !important;">
    <div class="col-12 px-0 br-top custom-bg">
      <div class="d-flex align-items-center ">
      <div class="">
        <ul >
          <li class="text-start text-gray-color ms-5 ps-5 f-12  font-regular " 
          *ngFor="let item of splitDescriptions let last = last" 
          [ngClass]="{'last-child': last}">
            {{ item.Description }}
          </li>
        </ul>
      </div>
     </div>
      </div>
    </div>
</div>
