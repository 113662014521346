<div class="modal-header d-flex align-items-center justify-content-center position-relative">
<div><span class="font-semibold text-primary">Closing</span></div>
    <app-svg-icon [name]="'cross_button_border'" class="cross-button-border"></app-svg-icon>
    <app-svg-icon [name]="'cross'" [ngClass]="currentHoverEffect" class="cross-button" (click)="activeModal.dismiss()"></app-svg-icon>
</div>
<form ngNativeValidate #rfidForm="ngForm">
<div class="modal-body mx-2">
    <div class="row mb-1">
        <div class="col-2 font-semibold text-gray-color d-flex align-items-center">Loss Wt</div>
        <div class="col ps-0">
            <app-input 
            [inputName]="'LossWt'" 
            [inputId]="'lossWt'" 
            [inputType]="'text'" 
            [inputValue]="data?.lossWt"
            (inputValueEmit)="data.lossWt=$event"
            [readOnly]="true">
            </app-input>
        </div>
        </div>
    <div class="row mb-1">
    <div class="col-2 font-semibold text-gray-color d-flex align-items-center">Purity Per</div>
    <div class="col ps-0">
        <app-input 
        [inputName]="'Purity Per.'" 
        [inputId]="'RFIDNo'" 
        [inputValue]="data?.purityPer"
        (inputValueEmit)="data.purityPer = $event;calculateItem();" 
        [isAllowDecimal]="true"
        >
        </app-input>
    </div>
    </div>
    <div class="row mb-1">
        <div class="col-2 font-semibold text-gray-color d-flex align-items-center">Purity Wt</div>
        <div class="col ps-0">
            <app-input 
            [inputName]="'Purity'" 
            [inputId]="'purityWt'" 
            [inputType]="'text'" 
            [inputValue]="data?.purityWt"
            (inputValueEmit)="data.purityWt=$event"
            [readOnly]="true">
            </app-input>
        </div>
        </div>

</div>
<div class="modal-footer pt-3 pb-2 mx-2">
    <app-button class="my-0 me-3" (buttonOutputEvent)="dismiss()" [buttonLabel]="'Close'" [buttonClass]="'pink_button'" [buttonType]="'button'">
    </app-button>
    <app-button class="m-0" [buttonLabel]="'Save'" (buttonOutputEvent)="close()" [buttonClass]="'blue_button'" [buttonType]="'submit'">
    </app-button>
</div>
</form>
