import { Component, OnInit, Renderer2, ViewChild } from '@angular/core';
import { CommonService } from '../../services/common.service';
import { NgForm } from '@angular/forms';
import { AdministrationService } from 'src/app/components/administration/administration.service';
import { ToastrService } from 'ngx-toastr';
import { catchError } from 'rxjs';
import { GetAllClarityResponseModel } from 'src/app/components/administration/administration.model';
import { SendDataService } from '../../services/send-data.service';

@Component({
  selector: 'app-clarity',
  templateUrl: './clarity.component.html',
  styleUrls: ['./clarity.component.scss']
})
export class ClarityComponent extends CommonService implements OnInit{
  isAdd                           : boolean = false;
  getAllClarityData              : any[]=[];

  copyGetAllClarityData          : any[]=[];
  editClarityData                = new GetAllClarityResponseModel();
  @ViewChild('administrationFormData') administrationFormData! : NgForm ;

constructor(
  public renderer:Renderer2,
  public administrationService : AdministrationService,
  public toaster: ToastrService,
  public sendDataService : SendDataService,
) 
{
  super(renderer)
}
ngOnInit(): void {
  this.getAllClarityList();
  }



add(){
  if(!this.sendDataService?.permissionVM?.mastersCanAdd) return;
  this.isAdd = true;
    const obj = {id:0, name:'',active:true}
    this.getAllClarityData?.push(obj);
    this.copyGetAllClarityData?.push(obj);
    setTimeout(()=>{
      const element = document.getElementById(`clarityName${obj.id}`);
      element?.focus();
    },0)
}

cancel(){
  if(this.isAdd)
  {
    this.isAdd = false;
    this.getAllClarityData = this.getAllClarityData.filter((x)=>x.id != 0);
    this.copyGetAllClarityData = this.copyGetAllClarityData.filter((x)=>x.id != 0);
  }
  else if(this.editClarityData?.id && this.editClarityData?.isEdit){
    const index = this.copyGetAllClarityData.findIndex((x)=>x.id == this.editClarityData?.id);
    const singleData =JSON.parse(JSON.stringify(this.copyGetAllClarityData))[index];
    this.getAllClarityData[index] = singleData;
    this.editClarityData = new GetAllClarityResponseModel();
  }  
}

edit(item,formData){
  if(!this.sendDataService?.permissionVM?.mastersCanUpdate) return;
  item.isEdit = true;
  const controlObj = formData?.form?.controls;
  if(controlObj){
    const id = Object.keys(controlObj)[0];
    const element = document.getElementById(id);
    element?.focus();
  }
  this.editClarityData = {...item};
}


checkIsEdit(arr:any[]):boolean{
  return arr?.some((x)=>x.isEdit)
}


    // *********** Clarity API START *********
    // **********get all Clarity *********
    getAllClarityList() {
      this.administrationService.getAllClarity().subscribe({
        next:(res)=>{
          if(res.isSuccess){
            this.editClarityData = new GetAllClarityResponseModel();
            this.getAllClarityData = res.response??[];
            this.copyGetAllClarityData = JSON.parse(JSON.stringify(this.getAllClarityData))??[];
          }else{           
            this.getAllClarityData = [];  
            this.copyGetAllClarityData = [];
          }  
        },
        error:(err)=>{
          this.getAllClarityData = [];  
          this.copyGetAllClarityData = [];
        }
      })
    }
    
    
       // *************insert Clarity **************
       InserClarityList(item) {
        const {id , ...rest}= item;
        this.administrationService.insertClarity(rest)
            .pipe(catchError((err) => this.handleError(err)))
            .subscribe((res) => {
              if(res.isSuccess){
                this.isAdd = false;
                this.getAllClarityList();
                this.toaster.success(res.message);
              }else{
                this.toaster.error(res.errors[0]);
              }  
          });
        }
    
    // **********update Clarity *********
    UpdateClarityList(item) {
      const {isEdit , ...rest} = item;
      this.administrationService.updateClarity(rest)
          .pipe(catchError((err) => this.handleError(err)))
          .subscribe((res) => {
            if(res.isSuccess){
              this.isAdd = false;
              this.getAllClarityList();
              this.toaster.success(res.message);
            }else{
              this.toaster.error(res.errors[0]);
            }  
        });
      } 

      // **********delete Clarity *********
      DeleteClarityList(id) {
      if(!this.sendDataService?.permissionVM?.mastersCanDelete) return;
        this.administrationService.deleteClarity(id)
            .pipe(catchError((err) => this.handleError(err)))
            .subscribe((res) => {
              if(res.isSuccess){
                this.getAllClarityList();
                this.toaster.success(res.message);
              }else{
                this.toaster.error(res.errors[0]);
              }  
        });
      }
          
}
