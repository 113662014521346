import { Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { DataService } from '../../../shared/services/data.service';
import { ResponseModel } from '../../../../app/shared/models/response-model.model';
import { API_URL_GET_ALL_GETSALEORDERJOBCARDBYVOUCHERNOANDVOUCHERTYPEID, API_URL_SALEORDER_PROCESS_LIST, API_URL_SALEORDERLIST_GET_RFID_PRINT } from '../../../../app/shared/url-helper/url-helper';
@Injectable({
  providedIn: 'root'
})
export class SaleOrderProcessService {
  public isCardView: boolean;
  public isDisabledZebraIcon : boolean = false;
  constructor(public service:DataService) { 
  }

  
  getAll(data : any):Observable<ResponseModel>{
    let url = API_URL_SALEORDER_PROCESS_LIST;
    const json =JSON.stringify(data);
    return this.service.post(url,data, true).pipe<ResponseModel>(tap((response: any) => {
    return response;
      })
    );
  }

  getPrintRFID(data : any):Observable<ResponseModel>{
    let url = API_URL_SALEORDERLIST_GET_RFID_PRINT;
    const json =JSON.stringify(data);
    return this.service.post(url,data, true).pipe<ResponseModel>(tap((response: any) => {
    return response;
      })
    );
  }

  updatesaleorderrfidcode(data):Observable<ResponseModel>{
    let url = "api/app/saleorderrfid/updatesaleorderrfidcode";
    return this.service.put(url,data, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }
  
   // *************upload file **************
   uploadFileStyle(data:any):Observable<ResponseModel>{
    let url = "api/app/saleorder/uploadsaleorder";
    const formData = new FormData();
    formData.append('file', data);
    return this.service.postWithFile(url,formData, true,null,true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }
  // end 

  //job-card start//
  getsaleorderjobcardbyvouchernoandvouchertypeid(data : any):Observable<ResponseModel>{
    let url = API_URL_GET_ALL_GETSALEORDERJOBCARDBYVOUCHERNOANDVOUCHERTYPEID;
    const json =JSON.stringify(data);
    return this.service.post(url,data, true).pipe<ResponseModel>(tap((response: any) => {
    return response;
      })
    );
  }
  //end
}
