import { Injectable } from '@angular/core';
import { DataService } from './../../../shared/services/data.service';
import { Observable, tap } from 'rxjs';
import { ResponseModel } from '../../../shared/models/response-model.model';
import { InsertWhatsAppCampaignRequestModel } from './whatsapp/insert-whatsapp-campaign/insert-whatsapp-campaign.model';

@Injectable({
  providedIn: 'root'
})
export class CampaignManagerService {

  constructor(public service: DataService) { }
 
  // *************insert WhatsAppCampaign **************
  insertWhatsAppCampaignList(insertWhatsAppCampaignRequestModel:InsertWhatsAppCampaignRequestModel):Observable<ResponseModel>{
    let url = "api/app/whatsappcampaign/insertwhatsappcampaign";
    const json = JSON.stringify(insertWhatsAppCampaignRequestModel);
    return this.service.post(url,insertWhatsAppCampaignRequestModel, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

 // *************getByid WhatsAppCampaign **************
  getWhatsAppCampaignById(id){
    let url = `api/app/whatsappcampaign/getwhatsappcampaignbyid?Id=${id}`;
    return this.service.get(url,true).pipe<ResponseModel>(tap((response: any) => {
      if(response.isSuccess == true)
        return response;
        })
      );
  }

  blockUnblockContactDetails(data:any):Observable<ResponseModel>{
    let url = "api/app/accountledger/blockorunblockaccountlegdercontactdetails";
    const json = JSON.stringify(data);
    return this.service.post(url,data, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

}
