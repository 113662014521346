import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PreviewIconService {
  public previewIconClickedSource = new Subject<any>();

  constructor() { }

  triggerpreviewClick(params: any) {
    this.previewIconClickedSource.next(params);
  }
}
