import { Component, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { CustomerDetailsComponent } from '../../customer-details.component';
import { CustomerDetails, ShippingAddressModel } from '../../customer-details.model';
import { CommonService } from 'src/app/shared/services/common.service';
import { StorageService } from 'src/app/shared/services/storage.service';
import { StorageName } from 'src/app/shared/enum/common-enum';

@Component({
  selector: 'app-shipping-address',
  templateUrl: './shipping-address.component.html',
  styleUrls: ['./shipping-address.component.scss']
})
export class ShippingAddressComponent extends CommonService {
  @ViewChild(CustomerDetailsComponent) customerDetailsComponent: CustomerDetailsComponent;
  @Input() customerDetails : CustomerDetails;
  @Input() getAllDropdownModel  : any = {};

  storageName=StorageName;
  isFromAccountLedger:boolean = false;
  LedgerId: any;
  originalShippingAddress: any = {};

  ngOnInit() {
    if(this?.customerDetails.shippingAddressModel?.active){
      this.checkShipping(true)
    }
  }

  checkShipping(isActive: boolean ) {
    if (isActive) {
      this.originalShippingAddress = { ...this.customerDetails.shippingAddressModel };
    
      this.customerDetails.shippingAddressModel = { 
        ...this.customerDetails.billingAddressModel, 
        active: true 
      };
      this.onChangeCountryName(this.customerDetails?.shippingAddressModel?.countryId ? this.customerDetails?.shippingAddressModel?.countryId : null )
    } else {
      this.customerDetails.shippingAddressModel = new ShippingAddressModel();
    }
  }

  onChangeCountryName(id){
    this.getAllDropdownModel.shippingAddressStateList = this.getAllDropdownModel?.stateDropdown?.filter((x)=>x?.extra1 == id || x.id ==null);
   }

}