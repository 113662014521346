import { Injector, NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { ContentComponent } from "./shared/components/layout/content/content.component";
import { content } from "./shared/routes/routes";
import { EditProfileComponent } from "./components/edit-profile/edit-profile.component";
import {AuthGuard} from "../../src/app/shared/guard/auth.guard"
import { SidebarComponent } from "./shared/components/sidebar/sidebar.component";
import { CompanyListComponent } from "./components/company-list/company-list.component";
const routes: Routes = [
  {
    path: "",
    pathMatch: "full",
    redirectTo: "/dashboard",
  },
  {
    path: "account",
    loadChildren: () => import("../app/auth/auth/auth.module").then((m) => m.AuthModule),
  },
  {
    path: "",
    component: ContentComponent,
    canActivate: [AuthGuard],
    children: content,
  },
  // {
  //   path: "add-update-profile",
  //   component: EditProfileComponent,
  // },
  // {
  //   path: "company-list",
  //   component: CompanyListComponent,
  // },
  {
    path: "app-jewelry",
    loadChildren: () => import("../app/jewelry/jewelry.module").then((m) => m.JewelryModule),
  },
  {
    path: 'invalid-link',
    loadChildren: () => import("../app/auth/auth/auth.module").then((m) => m.AuthModule),
  },
  {
    path: "**",
    redirectTo: "/dashboard",
  },
];

@NgModule({
  imports: [
    [
      RouterModule.forRoot(routes, {
        anchorScrolling: "enabled",
        scrollPositionRestoration: "enabled",
      }),
    ],
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {

  constructor(private injector: Injector) {
    setTimeout(() => {
      const sidebarComponent = this.injector.get(SidebarComponent);
      const currentRoute = window.location.pathname;
      sidebarComponent.setActiveClassForRoute(currentRoute);
    });
  }
}
