import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-call-api-confirm',
  templateUrl: './call-api-confirm.component.html',
  styleUrls: ['./call-api-confirm.component.scss']
})
export class CallApiConfirmComponent {
  callApi:any
  constructor(public activeModal:NgbActiveModal){
  }

  onDelete(){
    this.activeModal.close(true);
  }
}
