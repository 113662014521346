
<app-advanced-filter-pop-up  (applyFilter)="onClickAdvancedFilter()" (clearFilter)="clearFields()">
<form class="theme-form">
    <div class="modal-body mx-2 py-0">
        <div class="row mx-0">
            <div class="col-12 px-0 gap-3 d-flex align-items-center mb-2">
               <div class="col d-flex align-items-center">
                    <div class="w-90px f-14 font-semibold text-gray-color text-nowrap mb-0">
                        Date Range
                    </div>
                    <div class="col">
                        <app-range-datepicker #rangePicker [startDate]="advanceFilterRequest.fromDate"  [endDate]="advanceFilterRequest.toDate" (startDateEmit)="advanceFilterRequest.fromDate = $event;"  (endDateEmit)="advanceFilterRequest.toDate = $event"  ></app-range-datepicker>
                    </div>
               </div>
               <div class="col d-flex align-items-center">
                <div class="w-175px f-14 font-semibold text-gray-color text-nowrap mb-0">
                    Contact
                </div>
                <app-input 
                    [inputName]="'contactNumber'" 
                    [inputType]="'text'" 
                    [inputId]="'contactNumber'"
                    [isRequired]="false"
                    [inputValue]="advanceFilterRequest?.contact" 
                    (inputValueEmit)="advanceFilterRequest.contact = $event"
                    [isOnlyNumbers]="true"
                    [inputMaxlength]="12"
                    >
                </app-input>
           </div>
            </div>
            <div class="col-12 px-0 d-flex align-items-center mb-2">
                <div class="w-90px f-14 font-semibold text-gray-color text-nowrap mb-0">
                    Branch
                </div>
                <div class="col">
                    <app-ng-multi-select-dropdown
                    [isShowlable]="false" 
                    [isPlusIcon]="false" 
                    [name]="'branchId'" 
                    [getAllDropdownModel]="data.getAllDropdownModel?.branchDropdown" 
                    [selectedIds]="selectedBranchIds"
                    [data]="data.getAllDropdownModel?.branchDropdown" 
                    [placeholder]="'Select Branch'"
                    (onSelectItem)="advanceFilterRequest.selectedBranches= $event" 
                    [id]="'branchId'" 
                    >
                </app-ng-multi-select-dropdown>
                </div>
            </div>
            <div class="col-12 px-0 d-flex align-items-center mb-2">
                <div class="w-90px f-14 font-semibold text-gray-color text-nowrap mb-0">
                    Group
                </div>
                <div class="col">
                 
                    <app-ng-multi-select-dropdown
                    [isShowlable]="false" 
                    [isPlusIcon]="false" 
                    [name]="'groupId'" 
                    [selectedIds]="selectedGroupIds"
                    [data]="data.getAllDropdownModel?.accountGroupDropdown" 
                    [placeholder]="'Select Group'"
                    (onSelectItem)="advanceFilterRequest.selectedGroups= $event" 
                    [id]="'groupId'" 
                    >
                </app-ng-multi-select-dropdown>
                </div>
            </div>
            <div class="col-12 px-0 d-flex align-items-center mb-2">
                <div class="w-90px f-14 font-semibold text-gray-color text-nowrap mb-0">
                        Ledger
                    </div>
                    <div class="col">
                        <app-ng-multi-select-dropdown
                        [isShowlable]="false" 
                        [isPlusIcon]="false" 
                        [name]="'accountLedger'" 
                        [selectedIds]="selectedLedgerIds"
                        [data]="data.getAllDropdownModel?.allAccountLedgerDropdown" 
                        [placeholder]="'Select Ledger'"
                        (onSelectItem)="advanceFilterRequest.selectedLedgerIds= $event" 
                        [id]="'accountLedger'" 
                        >
                    </app-ng-multi-select-dropdown>
                    </div>
               </div>
            <div class="col-12 px-0 gap-3 d-flex align-items-center mb-1">
                <div class="col d-flex align-items-center">
                     <div class="w-90px f-14 font-semibold text-gray-color text-nowrap mb-0">
                        Cr/Dr
                     </div>
                     <div class="col">
                        <app-select 
                        [options]="data.getAllDropdownModel?.transactionTypeDropdown" 
                        [selectedValue]="advanceFilterRequest?.crordrid"
                        (valueChanged)="advanceFilterRequest.crordrid = $event" 
                        [id]="'crDrId'"
                        [name]="'crDr'" 
                        >
                        </app-select>  
                     </div>
                </div>
                <div class="col d-flex align-items-center">
                     
                </div>
            </div>
        </div>  
    </div>
</form>
</app-advanced-filter-pop-up>
