import { Component, Input, OnDestroy, QueryList, Renderer2, ViewChildren } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AgGridLossTrackingModel } from 'src/app/components/manufacturer/loss-tracking/loss-tracking.model';
import { AgGridI, PaginationModel, defaultPageLimit } from 'src/app/shared/common-components/ag-grid/ag-grid.model';
import { AgGriBarcodePopupGrid } from '../../stock-report/stock-report.model';
import { catchError } from 'rxjs';
import { CommonService } from 'src/app/shared/services/common.service';
import { ReportService } from '../../report.service';
import { GetallBarcodestockRequestModel } from '../../report.model';
import { AgGridComponent } from 'src/app/shared/common-components/ag-grid/ag-grid.component';

@Component({
  selector: 'app-barcode-popup',
  templateUrl: './barcode-popup.component.html',
  styleUrls: ['./barcode-popup.component.scss']
})
export class BarcodePopupComponent  extends CommonService {
  @Input() modalTitle                 : string;
  @Input() MetalTabId: string;
  @Input() ResponseData :any;
  @Input() ProductId:string;
  @Input() isbarcode :boolean;
  @Input()isInfo :boolean;
  gridApi                             : any;
  agGriBarcodePopupGrid             : AgGridI;
  copiedBarcodeStockList :any[]=[];
  totalRecords                    : number;
  pagination = new PaginationModel();
  getallBarcodestockRequestModel         = new GetallBarcodestockRequestModel();
  agGridLossTrackingRowData: any[] = [];
  @ViewChildren(AgGridComponent) grids: QueryList<AgGridComponent>
  constructor(public activeModal : NgbActiveModal,  public renderer : Renderer2,  private reportService : ReportService ,
  ) {
    super(renderer);
  }

  ngOnInit(): void {
    this.agGriBarcodePopupGrid  = AgGriBarcodePopupGrid;
    this.agGriBarcodePopupGrid.showPagination = true;
   if(this.isbarcode == true){
    this.agGriBarcodePopupGrid.showPagination = false;
   }
  }
  onGridReady(event ){
    if(this.isbarcode == true){
      this.gridApi  = event; 
      this.setBarcodeData();
    }else{
      this.gridApi  = event; 
      this.onClickFilterData();
      this. getAllBarcodeStockList()
  }
    }


  onClickFilterData() {
    const rulesFilterList = [];
    const filterData = {
      rules: [],
      groups: [],
    };

  if(this.isInfo){
    const filterObj = { data: this.ProductId, op: 'eq', field: 'productId' };
    rulesFilterList.push(filterObj);
  }else{
    const filterObj = { data: this.MetalTabId, op: 'eq', field: 'metalId' };
    rulesFilterList.push(filterObj);
  }
 

    filterData.rules = rulesFilterList;
    this.getallBarcodestockRequestModel.filters = JSON.stringify(filterData);
    this.getAllBarcodeStockList();
  }
  
 getAllBarcodeStockList(){
  this.showLoading(this.gridApi);
  this.reportService.getallBarcodeStock(this.getallBarcodestockRequestModel).pipe(
    catchError((err) => this.handleError(err)) 
  )
  .subscribe((res) => {
      if(res?.isSuccess){
        this.totalRecords = res.totalRecords;
        this.copiedBarcodeStockList =   JSON.parse(JSON.stringify(res?.response?.data));
        setTimeout(() => {
          this.hideLoading(this.gridApi);
          var response = res?.response?.data;
          if(response){
            var firstTabEntry = response[0];
          }
         this.gridApi?.api.paginationSetPageSize(this.pagination == undefined ||  this.pagination.pageSize == undefined ? defaultPageLimit : this.pagination.pageSize);
          this.gridApi?.api?.setRowData(res?.response?.data);
          this.refreshGridApi()
          if(this.getallBarcodestockRequestModel.getAll == true){
            this.grids.first.updateServersidePaginationgetallData(this.pagination.page ,this.totalRecords);

          }else{
            this.grids.first.updateServersidePaginationData(firstTabEntry == undefined || firstTabEntry == null ? 0 : firstTabEntry.total_rows, firstTabEntry == undefined || firstTabEntry == null ? 0 : firstTabEntry.total_pages, this.pagination == undefined ? 1 : this.pagination.page, this.pagination.pageSize == undefined ? defaultPageLimit : this.pagination.pageSize);
          }
          }, 100);

      
    } else {
      this.hideLoading(this.gridApi);
      this.gridApi?.api?.setRowData([]);
    }
  });
 }

 onPagination(pagination: PaginationModel) {
  this.storeChangedPagination(pagination);
  this.getAllBarcodeStockList();
}

storeChangedPagination(pagination: PaginationModel) {
  this.pagination.page = pagination.page;
  this.pagination.reloadGrid = pagination.reloadGrid;
  this.pagination.reloadGridFilters = false;
  this.pagination.pageSize = pagination.pageSize;
  if(this.pagination.pageSize == 1){
    this.getallBarcodestockRequestModel.getAll =true;
    this.getallBarcodestockRequestModel.pageSize = 0;
  }else{
    this.getallBarcodestockRequestModel.pageSize = this.pagination.pageSize;
    this.getallBarcodestockRequestModel.getAll =false;
  }
  this.getallBarcodestockRequestModel.page =  this.pagination.page;
}

 rowClicked(event){
  if(this.isbarcode == false){
    this.activeModal.close(event.data);

  }
}

setBarcodeData(){
  this.agGridLossTrackingRowData = this.ResponseData;
}
 refreshGridApi(){
  this.gridApi.api.refreshHeader();
 }
 ngOnDestroy(): void {
  this.agGriBarcodePopupGrid.showPagination =undefined;
 }

}
