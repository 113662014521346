import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EditNewService {
  private editNewClickedSource = new Subject<string>();
  plusIconClicked$ = this.editNewClickedSource.asObservable();

  emitEditNewClick(params: any) {
    this.editNewClickedSource.next(params);
  }
  constructor() { }
}
