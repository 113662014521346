<div class="modal-header d-flex align-items-center justify-content-center position-relative">
    <div><span class="font-semibold text-primary">Account Groups</span></div>
    <app-svg-icon [name]="'cross_button_border'" class="cross-button-border"></app-svg-icon>
    <app-svg-icon [name]="'cross'" class="cross-button" (click)="activeModal.dismiss()"></app-svg-icon>
</div>
<form autocomplete="off" class="theme-form d-flex flex-fill flex-column justify-content-between" ngNativeValidate #AdditionalAmountForm="ngForm">
    <div class="modal-body px-1 pb-0">
        <div class="row mx-0 gap-2 flex-fill">
            <div class="col-12 p-0 mb-2"> 
                <div class="row d-flex justify-content-end p-0">
                    <div class="col-7 d-flex mb-2 text-primary">
                        {{ data.accountGroupName}}
                    </div>
                    <div class="col-3 d-flex align-items-center justify-content-end mb-2">
                        <div class="w-50px f-14 font-semibold text-gray-color text-nowrap mb-0">
                            Date
                        </div>
                        <div class="col">
                            <div>
                                <app-range-datepicker #rangePicker [startDate]="fromDate" [endDate]="toDate" (startDateEmit)="fromDate = $event;" (endDateEmit)="toDate = $event" (applyDate)="apply()"></app-range-datepicker>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="h-100 position-relative">
                    <app-ag-grid 
                        [id]="'ModalHeight'"
                        [class]="'all_aggrid_tables position-relative d-block'"
                        [dataModel]="agGridAccountGroupsGrid" 
                        [rowData]="agGridAccountGroups"
                        (onTextCellClick)="handleTextClick($event)"
                        [columnSettings]="false"
                        (gridReady)="onGridReady($event)">
                    </app-ag-grid>
                </div>
            </div>
        </div>
    </div>
</form>