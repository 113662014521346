<div class="d-flex align-items-center w-100">
    <select 
    [class]="dropdownClass + ' select_dropdown cursorPointer d-flex align-items-center'" 
    [name]="dropdownName"
    [id]="dropdownId"
    [(ngModel)]="selectedValue" 
    (change)="dropdownValueEmit.emit($event.target.value)" 
    [disabled]="disabled" 
    [required]="isRequired"
    >
    <option *ngFor="let option of options" [value]="option.id">{{ option.name }}</option>
    </select>
</div>