import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NgbOffcanvas, NgbOffcanvasRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ResponseModel } from 'src/app/shared/models/response-model.model';
import { NotificationsService } from './notifications.service';
import { NotificationSidebarComponent } from '../../notification-sidebar/notification-sidebar.component';
import { Ids, Notifications } from "./notification.model"
import { RelativeTimePipe } from 'src/app/shared/custom-pipes/relative-time.pipe';
import { PermissionsService } from 'src/app/components/administration/usermanagment/tabs/permissions/permissions.service';
import { StorageService } from 'src/app/shared/services/storage.service';
import { StorageName } from 'src/app/shared/enum/common-enum';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
  providers: [RelativeTimePipe]
})
export class NotificationComponent implements OnInit {
  public openNotification: boolean = false;
  public openClosedSidebarNotification: boolean = false;
  @ViewChild("notificationSidebarComponent") notificationSidebarComponent!: NotificationSidebarComponent;

  notificationList: Notifications[] = [];
  notificationIds = new Ids();

  constructor(private offcanvasService: NgbOffcanvas, public notificationsService: NotificationsService, public userPermissionsService: PermissionsService,public storageService: StorageService,
     public toaster: ToastrService,
    private relativeTimePipe: RelativeTimePipe) { }
  ngOnInit() {
    // this.listen();
    this.getAllNotifications();
    this. GetPermissionAccessByUser();
    setInterval(() => {
      this.updateTimeForNotifications();
    }, 1000);
  }

  updateTimeForNotifications() {
    this.notificationList.map(a => a.timeDuration)
    this.notificationList.forEach(item => {
      item.timeDuration = this.relativeTimePipe.transform(item.triggeredDateTime);
    });
  }
  private GetPermissionAccessByUser() {
    this.userPermissionsService.getPermissionAccessByUser()
    .subscribe((res: ResponseModel) => {
      if (res.isSuccess) {
        this.storageService.store(StorageName.USER_PERMISSIONS, res?.response);
      } 
    
  });
  }

  getAllNotifications() {
    this.notificationsService.getAllNotifications()
      .subscribe((res: ResponseModel) => {
        if (res.isSuccess) {
          this.notificationList = res.response;
        } else {
          // if (res.message != undefined) {
          //   this.toaster.error(res.message, null, { timeOut: 700 });
          // }
          // if (res?.errors?.length > 0) {
          //   this.toaster.error(res.errors[0], null, {
          //     timeOut: 700,
          //   });
          // }
        }
      });
  }



  // listen() {
  //   const messaging = getMessaging();
  //   console.log("Before",this.notificationList)
  //   onMessage(messaging, (payload) => {
  //     console.log('Message received. ', payload);
  //     const push = {
  //       id:JSON.parse(payload.data['gcm.notification.id']),
  //       userId:payload.data['gcm.notification.userId'],
  //       title:payload.notification.title,
  //       body:payload.notification.body,
  //       subtitle:payload.data['gcm.notification.subtitle'],
  //       isRead:JSON.parse(payload.data['gcm.notification.isRead']),
  //       isNotified:JSON.parse(payload.data['gcm.notification.isNotified']),
  //       triggeredDateTime:payload.data['gcm.notification.triggeredDateTime']
  //     }
  //     this.notificationList.unshift(push);
  //     console.log("after",this.notificationList)
  //   });

  // }

  toggleNotificationMobile() {
    this.openNotification = !this.openNotification;
  }
  openEnd(content: TemplateRef<any>) {
    this.openClosedSidebarNotification = true;
    setTimeout(() => {
      const offcanvasRef: NgbOffcanvasRef = this.offcanvasService.open(this.notificationSidebarComponent.content, { position: 'end' });
      offcanvasRef.dismissed.subscribe(() => {
        this.openClosedSidebarNotification = false;
      });
    }, 100);

  }

  markAsread(item) {
    item.isRead = true;
    this.notificationIds.ids = [item.id];
    this.notificationsService.markAsReadNotifications(this.notificationIds)
      .subscribe((res: ResponseModel) => {
        if (res.isSuccess) {
          // this.notificationList = res.response;
          this.toaster.success(res.message, null, { timeOut: 700 });
        } else {
          if (res.message != undefined) {
            this.toaster.error(res.message, null, { timeOut: 700 });
          }
          if (res?.errors?.length > 0) {
            this.toaster.error(res.errors[0], null, {
              timeOut: 700,
            });
          }
        }
      });
  }

  markAllAsread() {
    this.notificationList?.map((x) => x.isRead = true);
    this.notificationIds.ids = this.notificationList?.map(a => a.id);
    this.notificationsService.markAsReadNotifications(this.notificationIds)
      .subscribe((res: ResponseModel) => {
        if (res.isSuccess) {
          // this.notificationList = res.response;
          this.toaster.success(res.message, null, { timeOut: 700 });
        } else {
          if (res.message != undefined) {
            this.toaster.error(res.message, null, { timeOut: 700 });
          }
          if (res?.errors?.length > 0) {
            this.toaster.error(res.errors[0], null, {
              timeOut: 700,
            });
          }
        }
      });
  };

  checkAsmarkStatus(): boolean {
    return this.notificationList?.some(x => x.isRead !== true);
  }


  getNotificationCount(): number {
    return this.notificationList?.filter(x => x?.isRead !== true).length;
  }

}
