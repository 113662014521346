<div class="modal-header d-flex align-items-center justify-content-center position-relative">
  <div><span class="font-semibold text-primary">{{ modalTitle }}</span></div>
  <app-svg-icon [name]="'cross_button_border'" class="cross-button-border"></app-svg-icon>
  <app-svg-icon [name]="'cross'" class="cross-button" (click)="activeModal.dismiss()"></app-svg-icon>
</div>
<form autocomplete="off" class="theme-form">
  <div class="modal-body px-1 pb-2">
    <div class="row mx-0 gap-2 d-flex flex-column h-100">
      <div class="col flex-fill p-0">
        <app-ag-grid 
          id="FundTransferpopup" 
          [dataModel]="agGridFundTransfer" 
          [rowData]="agGriFundTransferData"
          (gridReady)="onGridReady($event)"
          [columnSettings]="true">
        </app-ag-grid>
      </div>
    </div>
  </div>
  <div class="modal-footer mt-2 pt-2 pb-1 mx-2">
    <app-button class="m-0" [buttonLabel]="'Submit'" [buttonId]="'saveButton'" [buttonClass]="'blue_button'"
      [buttonType]="'submit'" (buttonOutputEvent)="submit()">
    </app-button>
  </div>
</form>