<div class="card_category"> 
    <div class="card d-flex flex-column m-0 h-100">
        <div class="card-header p-0 border-bottom-0">
            <h5 class="card-title mx-2 my-1"> 
                <div class="card_header text-primary f-13 font-semibold border d-flex justify-content-center align-items-center py-2">
                  Size
                </div>
            </h5>
        </div>
        <div class="card-body overflow-auto py-0 px-3 flex-fill">
            <div class="row mx-0 flex-column h-100 card_details_header border">
                <div class="col-12 d-flex align-items-center py-1 border-bottom">
                    <div class="col-1"></div>
                    <div class="col f-13 font-semibold text-gray-color ps-2" >
                        Name <span class="text-color-danger">*</span>
                    </div>
                </div>
                <div class="overflow-auto flex-fill h-0 p-0 table_details">
                    <div *ngFor="let item of getAllSizeData; let first=first; let childindex=index" class="col-12 d-flex align-items-center border-bottom py-0 gap-0 ps-2 list_items" [ngClass]="{'bg-row-gray add_editactive':(item?.id==0 && isAdd || item?.isEdit) ,'border-top-0':first,'is-hover':!checkIsEdit(getAllSizeData) && !isAdd}">
                        <form ngNativeValidate #administrationFormData="ngForm" class="col-12 d-flex align-items-center">
                            <div class="col-1 d-flex justify-content-center">
                                <app-checkbox 
                                (check)="item.active = $event" 
                                [status]="item.active"
                                [disabled]="!item.isEdit && item.id!==0"
                                [dyanamicId]="'check'+ item?.id"
                                ></app-checkbox>
                            </div>
                            <div class="col f-11 font-regular text-gray-color ps-1">
                                <!-- {{child?.name}} -->
                                <app-input 
                                onlyAlphabetsAndNumbers
                                [inputClass]="'bordernone bg-transperent text-truncate'"
                                [inputName]="'Size'+ item?.id" 
                                [inputType]="'text'" 
                                [inputId]="'Size'+ item?.id" 
                                [inputValue]="item.name" 
                                (inputValueEmit)="item.name = $event"
                                [isRequired]="true" 
                                [readOnly]="!item.isEdit && item.id!==0"
                                ></app-input>
                            </div>
                            <div class="col d-flex align-items-center justify-content-end f-13 d-flex font-regular text-gray-color add_edit_remove cursorPointer ">
                                <div class="icon_edit_delete d-flex gap-2 pe-2" *ngIf="(!item?.isEdit && item?.id !== 0)">
                                <span 
                                [ngbTooltip]="sendDataService?.noPermissionTooltip" 
                                [disableTooltip]="(sendDataService?.permissionVM?.mastersCanUpdate)"
                                [ngClass]="{'cursor-not-allowed opacity-50': (!sendDataService?.permissionVM?.mastersCanUpdate)}">
                                    <app-svg-icon name="edit" (click)="edit(item,administrationFormData)" ></app-svg-icon>
                                </span>
                                <span 
                                [ngbTooltip]="sendDataService?.noPermissionTooltip" 
                                [disableTooltip]="(sendDataService?.permissionVM?.mastersCanDelete)"
                                [ngClass]="{'cursor-not-allowed opacity-50': (!sendDataService?.permissionVM?.mastersCanDelete)}">
                                    <app-svg-icon name="delete_red" (click)="DeleteSizeList(item.id)"></app-svg-icon>
                                </span>
                                </div>
                                <div [ngClass]="{'cursor-not-allowed':!administrationFormData?.form?.valid}" class="icon_save d-flex py-1 px-2 bg-white" *ngIf="(item?.isEdit || (item?.id==0 && isAdd))"> 
                                    <div [ngClass]="{'pe-none':!administrationFormData?.form?.valid}" class="d-flex">   
                                        <svg class="cursorPointer " (click)="item.id == 0?InserSizeList(item):UpdateSizeList(item)" id="Component_25_2" data-name="Component 25 – 2" xmlns="http://www.w3.org/2000/svg" width="14" height="19" viewBox="0 0 18.076 18.076">
                                            <path id="Path_1240" data-name="Path 1240" d="M-1816.65,2401.785v12.639a.374.374,0,0,0-.034.077,1.937,1.937,0,0,1-1.915,1.581q-7.093,0-14.187,0a1.933,1.933,0,0,1-1.939-1.939q0-7.094,0-14.187a1.934,1.934,0,0,1,1.461-1.887c.066-.018.131-.041.2-.062h12.639c.142.119.293.229.424.359q1.5,1.493,2.995,2.994C-1816.878,2401.491-1816.769,2401.643-1816.65,2401.785Zm-4.53-2.7v.218c0,1.318,0,2.636,0,3.954a1.965,1.965,0,0,1-2.052,2.054c-2.018,0-4.036,0-6.054,0a2.324,2.324,0,0,1-.692-.1,1.936,1.936,0,0,1-1.35-1.9c0-1.341,0-2.683,0-4.024v-.21h-1.352a.906.906,0,0,0-.985.989q0,1.571,0,3.142,0,5.427,0,10.855a.862.862,0,0,0,.773.953c.512.042,1.031.009,1.564.009v-.215c0-1.712,0-3.424,0-5.136a1.956,1.956,0,0,1,2.017-2.015q3.627,0,7.254,0a2.185,2.185,0,0,1,.506.055,1.949,1.949,0,0,1,1.5,1.964q0,2.568,0,5.136v.2a.26.26,0,0,0,.054.019c.435,0,.871,0,1.306,0a.9.9,0,0,0,.977-.979q0-5.86,0-11.72a.426.426,0,0,0-.112-.271c-.927-.937-1.866-1.862-2.788-2.8A.548.548,0,0,0-1821.18,2399.085Zm.062,15.933v-.175q0-2.577,0-5.154a.9.9,0,0,0-.988-.981h-7.166a.894.894,0,0,0-.984.985q0,2.568,0,5.136c0,.062.006.124.009.188Zm-9.139-15.942v.182q0,2,0,4.006a.892.892,0,0,0,.986.981c2.012,0,4.024-.006,6.036,0a.934.934,0,0,0,.987-.989c-.02-1.335-.006-2.671-.007-4.006,0-.057-.005-.114-.008-.178Z" transform="translate(1834.725 -2398.007)" [attr.fill]="administrationFormData?.form?.valid?'#5843cf':'#b2bdd5'"/>
                                            <path id="Path_1241" data-name="Path 1241" d="M-1617.312,2447.4c0,.276,0,.552,0,.827a.534.534,0,0,1-.539.552.541.541,0,0,1-.522-.555q-.005-.828,0-1.655a.542.542,0,0,1,.534-.56.533.533,0,0,1,.528.546c0,.012,0,.023,0,.035Q-1617.312,2446.992-1617.312,2447.4Z" transform="translate(1628.58 -2443.742)" [attr.fill]="administrationFormData?.form?.valid?'#5843cf':'#b2bdd5'"/>
                                        </svg>
                                    </div>
                                </div> 
                            </div>
                        </form>
                    </div>   
                </div>
            </div>
        </div>
        <div class="py-1">
            <div *ngIf="isAdd || editSizeData?.isEdit" class="text-color-danger font-semibold f-13 d-flex align-items-center justify-content-center mx-4">
                <div class="d-flex align-items-center justify-content-center cursorPointer " (click)="cancel()">
                    <span class="d-flex cursorPointer pe-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width="9" height="9" viewBox="0 0 9.499 9.502">
                        <path id="Union_4" data-name="Union 4" d="M-1055-8110l-4,4,4-4-4-4,4,4,4-4-4,4,4,4Z" transform="translate(1059.75 8114.75)" fill="#f92b35" stroke="#f92b35" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                        </svg>
                    </span>
                    Cancel
                </div>
            </div>
            <div  [ngbTooltip]="sendDataService?.noPermissionTooltip" 
            [disableTooltip]="(sendDataService?.permissionVM?.mastersCanAdd)"
            [ngClass]="{'cursor-not-allowed opacity-50': (!sendDataService?.permissionVM?.mastersCanAdd)}"
            *ngIf="!isAdd && !editSizeData?.isEdit" class="text-primary font-semibold f-13 d-flex align-items-center justify-content-center mx-4">
                <div class="d-flex align-items-center justify-content-center cursorPointer " (click)="add()">
                    <span class="d-flex cursorPointer pe-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 16.875 16.875">
                            <path id="Union_4" data-name="Union 4" d="M7.438-1a.938.938,0,0,1,.937.938V6.5h6.563a.937.937,0,1,1,0,1.875H8.375v6.563a.937.937,0,1,1-1.875,0V8.375H-.062A.938.938,0,0,1-1,7.438.938.938,0,0,1-.062,6.5H6.5V-.062A.938.938,0,0,1,7.438-1Z" transform="translate(1 1)" fill="#5843CF"/>
                        </svg>                                                  
                    </span>
                    Add
                </div>
            </div>
        </div>
    </div>
</div>



