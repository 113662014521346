
<div class="h-100 container-fluid d-flex align-items-center justify-content-center Order_Successful">
 <div class="row">
    <div class="col-12 d-flex flex-column align-items-center mb-5">
        <div class="card box-shadow">
            <div class="card-body pt-2">
                <div class="d-flex align-items-center justify-content-center mx-3 px-3">
                    <app-svg-icon name="order_successful" class="print_svg"></app-svg-icon>
                </div>
                <div class="text-center pb-2 mb-4">
                    <div class="primary-color-1 font-semibold f-28">Order Successfully Placed</div>
                    <div class="color-dark-gray font-semibold f-20">Your order number is 6464944</div>
                    <span class="text-secondary font-regular f-12">Thank you for shopping with us. <br>
                        We will send you the order confirmation with <br>
                        details and tracking info</span>
                </div>
                <div class="d-flex justify-content-center">
                    <app-button  class="cursorPointer"  [buttonLabel]="'Go To Jewellery Catalog'" [buttonClass]="'dark_pink_button text-nowrap'"  [buttonType]="'button'">
                    </app-button>
                </div>
            </div>
        </div>
    </div>
 </div>
</div>