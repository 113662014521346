<div class="searchInputBox p-1 d-flex">
    <input 
        type="text" 
        class="searchInput px-2" 
        id="quickFilter" 
        placeholder="Search"
        autocomplete="off" 
        [(ngModel)]="inputSerachValue"
        (keydown.enter)="inputSerachValue ? inputActionIconClick?.emit(inputSerachValue) : '' "
        (ngModelChange)="inputSerachValueEmit?.emit(inputSerachValue)"
        >
    <!-- <span class="clearSearchInput cursorPointer " *ngIf="inputSerachValue"
        (click)="inputSerachValue='';inputActionIconClick?.emit(inputSerachValue)">
        <span class="pe-2"><i class="fa fa-solid fa-close"></i></span>
    </span> -->

    <span class="clearSearchInput d-flex align-items-center px-2">
        <svg (click)="inputSerachValue='';inputActionIconClick?.emit(inputSerachValue)" *ngIf="inputSerachValue"  class="cursorPointer " id="cancel" xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 10 10">
            <g id="Group_41546" data-name="Group 41546">
              <path id="Path_35106" data-name="Path 35106" d="M5.552,5,9.885.668A.391.391,0,0,0,9.333.115L5,4.448.667.115A.391.391,0,0,0,.114.668L4.447,5,.114,9.334a.391.391,0,0,0,.552.552L5,5.553,9.333,9.886a.391.391,0,0,0,.552-.552Z" transform="translate(0 -0.001)" fill="#727272"/>
            </g>
        </svg>
    </span>


    <span *ngIf="isVisibleSearchIcon" class="d-flex align-items-center searchInputIcon ps-1">
        <svg (click)="inputActionIconClick?.emit(inputSerachValue)" class="cursorPointer" xmlns="http://www.w3.org/2000/svg" width="20" height="15" viewBox="0 0 21.063 21.859">
            <path id="Union_12" data-name="Union 12" d="M-12239.71-9397.424l-5.008-4.658a9.128,9.128,0,0,1-5.093,1.545,9.212,9.212,0,0,1-9.19-9.23,9.212,9.212,0,0,1,9.19-9.232,9.212,9.212,0,0,1,9.192,9.232,9.218,9.218,0,0,1-2.481,6.308l4.826,4.491a1.057,1.057,0,0,1,.052,1.492,1.043,1.043,0,0,1-.771.336A1.037,1.037,0,0,1-12239.71-9397.424Zm-17.291-12.344a7.22,7.22,0,0,0,7.19,7.23,7.219,7.219,0,0,0,7.192-7.23,7.221,7.221,0,0,0-7.192-7.232A7.221,7.221,0,0,0-12257-9409.768Z" transform="translate(12259.001 9419)" fill="#4f39cd"/>
          </svg>          
    </span>
</div>
