<div class="row mb-0">
    <div class="col-sm-12 col-lg-12 col-md-12 f-12">
      <label class="mt-0">
        <select
          class="btn-sm border px-1 p-t-5 p-b-5 hand"
          [(ngModel)]="paginationModel.pageSize"
          (ngModelChange)="onPageChange()"
        >
          <option [ngValue]="10">10</option>
          <option [ngValue]="20">20</option>
          <option [ngValue]="40">40</option>
          <option [ngValue]="80">80</option>
          <option [ngValue]="collectionSize">All</option>
        </select>
      </label>&nbsp;
      <span class="f-12 align-self-center m-0"> Records per page of {{collectionSize}} Items</span>
      <ngb-pagination
        class="pull-right"
        [collectionSize]="collectionSize"
        [maxSize]="3"
        [(page)]=" paginationModel.pageNumber"
        [pageSize]="paginationModel.pageSize"
        (pageChange)="onPageChange()"
        [boundaryLinks]="true"
      >
      </ngb-pagination>
    </div>
  </div>
