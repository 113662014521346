import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-delete',
  templateUrl: './delete.component.html',
  styleUrls: ['./delete.component.scss']
})
export class DeleteComponent {
  constructor(public activeModal:NgbActiveModal){
  }

  @Input() productName:string;
  @Output() deleteConfirmed: EventEmitter<boolean> = new EventEmitter<boolean>();

  delete(){
  this.deleteConfirmed.emit(true);
  this.activeModal.close();
  }
}
