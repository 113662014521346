import { Injectable } from '@angular/core';
import { DataService } from './../../shared/services/data.service';
import { ResponseModel } from './../../shared/models/response-model.model';
import { Observable, tap } from 'rxjs';
import { InsertCaratRequestModel, InsertCategoryRequestModel, InsertCollectionRequestModel, InsertColorRequestModel, InsertCurrencyRequestModel, InsertDiamondSizeRequestModel, InsertExchangeRateRequestModel, InsertLocationRequestModel, InsertMetalRequestModel, InsertPurityRequestModel, InsertQaulityRequestModel, InsertRemarkRequestModel, InsertSeivesizeRequestModel, InsertSettingRequestModel, InsertSettingTypeRequestModel, InsertTaxSettingRequestModel, InsertUnitConversionRequestModel, InsertUnitRequestModel, UpdateCaratRequestModel, UpdateCategoryRequestModel, UpdateCollectionRequestModel, UpdateColorRequestModel, UpdateCurrencyRequestModel, UpdateDiamondSizeRequestModel, UpdateExchangeRateRequestModel, UpdateLocationRequestModel, UpdateMetalRequestModel, UpdatePurityRequestModel, UpdateQaulityRequestModel, UpdateRemarkRequestModel, UpdateSeivesizeRequestModel, UpdateSettingRequestModel, UpdateSettingTypeRequestModel, UpdateTaxSettingRequestModel, UpdateUnitConversionRequestModel, UpdateUnitRequestModel, UpdateRateRequestModel, InsertRateRequestModel, UpdateCutRequestModel, InsertCutRequestModel, InsertShapeRequestModel, UpdateShapeRequestModel, InsertSizeRequestModel, UpdateSizeRequestModel, UpdateDocumentTypeRequestModel, InsertDocumentTypeRequestModel, InsertCounterRequestModel, UpdateCounterRequestModel, InsertupdateBarcodeToolboxRequestModel, UpdatePacketTypeRequestModel, InsertPacketTypeRequestModel, UpdateClarityRequestModel, InsertClarityRequestModel, InsertTaskTypeRequestModel, UpdateTaskTypeRequestModel, InsertLoanProductTypeRequestModel, UpdateLoanProductTypeRequestModel, UpdateLoanReasonTypeRequestModel, InsertLoanReasonRequestModel } from './administration.model';
@Injectable({
  providedIn: 'root'
})
export class AdministrationService {
  constructor(public service: DataService) { }
  getAllPurityData:any;
  getAllMetalData:any;
  getAllCaratData:any;
  getAllBarcodeToolboxData:any;
  getAllCurrencyData:any;

  // *********** purity service start ************
   // **********get all purity *********
  getAllPurity():Observable<ResponseModel>{
    let url = "api/app/purity/getallpurity";
    return this.service.get(url, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }
  
 // *************insert purity **************
  insertPurity(data:InsertPurityRequestModel):Observable<ResponseModel>{
    let url = "api/app/purity/insertpurity";
    const json = JSON.stringify(data);
    return this.service.post(url,data, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

   // *************update purity **************
   updatePurity(data:UpdatePurityRequestModel):Observable<ResponseModel>{
    let url = "api/app/purity/updatepurity";
    const json = JSON.stringify(data);
    return this.service.putWithId(url,data,true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

    // *************delete purity **************
    deletePurity(id:number):Observable<ResponseModel>{
      let url = `api/app/purity/deletepurity?Id=${id}`;
      const json = JSON.stringify(url);
      return this.service.delete(url, true).pipe<ResponseModel>(
        tap((response: any) => {
          return response;
        })
      );
    }

    // *************get by id purity **************
    getByIdPurity(id:number):Observable<ResponseModel>{
      let url = `api/App/Purity/getByIdPurity?Id=${id}`;
      return this.service.get(url, true).pipe<ResponseModel>(
        tap((response: any) => {
          return response;
        })
      );
    }

    // *********** purity service end ************


    // *********** carat service start ************
   // **********get all carat *********
  getAllCarat():Observable<ResponseModel>{
    let url = "api/app/carat/getallcarat";
    return this.service.get(url, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }
  
 // *************insert carat **************
  insertCarat(data:InsertCaratRequestModel):Observable<ResponseModel>{
    let url = "api/app/carat/insertcarat";
    const json = JSON.stringify(data);
    return this.service.post(url,data, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

   // *************update carat **************
   updateCarat(data:UpdateCaratRequestModel):Observable<ResponseModel>{
    let url = "api/app/carat/updatecarat";
    const json = JSON.stringify(data);
    return this.service.putWithId(url,data,true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

    // *************delete carat **************
    deleteCarat(id:number):Observable<ResponseModel>{
      let url = `api/app/carat/deletecarat?Id=${id}`;
      return this.service.delete(url, true).pipe<ResponseModel>(
        tap((response: any) => {
          return response;
        })
      );
    }

    // *************get by id carat **************
    getByIdCarat(id:number):Observable<ResponseModel>{
      let url = `api/app/carat/getbyidcarat?Id=${id}`;
      return this.service.get(url, true).pipe<ResponseModel>(
        tap((response: any) => {
          return response;
        })
      );
    }

    // *********** carat service end ************


    
  // *********** unit service start ************
   // **********get all unit *********
  getAllUnit():Observable<ResponseModel>{
    let url = "api/app/unit/getallunit";
    return this.service.post(url, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }
  
 // *************insert unit **************
  insertUnit(data:InsertUnitRequestModel):Observable<ResponseModel>{
    let url = "api/app/unit/insertunit";
    const json = JSON.stringify(data);
    return this.service.post(url,data, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

   // *************update unit **************
   updateUnit(data:UpdateUnitRequestModel):Observable<ResponseModel>{
    let url = "api/app/unit/updateunit";
    const json = JSON.stringify(data);
    return this.service.putWithId(url,data,true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

    // *************delete unit **************
    deleteUnit(id:number):Observable<ResponseModel>{
      let url = `api/app/unit/deleteunit?Id=${id}`;
      return this.service.delete(url, true).pipe<ResponseModel>(
        tap((response: any) => {
          return response;
        })
      );
    }

    // *************get by id unit **************
    getByIdUnit(id:number):Observable<ResponseModel>{
      let url = `api/App/Unit/getByIdUnit?Id=${id}`;
      return this.service.get(url, true).pipe<ResponseModel>(
        tap((response: any) => {
          return response;
        })
      );
    }

    // *********** unit service end ************


    // *********** Remark service start ************
   // **********get all Remark *********
  getAllRemark():Observable<ResponseModel>{
    let url = "api/app/remark/getallremark";
    return this.service.get(url, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }
  
 // *************insert Remark **************
  insertRemark(data:InsertRemarkRequestModel):Observable<ResponseModel>{
    let url = "api/app/remark/insertremark";
    const json = JSON.stringify(data);
    return this.service.post(url,data, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

   // *************update Remark **************
   updateRemark(data:UpdateRemarkRequestModel):Observable<ResponseModel>{
    let url = "api/app/remark/updateremark";
    const json = JSON.stringify(data);
    return this.service.putWithId(url,data,true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

    // *************delete Remark **************
    deleteRemark(id:number):Observable<ResponseModel>{
      let url = `api/app/remark/deleteremark?Id=${id}`;
      return this.service.delete(url, true).pipe<ResponseModel>(
        tap((response: any) => {
          return response;
        })
      );
    }

    // *************get by id Remark **************
    getByIdRemark(id:number):Observable<ResponseModel>{
      let url = `api/app/remark/getbyidremark?Id=${id}`;
      return this.service.get(url, true).pipe<ResponseModel>(
        tap((response: any) => {
          return response;
        })
      );
    }

    // *********** Remark service end ************


    // *********** Category service start ************
   // **********get all Category *********
  getAllCategory():Observable<ResponseModel>{
    let url = "api/app/productcategory/getallproductcategory";
    return this.service.get(url, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }
  
 // *************insert Category **************
  insertCategory(data:InsertCategoryRequestModel):Observable<ResponseModel>{
    let url = "api/app/productcategory/insertproductcategory";
    const json = JSON.stringify(data);
    return this.service.post(url,data, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

   // *************update Category **************
   updateCategory(data:UpdateCategoryRequestModel):Observable<ResponseModel>{
    let url = "api/app/productcategory/updateproductcategory";
    const json = JSON.stringify(data);
    return this.service.putWithId(url,data,true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

    // *************delete Category **************
    deleteCategory(id:number):Observable<ResponseModel>{
      let url = `api/app/productcategory/deleteproductcategory?Id=${id}`;
      return this.service.delete(url, true).pipe<ResponseModel>(
        tap((response: any) => {
          return response;
        })
      );
    }

    // *************get by id Category **************
    getByIdCategory(id:number):Observable<ResponseModel>{
      let url = `api/app/productcategory/getbyidproductcategory?Id=${id}`;
      return this.service.get(url, true).pipe<ResponseModel>(
        tap((response: any) => {
          return response;
        })
      );
    }

    // *********** Category service end ************


// ************ Loan Product Type all service start**********

// **************Get Loan Product Type*******************
getAllLoanProductType():Observable<ResponseModel>{
  let url = `api/app/loanproducttype/getallloanproducttype`;
  return this.service.get(url, true).pipe<ResponseModel>(
    tap((response: any) => {
      return response;
    })
  );
}
// *************insert Loan Product Type **************
insertLoanProductType(data:InsertLoanProductTypeRequestModel):Observable<ResponseModel>{
  let url = `api/app/loanproducttype/insertloanproducttype`;
  const json = JSON.stringify(data);
  return this.service.post(url,data, true).pipe<ResponseModel>(
    tap((response: any) => {
      return response;
    })
  );
}

 // *************update Loan Product Type**************
 updateLoanProductType(data:UpdateLoanProductTypeRequestModel):Observable<ResponseModel>{
  let url = `api/app/loanproducttype/updateloanproducttype`;
  const json = JSON.stringify(data);
  return this.service.putWithId(url,data,true).pipe<ResponseModel>(
    tap((response: any) => {
      return response;
    })
  );
}

  // *************delete Loan Product Type**************
  deleteLoanProductType(id:number):Observable<ResponseModel>{
    let url = `api/app/loanproducttype/deleteloanproducttype?Id=${id}`;
    return this.service.delete(url, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }
   // ***********Loan Product Type Typeservice end ************
// ******************************************************



// ************ Loan Reason all service start**********

// **************Get Loan Product Type*******************
getAllLoanReason():Observable<ResponseModel>{
  let url = `api/app/loanreason/getallloanreason`;
  return this.service.get(url, true).pipe<ResponseModel>(
    tap((response: any) => {
      return response;
    })
  );
}
// *************insert Loan Reason**************
insertLoanReason(data:InsertLoanReasonRequestModel):Observable<ResponseModel>{
  let url = `api/app/loanreason/insertloanreason`;
  const json = JSON.stringify(data);
  return this.service.post(url,data, true).pipe<ResponseModel>(
    tap((response: any) => {
      return response;
    })
  );
}

 // *************update Loan Reason**************
 updateLoanReason(data:UpdateLoanReasonTypeRequestModel):Observable<ResponseModel>{
  let url = `api/app/loanreason/updateloanreason`;
  const json = JSON.stringify(data);
  return this.service.putWithId(url,data,true).pipe<ResponseModel>(
    tap((response: any) => {
      return response;
    })
  );
}

  // *************delete Loan Reason**************
  deleteLoanReason(id:number):Observable<ResponseModel>{
    let url = `api/app/loanreason/deleteloanreason?Id=${id}`;
    return this.service.delete(url, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

    // ***********Loan Product Type Typeservice end ************
// ******************************************************



 // *********** Task Type get all service start ************
 getAllTaskType():Observable<ResponseModel>{
  let url = `api/app/tasktype/getalltasktype`;
  return this.service.get(url, true).pipe<ResponseModel>(
    tap((response: any) => {
      return response;
    })
  );
}

// *************insert Task Type **************
insertTaskType(data:InsertTaskTypeRequestModel):Observable<ResponseModel>{
  let url = `api/app/tasktype/inserttasktype`;
  const json = JSON.stringify(data);
  return this.service.post(url,data, true).pipe<ResponseModel>(
    tap((response: any) => {
      return response;
    })
  );
}

 // *************update Task Type**************
 updateTaskType(data:UpdateTaskTypeRequestModel):Observable<ResponseModel>{
  let url = `api/app/tasktype/updatetasktype`;
  const json = JSON.stringify(data);
  return this.service.putWithId(url,data,true).pipe<ResponseModel>(
    tap((response: any) => {
      return response;
    })
  );
}

  // *************delete Task Type **************
  deleteTaskType(id:number):Observable<ResponseModel>{
    let url = `api/app/tasktype/deletetasktype?Id=${id}`;
    return this.service.delete(url, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

  // *************get by id Task Type **************
  getByIdTaskType(id:number):Observable<ResponseModel>{
    let url = `api/app/tasktype/getbyidtasktype?Id=1=${id}`;
    return this.service.get(url, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }




  // ***********Task Typeservice end ************

   // *********** Quality service start ************
   // **********get all Quality *********
  getAllQuality():Observable<ResponseModel>{
    let url = "api/app/quality/getallquality";
    return this.service.get(url, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }
  
 // *************insert Quality **************
  insertQuality(data:InsertQaulityRequestModel):Observable<ResponseModel>{
    let url = "api/app/quality/insertquality";
    const json = JSON.stringify(data);
    return this.service.post(url,data, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

   // *************update Quality **************
   updateQuality(data:UpdateQaulityRequestModel):Observable<ResponseModel>{
    let url = "api/app/quality/updatequality";
    const json = JSON.stringify(data);
    return this.service.putWithId(url,data,true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

    // *************delete Quality **************
    deleteQuality(id:number):Observable<ResponseModel>{
      let url = `api/app/quality/deletequality?Id=${id}`;
      return this.service.delete(url, true).pipe<ResponseModel>(
        tap((response: any) => {
          return response;
        })
      );
    }

    // *************get by id Quality **************
    getByIdQuality(id:number):Observable<ResponseModel>{
      let url = `api/App/ProductCategory/getByIdProductCategory?Id=${id}`;
      return this.service.get(url, true).pipe<ResponseModel>(
        tap((response: any) => {
          return response;
        })
      );
    }
    // *********** Quality service end ************


    // ********** Location service start ***********
   // *********get all Location ********
   getAllLocation():Observable<ResponseModel>{
    let url = "api/app/location/getalllocation";
    return this.service.get(url, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

// ************insert Location *************
insertLocation(data:InsertLocationRequestModel):Observable<ResponseModel>{
  let url = "api/app/location/insertlocation";
  const json = JSON.stringify(data);
  return this.service.post(url,data, true).pipe<ResponseModel>(
    tap((response: any) => {
      return response;
    })
  );
}

 // ************update Location *************
 updateLocation(data:UpdateLocationRequestModel):Observable<ResponseModel>{
  let url = "api/app/location/updatelocation";
  const json = JSON.stringify(data);
  return this.service.putWithId(url,data,true).pipe<ResponseModel>(
    tap((response: any) => {
      return response;
    })
  );
}

  // ************delete Location *************
  deleteLocation(id:number):Observable<ResponseModel>{
    let url = `api/app/location/deletelocation?Id=${id}`;
    return this.service.delete(url, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

  // ************get by id Location *************
  getByIdLocation(id:any):Observable<ResponseModel>{
    let url = `api/app/location/getbyidlocation?Id=${id}`;
    return this.service.get(url, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

  // ********** Location service end ***********



// ********** Collection service start ***********
 // *********get all Collection ********

 getAllCollection():Observable<ResponseModel>{
  let url = "api/app/collection/getallcollection";
  return this.service.get(url, true).pipe<ResponseModel>(
    tap((response: any) => {
      return response;
    })
  );
}
// ************insert Collection *************
insertCollection(data:InsertCollectionRequestModel):Observable<ResponseModel>{
let url = "api/app/collection/insertcollection";
const json = JSON.stringify(data);
return this.service.post(url,data, true).pipe<ResponseModel>(
  tap((response: any) => {
    return response;
  })
);
}

// ************update Collection *************
updateCollection(data:UpdateCollectionRequestModel):Observable<ResponseModel>{
let url = "api/app/collection/updatecollection";
const json = JSON.stringify(data);
return this.service.putWithId(url,data,true).pipe<ResponseModel>(
  tap((response: any) => {
    return response;
  })
);
}

// ************delete Collection *************
deleteCollection(id:number):Observable<ResponseModel>{
  let url = `api/app/collection/deletecollection?Id=${id}`;
  return this.service.delete(url, true).pipe<ResponseModel>(
    tap((response: any) => {
      return response;
    })
  );
}

// ************get by id Collection *************
getByIdCollection(id:any):Observable<ResponseModel>{
  let url = `api/app/collection/getbyidcollection?Id=${id}`;
  return this.service.get(url, true).pipe<ResponseModel>(
    tap((response: any) => {
      return response;
    })
  );
}
// ********** Collection service end ***********


// ********** Color service start ***********
 // *********get all Color ********

getAllColor():Observable<ResponseModel>{
let url = "api/app/color/getallcolor";
return this.service.get(url, true).pipe<ResponseModel>(
  tap((response: any) => {
    return response;
  })
);
}
// ************insert Color *************
insertColor(data:InsertColorRequestModel):Observable<ResponseModel>{
let url = "api/app/color/insertcolor";
const json = JSON.stringify(data);
return this.service.post(url,data, true).pipe<ResponseModel>(
  tap((response: any) => {
    return response;
  })
);
}

// ************update Color *************
updateColor(data:UpdateColorRequestModel):Observable<ResponseModel>{
let url = "api/app/color/updatecolor";
const json = JSON.stringify(data);
return this.service.putWithId(url,data,true).pipe<ResponseModel>(
  tap((response: any) => {
    return response;
  })
);
}

// ************delete Color *************
deleteColor(id:number):Observable<ResponseModel>{
  let url = `api/app/color/deletecolor?Id=${id}`;
  return this.service.delete(url, true).pipe<ResponseModel>(
    tap((response: any) => {
      return response;
    })
  );
}

 // *************get by id Color **************
 getByIdColor(id:number):Observable<ResponseModel>{
  let url = `api/app/color/getbyidcolor?Id=${id}`;
  return this.service.get(url, true).pipe<ResponseModel>(
    tap((response: any) => {
      return response;
    })
  );
}
// ********** Color service end ***********


  // ********** Cut service start ***********
 // *********get all Cut ********
 getAllCut():Observable<ResponseModel>{
  let url = "api/app/cut/getallcut";
  return this.service.get(url, true).pipe<ResponseModel>(
    tap((response: any) => {
      return response;
    })
  );
}

// ************insert Cut *************
insertCut(data:InsertCutRequestModel):Observable<ResponseModel>{
  let url = "api/app/cut/insertcut";
  const json = JSON.stringify(data);
  return this.service.post(url,data, true).pipe<ResponseModel>(
    tap((response: any) => {
      return response;
    })
  );
}

 // ************update Cut *************
 updateCut(data:UpdateCutRequestModel):Observable<ResponseModel>{
  let url = "api/app/cut/updatecut";
  const json = JSON.stringify(data);
  return this.service.putWithId(url,data,true).pipe<ResponseModel>(
    tap((response: any) => {
      return response;
    })
  );
}

  // ************delete Cut *************
  deleteCut(id:number):Observable<ResponseModel>{
    let url = `api/app/cut/deletecut?Id=${id}`;
    return this.service.delete(url, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

  // *************get by id Cut **************
  getByIdCut(id:number):Observable<ResponseModel>{
    let url = `api/app/cut/getbyidcut?Id=${id}`;
    return this.service.get(url, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }
    // *********** Cut service end ************


  // ********** Shape service start ***********
 // *********get all Shape ********
  getAllShape():Observable<ResponseModel>{
    let url = "api/app/shape/getallshape";
    return this.service.get(url, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

  // ************insert Shape *************
  insertShape(data:InsertShapeRequestModel):Observable<ResponseModel>{
    let url = "api/app/shape/insertshape";
    const json = JSON.stringify(data);
    return this.service.post(url,data, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

  // ************update Shape *************
  updateShape(data:UpdateShapeRequestModel):Observable<ResponseModel>{
    let url = "api/app/shape/updateshape";
    const json = JSON.stringify(data);
    return this.service.putWithId(url,data,true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

  // ************delete Shape *************
  deleteShape(id:number):Observable<ResponseModel>{
    let url = `api/app/shape/deleteshape?Id=${id}`;
    return this.service.delete(url, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

  // *************get by id Shape **************
  getByIdShape(id:number):Observable<ResponseModel>{
    let url = `api/app/shape/getbyidshape?Id=${id}`;
    return this.service.get(url, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }
    // *********** Shape service end ************



 // *********** Metal service start ************
  // **********get all Metal *********
   getAllMetal():Observable<ResponseModel>{
    let url = "api/app/metal/getallmetal";
    return this.service.get(url, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }
  
 // *************insert Metal **************
  insertMetal(data:InsertMetalRequestModel):Observable<ResponseModel>{
    let url = "api/app/metal/insertmetal";
    const json = JSON.stringify(data);
    return this.service.post(url,data, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

   // *************update Metal **************
   updateMetal(data:UpdateMetalRequestModel):Observable<ResponseModel>{
    let url = "api/app/metal/updatemetal";
    const json = JSON.stringify(data);
    return this.service.putWithId(url,data,true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

    // *************delete Metal **************
    deleteMetal(id:number):Observable<ResponseModel>{
      let url = `api/app/metal/deletemetal?Id=${id}`;
      return this.service.delete(url, true).pipe<ResponseModel>(
        tap((response: any) => {
          return response;
        })
      );
    }

    // *************get by id Metal **************
    getByIdMetal(id:number):Observable<ResponseModel>{
      let url = `api/app/metal/getbyidmetal?Id=${id}`;
      return this.service.get(url, true).pipe<ResponseModel>(
        tap((response: any) => {
          return response;
        })
      );
    }
    // *********** Metal service end ************


    // *********** Seivesize service start ************
   // **********get all Seivesize *********
  getAllSeivesize():Observable<ResponseModel>{
    let url = "api/app/seivesize/getallseivesize";
    return this.service.get(url, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }
 
 // *************insert Seivesize **************
  insertSeivesize(data:InsertSeivesizeRequestModel):Observable<ResponseModel>{
    let url = "api/app/seivesize/insertseivesize";
    const json = JSON.stringify(data);
    return this.service.post(url,data, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

   // *************update Seivesize **************
   updateSeivesize(data:UpdateSeivesizeRequestModel):Observable<ResponseModel>{
    let url = "api/app/seivesize/updateseivesize";
    const json = JSON.stringify(data);
    return this.service.putWithId(url,data,true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

    // *************delete Seivesize **************
    deleteSeivesize(id:number):Observable<ResponseModel>{
      let url = `api/app/seivesize/deleteseivesize?Id=${id}`;
      return this.service.delete(url, true).pipe<ResponseModel>(
        tap((response: any) => {
          return response;
        })
      );
    }

    // *************get by id Seivesize **************
    getByIdSeivesize(id:number):Observable<ResponseModel>{
      let url = `api/app/seivesize/getbyidseivesize?Id=${id}`;
      return this.service.get(url, true).pipe<ResponseModel>(
        tap((response: any) => {
          return response;
        })
      );
    }

    // *********** Seivesize service end ************

    
    // *********** SettingType service start ************
   // **********get all SettingType *********
  getAllSettingType():Observable<ResponseModel>{
    let url = "api/app/settingtype/getallsettingtype";
    return this.service.get(url, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }
  
 // *************insert SettingType **************
  insertSettingType(data:InsertSettingTypeRequestModel):Observable<ResponseModel>{
    let url = "api/app/settingtype/insertsettingtype";
    const json = JSON.stringify(data);
    return this.service.post(url,data, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

   // *************update SettingType **************
   updateSettingType(data:UpdateSettingTypeRequestModel):Observable<ResponseModel>{
    let url = "api/app/settingtype/updatesettingtype";
    const json = JSON.stringify(data);
    return this.service.putWithId(url,data,true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

    // *************delete SettingType **************
    deleteSettingType(id:number):Observable<ResponseModel>{
      let url = `api/app/settingtype/deletesettingtype?Id=${id}`;
      return this.service.delete(url, true).pipe<ResponseModel>(
        tap((response: any) => {
          return response;
        })
      );
    }

    // *************get by id SettingType **************
    getByIdSettingType(id:number):Observable<ResponseModel>{
      let url = `api/app/settingtype/getbyidsettingtype?Id=${id}`;
      return this.service.get(url, true).pipe<ResponseModel>(
        tap((response: any) => {
          return response;
        })
      );
    }

    // *********** SettingType service end ************


    // *********** DiamondSize service start ************
   // **********get all DiamondSize *********
   getAllDiamondSize():Observable<ResponseModel>{
    let url = "api/app/diamondsize/getalldiamondsize";
    return this.service.get(url, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }
  
 // *************insert DiamondSize **************
  insertDiamondSize(data:InsertDiamondSizeRequestModel):Observable<ResponseModel>{
    let url = "api/app/diamondsize/insertdiamondsize";
    const json = JSON.stringify(data);
    return this.service.post(url,data, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

   // *************update DiamondSize **************
   updateDiamondSize(data:UpdateDiamondSizeRequestModel):Observable<ResponseModel>{
    let url = "api/app/diamondsize/updatediamondsize";
    const json = JSON.stringify(data);
    return this.service.putWithId(url,data,true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

    // *************delete DiamondSize **************
    deleteDiamondSize(id:number):Observable<ResponseModel>{
      let url = `api/app/diamondsize/deletediamondsize?Id=${id}`;
      return this.service.delete(url, true).pipe<ResponseModel>(
        tap((response: any) => {
          return response;
        })
      );
    }

    // *************get by id DiamondSize **************
    getByIdDiamondSize(id:number):Observable<ResponseModel>{
      let url = `api/app/diamondsize/getbyiddiamondsize?Id=${id}`;
      return this.service.get(url, true).pipe<ResponseModel>(
        tap((response: any) => {
          return response;
        })
      );
    }

    // *********** DiamondSize service end ************


    // *********** ExchangeRate service start ************
   // **********get all ExchangeRate *********
   getAllExchangeRate():Observable<ResponseModel>{
    let url = "api/app/exchangerate/getallexchangerate";
    return this.service.get(url, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }
  
 // *************insert ExchangeRate **************
  insertExchangeRate(data:InsertExchangeRateRequestModel):Observable<ResponseModel>{
    let url = "api/app/exchangerate/insertexchangerate";
    const json = JSON.stringify(data);
    return this.service.post(url,data, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

   // *************update ExchangeRate **************
   updateExchangeRate(data:UpdateExchangeRateRequestModel):Observable<ResponseModel>{
    let url = "api/app/exchangerate/updateexchangerate";
    const json = JSON.stringify(data);
    return this.service.putWithId(url,data,true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

    // *************delete ExchangeRate **************
    deleteExchangeRate(id:number):Observable<ResponseModel>{
      let url = `api/app/exchangerate/deleteexchangerate?Id=${id}`;
      return this.service.delete(url, true).pipe<ResponseModel>(
        tap((response: any) => {
          return response;
        })
      );
    }

    // *************get by id ExchangeRate **************
    getByIdExchangeRate(id:number):Observable<ResponseModel>{
      let url = `api/app/exchangerate/getbyidexchangerate?Id=${id}`;
      return this.service.get(url, true).pipe<ResponseModel>(
        tap((response: any) => {
          return response;
        })
      );
    }

    // *********** ExchangeRate service end ************


    
    // *********** Currency service start ************
   // **********get all Currency *********
   getAllCurrency():Observable<ResponseModel>{
    let url = "api/app/currency/getallcurrency";
    return this.service.get(url, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }
  
 // *************insert Currency **************
  insertCurrency(data:InsertCurrencyRequestModel):Observable<ResponseModel>{
    let url = "api/app/currency/insertcurrency";
    const json = JSON.stringify(data);
    return this.service.post(url,data, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

   // *************update Currency **************
   updateCurrency(data:UpdateCurrencyRequestModel):Observable<ResponseModel>{
    let url = "api/app/currency/updatecurrency";
    const json = JSON.stringify(data);
    return this.service.putWithId(url,data,true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

  // *************delete Currency **************
  deleteCurrency(id:number):Observable<ResponseModel>{
    let url = `api/app/currency/deletecurrency?Id=${id}`;
    return this.service.delete(url, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

  // *************get by id Currency **************
  getByIdCurrency(id:number):Observable<ResponseModel>{
    let url = `api/app/currency/getbyidcurrency?Id=${id}`;
    return this.service.get(url, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

    // *********** Currency service end ************

  

   // *********** Setting service start ************
   // **********get all Setting *********
   getAllSetting():Observable<ResponseModel>{
    let url = "api/app/setting/getallsetting";
    return this.service.get(url, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }
  
 // *************insert Setting **************
  insertSetting(data:InsertSettingRequestModel):Observable<ResponseModel>{
    let url = "api/app/setting/insertsetting";
    const json = JSON.stringify(data);
    return this.service.post(url,data, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

   // *************update Setting **************
   updateSetting(data:UpdateSettingRequestModel):Observable<ResponseModel>{
    let url = "api/app/setting/updatequality";
    const json = JSON.stringify(data);
    return this.service.putWithId(url,data,true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

  // *************delete Setting **************
  deleteSetting(id:number):Observable<ResponseModel>{
    let url = `api/app/setting/deletesetting?Id=${id}`;
    return this.service.delete(url, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

  // *************get by id Setting **************
  getByIdSetting(id:number):Observable<ResponseModel>{
    let url = `api/app/setting/getbyidsetting?Id=${id}`;
    return this.service.get(url, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

    // *********** Setting service end ************


    
   // *********** TaxSetting service start ************
   // **********get all TaxSetting *********
   getAllTaxSetting():Observable<ResponseModel>{
    let url = "api/app/taxsetting/getalltaxsetting";
    return this.service.get(url, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }
  
 // *************insert TaxSetting **************
  insertTaxSetting(data:InsertTaxSettingRequestModel):Observable<ResponseModel>{
    let url = "api/app/taxsetting/inserttaxsetting";
    const json = JSON.stringify(data);
    return this.service.post(url,data, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

   // *************update TaxSetting **************
   updateTaxSetting(data:UpdateTaxSettingRequestModel):Observable<ResponseModel>{
    let url = "api/app/taxsetting/updatetaxsetting";
    const json = JSON.stringify(data);
    return this.service.putWithId(url,data,true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

  // *************delete TaxSetting **************
  deleteTaxSetting(id:number):Observable<ResponseModel>{
    let url = `api/app/taxsetting/deletetaxsetting?Id=${id}`;
    return this.service.delete(url, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

  // *************get by id TaxSetting **************
  getByIdTaxSetting(id:number):Observable<ResponseModel>{
    let url = `api/app/taxsetting/getbyidtaxsetting?Id=${id}`;
    return this.service.get(url, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

    // *********** TaxSetting service end ************


    // *********** UnitConversion service start ************
   // **********get all UnitConversion *********
   getAllUnitConversion():Observable<ResponseModel>{
    let url = "api/app/unitconversion/getallunitconversion";
    return this.service.get(url, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }
  
 // *************insert UnitConversion **************
  insertUnitConversion(data:InsertUnitConversionRequestModel):Observable<ResponseModel>{
    let url = "api/app/unitconversion/insertunitconversion";
    const json = JSON.stringify(data);
    return this.service.post(url,data, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

   // *************update UnitConversion **************
   updateUnitConversion(data:UpdateUnitConversionRequestModel):Observable<ResponseModel>{
    let url = "api/app/unitconversion/updateunitconversion";
    const json = JSON.stringify(data);
    return this.service.putWithId(url,data,true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

  // *************delete UnitConversion **************
  deleteUnitConversion(id:number):Observable<ResponseModel>{
    let url = `api/app/unitconversion/deleteunitconversion?Id=${id}`;
    return this.service.delete(url, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

  // *************get by id UnitConversion **************
  getByIdUnitConversion(id:number):Observable<ResponseModel>{
    let url = `unitconversion/getbyidunitconversion?Id=${id}`;
    return this.service.get(url, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

    // *********** UnitConversion service end ************


    
     // ********** Rate service start ***********
   // *********get all Rate ********
   getAllRate():Observable<ResponseModel>{
    let url = "api/app/rate/getallrates";
    return this.service.get(url, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }
  
 // ************insert Rate *************
  insertRate(data:InsertRateRequestModel):Observable<ResponseModel>{
    let url = "api/app/rate/insertrate";
    const json = JSON.stringify(data);
    return this.service.post(url,data, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }
   // ************update Rate *************
   updateRate(data:UpdateRateRequestModel):Observable<ResponseModel>{
    let url = "api/app/rate/updaterate";
    const json = JSON.stringify(data);
    return this.service.putWithId(url,data,true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }
  // ************delete Rate *************
  deleteRate(id:number):Observable<ResponseModel>{
    let url = `api/app/rate/deleterate?Id=${id}`;
    return this.service.delete(url, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }
  // ************get by id Rate *************
  getByIdRate(id:number):Observable<ResponseModel>{
    let url = `api/app/rate/getbyidrate?Id=${id}`;
    return this.service.get(url, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }
    // ********** Rate service end ***********



    // ********** Size service start ***********
 // *********get all Size ********
  getAllSize():Observable<ResponseModel>{
    let url = "api/app/size/getallsize";
    return this.service.get(url, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

  // ************insert Size *************
  insertSize(data:InsertSizeRequestModel):Observable<ResponseModel>{
    let url = "api/app/size/insertsize";
    const json = JSON.stringify(data);
    return this.service.post(url,data, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

  // ************update Size *************
  updateSize(data:UpdateSizeRequestModel):Observable<ResponseModel>{
    let url = "api/app/size/updatesize";
    const json = JSON.stringify(data);
    return this.service.putWithId(url,data,true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

  // ************delete Size *************
  deleteSize(id:number):Observable<ResponseModel>{
    let url = `api/app/size/deletesize?Id=${id}`;
    return this.service.delete(url, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

  // *************get by id Size **************
  getByIdSize(id:number):Observable<ResponseModel>{
    let url = `api/app/size/getbyidsize?Id=${id}`;
    return this.service.get(url, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }
    // *********** Size service end ************



  // ********** DocumentType service start ***********
 // *********get all DocumentType ********
  getAllDocumentType():Observable<ResponseModel>{
    let url = "api/app/documenttype/getalldocumenttype";
    return this.service.get(url, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

  // ************insert DocumentType *************
  insertDocumentType(data:InsertDocumentTypeRequestModel):Observable<ResponseModel>{
    let url = "api/app/documenttype/insertdocumenttype";
    const json = JSON.stringify(data);
    return this.service.post(url,data, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

  // ************update DocumentType *************
  updateDocumentType(data:UpdateDocumentTypeRequestModel):Observable<ResponseModel>{
    let url = "api/app/documenttype/updatedocumenttype";
    const json = JSON.stringify(data);
    return this.service.putWithId(url,data,true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

  // ************delete DocumentType *************
  deleteDocumentType(id:number):Observable<ResponseModel>{
    let url = `api/app/documenttype/deletedocumenttype?Id=${id}`;
    return this.service.delete(url, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

  // *************get by id DocumentType **************
  getByIdDocumentType(id:number):Observable<ResponseModel>{
    let url = `api/app/documenttype/getbyiddocumenttype?Id=${id}`;
    return this.service.get(url, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }
    // *********** DocumentType service end ************



  // ********** Counter service start ***********
 // *********get all Counter ********
  getAllCounter():Observable<ResponseModel>{
    let url = "api/app/counter/getallcounter";
    return this.service.get(url, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

  // ************insert Counter *************
  insertCounter(data:InsertCounterRequestModel):Observable<ResponseModel>{
    let url = "api/app/counter/insertcounter";
    const json = JSON.stringify(data);
    return this.service.post(url,data, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

  // ************update Counter *************
  updateCounter(data:UpdateCounterRequestModel):Observable<ResponseModel>{
    let url = "api/app/counter/updatecounter";
    const json = JSON.stringify(data);
    return this.service.putWithId(url,data,true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

  // ************delete Counter *************
  deleteCounter(id:number):Observable<ResponseModel>{
    let url = `api/app/counter/deletecounter?Id=${id}`;
    return this.service.delete(url, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

  // *************get by id Counter **************
  getByIdCounter(id:number):Observable<ResponseModel>{
    let url = `api/app/counter/getbyidcounter?Id=${id}`;
    return this.service.get(url, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }
    // *********** Counter service end ************



    
 // *********** BarcodeToolbox service start ************
  // **********get all BarcodeToolbox *********
   getAllBarcodeToolbox():Observable<ResponseModel>{
    let url = "api/app/barcodetoolboxkey/getallbarcodetoolboxkey";
    return this.service.get(url, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }
  
 // *************insertupdate BarcodeToolbox **************
  insertUpdateBarcodeToolbox(data:InsertupdateBarcodeToolboxRequestModel):Observable<ResponseModel>{
    let url = "api/app/barcodetoolboxkey/updatebarcodetoolboxkey";
    const json = JSON.stringify(data);
    return this.service.putWithId(url,data, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

    // *************get by id BarcodeToolbox **************
    getByIdBarcodeToolbox(id:number):Observable<ResponseModel>{
      let url = `api/app/metal/getbyidmetal?Id=${id}`;
      return this.service.get(url, true).pipe<ResponseModel>(
        tap((response: any) => {
          return response;
        })
      );
    }
    // *********** BarcodeToolbox service end ************



    // ********** PacketType service start ***********
   // *********get all PacketType ********
    getAllPacketType():Observable<ResponseModel>{
      let url = "api/app/packettype/getallpackettype";
      return this.service.get(url, true).pipe<ResponseModel>(
        tap((response: any) => {
          return response;
        })
      );
    }
  // ************insert PacketType *************
    insertPacketType(data:InsertPacketTypeRequestModel):Observable<ResponseModel>{
      let url = "api/app/packettype/insertpackettype";
      const json = JSON.stringify(data);
      return this.service.post(url,data, true).pipe<ResponseModel>(
        tap((response: any) => {
          return response;
        })
      );
    }

  // ************update PacketType *************
    updatePacketType(data:UpdatePacketTypeRequestModel):Observable<ResponseModel>{
      let url = "api/app/packettype/updatepackettype";
      const json = JSON.stringify(data);
      return this.service.putWithId(url,data,true).pipe<ResponseModel>(
        tap((response: any) => {
          return response;
        })
      );
    }

  // ************delete PacketType *************
    deletePacketType(id:number):Observable<ResponseModel>{
      let url = `api/app/packettype/deletepackettype?Id=${id}`;
      return this.service.delete(url, true).pipe<ResponseModel>(
        tap((response: any) => {
          return response;
        })
      );
    }

  // ************get by id PacketType *************
    getByIdPacketType(id:any):Observable<ResponseModel>{
      let url = `api/app/packettype/getbyidpackettype?Id=${id}`;
      return this.service.get(url, true).pipe<ResponseModel>(
        tap((response: any) => {
          return response;
        })
      );
    }
  // ********** PacketType service end ***********


  
  // ********** Clarity service start ***********
 // *********get all Clarity ********
 getAllClarity():Observable<ResponseModel>{
  let url = "api/app/clarity/getallclarity";
  return this.service.get(url, true).pipe<ResponseModel>(
    tap((response: any) => {
      return response;
    })
  );
}

// ************insert Clarity *************
insertClarity(data:InsertClarityRequestModel):Observable<ResponseModel>{
  let url = "api/app/clarity/insertclarity";
  const json = JSON.stringify(data);
  return this.service.post(url,data, true).pipe<ResponseModel>(
    tap((response: any) => {
      return response;
    })
  );
}

// ************update Clarity *************
updateClarity(data:UpdateClarityRequestModel):Observable<ResponseModel>{
  let url = "api/app/clarity/updateclarity";
  const json = JSON.stringify(data);
  return this.service.putWithId(url,data,true).pipe<ResponseModel>(
    tap((response: any) => {
      return response;
    })
  );
}

// ************delete Clarity *************
deleteClarity(id:number):Observable<ResponseModel>{
  let url = `api/app/clarity/deleteclarity?Id=${id}`;
  return this.service.delete(url, true).pipe<ResponseModel>(
    tap((response: any) => {
      return response;
    })
  );
}

// *************get by id Clarity **************
// getByIdClarity(id:number):Observable<ResponseModel>{
//   let url = `api/app/counter/getbyidcounter?Id=${id}`;
//   return this.service.get(url, true).pipe<ResponseModel>(
//     tap((response: any) => {
//       return response;
//     })
//   );
// }
  // *********** Clarity service end ************


 // ***********Expences Category service Start ************


 // *********** Task Type get all service start ************
 getAllExpensesCategoryList():Observable<ResponseModel>{
  let url = `api/app/expensescategory/getallexpensescategory`;
  return this.service.get(url, true).pipe<ResponseModel>(
    tap((response: any) => {
      return response;
    })
  );
}

// *************insert Task Type **************
InsertExpensesCategoryList(data:InsertTaskTypeRequestModel):Observable<ResponseModel>{
  let url = `api/app/expensescategory/insertexpensescategory`;
  const json = JSON.stringify(data);
  return this.service.post(url,data, true).pipe<ResponseModel>(
    tap((response: any) => {
      return response;
    })
  );
}

 // *************update Task Type**************
 UpdateExpensesCategory(data:UpdateTaskTypeRequestModel):Observable<ResponseModel>{
  let url = `api/app/expensescategory/updateexpensescategory`;
  const json = JSON.stringify(data);
  return this.service.putWithId(url,data,true).pipe<ResponseModel>(
    tap((response: any) => {
      return response;
    })
  );
}

  // *************delete Task Type **************
  DeleteExpensesCategory(id:number):Observable<ResponseModel>{
    let url = `api/app/expensescategory/deleteexpensescategory?Id=${id}`;
    return this.service.delete(url, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

 // ***********Expences Category service end ************




}
