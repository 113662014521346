import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { StorageService } from 'src/app/shared/services/storage.service';
import { convertUtcToLocalDateTime } from 'src/app/shared/utils/common-utils';

@Component({
  selector: 'app-multiselect-filter',
  templateUrl: './multiselect-filter.component.html',
  styleUrls: ['./multiselect-filter.component.scss']
})
export class MultiselectFilterComponent implements OnInit {

  searchValue='';
  @Input()params;
  @Input()data = [];
  @Input()selectedData = [];
  @ViewChild('FieldValueForm') fieldValueForm  : NgForm

  constructor(public activeModal : NgbActiveModal,private modalService: NgbModal,public storageService : StorageService){

  }
  ngOnInit(): void {
    console.log(this.data);
    this.selectedData = JSON.parse(JSON.stringify(this.params?.appliedData??[]));
    if (this.data[0]?.entityType === 'ClosingDateDropdown') {
      this.data = this.data?.map(item => {
          return {
              ...item,
              name: convertUtcToLocalDateTime(item.name)
          };
      });
  }
  }

    clearFilter(){
      if(this.selectedData.length == 0){
        this.activeModal.dismiss()
      }else{
        this.selectedData = []
        this.activeModal.close(this.selectedData);
      }
      
    }
    applyFilter(){
      this.activeModal.close(this.selectedData);
    }

    check(value){
     return this.selectedData.map((x)=> x?.id)?.includes(value);
    }

    add(selected){
      if(this.selectedData.map((x)=> x?.id)?.includes(selected?.id)){
        const index = this.selectedData?.findIndex((x)=>x.id == selected.id)
        this.selectedData?.splice(index ,1);
      }else{
        this.selectedData?.push(selected);
      }
    }

    selectAll(event){
      this.selectedData = [];
      if(event){
        this.selectedData = [...this.data]
      }
    }
    CloseFilterModel(){
      this.activeModal.dismiss()
      // if(this.selectedData.length == 0){
      //   this.activeModal.dismiss()
      // }else{
      //   this.selectedData = [];
      //   this.activeModal.close(this.selectedData);
      // }
    }
}
