import { Component, OnInit, Renderer2, ViewChild } from '@angular/core';
import { CommonService } from '../../services/common.service';
import { NgForm } from '@angular/forms';
import { AdministrationService } from 'src/app/components/administration/administration.service';
import { ToastrService } from 'ngx-toastr';
import { catchError } from 'rxjs';
import { StorageService } from '../../services/storage.service';

@Component({
  selector: 'app-taxsetting',
  templateUrl: './taxsetting.component.html',
  styleUrls: ['./taxsetting.component.scss']
})
export class TaxsettingComponent extends CommonService implements OnInit{
  @ViewChild('administrationFormData') administrationFormData! : NgForm ;
  getAllTaxSettingData          : any[]=[];
  isAdd                       : boolean = false;

constructor(
  public renderer:Renderer2,
  public administrationService : AdministrationService,
  public toaster: ToastrService,
  public storageService : StorageService
) 
{
  super(renderer)
}

ngOnInit(): void {
  this.getAllTaxSettingList();
}

add(){
  this.isAdd = true;
  const obj = {id:0, name:'',active:true,applicableOn:0,rate:0,calculationMode:0, comment: '',}
  this.getAllTaxSettingData?.push(obj);
  setTimeout(()=>{
    const element = document.getElementById(`TaxSettingName${obj.id}`);
    element?.focus();
  },0)
}

cancel(){
  this.isAdd = false;
  this.getAllTaxSettingData = this.getAllTaxSettingData.filter((x)=>x.id != 0)
}

edit(item,formData){
  item.isEdit = true;
  const controlObj = formData?.form?.controls;
  if(controlObj){
    const id = Object.keys(controlObj)[0];
    const element = document.getElementById(id);
    element?.focus();
  }
}

  // *********** TaxSetting API START *********
  // **********get all TaxSetting *********
  getAllTaxSettingList() {
    this.administrationService.getAllTaxSetting().subscribe({
      next:(res)=>{
        if(res.isSuccess){
          this.getAllTaxSettingData = res.response??[];
          this.storageService.store("TaxSetting",this.getAllTaxSettingData );
        }else{           
          this.getAllTaxSettingData = [];
        }  
      },
      error:(err)=>{
        this.getAllTaxSettingData = [];
      }
    })
  }
  
 // *************insert TaxSetting **************
  InserTaxSettingList(item) {
    const {id , ...rest} = item;
    this.administrationService.insertTaxSetting(rest)
        .pipe(catchError((err) => this.handleError(err)))
        .subscribe((res) => {
          if(res.isSuccess){
            this.isAdd = false;
            this.getAllTaxSettingList();
            this.toaster.success(res.message);
          }else{
            this.toaster.error(res.errors[0]);
          }  
      });
    }

  // **********update TaxSetting *********
    UpdateTaxSettingList(item) {
    const {isEdit , ...rest} = item;
    this.administrationService.updateTaxSetting(rest)
        .pipe(catchError((err) => this.handleError(err)))
        .subscribe((res) => {
          if(res.isSuccess){
            console.log(res?.message)
            this.isAdd = false;
            this.getAllTaxSettingList();
            this.toaster.success(res.message);
          }else{
            this.toaster.error(res.errors[0]);
          }  
      });
    }  

  // **********delete TaxSetting *********
    DeleteTaxSettingList(id) {
      this.administrationService.deleteTaxSetting(id)
          .pipe(catchError((err) => this.handleError(err)))
          .subscribe((res) => {
            if(res.isSuccess){
              this.getAllTaxSettingList();
              this.toaster.success(res.message);
            }else{
              this.toaster.error(res.errors[0]);
            }  
      });
    }
  
  // *********** TaxSetting API END *********
}

