<form >
    <div class="row mx-0">
        <div class="col-12 overflow sale_bill_one px-0">
            <table class="table table-bordered table-scroll border-right">
                <tbody>
                    <tr class="">
                        <td width="6%">
                            <div class="table_text mt-1 font-semibold f-14">Comment</div>
                        </td>
                        <td width="65%">
                            <app-input 
                            [inputName]="'comment'" 
                            [inputType]="'text'"
                            [inputId]="'comment'" 
                            [inputClass]="'bordernone'"
                            [inputValue]="formModel.invoiceModel.comment"
                            (inputValueEmit)="formModel.invoiceModel.comment = $event" 
                            [isRequired]="false"
                            >
                        </app-input>
                        </td>     
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</form>
