import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ResetRenderService {
  public deleteIconClickIndex = new Subject<any>();
  constructor() { }
  DeleteClick(index) {
    this.deleteIconClickIndex.next(index);
  }
}
