<!-- footer start-->
<div class="container-fluid">
  <form action="">
  <div class="row">
    <div class="col-md-9 d-flex px-0">
      <!-- *ngIf="showYoutubeicon"  -->
      <svg
      (click)="onYoutube()"
        class="pe-2 cursorPointer"
        xmlns="http://www.w3.org/2000/svg"
        width="50.455"
        height="28"
        viewBox="0 0 50.455 34"
      >
        <path
          id="youtube"
          d="M47.407,45H13a7.962,7.962,0,0,1-8.024-7.851v-18.3A7.962,7.962,0,0,1,13,11H47.407a7.962,7.962,0,0,1,8.024,7.851v18.3A7.962,7.962,0,0,1,47.407,45ZM24.449,21.31V35.831l13.043-7.382Z"
          transform="translate(-4.977 -11)"
          fill="#e43535"
          fill-rule="evenodd"
        />
      </svg>

      <app-button
        class="me-3"
        [buttonLabel]="'Premium'"
        [buttonClass]="'yellow_button'"
        [buttonType]="'button'"
      >
      </app-button>
      <!-- <button type="button" class="button button-warning me-3 f-14">Premium</button> -->
      <div class="d-flex flex-wrap align-items-center">
        <span class="me-2 text-gray-color border-right pe-2 f-10">{{
          this.crid || "NA"
        }}</span>
        <span class="pe-2 border-right text-gray-color f-10">{{ipAddress}}</span>
        <span class="ms-2 text-gray-color border-right pe-2 f-10 d-none"
          >SMS Bal.- WhatsApp: 2000, E-Invoice: 2000, AML: 2000</span
        >
        <span class="ms-2 text-gray-color f-10 d-none">Last Backup: 06/09/2023</span>
      </div>
    </div>
    <div
      class="col-md-3 pe-0 text-end d-flex gap-3 justify-content-end align-items-center"
    >
      <!-- <div class="">
        <label for="category" class="f-14 font-semibold text-gray-color text-nowrap mb-0">Date</label>
    </div> -->
      <app-input-datepicker-renderer
        [inputDate]="invoiceDate"
        [id]="'invoiceDate'"
        (dateChanged)="selectedRangeDates($event)"
      ></app-input-datepicker-renderer>
      <img
        src="./assets/svg/KeybordshortCutKey.svg"
        alt=""
        class="cursorPointer"
        (click)="
          openKeybordShortcutkeyPopup(keyboardShortcutsTitle.KeyboardShortcuts)
        "
      />
      <img
        src="./assets/SVGicon/footericon.svg"
        alt=""
        class="cursorPointer"
        [ngClass]="layoutService.hideContent == true?'d-none':'d-block'"
        (click)="openReferAndEarnPopup()"
      />
      <img
        src="./assets/SVGicon/whatsApp-icon.svg"
        alt=""
        class="cursorPointer"
        [ngClass]="layoutService.hideContent == true?'d-none':'d-block'"
        (click)="onWhatsAppSupportClick()"
      />
    </div>
  </div>
</form>
</div>
