import { Directive, Input, HostListener, ElementRef } from '@angular/core';

@Directive({
  selector: '[appMinMaxValue]'
})
export class MinMaxValueDirective {
  @Input('appMinMaxValue') minValue: any;
  @Input() appMaxValue: number;
  private previousValue: any;

  constructor(private el: ElementRef) {}

  @HostListener('ngModelChange', ['$event']) onModelChange(event: any) {
    const currentValue = event;

    // Check if the current value is less than the specified minimum
    if (parseFloat(currentValue) < this.minValue.min) {
      // Restore the previous value if the new value is less than the specified minimum
      this.el.nativeElement.value = this.previousValue;
    }
    // Check if the current value is greater than the specified maximum
    else if (parseFloat(currentValue) > this.minValue.max) {
      // Restore the previous value if the new value is greater than the specified maximum
      this.el.nativeElement.value = this.previousValue;
    } else {
      // Update the previous value if the new value is within the allowed range
      this.previousValue = currentValue;
    }
  }
}
