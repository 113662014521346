import { ResponseModel } from './../../shared/models/response-model.model';
import { Observable, tap } from 'rxjs';
import { DataService } from './../../shared/services/data.service';
import { Injectable } from '@angular/core';
import { DashboardRequestModel, StockRequestModel } from './dashboard.model';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(private service:DataService) { }

  getAllDashboardChartData():Observable<ResponseModel>{
    let url = "api/app/rate/getgoldratedashboard";
    return this.service.get(url, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

  getLowAndMostSellingStock(data:any):Observable<ResponseModel>{
    let url = "api/app/stockdashboard/getlowandmostsellingstock";
    return this.service.post(url,data, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

  getStockWithAverageMakingCharges(data:any):Observable<ResponseModel>{
    let url = "api/app/stockdashboard/getstockwithaveragemakingcharge";
    return this.service.post(url,data, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
}


getStockRadialBarChartData(data:StockRequestModel):Observable<ResponseModel>{
  let url = "api/app/stockdashboard/getstocktrackingdata";
  return this.service.post(url,data, true).pipe<ResponseModel>(
    tap((response: any) => {
      return response;
    })
  );
}

getmetalwiseavailablestock(data:any):Observable<ResponseModel>{
  let url = "api/app/stockdashboard/getmetalwiseavailablestock";
  return this.service.post(url,data, true).pipe<ResponseModel>(
    tap((response: any) => {
      return response;
    })
  );
}

getManufacturingDashboardMarketPrice(data:any):Observable<ResponseModel>{
  let url = "api/app/manufacturingdashboard/getmanufacturingdashboardmarketprices";
  return this.service.post(url,data, true).pipe<ResponseModel>(
    tap((response: any) => {
      return response;
    })
  );
}

getManufacturingDashboardAgeinglist(data:any):Observable<ResponseModel>{
  let url = "api/app/manufacturingdashboard/getmanufacturingdashboardageinglist";
  return this.service.post(url,data, true).pipe<ResponseModel>(
    tap((response: any) => {
      return response;
    })
  );
}
getManufacturingDashboardJobsinworkprogress(data:any):Observable<ResponseModel>{
  let url = "api/app/manufacturingdashboard/getmanufacturingdashboardjobsinprogress";
  return this.service.post(url,data, true).pipe<ResponseModel>(
    tap((response: any) => {
      return response;
    })
  );
}
getManufacturingDashboardJobsinworkstation(data:any):Observable<ResponseModel>{
  let url = "api/app/manufacturingdashboard/getmanufacturingdashboardjobsinworkstation";
  return this.service.post(url,data, true).pipe<ResponseModel>(
    tap((response: any) => {
      return response;
    })
  );
}
getCustomerRadialBarChartData(data:DashboardRequestModel):Observable<ResponseModel>{
  let url = "api/app/customerdashboard/getcustomerdashboardordertracking";
  return this.service.post(url,data, true).pipe<ResponseModel>(
    tap((response: any) => {
      return response;
    })
  );
}

getManufacturingRadialBarChartData(data:DashboardRequestModel):Observable<ResponseModel>{
  let url = "api/app/manufacturingdashboard/getmanufacturingdashboardordertracking";
  return this.service.post(url,data, true).pipe<ResponseModel>(
    tap((response: any) => {
      return response;
    })
  );
}
getRetailerRadialBarChartData(data:DashboardRequestModel):Observable<ResponseModel>{
  let url = "api/app/retailerdashboard/getretailerdashboardordertracking";
  return this.service.post(url,data, true).pipe<ResponseModel>(
    tap((response: any) => {
      return response;
    })
  );
}

getManufacturingDashboardPayment(data:any):Observable<ResponseModel>{
  let url = "api/app/manufacturingdashboard/getmanufacturingdashboardpaymentdata";
  return this.service.post(url,data, true).pipe<ResponseModel>(
    tap((response: any) => {
      return response;
    })
  );
}

getManufacturingDashboardJobsOnHold(data:any):Observable<ResponseModel>{
  let url = "api/app/manufacturingdashboard/getmanufacturingdashboardjobsonhold";
  return this.service.post(url,data, true).pipe<ResponseModel>(
    tap((response: any) => {
      return response;
    })
  );
}

getManufacturingDashboardCurrentconsignmentlist
(data:any):Observable<ResponseModel>{
  let url = "api/app/manufacturingdashboard/getmanufacturingdashboardcurrentconsignmentlist";
  return this.service.post(url,data, true).pipe<ResponseModel>(
    tap((response: any) => {
      return response;
    })
  );
}

getManufacturingDashboardDelayedjoborder(data:any):Observable<ResponseModel>{
  let url = "api/app/manufacturingdashboard/getmanufacturingdashboarddelayedjoborder";
  return this.service.post(url,data, true).pipe<ResponseModel>(
    tap((response: any) => {
      return response;
    })
  );
}
getManufacturingDashboardCompletedjoborder(data:any):Observable<ResponseModel>{
  let url = "api/app/manufacturingdashboard/getmanufacturingdashboardcompletedorder";
  return this.service.post(url,data, true).pipe<ResponseModel>(
    tap((response: any) => {
      return response;
    })
  );
}
getManufacturingDashboardRecentsaleorder(data:any):Observable<ResponseModel>{
  let url = "api/app/manufacturingdashboard/getmanufacturingdashboardrecentsaleorder";
  return this.service.post(url,data, true).pipe<ResponseModel>(
    tap((response: any) => {
      return response;
    })
  );
}
getCatalogTrackingRadialBarChartData(data:DashboardRequestModel):Observable<ResponseModel>{
  let url = "api/app/cataloguetrackingdashboard/getcataloguetrackingdashboardordertracking";
  return this.service.post(url,data, true).pipe<ResponseModel>(
    tap((response: any) => {
      return response;
    })
  );
}

getRetailerDashboardMarketPrice(data:any):Observable<ResponseModel>{
  let url = "api/app/retailerdashboard/getretailerdashboardmarketpricedata";
  return this.service.post(url,data, true).pipe<ResponseModel>(
    tap((response: any) => {
      return response;
    })
  );
}
getCustomerDashboardBilledData(data:DashboardRequestModel):Observable<ResponseModel>{
  let url = "api/app/customerdashboard/getcustomerdashboardmostbilledcustomer";
  return this.service.post(url,data, true).pipe<ResponseModel>(
    tap((response: any) => {
      return response;
    })
  );
}
getCatalogTrackingDashboardProductData(data:DashboardRequestModel):Observable<ResponseModel>{
  let url = "api/app/cataloguetrackingdashboard/getcataloguetrackingdashboardtopproducts";
  return this.service.post(url,data, true).pipe<ResponseModel>(
    tap((response: any) => {
      return response;
    })
  );
}
getCustomerDashboardAreaWiseData(data:DashboardRequestModel):Observable<ResponseModel>{
  let url = "api/app/customerdashboard/getcustomerdashboardareawisecustomer";
  return this.service.post(url,data, true).pipe<ResponseModel>(
    tap((response: any) => {
      return response;
    })
  );
}

 getDashboardByUserId(id:number):Observable<ResponseModel>{
  let url = `api/app/users/getrecentlyuseddashboardbyuserid?userId=${id}`;
  return this.service.get(url, true).pipe<ResponseModel>(
    tap((response: any) => {
      return response;
    })
  );
}
updateRecentlyUsedDashboard(data:any):Observable<ResponseModel>{
  let url = "api/app/users/updaterecentlyuseddashboard";
  return this.service.put(url,data,true).pipe<ResponseModel>(
    tap((response: any) => {
      return response;
    })
  );
}
getAllCalendarDataForDashboard(data:any):Observable<ResponseModel>{
  let url = "api/app/retailerdashboard/getallcalendardataforretailerdashboard";
  return this.service.post(url,data, true).pipe<ResponseModel>(
    tap((response: any) => {
      return response;
    })
  );
}
getCallScheduleDataForDashboard(data:any):Observable<ResponseModel>{
  let url = "api/app/retailerdashboard/getcallscheduledataforretailerdashboard";
  return this.service.post(url,data, true).pipe<ResponseModel>(
    tap((response: any) => {
      return response;
    })
  );
}

Getretailerstock(data:any):Observable<ResponseModel>{
  let url = 'api/app/retailerdashboard/getretailerstock';
  return this.service.post(url,data, true).pipe<ResponseModel>(
    tap((response: any) => {
      return response;
    })
  );
}
Getretailerstockmetalwise(data:any):Observable<ResponseModel>{
  let url = 'api/app/retailerdashboard/getretailerstockmetalwise';
  return this.service.post(url,data, true).pipe<ResponseModel>(
    tap((response: any) => {
      return response;
    })
  );
}

}
