import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbActiveModal, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import {AddEditComment} from './add-edit-comment.model'
import { ManufacturerService } from 'src/app/components/manufacturer/manufacturer.service';
import { IconHover } from '../../../enum/common-enum';
import { JobWorkStatus } from 'src/app/components/orders/order-tracking/order-tracking.model';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { CallApiConfirmComponent } from '../../common-popup/call-api-confirm/call-api-confirm.component';
import { commonModalOptions, ModalPopupSize } from 'src/app/shared/models/common.model';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-add-edit-comment',
  templateUrl: './add-edit-comment.component.html',
  styleUrls: ['./add-edit-comment.component.scss']
})
export class AddEditCommentComponent implements OnInit{
  // comment='';
  // dateTime:''
@Output() statusUpdated = new EventEmitter<void>();
 addeditComment = new AddEditComment()
 @Input() data: any
 @Input()getAllDropdownModel:any
  @Input()comments = [];
  currentHoverEffect = IconHover.Pink;
  comment
  index :number;
  @ViewChild('addEditCommentForm') addEditCommentForm  : NgForm
  formModel : any ;
  constructor(public activeModal : NgbActiveModal,
    public manufacturerService: ManufacturerService,
    public notificationService: NotificationService,
    public modalservice: NgbModal,
    public toaster : ToastrService,  

  ){

  }
  ngOnInit(): void {
      this.getComment(this.data?.jobworkQueueMasterId)
      const statusDropdown = this.getAllDropdownModel?.jobWorkStatusDropdown.filter(item =>item.id == JobWorkStatus.Processing || item.id == JobWorkStatus.Hold ||  item.id == JobWorkStatus.Rejected );
      this.getAllDropdownModel.jobWorkStatusDropdown =statusDropdown;
    }
  
getComment(id){
  if(id){
  this.manufacturerService.getJobworkQueueCommentByMasterId(id).subscribe({
    next: (res) => {
      if (res?.isSuccess) {
          this.comments = JSON.parse(res?.response?.comment);
          // let data:any = '[{"comment":"test","dateTime":"2024-08-23T15:21:32.539Z"}]'
          // this.comments = JSON.parse(data);
      }
    }
  })
}
}

  UpdateStatus(item) {
    const modalOption: NgbModalOptions = {
      ...commonModalOptions,
      size: "sm",
    };
    const madalRef = this.modalservice.open(CallApiConfirmComponent, modalOption);
    madalRef.componentInstance.callApi = item;
    madalRef.result.then((result) => {
      if (result) {
        this.UpdateStatusCallApi(this.data)
      } else {
      }
    });
    return false;
  }

  UpdateStatusCallApi(data){
    const requestModel ={ invoiceModel:{Id:data.jobworkOrderId ,VoucherTypeId:data.jobworkOrderVoucherTypeId ,statusId:data?.statusId}}
    this.manufacturerService.updateStatus(requestModel).subscribe({
      next:(res)=>{
        if(res?.isSuccess){
          this.toaster.success(res.message);
          this.statusUpdated.emit();
        }else{   
          this.toaster.error(res.message);           
        }  
      },
      error:(err)=>{
      }
    })
  }

  OnSaveFieldsValues(){
    const result ={comment:this.comments,id:this.data?.jobworkQueueMasterId}
    this.activeModal.close(result);
  }
  

  // onAddValue(){
  //   if(this.addEditCommentForm.valid){
  //     if (this.comments == null || this.comments == undefined) {
  //       this.comments = [];
  //     }
      
  //     if(this.index == null || this.index == undefined ){
  //       this.addeditComment.dateTime = new Date();
  //       this.comments.push(this.addeditComment);
  //       this.addeditComment = new AddEditComment();
  //       this.index = null;
  //     } else {
  //       this.comments[this.index] = this.addeditComment;
  //       this.index = null;
  //       this.addeditComment = new AddEditComment();
  //     }
  //     this.addEditCommentForm?.resetForm();
  //   } else {
  //     const hideSubmitBtn = document.getElementById('hide');
  //     hideSubmitBtn?.click();
  //   }
  //   const input = document.getElementById('value');
  //   input?.focus();
  // }
  

  // edit(item , index){
  //   this.addeditComment = item;
  //   this.index = index; 
  // }

  // deletecomment(item ,i){
  //   this.comments.splice(i ,1)
  //   this.addEditCommentForm?.resetForm();
  // }
}