import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EditDeleteIconService {
  public deleteIconClickedSource = new Subject<number>();
  deleteIconClicked$ = this.deleteIconClickedSource.asObservable();

  public editIconClickedSource = new Subject<any>();
  editIconClicked$ = this.editIconClickedSource.asObservable();

  public resetIconClickedSource = new Subject<any>();
  resetIconClicked$ = this.resetIconClickedSource.asObservable();

  private saveIconClickedSource = new Subject<number>();
  saveIconClicked$ = this.saveIconClickedSource.asObservable();

  triggerDeleteClick(params: any) {
    // const filterModel = [{
    //   deleted : {
    //     filter: 'equals',
    //     type: 'equals',
    //     value: true,
    //   },
    // }];
    // // params.api.refreshCells();
    // params.api.setFilterModel(filterModel);
    // params.api.onFilterChanged();
    // params.api.refreshCells();
    this.deleteIconClickedSource.next(params);
  }

  triggerSaveClick(data: any){
    this.saveIconClickedSource.next(data)
  }

  triggerEditClick(data: any){
    this.editIconClickedSource.next(data)
  }  

  triggerResetClick(data: any, tableid: any) {
    this.resetIconClickedSource.next({ data, tableid });
  }



  constructor() { }
}



