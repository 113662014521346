<button
#myButton
class="common_button d-flex align-items-center justify-content-center"
tabindex="-1"
[class]="buttonClass"
[type]="buttonType"
[ngClass]="ngClass"
[disabled]="disabled"
(click)="buttonOutputEvent.emit(); blurOnEnter()"
(keydown.enter)="blurOnEnter()"
[id]="buttonId"
>
<ng-container *ngIf="showIconCount">
  <span class="f-10 font-semibold text-primary position-absolute count-position">{{isFilterCount}}</span>
</ng-container>
<ng-container *ngIf="showIcon">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14.166"
      height="14.167"
      viewBox="0 0 14.166 14.167"
    >
      <g
        id="Group_2075"
        data-name="Group 2075"
        transform="translate(-2078.541 -6812.982)"
      >
        <path
          id="Path_1332"
          data-name="Path 1332"
          d="M2082.9,6813.232h5.446a.636.636,0,0,0,.072.026,2,2,0,0,1,1.662,1.962c0,.435,0,.87,0,1.305v.15h.4a2,2,0,0,1,1.921,1.533c.016.067.036.135.054.2v3.736c-.038.136-.068.273-.115.406a2,2,0,0,1-1.877,1.329h-.384c0,.617,0,1.213,0,1.809a1.192,1.192,0,0,1-1.211,1.207q-3.249,0-6.5,0a1.193,1.193,0,0,1-1.206-1.211c0-.552,0-1.1,0-1.654v-.15h-.416a2,2,0,0,1-1.959-1.958q0-1.649,0-3.3a1.991,1.991,0,0,1,1.676-1.923c.226-.032.458-.028.7-.041v-.144q0-.642,0-1.282a2,2,0,0,1,1.481-1.939Zm-1.738,8.514c-.091,0-.17,0-.25,0a.4.4,0,1,1,.056-.8h9.37a.4.4,0,0,1,0,.8c-.083,0-.166,0-.248,0v1.336h.3a1.211,1.211,0,0,0,1.264-1.27q0-1.534,0-3.07a1.509,1.509,0,0,0-.042-.356,1.2,1.2,0,0,0-1.223-.911h-9.637a1.191,1.191,0,0,0-1.159,1.167c-.014,1.09-.021,2.181,0,3.271a1.231,1.231,0,0,0,1.566,1.145Zm.8,0v.133q0,1.866,0,3.735a.422.422,0,0,0,.483.479h6.35a.423.423,0,0,0,.482-.483q0-1.86,0-3.721v-.144Zm7.315-5.081c0-.471,0-.929,0-1.387a1.208,1.208,0,0,0-1.255-1.251q-2.4,0-4.8,0a1.208,1.208,0,0,0-1.255,1.251c0,.422,0,.845,0,1.267,0,.039,0,.078.007.12Z"
          transform="translate(0 0)"
          fill="#fff"
          stroke="#fff"
          stroke-width="0.5"
        />
        <path
          id="Path_1333"
          data-name="Path 1333"
          d="M2128.533,6958.5c-.208,0-.417,0-.626,0a.4.4,0,1,1,0-.8q.626,0,1.252,0a.4.4,0,1,1,0,.8C2128.95,6958.5,2128.741,6958.5,2128.533,6958.5Z"
          transform="translate(-46.966 -139.311)"
          fill="#fff"
          stroke="#fff"
          stroke-width="0.5"
        />
        <path
          id="Path_1334"
          data-name="Path 1334"
          d="M2230.76,7078.272h-1.039a.4.4,0,1,1,0-.8h2.079a.4.4,0,1,1,0,.8Z"
          transform="translate(-145.136 -254.816)"
          fill="#fff"
          stroke="#fff"
          stroke-width="0.5"
        />
        <path
          id="Path_1335"
          data-name="Path 1335"
          d="M2230.76,7126.179h-1.039a.4.4,0,1,1,0-.8h2.079a.4.4,0,1,1,0,.8Z"
          transform="translate(-145.136 -301.015)"
          fill="#fff"
          stroke="#fff"
          stroke-width="0.5"
        />
      </g>
    </svg>
  </ng-container>
  <ng-container *ngIf="showFilterIcon">
    <svg
      class="cursorPointer"
      xmlns="http://www.w3.org/2000/svg"
      width="18.331"
      height="14.859"
      viewBox="0 0 22.331 22.859"
    >
      <path
        id="Union_17"
        data-name="Union 17"
        d="M-12251.149-9396.967a1.915,1.915,0,0,1-.133-.735c-.006-2.419-.012-5.16,0-7.84a2.082,2.082,0,0,0-.406-1.276c-2.015-2.827-4.055-5.7-6.03-8.485l-.953-1.342a1.436,1.436,0,0,1,.268-2.089,2.21,2.21,0,0,1,.279-.163.8.8,0,0,0,.087-.043l.113-.059h20.131l.092.037a1.549,1.549,0,0,1,.914.815,1.474,1.474,0,0,1-.215,1.5q-.983,1.38-1.963,2.763l-.8,1.133q-.535.751-1.064,1.5c-1.03,1.452-2.1,2.957-3.153,4.431a2.1,2.1,0,0,0-.409,1.3c0,1.554,0,3.129,0,4.651,0,.535,0,1.07,0,1.609a1.4,1.4,0,0,1-1.07,1.551l-1.4.532q-1.191.457-2.384.91a1.815,1.815,0,0,1-.638.123A1.338,1.338,0,0,1-12251.149-9396.967Zm-4-18.38c1.7,2.391,3.455,4.86,5.181,7.295a6.183,6.183,0,0,1,.434.717,3.707,3.707,0,0,1,.373,1.757v7.015l.418-.157c.735-.28,1.492-.572,2.243-.853,0-.861,0-1.731,0-2.578,0-1.107,0-2.255,0-3.381a4.119,4.119,0,0,1,.793-2.5c1.671-2.344,3.366-4.731,5-7.039.428-.6.855-1.2,1.28-1.8h-16.808Z"
        transform="translate(12259 9418.999)"
        fill="#4f39cd"
      />
    </svg>
  </ng-container>
  <ng-container *ngIf="showPlusIcon">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="13"
      viewBox="0 0 16.875 16.875"
    >
      <path
        id="Union_4"
        data-name="Union 4"
        d="M7.438-1a.938.938,0,0,1,.937.938V6.5h6.563a.937.937,0,1,1,0,1.875H8.375v6.563a.937.937,0,1,1-1.875,0V8.375H-.062A.938.938,0,0,1-1,7.438.938.938,0,0,1-.062,6.5H6.5V-.062A.938.938,0,0,1,7.438-1Z"
        transform="translate(1 1)"
        fill="#5843cf"
      />
    </svg>
  </ng-container>
  <ng-container *ngIf="showPlusIconwhite">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="10"
      viewBox="0 0 16.875 16.875"
    >
      <path
        id="Union_4"
        data-name="Union 4"
        d="M7.438-1a.938.938,0,0,1,.937.938V6.5h6.563a.937.937,0,1,1,0,1.875H8.375v6.563a.937.937,0,1,1-1.875,0V8.375H-.062A.938.938,0,0,1-1,7.438.938.938,0,0,1-.062,6.5H6.5V-.062A.938.938,0,0,1,7.438-1Z"
        transform="translate(1 1)"
        fill="#fff"
      />
    </svg>
  </ng-container>
  <ng-container *ngIf="showImportWhite">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13.798"
      height="14.484"
      viewBox="0 0 13.798 14.484"
    >
      <path
        id="Union_222"
        data-name="Union 222"
        d="M2855.64,19153.484c-.461,0-.534-.072-.534-.545v-3.7c0-.234.1-.393.346-.389s.341.168.341.4v3.5h8.643v-7.689h-3.73c-.582,0-.644-.059-.644-.625v-3.711h-4.269v4.537c0,.092.034.207-.01.27-.1.115-.221.273-.346.283-.1.01-.226-.164-.322-.273-.034-.043-.01-.135-.01-.207v-4.812c0-.441.082-.523.524-.523,1.548,0,3.091,0,4.634.01a.794.794,0,0,1,.5.2c1.389,1.359,2.759,2.74,4.144,4.109a.824.824,0,0,1,.26.635q-.007,3.987,0,7.971c0,.471-.087.563-.558.563Zm5.153-9.145h3.076l-3.076-3.1Zm-3.73,5.98a.739.739,0,0,1,.072-1.053c.409-.369.832-.721,1.322-1.148h-.375c-1.634,0-3.269,0-4.9,0a.748.748,0,0,1-.764-.986.758.758,0,0,1,.8-.508q2.437-.009,4.874-.006h.375c-.471-.4-.88-.75-1.279-1.105a.744.744,0,0,1,.88-1.2c1,.826,1.971,1.672,2.937,2.533a.706.706,0,0,1-.019,1.066c-.952.842-1.918,1.674-2.889,2.494a.705.705,0,0,1-.464.174A.757.757,0,0,1,2857.063,19150.32Z"
        transform="translate(-2851.869 -19139.5)"
        fill="#fff"
        stroke="rgba(0,0,0,0)"
        stroke-miterlimit="10"
        stroke-width="1"
      />
    </svg>
  </ng-container>
  <ng-container *ngIf="showUploadIcon">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14.826"
      height="13.847"
      viewBox="0 0 14.826 13.847"
    >
      <g
        id="Group_32"
        data-name="Group 32"
        transform="translate(-1783.48 -892.542)"
      >
        <path
          id="Union_33"
          data-name="Union 33"
          d="M-6769.729-2550.154a2.585,2.585,0,0,1-2.477-2.677v-2.884a.8.8,0,0,1,.782-.817.8.8,0,0,1,.78.817v2.884a.986.986,0,0,0,.915,1.042h9.873a.985.985,0,0,0,.915-1.042v-2.979a.8.8,0,0,1,.78-.818.8.8,0,0,1,.782.818v2.979a2.586,2.586,0,0,1-2.477,2.677Zm4.225-4.623v-6.064l-1.251,1.322a.752.752,0,0,1-.561.248.75.75,0,0,1-.562-.25.857.857,0,0,1,0-1.161l3.134-3.319.109.115,2.928,3.1a.836.836,0,0,1,.228.581.843.843,0,0,1-.226.581.773.773,0,0,1-.562.248.774.774,0,0,1-.564-.248l-1.089-1.154v6a.811.811,0,0,1-.794.826A.81.81,0,0,1-6765.5-2554.777Z"
          transform="translate(8555.686 3456.542)"
          fill="#fff"
        />
      </g>
    </svg>
  </ng-container>
  <ng-container *ngIf="showRefreshIcon">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14.78"
      height="14.78"
      viewBox="0 0 14.78 14.78"
    >
      <g id="Group_3433" data-name="Group 3433" transform="translate(0 0)">
        <path
          id="Path_51"
          data-name="Path 51"
          d="M-1998.906,6721.851c1.331.114,2.6.221,3.9.331-.56,1.232-1.1,2.429-1.671,3.678-.132-.4-.245-.752-.362-1.1-.087-.257-.151-.285-.377-.135a5.53,5.53,0,0,0-2.633,5.193,5.446,5.446,0,0,0,3.57,5.008c.24.1.3.187.219.437-.119.375-.206.763-.3,1.146-.038.158-.078.229-.265.158a7.136,7.136,0,0,1-4.8-5.7,7.42,7.42,0,0,1,3.364-7.761c.234-.151.243-.213.054-.437C-1998.423,6722.406-1998.644,6722.153-1998.906,6721.851Z"
          transform="translate(2001.759 -6721.851)"
          fill="#4f39cd"
        />
        <path
          id="Path_52"
          data-name="Path 52"
          d="M-1886.218,6733.342c.133.409.245.759.362,1.109.083.247.151.277.361.141a5.634,5.634,0,0,0,2.637-4.3,5.676,5.676,0,0,0-3.657-5.946c-.169-.059-.191-.127-.142-.294.115-.4.218-.8.3-1.2.048-.228.123-.284.346-.2a7.22,7.22,0,0,1,4.532,4.829,7.546,7.546,0,0,1-3.114,8.592c-.31.2-.315.235-.074.512.209.239.418.481.661.759-.655-.051-1.254-.1-1.854-.147s-1.206-.1-1.808-.155c-.1-.008-.249.024-.158-.175Z"
          transform="translate(1895.913 -6722.561)"
          fill="#4f39cd"
        />
      </g>
    </svg>
  </ng-container>
  <ng-container *ngIf="showUpload1Icon">
    <svg
      class="cursorPointer"
      xmlns="http://www.w3.org/2000/svg"
      width="14.526"
      height="13.479"
      viewBox="0 0 14.526 13.479"
    >
      <g
        id="Group_32"
        data-name="Group 32"
        transform="translate(-1783.63 -892.761)"
      >
        <path
          id="Union_33"
          data-name="Union 33"
          d="M-6769.729-2550.3a2.438,2.438,0,0,1-2.327-2.528v-2.884a.653.653,0,0,1,.632-.668.651.651,0,0,1,.631.668v2.884a1.134,1.134,0,0,0,1.064,1.192h9.872a1.136,1.136,0,0,0,1.064-1.192v-2.978a.652.652,0,0,1,.631-.668.653.653,0,0,1,.632.668v2.978a2.437,2.437,0,0,1-2.327,2.528Zm4.374-4.473v-6.442l-1.509,1.6a.611.611,0,0,1-.452.2.6.6,0,0,1-.452-.2.706.706,0,0,1,0-.957l3.025-3.2,2.929,3.1a.7.7,0,0,1,.186.478.692.692,0,0,1-.186.479.62.62,0,0,1-.453.2.621.621,0,0,1-.455-.2l-1.349-1.428v6.378a.661.661,0,0,1-.643.675A.661.661,0,0,1-6765.354-2554.776Z"
          transform="translate(8555.686 3456.542)"
          fill="#4f39cd"
        />
      </g>
    </svg>
  </ng-container>
  <ng-container *ngIf="showUploadIconWhite">
    <svg
      class="cursorPointer"
      xmlns="http://www.w3.org/2000/svg"
      width="14.526"
      height="13.479"
      viewBox="0 0 14.526 13.479"
    >
      <g
        id="Group_32"
        data-name="Group 32"
        transform="translate(-1783.63 -892.761)"
      >
        <path
          id="Union_33"
          data-name="Union 33"
          d="M-6769.729-2550.3a2.438,2.438,0,0,1-2.327-2.528v-2.884a.653.653,0,0,1,.632-.668.651.651,0,0,1,.631.668v2.884a1.134,1.134,0,0,0,1.064,1.192h9.872a1.136,1.136,0,0,0,1.064-1.192v-2.978a.652.652,0,0,1,.631-.668.653.653,0,0,1,.632.668v2.978a2.437,2.437,0,0,1-2.327,2.528Zm4.374-4.473v-6.442l-1.509,1.6a.611.611,0,0,1-.452.2.6.6,0,0,1-.452-.2.706.706,0,0,1,0-.957l3.025-3.2,2.929,3.1a.7.7,0,0,1,.186.478.692.692,0,0,1-.186.479.62.62,0,0,1-.453.2.621.621,0,0,1-.455-.2l-1.349-1.428v6.378a.661.661,0,0,1-.643.675A.661.661,0,0,1-6765.354-2554.776Z"
          transform="translate(8555.686 3456.542)"
          fill="#fff"
        />
      </g>
    </svg>
  </ng-container>

  <ng-container *ngIf="showBackIcon">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19.89"
      height="19.716"
      viewBox="0 0 19.89 19.716"
    >
      <g
        id="Group_4325"
        data-name="Group 4325"
        transform="translate(-1893.539 -4533.754)"
      >
        <path
          id="Path_2335"
          data-name="Path 2335"
          d="M1913.429,4542.958v1.31c-.03.233-.056.467-.09.7a9.661,9.661,0,0,1-2.37,5.126,9.814,9.814,0,0,1-5.983,3.262c-.279.043-.56.077-.84.114h-1.321c-.236-.029-.473-.055-.707-.089a9.8,9.8,0,0,1-6.427-3.664,9.776,9.776,0,0,1,1.625-13.822,9.734,9.734,0,0,1,7.525-2.047,9.926,9.926,0,0,1,8.475,8.3C1913.359,4542.415,1913.392,4542.687,1913.429,4542.958Zm-9.95-7.854a8.509,8.509,0,1,0,8.59,8.5A8.572,8.572,0,0,0,1903.479,4535.1Z"
          fill="#4f39cd"
        />
        <path
          id="Path_2336"
          data-name="Path 2336"
          d="M1936.533,4585.92h7.169a.69.69,0,0,1,.762.586.7.7,0,0,1-.5.817,1.525,1.525,0,0,1-.316.028q-3.419,0-6.839,0h-.273c.069.081.111.132.155.179q1.152,1.242,2.3,2.484a.738.738,0,0,1-.023,1.107.6.6,0,0,1-.782.013,1.721,1.721,0,0,1-.2-.187l-3.432-3.7a.782.782,0,0,1,0-1.231q1.735-1.869,3.471-3.74a.676.676,0,0,1,.65-.271.654.654,0,0,1,.512.477.723.723,0,0,1-.148.71c-.042.05-.087.1-.131.145l-2.212,2.383C1936.656,4585.775,1936.612,4585.829,1936.533,4585.92Z"
          transform="translate(-35.911 -43.023)"
          fill="#4f39cd"
        />
      </g>
    </svg>
  </ng-container>
  <ng-container *ngIf="importIcon">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15.41"
      height="13.516"
      viewBox="0 0 15.41 13.516"
    >
      <g
        id="Group_4844"
        data-name="Group 4844"
        transform="translate(4342.744 -7672.129)"
      >
        <path
          id="Path_2697"
          data-name="Path 2697"
          d="M-4293.305,7681.839l3.106-3.105a.679.679,0,0,0,.2-.48.679.679,0,0,0-.2-.48.678.678,0,0,0-.481-.2.677.677,0,0,0-.48.2l-1.435,1.435v-6.4a.679.679,0,0,0-.679-.679.68.68,0,0,0-.68.679v6.463l-1.6-1.6a.676.676,0,0,0-.48-.2.676.676,0,0,0-.48.2.68.68,0,0,0,0,.961Z"
          transform="translate(-41.681 0)"
          fill="#fff"
        />
        <path
          id="Path_2698"
          data-name="Path 2698"
          d="M-4328,7749.668a.672.672,0,0,0-.67.67v2.984a1.166,1.166,0,0,1-1.127,1.2h-10.477a1.166,1.166,0,0,1-1.127-1.2v-2.89a.67.67,0,0,0-.67-.669.67.67,0,0,0-.67.669v2.89a2.506,2.506,0,0,0,2.467,2.537h10.477a2.506,2.506,0,0,0,2.467-2.537v-2.984A.671.671,0,0,0-4328,7749.668Z"
          transform="translate(0 -70.214)"
          fill="#fff"
        />
      </g>
    </svg>
  </ng-container>
  <ng-container *ngIf="ResetBlueIcon">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="13"
      viewBox="0 0 14.779 14.779"
    >
      <g
        id="Group_3683"
        data-name="Group 3683"
        transform="translate(-1846.222 -316)"
      >
        <g
          id="Group_2919"
          data-name="Group 2919"
          transform="translate(1846.222 316)"
        >
          <path
            id="Path_51"
            data-name="Path 51"
            d="M-1997.863,6721.851c-1.331.114-2.6.221-3.9.331.56,1.232,1.1,2.429,1.671,3.678.132-.4.245-.752.362-1.1.087-.257.151-.285.377-.135a5.53,5.53,0,0,1,2.633,5.193,5.446,5.446,0,0,1-3.57,5.008c-.24.1-.3.187-.219.437.119.375.206.763.3,1.146.038.158.078.229.265.158a7.136,7.136,0,0,0,4.8-5.7,7.42,7.42,0,0,0-3.364-7.761c-.234-.151-.243-.213-.054-.437C-1998.346,6722.406-1998.125,6722.153-1997.863,6721.851Z"
            transform="translate(2009.79 -6721.851)"
            fill="#FFFFFF"
          />
          <path
            id="Path_52"
            data-name="Path 52"
            d="M-1882.766,6733.342c-.133.409-.245.759-.362,1.109-.083.247-.151.277-.361.141a5.634,5.634,0,0,1-2.637-4.3,5.676,5.676,0,0,1,3.657-5.946c.169-.059.191-.127.142-.294-.115-.4-.218-.8-.3-1.2-.048-.228-.123-.284-.346-.2a7.22,7.22,0,0,0-4.532,4.829,7.546,7.546,0,0,0,3.114,8.592c.31.2.315.235.074.512-.209.239-.418.481-.661.759.655-.051,1.254-.1,1.854-.147s1.206-.1,1.808-.155c.1-.008.249.024.158-.175Z"
            transform="translate(1887.851 -6722.561)"
            fill="#FFFFFF"
          />
        </g>
      </g>
    </svg>
  </ng-container>

  <ng-container *ngIf="showSearchIcon">
    <span
      class="d-flex align-items-center searchInputIcon cursorPointer p-1 pe-2 ps-2"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="21.063"
        height="21.859"
        viewBox="0 0 21.063 21.859"
      >
        <path
          id="Union_12"
          data-name="Union 12"
          d="M-12239.71-9397.424l-5.008-4.658a9.128,9.128,0,0,1-5.093,1.545,9.212,9.212,0,0,1-9.19-9.23,9.212,9.212,0,0,1,9.19-9.232,9.212,9.212,0,0,1,9.192,9.232,9.218,9.218,0,0,1-2.481,6.308l4.826,4.491a1.057,1.057,0,0,1,.052,1.492,1.043,1.043,0,0,1-.771.336A1.037,1.037,0,0,1-12239.71-9397.424Zm-17.291-12.344a7.22,7.22,0,0,0,7.19,7.23,7.219,7.219,0,0,0,7.192-7.23,7.221,7.221,0,0,0-7.192-7.232A7.221,7.221,0,0,0-12257-9409.768Z"
          transform="translate(12259.001 9419)"
          fill="#4f39cd"
        />
      </svg>
    </span>
  </ng-container>
  <ng-container *ngIf="ResetdarkBlueIcon">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="13"
      viewBox="0 0 14.779 14.779"
    >
      <g
        id="Group_3683"
        data-name="Group 3683"
        transform="translate(-1846.222 -316)"
      >
        <g
          id="Group_2919"
          data-name="Group 2919"
          transform="translate(1846.222 316)"
        >
          <path
            id="Path_51"
            data-name="Path 51"
            d="M-1997.863,6721.851c-1.331.114-2.6.221-3.9.331.56,1.232,1.1,2.429,1.671,3.678.132-.4.245-.752.362-1.1.087-.257.151-.285.377-.135a5.53,5.53,0,0,1,2.633,5.193,5.446,5.446,0,0,1-3.57,5.008c-.24.1-.3.187-.219.437.119.375.206.763.3,1.146.038.158.078.229.265.158a7.136,7.136,0,0,0,4.8-5.7,7.42,7.42,0,0,0-3.364-7.761c-.234-.151-.243-.213-.054-.437C-1998.346,6722.406-1998.125,6722.153-1997.863,6721.851Z"
            transform="translate(2009.79 -6721.851)"
            fill="#4f39cd"
          />
          <path
            id="Path_52"
            data-name="Path 52"
            d="M-1882.766,6733.342c-.133.409-.245.759-.362,1.109-.083.247-.151.277-.361.141a5.634,5.634,0,0,1-2.637-4.3,5.676,5.676,0,0,1,3.657-5.946c.169-.059.191-.127.142-.294-.115-.4-.218-.8-.3-1.2-.048-.228-.123-.284-.346-.2a7.22,7.22,0,0,0-4.532,4.829,7.546,7.546,0,0,0,3.114,8.592c.31.2.315.235.074.512-.209.239-.418.481-.661.759.655-.051,1.254-.1,1.854-.147s1.206-.1,1.808-.155c.1-.008.249.024.158-.175Z"
            transform="translate(1887.851 -6722.561)"
            fill="#4f39cd"
          />
        </g>
      </g>
    </svg>
  </ng-container>
  <ng-container *ngIf="copywhiteIcon">
    <svg
      id="Group_41288"
      data-name="Group 41288"
      xmlns="http://www.w3.org/2000/svg"
      width="15.006"
      height="15.012"
      viewBox="0 0 24.006 24.012"
    >
      <path
        id="Path_3255"
        data-name="Path 3255"
        d="M-494.176,581.127c0-2.092,0-4.184,0-6.276a2.812,2.812,0,0,1,2.234-2.841,3.286,3.286,0,0,1,.695-.067q6.263-.006,12.527,0a2.824,2.824,0,0,1,2.929,2.934q0,6.263,0,12.527a2.826,2.826,0,0,1-2.916,2.922q-6.276,0-12.552,0a2.823,2.823,0,0,1-2.915-2.9C-494.182,585.328-494.176,583.228-494.176,581.127Zm1.884.007q0,3.112,0,6.224a.947.947,0,0,0,1.086,1.085h12.448a.948.948,0,0,0,1.085-1.087q0-6.224,0-12.448a.947.947,0,0,0-1.086-1.085h-12.448a.946.946,0,0,0-1.084,1.086Q-492.293,578.022-492.293,581.134Z"
        transform="translate(499.795 -566.314)"
        fill="#fff"
      />
      <path
        id="Path_3256"
        data-name="Path 3256"
        d="M-625.3,425.922h-1.843c0-.246,0-.492,0-.737a.968.968,0,0,0-1.075-1.085H-640.7a.965.965,0,0,0-1.085,1.074q0,6.237,0,12.475a.968.968,0,0,0,1.076,1.085h.79v1.829c-1.842.287-3.362-.537-3.7-2.345a2.448,2.448,0,0,1-.031-.448q0-6.35,0-12.7a2.778,2.778,0,0,1,2.384-2.806,3.271,3.271,0,0,1,.548-.032c4.167,0,8.333.014,12.5-.009a2.84,2.84,0,0,1,2.9,2.424A10.116,10.116,0,0,1-625.3,425.922Z"
        transform="translate(643.65 -422.223)"
        fill="#fff"
      />
    </svg>
  </ng-container>

  <span>{{ buttonLabel }}</span>

  <ng-container *ngIf="backupDownloadIcon">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
    >
      <g
        id="Group_41544"
        data-name="Group 41544"
        transform="translate(-773.794 -477.794)"
      >
        <g
          id="Rectangle_21096"
          data-name="Rectangle 21096"
          transform="translate(773.794 477.794)"
          fill="none"
          stroke="#fff"
          stroke-width="1"
        >
          <rect width="18" height="18" rx="5" stroke="none" />
          <rect x="0.5" y="0.5" width="17" height="17" rx="4.5" fill="none" />
        </g>
        <g
          id="Group_32"
          data-name="Group 32"
          transform="translate(778.396 480.839)"
        >
          <path
            id="Union_33"
            data-name="Union 33"
            d="M0,11.029V9.854H8.684v1.175ZM1.724,5.7a.62.62,0,0,1,0-.842.54.54,0,0,1,.8,0L3.848,6.261V.6A.564.564,0,1,1,4.974.6V6.206L6.163,4.948a.542.542,0,0,1,.8,0,.619.619,0,0,1,0,.842L4.385,8.514Z"
            fill="#fff"
          />
        </g>
      </g>
    </svg>
  </ng-container>
</button>
