import { Injectable } from '@angular/core';
import ZebraBrowserPrintWrapper from 'zebra-browser-print-wrapper';

@Injectable({
  providedIn: 'root'
})
export class PrinterService {

  constructor() { }

  async initializePrinter() {
    // Create a new instance of the object
    const browserPrint = new ZebraBrowserPrintWrapper();

    try {
      // Select default printer
      const defaultPrinter = await browserPrint.getDefaultPrinter();

      // Set the printer
      browserPrint.setPrinter(defaultPrinter);

      // Check printer status
      const printerStatus = await browserPrint.checkPrinterStatus();

      // Check if the printer is ready
      if (printerStatus.isReadyToPrint) {
        return browserPrint;
      } else {
        console.error("Error/s", printerStatus.errors);
        return null;
      }
    } catch (error) {
      console.error('An error occurred:', error);
      return null;
    }
  }
  async printData(zebraZPLPrintData: string) {
    const browserPrint = await this.initializePrinter();
    if (browserPrint) {
      browserPrint.print(zebraZPLPrintData);
    } else {
      console.error('Printer is not ready.');
    }
  }
}
