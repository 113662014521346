// date.service.ts

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DateService {
  private selectedDateSubject = new BehaviorSubject<string>("");

  setSelectedDate(date: string): void {
    this.selectedDateSubject.next(date);
  }

  getSelectedDate(): Observable<string> {
    return this.selectedDateSubject.asObservable();
  }
}
