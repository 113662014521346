<div class="container-fluid d-flex flex-column h-100 px-0 ">
    <div class="modal-header d-flex align-items-center justify-content-center position-relative" *ngIf="hide_Header">
        <!-- <div><span class="font-semibold text-primary">{{storageName?.FORMNAME_ORDERS_ORDERTRACKING}}</span></div> -->
        <div><span class="font-semibold text-primary"> {{ data?.barcodeNo ? data?.barcodeNo + ' - Order Tracking' : 'Order Tracking' }}</span></div>
        <app-svg-icon [name]="'cross_button_border'" class="cross-button-border"></app-svg-icon>
        <app-svg-icon [name]="'cross'" [ngClass]="currentHoverEffect" class="cross-button" (click)="activeModal.close()"></app-svg-icon>
      </div>
    
    <div class="row flex-fill">
      <div class="overflow-auto flex-fill h-100 pb-1">
        <div class="p-0">
          <div class="row m-0 p-0 gap-2 h-100">
            <div class="order_tracking_contain p-0">
              <div class="d-flex flex-column m-0 overflow-y-auto overflow-x-hidden h-100">
                <div class="row overflow-auto">
                  <div class="col-12  py-2 border-right-grey">                  
                     <div class="sidebarPanel d-flex">
                        <div class="box1 d-flex flex-column border-card ms-3 px-2">
                            <div class="flex-fill ">
                                <h5 class="primary-color-1  my-2 pb-1 border-bottom br-light-blue-color font-semibold f-16 mb-0 ">
                                    Order Details
                                </h5>
                                <div class="row">
                                    <div class="col-12 ">
                                        <div class="row mt-1">
                                            <div class="col-6">
                                                <div class="text-gray-color font-regular f-12">Order No:</div>
                                            </div>
                                            <div class="col-6">
                                                <div class="text-gray-color font-semibold f-12">{{orderDetail?.orderDetails?.orderNo?orderDetail?.orderDetails?.orderNo:'-'}}</div>
                                            </div>
                                        </div>

                                        <div class="row mt-2">
                                            <div class="col-6">
                                                <div class="text-gray-color font-regular f-12">Customer Name:</div> 
                                            </div>
                                            <div class="col-6">
                                                <div class="text-gray-color font-semibold f-12">{{orderDetail?.orderDetails?.customerName?orderDetail?.orderDetails?.customerName:'-'}}</div>
                                            </div>
                                        </div>

                                        <!-- <div class="row mt-2">
                                            <div class="col-6">
                                                <div class="text-gray-color font-regular f-12">Service Type:</div>                                            </div>
                                            <div class="col-6">
                                                <div class="text-gray-color font-semibold f-12">Service Type</div>
                                            </div>
                                        </div> -->
                                        <div class="row mt-2">
                                            <div class="col-6">
                                                <div class="text-gray-color font-regular f-12">Order Date:</div>                                            </div>
                                            <div class="col-6">
                                                <div class="text-gray-color font-semibold f-12">{{orderDetail?.orderDetails?.orderDate?(orderDetail?.orderDetails?.orderDate| date:'dd/MM/yyyy'):'-'}}</div>
                                            </div>
                                        </div>


                                        <div class="row mt-2 text-nowrap">
                                            <div class="col-6">
                                                <div class="text-gray-color font-regular f-12">Delivery Date:</div>       
                                           </div>
                                            <div class="col-6">
                                                <div class="text-gray-color font-semibold f-12 text-nowrap">{{orderDetail?.orderDetails?.deliveryDate?(orderDetail?.orderDetails?.deliveryDate | date:'dd/MM/yyyy'):'-'}}</div>
                                            </div>
                                        </div> 
                                           
                                        <!-- <div class="row mt-2">
                                            <div class="col-6">
                                                <div class="text-gray-color font-regular f-12">Estimate Time:</div>                                            </div>
                                            <div class="col-6">
                                                <div class="text-gray-color font-semibold f-12">12:10:00</div>
                                            </div>
                                        </div>

                                        <div class="row mt-2">
                                            <div class="col-6">
                                                <div class="text-gray-color font-regular f-12">Advance Amount:</div>                                            </div>
                                            <div class="col-6">
                                                <div class="text-gray-color font-semibold f-12">200000.00</div>
                                            </div>
                                        </div> -->
                                            
                                        <div class="row mt-2">
                                            <div class="col-6">
                                                <div class="text-gray-color font-regular f-12">Status:</div> 
                                            </div>
                                            <div class="col-6">
                                                <div class="text-gray-color font-semibold f-12">{{orderDetail?.orderDetails?.statusName?orderDetail?.orderDetails?.statusName:'-'}}</div>
                                            </div>
                                        </div>  
                                            
                                            
                                        
                                    </div>
                                </div>
                                <h5 class="primary-color-1  my-2 pb-1 border-bottom br-light-blue-color font-semibold f-16 mb-0 ">
                                  Job Order Details
                                </h5>

                                <div class="row">
                                    <div class="col-12 ">
                                        <div class="row mt-1">
                                            <div class="col-6">
                                                <div class="text-gray-color font-regular f-12">Job Order No:</div>  
                                            </div>
                                            <div class="col-6">
                                                <div class="text-gray-color font-semibold f-12">{{orderDetail?.jobOrderDetails?.orderNo?orderDetail?.jobOrderDetails?.orderNo:'-'}}</div>
                                            </div>
                                        </div>

                                        <div class="row mt-2">
                                            <div class="col-6">
                                                <div class="text-gray-color font-regular f-12">Customer Name:</div>        
                                        </div>
                                            <div class="col-6">
                                                <div class="text-gray-color font-semibold f-12">{{orderDetail?.jobOrderDetails?.customerName?orderDetail?.jobOrderDetails?.customerName:'-'}}</div>
                                            </div>
                                        </div>

                                        <div class="row mt-2">
                                            <div class="col-6">
                                                <div class="text-gray-color font-regular f-12">Assign To:</div>            
                                            </div>
                                            <div class="col-6">
                                                <div class="text-gray-color font-semibold f-12">{{orderDetail?.jobOrderDetails?.assignTo?orderDetail?.jobOrderDetails?.assignTo:'-'}}</div>
                                            </div>
                                        </div>
                                        <div class="row mt-2">
                                            <div class="col-6">
                                                <div class="text-gray-color font-regular f-12">Order Date:</div>   
                                            </div>
                                            <div class="col-6">
                                                <div class="text-gray-color font-semibold f-12">{{orderDetail?.jobOrderDetails?.orderDate?(orderDetail?.jobOrderDetails?.orderDate | date:'dd/MM/yyyy'):'-'}}</div>
                                            </div>
                                        </div>


                                        <div class="row mt-2 text-nowrap">
                                            <div class="col-6">
                                                <div class="text-gray-color font-regular f-12">Delivery Date:</div>
                                             </div>
                                            <div class="col-6">
                                                <div class="text-gray-color font-semibold f-12">{{orderDetail?.jobOrderDetails?.deliveryDate?(orderDetail?.jobOrderDetails?.deliveryDate | date:'dd/MM/yyyy'):'-'}}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div class="row">
                                    <div class="Order_Tracking">
                                        <div class="d-flex justify-content-between align-items-center">
                                          <h5 class="text-primary font-semibold f-16 mb-0 mt-3">
                                              Images
                                          </h5>
                                        </div>
                                      
  
                                    <div *ngIf="orderDetail?.imageUrls?.length" class="order-tracking position-relative">
                                          <div class="py-2 height-custom flex-fill d-flex img_preview_display">
                                                <div class="img_preview bg-light">
                                                    <img *ngIf="previewImg" class="img_preview border-dark-gray" [src]="isBase64Image(previewImg)" alt="">
                                                </div>
  
                                                <div class="row smallBox ps-2">
                                                    <div class="col-12  firstBox gap-2 me-2 d-flex flex-column">
                                                        <div *ngFor="let item of orderDetail?.imageUrls" class="px-1 small_img ">
                                                            <img class="small_img cursorPointer border-dark-gray" (click)="previewImg = item?.imageUrl" [src]="isBase64Image(item?.imageUrl)" alt="">
                                                        </div>
                                                    </div>
                                                </div>
                                          </div>
                                    </div>
                                    <div *ngIf="!(orderDetail?.imageUrls?.length) " class="d-flex align-items-center justify-content-center">
                                       <span class="text-gray-color font-regular f-14 mt-5"> No Images To Display !</span>
                                    </div>
                                  </div>
                                </div>
                            </div>  
                                                            
                        </div>
                        

                        <div class="box2 tabel-bg-color d-flex align-items-center justify-content-center ms-2 me-3 px-0">
                            <div class="timeline">
                                <!-- <div class="timeline-bar"></div> -->
                                <!-- <div class="vertical-progressbar">
                                    <ngb-progressbar [value]="getProgressBarValue()" class="custom-progressbar" type="info"  height="5px"></ngb-progressbar>
                                </div> -->
                                    
                                <div class="timeline-content position-relative">
                                    <div class="progress_line_fill_container position-absolute start-50" [style.height.px]="processLine">
                                        <div class="progress_line"></div>
                                    </div>
                                    <div class="stage-item" *ngFor="let item of orderDetail?.orderFlow">
                                        <div class="card card-timeline timeline-heading shadow">
                                            <span class="font-semibold text-start f-14 " [ngClass]="progressBarValue>=30 ? 'primary-color-1' : 'text-gray-color'">{{item?.name}}</span>
                                        </div>
                                        <div class="timeline-action"  >
                                            <app-timeline-status-icon [timelineStatusId]="item?.status"></app-timeline-status-icon>
                                        </div>
                                        <div class="card card-timeline timeline-details" [ngClass]="((item?.departmentId == null) && (item?.status == jobWorkStatus.Completed)) ? 'd-none' : 'd-block'">
                                            <div class="start-date text-nowrap">
                                                <span class="title text-gray-color font-regular f-12">Order Date : </span>    
                                                <span class="value primary-color-1 font-regular f-12">{{item?.startTime ? ( item?.startTime | date:'dd/MM/yyyy') : 'NA' }}</span>
                                            </div>
                                            <div class="completion-date text-nowrap">
                                                <span class="title text-gray-color font-regular text-nowrap f-12">Completed Date : </span>    
                                                <span class="value primary-color-1 font-regular f-12">{{item?.endTime ? (item?.endTime  | date:'dd/MM/yyyy') :'NA'}}</span>
                                            </div>
                                            <div class="completion-date text-nowrap" *ngIf="item?.endTime">
                                                <span class="title text-gray-color font-regular text-nowrap f-12">Total Weight : </span>    
                                                <span class="value primary-color-1 font-regular f-12">{{item?.totalWt || 'NA'}}</span>
                                            </div>
                                            <div class="completion-date text-nowrap" *ngIf="item?.endTime">
                                                <span class="title text-gray-color font-regular text-nowrap f-12">Total Quantity : </span>    
                                                <span class="value primary-color-1 font-regular f-12">{{item?.totalQty || 'NA' }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> 
                        </div>
                    </div>               
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>