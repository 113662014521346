import { EventEmitter, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';
import { PermissionVM, ShowHeaderPageNameAndInvoieNum } from '../models/common.model';

@Injectable({
  providedIn: 'root'
})
export class SendDataService {

  permissionVM = new PermissionVM();
  
  public headerPageNameAndInvoiceNum = new BehaviorSubject<any>(undefined);
  public redirectToBranches = new Subject<any>();

  public showEdiComponent = new Subject<boolean>();
  showEdiComponentClicked$ = this.showEdiComponent.asObservable();

  public showBranchComponent =  new Subject<boolean>();
  showBranchComponentClicked$ = this.showBranchComponent.asObservable();

  public userDetailsData = new BehaviorSubject<any>(undefined);
  ShowUserDetails = this.userDetailsData.asObservable();

  public viewHistoryNavigate = new BehaviorSubject<any>(undefined);
  viewHistoryNavigateClicked$ = this.viewHistoryNavigate.asObservable();

  public resetColumnFilterSubject = new Subject<void>();
  resetColumnFilter$ = this.resetColumnFilterSubject.asObservable();

  public partiesheaderFilterSubject = new Subject<void>();
  partiesFilter$ = this.partiesheaderFilterSubject.asObservable();

  public updateCart = new Subject<boolean>();
  
  onSaveBeforeClose = new EventEmitter();
  onSaveAsDraftBeforeClose = new EventEmitter();
  private redirectUrlAfterSave: BehaviorSubject<string>;
 noPermissionTooltip = "You don't have permission to perform this action";

  constructor(private router: Router) { 
    this.redirectUrlAfterSave = new BehaviorSubject<string>(null);
  }

  sendComponetValue(istrue: boolean) {
    this.showEdiComponent.next(istrue);
  }

  sendToBranch(){
    this.showBranchComponent.next(true);
  }

  getUserDetailsData(userDetails){
    this.userDetailsData.next(userDetails);
  }

  sendviewHistoryDetailsValue(data) {
    this.viewHistoryNavigate.next(data);
  }

  clearViewHistoryData() {
    this.viewHistoryNavigate.next(null); 
  }
  saveBeforeClose() {
    this.onSaveBeforeClose.emit();
  }

  saveAsDraftBeforeClose() {
    this.onSaveAsDraftBeforeClose.emit();
  }
  emitpartiesFilterIconClick(data){
    this.partiesheaderFilterSubject.next(data)
  }

  triggerResetColumnFilter() {
    this.resetColumnFilterSubject.next();
  }
  
  public setredirectUrlAfterSave(value: string) {
    this.redirectUrlAfterSave.next(value);
  }

  checkRedirectUrlAfterSave() {
    // redirect after save on save button click of confirmation popup
    const redirectUrl = this.redirectUrlAfterSave.value;
    if (redirectUrl) {
      this.router.navigate([redirectUrl], { state: { confirmationSaveClicked: true } });
      this.setredirectUrlAfterSave(null);
    }
  }


  displayPageName(formName:string , invoiceNum?){
    const showHeaderPageNameAndInvoieNum = new ShowHeaderPageNameAndInvoieNum();
    showHeaderPageNameAndInvoieNum.pageName      = formName;
    this.headerPageNameAndInvoiceNum.next(showHeaderPageNameAndInvoieNum);
  }

}
