import { Component, OnInit, OnDestroy } from '@angular/core';
import { StorageService } from '../../services/storage.service';
import { LayoutService } from '../../services/layout.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {

  public show: boolean = true;

  constructor(public storageService :  StorageService ,public layoutService  : LayoutService) {
    setTimeout(() => {
      this.show = false;
    }, 3000);
  }

  ngOnInit() { }

  ngOnDestroy() { }

}
