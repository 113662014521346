import { Component, Renderer2 } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { AnyAaaaRecord } from 'dns';
import { OpenImagePopupComponent } from 'src/app/shared/common-components/common-voucher/popup/customer-details/popup/open-image-popup/open-image-popup.component';
import { ImgPopUpComponent } from 'src/app/components/inventory/jewelry-catalogue/popUp/img-pop-up/img-pop-up.component';
import { commonModalOptions } from 'src/app/shared/models/common.model';
import { CommonService } from 'src/app/shared/services/common.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-jwellary-image',
  templateUrl: './jwellary-image.component.html',
  styleUrls: ['./jwellary-image.component.scss']
})
export class JwellaryImageComponent extends CommonService implements ICellRendererAngularComp{
  params: any;
  displayImage:any;
  isDisplayImageByUrl:any
  public baseUrl = environment.baseUrl;
  fromDetailJson : boolean = false;
  fromImageUrl: boolean = false;
  forDirectImgValue: boolean = false;
  imagesListData:any= [];
  constructor( private modalService  : NgbModal , public renderer : Renderer2){
    super(renderer);
  }

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.fromDetailJson = this.params.fromDetailJson??false;
    this.fromImageUrl = this.params.fromImageUrl??false;
    this.forDirectImgValue = this.params?.forDirectImgValue??false;
    this.imageShow();

  }

  imageShow(){
    this.displayImage = null;
    if(this.fromDetailJson){
      const parseDataJson = JSON?.parse(this.params?.data?.detailedJson??'[]')??[];
      this.imagesListData = parseDataJson?.invoiceItemImageResources??this.params?.data?.invoiceItemImageResources??[];
      if(this.imagesListData.length){
        this.displayImage = this.imagesListData[0]?.base64Resource;
      }
    }else if(this.fromImageUrl){
      this.imagesListData = this.params?.data?.imageUrls??[]
      if(this.params?.data?.imageUrls){
        this.displayImage = this.params?.data?.imageUrls[0]?.imageUrl;
      }
    } else if(this.forDirectImgValue){
      this.displayImage = this.params?.value;
    }
  }
 

  refresh(): boolean {
    this.imageShow();
    return true;
  }

  onIconClick() {
    if((this.imagesListData?.length && this.displayImage )|| (this.displayImage && this.forDirectImgValue)){
      const modalOption: NgbModalOptions = {
          ...commonModalOptions,
          size: "sm",
          fullscreen:true,
          centered:true,
          // modalDialogClass: 'image_popup'
          modalDialogClass: 'image_popup_fullscreen'
      };
      let imagesList = [];
      if(this.fromDetailJson){ 
        const parseDetailedData = this.params?.data?.detailedJson ? JSON?.parse(this.params?.data?.detailedJson??'[]')??[] : this.params?.data?.invoiceItemImageResources ? this.params?.data : [];
        if(parseDetailedData?.invoiceItemImageResources?.length){
          const list = parseDetailedData.invoiceItemImageResources;
          imagesList = list
        }
      }
      else if(this.fromImageUrl){
        const imageList = this.params?.data?.imageUrls??[];
        imagesList = imageList
      }
      else if(this.forDirectImgValue){
          imagesList = [{imageUrl:this.params?.value}]
      }

      const modalRef = this.modalService.open(OpenImagePopupComponent, modalOption);
      modalRef.componentInstance.jewelryCatalogue = [...imagesList];
    }
  }


  
}

