import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-description-cell-render',
  templateUrl: './description-cell-render.component.html',
  styleUrls: ['./description-cell-render.component.scss']
})
export class DescriptionCellRenderComponent implements OnInit, ICellRendererAngularComp{

  registerRowDragger: (rowDraggerElement: HTMLElement, dragStartPixels?: number, value?: string, suppressVisibilityChange?: boolean) => void;

  params
  splitDescriptions =[]
  agInit(params: any): void 
  {
    this.params = params;
    const detailDescriptionsString = params.data.detailDescriptions;
     try {
        this.splitDescriptions = JSON.parse(detailDescriptionsString);
    } catch (error) {
        console.error('Invalid JSON string:', detailDescriptionsString, error);
    }
  }
  refresh(params: ICellRendererParams<any, any>): boolean {
    return true;
  }

  ngOnInit(): void
  {
  }


}
