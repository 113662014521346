<div class="mt-1">
 <form autocomplete="off"  class="theme-form" ngNativeValidate #addEditCommentForm="ngForm">
    <div class="col-12 d-flex gap-3 input-wrapper">
        <app-input 
            [ngClass]="'border-radius ' + (isLargeFont ? 'large-font-size' : '')"
            [inputName]="'value'" 
            [inputType]="'text'" 
            [inputId]="'value'"
            [inputPlaceholder]="'Enter Comment'"
            [inputValue]="addeditComment?.comment" 
            (inputValueEmit)="addeditComment.comment = $event"
            [isRequired]="required"
            (keydown.enter)="onClick()"
            >
        </app-input>
        <div class="d-flex justify-content-end align-items-center svg-button" >
          <button class="p-0 border-0 d-flex m-0 add_btn" type="button" (click)="onClick()">
              <svg  class="cursorPointer" xmlns="http://www.w3.org/2000/svg" 
              [attr.width]="isLargeFont ? 30 : 22" 
              [attr.height]="isLargeFont ? 30 : 22"
               viewBox="0 0 22 22">
                <g id="Group_3496" data-name="Group 3496" transform="translate(-0.023)">
                  <circle id="Ellipse_59" data-name="Ellipse 59" cx="11" cy="11" r="11" transform="translate(0.023)" fill="#e8ecf3"/>
                  <path id="Union_4" data-name="Union 4" d="M5.187-1a.687.687,0,0,1,.687.687V4.5h4.812a.687.687,0,1,1,0,1.375H5.875v4.812a.687.687,0,1,1-1.375,0V5.875H-.313A.687.687,0,0,1-1,5.187.687.687,0,0,1-.313,4.5H4.5V-.313A.687.687,0,0,1,5.187-1Z" transform="translate(6.023 6)" fill="#a3abb8"/>
                </g>
            </svg>
          </button>
          <button class="d-none" id="hide" type="submit"></button>
        </div>
    </div>
    <div *ngIf="comments?.length !== 0 || cardHeight" class="col-12">
        <div class="flex-fill overflow-auto mt-1" 
          [ngClass]="{
            'scrollnew': cardHeight, 
            'scroll': !cardHeight && comments.length > 2
         }">
            <div
            [ngClass]="{ 'isLarge': isLargeFont }" 
             class="px-2 py-1 wrapper f-14  font-regular text-gray-color d-flex field_value_display  overflow-hidden" *ngFor="let item of comments, let i=index">
             <span class="flex-grow-1 w-100 text-break {{isLargeFont?'mt-4px':'mt-2px'}}">{{item?.comment}}</span>
             <span class="text-nowrap ps-2 pe-3 {{isLargeFont?'mt-4px':'mt-2px'}}"> {{ item?.dateTime | date: 'dd/MM/yyyy - hh:mm a' }}</span>
              <div class="icon_edit_delete d-flex gap-2 pe-0">
                <!-- <app-svg-icon name="edit" (click)="edit(item ,i)" ></app-svg-icon>
                <app-svg-icon name="delete_red" (click)="deletecomment(item ,i)"></app-svg-icon> -->
                <img  class="cursorPointer"
                [attr.width]="isLargeFont ? 25 : 14" 
                [attr.height]="isLargeFont ? 34 : 18" 
                src="../../../../assets/svg/edit.svg" (click)="edit(item ,i)"  alt="">
                <img class="cursorPointer"
                [attr.width]="isLargeFont ? 21 : 12" 
                [attr.height]="isLargeFont ? 34 : 18" 
                src="../../../../assets/svg/delete_red.svg" (click)="deletecomment(item ,i)"  alt="">

            </div>
            </div>
          </div>

    </div>
 </form>
</div>
