<label *ngIf="datePickerLabel != undefined" [for]="datepicker" class="form-label d-flex align-items-center">{{datePickerLabel}}<span *ngIf="isRequired" class="required-color">*</span></label>
<div class="date-input range-datepicker d-flex p-0">
	<div class="input-group d-flex"> 
        <div class=" bg-new h-100 border-right d-flex align-items-center">
            <ng-container *ngIf="showdayIcon">
                <app-svg-icon class="cursorPointer " name="day" (click)="datepicker.toggle()"></app-svg-icon>
            </ng-container>
            <ng-container *ngIf="showdateIcon">
                <app-svg-icon class="cursorPointer " name="date" (click)="datepicker.toggle()"></app-svg-icon>
            </ng-container>
        </div>
        <input [required]="isRequired" [(ngModel)]="inputDate" type="date" [name]="datepickername" class="d-none">
        
        <input
            (keydown)="$event.preventDefault();" 
            [required]="isRequired"
            [name]="datepickername" 
            [id]="inputId"
            class="input-calendar" 
            [placeholder]="inputPlaceholder" 
            ngbDatepicker
            [value]="inputDate"
            #datepicker="ngbDatepicker" 
            [autoClose]="'outside'"
            (dateSelect)="onDateSelection($event)" 
            [dayTemplate]="dayTemplate"
            [footerTemplate]="footerTemplate" 
            outsideDays="hidden" 
            [startDate]="fromDate!" 
            tabindex="-1"
            autocomplete="off"
            (closed)="onDatepickerClosed()"
            [minDate]="minDate" 
            [maxDate]="maxDate"
            />
            
        
        <ng-template #dayTemplate let-date let-focused="focused" (mouseleave)="leave()">
            <span 
                class="custom-day" 
                [class.focused]="focused" 
                [class.range]="isRange(date)"
                [class.start-date]="isStartDate(date)" 
                [class.end-date]="isEndDate(date)"
                [class.faded]="isHovered(date) || isInside(date)" 
                (mouseenter)="hoveredDate = date ;mouseEnter()"
                (mouseleave)="hoveredDate = null">
                {{ date.day }}
            </span>
        </ng-template>
        <!-- Templates -->
        <ng-template #footerTemplate>
            <hr class="my-0">
            <div class="action-buttons">
                <button class="btn btn-sm btn-gray text-nowrap cursorPointer " (click)="onClearPicker()">Clear</button>
                <button class="btn btn-sm btn-blue text-nowrap cursorPointer " (click)="onApply()" [disabled]="!inputDate">Apply</button> 
            </div>
        </ng-template>
<!-- end Templates -->
    </div>
</div>







