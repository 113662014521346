<app-load-dropdown-data [formName]="storageName.FORMNAME_BUSINESSREGISTRATION" (fillDropdownData)="fillDropdownData($event)"></app-load-dropdown-data>

<div class="container-fluid d-flex px-0 flex-column h-100" >
    <div class=" d-flex  main-bussness-containe">
            <div class="item-1">
                <img [src]="layoutService?.logoBusinessRegistrationPage" alt="">
            </div>
            <div class="item-2"> 
                <div class="row mt-5 mb-3">
                    <div class="col-12">
                        <h5 class="text-light f-22 ps-4 font-semibold">Business Registration</h5>
                    </div>
                <div class="col-12 pt-2 h-business-tab" [ngClass]="{'child_company':fromAddCompany}">
                  <div class="d-flex border-bottom justify-content-between mt-1 align-items-center"
                  id="edit_navtab">
                    <ul ngbNav #nav="ngbNav" 
                      [(activeId)]="active" 
                      class="nav-tabs">
                      <li [ngbNavItem]="1">
                        <a ngbNavLink>Company Registration</a>
                        <ng-template ngbNavContent>
                         <app-company-registration [getAllDropdownModel]="getAllDropdownModel" [fromAddCompany]="fromAddCompany" [isCridAvailabilityCheck]="isCridAvailabilityCheck"  [user]="registerUserResponseM.user" #companyregistration ></app-company-registration>
                        </ng-template>
                      </li>
                      <li [ngbNavItem]="2" [disabled]="disabledbank" class="{{disabledbank == true ? 'cursor-not-allowed' : ''}}">
                        <a ngbNavLink>Bank Details</a>
                        <ng-template ngbNavContent>
                         <app-bank-details-registration [user]="registerUserResponseM.user" #bankregistration ></app-bank-details-registration>
                        </ng-template>
                      </li>
                      <li [ngbNavItem]="3" [disabled]="disabledUser" class="{{disabledUser == true ? 'cursor-not-allowed' : ''}}">
                        <a ngbNavLink>User Details</a>
                        <ng-template ngbNavContent>
                        <app-user-details-registration [user]="registerUserResponseM.user"  #userregistration></app-user-details-registration>
                        </ng-template>
                      </li>
                    </ul>
                  </div>
                  <div [ngbNavOutlet]="nav" class="mt-2"></div>
                </div>
                <div class="col-12 ">
                  <div class="d-flex justify-content-end border-top">
                    <div class="d-flex py-4 pe-4">
                      <button class=" {{ active == 1 ? 'd-block'  : 'd-none' }} common_button f-14 font-semibold text-light white_button" style="background-color: transparent;" (click)="close()">Close</button>
                      <button (click)="previousTabActive()" class="{{active == 1 ? 'd-none'  : 'd-block'}} bg-transperent common_button f-14 font-semibold text-light white_button ms-2" style="background-color: transparent;"><span><img src="./assets/SVGicon/pre-icon.svg" alt="" width="15" height="16"></span><span class="ms-2">Previous</span></button>
                      <button (click)="nextTabActive(nav)" class="{{active == 3 ?  'd-none'  : 'd-block'}} bg-transperent common_button ms-2 f-14 font-semibold text-light white_button" style="background-color: transparent;"><span>Next</span> <span class="ms-2"><img src="./assets/SVGicon/next-icon.svg" alt="" width="15" height="16"></span> </button>
                      <button type="submit" (click)="userDetailsinsert()"  class="{{active == 3 ? 'd-block' : 'd-none'}} bg-transperent common_button ms-2 f-14 font-semibold text-light white_button" style="background-color: transparent;"><span>Finish</span> <span class="ms-2"><img src="./assets/SVGicon/next-icon.svg" alt="" width="15" height="16" ></span> </button>
                    </div>
                  </div>
                </div>
                </div>
            </div>
    </div>
</div>
