import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Ids, Notifications } from '../elements/notification/notification.model';
import { ResponseModel } from 'src/app/shared/models/response-model.model';
import { NotificationsService } from '../elements/notification/notifications.service';

@Component({
  selector: 'app-notification-sidebar',
  templateUrl: './notification-sidebar.component.html',
  styleUrls: ['./notification-sidebar.component.scss']
})
export class NotificationSidebarComponent {
  constructor(public notificationsService : NotificationsService,public toaster:ToastrService,){}
  @Input() notificationsData:Notifications[];
  @Output() dataChange: EventEmitter<string> = new EventEmitter<string>();
  @ViewChild('content') content!: ElementRef;
  openTab:number = 1;

  notificationIds = new Ids();
  //   notifications:[
  //         {
  //           id:1,
  //           status:2,
  //           description:"Lorem ipsum dolor"
  //         },
  //         {
  //           id:2,
  //           status:2,
  //           description:"Lorem ipsum dolor"
  //         },
  //         {
  //           id:3,
  //           status:2,
  //           description:"Lorem ipsum dolor sit amet,consectetur adipiscing sed do eiusmod tempor incididunt ut labore et"
  //         },
  //         {
  //           id:4,
  //           status:2,
  //           description:"Lorem ipsum dolor"
  //         },
  //         {
  //           id:5,
  //           status:2,
  //           description:"Lorem ipsum dolor"
  //         },
  //         {
  //           id:6,
  //           status:2,
  //           description:"Lorem ipsum dolor dd"
  //         },
  //         {
  //           id:7,
  //           status:1,
  //           description:"Lorem ipsum dolord sir"
  //         },
  //         {
  //           id:8,
  //           status:1,
  //           description:"Lorem ipsum dolor sit amet,consectetur adipiscing sed do eiusmod tempor incididunt ut labore et"
  //         },
  //         {
  //           id:9,
  //           status:1,
  //           description:"Lorem ipsum dolor"
  //         },
  //         {
  //           id:10,
  //           status:1,
  //           description:"Lorem ipsum dolor"
  //         },
  //         {
  //           id:11,
  //           status:1,
  //           description:"Lorem ipsum dolor sit amet,consectetur adipiscing sed do eiusmod tempor incididunt ut labore et"
  //         }
  //       ],
  //     }
  //static data end

  ngOnChanges(){
    if(this.notificationsData.length > 0){
      this.notificationsData = this.notificationsData;
    }
  }

  get filteredNotificationsData(): any[] {
    return this.notificationsData.filter(notification => notification.isRead !== false);
  }

  markAsread(item){
    item.isRead = true;
    this.notificationIds.ids = [item.id];
    this.notificationsService.markAsReadNotifications(this.notificationIds)
      .subscribe((res: ResponseModel) => {
          if (res.isSuccess) {
            // this.notificationList = res.response;
            this.toaster.success(res.message, null, { timeOut: 700 });
          } else {
            if (res.message != undefined) {
              this.toaster.error(res.message, null, { timeOut: 700 });
          }
          if (res?.errors?.length > 0) {
              this.toaster.error(res.errors[0], null, {
                  timeOut: 700,
              });
          }
          }
      });
  }

  markAllAsread(isRead){
    this.notificationsData?.map((x)=>x.isRead = isRead);
    this.notificationIds.ids = this.notificationsData?.map(a=>a.id);
    this.notificationsService.markAsReadNotifications(this.notificationIds)
      .subscribe((res: ResponseModel) => {
          if (res.isSuccess) {
            // this.notificationList = res.response;
            this.toaster.success(res.message, null, { timeOut: 700 });
          } else {
            if (res.message != undefined) {
              this.toaster.error(res.message, null, { timeOut: 700 });
          }
          if (res?.errors?.length > 0) {
              this.toaster.error(res.errors[0], null, {
                  timeOut: 700,
              });
          }
          }
      });
  };


  deletenotification(item){
    this.notificationIds.ids = [item.id];
    this.notificationsService.deleteNotifications(this.notificationIds)
      .subscribe((res: ResponseModel) => {
          if (res.isSuccess) {
            this.notificationsData = this.notificationsData.filter((notification) => !this.notificationIds.ids.includes(notification.id));
            this.toaster.success(res.message, null, { timeOut: 700 });
          } else {
            if (res.message != undefined) {
              this.toaster.error(res.message, null, { timeOut: 700 });
          }
          if (res?.errors?.length > 0) {
              this.toaster.error(res.errors[0], null, {
                  timeOut: 700,
              });
          }
          }
      });
  }

  clearAllNotifications(){
    this.notificationIds.ids = this.notificationsData?.filter(a=>a.isRead == true).map(a=>a.id);
    this.notificationsService.deleteNotifications(this.notificationIds)
      .subscribe((res: ResponseModel) => {
          if (res.isSuccess) {
            this.notificationsData = this.notificationsData.filter((notification) => !this.notificationIds.ids.includes(notification.id));
            this.toaster.success(res.message, null, { timeOut: 700 });
          } else {
            if (res.message != undefined) {
              this.toaster.error(res.message, null, { timeOut: 700 });
          }
          if (res?.errors?.length > 0) {
              this.toaster.error(res.errors[0], null, {
                  timeOut: 700,
              });
          }
          }
      });
  };

  checkAsmarkStatus():boolean{
    return this.notificationsData?.some(x => x.isRead !== true);
  }

}
