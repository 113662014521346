<h5 class="text-primary font-semibold f-14 mb-0 ps-2 py-2 mt-1">
    Tax
</h5>
<div class="tax_list_table position-relative">
    <app-ag-grid
        class="all_aggrid_tables" 
        [dataModel]="agGridDataModelTax"
        [tableRowCellEditable]="false"
        (gridReady)="onGridReady($event)"
        >
    </app-ag-grid>
</div>