import { Component } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-save-invoice',
  templateUrl: './save-invoice.component.html',
  styleUrls: ['./save-invoice.component.scss']
})
export class SaveInvoiceComponent {
  constructor(public activeModal:NgbActiveModal){
  }
  ngOnInit(){
  }

  onSave(){
      this.activeModal.close(true);
  }
}
