<div class="product_img wrapper imgCantainer w-100">
  <!-- {{isBase64Resource?'w-100':''}} -->
  <ng-container *ngIf="!isBase64Resource">
    <img [style.height]="'12.1rem'" [ngClass]="{'cursorPointer': images?.length > 0}"
      [src]="images?.length > 0 ? isBase64Image(images[currentImageIndex]?.imageUrl) : './assets/images/icDiamondPreview.png'"
      onerror="this.src = './assets/images/icDiamondPreview.png'" alt="" (click)="onImageClick()">
  </ng-container>

  <ng-container *ngIf="isBase64Resource">
    <img [style.height]="'12.1rem'" [ngClass]="{'cursorPointer': images?.length > 0}"
      [src]="images?.length > 0 ?isBase64Image(images[currentImageIndex]?.base64Resource) : './assets/images/no_preview_image.png'"
      onerror="this.src = './assets/images/no_preview_image.png'" alt="" (click)="onImageClick()">
  </ng-container>

  <button *ngIf="images?.length > 1" class="arrow prev-arrow" (click)="showPrevImage($event)">
    <img src="./assets/images/prevArrow.svg" alt="Previous">
  </button>

  <button *ngIf="images?.length > 1" class="arrow next-arrow" (click)="showNextImage($event)">
    <img src="./assets/images/nextArrow.svg" alt="Next">
  </button>
</div>