import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanDeactivate, NavigationEnd, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable, Subject } from "rxjs";
import { ComponentCanDeactivate } from "./direty-check.guard";
import { NotificationService } from "../services/notification.service";
import { StorageService } from "../services/storage.service";
import { SendDataService } from "../services/send-data.service";

export interface CanComponentDeactivate {
  canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}

@Injectable()
export class CanDeactivateGuard implements CanDeactivate<CanComponentDeactivate> {
  private $destroy: Subject<void> = new Subject();
  private redirectTo: string = '';
  private selfCall: boolean = false;

  constructor(
    private router: Router,
    public sendDataService: SendDataService,
    private notificationService: NotificationService,
    private storageService: StorageService
  ) {
    router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.$destroy.next();
      }
    });
  }

  canDeactivate(
    component: ComponentCanDeactivate,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    var confirmationSaveClicked = this.router.getCurrentNavigation().extras?.state?.confirmationSaveClicked;
    if (confirmationSaveClicked) {
      return true;
    }
    if (this.selfCall) {
      this.selfCall = false;
      return true;
    }
    else {
      this.redirectTo = decodeURI(nextState ? nextState.url : currentState.url);
      return component.canDeactivate ? (component.canDeactivate() ? true : this.openConfirmDialog()) : true;
    }
  }


  openConfirmDialog() {
    this.notificationService.openConfirmDialog().subscribe((result) => {
      if (result.isDismissed) {
        this.selfCall = true;
        this.router.navigate([this.redirectTo]);
      }else if (result.isConfirmed) {
        this.sendDataService.saveBeforeClose(); 
        this.$destroy.next();
        this.sendDataService.setredirectUrlAfterSave(this.redirectTo);
      }else if(result.isDenied){
        this.sendDataService.saveAsDraftBeforeClose(); 
        this.$destroy.next();
        this.sendDataService.setredirectUrlAfterSave(this.redirectTo);
      }
    })
    return false;
  }

}