
<div class="col-12 align-items-center d-flex">
    <div *ngIf="isShowlable" class="col-4 f-14 font-semibold text-gray-color text-nowrap mb-0">
        Branch<span class="text-color-danger">*</span>
    </div>
    
    <div class="col position-relative">
        <ng-multiselect-dropdown 
            class="custom_dropdown" 
            [placeholder]="placeholder" 
            [id]="id" 
            [name]="id"
            [required]="isRequired" 
            [settings]="dropdownSettings" 
            [data]="data??values" 
            [(ngModel)]="selectedIds"
            (onSelect)="onItemSelect($event)" 
            (onSelectAll)="onSelectAll($event)" 
            (onDeSelect)="onItemDeSelect($event)"
            (onDeSelectAll)="onItemDeSelectAll($event)">
        </ng-multiselect-dropdown>
        <app-input *ngIf="isRequired ==true" class="ng-multiselect-requried" [inputName]="id" [inputId]="id"
            [isRequired]="!selectedIds?.length" [inputValue]="selectedIds"></app-input>
    </div>

    <!-- <ng-container *ngIf="isclearIcon">
        <div *ngIf=" branchIds && branchIds.length > 1" class="cursorPointer cross-drop">
            <svg (click)="clearSelectedItems()" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
                <path d="M11.354 4.646a.5.5 0 0 1 0 .708L8.707 8l2.647 2.646a.5.5 0 1 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 .708-.708L8 7.293l2.646-2.647a.5.5 0 0 1 .708 0z" fill="#a3abb8"/>
            </svg>
            </div>
    </ng-container> -->

   
    <div *ngIf="isPlusIcon" class="d-flex justify-content-start ps-2 align-items-center" >
        <svg (click)="openModelPopup.emit(true)" class="cursorPointer" xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
            <g id="Group_3496" data-name="Group 3496" transform="translate(-0.023)">
              <circle id="Ellipse_59" data-name="Ellipse 59" cx="11" cy="11" r="11" transform="translate(0.023)" fill="#e8ecf3"/>
              <path id="Union_4" data-name="Union 4" d="M5.187-1a.687.687,0,0,1,.687.687V4.5h4.812a.687.687,0,1,1,0,1.375H5.875v4.812a.687.687,0,1,1-1.375,0V5.875H-.313A.687.687,0,0,1-1,5.187.687.687,0,0,1-.313,4.5H4.5V-.313A.687.687,0,0,1,5.187-1Z" transform="translate(6.023 6)" fill="#a3abb8"/>
            </g>
        </svg>
    </div>
    <div class="d-flex">
        <div *ngIf="isFilterIcon" (click)="onFilterIconClick(branchIds)">
            <app-button
                class=""
                showFilterIcon="true"
                [buttonClass]="'gap-0 h-1_8rem bordernone light_voiletButton px-1'"
                [buttonType]="'button'"
            ></app-button>
        </div>
        <div *ngIf="isResetIcon" (click)="onResetIconClick(branchIds)">
            <app-button class="p-0" 
            ResetdarkBlueIcon="true" 
            [buttonClass]="'gap-0 h-1_8rem bordernone light_voiletButton px-1'"
            [buttonType]="'button'">
        </app-button>
        </div>
    </div>
    
  
</div>

