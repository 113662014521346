import { Component, EventEmitter, Input, Optional, Output, SkipSelf } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      deps: [[Optional, NgForm]],
      useFactory: (ngForm: NgForm) => ngForm,
    },
  ],
})
export class SelectComponent {
  @Input() id:string;
  @Input() name:string;
  @Input() selectClass:string='';
  @Input() isDateTimePipe= false;

  @Input() isRequired:boolean;
  @Input() isDisabled:boolean;
  @Input() options: any[] = [];
  @Input() selectedValue: string = ''
  @Input() isValueOptionShow: boolean = true;
  @Input() optionDisables: any[] = []; 
  @Input () optionDisable:any;
  @Output() valueChanged = new EventEmitter<string>();
  @Input() defaultOption:boolean = false;
  @Input() isOptionDisabled:boolean;
  @Input() accountLedgerId: number | null;
  @Input() isEstimate:boolean = false;
  constructor(){

  }
  
  ngOnInit(): void {
    
  }
  setDropdownText(value) {
    this.id = value;
  }

  clearSelectedValue() {
    this.selectedValue = null; 
}

 // Accept an array of IDs to disable

isOptionDisableds(optionId: string): boolean {
    return this.optionDisables.includes(optionId);
}

}