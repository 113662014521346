import { Component, OnInit, Renderer2, ViewChild } from '@angular/core';
import { catchError } from 'rxjs';
import { CommonService } from '../../services/common.service';
import { NgForm } from '@angular/forms';
import { AdministrationService } from 'src/app/components/administration/administration.service';
import { ToastrService } from 'ngx-toastr';
import { GetAllBarcodeToolboxResponseModel } from 'src/app/components/administration/administration.model';

@Component({
  selector: 'app-barcode-toolboxkey',
  templateUrl: './barcode-toolboxkey.component.html',
  styleUrls: ['./barcode-toolboxkey.component.scss']
})
export class BarcodeToolboxkeyComponent extends CommonService implements OnInit{
  @ViewChild('administrationFormData') administrationFormData! : NgForm ;
  getAllBarcodeToolboxData          : any[]=[];
  isAdd                             : boolean = false;

  copyGetAllBarcodeToolboxData      : any[]=[]
  editBarcodeToolboxData            = new GetAllBarcodeToolboxResponseModel();

constructor(
  public renderer:Renderer2,
  public administrationService : AdministrationService,
  public toaster: ToastrService,
) 
{
  super(renderer)
}

ngOnInit(): void {
  this.getAllBarcodeToolboxList();
}

add(){
  this.isAdd = true;
  const obj = {id:0, name:'',hsnCode:'',active:true,systemName:''}
  this.getAllBarcodeToolboxData?.push(obj);
  this.copyGetAllBarcodeToolboxData?.push(obj);
  setTimeout(()=>{
    const element = document.getElementById(`barcodeToolboxName${obj.id}`);
    element?.focus();
  },0)
}


cancel(){
  // if(this.isAdd)
  // {
  //   this.isAdd = false;
  //   this.getAllMetalData = this.getAllMetalData.filter((x)=>x.id != 0);
  //   this.copyGetAllMetalData = this.copyGetAllMetalData.filter((x)=>x.id != 0);
  // }
  if(this.editBarcodeToolboxData?.id && this.editBarcodeToolboxData?.isEdit){
    const index = this.copyGetAllBarcodeToolboxData.findIndex((x)=>x.id == this.editBarcodeToolboxData?.id);
    const singleData =JSON.parse(JSON.stringify(this.copyGetAllBarcodeToolboxData))[index];
    this.getAllBarcodeToolboxData[index] = singleData;
    this.editBarcodeToolboxData = new GetAllBarcodeToolboxResponseModel();
  }  
}

edit(item,formData){
  item.isEdit = true;
  const controlObj = formData?.form?.controls;
  if(controlObj){
    const id = Object.keys(controlObj)[0];
    const element = document.getElementById(id);
    element?.focus();
  }
  this.editBarcodeToolboxData = {...item};
}

checkIsEdit(arr:any[]):boolean{
  return arr?.some((x)=>x.isEdit)
}


  // *********** BarcodeToolbox API START *********
  // **********get all BarcodeToolbox *********
  getAllBarcodeToolboxList() {
    this.administrationService.getAllBarcodeToolboxData = [];
    this.administrationService.getAllBarcodeToolbox().subscribe({
      next:(res)=>{
        if(res.isSuccess){
          this.getAllBarcodeToolboxData = res.response??[];
          this.administrationService.getAllBarcodeToolboxData = res.response??[];
          this.editBarcodeToolboxData = new GetAllBarcodeToolboxResponseModel();
          this.copyGetAllBarcodeToolboxData = JSON.parse(JSON.stringify(this.getAllBarcodeToolboxData))??[];
        }else{           
          this.getAllBarcodeToolboxData = [];
          this.copyGetAllBarcodeToolboxData = [];
        }  
      },
      error:(err)=>{
        this.getAllBarcodeToolboxData = [];
        this.administrationService.getAllBarcodeToolboxData = [];
        this.copyGetAllBarcodeToolboxData = [];
      }
    })
  }
  
 // *************insert BarcodeToolbox **************
  inserUpdateBarcodeToolboxList(item) {
    const {isEdit, ...rest} = item;
    this.administrationService.insertUpdateBarcodeToolbox(rest)
        .pipe(catchError((err) => this.handleError(err)))
        .subscribe((res) => {
          if(res.isSuccess){
            this.isAdd = false;
            this.getAllBarcodeToolboxList();
            this.toaster.success(res.message);
          }else{
            this.toaster.error(res.errors[0]);
          }  
      });
    }
    
}
