import { Component, EventEmitter, HostListener, Input, OnInit, Output, Renderer2 } from '@angular/core';
import { StorageService } from 'src/app/shared/services/storage.service';
import {UpdateCurrentRateService} from './update-current-rate.service';
import {InsertUpdateNewRateModel } from './update-current-rate.model';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/shared/services/common.service';
import { Router } from '@angular/router';
import { SendDataService } from 'src/app/shared/services/send-data.service';
@Component({
  selector: 'app-update-current-rate',
  templateUrl: './update-current-rate.component.html',
  styleUrls: ['./update-current-rate.component.scss']
})
export class UpdateCurrentRateComponent extends CommonService implements OnInit {
  @Input() getAllDropdownModel      : any = {};
  websiteURLDropdown: any[]=[];
  websiteURLId=0;
  websiteRateList:any[];
  @Output() toggler = new EventEmitter();

  constructor(private router: Router, public sendDataService:SendDataService ,public toaster: ToastrService, public renderer:Renderer2, public storageService : StorageService, public updateCurrentRateService:UpdateCurrentRateService) 
  {super(renderer)}
  
  ngOnInit(): void {
    this.getAllNewRateList(true);
  }

 
  updateSingleNewRate(item){
    const insertUpdateNewRateModel = new InsertUpdateNewRateModel()
    insertUpdateNewRateModel.data =[{...item}];
    this.updateRate(insertUpdateNewRateModel)
  }

  save(){
    const insertUpdateNewRateModel = new InsertUpdateNewRateModel()
    this.websiteRateList = this.websiteRateList.filter(item => item.newRate && item.newRate !== item.currentRate && item.newRate > 0);
    insertUpdateNewRateModel.data = JSON.parse(JSON.stringify(this.websiteRateList)) ;
    this.updateRate(insertUpdateNewRateModel);
    this.toggler.emit();
  }

  updateRate(insertUpdateNewRateModel){
    this.updateCurrentRateService.insertUpdateNewRate(insertUpdateNewRateModel).subscribe(res=>{
      if (res?.isSuccess) {
        this.getAllNewRateList(true);
        this.toaster.success(res?.message);
        this.sendDataService.notifyRateUpdate();
        // this.router.navigate([this.router.url]).then(() => {
        //   window.location.reload(); // Optional: Use this only if full page reload is needed.
        // });
      }
    else {
      this.toaster.error(res?.errors[0]);
    }
    })
  }

  getAllNewRateList(isRefresh?){
    this.updateCurrentRateService.getAllNewRate().subscribe({
      next : res=>{
        if (res.isSuccess) {
          this.websiteURLDropdown = res?.response;
          if(isRefresh){
            this.getDataByWebsiteUrlId(this.websiteURLId);
          }
        }
      else {
        this.websiteURLDropdown = [];
      }
      } , error:(err)=>{
        this.websiteURLDropdown = [];
      }
    })
  }

  getDataByWebsiteUrlId(id){
    const rateList  = this.websiteURLDropdown?.find((x)=>x?.id == id).extra1;
    this.websiteRateList = JSON.parse(rateList);
  }


  close(){
      this.toggler.emit();
  }

  // @HostListener('document:click', ['$event'])
  // onClick(event: Event) {
  //   const clickedElement = event?.target as HTMLElement;
  //   if ((!clickedElement?.closest('.rate_container'))) {
  //     this.close()
  //   }
  // }

  refresh(){
    this.getAllNewRateList(true);
  }
} 
