import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-ag-grid-inner-htmldisplay',
  templateUrl: './ag-grid-inner-htmldisplay.component.html',
  styleUrls: ['./ag-grid-inner-htmldisplay.component.scss']
})
export class AgGridInnerHTMLDisplayComponent implements ICellRendererAngularComp {
 
  public params : any;
  htmlContent :any;

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.htmlContent = params?.value || '';
    this.removeBrTags();
  }

  refresh(params: ICellRendererParams<any, any, any>): boolean {
    this.params = params;
    this.htmlContent = params?.value || '';
    throw '';
  }

  afterGuiAttached?(): void {
    this.getValue();
  }

  getValue() {
    return this.params.value;
  }

  removeBrTags() {
    this.htmlContent = this.htmlContent?.replace(/<br\s*\/?>/gi, '');
  }

  onClick(params){
    const customTextEvent = new CustomEvent('innerHtmlContentClick', {
      detail: { data: params },
    });
    this.params.api.dispatchEvent(customTextEvent);
  }
  
}
