import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import {ResetRenderService} from'./reset-render.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-reset-render',
  templateUrl: './reset-render.component.html',
  styleUrls: ['./reset-render.component.scss'],
  
})
export class ResetRenderComponent implements OnInit, ICellRendererAngularComp{
  params:any
  previousData
  constructor(private resetRenderService: ResetRenderService,private modalService : NgbModal){

  }
  agInit(params: any): void 
  {
    this.params = params;
    this.previousData = JSON.parse(JSON.stringify(this.params.node.data));
    
  }
  
  refresh(params: ICellRendererParams<any, any>): boolean {
    return true;
  }

  ngOnInit(): void
  {
  }
  public onCancelClick() 
  {
    this.params.node.setData(this.previousData);
    this.params.api.stopEditing(true);
  }
  deleterowdata(){
    this.resetRenderService.DeleteClick(this.params.rowIndex);
     const selectedData = [this.params.node.data];
     this.params.api.applyTransaction({ remove: selectedData });
     this.modalService.dismissAll();
     
  }
  onUpdateClick() {
    let obj: any = {};
    obj.type = "update";
    this.params.api.stopEditing();
    obj.selectedData = [this.params.data];
    
}

openDeleteConfirmationPopup(content,params)
{
  let pay = params
  this.modalService.open(content, { windowClass: "delet-confirm-popup-w" , centered:true,  backdrop: "static",
  keyboard: false,});
}
}