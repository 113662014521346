import { RadioRendererComponent } from "src/app/shared/common-components/ag-grid/radio-renderer/radio-renderer.component";
import { AgColDefsI, AgGridI, paginationModel } from "./../../shared/common-components/ag-grid/ag-grid.model";
import { CheckboxRendererComponent } from "./../../shared/common-components/ag-grid/checkbox-renderer/checkbox-renderer.component"
import {EditDeleteIconComponent} from "./../../shared/common-components/ag-grid/edit-delete-icon/edit-delete-icon.component"
import { CustomHeaderComponent } from "src/app/shared/common-components/ag-grid/custom-header/custom-header.component";
import { PreviewIconComponent } from "src/app/shared/common-components/ag-grid/preview-icon/preview-icon.component";
import { TextRendererComponent } from "src/app/shared/common-components/ag-grid/text-renderer/text-renderer.component";
import { StorageService } from "src/app/shared/services/storage.service";
import { StorageName } from "src/app/shared/enum/common-enum";
export class Settings {
}

const Name: AgColDefsI = {
    headerName: "Name",
    field: "name",
    resizable: false,
    sortable: true,
    minWidth: 350,
    lockPosition: "left",
    suppressMovable: false,
    lockPinned: true,
    editable:false,
    pinned:'left',
    headerClass:"custom-column-group-cell",
    cellClass:"custom-column-group-cell f-12 font-regular text-gray-color"
  };

  const DecimalNo: AgColDefsI = {
    headerName: "No. of Decimal ",
    field: "decimalNo",
    resizable: false,
    sortable: true,
    editable:false,
    minWidth: 130,
    headerClass:"custom-column-group-cell",
    cellClass:"custom-column-group-cell d-flex justify-content-end",
    headerComponentParams: {
      enableMenu : true
    },
  };

  const Symbol: AgColDefsI = {
    headerName: "Symbol",
    field: "symbol",
    resizable: false,
    sortable: true,
    editable:false,
    minWidth: 120,
    maxWidth: 150,
    headerClass:"custom-column-group-cell",
    cellClass:"custom-column-group-cell d-flex justify-content-end",
    headerComponentParams: {
      enableMenu : true
    },
  };

  const Description: AgColDefsI = {
    headerName: "Description",
    field: "description",
    resizable: false,
    sortable: true,
    editable:false,
    minWidth: 450,
    headerClass:"custom-column-group-cell",
    cellClass:"custom-column-group-cell",
    headerComponentParams: {
      enableMenu : true
    },
  };

  const Status: AgColDefsI = {
    headerName: "Status",
    field: "status",
    resizable: false,
    sortable: true,
    minWidth: 150,
    maxWidth: 100,
    editable:false,
    headerClass:"custom-column-group-cell",
    cellClass:"custom-column-group-cell d-flex justify-content-center",
    cellRenderer: CheckboxRendererComponent,
    cellRendererParams: {
      showLabel: false
    },
  };

  const ActionColumn: AgColDefsI = {
    headerName: "",
    field: "",
    minWidth: 50,
    maxWidth: 50,
    editable:false,
    cellRenderer: EditDeleteIconComponent,
    headerClass:"custom-column-group-cell",
    cellClass:"custom-column-group-cell",
  };
  

export const ColDefsCurrency: AgColDefsI[] = [
    Name,
    DecimalNo ,
    Symbol,
    Description,
    Status,
    ActionColumn
];


export const AgGridDataModelCurrency: AgGridI = {
    colDefs: ColDefsCurrency,
    rowSelection: "multiple",
};



// ag grid data model rate list

const Currency: AgColDefsI = {
  headerName: "Currency",
  field: "currency",
  resizable: false,
  sortable: true,
  minWidth: 350,
  lockPosition: "left",
  suppressMovable: false,
  lockPinned: true,
  editable:false,
  pinned:'left',
  headerClass:"custom-column-group-cell",
  cellClass:"custom-column-group-cell f-12 font-regular text-gray-color"
};

const ExchangeRate: AgColDefsI = {
  headerName: "Exchange Rate",
  field: "exchangeRate",
  resizable: false,
  sortable: true,
  editable:false,
  minWidth: 130,
  headerClass:"custom-column-group-cell",
  cellClass:"custom-column-group-cell d-flex justify-content-end",
  headerComponentParams: {
    enableMenu : true
  },
};

const Date: AgColDefsI = {
  headerName: "Date",
  field: "date",
  resizable: false,
  sortable: true,
  editable:false,
  minWidth: 120,
  maxWidth: 150,
  headerClass:"custom-column-group-cell",
  cellClass:"custom-column-group-cell d-flex justify-content-end",
  headerComponentParams: {
    enableMenu : true
  },
};

const DescriptionRateList: AgColDefsI = {
  headerName: "Description",
  field: "description",
  resizable: false,
  sortable: true,
  editable:false,
  minWidth: 450,
  headerClass:"custom-column-group-cell",
  cellClass:"custom-column-group-cell",
  headerComponentParams: {
    enableMenu : true
  },
};

const ActionColumnRateList: AgColDefsI = {
  headerName: "",
  field: "",
  minWidth: 50,
  maxWidth: 50,
  editable:false,
  cellRenderer: EditDeleteIconComponent,
  headerClass:"custom-column-group-cell",
  cellClass:"custom-column-group-cell",
};


const reportFilesListColumnDefs: AgColDefsI[] = [

  { 
    field:"active", 
    headerName: "", 
    suppressNavigable: true,
    editable: false,
    sortable: false, 
    resizable:false,
    suppressMovable:true,
    lockPinned:true,
    headerClass: '',
    cellRenderer: RadioRendererComponent,
    cellClass:'custom-column-group-cell',minWidth: 50,maxWidth:50,
  }
  ,
  { headerName: "", 
    field: 'resourceTitle',
    suppressNavigable: true,
    cellRenderer:TextRendererComponent,
    headerComponentParams: {enableMenu:false,uploadButton:true},   
    sortable: false, 
    editable: false,
    minWidth: 120, 
    resizable:false,
    suppressMovable:true,
    lockPinned:true,
    flex: 1, 
    cellClass: "custom-column-group-cell px-2", 
    cellRendererParams:{editIcon:true},
    headerClass: "custom-column-group-cell px-0 " ,
  },
  { headerName: "", 
  field: '',  
  headerClass: "",
  sortable: false, 
  editable: false,
  resizable:false,
  suppressMovable:true,
  lockPinned:true,
  minWidth: 50, 
  maxWidth: 50, 
  cellClass: "custom-column-group-cell d-flex align-items-center justify-content-center", 
  cellRenderer:PreviewIconComponent,
  },
  { headerName: "", 
    field: '',  
    headerClass: "custom-column-group-cell",
    sortable: false, 
    editable: false,
    suppressMovable:true,
    lockPinned:true,
    minWidth: 50, 
    maxWidth: 50, 
    cellRenderer: EditDeleteIconComponent,
    // cellRendererParams :{focusOnColumn:'name' ,isEditRowOnEdit:false},
    // cellRendererParams : {isConfirmationPopOpen:true},
    cellClass: "custom-column-group-cell",
    cellRendererParams: (params: any) => {
      const storageService = new StorageService();
      const userPermissions = storageService.retrieve(StorageName.USER_PERMISSIONS);
      return {
        hasDeletePermission: userPermissions?.setSoftwareCanDelete,
        focusOnColumn:'name' ,isEditRowOnEdit:false
      };
    },
 },

]


export const ColDefsRateList: AgColDefsI[] = [
  Currency,
  ExchangeRate ,
  Date,
  DescriptionRateList,
  ActionColumnRateList
];


export const AgGridDataModelRateList: AgGridI = {
  colDefs: ColDefsRateList,
  rowSelection: "multiple",
};


export const  AgGridDataModelReportFiles:AgGridI = {
  colDefs: reportFilesListColumnDefs,
  rowSelection: "multiple",
  rowHeight:28,
}


export class UpdateSettingRequestModel {
  settingModel: SettingModel[]
}

export class SettingModel{
  id               :number;
  value?           :string;
  settingTypeKeyId?: number;
  active           : boolean= false;
}


export class VouchertypeRequestModel extends paginationModel{
  page: number = 1;
}
export class GetallWhatsappRequestModel extends paginationModel {
  fromDate: string;
  toDate: string;
  pageSize: number = 25;
  filters       : string ;
  exportAs      :number;
  exportRequest : exportRequest[];
}
export class GetallWhatsappInsertRequestModel {
  caption: string;
  attachmentPath:string;
  date:string;
  statusName: string;
}
export class InsertEmailRequestModel {
  campaignMasterModel: CampaignMasterModel;
  campaignMappingModel: CampaignMappingModel[];
}


export class CampaignMasterModel {
  campaignTypeId: number;
  campaignName:string;
  body:string;
  date:string;
  statusId: number;
  templateId: number;
  subject: string;
  attachmentPath:string;
 groupId:number;
}

export class CampaignMappingModel {
  accountLedgerId: number;
  campaignTypeId: number;
  statusId: number;
  statusMessage: string;
  contactInfo: string;
}

export class GetallEmailRequestModel extends paginationModel {
  fromDate: string;
  toDate: string;
  pageSize: number = 25;
  filters: any;
}
export class exportRequest {
  gridName : string;
  columnToInclude : any
}
