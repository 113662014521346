import { Injectable } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { NotificationService } from './notification.service';

@Injectable({
  providedIn: 'root'
})
  export class HtmlToPdfService {
    constructor(    private sanitizer: DomSanitizer,public notificationService : NotificationService) {
      pdfMake.vfs = pdfFonts.pdfMake.vfs;
    }
  
    generatePdf(htmlByteArray: Uint8Array): Promise<any> {
      const pdfDocGenerator = pdfMake.createPdf({
        content: [{ text: 'Generated PDF from HTML Byte Array', fontSize: 16, margin: [0, 0, 0, 10] }],
        header: 'PDF Header',
        footer: 'PDF Footer'
      });
  
      return new Promise<any>((resolve) => {
        pdfDocGenerator.getBlob((blob: Blob) => {
          resolve(blob);
        });
      });
    }


// openPrintDialog(htmlContent: string): void {
//   const sanitizedHtml: SafeHtml = this.sanitizer.bypassSecurityTrustHtml(htmlContent);

//   // Create a hidden iframe
//   const iframe = document.createElement('iframe');
//   iframe.style.display = 'none';
//   document.body.appendChild(iframe);

//   // Set iframe content
//   const iframeDocument = iframe.contentWindow?.document;
//   if (iframeDocument) {
//     iframeDocument.open();
//     iframeDocument.write(sanitizedHtml.toString()); // Convert SafeHtml to string
//     iframeDocument.close();

//     // Wait for the iframe to load its content before triggering print
//     const waitForLoad = setInterval(() => {
//       if (iframe.contentWindow?.document.readyState === 'complete') {
//         clearInterval(waitForLoad);
//         iframe.contentWindow?.print();
//       }
//     }, 100);
//   } else {
//     console.error('Failed to open print window');
//   }
// }


}
