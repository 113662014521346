import { Injectable } from '@angular/core';
import { tap } from 'rxjs';
import { DataService } from '../../services/data.service';
import { StorageService } from '../../services/storage.service';
import { StorageName } from '../../enum/common-enum';
import { MetalSectionReadonlyModel } from './metal-section-readonly.model';

@Injectable({
  providedIn: 'root'
})
export class MetalSectionReadonlyService {
  constructor(public service:DataService,public storageService:StorageService) { }


  setFooterCalcAndDisplay(gridApi,isGridFooter){
    if(isGridFooter == true)
    {
    this.addFooterAndCalculation(gridApi,isGridFooter);
    }
  }
  addFooterAndCalculation(params,isGridFooter) {
    if(isGridFooter){
      const rowsdata =  params?.api?.getModel()?.rowsToDisplay?.map(a => a?.data);
      const sumOfQuantity = (rowsdata?.map(a => Number(a?.quantity))?.filter(value => typeof value === 'number' && !isNaN(value))?.reduce((a, b) => (a || 0) + (b || 0), 0) || 0);
      const sumOfGrossWt = (rowsdata?.map(a => Number(a?.grossWt))?.filter(value => typeof value === 'number' && !isNaN(value))?.reduce((a, b) => (a || 0) + (b || 0), 0) || 0).toFixed(2);
      const sumOfNetWt: string = (rowsdata?.map(a => Number(a?.netWt))?.filter(value => typeof value === 'number' && !isNaN(value))?.reduce((a, b) => (a || 0) + (b || 0), 0) || 0).toFixed(2);
      const sumOfFinalWt: string = (rowsdata?.map(a => Number(a?.finalWt))?.filter(value => typeof value === 'number' && !isNaN(value))?.reduce((a, b) => (a || 0) + (b || 0), 0) || 0).toFixed(2);
      const sumOfAmount: string = (rowsdata?.map(a => Number(a?.amount))?.filter(value => typeof value === 'number' && !isNaN(value))?.reduce((a, b) => (a || 0) + (b || 0), 0) || 0).toFixed(2);
      const sumOfNetAmount: string = (rowsdata?.map(a => Number(a?.netAmount))?.filter(value => typeof value === 'number' && !isNaN(value))?.reduce((a, b) => (a || 0) + (b || 0), 0) || 0).toFixed(2);
  
      const totalsObject = {
        quantity    : sumOfQuantity,
        grossWt     : sumOfGrossWt,
        netWt       : sumOfNetWt,
        finalWt     : sumOfFinalWt,
        amount      : sumOfAmount,
        netAmount   : sumOfNetAmount,
        isFooterRow : true
        }
        params?.api?.setPinnedBottomRowData([totalsObject]);
        params?.api?.refreshCells();
    }
  }

  setVisibility(storageName : StorageName | StorageName) : any
  {
    var metalSectionModel = new MetalSectionReadonlyModel();
    switch(storageName)
    {
      case StorageName.FORMNAME_TRANSACTION_STOCKJOURNAL_MOVEMENTSTOCK_SOURCE:
        metalSectionModel.isInternal_Fix = false;
        metalSectionModel.isAML =false;
        metalSectionModel.isKYC =false;
        metalSectionModel.isBarcodeInput = false;
        metalSectionModel.isGridFooter  = true;
        metalSectionModel.showCommentAndAddField = false;
        metalSectionModel.agGridDataModelGold.moreButton = false;
        metalSectionModel.agGridDataModelSilver.moreButton = false;
        metalSectionModel.agGridDataModelDiamond.moreButton = false;
        metalSectionModel.agGridDataModelStoneGEMS.moreButton = false;
        metalSectionModel.agGridDataModelImmitationAndWatches.moreButton = false;
        metalSectionModel.agGridDataModelGold.selectAll=true;
        metalSectionModel.agGridDataModelSilver.selectAll=true;
        metalSectionModel.agGridDataModelDiamond.selectAll=true;
        metalSectionModel.agGridDataModelStoneGEMS.selectAll=true;
        metalSectionModel.agGridDataModelImmitationAndWatches.selectAll=true;
        metalSectionModel.agGridDataModelGold = this.setNewColDef(metalSectionModel.agGridDataModelGold,StorageName.TRANSACTION_STOCKJOURNAL_MOVEMENTSTOCK_SOURCE_GOLD_ITEM_GRID);
        metalSectionModel.agGridDataModelSilver = this.setNewColDef(metalSectionModel.agGridDataModelSilver,StorageName.TRANSACTION_STOCKJOURNAL_MOVEMENTSTOCK_SOURCE_SILVER_ITEM_GRID);
        metalSectionModel.agGridDataModelDiamond = this.setNewColDef(metalSectionModel.agGridDataModelDiamond,StorageName.TRANSACTION_STOCKJOURNAL_MOVEMENTSTOCK_SOURCE_DIAMOND_ITEM_GRID);
        metalSectionModel.agGridDataModelStoneGEMS = this.setNewColDef(metalSectionModel.agGridDataModelStoneGEMS,StorageName.TRANSACTION_STOCKJOURNAL_MOVEMENTSTOCK_SOURCE_STONE_GEMS_ITEM_GRID);
        metalSectionModel.agGridDataModelImmitationAndWatches = this.setNewColDef(metalSectionModel.agGridDataModelImmitationAndWatches,StorageName.TRANSACTION_STOCKJOURNAL_MOVEMENTSTOCK_SOURCE_IMITATION_ITEM_GRID);

        metalSectionModel.agGridDataModelGold.editable = false;
        // metalSectionModel.storageNamePlatinum=StorageName.TRANSACTION_STOCKJOURNAL_MOVEMENTSTOCK_SOURCE_PLATINUM_ITEM_GRID;
        // metalSectionModel.storageNameOther=StorageName.TRANSACTION_STOCKJOURNAL_MOVEMENTSTOCK_SOURCE_OTHER_ITEM_GRID;
        metalSectionModel.tableIndex = 0;
        metalSectionModel.selectAll = true;
        metalSectionModel.showMetalSelectionTabs = false;
        break;

        case StorageName.FORMNAME_TRANSACTION_STOCKJOURNAL_MOVEMENTSTOCK_DESTINATION:
        metalSectionModel.isInternal_Fix = false;
        metalSectionModel.isAML =false;
        metalSectionModel.isKYC =false;
        metalSectionModel.isBarcodeInput = false;
        metalSectionModel.isGridFooter  = true;
        metalSectionModel.showCommentAndAddField = false;
        metalSectionModel.agGridDataModelGold.moreButton = false;
        metalSectionModel.agGridDataModelSilver.moreButton = false;
        metalSectionModel.agGridDataModelDiamond.moreButton = false;
        metalSectionModel.agGridDataModelStoneGEMS.moreButton = false;
        metalSectionModel.agGridDataModelImmitationAndWatches.moreButton = false;
        metalSectionModel.agGridDataModelGold.selectAll=true;
        metalSectionModel.agGridDataModelSilver.selectAll=true;
        metalSectionModel.agGridDataModelDiamond.selectAll=true;
        metalSectionModel.agGridDataModelStoneGEMS.selectAll=true;
        metalSectionModel.agGridDataModelImmitationAndWatches.selectAll=true;
        metalSectionModel.agGridDataModelGold = this.setNewColDef(metalSectionModel.agGridDataModelGold,StorageName.TRANSACTION_STOCKJOURNAL_MOVEMENTSTOCK_DESTINATION_GOLD_ITEM_GRID);
        metalSectionModel.agGridDataModelSilver = this.setNewColDef(metalSectionModel.agGridDataModelSilver,StorageName.TRANSACTION_STOCKJOURNAL_MOVEMENTSTOCK_DESTINATION_SILVER_ITEM_GRID);
        metalSectionModel.agGridDataModelDiamond = this.setNewColDef(metalSectionModel.agGridDataModelDiamond,StorageName.TRANSACTION_STOCKJOURNAL_MOVEMENTSTOCK_DESTINATION_DIAMOND_ITEM_GRID);
        metalSectionModel.agGridDataModelStoneGEMS = this.setNewColDef(metalSectionModel.agGridDataModelStoneGEMS,StorageName.TRANSACTION_STOCKJOURNAL_MOVEMENTSTOCK_DESTINATION_STONE_GEMS_ITEM_GRID);
        metalSectionModel.agGridDataModelImmitationAndWatches = this.setNewColDef(metalSectionModel.agGridDataModelImmitationAndWatches,StorageName.TRANSACTION_STOCKJOURNAL_MOVEMENTSTOCK_DESTINATION_IMITATION_ITEM_GRID);


        metalSectionModel.agGridDataModelImmitationAndWatches.editable = false;

        metalSectionModel.tableIndex = 1;
        metalSectionModel.selectAll = true;
        metalSectionModel.showMetalSelectionTabs = false;

        break;

        case StorageName.FORMNAME_TRANSACTION_STOCKJOURNAL_TRANSFER_SOURCE:
          metalSectionModel.isInternal_Fix = false;
          metalSectionModel.isAML =false;
          metalSectionModel.isKYC =false;
          metalSectionModel.isBarcodeInput = false;
          metalSectionModel.isGridFooter  = true;
          metalSectionModel.showCommentAndAddField = false;
          metalSectionModel.agGridDataModelGold.moreButton = false;
          metalSectionModel.agGridDataModelSilver.moreButton = false;
          metalSectionModel.agGridDataModelDiamond.moreButton = false;
          metalSectionModel.agGridDataModelStoneGEMS.moreButton = false;
          metalSectionModel.agGridDataModelImmitationAndWatches.moreButton = false;
          metalSectionModel.agGridDataModelGold.selectAll=true;
          metalSectionModel.agGridDataModelSilver.selectAll=true;
          metalSectionModel.agGridDataModelDiamond.selectAll=true;
          metalSectionModel.agGridDataModelStoneGEMS.selectAll=true;
          metalSectionModel.agGridDataModelImmitationAndWatches.selectAll=true;

          metalSectionModel.agGridDataModelGold = this.setNewColDef(metalSectionModel.agGridDataModelGold,StorageName.TRANSACTION_STOCKJOURNAL_TRANSFER_SOURCE_GOLD_ITEM_GRID);
          metalSectionModel.agGridDataModelSilver = this.setNewColDef(metalSectionModel.agGridDataModelSilver,StorageName.TRANSACTION_STOCKJOURNAL_TRANSFER_SOURCE_SILVER_ITEM_GRID);
          metalSectionModel.agGridDataModelDiamond = this.setNewColDef(metalSectionModel.agGridDataModelDiamond,StorageName.TRANSACTION_STOCKJOURNAL_TRANSFER_SOURCE_DIAMOND_ITEM_GRID);
          metalSectionModel.agGridDataModelStoneGEMS = this.setNewColDef(metalSectionModel.agGridDataModelStoneGEMS,StorageName.TRANSACTION_STOCKJOURNAL_TRANSFER_SOURCE_STONE_GEMS_ITEM_GRID);
          metalSectionModel.agGridDataModelImmitationAndWatches = this.setNewColDef(metalSectionModel.agGridDataModelImmitationAndWatches,StorageName.TRANSACTION_STOCKJOURNAL_TRANSFER_SOURCE_IMITATION_ITEM_GRID);
  
          metalSectionModel.agGridDataModelGold.editable = false;
          // metalSectionModel.storageNamePlatinum=StorageName.TRANSACTION_STOCKJOURNAL_MOVEMENTSTOCK_SOURCE_PLATINUM_ITEM_GRID;
          // metalSectionModel.storageNameOther=StorageName.TRANSACTION_STOCKJOURNAL_MOVEMENTSTOCK_SOURCE_OTHER_ITEM_GRID;
          metalSectionModel.tableIndex = 0;
          metalSectionModel.selectAll = true;
          metalSectionModel.showMetalSelectionTabs = true;
          break;
  
          case StorageName.FORMNAME_TRANSACTION_STOCKJOURNAL_TRANSFER_DESTINATION:
          metalSectionModel.isInternal_Fix = false;
          metalSectionModel.isAML =false;
          metalSectionModel.isKYC =false;
          metalSectionModel.isBarcodeInput = false;
          metalSectionModel.isGridFooter  = true;
          metalSectionModel.showCommentAndAddField = false;
          metalSectionModel.agGridDataModelGold.moreButton = false;
          metalSectionModel.agGridDataModelSilver.moreButton = false;
          metalSectionModel.agGridDataModelDiamond.moreButton = false;
          metalSectionModel.agGridDataModelStoneGEMS.moreButton = false;
          metalSectionModel.agGridDataModelImmitationAndWatches.moreButton = false;
          metalSectionModel.agGridDataModelGold.selectAll=true;
          metalSectionModel.agGridDataModelSilver.selectAll=true;
          metalSectionModel.agGridDataModelDiamond.selectAll=true;
          metalSectionModel.agGridDataModelStoneGEMS.selectAll=true;
          metalSectionModel.agGridDataModelImmitationAndWatches.selectAll=true;

          metalSectionModel.agGridDataModelGold = this.setNewColDef(metalSectionModel.agGridDataModelGold,StorageName.TRANSACTION_STOCKJOURNAL_TRANSFER_DESTINATION_GOLD_ITEM_GRID);
          metalSectionModel.agGridDataModelSilver = this.setNewColDef(metalSectionModel.agGridDataModelSilver,StorageName.TRANSACTION_STOCKJOURNAL_TRANSFER_DESTINATION_SILVER_ITEM_GRID);
          metalSectionModel.agGridDataModelDiamond = this.setNewColDef(metalSectionModel.agGridDataModelDiamond,StorageName.TRANSACTION_STOCKJOURNAL_TRANSFER_DESTINATION_DIAMOND_ITEM_GRID);
          metalSectionModel.agGridDataModelStoneGEMS = this.setNewColDef(metalSectionModel.agGridDataModelStoneGEMS,StorageName.TRANSACTION_STOCKJOURNAL_TRANSFER_DESTINATION_STONE_GEMS_ITEM_GRID);
          metalSectionModel.agGridDataModelImmitationAndWatches = this.setNewColDef(metalSectionModel.agGridDataModelImmitationAndWatches,StorageName.TRANSACTION_STOCKJOURNAL_TRANSFER_DESTINATION_IMITATION_ITEM_GRID);
  
          metalSectionModel.tableIndex = 1;
          metalSectionModel.selectAll = true;
          metalSectionModel.showMetalSelectionTabs = true;
  
          break;

    }

    return metalSectionModel;
  }

  setNewColDef(grirDataModel, storageName, 
    filterStorageName = "", 
    sortStorageName = "", 
    paginationStorageName ="",
    moreButton : boolean = false,
    selectAll : boolean = false,
    isFilter : boolean = false,
    isServerSidepagination : boolean = false) : any
  {
    grirDataModel.colDefs = grirDataModel.colDefs.map((item, index) => ({
      ...item,
      headerComponentParams: {
        ...item.headerComponentParams,
        storageName: storageName,
        filterStorageName : filterStorageName,
        sortStorageName : sortStorageName,
        paginationStorageName : paginationStorageName,
        moreButton : (item.extraField == "Action" || item.field=='action') ? moreButton : false,
        selectAll : (item.field=='active') ? selectAll : false,
        isFilter : isFilter,
        isServerSidepagination : isServerSidepagination
      },
      storageName: storageName,
    }));
    grirDataModel.storageName = storageName;
    return grirDataModel;
  }
}