
import { ToggleButtonsComponent } from "src/app/shared/common-components/ag-grid/toggle-buttons/toggle-buttons.component";
import { AgColDefsI, AgGridI } from "./../../../../../shared/common-components/ag-grid/ag-grid.model";
import {EditDeleteIconComponent} from "./../../../../../shared/common-components/ag-grid/edit-delete-icon/edit-delete-icon.component"
import {GoldAmountColumn, RateColumn, WeightColumn } from "src/app/shared/common-components/metal-section/metal-section.model";
import { StorageName } from "src/app/shared/enum/common-enum";
import { TextRendererComponent } from "src/app/shared/common-components/ag-grid/text-renderer/text-renderer.component";
import { AgGridCustomDropdownComponent } from "src/app/shared/common-components/ag-grid/ag-grid-custom-dropdown/ag-grid-custom-dropdown.component";
import { StorageService } from "src/app/shared/services/storage.service";

// ag grid data model rate list
const WeightColumnMetalWiseGrid = {...WeightColumn};
      WeightColumnMetalWiseGrid.minWidth = 80;
const RateColumnMetalWiseGrid   = {...RateColumn};
      RateColumnMetalWiseGrid.minWidth = 80;
      RateColumnMetalWiseGrid.headerComponentParams = {
        ...RateColumnMetalWiseGrid.headerComponentParams,
        // isRequired: false,
      };
const StockValueMetalWiseGrid   = {...GoldAmountColumn};
      StockValueMetalWiseGrid.field   = "stockValue";
      StockValueMetalWiseGrid.headerName = "Stock Val.";
      StockValueMetalWiseGrid.minWidth   = 80;
      StockValueMetalWiseGrid.editable   = false;



const Metal: AgColDefsI = {
    headerName: "Metal",
    field: "metalName",
    resizable: true,
    sortable: true,
    minWidth: 80,
    flex:1,
    editable:false,
    headerClass:"px-0 custom-column-group-cell",
    cellClass:"px-2 custom-column-group-cell f-12 font-regular text-gray-color",
    cellRenderer: TextRendererComponent
  };
  
  // const StockValue: AgColDefsI = {
  //   headerName: "Stock Val.",
  //   field: "stockValue",
  //   resizable: false,
  //   sortable: true,
  //   editable:true,
  //   minWidth: 100,
  //   headerClass:"px-1 custom-column-group-cell ag_header_end",
  //   cellClass:"px-2 custom-column-group-cell d-flex justify-content-end",
  // };

  
  const CrDr: AgColDefsI = {
    headerName: "Cr/Dr",
    field: "transactionTypeId",
    resizable: true,
    sortable: true,
    editable:false,
    minWidth: 90,
    cellRenderer: ToggleButtonsComponent,
    headerClass:"custom-column-group-cell",
    cellClass:"custom-column-group-cell d-flex align-items-center justify-content-center",
  };
  
const ActionColumn: AgColDefsI = {
  headerName: "",
  field: "",
  minWidth: 50,
  maxWidth: 50,
  editable: false,
  cellRenderer: EditDeleteIconComponent,
  cellRendererParams: {
    isEditRowOnEdit: false,
    isFirstDeleteIcon: false,
    isDeleteIcon: false,
    resetIcon: true
  },
  headerClass: "custom-column-group-cell",
  cellClass: "custom-column-group-cell",
  pinned: true,
};
  
  
  export const ColDefsMetalWiseOpening: AgColDefsI[] = [
    Metal,
    RateColumnMetalWiseGrid,
    WeightColumnMetalWiseGrid,
    StockValueMetalWiseGrid ,
    CrDr,
    ActionColumn
  ];
  
  
  export const AgGridDataModelMetalWiseOpening: AgGridI = {
    colDefs: ColDefsMetalWiseOpening,
    rowSelection: "multiple",
    storageName:StorageName.PRODUCT_LEDGEROPENING_METALWISE_OPENING_GRID
  };

export class CustomerDetails  {
    [key: string]         : any | null; // Define index signature to allow any additional properties
    addressModel          = new AddressVM();
    billingAddressModel   = new BillingAddressModel();
    shippingAddressModel  = new ShippingAddressModel();
    openingBalanceModel: OpeningBalanceModel[];
    resourcePropertyModel: ResourceProperties[];
    ledgerItemTypeTaxModel     :LedgerItemTypeTaxModel[];
}

export class AddressVM {
  [key: string]: any; // Define index signature to allow any additional properties
}

export class OpeningBalanceModel {
    [key: string]: any | null; // Define index signature to allow any additional properties
    deleted   : boolean=false;
    added     : boolean=false;
    edited    : boolean=false;
    none      : boolean=false;
}

export class LedgerItemTypeTaxModel {
  [key: string]: any | null; // Define index signature to allow any additional properties
  deleted   : boolean=false;
  added     : boolean=false;
  edited    : boolean=false;
  none      : boolean=false;
}

export class ResourceProperties {
    [key: string]: any | null; // Define index signature to allow any additional properties
    documentTypeId    : number= 0;
    expiryDate        : string;
    isDeleteIcon      : boolean=false;
    mimeType          : string ='';
    added             : boolean=false
    edited            : boolean=false
    deleted           : boolean=false
    none              : boolean=false
    id                : number = 0
  }
export class BillingAddressModel {
    [key: string]: any | null; // Define index signature to allow any additional properties
  }

export class ShippingAddressModel {
    [key: string]: any | null; // Define index signature to allow any additional properties
  }

  export enum SundryType{
    SundryCreditor  = 'Sundry Creditors',
    SundryDebitor  = 'Sundry Debtors',
  }

  export enum BilltoBill{
    Yes  = 'true',
    No  = 'false'
  }

  export enum AccountGroupId{
    SundryCreditor  = 22,
    SundryDebitor   = 26,
  }

  export enum AddressTab{
    BillingAddress    = 1,
    ShippingAddress   = 2,
  }

  export enum LedgerTypesEnum
  {
      // [Description("Customer")]
      Customer = 1,
      // [Description("WholeSaler")]
      WholeSaler = 2,
      // [Description("Job Worker")]
      JobWorker = 3 ,
      // [Description("Employee")]
      Employee = 4,
      // [Description("Sales Person")]
      SalesPerson = 5,
  }

  const MetalTypeColumn: AgColDefsI = {
    headerName: "Item Name",
    field: "itemTypeName",
    minWidth: 180,
    headerClass: "custom-column-group-cell px-0",
    cellClass: "custom-column-group-cell px-0",
    editable: false,
    sortable: true,
    resizable: true,
    cellRenderer: TextRendererComponent,
  }
  
  const DefaultInputType: AgColDefsI = {
    headerName: "Default Input Type",
    field: "defaultInputTypeId",
    headerComponentParams: { isRequired: false, enableSorting: false},
    minWidth: 100,
    sortable: true,
    editable: true,
    cellRenderer: TextRendererComponent,
    cellEditor: AgGridCustomDropdownComponent,
    cellEditorParams: {
      values: () => {
        const storageService = new StorageService();
        const storage = StorageName;
        return storageService.retrieve(storage.TAX_DROPDOWN);
      },
    },
    cellClass: "custom-column-group-cell px-0",
    headerClass: "custom-column-group-cell px-0",
  }
  const DefaultOutputType: AgColDefsI = {
    headerName: "Default Output Type",
    field: "defaultOutputTypeId",
    headerComponentParams: { isRequired: false, enableSorting: false},
    minWidth: 100,
    sortable: true,
    editable: true,
    cellRenderer: TextRendererComponent,
    cellEditor: AgGridCustomDropdownComponent,
    cellEditorParams: {
      values: () => {
        const storageService = new StorageService();
        const storage = StorageName;
        return storageService.retrieve(storage.TAX_DROPDOWN);
      },
    },
    cellClass: "custom-column-group-cell px-0",
    headerClass: "custom-column-group-cell px-0",
  }



  const MetalTypeTax: AgColDefsI[] = [
  MetalTypeColumn,
  DefaultInputType,
  DefaultOutputType,
  ]
  
  export const AgGridDataModelMetalTypeTax: AgGridI = {
    colDefs: MetalTypeTax,
    rowSelection: "multiple",
    storageName: StorageName.PRODUCT_LEDGEROPENING_METALTYPETAX_GRID,
    rows: [],
    headerHeight : 28,
    rowHeight: 28,
  };
  