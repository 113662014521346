export class CompanyList {
    
}
export class GetAllCompanyQueryResponse
{
    id              : number;
    companyName     : string;
    companyLogo     : string;
    address         : string;
    branchName      : string;
    isCurrentCompany: boolean;
    isMainCompany   : boolean;
    fullName        : string;
    contactNo       : string;
    email           : string;
    businessLicenceNo : string;
    crid            : string;
    registrationDate: string;
    country         : string;
    state           : string;
    city            : string;
   
}

