<app-load-dropdown-data [formName]="storageName. FORMNAME_ADMINISTRATION_MASTERS_BRANCHES"
  (fillDropdownData)="fillDropdownData()"></app-load-dropdown-data>
<div class="modal-header d-flex align-items-center justify-content-center position-relative">
  <div><span class="font-semibold text-primary">{{modalTitle}}</span></div>
  <app-svg-icon [name]="'cross_button_border'" class="cross-button-border"></app-svg-icon>
  <app-svg-icon [name]="'cross'" [ngClass]="currentHoverEffect" class="cross-button" (click)="activeModal.dismiss()"></app-svg-icon>
</div>
<form class="theme-form" ngNativeValidate #AddEditBranchForm="ngForm">
  <div class="modal-body mx-2">
    <div class="row my-0">
      <div class="px-2 div-1">
          <div class="col d-flex align-items-center mb-2">
              <div class="w-25 f-14 font-semibold text-gray-color text-nowrap mb-0">
               Branch Name<span class="text-color-danger">*</span>
                </div>
                <div class="col">
                  <app-input 
                    onlyAlphabetsAndNumbers
                    [inputName]="'name'" 
                    [inputType]="'text'"
                    [inputId]="'name'" 
                    [inputValue]="createUpdateBranchRequestModel?.name" 
                    (inputValueEmit)="createUpdateBranchRequestModel.name= $event" 
                    [isRequired]="true"
                    (keydown.enter)="onKeydown($event, 'mobileNumber1')"
                    >
                  </app-input>
                </div>
          </div>
          <!-- <div class="col d-flex mb-2">
              <div class="w-25 f-14 font-semibold text-gray-color text-nowrap mb-0">
                 Company Id
                   </div>
             <div class="col">
               <app-select  
                  [id]="'companyId'"
                  [name]="'companyId'"
                  [options]="companyDropdownModelList"
                  [selectedValue]="createUpdateBranchRequestModel?.companyId"
                  (valueChanged)="createUpdateBranchRequestModel.companyId= $event"
                  [options]=""
                  (keydown.enter)="onKeydown($event, 'mobileNumber1')"
                 >
               </app-select>
             </div>
          </div> -->
          <div class="col d-flex align-items-center mb-2">
              <div class="w-25 f-14 font-semibold text-gray-color text-nowrap mb-0">
                 Contact 1
                   </div>
             <div class="col align-contact"> 
               <app-input-with-select-dropdown
               [inputName]="'mobileNumber1'" 
               [inputMaxlength]="10"
               [inputType]="'text'" 
               [inputId]="'mobileNumber1'"
               [inputValue]="createUpdateBranchRequestModel?.mobileNumber1" 
               (inputValueEmit)="createUpdateBranchRequestModel.mobileNumber1 = $event"
               [selectedDialCodeValue]="createUpdateBranchRequestModel?.mobileNo1DialCode"
               (dialCodeValueChanged)="createUpdateBranchRequestModel.mobileNo1DialCode = $event"
               [isRequired]="false" 
               (keydown.enter)="onKeydown($event, 'mobileNumber2')"
               ></app-input-with-select-dropdown>
             </div>
          </div>
          <div class="col d-flex  align-items-center mb-2">
              <div class="w-25 f-14 font-semibold text-gray-color text-nowrap mb-0">
                 Contact 2
                   </div>
             <div class="col align-contact">
               <app-input-with-select-dropdown
               [inputName]="'mobileNumber2'" 
               [inputMaxlength]="10"
               [inputType]="'text'" 
               [inputId]="'mobileNumber2'"
               [inputValue]="createUpdateBranchRequestModel?.mobileNumber2" 
               (inputValueEmit)="createUpdateBranchRequestModel.mobileNumber2 = $event"
               [selectedDialCodeValue]="createUpdateBranchRequestModel?.mobileNo2DialCode"
               (dialCodeValueChanged)="createUpdateBranchRequestModel.mobileNo2DialCode = $event"
               [isRequired]="false" 
               (keydown.enter)="onKeydown($event, 'email')"
               ></app-input-with-select-dropdown>
             </div>
          </div>
          <div class="col d-flex align-items-center mb-2">
             <div class="w-25 f-14 font-semibold text-gray-color text-nowrap mb-0">
              Mail ID<span class="text-color-danger">*</span>
               </div>
             <div class="col">
               <app-input 
                 [inputName]="'email'" 
                 [inputType]="'email'"
                 [isEmailPattern]="true"
                 [inputId]="'email'" 
                 [inputValue]="createUpdateBranchRequestModel?.email" 
                 (inputValueEmit)="createUpdateBranchRequestModel.email = $event" 
                 [isRequired]="true"
                 (keydown.enter)="onKeydown($event, 'pinCode')"
                 >
               </app-input>
             </div>
          </div>

          <div class="col d-flex align-items-center">
            <div class="w-25 f-14 font-semibold text-gray-color text-nowrap mb-0">
                Zip Code
                 </div>
            <div class="col">
              <app-input 
                [isOnlyAlphabetsAndNumbers]="true"
                [inputName]="'pinCode'" 
                [inputType]="'text'"
                [inputId]="'pinCode'" 
                [inputValue]="createUpdateBranchRequestModel?.pinCode" 
                (inputValueEmit)="createUpdateBranchRequestModel.pinCode = $event" 
                [isRequired]="false"
                [inputMaxlength]="10"
                (keydown.enter)="onKeydown($event, 'addressLine1')"
                >
              </app-input>
            </div>
        </div>
      </div>
      <div class="col px-2">
        <div class="col d-flex align-items-center mb-2">
              <div class="w-25 f-14 font-semibold text-gray-color text-nowrap mb-0">
                Address Line 1<span class="text-color-danger">*</span>
                 </div>
              <div class="col">
                <app-input 
                  [inputName]="'addressLine1'" 
                  [inputType]="'text'"
                  [inputId]="'addressLine1'" 
                  [inputValue]="createUpdateBranchRequestModel?.addressLine1" 
                  (inputValueEmit)="createUpdateBranchRequestModel.addressLine1 = $event" 
                  [isRequired]="true"
                  (keydown.enter)="onKeydown($event, 'addressLine2')"
                  >
                </app-input>
              </div>
        </div>
        <div class="col d-flex align-items-center mb-2">
            <div class="w-25 f-14 font-semibold text-gray-color text-nowrap mb-0">
                Address Line 2
                 </div>
           <div class="col">
            <app-input 
              [inputName]="'addressLine2'" 
              [inputType]="'text'"
              [inputId]="'addressLine2'" 
              [inputValue]="createUpdateBranchRequestModel?.addressLine2" 
              (inputValueEmit)="createUpdateBranchRequestModel.addressLine2 = $event" 
              [isRequired]="false"
              (keydown.enter)="onKeydown($event, 'countryId')"
              >
            </app-input>
          </div>
        </div>
        <div class="col d-flex align-items-center gap-2 mb-2">
            <div class="col d-flex">
              <div class="w-25 f-14 font-semibold text-gray-color text-nowrap mb-0">
                Country
              </div>
              <div class="col">
                <app-select 
                    [id]="'countryId'"
                    [name]="'country'"
                    [options]="getAllDropdownModel?.countryDropdown"
                    [selectedValue]="createUpdateBranchRequestModel?.countryId"
                    (valueChanged)="createUpdateBranchRequestModel.countryId= $event; onChangeCountryName($event)"
                    (keydown.enter)="onKeydown($event, 'stateId')"
                  >
                  <!-- ;getDistrictByStateID(false) -->
                </app-select>
              </div>
            </div>
        </div>
        <div class="col d-flex align-items-center gap-2 mb-2">
          <div class="col d-flex">
            <div class="w-25 f-14 font-semibold text-gray-color text-nowrap mb-0">
              State
            </div>
            <div class="col">
              <app-select 
                  [id]="'stateId'"
                  [name]="'state'"
                  [options]="filterCountryWise"
                  [selectedValue]="createUpdateBranchRequestModel?.stateId"
                  (valueChanged)="createUpdateBranchRequestModel.stateId= $event"
                  (keydown.enter)="onKeydown($event, 'active')"
                >
                <!-- getDistrictByStateID(false) -->
              </app-select>
            </div>
          </div>
          <!-- <div class="col d-flex align-items-center mb-2">
            <div class="w-50 f-14 font-semibold text-gray-color text-nowrap mb-0">
               District
            </div>
            <div class="col">
              <app-select 
                [id]="'districtId'"
                [name]="'destrict'"
                [options]="getDistrictData"
                [selectedValue]="createUpdateBranchRequestModel?.districtId"
                (valueChanged)="createUpdateBranchRequestModel.districtId= $event;getTalukaByDistrictID(false)"
                (keydown.enter)="onKeydown($event, 'talukaId')"
                >
              </app-select> 
            </div>
          </div> -->
      </div>
        <div class="col d-flex gap-2 align-items-center">
            <!-- <div class="col d-flex">
              <div class="w-50 f-14 font-semibold text-gray-color text-nowrap mb-0">
                Taluka
              </div>
              <div class="col">
                <app-select 
                  [id]="'talukaId'"
                  [name]="'taluka'"
                  [options]="getTalukaData"
                  [selectedValue]="createUpdateBranchRequestModel?.talukaId"
                  (valueChanged)="createUpdateBranchRequestModel.talukaId= $event"
                  (keydown.enter)="onKeydown($event, 'pincodeId')"
                >
                </app-select>
              </div>
            </div> -->
            <div class="col d-flex align-items-center">
              <div (click)="this.createUpdateBranchRequestModel.active = !this.createUpdateBranchRequestModel.active;"  class="w-25 f-14 font-semibold cursorPointer text-gray-color text-nowrap mb-0">
                  Active
              </div>
              <div class="col d-flex align-items-center">
                <app-checkbox 
                [dyanamicId]="'active'" 
                [isRequired]="false" 
                [status]="createUpdateBranchRequestModel?.active" 
                (check)="createUpdateBranchRequestModel.active = $event"
                [disabled]="createUpdateBranchRequestModel.isSystemGenerated == true "
                ></app-checkbox>
              </div>
            </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer pt-3 pb-2 mx-2">
    <app-button class="my-0 me-3" (buttonOutputEvent)="activeModal.close()" [buttonLabel]="'Close'" [buttonClass]="'light_button'" [buttonType]="'button'">
    </app-button>
    <app-button 
      [ngbTooltip]="sendDataService?.noPermissionTooltip"
      [disableTooltip]="(editBranchDetails?.id? sendDataService?.permissionVM?.mastersCanUpdate : sendDataService?.permissionVM?.mastersCanAdd)"
      [disabled]="!(editBranchDetails?.id? sendDataService?.permissionVM?.mastersCanUpdate : sendDataService?.permissionVM?.mastersCanAdd)" class="m-0" [buttonLabel]="'Save'" [buttonClass]="'blue_button'" [buttonType]="'submit'" (buttonOutputEvent)="addEditBranch()">
    </app-button>
  </div>
</form>