<div class="row ">
  <div class="col-12">
    <app-icon-with-input [inputName]="'customerAddress1'" 
      [inputType]="'text'" [inputId]="'customerAddress1'"
      (keydown.enter)="onKeydown($event, 'customerAddress2')"
      [inputValue]="customerDetails?.shippingAddressModel?.addressLine1"
      (inputValueEmit)="customerDetails.shippingAddressModel.addressLine1 = $event"
      [inputPlaceholder]="'Address 1'" [InputLabel]="'Address 1'" labelName="true" [className]="'w-90px'"></app-icon-with-input>
  </div>
</div>

<div class="row mt-2 ">
  <div class="col-12">
    <app-icon-with-input [inputName]="'customerAddress2'"
      [inputType]="'text'" [inputId]="'customerAddress2'" 
      (keydown.enter)="onKeydown($event, 'shippingAddressCountry')"
      [inputValue]="customerDetails?.shippingAddressModel?.addressLine2"
      (inputValueEmit)="customerDetails.shippingAddressModel.addressLine2 = $event" [inputPlaceholder]="'Address 2'"
      [InputLabel]="'Address 2'" labelName="true" [className]="'w-90px'"></app-icon-with-input>
  </div>
</div>

<div class="row mt-2">
  <div class="col-12 d-flex">
    <div class="col-6 pe-1">
      <app-select-with-icon [options]="getAllDropdownModel?.countryDropdown"
      [selectedValue]="customerDetails?.shippingAddressModel?.countryId" (valueChanged)="customerDetails.shippingAddressModel.countryId = convertToNum($event); onChangeCountryName($event)"
      [id]="'shippingAddressCountry'" [name]="'shippingAddressCountry'" [isDisabled]="false"
      (keydown.enter)="onKeydown($event, 'shippingAddressState')"
      [selectPlaceholder]="'Maharashtra'" [SelectLabel]="'Country'"
      [isRequired]="false"
      labelName="true" [className]="'w-90px'">
      <!-- [isRequired]="isFromAccountLedger || LedgerId?false:true" -->
    </app-select-with-icon>
    </div>
    <div class="col-6 ps-1">
      <app-select-with-icon [options]="getAllDropdownModel?.shippingAddressStateList"
      [selectedValue]="customerDetails?.shippingAddressModel?.stateId" (valueChanged)="customerDetails.shippingAddressModel.stateId = convertToNum($event)"
      [id]="'shippingAddressState'" [name]="'shippingAddressState'" [isDisabled]="false"
      (keydown.enter)="onKeydown($event, 'customerPinCode')"
      [selectPlaceholder]="'Maharashtra'" [SelectLabel]="'State'"
      [isRequired]="false"
      labelName="true" [className]="'w-90px'">
      <!-- [isRequired]="isFromAccountLedger || LedgerId?false:true"  -->
    </app-select-with-icon>
    </div>
  </div>
</div>

<div class="row mt-2">
  <div class="col-6">
    <app-icon-with-input [inputName]="'customerPinCode'" [inputMaxlength]="9" [isOnlyAlphabetsAndNumbers]="true"
      [inputType]="'text'" [inputId]="'customerPinCode'"
      [inputValue]="customerDetails?.shippingAddressModel?.pinCode"
      [isAllowDecimal]="false"
      (keydown.enter)="onKeydown($event, 'billingAddressActive')"
      (inputValueEmit)="customerDetails.shippingAddressModel.pinCode = $event" [isRequired]="false"
      [inputPlaceholder]="'Zip Code'" [InputLabel]="'Zip Code'" labelName="true" [className]="'w-90px'"></app-icon-with-input>
  </div>
  <div class="col d-flex"> 
      <div class="col d-flex align-items-center">
          <app-checkbox 
          [dyanamicId]="'billingAddressActive'" 
          [isRequired]="false" 
          [status]="customerDetails.shippingAddressModel.active" 
          (check)="checkShipping($event)"
          [lableName]="'Same As Billing Address'"
          ></app-checkbox>
      </div>
  </div>
</div>

