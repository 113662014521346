import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {AddEditComment} from './add-edit-comment.model'
import { ManufacturerService } from 'src/app/components/manufacturer/manufacturer.service';
@Component({
  selector: 'app-add-edit-comment',
  templateUrl: './add-edit-comment.component.html',
  styleUrls: ['./add-edit-comment.component.scss']
})
export class AddEditCommentComponent implements OnInit{
  // comment='';
  // dateTime:''
 addeditComment = new AddEditComment()
 @Input() data: any
  @Input()comments = [];
  comment
  index :number;
  @ViewChild('addEditCommentForm') addEditCommentForm  : NgForm
  formModel : any ;
  constructor(public activeModal : NgbActiveModal,
    public manufacturerService: ManufacturerService,
  ){

  }
  ngOnInit(): void {
      this.getComment(this.data?.jobworkQueueMasterId)
  }
getComment(id){
  if(id){
  this.manufacturerService.getJobworkQueueCommentByMasterId(id).subscribe({
    next: (res) => {
      if (res?.isSuccess) {
          this.comments = JSON.parse(res?.response?.comment);
          // let data:any = '[{"comment":"test","dateTime":"2024-08-23T15:21:32.539Z"}]'
          // this.comments = JSON.parse(data);
      }
    }
  })
}
}

  OnSaveFieldsValues(){
    const result ={comment:this.comments,id:this.data?.jobworkQueueMasterId}
    this.activeModal.close(result);
  }

  // onAddValue(){
  //   if(this.addEditCommentForm.valid){
  //     if (this.comments == null || this.comments == undefined) {
  //       this.comments = [];
  //     }
      
  //     if(this.index == null || this.index == undefined ){
  //       this.addeditComment.dateTime = new Date();
  //       this.comments.push(this.addeditComment);
  //       this.addeditComment = new AddEditComment();
  //       this.index = null;
  //     } else {
  //       this.comments[this.index] = this.addeditComment;
  //       this.index = null;
  //       this.addeditComment = new AddEditComment();
  //     }
  //     this.addEditCommentForm?.resetForm();
  //   } else {
  //     const hideSubmitBtn = document.getElementById('hide');
  //     hideSubmitBtn?.click();
  //   }
  //   const input = document.getElementById('value');
  //   input?.focus();
  // }
  

  // edit(item , index){
  //   this.addeditComment = item;
  //   this.index = index; 
  // }

  // deletecomment(item ,i){
  //   this.comments.splice(i ,1)
  //   this.addEditCommentForm?.resetForm();
  // }
}