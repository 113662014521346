<!-- <div class="d-flex align-items-center justify-content-center"> -->
    <div class="wrapper">
        <div class="custom-input">
          <input type="radio" [id]="params.rowIndex +'cr'" [value]="1" [(ngModel)]="params.value" [name]="params.rowIndex +'cr'" (click)="toggleValue(1)">
          <label class="mb-0" [for]="params.rowIndex +'cr'">Cr</label>
        </div>
        <div class="custom-input">
          <input type="radio" [id]="params.rowIndex +'dr'" [value]="2" [(ngModel)]="params.value" [name]="params.rowIndex +'dr'" (click)="toggleValue(2)">
          <label class="mb-0" [for]="params.rowIndex +'dr'">Dr</label>
        </div>
    </div>
<!-- </div> -->