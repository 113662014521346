import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, tap } from 'rxjs';
import { ResponseModel } from 'src/app/shared/models/response-model.model';
import { DataService } from 'src/app/shared/services/data.service';
import { BarcodeTrackingRequestModel, GetAllDepartmentReportRequestModel, GetRequestModel, GetallBarcodestockRequestModel, GetallDayReportRequestModel, GetallDraftsRequestModel, GetallInvoiceRequestModel, GetallstockDetailRequestModel, GetallstockRequestModel, GetallstockhistoryRequestModel, MetalFixInsertUpdateRequestModel, PrintDiamondCertificate, PrintSaleInvoice, Whatsapp ,CheckPermissionFor} from './report.model';
import { API_URL_GET_ALL_GRID_GET_DIAMOND_CURRENT_STOCK, API_URL_JOBWORK_ORDER_LIST, API_URL_SALEORDER_PROCESS_LIST } from 'src/app/shared/url-helper/url-helper';
import { StorageName } from 'src/app/shared/enum/common-enum';
import { StorageService } from 'src/app/shared/services/storage.service';
import { Router } from '@angular/router';
import { AddDataToRefine, GetallReportOldJewelleryRequestModel} from './old-jewellery/old-jewellery.model';
import { TransactionStockJournalTabs } from '../Transaction/transaction.model';
import { VoucherType, VoucherTypeName, commonModalOptions } from 'src/app/shared/models/common.model';
import { ToastrService } from 'ngx-toastr';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { PrintPreviewComponent } from 'src/app/shared/common-components/common-popup/print-preview/print-preview.component';
import { ManufacturerService } from '../manufacturer/manufacturer.service';
import { IpcRenderService } from 'src/app/shared/services/ipc-render.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { TransactionService } from '../Transaction/transaction.service';
import { environment } from 'src/environments/environment';
import { SendDataService } from 'src/app/shared/services/send-data.service';


@Injectable({
  providedIn: 'root'
})
export class ReportService {
  storageName = StorageName;
  constructor( public sendDataService : SendDataService,  private sanitizer: DomSanitizer, public transactionService    : TransactionService, private ipcService            : IpcRenderService,public service:DataService, public storageService : StorageService,public manufacturerService : ManufacturerService , private router : Router,public toastrService:ToastrService,public notificationService:NotificationService, private modalService    : NgbModal ) { }
  public SaleInvoiceNavigate = new BehaviorSubject<any>(undefined);
  public stockHistorynavigate = new BehaviorSubject<any>(undefined);

// ******Report Invoice start********
//Invoices
  GetInvoiceReportData(data):Observable<ResponseModel>{
    let url = "api/app/invoice/getallinvoice";
    const json =JSON.stringify(data);
    return this.service.post(url,data, true ,false).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

  updateallledgerposting(data):Observable<ResponseModel>{
    let url = "api/app/invoice/updateallledgerposting";
    const json =JSON.stringify(data);
    return this.service.post(url,data, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }


  sendmail(data):Observable<ResponseModel>{
    let url = "api/app/setting/sendmail";
    const json =JSON.stringify(data);
    return this.service.put(url,data, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

  printbarcodeaslabelorcard(data : any): Observable<ResponseModel> {
    let url ="api/app/stockreport/printbarcodeaslabelorcard";
    return this.service.post(url,data, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

  // getByInvoiceVoucherTypeId(id?:number):Observable<ResponseModel>{
  //   let url = `=${id}`;
  //   return this.service.get(url, true).pipe<ResponseModel>(
  //     tap((response: any) => {
  //       return response;
  //     })
  //   );
  // }
// Drafts
  GetDraftsReportData(getallDraftsRequestModel : GetallDraftsRequestModel):Observable<ResponseModel>{
    let url = "api/app/invoicedraft/getallinvoicedraft";
    return this.service.post(url,getallDraftsRequestModel, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }
  sendNotification(data):Observable<ResponseModel>{
    let url = "api/app/setting/sendnotification";
    const json =JSON.stringify(data);
    return this.service.post(url,data, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }
  // getByDraftVoucherTypeId(id?:number):Observable<ResponseModel>{
  //   let url = `=${id}`;
  //   return this.service.get(url, true).pipe<ResponseModel>(
  //     tap((response: any) => {
  //       return response;
  //     })
  //   );
  // }

// ******Repart Invoice end********

// ********Stock Report start*******

getallstock(getallstockRequestModel : GetallstockRequestModel):Observable<ResponseModel>{
  let url = "api/app/stockreport/getAllStock";
  return this.service.post(url,getallstockRequestModel, true, false, false).pipe<ResponseModel>(
    tap((response: any) => {
      return response;
    })
  );
}

getDiamondCurrentStock (getallstockRequestModel : GetallstockRequestModel):Observable<ResponseModel>{
  let url = API_URL_GET_ALL_GRID_GET_DIAMOND_CURRENT_STOCK;
  return this.service.post(url,getallstockRequestModel, true).pipe<ResponseModel>(
    tap((response: any) => {
      return response;
    })
  );
}

getAllStockHistoryOfDiamond (getallstockRequestModel : GetallstockRequestModel):Observable<ResponseModel>{
  let url = "api/app/stockreport/getAllStockHistoryOfDiamond";
  return this.service.post(url,getallstockRequestModel, true).pipe<ResponseModel>(
    tap((response: any) => {
      return response;
    })
  );
}

GetAllDiamondAndStones(data : any ,URL):Observable<ResponseModel>{
  let url = URL;
  return this.service.post(url,data, true).pipe<ResponseModel>(
    tap((response: any) => {
      return response;
    })
  );
}

GetAllGoldAndSilver(data : any ,URL):Observable<ResponseModel>{
  let url = URL;
  return this.service.post(url,data, true).pipe<ResponseModel>(
    tap((response: any) => {
      return response;
    })
  );
}

getAllImmitationAndWatches(data : any):Observable<ResponseModel>{
  let url = "api/app/stockreport/getimitationwatchesreport";
  return this.service.post(url,data, true).pipe<ResponseModel>(
    tap((response: any) => {
      return response;
    })
  );
}

getallstockhistory(data :any ,path ):Observable<ResponseModel>{
  let url = `api/app/stockreport/${path}`;
  const json =JSON.stringify(data);
  return this.service.post(url,data, true, false, false).pipe<ResponseModel>(
    tap((response: any) => {
      return response;
    })
  );
}

getallstockDetail(getallstockDetailRequestModel : GetallstockDetailRequestModel):Observable<ResponseModel>{
  let url = "api/app/stockreport/getallstockdetails";
  return this.service.post(url,getallstockDetailRequestModel, true).pipe<ResponseModel>(
    tap((response: any) => {
      return response;
    })
  );
}
getallstockDetailReport(data):Observable<ResponseModel>{
  let url = "api/app/goldstockanalysis/getgoldstockanalysisreportbyproduct";
  return this.service.post(url,data, true).pipe<ResponseModel>(
    tap((response: any) => {
      return response;
    })
  );
}

getallRFIDAvailable(getallrfdcstockRequestModel : GetRequestModel):Observable<ResponseModel>{
  let url = "api/app/stockreport/getallavailablestockrfidbarcode";
  return this.service.post(url,getallrfdcstockRequestModel, true).pipe<ResponseModel>(
    tap((response: any) => {
      return response;
    })
  );
}

getallRFIDScanned(getallrfidScannedRequestModel : GetRequestModel):Observable<ResponseModel>{
  let url = "api/app/stockreport/getallscannedrfidbarcode";
  return this.service.post(url,getallrfidScannedRequestModel, true).pipe<ResponseModel>(
    tap((response: any) => {
      return response;
    })
  );
}

deleteRFIDStock(data : any):Observable<ResponseModel>{
  let url = "api/app/stockreport/deletetrackingrfidbarcode";
  return this.service.post(url,data, true).pipe<ResponseModel>(
    tap((response: any) => {
      return response;
    })
  );
};

//metal fixing start 
insertUpdateMetalfix(metalFixInsertUpdateRequestModel : MetalFixInsertUpdateRequestModel):Observable<ResponseModel>{
  let url = "api/app/metalfixing/insertupdatemetalfixing";
  const json = JSON.stringify(metalFixInsertUpdateRequestModel);
  console.log(json);
  return this.service.post(url,metalFixInsertUpdateRequestModel, true).pipe<ResponseModel>(
    tap((response: any) => {
      return response;
    })
  );
};



getMetalFixingByInvoiceId(invoiceId:number ,voucherTypeId:number){
  const url = `api/app/metalfixing/getmetalfixingbyinvoiceid?invoiceId=${invoiceId}&voucherTypeId=${voucherTypeId}`;
  return this.service.get(url ,true).pipe<ResponseModel>(
    tap((response: any) => {
      return response;
    })
  );
}

getsaleinvoicebyid(data :PrintSaleInvoice):Observable<ResponseModel>{
    let url = "api/app/saleinvoice/getsaleinvoicebyid";
    return this.service.post(url,data, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  };

  getscrapinvoicebyid(data :PrintSaleInvoice):Observable<ResponseModel>{
    let url = "api/app/scrapinvoice/getscrapinvoicebyid";
    return this.service.post(url,data, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  };

  getsaleiorderbyid(data :PrintSaleInvoice):Observable<ResponseModel>{
    let url = "api/app/saleorder/getsaleorderbyid";
    return this.service.post(url,data, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  };
  getjobworkinvoicebyid(data :PrintSaleInvoice):Observable<ResponseModel>{
    let url = "api/app/Jobworkinvoice/getJobworkinvoicebyid";
    return this.service.post(url,data, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  };
  getconsignmentoutbyid(data :PrintSaleInvoice):Observable<ResponseModel>{
    let url = "api/app/ConsignmentOut/getPrintConsignmentOutById";
    return this.service.post(url,data, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  };
  getcontravoucherbyid(data :PrintSaleInvoice):Observable<ResponseModel>{
    let url = "api/app/contravoucher/getcontravoucherbyid";
    return this.service.post(url,data, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  };
  
//metal fixing end

// Ageing report
getStockAgeingReport(data):Observable<ResponseModel>{
  let url = ``;
  const json = JSON.stringify(data);
  return this.service.post(url,data,true).pipe<ResponseModel>(
    tap((response: any) => {
      return response;
    })
  )
}

getBalanceAgeingReport(data):Observable<ResponseModel>{
  let url = `api/ageingreport/getallageingreport`;
  const json = JSON.stringify(data);
  return this.service.post(url,data,true).pipe<ResponseModel>(
    tap((response: any) => {
      return response;
    })
  )
}
// Ageing report end

//Whatsapp API Start
getCampaignWhatsapp(data):Observable<ResponseModel>{
  let url = `api/app/whatsappcampaign/getallwhatsappcampaign`;
  const json = JSON.stringify(data);
  return this.service.post(url,data,true).pipe<ResponseModel>(
    tap((response: any) => {
      return response;
    })
  )
}
//Whatsapp API End
printInvoices(voucherTypeId,id)
{
    this.transactionService.getInvoiceById(voucherTypeId,id,true).subscribe({
      next:(res)=>{
       this.handleResponcePrint(res);
      },error:(err)=>{
      }
    })
}

handleResponceRFIDPrint(res, isBarcodePrint = false, isBase64 = false)
{
  if (this.ipcService.isRunningInElectron) {
  var printerSetting = this.storageService.retrieve(StorageName.PRINTER_SETTING);
      console.log(JSON.stringify(printerSetting))
      if (this.ipcService.isRunningInElectron) {
        var printerName = isBarcodePrint == true ? printerSetting.barcodePrinterName : printerSetting.invoicePrinterName;
        var isSilentPrint = isBarcodePrint == true ? printerSetting.isBarcodePrint : printerSetting.isDirectPrint;
        this.ipcService.send('print-label', { isSilentPrint : isSilentPrint,  printerName : printerName , response : res });
        this.ipcService.on('printFinished', (event, data) => {
          this.toastrService.success("Print Finished");
        });
      }
    }
}

isBase64Image(str: string | null | undefined): string | undefined {
  if (str == null || str.trim() === "") {
    return environment.baseUrl; // Return base URL if the input is null, undefined, or an empty string
  }

  // Check if the string starts with a data URI prefix for an image
  if (/^data:image\/[a-zA-Z]*;base64,/.test(str)) {
    return ''; // Return undefined if it's a base64-encoded image
  }

  return environment.baseUrl; // Return base URL for other cases
}

handleResponcePrint(res, isBarcodePrint = false, isBase64 = false)
{
  if(res.isSuccess==false)
    this.notificationService.errorPopup(res.errors[0])
  else
  {
    if(res?.response==null)
      {
      this.notificationService.errorPopup("Please set print (GO TO SETTINGS => SET SOFTWARE => RDLC DESIGN)");
      return ;
      }
    if(res?.response?.includes('.pdf'))
    {
      if (this.ipcService.isRunningInElectron) 
      {
        var url = this.isBase64Image(this.isBase64Image(res?.response))
        this.ipcService.send('print-pdf', url+res?.response);
      }
      else this.openPrintPreviewPopUp(res?.response);
    }
      else
      {
            // const decodedHtml = this.decodeHtml(res?.response);
            // console.log(printerSetting)
            var printerSetting = this.storageService.retrieve(StorageName.PRINTER_SETTING);
            console.log(JSON.stringify(printerSetting))
            if (this.ipcService.isRunningInElectron) {
              var printerName = isBarcodePrint == true ? printerSetting.barcodePrinterName : printerSetting.invoicePrinterName;
              var isSilentPrint = isBarcodePrint == true ? printerSetting.isBarcodePrint : printerSetting.isDirectPrint;
              this.ipcService.send('getPrint', { isSilentPrint : isSilentPrint,  printerName : printerName , response : res?.response });
              this.ipcService.on('printFinished', (event, data) => {
                this.toastrService.success("Print Finished");
            });
        } else {
          if (!isBarcodePrint)
            this.openPrintPreviewPopUp("", res?.response);
          else
            this.toastrService.success("Print Finished");

          //this.openPrintDialog(res?.response,isBase64);
        }
      }
  }
}

openPrintDialog(htmlContent, isBase64 = false) {
  console.log(htmlContent.toString)
  if(htmlContent == undefined || htmlContent == null || htmlContent == "") {
    this.notificationService.errorPopup("Please set print <br>(GO TO SETTINGS => SET SOFTWARE => RDLC DESIGN)");
  } else {
    // Create a hidden iframe
    const iframe = document.createElement('iframe');
    iframe.style.display = 'none';
    document.body.appendChild(iframe);

    // Set iframe content
    const iframeDocument = iframe.contentWindow?.document;
    if (iframeDocument) {
      iframeDocument.open();
      iframeDocument.write(htmlContent);
      iframeDocument.close();
      // Wait for the iframe to load its content before triggering print
      const waitForLoad = setInterval(() => {
        if (iframe.contentWindow?.document.readyState === 'complete') {
          clearInterval(waitForLoad);
          iframe.contentWindow?.print();
          document.body.removeChild(iframe); // Clean up by removing the iframe
        }
      }, 100);
    } else {
      console.error('Failed to open print window');
    }
  }
}

decodeHtml(html: string): string {
  // Create a new textarea element
  const textarea = document.createElement('textarea');
  // Set its HTML content to the encoded HTML string
  textarea.innerHTML = html;
  // The textContent property of the textarea will contain the decoded HTML
  return textarea.textContent || '';
}

openPrintPreviewPopUp(resourceFileUrl:string = "", html : string= ""){
  const modalOption: NgbModalOptions = {
    ...commonModalOptions,
    // size            : "xl",
    // centered        : true,
    windowClass     : "print-popup"

  };
  const modalRef = this.modalService.open(PrintPreviewComponent, modalOption);
  modalRef.componentInstance.printHtml =  html;
  modalRef.componentInstance.printUrl =  resourceFileUrl;
  modalRef.result.then((result) => {
    if (result) {

    }
  });
}

getPrintDiamondCertificate(data :PrintDiamondCertificate):Observable<ResponseModel>{
  let url = "api/app/stockreport/printdiamondcertificate";
  return this.service.post(url,data, true).pipe<ResponseModel>(
    tap((response: any) => {
      return response;
    })
  );
};

getPrintJobworkOrderById(data :PrintSaleInvoice):Observable<ResponseModel>{
  let url = "api/app/JobWorkOrder/getJobworkOrderById";
  return this.service.post(url,data, true).pipe<ResponseModel>(
    tap((response: any) => {
      return response;
    })
  );
};

getPrintReceiptVoucherById(data :PrintSaleInvoice):Observable<ResponseModel>{
  let url = "api/app/ReceiptVoucher/getReceiptVoucherById";
  return this.service.post(url,data, true).pipe<ResponseModel>(
    tap((response: any) => {
      return response;
    })
  );
};
getPrintPurchaseFixingById(data :PrintSaleInvoice):Observable<ResponseModel>{
  let url = "api/app/PurchaseFixing/getPurchaseFixingById";
  return this.service.post(url,data, true).pipe<ResponseModel>(
    tap((response: any) => {
      return response;
    })
  );
};
getPrintSaleFixingById(data :PrintSaleInvoice):Observable<ResponseModel>{
  let url = "api/app/SaleFixing/getSaleFixingById";
  return this.service.post(url,data, true).pipe<ResponseModel>(
    tap((response: any) => {
      return response;
    })
  );
};
getPrintPaymentVoucherById(data :PrintSaleInvoice):Observable<ResponseModel>{
  let url = "api/app/PaymentVoucher/getPaymentVoucherById";
  return this.service.post(url,data, true).pipe<ResponseModel>(
    tap((response: any) => {
      return response;
    })
  );
};
getPrintJournalVoucherById(data :PrintSaleInvoice):Observable<ResponseModel>{
  let url = "api/app/JournalVoucher/getJournalVoucherById";
  return this.service.post(url,data, true).pipe<ResponseModel>(
    tap((response: any) => {
      return response;
    })
  );
};

updaterfidtag(data : any):Observable<ResponseModel>{
  let url = "api/app/stockreport/updaterfidtag";
  return this.service.post(url,data, true).pipe<ResponseModel>(tap((response: any) => {
  return response;
    })
  );
}

getPurchaseInvoiceById(id:number){
  let url = 'api/app/Purchaseinvoice/getPurchaseinvoicebyid';
  const data = id
  return this.service.post(url,data,false).pipe<ResponseModel>(tap((response: any) => {
    if(response.isSuccess == true)
    {
      this.storageService.getInvoice.next(response);

      this.storageService.setSelect2Dropdown.next(response.response?.invoiceModel?.accountLedgerId?.toString())
    }
    return response;
    })
  );
}


// ********Stock Report end*******


// ********Day Report start*******

getallDayBookReport(getallDayReportRequestModel : GetallDayReportRequestModel):Observable<ResponseModel>{
  let url = "api/app/DayBook/getAllDayBook";
  return this.service.post(url,getallDayReportRequestModel, true).pipe<ResponseModel>(
    tap((response: any) => {
      return response;
    })
  );
}
// ********Day Report end*******

// ********Day Report start*******

getallSaleOrderList(data : any):Observable<ResponseModel>{
  let url = API_URL_SALEORDER_PROCESS_LIST;
  return this.service.post(url,data, true).pipe<ResponseModel>(tap((response: any) => {
  return response;
    })
  );
}


// ********Day Report end*******

// ********Account Ledger Report start*******
getAccountLedgerReportAmount(data : any):Observable<ResponseModel>{
  let url = "api/app/accountledgerReport/getAccountLedgerReport";
  return this.service.post(url,data, true).pipe<ResponseModel>(
    tap((response: any) => {
      return response;
    })
  );
}

getAccountLedgerReportMetal(data : any):Observable<ResponseModel>{
  let url = "api/app/accountledgerReport/getAccountLedgerReportMetal";
  return this.service.post(url,data, true).pipe<ResponseModel>(
    tap((response: any) => {
      return response;
    })
  );
}
// ********Account Ledger Report end*******


// ******** Account Ledger Detail Info Start *******
getAccountLedgerDetailInfo(data : any):Observable<ResponseModel>{
  let url = "api/app/accountledgerreport/getaccountledgerdetailbyledgerid";
  return this.service.post(url,data, true).pipe<ResponseModel>(
    tap((response: any) => {
      return response;
    })
  );
}
// ******** Account Ledger Detail Info end *******

// Barcode Stock apis Start

deleteBarcodeTracking(): Observable<ResponseModel> {
  let url =`api/app/barcodesetting/deletebarcodetracking`
  return this.service.delete(url, true).pipe<ResponseModel>(
    tap((response: any) => {
      return response;
    })
  );
}

deleteBarcodeStock(data : any): Observable<ResponseModel> {
  let url =`api/app/stockreport/deletebarcode`
  return this.service.post(url,data, true).pipe<ResponseModel>(
    tap((response: any) => {
      return response;
    })
  );
}

getallBarcodeStock(getallBarcodestockRequestModel : GetallBarcodestockRequestModel):Observable<ResponseModel>{
  let url = "api/app/stockreport/getallbarcodestock";
 const json= JSON.stringify(getallBarcodestockRequestModel);
  return this.service.post(url,getallBarcodestockRequestModel, true).pipe<ResponseModel>(
    tap((response: any) => {
      return response;
    })
  );
}

printBarcodeStock(barcodeTrackingRequestModel : BarcodeTrackingRequestModel):Observable<ResponseModel>{
  let url = "api/app/stockreport/printbarcode";
  return this.service.post(url,barcodeTrackingRequestModel, true).pipe<ResponseModel>(
    tap((response: any) => {
      return response;
    })
  );
}

getJobworkOrders(data : any):Observable<ResponseModel>{
  let url = API_URL_JOBWORK_ORDER_LIST;
  return this.service.post(url,data, true).pipe<ResponseModel>(tap((response: any) => {
  return response;
    })
  );
}

getVoucherTypeText(value: string): string | undefined {
  const enumKeys = Object.keys(VoucherType).filter(k => typeof VoucherType[k as any] === 'string');
  const enumValues = enumKeys.map(k => VoucherType[k as any]);

  const index = this.getVoucherTypeName(enumValues[parseInt(value)-1]);
  return index;
}

getVoucherTypeName(value: any): string | undefined {
  if(value == 'SaleInvoice')
    value = 'SalesInvoice';
  const enumKeys = Object.keys(VoucherTypeName).filter(k =>  k === value);
  const enumValues = enumKeys.map(k => VoucherTypeName[k as any]);

  const index = enumValues[0];
  return index;
}

getAllKycReport(data:any):Observable<ResponseModel>{
  let url = "api/app/kyc/getkycreport";
  return this.service.post(url,data,true).pipe<ResponseModel>(
    tap((response: any) => {
      return response;
    })
  );
}
// getByIdkyc data
getByIdKycReport(id:any):Observable<ResponseModel>{
  let url = "api/app/kyc/getattacheddcoumentbyledgerid";
  return this.service.post(url,id,true).pipe<ResponseModel>(
    tap((response: any) => {
      return response;
    })
  );
}


//#region  navigate to invoices ;
//#endregion  navigate to invoices ;
navigateToInvoice(voucherTypeId: number, id: number,stockJournalTypeId? : number, againstVoucherTypeId = undefined, againstVoucherNo = undefined ,isItemAdded?:boolean,againstInvoiceNo?:number) {
  var voucherTypeName = this.getVoucherTypeText(voucherTypeId.toString());
  if(againstVoucherTypeId != null && againstVoucherTypeId != 0 && againstVoucherTypeId != undefined)
  {
    voucherTypeName = this.getVoucherTypeText(againstVoucherTypeId);
    id = againstVoucherNo;
  }

  switch (voucherTypeName) {
    case StorageName.FORMNAME_TRANSACTION_PURCHASEFIXING:
      this.storageService.store("PurchaseFixingId", id);
      this.router.navigate(['Transaction/purchase-fixing']);
      break;
    case StorageName.FORMNAME_TRANSACTION_SALEFIXING:
      this.storageService.store("SaleFixingId", id);
      this.router.navigate(['Transaction/sale-fixing']);
      break;
    case StorageName.FORMNAME_TRANSACTION_PURCHASEORDER:
      this.storageService.store("PurchaseOrderId", id);
      this.router.navigate(['Transaction/purchase-order']);
      break;
    case StorageName.FORMNAME_TRANSACTION_CREDITNOTE:
      this.storageService.store("creditnoteId", id);
      this.router.navigate(['Transaction/credit-note']);
      break;
    case StorageName.FORMNAME_TRANSACTION_DEBITNOTE:
      this.storageService.store("debitnoteId", id);
      this.router.navigate(['Transaction/debit-note']);
      break;
    case StorageName.FORMNAME_TRANSACTION_PAYMENTVOUCHER:
      this.storageService.store("PaymentVoucherId", id);
      this.router.navigate(['Transaction/payment-voucher']);
      break;
    case StorageName.FORMNAME_TRANSACTION_RECEIPTVOUCHER:
      this.storageService.store("ReceiptVoucherId", id);
      this.router.navigate(['Transaction/receipt-voucher']);
      break;
    case StorageName.FORMNAME_TRANSACTION_MATERIALIN:
      this.storageService.store("MaterialInId", id);
      this.router.navigate(['Transaction/material-in']);
      break;
    case StorageName.FORMNAME_TRANSACTION_MATERIALOUT:
      this.storageService.store("MaterialOutId", id);
      this.router.navigate(['Transaction/material-out']);
      break;
    case StorageName.FORMNAME_TRANSACTION_PURCHASEINVOICE:
      this.storageService.store("PurchaseInvoiceId", id);
      this.router.navigate(['Transaction/purchase-invoice']);
      break;
    case StorageName.FORMNAME_TRANSACTION_SALESORDER:
      this.storageService.store("SaleOrderId", id);
      this.router.navigate(['Transaction/sale-order']);
      break;
    case StorageName.FORMNAME_TRANSACTION_SALESINVOICE:
      this.storageService.store("SaleInvoiceId", id);
      this.router.navigate(['Transaction/saleinvoice']);
      break;
    case "Stock Journal":
      this.storageService.store("StockJournalId", id);
      this.storageService.store("StockJournalTypeId", stockJournalTypeId);
      if(isItemAdded){
        this.storageService.store("againstInvoiceNo", againstInvoiceNo);
        this.router.navigate(['/Transaction/stock-journal-2']);
      }else{
        this.router.navigate(['/Transaction/stock-journal']);
      }
      break;
    case StorageName.FORMNAME_TRANSACTION_JOURNALVOUCHER:
      this.storageService.store("JournalVoucherId", id);
      this.router.navigate(['/Transaction/journal-voucher']);
      break;
    case StorageName.FORMNAME_TRANSACTION_CONTRAVOUCHER:
      this.storageService.store("ContraVoucherId", id);
      this.router.navigate(['/Transaction/contra-voucher']);
      break;
    case StorageName.FORMNAME_TRANSACTION_OLDJEWELRY_SCRAPINVOICE:
      this.storageService.store("ScrapInvoiceId", id);
      this.router.navigate(['Transaction/old-jewelry-scrap-invoice']);
      break;
    
      case StorageName.FORMNAME_TRANSACTION_JOBWORK_ORDER:
        this.storageService.store(StorageName.STORED_JOBWORK_ORDERID, id);
        this.router.navigate(['Transaction/jobwork-order']);
        break;
      case StorageName.FORMNAME_TRANSACTION_JOBWORKINVOICE:
      this.storageService.store(StorageName.STORED_JOBWORK_INVOICEID, id);
      this.router.navigate(['Transaction/jobwork-invoice']);
      break;
      case StorageName.FORMNAME_TRANSACTION_STOCKJOURNALMOVEMENT:
        this.storageService.store(StorageName.STORED_STOCKJOURNAL_MOVEMENT, id);
        this.storageService.store("transactionstockJournalMovement", TransactionStockJournalTabs.movement);
        this.router.navigate(['Transaction/stock-journal']);
        break;
      case StorageName.FORMNAME_ORDERS_REPAIRORDER:
        this.storageService.store("RepairOrderId", id);
        this.router.navigate(['Orders/repair-order']);
        break;
      case StorageName.FORMNAME_TRANSACTION_REPAIRINVOICE:
        this.storageService.store(StorageName.STORED_TRANSACTION_REPAIRINVOICEID, id);
        this.router.navigate(['Transaction/repair-invoice']);
      break;
      case StorageName.FORMNAME_TRANSACTION_MATERIAL_ISSUE:
        this.storageService.store(StorageName.STORED_MATERIAL_ISSUEID, id);
        this.router.navigate(['Transaction/material-issue']);
      break;
      case StorageName.FORMNAME_TRANSACTION_MATERIAL_RECEIVE:
        this.storageService.store(StorageName.STORED_MATERIAL_RECEIVEID, id);
        this.router.navigate(['Transaction/material-receive']);
      break;
      case StorageName.FORMNAME_INVENTORY_PHYSICALSTOCK:
        this.storageService.store("PhysicalStockId", id);
        this.router.navigate(['Inventory/physical-stock']);
      break;
      case StorageName.FORMNAME_TRANSACTION_CONSIGNMENTOUT:
        this.storageService.store("ConsignmentOutId", id);
        this.router.navigate(['Inventory/consignment-out']);
      break;
      case StorageName.FORMNAME_TRANSACTION_CONSIGNMENTIN:
        this.storageService.store("ConsignmentInId", id);
        this.router.navigate(['Inventory/consignment-in']);
      break;
      case StorageName.FORMNAME_TRANSACTION_SALES_QUOTATION:
        this.storageService.store(StorageName.STORED_TRANSACTION_SALESQUOTATION_ID, id);
        this.router.navigate(['Transaction/estimate']);
        break; 
        case StorageName.FORMNAME_TRANSACTION_DELIVERY_NOTE:
        this.storageService.store(StorageName.STORED_TRANSACTION_DELIVERYNOTE_ID, id);
        this.router.navigate(['Transaction/deliverynote']);
        break; 
        case StorageName.FORMNAME_TRANSACTION_SALESRETURN:
        this.storageService.store("SaleReturnInvoiceId", id);
        this.router.navigate(['Transaction/sale-return']);
        break; 
        case StorageName.FORMNAME_TRANSACTION_PURCHASERETURN:
          this.storageService.store("PurchaseReturnInvoiceId", id);
          this.router.navigate(['Transaction/purchase-return']);
          break; 
          case StorageName.FORMNAME_TRANSACTION_EXPENSES:
            this.storageService.store("ExpenseInvoiceId", id);
            this.router.navigate(['Transaction/expenses']);
            break; 
            case StorageName.FORMNAME_TRANSACTION_INVESTMENTFUND:
              this.storageService.store("InvestmentFundInvoiceId", id);
              this.router.navigate(['Transaction/investment-fund']);
              break; 
  }
}




isDisableButtonPermissionWise(voucherTypeName , checkPermissionFor) {
  switch (voucherTypeName) {
    case StorageName.FORMNAME_TRANSACTION_PURCHASEFIXING:

      break;
    case StorageName.FORMNAME_TRANSACTION_SALEFIXING:
     
      break;
    case StorageName.FORMNAME_TRANSACTION_PURCHASEORDER:
      if(checkPermissionFor == CheckPermissionFor.CanDelete) return this.sendDataService?.permissionVM?.purchaseOrderCanDelete;
      if(checkPermissionFor == CheckPermissionFor.CanView) return this.sendDataService?.permissionVM?.purchaseOrderCanView;
      break;
    case StorageName.FORMNAME_TRANSACTION_CREDITNOTE:
      if(checkPermissionFor == CheckPermissionFor.CanDelete) return this.sendDataService?.permissionVM?.creditNoteCanDelete;
      if(checkPermissionFor == CheckPermissionFor.CanView) return this.sendDataService?.permissionVM?.creditNoteCanView;
      break; 
    case StorageName.FORMNAME_TRANSACTION_DEBITNOTE:
      if(checkPermissionFor == CheckPermissionFor.CanDelete) return this.sendDataService?.permissionVM?.debitNoteCanDelete;
      if(checkPermissionFor == CheckPermissionFor.CanView) return this.sendDataService?.permissionVM?.debitNoteCanView;
      break; 
    case StorageName.FORMNAME_TRANSACTION_PAYMENTVOUCHER:
      if(checkPermissionFor == CheckPermissionFor.CanDelete) return this.sendDataService?.permissionVM?.paymentVoucherCanDelete;
      if(checkPermissionFor == CheckPermissionFor.CanView) return this.sendDataService?.permissionVM?.paymentVoucherCanView;
      break;
    case StorageName.FORMNAME_TRANSACTION_RECEIPTVOUCHER:
      if(checkPermissionFor == CheckPermissionFor.CanDelete) return this.sendDataService?.permissionVM?.receiptVoucherCanDelete;
      if(checkPermissionFor == CheckPermissionFor.CanView) return this.sendDataService?.permissionVM?.receiptVoucherCanView;
      break;
    case StorageName.FORMNAME_TRANSACTION_MATERIALIN:
      if(checkPermissionFor == CheckPermissionFor.CanDelete) return this.sendDataService?.permissionVM?.materialInCanDelete;
        if(checkPermissionFor == CheckPermissionFor.CanView) return this.sendDataService?.permissionVM?.materialInCanView;
        break;
    case StorageName.FORMNAME_TRANSACTION_MATERIALOUT:
      if(checkPermissionFor == CheckPermissionFor.CanDelete) return this.sendDataService?.permissionVM?.materialOutCanDelete;
      if(checkPermissionFor == CheckPermissionFor.CanView) return this.sendDataService?.permissionVM?.materialOutCanView;
      break;
    case StorageName.FORMNAME_TRANSACTION_PURCHASEINVOICE:
      if(checkPermissionFor == CheckPermissionFor.CanDelete) return this.sendDataService?.permissionVM?.purchaseInvoiceCanDelete;
        if(checkPermissionFor == CheckPermissionFor.CanView) return this.sendDataService?.permissionVM?.purchaseInvoiceCanView;
      break;
    case StorageName.FORMNAME_TRANSACTION_SALESORDER:
      if(checkPermissionFor == CheckPermissionFor.CanDelete) return this.sendDataService?.permissionVM?.salesOrderCanDelete;
      if(checkPermissionFor == CheckPermissionFor.CanView) return this.sendDataService?.permissionVM?.salesOrderCanView;
      break;
    case StorageName.FORMNAME_TRANSACTION_SALESINVOICE:
      if(checkPermissionFor == CheckPermissionFor.CanDelete) return this.sendDataService?.permissionVM?.salesInvoiceCanDelete;
      if(checkPermissionFor == CheckPermissionFor.CanView) return this.sendDataService?.permissionVM?.salesInvoiceCanView;
      break;
    case "Stock Journal":
      if(checkPermissionFor == CheckPermissionFor.CanDelete) return this.sendDataService?.permissionVM?.stockJournalOption1CanDelete;
      if(checkPermissionFor == CheckPermissionFor.CanView) return this.sendDataService?.permissionVM?.stockJournalOption1CanView;
      break;
    case StorageName.FORMNAME_TRANSACTION_JOURNALVOUCHER:
      if(checkPermissionFor == CheckPermissionFor.CanDelete) return this.sendDataService?.permissionVM?.journalVoucherCanDelete;
      if(checkPermissionFor == CheckPermissionFor.CanView) return this.sendDataService?.permissionVM?.journalVoucherCanView;
      break;
      break;
    case StorageName.FORMNAME_TRANSACTION_CONTRAVOUCHER:
      if(checkPermissionFor == CheckPermissionFor.CanDelete) return this.sendDataService?.permissionVM?.contraVoucherCanDelete;
      if(checkPermissionFor == CheckPermissionFor.CanView) return this.sendDataService?.permissionVM?.contraVoucherCanView;
      break;
    case StorageName.FORMNAME_TRANSACTION_OLDJEWELRY_SCRAPINVOICE:
      if(checkPermissionFor == CheckPermissionFor.CanDelete) return this.sendDataService?.permissionVM?.oldJewelryScrapInvoiceCanDelete;
      if(checkPermissionFor == CheckPermissionFor.CanView) return this.sendDataService?.permissionVM?.oldJewelryScrapInvoiceCanView;
      break;
      case StorageName.FORMNAME_TRANSACTION_JOBWORK_ORDER:
        if(checkPermissionFor == CheckPermissionFor.CanDelete) return this.sendDataService?.permissionVM?.jobworkOrderCanDelete;
        if(checkPermissionFor == CheckPermissionFor.CanView) return this.sendDataService?.permissionVM?.jobworkOrderCanView;
        break;
      case StorageName.FORMNAME_TRANSACTION_JOBWORKINVOICE:
        if(checkPermissionFor == CheckPermissionFor.CanDelete) return this.sendDataService?.permissionVM?.jobworkInvoiceCanDelete;
        if(checkPermissionFor == CheckPermissionFor.CanView) return this.sendDataService?.permissionVM?.jobworkInvoiceCanView;
      break;
      case StorageName.FORMNAME_TRANSACTION_STOCKJOURNALMOVEMENT:
       
        break;
      case StorageName.FORMNAME_ORDERS_REPAIRORDER:
        if(checkPermissionFor == CheckPermissionFor.CanDelete) return this.sendDataService?.permissionVM?.repairOrderCanDelete;
        if(checkPermissionFor == CheckPermissionFor.CanView) return this.sendDataService?.permissionVM?.repairOrderCanView;
        break;
      case StorageName.FORMNAME_TRANSACTION_REPAIRINVOICE:
        if(checkPermissionFor == CheckPermissionFor.CanDelete) return this.sendDataService?.permissionVM?.repairInvoiceCanDelete;
        if(checkPermissionFor == CheckPermissionFor.CanView) return this.sendDataService?.permissionVM?.repairInvoiceCanView;
        break;
      case StorageName.FORMNAME_TRANSACTION_MATERIAL_ISSUE:
        if(checkPermissionFor == CheckPermissionFor.CanDelete) return this.sendDataService?.permissionVM?.materialIssueCanDelete;
        if(checkPermissionFor == CheckPermissionFor.CanView) return this.sendDataService?.permissionVM?.materialIssueCanView;
        break;
      case StorageName.FORMNAME_TRANSACTION_MATERIAL_RECEIVE:
        if(checkPermissionFor == CheckPermissionFor.CanDelete) return this.sendDataService?.permissionVM?.materialReceiveCanDelete;
        if(checkPermissionFor == CheckPermissionFor.CanView) return this.sendDataService?.permissionVM?.materialReceiveCanView;
        break;
      case StorageName.FORMNAME_INVENTORY_PHYSICALSTOCK:
        if(checkPermissionFor == CheckPermissionFor.CanDelete) return this.sendDataService?.permissionVM?.physicalStockCanDelete;
        if(checkPermissionFor == CheckPermissionFor.CanView) return this.sendDataService?.permissionVM?.physicalStockCanView;
      break;
      case StorageName.FORMNAME_INVENTORY_CREATEMEMO:
      break;
      case StorageName.FORMNAME_TRANSACTION_CONSIGNMENTOUT:
        if(checkPermissionFor == CheckPermissionFor.CanDelete) return this.sendDataService?.permissionVM?.consignmentOutCanDelete;
        if(checkPermissionFor == CheckPermissionFor.CanView) return this.sendDataService?.permissionVM?.consignmentOutCanView;
      break;
      case StorageName.FORMNAME_TRANSACTION_CONSIGNMENTIN:
        if(checkPermissionFor == CheckPermissionFor.CanDelete) return this.sendDataService?.permissionVM?.consignmentInCanDelete;
        if(checkPermissionFor == CheckPermissionFor.CanView) return this.sendDataService?.permissionVM?.consignmentInCanView;
      break;
      case StorageName.FORMNAME_TRANSACTION_SALES_QUOTATION:
        if(checkPermissionFor == CheckPermissionFor.CanDelete) return this.sendDataService?.permissionVM?.salesQuotationCanDelete;
        if(checkPermissionFor == CheckPermissionFor.CanView) return this.sendDataService?.permissionVM?.salesQuotationCanView;
      break; 
      case StorageName.FORMNAME_TRANSACTION_DELIVERY_NOTE:
        if(checkPermissionFor == CheckPermissionFor.CanDelete) return this.sendDataService?.permissionVM?.deliveryNoteCanDelete;
        if(checkPermissionFor == CheckPermissionFor.CanView) return this.sendDataService?.permissionVM?.deliveryNoteCanView;
      break; 
      case StorageName.FORMNAME_TRANSACTION_SALESRETURN:
        if(checkPermissionFor == CheckPermissionFor.CanDelete) return this.sendDataService?.permissionVM?.salesReturnCanDelete;
        if(checkPermissionFor == CheckPermissionFor.CanView) return this.sendDataService?.permissionVM?.salesReturnCanView;
        break;
      case StorageName.FORMNAME_TRANSACTION_PURCHASERETURN:
        if(checkPermissionFor == CheckPermissionFor.CanDelete) return this.sendDataService?.permissionVM?.purchaseReturnCanDelete;
        if(checkPermissionFor == CheckPermissionFor.CanView) return this.sendDataService?.permissionVM?.purchaseReturnCanView;
        break; 
      case StorageName.FORMNAME_TRANSACTION_EXPENSES:
        if(checkPermissionFor == CheckPermissionFor.CanDelete) return this.sendDataService?.permissionVM?.expensesCanDelete;
        if(checkPermissionFor == CheckPermissionFor.CanView) return this.sendDataService?.permissionVM?.expensesCanView;
        break; 
      case StorageName.FORMNAME_TRANSACTION_INVESTMENTFUND:
        if(checkPermissionFor == CheckPermissionFor.CanDelete) return this.sendDataService?.permissionVM?.investmentFundCanDelete;
        if(checkPermissionFor == CheckPermissionFor.CanView) return this.sendDataService?.permissionVM?.investmentFundCanView;
        break; 
  }
}


//Report old-jewellery-scrap start

getalloldjewellery(data ):Observable<ResponseModel>{
  let url = "api/app/oldjewelleryscrap/getoldjewelleryscrap";
  const json = JSON.stringify(data);
  return this.service.post(url,data, true).pipe<ResponseModel>(
    tap((response: any) => {
      return response;
    })
  );
}

getOldJewelleryScrapRefine(data):Observable<ResponseModel>{
  let url = "api/app/oldjewelleryscrap/getoldjewelleryscraprefine";
  const json = JSON.stringify(data);
  return this.service.post(url,data, true).pipe<ResponseModel>(
    tap((response: any) => {
      return response;
    })
  );
}

getAllOldJewelryScrapReceived(data ):Observable<ResponseModel>{
  let url = "api/app/oldjewelleryscrap/getAllOldJewelryScrapReceived";
  const json = JSON.stringify(data);
  return this.service.post(url,data, true).pipe<ResponseModel>(
    tap((response: any) => {
      return response;
    })
  );
}



getAllManufacturingDepartmentReport(data:GetAllDepartmentReportRequestModel):Observable<ResponseModel>{
  let url = "api/app/manufacturingDepartment/GetAllManufacturingDepartmentReport";
  return this.service.post(url,data, true).pipe<ResponseModel>(
    tap((response: any) => {
      return response;
    })
  );
}


updatescrapinvoiceitemstatus(data:AddDataToRefine):Observable<ResponseModel>{
  let url = "api/app/scrapinvoice/updatescrapinvoiceitemstatus";
  const json = JSON.stringify(data);
  return this.service.put(url,data,true).pipe<ResponseModel>(
    tap((response: any) => {
      return response;
    })
  );
} 

//Report old-jewellery-scrap end
getWhatappDetail(data :Whatsapp):Observable<ResponseModel>{
  let url = "api/app/notification/whatappnotification";
  return this.service.post(url,data, true).pipe<ResponseModel>(
    tap((response: any) => {
      return response;
    })
  );
};

}