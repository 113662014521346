<app-load-dropdown-data [formName]="storageName.FORMNAME_BUSINESSREGISTRATION" (fillDropdownData)="fillDropdownData($event)"></app-load-dropdown-data>

<div class="container-fluid d-flex ps-5 pe-0 flex-column pt-3 h-100">
  <form class="theme-form" ngNativeValidate #UserForm="ngForm">
    <div class="row" style="gap: 4rem;">
      <div class="col-5 pe-0">
        <div class="row">
          <div class="col-12 pe-0 pb-3">
            <div class="row">
                <div class="w-135 col-xl-3 col-lg-5 col-md-5">
                  <span class="f-14 font-semibold text-light text-nowrap mb-0">First Name <span class="text-color-danger">*</span></span>
                </div>
                <div class="col pe-0">
                  <app-input
                    onlyAlphabetsAndNumbers
                    [inputName]="'firstName'"
                    [inputType]="'text'"
                    [inputId]="'firstName'"
                    [inputValue]="usermodel.firstname"
                    (inputValueEmit)="usermodel.firstname = $event"
                    [isRequired]="true"
                    (keydown.enter)="onKeydown($event, 'lastNameId')"
                    [ngClass]="{'border-red' : ((UserForm?.form?.controls?.firstName?.touched || UserForm?.form?.controls?.firstName?.dirty) && UserForm?.form?.controls?.firstName?.invalid)}">
                  </app-input>
                </div>
            </div>
          </div>
          <!-- end f name -->
          <div class="col-12 pe-0 pb-3">
            <div class="row">
              <div class="w-135 col-xl-3 col-lg-5 col-md-5">
                <span class="f-14 font-semibold text-light text-nowrap mb-0">
                  Last Name <span class="text-color-danger">*</span>
                </span>
              </div>
              <div class="col pe-0">
                <app-input
                  [inputName]="'lastName'"
                  [inputType]="'text'"
                  [inputId]="'lastNameId'"
                  [inputValue]="usermodel.lastname"
                  (inputValueEmit)="usermodel.lastname = $event"
                  [isRequired]="true"
                  (keydown.enter)="onKeydown($event, 'dateOfBirth')"
                  [ngClass]="{'border-red' : ((UserForm?.form?.controls?.lastName?.touched || UserForm?.form?.controls?.lastName?.dirty) && UserForm?.form?.controls?.lastName?.invalid)}">
                </app-input>
              </div>
            </div>
          </div>
          <!-- end l name -->
          <div class="col-12 pe-0 pb-3">
            <div class="row">
              <div class="w-135 col-xl-3 col-lg-5 col-md-5">
                <span class="f-14 font-semibold text-light text-nowrap mb-0">Date Of Birth</span>
              </div>
              <div class="col pe-0">
                <app-input-datepicker-renderer 
                  [id]="'dateOfBirth'" 
                  (dateChanged)="selectedDates($event)"
                  (keydown.enter)="onKeydown($event, 'contactNoId')">
                </app-input-datepicker-renderer>
                <!-- <app-input
                  [inputName]="'dateOfBirth'"
                  [inputType]="'date'"
                  [inputId]="'dateOfBirth'"
                  [inputValue]="usermodel.dob"
                  (inputValueEmit)="usermodel.dob = $event"
                  (keydown.enter)="onKeydown($event, 'contactNoId')">
                </app-input> -->
              </div>
            </div>
          </div>
          <!-- end dob -->
          <div class="col-12 pe-0 pb-3">
            <div class="row">
              <div class="w-135 col-xl-3 col-lg-5 col-md-5">
                <span class="f-14 font-semibold text-light text-nowrap mb-0">
                  Contact No.
                </span>
              </div>
              <div class="col pe-0">
                <app-input-with-select-dropdown
                [inputName]="'contactNo'" 
                [inputMaxlength]="15"
                [inputType]="'text'" 
                [inputId]="'contactNoId'"
                [name]="'dialcodename'"
                [options]="getAllDropdownModel?.dialCodeDropdown"
                [inputValue]="usermodel?.contactNo" 
                (inputValueEmit)="usermodel.contactNo = $event"
                [selectedDialCodeValue]="usermodel?.dialCode"
                (dialCodeValueChanged)="usermodel.dialCode = $event"
                [selectClass]="'white-color ms-0'"
                (keydown.enter)="onKeydown($event, 'EmailNoId')"
                ></app-input-with-select-dropdown>
              </div>
            </div>
          </div>
          <!-- end contact -->
          <div class="col-12 pe-0 pb-3">
            <div class="row">
              <div class="w-135 col-xl-3 col-lg-5 col-md-5">
                <span class="f-14 font-semibold text-light text-nowrap mb-0">Email<span class="text-color-danger">*</span></span>
              </div>
              <div class="col pe-0">
                <app-input
                  [inputName]="'EmailNo'"
                  [inputType]="'email'"
                  [inputId]="'EmailNoId'"
                  [inputValue]="usermodel?.email"
                  (inputValueEmit)="usermodel.email = $event"
                  [isRequired]="true"
                  [isEmailPattern]="true"
                  [ngClass]="{'input-border-red' : ((UserForm?.form?.controls?.EmailNo?.touched || UserForm?.form?.controls?.EmailNo?.dirty) && UserForm?.form?.controls?.EmailNo?.invalid)}"
                  (keydown.enter)="onKeydown($event, 'UserNameId')">
                </app-input>
                <div *ngIf="((UserForm?.form?.controls?.EmailNo?.touched || UserForm?.form?.controls?.EmailNo?.dirty) && UserForm?.form?.controls?.EmailNo?.invalid)" class="text-yellow font-regular f-11 position-absolute">Please enter a valid email address</div>
              </div>
            </div>
          </div>
        </div>
        <!-- end row -->
      </div>
      <!-- end first col-5 -->
      <div class="col-5 pe-0">
        <div class="row">
          <div class="col-12 pe-0 pb-3">
            <div class="row">
              <div class="w-135 col-xl-3 col-lg-5 col-md-5">
                <span class="f-14 font-semibold text-light text-nowrap mb-0">User Name
                  <span class="text-color-danger">*</span>
                </span>
              </div>
              <div class="col pe-0">
                <app-input
                  [inputName]="'UserName'"
                  [inputType]="'text'"
                  [inputId]="'UserNameId'"
                  [inputValue]="usermodel.username"
                  (inputValueEmit)="usermodel.username = $event"
                  [isRequired]="true"
                  (keydown.enter)="onKeydown($event, 'password')"
                  [ngClass]="{'border-red' : !usermodel.username &&  submitUser}">
                </app-input>
              </div>
            </div>
          </div>
          <!-- end user name -->
          <div class="col-12 pe-0 pb-3">
            <div class="row">
              <div class="w-135 col-xl-3 col-lg-5 col-md-5">
                <span class="f-14 font-semibold text-light text-nowrap mb-0">
                  Password
                  <span class="text-color-danger">*</span>
                </span>
              </div>
              <div class="col pe-0">
                <app-input
                  [isEyeIconShow]="true"
                  [inputName]="'password'"
                  [inputType]="'password'"
                  [inputId]="'password'"
                  [inputValue]="usermodel.password"
                  (inputValueEmit)="usermodel.password = $event"
                  [isRequired]="true"
                  (keydown.enter)="onKeydown($event, 'confirmPassWord')"
                  [ngClass]="{'border-red' : ((UserForm?.form?.controls?.password?.touched || UserForm?.form?.controls?.password?.dirty) && UserForm?.form?.controls?.password?.invalid)}">
                </app-input>
              </div>
            </div>
          </div>
          <!-- end password name -->
          <div class="col-12 pe-0 pb-3">
            <div class="row">
              <div class="w-135 col-xl-3 col-lg-5 col-md-5">
                <span class="f-14 font-semibold text-light text-nowrap mb-0">
                  Confirm Password
                  <span class="text-color-danger">*</span>
                </span>
              </div>
              <div class="col pe-0">
                <app-input
                  [isEyeIconShow]="true"
                  [inputName]="'confirmPassword'"
                  [inputType]="'password'"
                  [inputId]="'confirmPassword'"
                  [inputValue]="confirmPassWord"
                  (inputValueEmit)="confirmPassWord = $event"
                  [isRequired]="true"
                  [ngClass]="{'border-red' : ((UserForm?.form?.controls?.confirmPassword?.touched || UserForm?.form?.controls?.confirmPassword?.dirty) && UserForm?.form?.controls?.confirmPassword?.invalid)}">
                </app-input>
              </div>
              <span *ngIf="UserForm.form?.controls?.confirmPassword?.touched" style="margin-left:138px;">
                <span class="f-11 text-light" *ngIf="usermodel.password !== confirmPassWord">
                  Confirm passwords do not match.
                </span>
              </span>
            </div>
          </div>
          <div class="col-12 f-12 text-red font-regular text-end text-underline cursorPointer" (click)="generateRandumPassword()">
            Generate Random Password
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
