<!-- <app-custom-loader  [isLoading]="isLoading"></app-custom-loader> -->
<app-load-dropdown-data [formName]="formName" (fillDropdownData)="getAllDropdownData()"></app-load-dropdown-data> 
<div class="container-fluid d-flex flex-column p-0 px-3 px-sm-0" [ngClass]="[isHeightFull?'fullheight':'h-100']">
  <div class="modal-header d-flex align-items-center justify-content-center position-relative" *ngIf="hide_Header">
    <div><span class="font-semibold text-primary">Add Product</span></div>
    <app-svg-icon [name]="'cross_button_border'" class="cross-button-border"></app-svg-icon>
    <app-svg-icon [name]="'cross'" class="cross-button" [ngClass]="currentHoverEffect" (click)="activeModal.close(productNameObj)"></app-svg-icon>
  </div>
  <div class="row gap-2 my-2 mx-0 flex-fill product_container">
      <div class="w-20 h-100 m-0 page-size-dropdown px-0 product_list">
        <div class="card h-100 d-flex flex-column m-0">
          <div class="row mx-0">
            <div class="col-12 px-0">
              <div class="card-header px-2 py-1 d-flex justify-content-between align-items-center">
                <div class="cursorPointer m-t-1">
                  <div class="d-flex align-items-center justify-content-end f-13">
                    <div class="col add_Export_dropdown position-relative export_dropdown_hover" > 
                      <app-export-dropdown [requestModel]="productListModel" [gridApi]="[gridApiProductOpening]" [gridName]="[storageName.PRODUCT_PRODUCTOPENING_PRODUCTLIST_GRID]" (callApiForExport)="callApiForExport()"></app-export-dropdown>
                    </div>
                </div>
                </div>
                <div class="d-flex align-items-center justify-content-end f-13 gap-1">
                  <div *ngIf="fileName" class="d-flex">
                    <div class="d-block text-center my-auto text-primary text-truncate font-regular me-2 ps-1 " [title]="fileName" style="max-width: 50px;">{{fileName}}</div>
                    <div >
                      <app-svg-icon name="importFile" class="cursorPointer" (click)="uploadFile()"></app-svg-icon>
                    </div>
                  </div>
                  <div class="col New_product_dropdown position-relative">
                    <div ngbDropdown class="d-inline-block add_parties">
                      <button type="button" class="btn w-100" id="dropdownBasic1" ngbDropdownToggle>
                        &nbsp;
                        &nbsp;
                      </button>
                      <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                        <div class="form-check d-flex" ngbDropdownItem>
                          <div class="d-flex align-items-center"><app-svg-icon name="import" class="cursorPointer" ></app-svg-icon></div>
                          <input #importFile type="file" id="import" name="import" (change)="onFileSelected($event)" class="d-none" accept=".xls,.xlsx">
                          <label for="import" class="d-flex align-items-center mb-0 f-12 ps-1 font-regular cursorPointer color-dark-gray mb-0">
                              Import
                          </label>
                      </div>
                      <div class="form-check d-flex" ngbDropdownItem (click)="downloadSampleFile()">
                          <div class="d-flex align-items-center"><app-svg-icon name="importsample" class="cursorPointer" ></app-svg-icon></div>
                          <!-- <input #importSampleFile type="file" id="sample" name="sample" (change)="onFileSelected($event)" class="d-none "> -->
                          <label for="sample" class="d-flex align-items-center mb-0 f-12 ps-1 cursorPointer font-regular color-dark-gray mb-0 f-12">
                              Sample
                          </label>
                      </div>
                      </div>
                    </div>
                    <div (click)="clearDetails()" class="position-absolute start-0 top-0 New_product_button">
                      <button type="button" class="btn  p-0" id="newProduct">
                        <span>
                          <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 16.875 16.875">
                          <path id="Union_4" data-name="Union 4" d="M7.438-1a.938.938,0,0,1,.937.938V6.5h6.563a.937.937,0,1,1,0,1.875H8.375v6.563a.937.937,0,1,1-1.875,0V8.375H-.062A.938.938,0,0,1-1,7.438.938.938,0,0,1-.062,6.5H6.5V-.062A.938.938,0,0,1,7.438-1Z" transform="translate(1 1)" fill="#fff"/>
                          </svg>  
                        </span>
                        <span>
                          New Product
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row flex-column h-100 mx-0">
            <div class="col p-0 flex-fill h-0 mb-3 position-relative">
              <app-ag-grid 
                [dataModel]="agGridDataModelProductList" 
                [tableRowCellEditable]="false" 
                (onTextCellClick) = "handleTextClick($event)"
                (gridReady)="onGridProdutListReady($event)"
                (onDeleteIconClick)="deleteIconClicked($event)"
                >
                <!-- (onPagination)="onPagination($event)" -->
              </app-ag-grid>
            </div>
          </div>
        </div>
      </div>
      <div class="col h-100 ms-1 p-0">
        <form autocomplete="off" ngNativeValidate #ProductOpeningForm="ngForm" class="flex-fill d-flex flex-column h-100 p-0">
          <div class="card d-flex flex-column mb-3 product_deatails">
            <div class="row m-0">
                <div class="col-12 pt-2">
                  <div class="row">
                    <div class="col-12 mt-1 mb-2 d-flex flex-column">
                      <div class="col d-flex ">
                        <div class="col-8 pe-3">
                          <div class="col-12 mb-2 gap-3 d-flex">
                            <div class="col">
                              <h5 class="text-primary font-semibold f-16 mb-0">
                                Product Details
                              </h5>
                            </div>
                            <div class="col d-flex justify-content-end gap-2">
                              <div *ngIf="makeCopy" class="">
                                <app-button class="" (buttonOutputEvent)="makeCopyProduct()"  [buttonLabel]="'Make Copy'" [buttonClass]="'blue_button'" [buttonType]="'button'">
                                </app-button>
                              </div>
                              <div>
                                <!-- <app-button class="" [buttonLabel]="'Save'" [buttonClass]="'blue_button'" [buttonType]="'button'"
                                  (buttonOutputEvent)="openSavePopUp()">
                                </app-button> -->
                                <app-button 
                                  [ngbTooltip]="sendDataService?.noPermissionTooltip"
                                  [disableTooltip]="(productOpening?.productModel?.id ? sendDataService?.permissionVM?.productOpeningCanUpdate : sendDataService?.permissionVM?.productOpeningCanAdd)"
                                  [disabled]="!(productOpening?.productModel?.id ? sendDataService?.permissionVM?.productOpeningCanUpdate : sendDataService?.permissionVM?.productOpeningCanAdd)"
                                  class="" 
                                  [buttonLabel]="'Save'" 
                                  [buttonClass]="'blue_button'" 
                                  [buttonId]="'save_invoice'" 
                                  [buttonType]="'submit'"
                                  (buttonOutputEvent)="openSavePopUp()">
                              </app-button>
                              </div>
                            </div>
                          </div>
                          <div class="col-12 d-flex">
                            <div class="col-6 pe-3">
                              <div class="col d-flex align-items-center mb-2">
                                <div class="">
                                  <label for="productName"
                                    class="f-14 font-semibold text-gray-color text-nowrap mb-0 w-75px">
                                    Name<span class="text-color-danger">*</span>
                                  </label>
                                </div>
                                <div class="col">
                                  <app-input 
                                    [inputName]="'productName'" 
                                    [isRequired]="true"
                                    [inputType]="'text'"
                                    [inputId]="'productName'" 
                                    [inputValue]="productOpening?.productModel?.name"
                                    (inputValueEmit)="productOpening.productModel.name = $event"
                                    [isRequired]="true"
                                    (keydown.enter)="onKeydown($event, 'alternateName')"
                                    >
                                  </app-input>
                                </div>
                              </div>
                              <div class="col-12 d-flex align-items-center mb-2">
                                <div class="">
                                  <label for="category"
                                    class="f-14 font-semibold text-gray-color text-nowrap mb-0 w-75px">Category</label>
                                </div>
                                <div class="col gap-2 d-flex">
                                  <div class="col">
                                    <app-select 
                                      [options]="getAllDropdownModel?.categoryDropdown" 
                                      [selectedValue]="productOpening.productModel.categoryId"
                                      (valueChanged)="productOpening.productModel.categoryId = $event"
                                      [id]="'category'" 
                                      [name]="'category'"
                                      [isDisabled]="false" 
                                      (keydown.enter)="onKeydown($event, 'decimalPoint')">
                                    </app-select>
                                  </div>
                                  <div class="d-flex align-items-center cursorPointer justify-content-end">
                                    <app-svg-icon name="plus_circle" (click)=" addCategory()"></app-svg-icon>
                                  </div>
                                </div>
                              </div>
                            </div>                            
                            <div class="col-6">
                              <div class="col-12 d-flex align-items-center mb-2">
                                <div class="">
                                  <label for="alternateName"
                                    class="f-14 font-semibold text-gray-color text-nowrap mb-0 w-120px">Alternate
                                    Name
                                  </label>
                                </div>
                                <div class="col">
                                  <app-input 
                                    [inputName]="'alternateName'" 
                                    [inputType]="'text'"
                                    [inputId]="'alternateName'" 
                                    [inputValue]="productOpening?.productModel?.alternateName"
                                    (inputValueEmit)="productOpening.productModel.alternateName = $event"
                                    [isRequired]="false"
                                    (keydown.enter)="onKeydown($event, 'category')"
                                    >
                                  </app-input>
                                </div>
                              </div>
                              <div class="col-12 d-flex align-items-center mb-2">
                                <div class="">
                                  <label for="Decimal Point"
                                    class="f-14 font-semibold text-gray-color text-nowrap mb-0 w-120px">Decimal
                                    Pt.
                                  </label>
                                </div>
                                <div class="col">
                                  <app-input
                                    class="custom-number-input" 
                                    [isRequired]="false"
                                    onlyNumbers 
                                    [inputName]="'decimalPoint'" 
                                    [inputType]="'number'"
                                    [inputId]="'decimalPoint'" 
                                    [inputValue]="productOpening?.productModel?.decimalPoint"
                                    (inputValueEmit)="productOpening.productModel.decimalPoint = $event"
                                    >
                                  </app-input>
                                </div>
                              </div>
                            </div>
                          </div> 
                          <div class="row mx-0 mb-2 p-0">
                            <div class="col p-0 gap-3 d-flex justify-content-between align-items-center">
                              <div class="col px-0 d-flex align-items-center">
                                <div class="">
                                  <label for="branch"
                                    class="f-14 font-semibold text-gray-color text-nowrap mb-0 w-75px">Branch<span class="text-color-danger">*</span></label>
                                </div>
                                <div class="col d-flex">
                                  <div class="col">
                                    <app-ng-multi-select-dropdown
                                    [isShowlable]="false" 
                                    [isPlusIcon]="true"
                                    [name]="'branchId'"  
                                    [getAllDropdownModel]="getAllDropdownModel" 
                                    [selectedIds]="branchIds"
                                    [data]="getAllDropdownModel?.branchDropdown" 
                                    [placeholder]="'Select Branch'"
                                    [id]="'branchId'" 
                                    (onSelectItem)="productOpening?.productModel.branchIds=$event"
                                    (keydown.enter)="onKeydown($event, 'contactNo')"
                                    (openModelPopup)="openBranchPopup()"                            
                                   >
                                    </app-ng-multi-select-dropdown>
                                  </div>
                                </div>
                              </div> 
                              <div class="w-25px d-flex align-items-center justify-content-end">
                                <app-checkbox  
                                  [status]="productOpening.productModel.showInStock"
                                  (check)="productOpening.productModel.showInStock= $event" 
                                  ></app-checkbox>
                                <span (click)="this.productOpening.productModel.showInStock = !this.productOpening.productModel.showInStock;" class="text-primary cursorPointer f-12 font-semibold ps-2">Show In Stock</span>
                              </div>      
                            </div>
                          </div>
                        </div>
                        <div class="col-4">
                          <div id="Tax_Table" class="h-100">
                            <app-ag-grid 
                            [dataModel]="agGridDataModelTaxListTable"
                            [rowData]="taxList" 
                            [gridOptions]="gridOptions"
                            (gridReady)="onGridProductTaxReady($event)"
                            (gridApiEmit)="taxGridApi = $event">
                            </app-ag-grid>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
            </div> 
          </div>
          <div class="card col d-flex flex-column m-0 product_deatails">
              <h5 class="text-primary font-semibold px-2 ms-1 py-2 f-16 mb-0">
                Product Characteristics
              </h5>
            <!-- <div class="row m-0"> 
              <div class="col list-tabs">
                <ul class="nav nav-tabs border-tab nav-primary mb-0 px-0 pt-2 gap-md-3 d-flex" id="nav-tab"
                    role="tablist">
                    <li class="nav-item">
                        <a href="javascript:void(0)" (click)="tabChanged(tabId.PRODUCT_CHARACTERISTICS)"
                        [ngClass]="{'active show': openTab == tabId.PRODUCT_CHARACTERISTICS}" class="nav-link px-1"
                            id="All">Product Characteristics</a>
                    </li>
                    <li class="nav-item px-2 text-truncate">
                        <a href="javascript:void(0)" 
                        class="nav-link" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true"
                        (click)="tabChanged(tabId.MANUFACTURING)"
                        [ngClass]="{'active show': openTab == tabId.MANUFACTURING}" class="nav-link px-1"
                            >Manufacturing </a>
                    </li>
                </ul>
              </div>
            </div> -->
            <ng-container class="tab-content"  id="nav-tabContent">
              <div *ngIf="openTab == tabId.PRODUCT_CHARACTERISTICS" class="row flex-fill Product_Characteristics">
                <div class="d-flex flex-fill px-4 flex-column tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                  <div class="col-12 position-relative flex-fill m-0 mb-3 h-0 ">
                    <app-ag-grid 
                      [dataModel]="agGridDataModelProductCharacteristics" 
                      [gridOptions]="gridOptionsProductCharacteristics" 
                      [columnSettings]="true"
                      (gridReady)="onGridProductCharacteristicsReady($event)"
                      (cellValueChanged)="onCellValueChange($event)"
                      (onTextCellClick) = "onTextCellClick($event)"
                      >
                    </app-ag-grid>
                    <!-- [rowData]="productOpening.productDetailModel" -->
                  </div>
                </div>
              </div>
            </ng-container> 
          </div>
        </form>
      </div>
      
  </div>
  <!-- <hr class="mt-1 mb-2" *ngIf="openTab ==tabId.MANUFACTURING">
  <div class="row mx-0 p-0 mb-2">
    <div class="col-12 d-flex p-0">
      <ng-container *ngIf="openTab ==tabId.MANUFACTURING" > 
        <div class="col-7 d-flex justify-content-end my-1">
          <app-button class="" [buttonLabel]="'Publish To Jewellery Catalog'" [buttonClass]="'pink_button'" [buttonType]="'button'">
          </app-button>
        </div>
      </ng-container> 
    </div>
  </div>  -->
</div>