import { Component, OnInit, QueryList, Renderer2, ViewChild, ViewChildren } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AdministrationService } from 'src/app/components/administration/administration.service';
import { CommonService } from '../../services/common.service';
import { ToastrService } from 'ngx-toastr';
import { catchError } from 'rxjs';
import { GetAllCollectionResponseModel } from 'src/app/components/administration/administration.model';
import { SendDataService } from '../../services/send-data.service';

@Component({
  selector: 'app-collection',
  templateUrl: './collection.component.html',
  styleUrls: ['./collection.component.scss']
})
export class CollectionComponent extends CommonService implements OnInit {
  isAdd                           : boolean = false;
  getAllCollectionData              : any[]=[];

  copyGetAllCollectionData          : any[]=[];
  editCollectionData                = new GetAllCollectionResponseModel();
  @ViewChild('administrationFormData') administrationFormData! : NgForm ;
  
constructor(
  public renderer:Renderer2,
  public administrationService : AdministrationService,
  public toaster: ToastrService,
  public sendDataService : SendDataService
) 
{
  super(renderer)
}


ngOnInit(): void {
  this.getAllCollectionList();
  }

add(){
if(!this.sendDataService?.permissionVM?.mastersCanAdd) return;
  this.isAdd = true;
    const obj = {id:0, name:'',description:'', active:true}
    this.getAllCollectionData?.push(obj);
    this.copyGetAllCollectionData?.push(obj);
    setTimeout(()=>{
      const element = document.getElementById(`collectionname${obj.id}`);
      element?.focus();
    },0)
}


cancel(){
  // console.log('fdf',this.editQualityData);
  if(this.isAdd)
  {
    this.isAdd = false;
    this.getAllCollectionData = this.getAllCollectionData.filter((x)=>x.id != 0);
    this.copyGetAllCollectionData = this.copyGetAllCollectionData.filter((x)=>x.id != 0);
  }
  else if(this.editCollectionData?.id && this.editCollectionData?.isEdit){
    const index = this.copyGetAllCollectionData.findIndex((x)=>x.id == this.editCollectionData?.id);
    const singleData =JSON.parse(JSON.stringify(this.copyGetAllCollectionData))[index];
    this.getAllCollectionData[index] = singleData;
    this.editCollectionData = new GetAllCollectionResponseModel();
  }  
}


edit(item,formData){
if(!this.sendDataService?.permissionVM?.mastersCanUpdate) return;
  item.isEdit = true;
  const controlObj = formData?.form?.controls;
  if(controlObj){
    const id = Object.keys(controlObj)[0];
    const element = document.getElementById(id);
    element?.focus();
  }
  this.editCollectionData = {...item};
}


checkIsEdit(arr:any[]):boolean{
  return arr?.some((x)=>x.isEdit)
}

  // *********** Collection API START *********
    // **********get all Collection *********
    getAllCollectionList() {
      this.administrationService.getAllCollection().subscribe({
        next:(res)=>{
          if(res.isSuccess){
            this.getAllCollectionData = res.response??[];
            this.editCollectionData = new GetAllCollectionResponseModel();
            this.copyGetAllCollectionData = JSON.parse(JSON.stringify(this.getAllCollectionData))??[];
          }else{           
            this.getAllCollectionData = []; 
            this.copyGetAllCollectionData = [];
          }  
        },
        error:(err)=>{
          this.getAllCollectionData = [];
          this.copyGetAllCollectionData = [];
        }
      })
    }
  
    // *************insert Collection **************
      InserCollectionList(item) {
        const {id , ...rest}= item;
        this.administrationService.insertCollection(rest)
            .pipe(catchError((err) => this.handleError(err)))
            .subscribe((res) => {
              if(res.isSuccess){
                this.isAdd = false;
                this.getAllCollectionList();
                this.toaster.success(res.message);
              }else{
                this.toaster.error(res.errors[0]);
              }  
          });
      }
    
    // **********update Collection *********
    UpdateCollectionList(item) {
      const {isEdit , ...rest} = item;
      this.administrationService.updateCollection(rest)
          .pipe(catchError((err) => this.handleError(err)))
          .subscribe((res) => {
            if(res.isSuccess){
              this.isAdd = false;
              this.getAllCollectionList();
              this.toaster.success(res.message);
            }else{
              this.toaster.error(res.errors[0]);
            }  
        });
      } 

      // **********delete Collection *********
      DeleteCollectionList(id) {
      if(!this.sendDataService?.permissionVM?.mastersCanDelete) return;
        this.administrationService.deleteCollection(id)
            .pipe(catchError((err) => this.handleError(err)))
            .subscribe((res) => {
              if(res.isSuccess){
                this.getAllCollectionList();
                this.toaster.success(res.message);
              }else{
                this.toaster.error(res.errors[0]);
              }  
        });
      }

  
}
