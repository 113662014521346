<app-load-dropdown-data [formName]="storageName.FORMNAME_ADMINISTRATION_MASTERS_BRANCHES" (fillDropdownData)="fillDropdownData()"></app-load-dropdown-data>
<div class="d-flex flex-column h-100 pb-2">
    <div class="row mx-0 gap-2 d-flex flex-column h-100">
        <div class="col h-0 flex-fill p-0">
            <app-ag-grid 
            id="MasterTabs" 
            class="all_aggrid_tables" 
            [dataModel]="agGridDataModelBranchesList"
            [rowData]="BranchesListRowData"
            [columnSettings]="true"
            (gridReady)="onGridReady($event)"
             (onTextCellClick) = "handleTextClick($event)"
            [gridOptions]="gridOptionsBranches" 
            (onDeleteIconClick)="onDeleteIconClick($event)"
            >
            </app-ag-grid>
        </div>
    </div>
    <hr class="mt-2 border-gray-1">
</div>

