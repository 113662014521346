import { Component, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { StorageName } from 'src/app/shared/enum/common-enum';
import { CommonService } from 'src/app/shared/services/common.service';
import { SendDataService } from 'src/app/shared/services/send-data.service';
import { StorageService } from 'src/app/shared/services/storage.service';
import { GetAllCompanyQueryResponse } from './company-list.model';
import { CompanyListService } from './company-list.service';
import { LayoutDetails } from 'src/app/auth/auth/auth.model';
import { Location } from '@angular/common';
import { LayoutService } from 'src/app/shared/services/layout.service';

@Component({
  selector: 'app-company-list',
  templateUrl: './company-list.component.html',
  styleUrls: ['./company-list.component.scss']
})
export class CompanyListComponent extends CommonService implements OnInit{
  formName = StorageName.FORMNAME_COMPANY_LIST;
  selectedIndex
  getAllCompanyQueryResponse: GetAllCompanyQueryResponse[]=[];
  subCompany  = new GetAllCompanyQueryResponse();
  parentCompany  = new GetAllCompanyQueryResponse();
  layoutDetails             = new LayoutDetails();
  constructor(
    private router : Router,
    private activatedRoute :ActivatedRoute,
    public storageService   :StorageService,
    public renderer : Renderer2,
    public toaster  : ToastrService,
    private sendDataService: SendDataService, 
    private companyListService: CompanyListService, 
    private location : Location,
    public layoutService    : LayoutService,
    )
    {
    super(renderer);
  }

  ngOnInit(): void {
    this.sendDataService.displayPageName(this.formName); 
    this.getAllCompanyListData(true);
     this.layoutDetails.dialCode = this.storageService.retrieve('layoutDetails')?.dialCode??'';
  }

  handleImageError(event: any) {
    event.target.src = this.layoutService?.displayDefaultCompanyIcon;
  }
  
  getAllCompanyListData(isOnInit){
    this.companyListService.getallcompanyListService().subscribe({
      next:(res)=>{
        if(res?.isSuccess && res?.response){
          this.getAllCompanyQueryResponse = res?.response;
          if(this.getAllCompanyQueryResponse?.length && isOnInit){
            this.getParentCompany();
          }
        }
      }
    })
  }
  
  close(){
    // this.router.navigate(['/Product/product-opening'],{relativeTo: this.activatedRoute });
    this.location.back();
  }

  navigateToNewCompany(){
    this.router.navigate(["add-company"])
  }

  navigateToEditProfile(item){
    this.router.navigate(["/add-update-profile"],{ queryParams: { id: item.id }})
  }

  ngOnDestroy(): void {
    this.sendDataService?.headerPageNameAndInvoiceNum?.next(undefined);
  }

  getParentCompany(){
    const obj = this.getAllCompanyQueryResponse?.find((x)=>x?.isMainCompany == true);
    this.parentCompany = obj;
  }

  assignToSubCompany(item , index){
    this.selectedIndex = index;
    this.subCompany = JSON.parse(JSON.stringify(item));
  }
  deleteCompany(id){
    const data:any = { id: id };
    this.companyListService.deleteCompany(data).subscribe({
      next:(res=>{
        if(res.isSuccess){
          this.toaster.success(res.message);
          this.getAllCompanyListData(true);  
          this.subCompany = undefined;   
           }else{
            this.toaster.error(res.message);
           }
      }),error:()=>{

    }})
  }
  
}


