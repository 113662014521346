import { Directive, ElementRef, HostListener, Input, OnChanges, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[AppZeroToOneNumber]',
})
export class ZeroToOneNumberDirective implements OnChanges {
  @Input('isBlureApplicable') isBlureApplicable : boolean =false;
  private regex: RegExp;
  private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', '-', 'ArrowLeft', 'ArrowRight', 'Del', 'Delete'];

  constructor(private el: ElementRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    // Allow only a single digit between 0 and 1 (including decimal values)
    this.regex = new RegExp(/^0?(\.\d{0,1})?$/);
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (this.specialKeys.indexOf(event.key) !== -1) {
      return;
    }

    const current: string = this.el.nativeElement.value;
    const next: string = [
      current.slice(0, this.el.nativeElement.selectionStart),
      event.key,
      current.slice(this.el.nativeElement.selectionStart),
    ].join('');

    if (next && !String(next).match(this.regex)) {
      event.preventDefault();
    }
  }

  @HostListener('blur', ['$event'])
  onBlur(event: Event | any) {
    let value = this.el.nativeElement.value;
    if(this.isBlureApplicable && (!event?.target?.readOnly)){
        if (value !== '' && !isNaN(Number(value))) {
        value = Math.min(1, parseFloat(value)).toFixed(1); // Limit to a single digit between 0 and 1
        this.el.nativeElement.value = value;
        }
    }
  }

  @HostListener('focus', ['$event'])
  onFocus(event: FocusEvent | any): void {
    setTimeout(() => {
      if ((!event?.target?.readOnly) && (this.el?.nativeElement?.value) !== '' && !isNaN(Number(this.el?.nativeElement?.value))) {
        this.el?.nativeElement?.select();
      }
    }, 10);
  }

  
}

