<div class="modal-header d-flex align-items-center justify-content-center position-relative">
    <div><span class="font-semibold text-primary">Add Image</span></div>
    <app-svg-icon [name]="'cross_button_border'" class="cross-button-border"></app-svg-icon>
    <app-svg-icon [name]="'cross'" class="cross-button" (click)="activeModal.close(true)"></app-svg-icon>
</div>
<form ngNativeValidate #ImageForm="ngForm">
    <div class="modal-body AddImagePopUp mx-2">
        <div class="row pt-1 pb-3" >
            <div class="col-4 pe-0">  
                <div class="main_image">
                    <img class="img-fluid h-100 w-100 cursorPointer" [src]="isBase64Image(displayImage)" alt="previewImage" (click)="openImgPopUp()" onerror="this.src = './assets/svg/nopreviewImage.svg'">                      
                </div>
            </div>
            <div class="col-8">
                <div class="d-flex flex-wrap gap-3 ms-1">
                    <!-- <div class="child_image"> 
                        <input id="uploadchildImg" type="file" class="d-none" (change)="uploadChildImages($event)">
                        <label for="uploadchildImg" class="h-100 w-100 d-flex align-items-center justify-content-center cursorPointer">
                            <app-svg-icon [name]="'upload_Icon'"></app-svg-icon>
                        </label>                       
                    </div> 
                    <div class="child_image p-1" *ngFor="let img of imagesList;let i = index">
                        <span class="d-flex justify-content-end mb-1" (click)="removeImage(i)"> 
                            <img class="img-fluid cursorPointer" src="../../../../../assets/svg/cross.svg" height="10px" width="10px" alt="">
                        </span> 
                        <span class="d-flex align-items-center" (click)="showPreviewImage(img)"> 
                            <img class="cursorPointer " [src]="img?.base64Resource" height="68" width="90" alt="">   
                        </span>                   
                    </div>  -->
                    <div class="row m-0 p-0">
                        <div class="p-0 m-0 d-flex gap-3 overflow-auto flex-wrap child_item_img_container">
                            <div class="tabel-bg-color disabled border rounded child_image justify-content-center cursorPointer " ngx-dropzone [accept]="'image/*'" (change)="onSelect($event)">
                                <app-svg-icon class="w-50" [name]="'upload_Icon'"></app-svg-icon>
                            </div>
                            <div   *ngFor="let img of imagesList ;let i = index" class="child_image tabel-bg-color border rounded justify-content-center position-relative">
                                <img [src]="isBase64Image(img?.base64Resource)" alt="img" (click)="showPreviewImage(img)" class="w-100 h-100 cursorPointer" loading="lazy">
                                <div (click)="removeImage(i)" class="position-absolute top-0 end-0 m-1 cursorPointer cross-icon">
                                <app-svg-icon class="" [name]="'cross'"></app-svg-icon>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer pt-3 pb-2 mx-2">
        <app-button class="m-0" [buttonLabel]="'Save'" (buttonOutputEvent)="saveImages()" [buttonClass]="'blue_button'" [buttonType]="'submit'">
        </app-button>
    </div>
</form>