<div class="d-flex align-items-center gap-3">
    <h5 class="text-primary font-semibold f-14 mb-0 p-2 mt-1">
        Product List
    </h5>
    <ng-container *ngIf="itemSectionModel.showImportAndDownloadFile && showImportAndDownloadFile">
        <div>
            <app-button (click)="fileInput.click()" class="" [buttonLabel]="'Import'"  showUploadIconWhite="true" [buttonClass]="'blue_button'"
            [buttonType]="'button'" >
            </app-button>
            <input class="d-none" type="file" #fileInput (change)="onFileSelect($event)" accept=".xls,.xlsx"/>
        </div>
        <div class="ms-3 col d-flex justify-content-end" >
            <a (click)="downloadExcel()" class="text-primary font-semibold f-14 cursorPointer ">Download Sample File</a>
        </div>
    </ng-container>
</div>



<div class="item_list_table position-relative">
    <app-ag-grid     
        class="ag-theme-alpine" 
        [dataModel]="agGridDataModelItemList"
        [tableRowCellEditable]="false"
        [columnSettings]="true" 
        [rowHeight]="27"
        (onEditIconClick)="onEditIconClick($event)"
        (imgCellClicked)="imgCellClicked($event)"
        (barcodeCellClicked)="barcodeCellClicked($event)"
        (gridReady)="onGridReady($event)"
        (onDeleteIconClick)="onDeleteIconClick($event)"
        >
    </app-ag-grid>
</div>