import { Component, Input } from '@angular/core';
import {JobWorkStatus, OrdetTrackingStatus} from '../order-tracking.model'

@Component({
  selector: 'app-timeline-status-icon',
  templateUrl: './timeline-status-icon.component.html',
  styleUrls: ['./timeline-status-icon.component.scss']
})
export class TimelineStatusIconComponent {
  @Input() timelineStatusId: number;

  stageStatusEnum = OrdetTrackingStatus;
  jobWorkStatus = JobWorkStatus;
}
