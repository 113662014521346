<app-load-dropdown-data [formName]="formName"></app-load-dropdown-data>

<div class="modal-header d-flex align-items-center justify-content-center position-relative">
    <div><span class="font-semibold text-primary">Add Expenses Category</span></div>
    <app-svg-icon [name]="'cross_button_border'" class="cross-button-border"></app-svg-icon>
    <app-svg-icon [name]="'cross'" class="cross-button" (click)="activeModal.dismiss()"></app-svg-icon>
  </div>
  <form ngNativeValidate #ExpensesForm="ngForm">
    <div class="modal-body mx-2">
      <div class="row mb-1">
        <div class="col-4 font-semibold text-gray-color d-flex align-items-center">Name<span class="text-color-danger">*</span></div>
        <div class="col ps-0">
            <app-input 
            [inputName]="'carat'" 
            [inputId]="'carat'" 
            [inputType]="'text'" 
            [inputValue]="insertTaskTypeRequestModel.name"
            (inputValueEmit)="insertTaskTypeRequestModel.name=$event"
            [isRequired]="false"
            >
          </app-input>
        </div>
        <div class="col-2 d-flex align-items-center ps-0">
          <div class="col-9 f-14 font-semibold text-gray-color text-center text-nowrap ps-2 mb-0">
              Active
          </div>
          <div class="col d-flex justify-content-end align-items-center">
              <app-checkbox  [dyanamicId]="'active'" [isRequired]="false" [status]="insertTaskTypeRequestModel.active" (check)="insertTaskTypeRequestModel.active = $event"></app-checkbox>
          </div>
      </div>
      </div>
    </div>
    <div class="modal-footer pt-3 pb-2 mx-2">
      <app-button class="my-0 me-3" (buttonOutputEvent)="activeModal.close()" [buttonLabel]="'Close'" [buttonClass]="'white_button'" [buttonType]="'button'">
      </app-button>
      <app-button class="m-0" [buttonLabel]="'Save'" (buttonOutputEvent)="InserExpensesList()" [buttonClass]="'blue_button'" [buttonType]="'submit'">
      </app-button>
    </div>
  </form>