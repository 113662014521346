import { Component, OnInit ,Input, Renderer2, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { DomSanitizer, SafeHtml, SafeResourceUrl } from '@angular/platform-browser';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonService } from 'src/app/shared/services/common.service';
import { IpcRenderService } from 'src/app/shared/services/ipc-render.service';
import { IconHover } from '../../../enum/common-enum';

@Component({
  selector: 'app-print-preview',
  templateUrl: './print-preview.component.html',
  styleUrls: ['./print-preview.component.scss']
})
export class PrintPreviewComponent extends CommonService implements OnInit , AfterViewInit {
  currentHoverEffect = IconHover.Pink;
  @ViewChild('pdfIframe') pdfIframe: ElementRef;
  src: SafeResourceUrl;
  srcdoc: SafeHtml;
  @Input() printUrl = "";
  @Input() printHtml = "";
  // https://api.jewelsteps.com//Uploads/Invoices/1InvoiceTemplate1Report.pdf

  constructor(public activeModal : NgbActiveModal,private ipcService            : IpcRenderService,
    public  renderer              : Renderer2,
    private sanitizer: DomSanitizer){
    super(renderer);
  }


  ngOnInit(): void {
    if (!this.printHtml)
      this.src = this.sanitizer.bypassSecurityTrustResourceUrl(`${this.isBase64Image(this.printUrl)}`);
  }

  ngAfterViewInit(): void {
    if (this.printHtml != "" && this.printHtml != undefined && this.printHtml != null ) {
      this.loadHtmlContent(this.printHtml);
    }
  }

  private loadHtmlContent(htmlContent: string): void {
    const iframe = this.pdfIframe.nativeElement;
    iframe.style.display = 'none';

    const iframeDocument = iframe.contentWindow?.document;
    if (iframeDocument) {
      iframeDocument.open();
      iframeDocument.write(htmlContent);
      iframeDocument.close();

      const waitForLoad = setInterval(() => {
        if (iframe.contentWindow?.document.readyState === 'complete') {
          clearInterval(waitForLoad);
          iframe.style.display = 'block'; // Make iframe visible if needed
          iframe.contentWindow?.print();
        }
      }, 100);
    } else {
      console.error('Failed to open print window');
    }
  }


  bypassAndSanitize(url): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  printPdf() {
    
    // const iframe = this.pdfIframe.nativeElement;
    // iframe.focus();
    // iframe.contentWindow.print();

    const iframe = this.pdfIframe.nativeElement;
    iframe.contentWindow?.print();
    this.ipcService.send('print-pdf', iframe.src);
  }

}