import { Component, Input, NgZone, Renderer2 } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GridOptions } from 'ag-grid-community';
import { AgGridI } from 'src/app/shared/common-components/ag-grid/ag-grid.model';
import { CommonService } from 'src/app/shared/services/common.service';
import { catchError, Subscription } from 'rxjs';
import { AgGridService } from 'src/app/shared/common-components/ag-grid/ag-grid.service';
import { ResponseModel } from 'src/app/shared/models/response-model.model';
import { ToastrService } from 'ngx-toastr';
import { VoucherType } from 'src/app/shared/models/common.model';
import { AgGridSaleOrderList } from 'src/app/components/Transaction/invoice/invoice.model';
import { InvoiceService } from 'src/app/components/Transaction/invoice/invoice.service';

@Component({
  selector: 'app-sale-order-popup',
  templateUrl: './sale-order-popup.component.html',
  styleUrls: ['./sale-order-popup.component.scss']
})
export class SaleOrderPopupComponent extends CommonService{
  @Input() modalTitle            : string;
  @Input() accounLedgerId        : any;
  @Input()saleOrderNoDropdownFilterData :any;
  @Input()saleQuotationNoDropdownFilterData:any;
  @Input()deliveryNoteNoDropdownFilterData:any;
  agGridSaleOrderList            : AgGridI;
  gridOptions                    : GridOptions;
  gridApiSaleOrder               :any;
  agGridSaleOrderRowData         :any[]=[];
  saleOrdeList: any;
  requestIds=[];
  itemList = [];
  private onRadioButtonSubscription : Subscription | undefined;
  voucherdata: any;

  constructor(
    public activeModal  : NgbActiveModal,
    public renderer : Renderer2,
    public invoiceService : InvoiceService,
    public agGridService:AgGridService ,
    private zone: NgZone ,
    public toaster: ToastrService,
  ) {
    super(renderer);
    this.gridOptions = {
      singleClickEdit : true, // Add other grid options as needed
    };
    // this.onRadioButtonSubscription = this.agGridService.onRadioButtonChange$.subscribe((params:any) => {
    //   this.onRadioChange(params)
    // }); 
  
  }

  ngOnInit() : void {
    this.agGridSaleOrderList = AgGridSaleOrderList;
    const element = document.querySelector('.custom_focus') as any;
    element?.focus();
  }

 onKeyDown(event: KeyboardEvent | any) {
    event?.srcElement?.parentElement?.click();
  }

  onGridReady(event) {
    this.gridApiSaleOrder = event; 
    if (this.modalTitle == "Sale Order") {
    this.agGridSaleOrderRowData = this.saleOrderNoDropdownFilterData;
    } else if (this.modalTitle == "Estimate/Quotation") {
    this.agGridSaleOrderRowData = this.saleQuotationNoDropdownFilterData;
    } else if (this.modalTitle == "Delivery Note") {
    this.agGridSaleOrderRowData = this.deliveryNoteNoDropdownFilterData; 
    }
}

  onApply(){
   this.requestIds  =  this.gridApiSaleOrder?.api?.getModel()?.rowsToDisplay?.map(a => a?.data)?.filter((x)=>x?.active == true)?.map((y)=>y?.id);
  //  Not call Api if checkbox is not selected
   if (!this.requestIds || this.requestIds.length === 0) {
    this.activeModal.close(false);
    return;
   }
   if (this.modalTitle == "Sale Order") {
    this.voucherdata = VoucherType.SalesOrder;
} else if (this.modalTitle == "Estimate/Quotation") {
    this.voucherdata = VoucherType.SalesQuotation;
} else if (this.modalTitle == "Delivery Note") {
    this.voucherdata = VoucherType.DeliveryNote;
}
   const requestData ={ids:this.requestIds ,voucherType:this.voucherdata}
    this.invoiceService.getBySaleOrderId(requestData).subscribe({
        next:(res)=>{
          if(res?.isSuccess){
            this.itemList = res?.response?.itemList;
            // reset active checkbox
            this.gridApiSaleOrder?.api?.forEachNode((node) => {
              if (node?.data?.active) {
                 node.data.active = false;
                 this.gridApiSaleOrder?.api?.refreshCells({rowNodes: [node]});
              }
           });

            this.activeModal.close(res.isSuccess);
          }else{
            this.toaster.error(res.errors[0]);
            this.activeModal.close();
          }

        },error:(err)=>{
          console.log(err)
        }
    });
  }

  // onRadioChange(params): void {
  //   this.gridApiSaleOrder?.api?.deselectAll();
  //   params?.node?.setSelected(true);
  // }
}