
<div class="modal-header d-flex align-items-center justify-content-center position-relative">
    <div><span class="font-semibold text-primary">{{params?.displayName||'NA'}}</span></div>
    <app-svg-icon [name]="'cross_button_border'" class="cross-button-border"></app-svg-icon>
    <app-svg-icon [name]="'cross'"  [ngClass]="currentHoverEffect" class="cross-button" (click)="CloseFilterModel()"></app-svg-icon>
</div>
<form autocomplete="off"  class="theme-form" ngNativeValidate #FieldValueForm="ngForm">
    <div class="modal-body px-1 pb-0">
        <div class="col-12 d-flex gap-3">
            <app-input 
                [inputName]="'value'" 
                [inputType]="'text'" 
                [inputId]="'value'"
                [inputValue]="searchValue" 
                (inputValueEmit)="searchValue = $event"
                [isRequired]="false"
                >
                <!-- (keydown.enter)="onKeydown($event, 'branchId')" -->
            </app-input>
        </div>

        <div class="border d-flex flex-column m-t-10 mb-2 field-value-table">
          <div class="border-bottom header d-flex align-items-center gap-2 p-2 py-1 f-14 font-semibold text-gray-color">
            <app-checkbox [status]="data?.length == selectedData?.length" [dyanamicId]="'selectAll'" (check)="selectAll($event)"  [lableName]="'Select All'" ></app-checkbox>
          </div>
          <div class="flex-fill overflow-auto h-0">
            <div class="border-bottom p-2 py-1 f-14 d-flex align-items-center gap-2 font-regular text-gray-color d-flex field_value_display" *ngFor="let item of data | filter: searchValue; let i=index" >
                <app-checkbox [status]="check(item?.id)"  [dyanamicId]="'name'+i" (check)="add(item)" [lableName]="item?.name" ></app-checkbox>
            </div>
          </div>
        </div>
        
        <hr class="m-0">
        <div class="d-flex py-2  align-items-center justify-content-center cursorPointer text-primary gap-2 f-14 font-semibold">
            <app-button class="" [buttonLabel]="'Apply Filter'" (buttonOutputEvent)="applyFilter()" [buttonClass]="'blueText_white_bg_button'" [buttonType]="'button'">
            </app-button>
            <app-button class="" [buttonLabel]="'Clear Filter'" (buttonOutputEvent)="clearFilter()" [buttonClass]="'pink_button'" [buttonType]="'button'">
            </app-button>
            
        </div>
    </div>
</form>