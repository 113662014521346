import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-radio-button',
  templateUrl: './radio-button.component.html',
  styleUrls: ['./radio-button.component.scss']
})
export class RadioButtonComponent {
  @Input() id?:string;
  @Input() tabIndex?:number;
  @Input() name?:string;
  @Input() radioButtonValue:any;
  @Input() radioButtonLabel:string;
  @Input() status: any;
  @Input() isRequired: boolean;
   tabindex : number = 0;
  @Output() check:EventEmitter<boolean>=new EventEmitter() ;
  ngOnInit()
  {
    this.tabindex =  this.tabIndex ;
  }

}
