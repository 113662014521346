import { Component } from '@angular/core';
import { NgbDate, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { AgGridService } from '../ag-grid.service';

@Component({
  selector: 'app-datepicker-renderer',
  templateUrl: './datepicker-renderer.component.html',
  styleUrls: ['./datepicker-renderer.component.scss'],

})
export class DatepickerRendererComponent implements ICellEditorAngularComp {
  params:  ICellRendererParams ;

  constructor( public formatter: NgbDateParserFormatter , public agGridService:AgGridService){

  }
  refresh(params: ICellRendererParams<any, any>): boolean {
    return true;
   }

  agInit(params: any): void {
    this.params = params;
    this.params.value = this.formatter.parse(this.params.value);
    // this.formatDateValue();
    // this.params.node.setDataValue(this.params.colDef.field,  this.params.value );
  }

  getValue(): any {
    return this.params.value;
  }

  isPopup(): boolean {
    return false;
  }

  getPopupPosition(): any{
    return 'over';
  }

  onDateSelection(selectedDates: any): void {
    this.agGridService.onChangeSelectedDates(selectedDates , this.params)
    const newDate = selectedDates
    this.params.node.setDataValue(this.params.colDef.field, newDate);
  }


  close(event){
    console.log('close');
    this.params.api.stopEditing();
  }

  // private formatDateValue(): void {
  //   if (this.params.value) {
  //     this.params.value = this.formatter.parse(this.params.value);
  //   }
  // }
}
