
<div>
  <div class="modal-header d-flex align-items-center justify-content-center position-relative">
    <div><span class="font-semibold text-primary">{{modalTitle}}</span></div>
    <app-svg-icon [name]="'cross_button_border'" class="cross-button-border"></app-svg-icon>
    <app-svg-icon [name]="'cross'" [ngClass]="currentHoverEffect" class="cross-button" (click)="activeModal.dismiss()"></app-svg-icon>
  </div>
  <form autocomplete="off" class="theme-form" ngNativeValidate #TaxForm="ngForm">
    <div class="modal-body mx-2 padding-custom">
      <div class="row mb-065rem">
        <div class="col-4 font-semibold text-gray-color d-flex align-items-center">Tax Name<span class="text-color-danger">*</span></div>
        <div class="col-4 ps-0">
          <app-input 
          [inputName]="'taxName'" 
          [inputId]="'taxName'" 
          [inputType]="'text'" 
          [inputClass]="'ms-1'"
          [inputValue]="createUpdateTaxSettingRequestModel?.name"
          (inputValueEmit)="createUpdateTaxSettingRequestModel.name=$event"
          [isRequired]="true"
          (keydown.enter)="onKeydown($event, 'rate')"
          >
        </app-input>
        </div>
        <div class="col-1 font-semibold text-gray-color d-flex align-items-center ps-2">Rate<span class="text-color-danger">*</span></div>
        <div class="col-3 ">
          <app-input-with-icon 
          [notNagativeDecimalNumber]="true"
          [inputName]="'rate'" 
          [inputId]="'rate'" 
          [inputType]="'text'"
          [isPercentApplicable] ="true"
          (inputValueEmit)="createUpdateTaxSettingRequestModel.rate=$event" 
          [inputValue]="createUpdateTaxSettingRequestModel?.rate" 
          [isRequired]="true" 
          [isPercentIcon]="true"
          (keydown.enter)="onKeydown($event, 'applicableFor')"
          >
        </app-input-with-icon>
        </div>
      </div>
      <div class="row mb-065rem">
        <div class="d-flex align-items-center" [ngClass]="this.layoutDetails.countryCode === countryCodeEnum.IN?'col-8':'col-12'">
          <div class="font-semibold text-gray-color d-flex align-items-center" [ngClass]="this.layoutDetails.countryCode === countryCodeEnum.IN?'col-6':'col-4'">Applicable For<span class="text-color-danger">*</span></div>
          <div [ngClass]="this.layoutDetails.countryCode === countryCodeEnum.IN?'col-6':'col-8'">
              <app-select 
                [id]="'applicableFor'"
                [name]="'applicableFor'"
                [isDisabled]="false"
                [options]="getAllDropdownModel?.applicableOnDropdown"
                [selectedValue]="createUpdateTaxSettingRequestModel?.applicableOn"
                (valueChanged)="createUpdateTaxSettingRequestModel.applicableOn=$event"
                [isRequired]="true"
                [selectClass]="'ms-1'"
                (keydown.enter)="onKeydown($event, 'taxGroupId')"
                >
              </app-select>
          </div>
        </div>
  
        <!-- <div class="col-4 d-flex align-items-center ps-0" *ngIf="this.layoutDetails.countryCode === countryCodeEnum.IN">
            <div class="col-9 f-14 font-semibold text-gray-color ps-2 mb-0">
              Is State Wise
            </div>
            <div class="col d-flex justify-content-end align-items-center">
                <app-checkbox (keydown.enter)="onKeydown($event, 'calculationMode')" [dyanamicId]="'isStateWise'" [isRequired]="false" [status]="createUpdateTaxSettingRequestModel?.isStateWise" (check)="createUpdateTaxSettingRequestModel.isStateWise = $event"></app-checkbox>
            </div>
        </div> -->
      </div>
      <!--  -->
      <div class="row mb-065rem">
        <div class="col-4 font-semibold text-gray-color d-flex align-items-center">Tax Group<span class="text-color-danger">*</span></div>
        <div class="col-4 ps-0 taxG">
          <app-select 
                [id]="'taxGroupId'"
                [name]="'taxGroupId'"
                [isDisabled]="false"
                [options]="getAllDropdownModel?.taxGroupDropdown"
                [selectedValue]="createUpdateTaxSettingRequestModel?.taxGroupId"
                (valueChanged)="createUpdateTaxSettingRequestModel.taxGroupId=$event"
                [isRequired]="true"
                (keydown.enter)="onKeydown($event, 'active')"
                >
              </app-select>
        </div>
        <div (click)="this.createUpdateTaxSettingRequestModel.active = !this.createUpdateTaxSettingRequestModel.active;"
         class="col px-0 f-14 font-semibold text-gray-color cursorPointer text-nowrap mb-0 active-css">
          Active
        </div>
        <div class="w-30px px-0 d-flex justify-content-start align-items-center active-check-css">
          <app-checkbox [checkBoxClass]="'ms-1'" 
          [dyanamicId]="'active'" 
          [isRequired]="false" 
          [status]="createUpdateTaxSettingRequestModel.active" 
          (check)="createUpdateTaxSettingRequestModel.active = $event"
          ></app-checkbox>
        </div>
        
      </div>
      <!--  -->
      <div class="row d-flex align-items-center ps-0">
        <!--  -->
      </div>
      <!-- <div class="row mb-065rem">
        <div class="col-4 font-semibold text-gray-color d-flex align-items-center">Calculation Mode</div>
        <div class="col-6 ps-0">
          <app-select
            [id]="'calculationMode'"
            [name]="'calculationMode'"
            [options]="getAllDropdownModel?.calculationDropdown" 
            [selectedValue]="createUpdateTaxSettingRequestModel?.calculationMode"
            (valueChanged)="createUpdateTaxSettingRequestModel.calculationMode = $event"
            [isDisabled]="false"
            [isRequired]="false"
            (keydown.enter)="onKeydown($event, 'active')"
            >
          </app-select>
        </div>
        <div class="col-2 d-flex align-items-center ps-0">
          <div class="col-9 f-14 font-semibold text-gray-color text-center text-nowrap mb-0">
              Active
          </div>
          <div class="col d-flex justify-content-end align-items-center">
              <app-checkbox (keydown.enter)="onKeydown($event, 'comment')" [dyanamicId]="'active'" [isRequired]="false" [status]="createUpdateTaxSettingRequestModel.active" (check)="createUpdateTaxSettingRequestModel.active = $event"></app-checkbox>
          </div>
        </div>
      </div> -->
      <div class="row">
        <div class="col-4 font-semibold text-gray-color d-flex pt-2">Comment</div>
        <div class="col ps-0 ms-1">
          <textarea [(ngModel)]="createUpdateTaxSettingRequestModel.comment" (ngModelChange)="createUpdateTaxSettingRequestModel.comment =$event" name="comment"  id="comment" class="text-area-style font-regular" rows="3"></textarea>
        </div>
      </div>
      <div class="row d-flex align-items-center ps-0">
        <div class="col-4">
        </div>
        <!-- <div class="w-30px px-0 d-flex justify-content-start align-items-center">
          <app-checkbox [checkBoxClass]="'ms-1'" [dyanamicId]="'reverseTax'" [isRequired]="false" [status]="createUpdateTaxSettingRequestModel.reverseTax" (check)="createUpdateTaxSettingRequestModel.reverseTax = $event"></app-checkbox>
        </div>
        <div class="col-2 ps-0 me-3 f-14 font-semibold text-gray-color text-nowrap mb-0">
          Reverse Tax
        </div> -->

        <!-- <div class="w-30px px-0 d-flex justify-content-start align-items-center">
          <app-checkbox [checkBoxClass]="'ms-1'" [dyanamicId]="'active'" [isRequired]="false" [status]="createUpdateTaxSettingRequestModel.active" (check)="createUpdateTaxSettingRequestModel.active = $event"></app-checkbox>
        </div>
        <div class="col px-0 f-14 font-semibold text-gray-color text-nowrap mb-0">
          Active
        </div> -->
      </div>
    </div>
    <div class="modal-footer pt-3 pb-2 mx-2">
      <app-button class="my-0 me-3" (buttonOutputEvent)="activeModal.close()" [buttonLabel]="'Close'" [buttonClass]="'light_button'" [buttonType]="'button'">
      </app-button>
      <app-button class="m-0" [buttonLabel]="'Save'" (buttonOutputEvent)="addEditTaxSetting()" [buttonClass]="'blue_button'" [buttonType]="'submit'">
      </app-button>
    </div>
  </form>
</div>