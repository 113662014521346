import { Injectable } from '@angular/core';
import { DataService } from './../../shared/services/data.service';
import { Observable, tap } from 'rxjs';
import { ResponseModel } from './../../shared/models/response-model.model';

@Injectable({
  providedIn: 'root'
})
export class CompanyListService {

  constructor(public service: DataService,) { }

  getallcompanyListService():Observable<ResponseModel>{
    let url = "api/app/company/getallcompany";
    return this.service.get(url, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

  deleteCompany(id:number):Observable<ResponseModel>{
    let url = "api/app/company/deletecompany";
    return this.service.post(url,id,false).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }
}