<div class="row mx-0">
  <div class="col p-0">
    <select
      class="ag_grid_custom_dropdown cursorPointer ps-2"
      id="custom_select_options" 
      [(ngModel)]="value" 
      (ngModelChange)="selected($event)"
     >
      <option *ngFor="let val of values" [value]="val.id">
        {{ val.name }}
      </option>
    </select>
  </div>
</div>

  <!-- <select #mySelect (click)="active = !active" 
            [(ngModel)]="selectedValue" (change)="onValueChange($event.target.value)">
            <option class="options custom-option"*ngFor="let option of params.colDef.cellEditorParams.values" [value]="option">{{ option }}</option>
        </select> -->
  <!-- <div class="svg-main">
            <div class="cursorPointer svg-select px-2">
                <svg xmlns="http://www.w3.org/2000/svg" width="12.414" height="6.555" viewBox="0 0 12.414 6.555">
                    <path id="Path_1308" data-name="Path 1308" d="M-1841.061,5637.15l5.5,5.348,5.5-5.348"
                        transform="translate(1841.768 -5636.443)" fill="none" stroke="#a3abb8" stroke-linecap="round"
                        stroke-linejoin="round" stroke-width="1" />
                </svg>
            </div>
        </div> -->

<!-- <div *ngIf="params.plusIcon" class="cursorPointer d-flex align-items-center w-auto px-1">
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 30 30">
            <g id="Group_2039" data-name="Group 2039" transform="translate(838 -5635)">
                <circle id="Ellipse_15" data-name="Ellipse 15" cx="15" cy="15" r="15" transform="translate(-838 5635)"
                    fill="#e8ecf3" />
                <path id="Union_4" data-name="Union 4"
                    d="M7.438-1a.938.938,0,0,1,.937.938V6.5h6.563a.937.937,0,1,1,0,1.875H8.375v6.563a.937.937,0,1,1-1.875,0V8.375H-.062A.938.938,0,0,1-1,7.438.938.938,0,0,1-.062,6.5H6.5V-.062A.938.938,0,0,1,7.438-1Z"
                    transform="translate(-830.438 5642.563)" fill="#a3abb8" />
            </g>
        </svg>
    </div> -->
