import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appDraggableModal]'
})
export class DraggableModalDirective {
  private isDragging = false;
  private startX = 0;
  private startY = 0;
  private modalElement!: HTMLElement;

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngAfterViewInit() {
    // Locate the modal dialog parent element
    this.modalElement = this.el.nativeElement.closest('.modal-dialog');
    this.renderer.setStyle(this.modalElement, 'position', 'absolute');
  }

  @HostListener('mousedown', ['$event'])
  onMouseDown(event: MouseEvent) {
    this.isDragging = true;
    this.startX = event.clientX;
    this.startY = event.clientY;

    document.addEventListener('mousemove', this.onMouseMove.bind(this));
    document.addEventListener('mouseup', this.onMouseUp.bind(this));
  }

  onMouseMove(event: MouseEvent) {
    if (this.isDragging && this.modalElement) {
      const diffX = event.clientX - this.startX;
      const diffY = event.clientY - this.startY;

      const rect = this.modalElement.getBoundingClientRect();

      this.renderer.setStyle(this.modalElement, 'left', `${rect.left + diffX}px`);
      this.renderer.setStyle(this.modalElement, 'top', `${rect.top + diffY}px`);

      this.startX = event.clientX;
      this.startY = event.clientY;
    }
  }

  onMouseUp() {
    this.isDragging = false;
    document.removeEventListener('mousemove', this.onMouseMove.bind(this));
    document.removeEventListener('mouseup', this.onMouseUp.bind(this));
  }
}
