<div *ngIf="!showColumn" class="row m-0 p-0 gap-1 main position-absolute cursorPointer "
  (click)="hideSettingButton($event)">
  <div class="col d-flex align-items-center">
    <app-svg-icon name="setting" class="setting-icon"></app-svg-icon>
  </div>
  <div class="col header-text d-flex align-items-center">
    <span>Columns</span>
  </div>
</div>
<div class="col column-div px-2 position-absolute top-0 end-0" *ngIf="showColumn" (mouseleave)="onMouseLeave()">
  <div class="row m-0 p-0 gap-1">
    <div class="col d-flex justify-content-between p-1">
      <div class="col d-flex gap-1">
        <div class="p-0 d-flex align-items-center">
          <app-svg-icon name="setting" class="setting-icon cursorPointer "></app-svg-icon>
        </div>
        <div class="p-0 header-text d-flex align-items-center">
          <span>Columns</span>
        </div>
      </div>
      <div class="col d-flex gap-2 align-items-center justify-content-end">
        <div class="d-flex" (click)="reset()">
          <app-svg-icon name="reset" class="cursorPointer "></app-svg-icon>
        </div>
        <div class="d-flex" (click)="hideSettingButton()">
          <app-svg-icon name="columnCross" class="cursorPointer "></app-svg-icon>
        </div>
      </div>
    </div>
  </div>
  <div class="row m-0 px-0 py-1">
    <div class="p-0 input-div">
      <input placeholder="Search" class="column-input form-control" type="text"  [(ngModel)]="searchTerm" (input)="filterColumns()">
      <app-svg-icon name="searchColumn" class="cursorPointer search-icon"></app-svg-icon>
    </div>
  </div>
  <div class="header-list">
    <ng-container *ngFor="let item of filteredColumns; let i = index">
      <div *ngIf="item.isVisibleOnColumnSetting != false" class="col m-0 py-1" draggable="false"  
    
      >
      <!-- cdkDrag -->
        <div class="p-0 col d-flex gap-2 align-items-center" [ngClass]="{'pointer-none': item.isHidden}">
          <app-checkbox [status]="item.isChecked" (check)="hideOnCheck(item?.keyName,$event)" [disabled]="item.isHidden"></app-checkbox>
          <div>
            <span class="f-10">{{item.headerName}}</span>
          </div>
        </div>
      </div>
  </ng-container>
  </div>
</div>



