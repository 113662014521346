
<div class="container-fluid d-flex ps-4 flex-column pt-3 h-100">
  <form class="theme-form" ngNativeValidate #CompanyForm="ngForm">
    <div class="row">
      <div class="col-2">
        <div class="row">
          <div class="col-xl-12 col-lg-12 col-md-12">
            <span class="f-14 font-semibold text-light text-nowrap mb-0">Add Company Logo</span>
          </div>
          <div class="col mt-2">
              <div class="upload-image">
                      <!-- <img [src]="'data:image/png;base64,' + uploadImageUrl" alt=""> -->
                      <img alt="" [src]="isBase64Image(usermodel.companyLogo)" />

                      <!-- <img [src]="baseUrl + uploadImageUrl" alt=""> -->
              </div>
              <div class="d-flex mt-2">
                  <label class="common_button white_button px-2 mb-0  cursorPointer d-flex justify-content-center align-items-center"  (click)="clearImage()">Clear</label>
                  <label for="upload_logo" class=" px-2 common_button cursorPointer ms-2  blue_button mb-0  d-flex justify-content-center align-items-center">
                          <span>
                              <img
                              src="./assets/SVGicon/camera.svg"
                              width="15"
                              height="15"
                              alt="" />
                          </span>
                          <span style="margin-top: 1px;">Upload</span>
                  </label>
                  <input type="file" id="upload_logo" class="invisible d-none" (change)="onChangeImage($event)">
              </div>
     
          </div>
        </div>

      </div>
      <div class="col-5 ">
        <div class="row">
          <div class="col-12  pb-3">
            <div class="row">
                <div class="w-135 col-xl-3 col-lg-5 col-md-5">
                  <span class="f-14 font-semibold text-light text-nowrap mb-0">Business Name
                  <span class="text-color-danger">*</span>
                </span>
                </div>
                <div class="col ">
                  <app-input
                    onlyAlphabetsAndNumbers
                    [inputName]="'shopName'"
                    [inputType]="'text'"
                    [inputId]="'shopName'"
                    [inputValue]="usermodel.shopName"
                    (inputValueEmit)="usermodel.shopName = $event ;!fromAddCompany? getStartingCharacters(usermodel?.shopName):'';!fromAddCompany?checkIsCridAvailable(usermodel?.crid):''"
                    [isRequired]="true"
                    (keydown.enter)="onKeydown($event,fromAddCompany?'businessLicenceNoId' : 'crid')"
                    [ngClass]="{'input-border-red' : ((CompanyForm?.form?.controls?.shopName?.touched || CompanyForm?.form?.controls?.shopName?.dirty) && CompanyForm?.form?.controls?.shopName?.invalid)}">
                  </app-input>
                </div>
            </div>
          </div>
          <!-- end shop name -->
          <div class="col-12 pb-3">
            <div class="row">
                <div class="w-135 col-xl-3 col-lg-5 col-md-5">
                  <span class="f-14 font-semibold text-light text-nowrap mb-0">CRID
                  <span class="text-color-danger">*</span>
                </span>
                </div>
                <div class="col" [ngClass]="{'pe-none':fromAddCompany}">
                  <app-input
                    onlyAlphabetsAndNumbers
                    [inputName]="'crid'"
                    [inputType]="'text'"
                    [inputId]="'crid'"
                    [inputValue]="usermodel.crid"
                    (inputValueEmit)="usermodel.crid = $event;checkIsCridAvailable(usermodel?.crid)"
                    [isRequired]="true"
                    [maxLength]="8"
                    [minLength]="6"
                    (keydown.enter)="onKeydown($event, 'businessLicenceNoId')"
                    [ngClass]="{'input-border-red' : ((CompanyForm?.form?.controls?.crid?.touched || CompanyForm?.form?.controls?.crid?.dirty) && CompanyForm?.form?.controls?.crid?.invalid)}">
                  </app-input>
                  <span *ngIf="(!isCridAvailabilityCheck.isCridAvailable && isCridAvailabilityCheck?.count > 0)" class="hintMessage">That ID is already is Taken.Please Try Another</span>
                  <span *ngIf="(isCridAvailabilityCheck?.count > 0)"  class="closeAndRightIcon position-absolute d-flex h-100 align-items-center end-0 pe-3 pointer">
                    <svg *ngIf="!isCridAvailabilityCheck?.isCridAvailable" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 22 22">
                        <g id="Group_42950" data-name="Group 42950" transform="translate(-1154 -243)">
                          <circle id="Ellipse_684" data-name="Ellipse 684" cx="11" cy="11" r="11" transform="translate(1154 243)" fill="#f92b35"/>
                          <path id="Union_238" data-name="Union 238" d="M5,5,0,10,5,5,0,0,5,5l5-5L5,5l5,5Z" transform="translate(1160.001 248.999)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                        </g>
                      </svg>
                      <svg *ngIf="isCridAvailabilityCheck?.isCridAvailable" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 22 22">
                        <g id="Group_42949" data-name="Group 42949" transform="translate(-1154 -243)">
                          <circle id="Ellipse_684" data-name="Ellipse 684" cx="11" cy="11" r="11" transform="translate(1154 243)" fill="#1ede58"/>
                          <path id="Path_1214" data-name="Path 1214" d="M654.936,654.014l4.483,4.483,3.095-3.094,4.547-4.547" transform="translate(504.001 -400.676)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                        </g>
                      </svg>
                </span>
                </div>
            </div>
          </div>
          <!-- end shop name -->
          <div class="col-12  pb-3">
            <div class="row">
              <div class="w-135 col-xl-3 col-lg-5 col-md-5">
                <span class="f-14 font-semibold text-light text-nowrap mb-0">
                  Business License No
                </span>
              </div>
              <div class="col ">
                <app-input
                  [inputName]="'businessLicenceNo'"
                  [inputType]="'text'"
                  [inputId]="'businessLicenceNoId'"
                  [inputValue]="usermodel.businessLicenceNo"
                  (inputValueEmit)="usermodel.businessLicenceNo = $event"
                  (keydown.enter)="onKeydown($event, 'panCard')">
                </app-input>
              </div>
            </div>
          </div>
          <!-- end business l no name -->
          <div class="col-12  pb-3">
            <div class="row">
              <div class="w-135 col-xl-3 col-lg-5 col-md-5">
                <span class="f-14 font-semibold text-light text-nowrap mb-0">National Id/Driving <br>Lisence<br/></span>
              </div>
              <div class="col ">
                <app-input
                  [inputName]="'panCard'"
                  [inputType]="'text'"
                  [inputId]="'panCard'"
                  [inputValue]="usermodel.panCard"
                  (inputValueEmit)="usermodel.panCard = $event"
                  (keydown.enter)="onKeydown($event, 'registrationDateId')">
                </app-input>
              </div>
            </div>
          </div>
          <!-- end pancard dob -->
          <div class="col-12  pb-3">
            <div class="row">
              <div class="w-135 col-xl-3 col-lg-5 col-md-5">
                <span class="f-14 font-semibold text-light text-nowrap mb-0">
                  Registration Date
                </span>
              </div>
              <!-- <div class="col ">
                <app-input
                  [inputName]="'registrationDate'"
                  [inputType]="'date'"
                  [inputId]="'registrationDateId'"
                  [inputValue]="usermodel?.registrationDate"
                  (inputValueEmit)="selectDate($event)"
                  [isRequired]="true"
                  (keydown.enter)="onKeydown($event, 'emailId')">
                </app-input>
              </div> -->
              <div class="col">
                  <app-input-datepicker-renderer 
                  [id]="'registrationDateId'" 
                  (inputValueEmit)="selectDate($event)"  
                  (keydown.enter)="onKeydown($event, 'emailId')"></app-input-datepicker-renderer>
              </div>
            </div>
          </div>
          <!-- end registrationDate  -->
          <div class="col-12  pb-3">
            <div class="row">
              <div class="w-135 col-xl-3 col-lg-5 col-md-5">
                <span class="f-14 font-semibold text-light text-nowrap mb-0">Email
                <span class="text-color-danger">*</span>
                </span>
              </div>
              <div class="col ">
                <app-input
                  [inputName]="'businessEmail'"
                  [inputType]="'email'"
                  [inputId]="'emailId'"
                  [inputValue]="usermodel?.businessEmail"
                  (inputValueEmit)="usermodel.businessEmail = $event"
                  [isRequired]="true"
                  [isEmailPattern]="true"
                  [ngClass]="{'input-border-red' : ((CompanyForm?.form?.controls?.businessEmail?.touched || CompanyForm?.form?.controls?.businessEmail?.dirty) && CompanyForm?.form?.controls?.businessEmail?.invalid)}"
                  (keydown.enter)="onKeydown($event, 'officeNoId')">
                </app-input>
                <div *ngIf="((CompanyForm?.form?.controls?.businessEmail?.touched || CompanyForm?.form?.controls?.businessEmail?.dirty) && CompanyForm?.form?.controls?.businessEmail?.invalid)" class="text-yellow font-regular f-11 position-absolute">Please enter a valid email address</div>
              </div>
            </div>
          </div>
          <!-- end email -->
          <div class="col-12  pb-3">
            <div class="row">
              <div class="w-135 col-xl-3 col-lg-5 col-md-5">
                <span class="f-14 font-semibold text-light text-nowrap mb-0">
                  Office Number
                </span>
              </div>
              <div class="col ">
                <app-input 
                onlyNumbers
                [inputName]="'officeNo'" 
                [inputType]="'text'" 
                [inputId]="'officeNoId'"
                [inputValue]="usermodel.officeNo" 
                (inputValueEmit)="usermodel.officeNo = $event" 
                (keydown.enter)="onKeydown($event, 'addressLine1Id')"
                >
                </app-input>
              </div>
            </div>
          </div>
          <!-- end office no -->
        </div>
        <!-- end row -->
      </div>
      <!-- end first col-5 -->
      <div class="col-5">
        <div class="row">
          <div class="col-12  pb-3">
            <div class="row">
              <div class="w-135 col-xl-3 col-lg-5 col-md-5">
                <span class="f-14 font-semibold text-light text-nowrap mb-0">Address Line 1
                </span>
              </div>
              <div class="col ">
                <app-input
                  [inputName]="'addressLine1'"
                  [inputType]="'text'"
                  [inputId]="'addressLine1Id'"
                  [inputValue]="usermodel.addressLine1"
                  (inputValueEmit)="usermodel.addressLine1 = $event"
                  (keydown.enter)="onKeydown($event, 'addressLine2Id')">
                </app-input>
              </div>
            </div>
          </div>
          <!-- end addressLine1 name -->
          <div class="col-12  pb-3">
            <div class="row">
              <div class="w-135 col-xl-3 col-lg-5 col-md-5">
                <span class="f-14 font-semibold text-light text-nowrap mb-0">
                  Address Line 2
                </span>
              </div>
              <div class="col ">
                <app-input
                [inputName]="'addressLine2'"
                [inputType]="'text'"
                [inputId]="'addressLine2Id'"
                [inputValue]="usermodel.addressLine2"
                (inputValueEmit)="usermodel.addressLine2 = $event"
                (keydown.enter)="onKeydown($event, 'country')">
                </app-input>
              </div>
            </div>
          </div>
          <!-- end addressLine2 name -->
          <div class="col-12  pb-3">
            <div class="row">
              <div class="w-135 col-xl-3 col-lg-5 col-md-5">
                <span class="f-14 font-semibold text-light text-nowrap mb-0">
                  Country
                </span>
              </div>
              <div class="col ">
                <!-- <app-input
                [inputName]="'country'"
                [inputType]="'text'"
                [inputId]="'country'"
                [inputValue]="usermodel.country"
                (inputValueEmit)="usermodel.country = $event"
                [isRequired]="true"
                (keydown.enter)="onKeydown($event, 'state')"
                >
                </app-input> -->
                <app-select
                [options]="getAllDropdownModel?.countryDropdown"
                [selectedValue]="usermodel?.countryId"
                (valueChanged)="usermodel.countryId= $event; onChangeCountryName($event)"
                [id]="'country'"
                [name]="'country'"
                [isDisabled]="false"
                [selectClass]="'white-color'"
                (keydown.enter)="onKeydown($event, 'state')">
                </app-select>
              </div>
            </div>
          </div>
          <!-- end country  -->
          <div class="col-12  pb-3">
            <div class="row">
              <div class="w-135 col-xl-3 col-lg-5 col-md-5">
                <span class="f-14 font-semibold text-light text-nowrap mb-0">
                  State/Province 
                  <span class="text-color-danger">*</span>
                </span>
              </div>
              <div class="col ">
                <app-select
                [options]="getAllDropdownModel?.stateList"
                [selectedValue]="usermodel?.stateId"
                (valueChanged)="usermodel.stateId= $event; getStateName($event)"
                [id]="'state'"
                [name]="'state'"
                [isDisabled]="false"
                [isRequired]="true"
                [selectClass]="'white-color'"
                [ngClass]="{'select-dropdown-border-red' : ((CompanyForm?.form?.controls?.state?.touched || CompanyForm?.form?.controls?.state?.dirty) && CompanyForm?.form?.controls?.state?.invalid)}"
                (keydown.enter)="onKeydown($event, 'pinCodeId')">
                </app-select>
              </div>
            </div>
          </div>
          <!-- end State  -->
          <!-- <div class="col-12  pb-3">
            <div class="row">
              <div class="w-135 col-xl-3 col-lg-5 col-md-5">
                <span class="f-14 font-semibold text-light text-nowrap mb-0">
                  District/Area 
                </span>
              </div>
              <div class="col ">
                <app-select
                [options]="districList"
                [selectedValue]="usermodel.districtId"
                (valueChanged)="usermodel.districtId= $event; getDistricName($event)"
                [id]="'district'"
                [name]="'district'"
                [isDisabled]="false"
                [isRequired]="true">
                </app-select>
              </div>
            </div>
          </div> -->
          <!-- end district  -->
          <!-- <div class="col-12  pb-3">
            <div class="row">
              <div class="w-135 col-xl-3 col-lg-5 col-md-5">
                <span class="f-14 font-semibold text-light text-nowrap mb-0">
                  Taluka 
                </span>
              </div>
              <div class="col ">
                <app-input
                [inputName]="'taluka'"
                [inputType]="'text'"
                [inputId]="'taluka'"
                [inputValue]="usermodel.taluka"
                (inputValueEmit)="usermodel.taluka = $event"
                [isRequired]="true"
                (keydown.enter)="onKeydown($event, 'pinCodeId')">
                </app-input>
              </div>
            </div>
          </div> -->
          <!-- end taluka  -->
          <div class="col-12  pb-3">
            <div class="row">
              <div class="w-135 col-xl-3 col-lg-5 col-md-5">
                <span class="f-14 font-semibold text-light text-nowrap mb-0">
                  Zip Code
                </span>
              </div>
              <div class="col ">
                <app-input
                [isOnlyAlphabetsAndNumbers]="true"
                [inputName]="'pinCode'"
                [inputType]="'text'"
                [inputId]="'pinCodeId'"
                [inputValue]="usermodel.pinCode"
                (inputValueEmit)="usermodel.pinCode = $event"
                (keydown.enter)="onKeydown($event, 'gstTrn')">
                </app-input>
              </div>
            </div>
          </div>
          <!-- end pinCode  -->
          <div class="col-12  pb-3">
            <div class="row">
              <div class="w-135 col-xl-3 col-lg-5 col-md-5">
                <span class="f-14 font-semibold text-light text-nowrap mb-0">
                  GST/TRN/HST                
                </span>
              </div>
              <div class="col ">
                <app-input
                [inputName]="'gstTrn'"
                [inputType]="'text'"
                [inputId]="'gstTrn'"
                [inputValue]="usermodel.gstTrn"
                (inputValueEmit)="usermodel.gstTrn = $event"
                >
                </app-input>
              </div>
            </div>
          </div>
        </div>
      </div>
 
      <!-- end second -->
    </div>
  </form>
</div>
