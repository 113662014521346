import { MetalSectionService } from '../metal-section/metal-section.service';
import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, Output, Renderer2, ViewChild } from '@angular/core';
import { AgGridService } from '../ag-grid/ag-grid.service';
import { InvoiceService } from 'src/app/components/Transaction/invoice/invoice.service';
import { ResponseModel } from '../../models/response-model.model';
import { Subscription, catchError } from 'rxjs';
import { StorageService } from '../../services/storage.service';
import { CommonService } from "src/app/shared/services/common.service";
import { CalculateOn, CalculationType, MakingChargeType, StorageName } from '../../enum/common-enum';
import { ProductService } from 'src/app/components/Product/product.service';
import { NgbActiveModal, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { DiamondCategory, LedgerItemTypeTaxes, MetalType, VoucherType, VoucherTypeName, commonModalOptions } from '../../models/common.model';
import { AddeditcategoryComponent } from 'src/app/components/Product/product-opening/popup/addeditcategory/addeditcategory.component';
import { PlusIconService } from '../ag-grid/plus-icon/plus-icon.service';
import { ProductOpeningComponent } from 'src/app/components/Product/product-opening/product-opening.component';
import { CaratPopupComponent } from '../common-popup/carat-popup/carat-popup.component';
import { NavTabsComponent } from '../nav-tabs/nav-tabs.component';
import { TransactionService } from 'src/app/components/Transaction/transaction.service';
import { AddImageComponent } from '../common-popup/add-image/add-image.component';
import { StockJournalService } from 'src/app/components/Transaction/stock-journal/stock-journal.service';
import { CompressImageService } from '../../services/compress-image.service';
import { ColDef } from 'ag-grid-community';
import { BarcodePopupComponent } from 'src/app/components/Report/popups/barcode-popup/barcode-popup.component';
import { LoadDropdownDataService } from '../load-dropdown-data/load-dropdown-data.service';
import { AddEditLocationComponent } from 'src/app/components/Product/product-opening/popup/add-edit-location/add-edit-location.component';
import { MetalTabs } from '../metal-section/metal-section.model';

@Component({
  selector: 'app-metal-section-child',
  templateUrl: './metal-section-child.component.html',
  styleUrls: ['./metal-section-child.component.scss']
})
export class MetalSectionChildComponent extends CommonService implements AfterViewInit, OnDestroy {
  @ViewChild(NavTabsComponent) navTabsComponent: NavTabsComponent;
  @Input() formName: string;
  @Input() formModel: any;
  @Input() metalSectionModel: any;
  @Output() getTabId = new EventEmitter<any>();
  @Input() comment: string;
  @Input() groupName: string;
  @Input() isPopup: boolean = true;
  isGroup: boolean;
  @Output() commentChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() groupNameChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() sendDataToItemList = new EventEmitter<any>();
  activemetalId: number;
  isDisabled: boolean = false;
  tabGroupList: any[];
  tabId: any = 1;
  tabItems: any[] = [];
  productTax: any[] = [];
  storage = StorageName;
  gridApi: any = {};
  isLoading: boolean = false;
  voucherType: string;
  // voucherWiseData:any;
  applicableOnProducts: any[] = [];
  getSaleInvoiceItemsDetailsModel: any = {};
  gridApiGoldTable: any;
  gridApiSilverTable: any;
  gridApiDiamondTable: any;
  gridApiImmitationAndWatchesTable: any;
  barcodeNo: string;
  uploadImagesList = [];
  @Input() gridDynamicHeightClass: string = 'h-150px';
  onItemListRowClickedSubscription: Subscription;
  resetInvoiceSubscription: Subscription;
  onAddRowButtonSubscription: Subscription | undefined;
  onNavTabKeyPressedSubscription: Subscription;
  onplusIconClickSubscription: Subscription;
  barcodeScanValueSourceSubscription: Subscription;
  isImageSaved: boolean = false;
  purchaseInvoiceNoDropdownList: any;
  barcodeClickSubscription: Subscription;
  voucherTypeName: typeof VoucherTypeName = VoucherTypeName;
  @Output() fillDropdownData = new EventEmitter();
  constructor(
    public activeModal: NgbActiveModal,
    public metalSectionService: MetalSectionService,
    public stockJournalService: StockJournalService,
    private agGridService: AgGridService,
    public renderer: Renderer2,
    public modalService: NgbModal,
    public invoiceService: InvoiceService,
    public transactionService: TransactionService,
    public storageService: StorageService,
    public productService: ProductService,
    private plusIconService: PlusIconService,
    public compressImageService: CompressImageService,
    public loadDropdownDataService: LoadDropdownDataService,
  ) {
    super(renderer);;
    this.onItemListRowClickedSubscription = this.storageService.onItemListRowClicked.subscribe((result) => {
      if (result != undefined) {
        result ? this.isImageSaved = true : false;
        if (this.formName == StorageName.FORMNAME_TRANSACTION_JOBWORKINVOICE)
          this.assignMoreSelectedItems(result?.data?.detailedJson, false, result?.data?.parentMetalId, result?.data);
        else
          this.onItemListRowClickedResponse(result?.data?.detailedJson, false, result?.data?.parentMetalId, result?.data);
      }
    });

    this.resetInvoiceSubscription = this.storageService.resetInvoice.subscribe((result) => {
      if (result != undefined)
        this.resetInvoiceResponse(result);
    });

    this.onNavTabKeyPressedSubscription = this.storageService.onNavTabKeyPressed.subscribe((tabId) => {
      if (tabId) {
        this.focusFirstRowFirstCell(tabId);
        this.agGridService.cameraButtonDisabled = this.checkAnyGridHasRowWithTabs();
      }
    });
    this.onplusIconClickSubscription = this.plusIconService.plusIconClicked$.subscribe((params: any) => {
      this.openPopup(params)
    });

    this.barcodeScanValueSourceSubscription = this.storageService.barcodeScanValueChange$.subscribe(({ barcodeNO, formName }) => {
      this.getProductDataByBarcodeNo(barcodeNO, formName)
    })

  }

  onDeleteIconClick(params) {
    const result = params?.detail?.data
    if (result != undefined) {
      this.agGridService.cameraButtonDisabled = this.checkAnyGridHasRowWithTabs();
      this.onDeleteRowClickedResponse(result);
      if (!this.checkAnyGridHasRowWithTabs()) {
        this.uploadImagesList = [];
        this.isImageSaved = false;
      }
    }
  }


  ngOnInit() {
    var data = this.formModel;

    this.initialCalls();
  }

  initialCalls() {
    this.loadVoucherTypeWiseData();
    this.tabGroupList = [
      { tabId: MetalTabs.Gold, dataModel: this.metalSectionModel.agGridDataModelGold, label: 'GoldItem', tabIndex: 0 },
      { tabId: MetalTabs.Silver, dataModel: this.metalSectionModel.agGridDataModelSilver, label: 'SilverItem', tabIndex: 1 },
      { tabId: MetalTabs.Diamond, dataModel: this.metalSectionModel.agGridDataModelDiamond, label: 'DiamondItem', tabIndex: 2 },
      { tabId: MetalTabs.ImmitationAndWatches, dataModel: this.metalSectionModel.agGridDataModelImmitationAndWatches, label: 'ImitationItem', tabIndex: 4 },
    ];
    this.selectTab(this.tabId);

  }
  fillPreviousRow() {
    var result = this.metalSectionModel.previousRow;
    var data = result?.detail?.data?.data;
    if (result != undefined) {
      result ? this.isImageSaved = true : false;
      if (this.formName == StorageName.FORMNAME_TRANSACTION_JOBWORKINVOICE)
        this.assignMoreSelectedItems(data?.detailedJson, false, data?.parentMetalId, data);
      else
        this.onItemListRowClickedResponse(data?.detailedJson, false, data?.parentMetalId, data);
    }
    
  }

  onGridReady(item, gridApi) {
    switch (item) {
      case 0: this.gridApiGoldTable = gridApi;
        this.sendDataToItemList.emit(this.getGridApiOnSelectdTab()); break;
      case 1: this.gridApiSilverTable = gridApi; break;
      case 2: this.gridApiDiamondTable = gridApi; break;
      case 4: this.gridApiImmitationAndWatchesTable = gridApi; break;
    }
    this.checkAnyGridHasRowWithTabs();
  }

  selectTab(tabId: number) {
    this.tabId = tabId;
    this.getTabId.emit(tabId);
    this.invoiceService.TabId.next(this.tabId);
    this.sendDataToItemList.emit(this.getGridApiOnSelectdTab());
    this.agGridService.cameraButtonDisabled = this.checkAnyGridHasRowWithTabs();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.fillPreviousRow();
      if(this.metalSectionModel.defaultActiveMetalTab)
        this.selectNavTab(this.metalSectionModel.defaultActiveMetalTab, true);
    }, 1000);
  }
  openPopup(params) {
    switch (params?.displayName) {
      case 'Category': this.agGridService.openModal(AddeditcategoryComponent, params, this.storage.CATEGORY_DROPDOWN, 'subCategoryId'); break;
      case 'Product': this.agGridService.openModal(ProductOpeningComponent, params, this.storage.PRODUCT_DROPDOWN, 'productDetailId'); break;
      case 'Carat': this.agGridService.openModal(CaratPopupComponent, params, this.storage.CARAT_DROPDOWN, 'caratId'); break;
      case 'Location': this.agGridService.openModal(AddEditLocationComponent, params, this.storage.LOCATION_DROPDOWN, 'locationId'); break;
    }
    if (this.formName) {
      this.loadDropdownDataService.getAllDropdownDataFormWise(this.formName)
        .pipe(catchError((err) => this.handleError((err))))
        .subscribe((res: ResponseModel) => {
          if (res.isSuccess)
            this.fillDropdownData.emit(res?.response);
        })
    }
  }

  openModalBarcodePopup(): void {
    const modalOption: NgbModalOptions = {
      centered: true,
      backdrop: "static",
      keyboard: false,
      size: "xl",
    };
    const modalRef = this.modalService.open(BarcodePopupComponent, modalOption);
    modalRef.componentInstance.MetalTabId = this.tabId;
    modalRef.componentInstance.isbarcode = false;
    modalRef.result.then((rowData) => {
      if (rowData)
        this.assingToMetalGrid(rowData);
    })
  }
  assingToMetalGrid(data) {
    const barcodeNo = data?.barcodeNo
    this.getProductDataByBarcodeNo(barcodeNo, StorageName.FORMNAME_TRANSACTION_SALESINVOICE);
  }

  focusFirstRowFirstCell(tabId) {
    switch (tabId) {
      case 1: this.agGridService.setFocusToLastRow(this.gridApiGoldTable); break;
      case 2: this.agGridService.setFocusToLastRow(this.gridApiSilverTable); break;
      case 4: this.agGridService.setFocusToLastRow(this.gridApiDiamondTable); break;
      case 5: this.agGridService.setFocusToLastRow(this.gridApiImmitationAndWatchesTable); break;
    }
  }

  loadVoucherTypeWiseData() {
    if (this.formModel.voucherType == this.voucherTypeName.StockJournal) {
      this.agGridService.getDataFromVoucherType(this.formModel.voucherType).pipe(catchError((err) => this.handleError(err))).subscribe((res: ResponseModel) => {
        if (res.isSuccess === true)
          this.onTaxChangeResponse(res.response);
      });
    }
    else this.onTaxChangeResponse(undefined);
  }

  onDeleteRowClickedResponse(event) {
    var deletedRowId = event.data.rowId;
    if (deletedRowId != undefined) {
      var allMetals = this.getAllMetals();
      allMetals.goldItem = allMetals.goldItem.filter(a => a.rowId != deletedRowId);
      allMetals.silverItem = allMetals.silverItem.filter(a => a.rowId != deletedRowId);
      allMetals.diamondItem = allMetals.diamondItem.filter(a => a.rowId != deletedRowId);
      allMetals.imitationItem = allMetals.imitationItem.filter(a => a.rowId != deletedRowId);
      this.setAllMetals(allMetals);
    }
  }

  headerAddClick(data) {
    var metalTypeId: any = 0;
    if (data.params.storageName.indexOf('DIAMOND') > -1)
      metalTypeId = MetalType.Diamond;
    var firstRowNode = data.params.api.getRowNode(0);
    this.metalSectionModel.parentMetalId = metalTypeId;
    switch (data.params.displayName) {
      case "Gold Amount":
        var postGoldData = {
          purityPer: "1.0",
          grossWt: firstRowNode.data.goldWt,
          caratId: firstRowNode.data.goldCaratId,
          parentMetalId: metalTypeId
        }
        this.setSelectedTab(1);
        setTimeout(() => { this.agGridService.appendRow(this.gridApiGoldTable, postGoldData); }, 1000);
        break;
    }
  }

  lastCellTriggered(params) {
    var arrayOfMetals = [];
    var metals = this.getAllMetals();
    if (metals.goldItem.length > 0)
      arrayOfMetals.push(1);
    if (metals.silverItem.length > 0)
      arrayOfMetals.push(2);
    if (metals.diamondItem.length > 0)
      arrayOfMetals.push(4);
    if (metals.imitationItem.length > 0)
      arrayOfMetals.push(6);

    var indexGold = params.dataModel.storageName.indexOf("GOLD");
    var indexSilver = params.dataModel.storageName.indexOf("SILVER");
    var indexDiamond = params.dataModel.storageName.indexOf("DIAMOND");
    var indexImitation = params.dataModel.storageName.indexOf("IMITATION");

    var indexOfCurrentTabItem: any = [];
    if (indexGold != -1)
      indexOfCurrentTabItem = arrayOfMetals.filter(a => a == MetalType.Gold);
    if (indexSilver != -1)
      indexOfCurrentTabItem = arrayOfMetals.filter(a => a == MetalType.Silver);
    if (indexDiamond != -1)
      indexOfCurrentTabItem = arrayOfMetals.filter(a => a == MetalType.Diamond);
    if (indexImitation != -1)
      indexOfCurrentTabItem = arrayOfMetals.filter(a => a == MetalType.Imitation);
    if (indexOfCurrentTabItem.length > 0) {
      this.setSelectedTab(indexOfCurrentTabItem[0]);
      if (indexOfCurrentTabItem[0] == 4) {
        var firstRowNode = this.gridApiDiamondTable?.api.getRowNode(0);
        firstRowNode.goldAmount = params?.gridOptions?.data?.netAmountWithTax;
        setTimeout(() => {
          this.agGridService.appendRow(this.gridApiDiamondTable, firstRowNode, "goldAmount");
        }, 1000);
      }
    }
    else this.storageService.outOfMetalSection.next(params);
  }



  async onTaxChangeResponse(res) {
    if (this.metalSectionModel.voucherWiseData == undefined)
      this.metalSectionModel.voucherWiseData = res;

    this.tabItems = this.metalSectionModel.voucherWiseData?.allMetalList ?? [];

  }

  public async setUdfColumns(storageUDFs) {
    setTimeout(async () => {
    if (this.metalSectionModel != undefined) {
      // var storageUDFs =  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN);
      const metalIdWiseUdfGold = storageUDFs?.filter((element) => element?.extra2 == MetalType.Gold);
      const metalIdWiseUdfSilver = storageUDFs?.filter((element) => element?.extra2 == MetalType.Silver);
      const metalIdWiseUdfDiamond = storageUDFs?.filter((element) => element?.extra2 == MetalType.Diamond);
      //taxcolumn wont load
      await this.agGridService.updateColumnDefinations(this.metalSectionModel.agGridDataModelGold, this.gridApiGoldTable, MetalType.Gold, metalIdWiseUdfGold);
      await this.agGridService.updateColumnDefinations(this.metalSectionModel.agGridDataModelSilver, this.gridApiSilverTable, MetalType.Silver, metalIdWiseUdfSilver);
      await this.agGridService.updateColumnDefinations(this.metalSectionModel.agGridDataModelDiamond, this.gridApiDiamondTable, MetalType.Diamond, metalIdWiseUdfDiamond);
      await this.agGridService.updateColumnDefinations(this.metalSectionModel.agGridDataModelImmitationAndWatches, this.gridApiImmitationAndWatchesTable, MetalType.Imitation, metalIdWiseUdfDiamond);
    }
  }, 2000)
  }

  setTaxCustomerwise(taxFromCustomer) {
    if (this.metalSectionModel.voucherWiseData != undefined) {

      this.metalSectionModel.voucherWiseData.applicableOnInvoiceByCustomer = taxFromCustomer?.applicableOnInvoice;
      this.metalSectionModel.voucherWiseData.applicableOnProductsByCustomer = taxFromCustomer?.applicableOnProducts;
    }
  }

  onAddNewRow(tableId) {
    setTimeout(() => {
      switch (this.tabId) {
        case 1: this.agGridService.appendNewRow(this.gridApiGoldTable); break;
        case 2: this.agGridService.appendNewRow(this.gridApiSilverTable); break;
        case 4: this.agGridService.appendNewRow(this.gridApiDiamondTable); break;
        case 5: this.agGridService.appendNewRow(this.gridApiImmitationAndWatchesTable); break;
      }
    }, 0)
  }

  onCellValueChangedDetect(params) {
    switch (params.column.colId) {
      case "productDetailId": this.assignGridDataProductWise(params); break;
      case "styleId": this.assignGridDataStyleWise(params); break;
      case "itemCode": this.assignGridDataItemCodeWise(params); break;
      case "caratId": this.assignRateCaratWise(params); break;
      case "goldCaratId": this.assignGoldRateGoldCaratWise(params); break;
    }

    if (params.column.colId != "purchaseAmount")
      this.callCalulationApi(params.data, false, params.column.colId);
  }

  assignGridDataProductWise(params) {
    var productDropdown = this.storageService.retrieve(StorageName.PRODUCT_DROPDOWN);
    var particularProductData = productDropdown?.find(a => a.id == parseInt(params.node.data.productDetailId));
    const obj = JSON.parse(particularProductData?.extra2);
    params.data.purityPer = params.data.purityPer == undefined || Number(params.data.purityPer ?? 0) == 0 ? '1.0' : params.data.purityPer;
    params.data.makingOn = obj[0].makingOn;
    params.data.subCategoryId = obj[0].subCategoryId.toString();
    this.productTax = obj[0].taxesIds;//.map(a=>a.taxId);
    if (params.data.caratId == undefined || params.data.caratId == 0)
      params.data.caratId = obj[0].caratId;

    if (params.colDef.headerComponentParams.storageName.indexOf('PURCHASE') != -1 || params.colDef.headerComponentParams.storageName.indexOf('MATERIAL_ISSUE') != -1 || params.colDef.headerComponentParams.storageName.indexOf('JOBWORKORDER') != -1)
      params.data.purityPer = obj[0].purityPurchase;
    else if (params.colDef.headerComponentParams.storageName.indexOf('SALE') != -1 || params.colDef.headerComponentParams.storageName.indexOf('MATERIAL_RECEIVED') != -1 || params.colDef.headerComponentParams.storageName.indexOf('JOBWORKINVOICE') != -1)
      params.data.purityPer = obj[0].puritySale;
    params.data.purityPer = Number(params.data.purityPer) == 0 ? "1.0" : this.fixWeightDecimal(params.data.purityPer);

    if (params.data.cquantity == undefined)
      params.data.cquantity = obj[0].quantity;
    if (params.data.wastagePer == undefined)
      params.data.wastagePer = obj[0].wastagePer;
    params?.api?.refreshCells();
  }

  assignGridDataStyleWise(params) {
    const styleDropdown = this.storageService.retrieve(StorageName.STYLE_DROPDOWN);
    var particularStyleData = styleDropdown?.find(a => a.id == params?.node?.data?.styleId);
    const obj = (JSON?.parse(particularStyleData?.extra1)[0]);
    for (let key in obj) {
      if (key == "categoryId")
        key = "diamondCategoryId"
      params.data[key] = obj[key]?.toString();
    }
    params?.api?.refreshCells();
  }

  assignGridDataItemCodeWise(params) {
    this.transactionService.getiItemDetailByItemCode(params.data.itemCode)
      .pipe(catchError((err) => this.handleError(err)))
      .subscribe((res: any) => {
        for (let key in res.response)
          params.data[key] = res.response[key]?.toString();
      });
    params?.api?.refreshCells();
  }

  assignRateCaratWise(params) {
    const caratDropdown = this.storageService.retrieve(StorageName.CARAT_DROPDOWN);
    var particularCaratData = caratDropdown?.find(a => a.id == params?.node?.data?.caratId);
    params.data.rate = particularCaratData?.extra2 ? Number(particularCaratData?.extra2)?.toFixed(2) : '0.00';
    params?.api?.refreshCells();
  }

  assignRateGoldCaratWise(params) {
    const caratDropdown = this.storageService.retrieve(StorageName.CARAT_DROPDOWN);
    var particularCaratData = caratDropdown?.find(a => a.id == params.goldCaratId);
    params.goldPurityPer = particularCaratData?.extra2 ? Number(particularCaratData?.extra3)?.toFixed(2) : '1.00';
    // params.goldRate = particularCaratData?.extra2? Number(particularCaratData?.extra2)?.toFixed(2):'0.00';
    // this.gridApiDiamondTable.api?.refreshCells();
  }

  assignGoldRateGoldCaratWise(params) {
    const goldcaratDropdown = this.storageService.retrieve(StorageName.CARAT_DROPDOWN);
    var particularCaratData = goldcaratDropdown?.find(a => a.id == params?.node?.data?.goldCaratId);
    params.data.goldRate = particularCaratData?.extra2 ? Number(particularCaratData?.extra2)?.toFixed(2) : '0.00';
    params?.api?.refreshCells();
  }

  callCalulationApi(node, IsBacodeScan = false, calculateByField, tabId = undefined, calculateTax = true) {
    var productTax = this.metalSectionModel.voucherWiseData.applicableOnProducts != undefined ? this.metalSectionModel.voucherWiseData.applicableOnProducts?.map((x) => { return { taxId: x.id }; }) : this.applicableOnProducts?.map((x) => { return { taxId: x.id }; });
    if (IsBacodeScan) {
      node.rate = (node.saleAmount > 0) ? node.saleAmount : node.rate;
      node.saleAmount = 0;
      node.purchaseAmount = 0;
    }

    node.itemTaxDetails = productTax;
    node.productDetailId = node.productDetailId ?? '0';
    if ((node.makingTypeId == undefined || node.makingTypeId == 0))
      node.makingTypeId = this.storageService.retrieve(StorageName.MAKINGCHARGESTYPE_DROPDOWN)[0]?.id;
    this.gridApiGoldTable?.api?.refreshCells();
    if (tabId == undefined)
      tabId = this.tabId;
    switch (tabId) {
      case 1:
      case 2: if (calculateByField != "reverseCalculationAmount") this.calculateGoldSilverItem(node, tabId, false, IsBacodeScan);
      else this.reversecalculateGoldSilverItem(node, tabId); break;
      case 4: if (calculateByField != "reverseCalculationAmount") {
        this.calculateDiamondItem(node, calculateByField, false, IsBacodeScan);
      }
      else this.reverseCalculateDiamondItem(node); break;
      case 6: this.calculateImitationItem(node, IsBacodeScan); break;
    }
  }

  taxCalculationLoop(model, metalId, isBarcodeScan = false, fromReverse = false, calculateTaxFromInvoice = true): any {
    if (calculateTaxFromInvoice == true)
      for (const item of model.itemTaxDetails ?? []) {
        var taxSetting = this.storageService.retrieve("TaxSetting");
        var tax = taxSetting?.filter(a => a?.id == item?.taxId)[0];
        if (tax != null && tax != undefined) {
          if (isBarcodeScan == true || this.productTax.length <= 0) {
            var productDropdown = this.storageService.retrieve(StorageName.PRODUCT_DROPDOWN);
            var particularProductData = productDropdown?.find(a => a.id == parseInt(model.productDetailId));
            const obj = JSON.parse(particularProductData?.extra2);
            this.productTax = obj[0].taxesIds;//.map(a=>a.taxId);
          }
          let applyTaxOnAmount: number;
          var taxRate = 0;
          var totalTaxRate = 0;
          var totalTaxRateExceptMaking = 0;
          var calculateTax = false;
          var calculationMode: any;
          if (this.metalSectionModel.voucherWiseData.applicableOnProductsByCustomer?.length > 0) {
            var element = this.metalSectionModel.voucherWiseData.applicableOnProductsByCustomer?.filter(a => a.id == tax.id.toString());
            var taxAmount = 0;
            var taxableAmount = 0;
            element.forEach(taxByCustomer => {
              applyTaxOnAmount = 0;
              taxRate = tax.rate;
              calculationMode = undefined;
              calculateTax = taxByCustomer != undefined;
              var taxOnMakingExist = false;
              switch (Number(taxByCustomer.extra3)) {
                case LedgerItemTypeTaxes.Gold:
                  if (metalId == MetalType.Gold) {
                    calculationMode = CalculateOn.ProductAmount;
                    taxOnMakingExist = this.metalSectionModel.voucherWiseData.applicableOnProductsByCustomer.filter(a => Number(a.extra3) === LedgerItemTypeTaxes.GoldMaking)?.length > 0;
                  }
                  if (metalId == MetalType.Diamond) {
                    calculationMode = CalculateOn.GoldAmount;
                    taxOnMakingExist = this.metalSectionModel.voucherWiseData.applicableOnProductsByCustomer.filter(a => Number(a.extra3) === LedgerItemTypeTaxes.GoldMaking)?.length > 0;
                  }
                  break;
                case LedgerItemTypeTaxes.GoldMaking:
                  if (metalId == MetalType.Gold) {
                    calculationMode = CalculateOn.MakingCharge;
                    taxOnMakingExist = this.metalSectionModel.voucherWiseData.applicableOnProductsByCustomer.filter(a => Number(a.extra3) === LedgerItemTypeTaxes.GoldMaking)?.length > 0;
                  }
                  if (metalId == MetalType.Diamond) {
                    calculationMode = CalculateOn.MakingCharge;
                    taxOnMakingExist = this.metalSectionModel.voucherWiseData.applicableOnProductsByCustomer.filter(a => Number(a.extra3) === LedgerItemTypeTaxes.GoldMaking)?.length > 0;
                  }
                  break;
                case LedgerItemTypeTaxes.Silver:
                  if (metalId == MetalType.Silver) {
                    calculationMode = CalculateOn.ProductAmount;
                    taxOnMakingExist = this.metalSectionModel.voucherWiseData.applicableOnProductsByCustomer.filter(a => Number(a.extra3) === LedgerItemTypeTaxes.SilverMaking)?.length > 0;
                  }
                  break;
                case LedgerItemTypeTaxes.SilverMaking:
                  if (metalId == MetalType.Silver) {
                    calculationMode = CalculateOn.MakingCharge;
                    taxOnMakingExist = this.metalSectionModel.voucherWiseData.applicableOnProductsByCustomer.filter(a => Number(a.extra3) === LedgerItemTypeTaxes.SilverMaking)?.length > 0;
                  }
                  break;
                case LedgerItemTypeTaxes.Diamond:
                  if (metalId == MetalType.Diamond && model.category == DiamondCategory.DiamondJewellery)
                    calculationMode = CalculateOn.ProductAmount;

                  break;
                case LedgerItemTypeTaxes.LooseDiamond:
                  if (metalId == MetalType.Diamond && model.category == DiamondCategory.LooseDiamond)
                    calculationMode = CalculateOn.ProductAmount;
                  break;
                case LedgerItemTypeTaxes.CertifiedDiamond:
                  if (metalId == MetalType.Diamond && model.category == DiamondCategory.CeritifiedDiamond)
                    calculationMode = CalculateOn.ProductAmount;
                  break;
              }


              // if(this.formModel.invoiceModel.internalFix == true && )
              //   calculationMode = CalculateOn.MakingCharge;
              switch (Number(calculationMode)) {
                case CalculateOn.HallmarkAmount: applyTaxOnAmount = Number(model.hallmarkAmount ?? 0)
                case CalculateOn.GoldAmount: applyTaxOnAmount = Number(model.goldAmount ?? 0); break;
                case CalculateOn.MakingCharge: applyTaxOnAmount = Number(model.makingAmount ?? 0); break;
                case CalculateOn.ProductAmount: applyTaxOnAmount = taxOnMakingExist == true ? Number(model.amount ?? 0) : Number(model.netAmount ?? 0); break;
                default: applyTaxOnAmount = 0; break;
              }
              taxableAmount += applyTaxOnAmount
              taxAmount += (calculateTax && this.formModel.invoiceModel.vat != false) ? (applyTaxOnAmount * Number(taxRate ?? 0)) / 100 : 0;
              totalTaxRate += (calculationMode != undefined) ? taxRate : 0;
              totalTaxRateExceptMaking += (calculationMode != CalculateOn.MakingCharge && calculationMode != undefined) ? Number(taxRate ?? 0) : 0;
            });
            item.taxableAmount = taxableAmount;
            item.taxAmount = taxAmount;
            item.taxAmount = this.fixAmountDecimal(taxAmount);
            item.taxRate = calculateTax ? Number(totalTaxRate ?? 0) : 0;
            item.taxValue = calculateTax ? Number(totalTaxRate ?? 0) : 0;
            item.taxRateExceptMaking = totalTaxRateExceptMaking;
            item.calculateTax = ((calculateTax && this.formModel.invoiceModel.vat != false) && calculateTax);
            item.taxName = tax.name;
          }
          else {
            calculateTax = this.productTax.some(a => a.taxId === tax.id);
            taxRate = calculateTax ? this.productTax.filter(a => a.taxId === tax.id)[0].taxValue : tax.rate;
            calculationMode = this.productTax.filter(a => a.taxId === tax.id)[0]?.calculationMode;

            var taxOnMakingExist = this.productTax.filter(a => a.calculationMode === CalculateOn.MakingCharge)?.length > 0;
            if (this.formModel.invoiceModel.internalFix == true)
              calculationMode = CalculateOn.MakingCharge;
            switch (Number(calculationMode)) {
              case CalculateOn.HallmarkAmount: applyTaxOnAmount = Number(model.hallmarkAmount ?? 0); break;
              case CalculateOn.MakingCharge: applyTaxOnAmount = Number(model.makingAmount ?? 0); break;
              case CalculateOn.ProductAmount: applyTaxOnAmount = (taxOnMakingExist == true ? Number(model.amount ?? 0) : Number(model.netAmount ?? 0)) - Number(model.discountAmount ?? 0); break;
              default: applyTaxOnAmount = 0; break;
            }
            item.taxableAmount = applyTaxOnAmount;
            item.taxAmount = (calculateTax && this.formModel.invoiceModel.vat != false) ? (applyTaxOnAmount * Number(taxRate ?? 0)) / 100 : 0;
            item.taxAmount = this.fixAmountDecimal(item.taxAmount);
            item.taxRate = calculateTax ? Number(taxRate ?? 0) : 0;
            item.taxValue = calculateTax ? Number(taxRate ?? 0) : 0;
            item.taxRateExceptMaking = (calculateTax && calculationMode != CalculateOn.MakingCharge) ? Number(taxRate ?? 0) : 0;
            item.calculateTax = ((calculateTax && this.formModel.invoiceModel.vat != false) && calculateTax);
            item.taxName = tax.name;
          }
        }
      }
    model.taxableAmount = model.itemTaxDetails?.reduce((acc, curr) => acc + (Number(curr.taxableAmount) || 0), 0) || 0;
    model.taxAmount = model.itemTaxDetails?.reduce((acc, curr) => acc + (Number(curr.taxAmount) || 0), 0) || 0;
    model.taxRate = model.itemTaxDetails?.filter(a => a.calculateTax == true).reduce((acc, curr) => acc + (curr.taxRate || 0), 0) || 0;
    model.taxValue = model.taxRate;
    model.taxRateExceptMaking = model.itemTaxDetails?.reduce((acc, curr) => acc + (curr.taxRateExceptMaking || 0), 0) || 0;
    return model;
  }

  taxCalculation(model, metalId, isBarcodeScan: boolean = false, fromReverse = false, calculateTax = true) {
    if (model.parentMetalId != 4)
      model = this.taxCalculationLoop(model, metalId, isBarcodeScan, fromReverse, calculateTax);

    model.netAmountWithTax = Number(model.netAmount ?? 0) + Number(model.taxAmount ?? 0);
    model.itemTaxDetails?.forEach((key, value) => { model[key.taxName] = key.taxAmount?.toString(); });
    model.diamondAmount = this.fixAmountDecimal(model.diamondAmount);
    model.purchaseAmount = this.fixAmountDecimal(model.purchaseAmount);
    model.saleAmount = this.fixAmountDecimal(model.saleAmount);
    model.goldAmount = this.fixAmountDecimal(model.goldAmount);
    model.stoneAmount = this.fixAmountDecimal(model.stoneAmount);
    model.makingAmount = this.fixAmountDecimal(model.makingAmount);
    model.makingRate = this.fixAmountDecimal(model.makingRate);
    model.hallmarkRate = this.fixAmountDecimal(model.hallmarkRate);
    model.metalValue = this.fixAmountDecimal(model.metalValue);
    model.amount = this.fixAmountDecimal(model.amount);
    model.netAmount = this.fixAmountDecimal(model.netAmount);
    model.makingDiscount = this.fixAmountDecimal(model.makingDiscount);
    model.taxAmount = this.fixAmountDecimal(model.taxAmount);
    model.netAmountWithTax = this.fixAmountDecimal(model.netAmountWithTax);
    model.requestedPurityPer = this.fixWeightDecimal(model.requestedPurityPer);
    model.requestedWt = this.fixWeightDecimal(model.requestedWt);
    model.alloyWt = this.fixWeightDecimal(model.alloyWt);
    model.goldWt = this.fixWeightDecimal(model.goldWt);
    model.grossWt = this.fixWeightDecimal(model.grossWt);
    model.netWt = this.fixWeightDecimal(model.netWt);
    model.packetWt = this.fixWeightDecimal(model.packetWt);
    model.packetLessWt = this.fixWeightDecimal(model.packetLessWt);
    model.purityWt = this.fixWeightDecimal(model.purityWt);
    model.finalWt = this.fixWeightDecimal(model.finalWt);
    model.wastageWt = this.fixWeightDecimal(model.wastageWt);
    model.goldWt = this.fixWeightDecimal(model.goldWt);
    model.carat = this.fixWeightDecimal(model.carat);
    model.weight = this.fixWeightDecimal(model.weight);
    model.goldPurityWt = this.fixWeightDecimal(model.goldPurityWt);
    model.weight = this.fixWeightDecimal(model.weight);
    this.refreshAllCells();
  }

  refreshAllCells(all: boolean = false) {
    if (all == false)
      switch (this.tabId) {
        case 1: this.gridApiGoldTable?.api?.refreshCells(); break;
        case 2: this.gridApiSilverTable?.api?.refreshCells(); break;
        case 4: this.gridApiDiamondTable?.api?.refreshCells(); break;
        case 5: this.gridApiImmitationAndWatchesTable?.api?.refreshCells(); break;
      }
    else {
      this.gridApiGoldTable?.api?.refreshCells();
      this.gridApiSilverTable?.api?.refreshCells();
      this.gridApiDiamondTable?.api?.refreshCells();
      this.gridApiImmitationAndWatchesTable?.api?.refreshCells();
    }
  }

  calculateGoldSilverItem(model, metalid, fromDiamond = false, isBarcodeScan = false, fromReverse = false, calculateTax = true) {
    model.reverseCalculationAmount = Number(model.reverseCalculationAmount ?? 0);
    model.diamondAmount = Number(model.diamondAmount ?? 0);
    model.stoneAmount = Number(model.stoneAmount ?? 0);
    model.makingAmount = Number(model.makingAmount ?? 0);
    model.makingRate = Number(model.makingRate ?? 0);
    model.hallmarkRate = Number(model.hallmarkRate ?? 0);
    model.quantity = model.quantity === 0 ? 1 : model.quantity;
    model.grossWt = ((model.packetWt ?? 0) > 0) ? (Number(model.packetWt ?? 0) - Number(model.packetLessWt ?? 0)) : model.grossWt ?? 0;
    model.netWt = Number(model.grossWt ?? 0) - Number(model.lessWt ?? 0);
    model.purityWt = Number(model.netWt ?? 0) * (Number(model.purityPer == null || model.purityPer == 0 ? 1 : model.purityPer));
    model.wastageWt = (Number(model.netWt ?? 0) * (Number(model.wastagePer ?? 0))) / 100;
    model.requestedWt = (model.requestedPurityPer != undefined && Number(model.requestedPurityPer ?? 0) != 0) ? Number(model.purityWt ?? 0) / Number(model.requestedPurityPer ?? 1) : 0;
    model.alloyWt = Number(model.requestedWt ?? 0) > 0 ? (Number(model.requestedWt ?? 0) - Number(model.netWt ?? 0)) : 0;
    model.finalWt = (model.purityWt ?? 0) + Number(model.wastageWt ?? 0);
    model.rate = Number(model.ratePer) > 0 ? (Number(model.rate ?? 0) / (Number(model.ratePer) / 100)) : model.rate ?? 0;
    model.metalValue = (model.finalWt ?? 0) * (model.rate ?? 0);

    const applyMakingDiscount = true;
    const applyMakingOn: string = "Net";

    let caratRate: number = 0;
    let disc: number = 0;

    const applyMakingOnAmount: number = Number((model.makingTypeId != null && Number(model.makingTypeId) === MakingChargeType.PP) ? Number(model.quantity) : Number(applyMakingOn === "Net" ? (model.netWt ?? 0) : (applyMakingOn === "Gross" ? (model.grossWt ?? 0) : (applyMakingOn === "Final" ? (model.finalWt ?? 0) : 0))));
    disc = Number((applyMakingDiscount && model.makingDiscount !== 0) ? ((applyMakingOnAmount * ((model.makingTypeId != null && Number(model.makingTypeId) === MakingChargeType.PER_Percent) ? Number(model.rate ?? 0) : 1) * Number(model.makingRate ?? 0)) / 100) * (Number(model.makingDiscount ?? 0) / 100) / 100 : 0);

    if ((model.makingTypeId != null && Number(model.makingTypeId) === MakingChargeType.PG) || (model.makingTypeId != null && Number(model.makingTypeId) === MakingChargeType.PP) || (model.makingTypeId != null && Number(model.makingTypeId) === MakingChargeType.PKg))
      model.makingAmount = ((applyMakingOnAmount * Number(model.makingRate ?? 0)) - disc) / ((Number(model.makingTypeId) === MakingChargeType.PKg) ? 1000 : 1);
    if (model.makingTypeId != null && Number(model.makingTypeId) === MakingChargeType.PER_Percent)
      model.makingAmount = ((applyMakingOnAmount * Number(model.rate) * Number(model.makingRate ?? 0)) / 100) - disc;
    if (model.makingTypeId != null && Number(model.makingTypeId) === MakingChargeType.MKT)
      model.makingAmount = ((applyMakingOnAmount * caratRate) / 100 * Number(model.makingRate ?? 0)) - disc;
    if (model.makingTypeId != null && Number(model.makingTypeId) === MakingChargeType.FIX)
      model.makingAmount = Number(model.makingAmount ?? 0) - disc;
    model.hallmarkAmount = Number(model.quantity ?? 1) * Number(model.hallmarkRate ?? 0);

    model.amount = this.formModel?.invoiceModel?.metalFix ? 0 : ((model.makingTypeId != null && Number(model.makingTypeId) === MakingChargeType.MRP) ? Number(model.rate ?? 0) : (Number(model.rate ?? 0) * (model.purityWt ?? 0)));
    var amount = this.formModel?.invoiceModel?.metalFix ? model.amount : model.amount

    model.netAmount = Number(model.makingAmount ?? 0) + (Number(amount ?? 0)) + Number(model.diamondAmount ?? 0) + Number(model.stoneAmount ?? 0);
    model.makingDiscount = disc;
    if (model.parentMetalId == MetalType.Diamond) {
      var rowNodeAtDiamondTable = this.gridApiDiamondTable.api.getRowNode(0);
      if (rowNodeAtDiamondTable != undefined) {
        rowNodeAtDiamondTable.data.goldWt = model.grossWt;
        rowNodeAtDiamondTable.data.goldPurityPer = model.purityPer;
        rowNodeAtDiamondTable.data.goldPurityWt = model.purityWt;
        rowNodeAtDiamondTable.data.goldAmount = model.netAmount;
        rowNodeAtDiamondTable.data.goldCaratId = model.caratId;
        this.calculateDiamondItem(rowNodeAtDiamondTable.data, "", true);
        if (fromDiamond == false)
          setTimeout(() => {
            this.agGridService.appendRow(this.gridApiDiamondTable, rowNodeAtDiamondTable.data);
          }, 1000);
      }
    }
    this.taxCalculation(model, metalid, isBarcodeScan, fromReverse, calculateTax);
  }

  reversecalculateGoldSilverItem(model, metalid) {
    if (model.reverseCalculationAmount != 0 && model.reverseCalculationAmount != "" && model.reverseCalculationAmount != undefined) {
      var rate = 0;
      var mktAmt = 0;
      var netAmount = 0;
      var makingAmount = 0;
      if (parseFloat(model.taxRate) - parseFloat(model.taxRateExceptMaking) == 0) {
        netAmount = Number((Number(model.reverseCalculationAmount) * 100) / (100 + (parseFloat(model.taxRateExceptMaking))));
        makingAmount = netAmount - Number(model.amount)
      }
      else {
        var amountTax = Number(model.amount ?? 0) * (parseFloat(model.taxRateExceptMaking) / 100);
        var makingAmountWithTax = Number(model.reverseCalculationAmount) - model.amount - amountTax;
        var makingTax = 0;
        makingAmount = Number((Number(makingAmountWithTax) * 100) / (100 + (parseFloat(model.taxRate) - parseFloat(model.taxRateExceptMaking)))) - makingTax;
      }

      var makingRate = 0;

      if (model.grossWt != 0) {
        const applyMakingOn: string = "Net";
        const applyMakingOnWt: number = Number((model.makingTypeId != null && Number(model.makingTypeId) === MakingChargeType.PP) ? Number(model.quantity) : Number(applyMakingOn === "Net" ? (model.netWt ?? 0) : (applyMakingOn === "Gross" ? (model.grossWt ?? 0) : (applyMakingOn === "Final" ? (model.finalWt ?? 0) : 0))));
        rate = (model.makingTypeId != null && model.makingTypeId != MakingChargeType.MRP) ? ((netAmount - model.makingAmount - model.stoneAmount - model.diamondAmount) / model.finalWt) : (Number(model.netAmount));

        if ((model.makingTypeId !== null && Number(model.makingTypeId) === MakingChargeType.PG) || (model.makingTypeId !== null && model.makingTypeId === MakingChargeType.PKg))
          makingRate = makingAmount / applyMakingOnWt;
        if (model.makingTypeId !== null && Number(model.makingTypeId) === MakingChargeType.PP)
          makingRate = makingAmount / Number(model.quantity ?? 1);
        if (model.makingTypeId !== null && Number(model.makingTypeId) === MakingChargeType.PER_Percent)
          makingAmount = (((netAmount - Number(model.amount) - Number(model.stoneAmount) - Number(model.diamondAmount)) * 100) / (Number(model.rate) * applyMakingOnWt));
        if (model.makingTypeId !== null && Number(model.makingTypeId) === MakingChargeType.MKT)
          makingAmount = (((netAmount - Number(model.amount) - Number(model.stoneAmount) - Number(model.diamondAmount)) * 100) / mktAmt);
        if (model.makingTypeId !== null && Number(model.makingTypeId) === MakingChargeType.FIX)
          makingRate = 0;
      }

      model.makingAmount = makingAmount;
      model.makingRate = makingRate;
      this.calculateGoldSilverItem(model, metalid, false, false, true);
    }
  }

  async calculateImitationItem(model, isBarcodeScan = false, fromReverse = 0) {
    model.packetWt = Number(model.packetWt ?? 0);
    model.packetLessWt = Number(model.packetLessWt ?? 0);
    model.grossWt = Number(model.grossWt ?? 0);
    model.amount = Number(model.amount ?? 0);
    model.hallmarkRate = Number(model.hallmarkRate ?? 0);
    model.netAmount = Number(model.quantity ?? 0) * Number(model.saleAmount ?? 0);
    model.discountAmount = (Number(model.netAmount ?? 0) * Number(model.discountPer ?? 0)) / 100;
    model.grossWt = (Number(model.packetWt ?? 0) > 0) ? (Number(model.packetWt ?? 0) - Number(model.packetLessWt ?? 0)) : Number(model.grossWt ?? 0);
    this.taxCalculation(model, MetalType.Imitation, isBarcodeScan);
  }

  async calculateDiamondItem(model, calculateByField, fromGold = false, isBarcodeScan = false, addOtherRowsAmount: number = 0) {

    const rowsdata = this.gridApiDiamondTable.api?.getModel()?.rowsToDisplay?.map(a => a?.data);
    model = this.metalSectionService.calculateDiamondItem(model, calculateByField, addOtherRowsAmount, rowsdata);

    if (model.diamondCategoryId != DiamondCategory.LooseDiamond && model.diamondCategoryId != DiamondCategory.CeritifiedDiamond && model.diamondCategoryId != undefined) {
      switch (calculateByField) {
        case "carat":
        case "grossWt":
        case "weight":
          model.goldWt = Number(model.grossWt ?? 0) - Number(model.weight ?? 0);
          break;
      }
      model.goldPurityWt = Number(model.goldWt ?? 0) * (Number(model.goldPurityPer == null || model.goldPurityPer == 0 ? 1 : model.goldPurityPer));
      model.goldAmount = Number(model.goldPurityWt ?? 0) * Number(model.goldRate ?? 0);
    }
    model.goldAmount = (this.formModel != undefined && this.formModel?.invoiceModel?.metalFix) ? 0 : model.goldAmount;
    const applyMakingOnAmount: number = (model.makingTypeId !== null && Number(model.makingTypeId) === MakingChargeType.PP) ? Number(model.quantity ?? 1) : ((model.makingTypeId !== null && Number(model.makingTypeId) === MakingChargeType.PG) ? Number(model.grossWt ?? 0) : 0);
    if ((model.makingTypeId !== null && Number(model.makingTypeId) === MakingChargeType.PG) || (model.makingTypeId !== null && model.makingTypeId === MakingChargeType.PP) || (model.makingTypeId !== null && model.makingTypeId === MakingChargeType.PKg))
      model.makingAmount = (applyMakingOnAmount * Number(model.makingRate ?? 0)) / ((model.makingTypeId === MakingChargeType.PKg) ? 1000 : 1);
    if (model.makingTypeId !== null && Number(model.makingTypeId) === MakingChargeType.PER_Percent)
      model.makingAmount = (applyMakingOnAmount * Number(model.rate ?? 0) * Number(model.makingRate ?? 0)) / 100;
    if (model.makingTypeId !== null && Number(model.makingTypeId) === MakingChargeType.MKT)
      model.makingAmount = (applyMakingOnAmount * Number(model.rate ?? 0)) / 100 * Number(model.makingRate ?? 0);
    if (model.makingTypeId !== null && Number(model.makingTypeId) === MakingChargeType.FIX)
      model.makingAmount = Number(model.makingAmount ?? 0);
    model.hallmarkAmount = Number(model.quantity ?? 1) * Number(model.hallmarkRate ?? 0);
    model.netAmount = Number(model.amount ?? 0) + Number(model.stoneAmount ?? 0) + Number(model.certificateAmount ?? 0) + (this.formModel.invoiceModel.metalFix == true ? 0 : Number(model.goldAmount ?? 0)) + Number(model.makingAmount ?? 0) + Number(model.otherAmount ?? 0);
    model.discountAmount = (Number(model.netAmount ?? 0) * Number(model.discountPer ?? 0)) / 100;
    model.purchaseAmount = model.netAmount;
    this.taxCalculation(model, MetalType.Diamond, isBarcodeScan);
    if (model.diamondCategoryId != DiamondCategory.DiamondJewellery.toString()  && rowsdata.length > 1) {
      const rowsdata = this.gridApiDiamondTable.api?.getModel()?.rowsToDisplay?.map(a => a?.data);
      const firstRowNode = rowsdata[0];
      var exceptJewelleryRow = rowsdata?.filter(row => Number(row?.diamondCategoryId) !== Number(DiamondCategory.DiamondJewellery));
      const sumOfAmount = (exceptJewelleryRow?.map(a => Number(a?.amount))?.filter(value => typeof value === 'number' && !isNaN(value))?.reduce((a, b) => (a || 0) + (b || 0), 0) || 0);
      const sumOfCarat = (exceptJewelleryRow?.map(a => Number(a?.carat))?.filter(value => typeof value === 'number' && !isNaN(value))?.reduce((a, b) => (a || 0) + (b || 0), 0) || 0);
      const sumOfWeight = (exceptJewelleryRow?.map(a => Number(a?.weight))?.filter(value => typeof value === 'number' && !isNaN(value))?.reduce((a, b) => (a || 0) + (b || 0), 0) || 0);
      const sumOfRate = (exceptJewelleryRow?.map(a => Number(a?.rate))?.filter(value => typeof value === 'number' && !isNaN(value))?.reduce((a, b) => (a || 0) + (b || 0), 0) || 0);
      // firstRowNode.rate = this.fixAmountDecimal(sumOfRate);
      firstRowNode.weight = this.fixWeightDecimal(sumOfWeight);
      firstRowNode.carat = this.fixWeightDecimal(sumOfCarat);
      firstRowNode.colorCode = "pink";
      this.calculateDiamondItem(firstRowNode, "grossWt", false, false, sumOfAmount);
      this.gridApiDiamondTable?.api?.refreshCells();
    }
    // else
    // {

    // }

  }

  reverseCalculateDiamondItem(model) {
    if (model.reverseCalculationAmount != 0 && model.reverseCalculationAmount != "" && model.reverseCalculationAmount != undefined) {
      var withoutTax = Number((Number(model.reverseCalculationAmount) * 100) / (100 + Number(model.taxRate)));
      var reverseAmount = withoutTax - Number(model.stoneAmount ?? 0) - Number(model.certificateAmount ?? 0) - Number(model.goldAmount ?? 0) - Number(model.makingAmount ?? 0) - Number(model.otherAmount ?? 0);
      var rate = reverseAmount / Number(model.carat);
      if (model.calculationType == CalculationType.Fix)
        rate = reverseAmount;
      model.rate = rate;
      this.calculateDiamondItem(model, "reverseCalculationAmount");
    }
  }

  onItemListRowClickedResponse(event, IsBarcodeScan = false, parentMetalId = undefined, result?: any) {
    var records: any;
    if (event != undefined)
      records = JSON.parse(event);
    else {
      records = {};
      var defaultEntry = [{ grossWt: result.totalWt, quantity: result.totalQty, purityPer: "1.00", purityWt: result.finalWt }];
      switch (this.tabId) {
        case 1: records.goldItem = defaultEntry; break;
        case 2: records.silverItem = defaultEntry; break;
        case 4: records.diamondItem = defaultEntry; break;
        case 6: records.imitationItem = defaultEntry; break;
      }
    }

    if (this.metalSectionModel.voucherWiseData != undefined) {
      this.agGridService.cameraButtonDisabled = true;
      records.goldItem?.forEach(item => {
        this.callCalulationApi(item, IsBarcodeScan, "", 1);
        this.metalSectionModel.voucherWiseData.applicableOnProducts.forEach(element => {
          var tax = item.itemTaxDetails.filter(a => a.taxId == element.id);
          if (tax.length > 0) {
            var taxAmount = tax[0].taxAmount != undefined ? tax[0].taxAmount : 0;
            var taxName = element.name;
            item[taxName] = taxAmount.toString();
          }
        });
      });
      records.silverItem?.forEach(item => {
        this.callCalulationApi(item, IsBarcodeScan, "", 2);
        this.metalSectionModel.voucherWiseData.applicableOnProducts.forEach(element => {
          var tax = item.itemTaxDetails.filter(a => a.taxId == element.id);
          if (tax.length > 0) {
            var taxAmount = tax[0].taxAmount != undefined ? tax[0].taxAmount : 0;
            var taxName = element.name;
            item[taxName] = taxAmount.toString();
          }
        });
      });
      records.diamondItem?.forEach(item => {
        this.assignRateGoldCaratWise(item);


        this.callCalulationApi(item, IsBarcodeScan, "carat", 4);
        this.metalSectionModel.voucherWiseData.applicableOnProducts.forEach(element => {
          var tax = item.itemTaxDetails.filter(a => a.taxId == element.id);
          if (tax.length > 0) {
            var taxAmount = tax[0].taxAmount != undefined ? tax[0].taxAmount : 0;
            var taxName = element.name;
            item[taxName] = taxAmount.toString();
          }
        });
      });


      records.imitationItem?.forEach(item => {
        this.callCalulationApi(item, IsBarcodeScan, "", 6);
        this.metalSectionModel.voucherWiseData.applicableOnProducts.forEach(element => {
          var tax = item.itemTaxDetails.filter(a => a.taxId == element.id);
          if (tax.length > 0) {
            var taxAmount = tax[0].taxAmount != undefined ? tax[0].taxAmount : 0;
            var taxName = element.name;
            item[taxName] = taxAmount.toString();
          }
        });
      });
      // in checking
      this.metalSectionModel.comment = result?.comment;
      this.metalSectionModel.groupName = result?.groupName;
    }
    this.setTabItemColors(records);
    this.gridApiGoldTable.api.setRowData(records.goldItem);
    this.gridApiSilverTable.api.setRowData(records.silverItem);
    this.gridApiDiamondTable.api.setRowData(records.diamondItem);

    const rowsdata = this.gridApiDiamondTable.api?.getModel()?.rowsToDisplay?.map(a => a?.data);
    const firstRowNode = rowsdata[0];
    var exceptJewelleryRow = rowsdata?.filter(row => Number(row?.diamondCategoryId) !== Number(DiamondCategory.DiamondJewellery));
    const sumOfAmount = (exceptJewelleryRow?.map(a => Number(a?.amount))?.filter(value => typeof value === 'number' && !isNaN(value))?.reduce((a, b) => (a || 0) + (b || 0), 0) || 0);
    const sumOfCarat = (exceptJewelleryRow?.map(a => Number(a?.carat))?.filter(value => typeof value === 'number' && !isNaN(value))?.reduce((a, b) => (a || 0) + (b || 0), 0) || 0);
    const sumOfWeight = (exceptJewelleryRow?.map(a => Number(a?.weight))?.filter(value => typeof value === 'number' && !isNaN(value))?.reduce((a, b) => (a || 0) + (b || 0), 0) || 0);
    const sumOfRate = (exceptJewelleryRow?.map(a => Number(a?.rate))?.filter(value => typeof value === 'number' && !isNaN(value))?.reduce((a, b) => (a || 0) + (b || 0), 0) || 0);
    if (firstRowNode) {
      firstRowNode.carat = this.fixWeightDecimal(sumOfCarat);
    }
    this.calculateDiamondItem(firstRowNode, 'goldWt', false, false, sumOfAmount);

    this.gridApiImmitationAndWatchesTable.api.setRowData(records.imitationItem);
    this.uploadImagesList = records.invoiceItemImageResources ?? [];
    if (parentMetalId != undefined)
      records.parentMetalId = parentMetalId;
    if (records.parentMetalId != 0 && records.parentMetalId != null)
      this.selectNavTab(records.parentMetalId);
    else
      this.selectTabAccordingBarcode(records);
  }

  setTabItemColors(records) {
    this.tabItems = this.tabItems.map(item => {
      if ((item.id === 1 && records.goldItem?.length > 0) ||
        (item.id === 2 && records.silverItem?.length > 0) ||
        (item.id === 4 && records.diamondItem?.length > 0) ||
        (item.id === 5 && records.imitationItem?.length > 0)
      ) {
        return { ...item, color: "lightgray" };
      } else return item;
    });
  }

  addMultipleItems(element) {
    element.forEach(event => {
      if (event != undefined) {
        const records = JSON.parse(event.detailedJson);
        records.goldItem?.forEach(item => {
          this.metalSectionModel.voucherWiseData.applicableOnProducts.forEach(element => {
            var tax = item.itemTaxDetails.filter(a => a.taxId == element.id);
            if (tax.length > 0) {
              var taxAmount = tax[0].taxAmount;
              var taxName = element.name;
              item[taxName] = taxAmount.toString();
            }
          });
        });
        records.silverItem?.forEach(item => {
          this.metalSectionModel.voucherWiseData.applicableOnProducts.forEach(element => {
            var tax = item.itemTaxDetails.filter(a => a.taxId == element.id);
            if (tax.length > 0) {
              var taxAmount = tax[0].taxAmount;
              var taxName = element.name;
              item[taxName] = taxAmount.toString();
            }
          });
        });
        records.diamondItem?.forEach(item => {
          this.metalSectionModel.voucherWiseData.applicableOnProducts.forEach(element => {
            var tax = item.itemTaxDetails.filter(a => a.taxId == element.id);
            if (tax.length > 0) {
              var taxAmount = tax[0].taxAmount;
              var taxName = element.name;
              item[taxName] = taxAmount.toString();
            }
          });
        });
        records.imitationItem?.forEach(item => {
          this.metalSectionModel.voucherWiseData.applicableOnProducts.forEach(element => {
            var tax = item.itemTaxDetails.filter(a => a.taxId == element.id);
            if (tax.length > 0) {
              var taxAmount = tax[0].taxAmount;
              var taxName = element.name;
              item[taxName] = taxAmount.toString();
            }
          });
        });
        this.agGridService.addNewRowsWithData(this.gridApiGoldTable, records.goldItem);
        this.agGridService.addNewRowsWithData(this.gridApiSilverTable, records.silverItem);
        this.agGridService.addNewRowsWithData(this.gridApiDiamondTable, records.diamondItem);
        this.agGridService.addNewRowsWithData(this.gridApiImmitationAndWatchesTable, records.imitationItem);
      }
    });
  }
  addProductsInItemList() {
    this.getSaleInvoiceItemsDetailsModel.isMelting = this.formModel.invoiceModel.isMelting;
    this.getSaleInvoiceItemsDetailsModel.parentMetalId = this.metalSectionModel.parentMetalId;
    this.getSaleInvoiceItemsDetailsModel.goldItem = this.gridApiGoldTable?.api?.getModel()?.rowsToDisplay?.map(a => a?.data)?.filter((x) => x?.productDetailId != '0' && x?.productDetailId);
    this.getSaleInvoiceItemsDetailsModel.silverItem = this.gridApiSilverTable?.api?.getModel()?.rowsToDisplay?.map(a => a?.data)?.filter((x) => x?.productDetailId != '0' && x?.productDetailId);;
    this.getSaleInvoiceItemsDetailsModel.diamondItem = this.gridApiDiamondTable?.api?.getModel()?.rowsToDisplay?.map(a => a?.data)?.filter((x) => x?.productDetailId != '0' && x?.productDetailId);;
    this.getSaleInvoiceItemsDetailsModel.imitationItem = this.gridApiImmitationAndWatchesTable?.api?.getModel()?.rowsToDisplay?.map(a => a?.data)?.filter((x) => x?.productDetailId != '0' && x?.productDetailId);;
    this.getSaleInvoiceItemsDetailsModel.itemList = this.formModel.itemList;//this.gridApiForSalesInvoiceItemListTable                  ?.api?.getModel()?.rowsToDisplay?.map(a => a.data);
    this.getSaleInvoiceItemsDetailsModel.invoiceTaxDetail = this.formModel.invoiceTaxDetail;//this.gridApiForSalesInvoiceTaxTable                       ?.api?.getModel()?.rowsToDisplay?.map(a => a.data);
    this.getSaleInvoiceItemsDetailsModel.VoucherTypeId = this.formModel.invoiceModel.voucherTypeId;
    this.getSaleInvoiceItemsDetailsModel.invoiceItemImageResources = this.uploadImagesList;
    this.getSaleInvoiceItemsDetailsModel.comment = this.metalSectionModel.comment;
    this.getSaleInvoiceItemsDetailsModel.groupName = this.metalSectionModel.groupName;
    this.getSaleInvoiceItemsDetailsModel.vat = this.formModel.invoiceModel.vat;
    this.getSaleInvoiceItemsDetailsModel.isGroup = this.getSaleInvoiceItemsDetailsModel.groupName === "" ? false : true;
    this.transactionService.getInvoiceItemWithDescription(this.getSaleInvoiceItemsDetailsModel,)
      .pipe(catchError((err) => this.handleError(err)))
      .subscribe((res: ResponseModel) => {
        this.formModel.itemTaxDetails = res.response.invoiceTaxDetail;
        this.formModel.invoiceModel.groupName = "";
        this.storageService.onAddProductsInItemList.next(res);
        this.clearAllMetals();
        res.isSuccess == true ? this.agGridService.cameraButtonDisabled = false : true;
        this.uploadImagesList = [];
        this.isImageSaved = false;
        this.metalSectionModel.comment = null;
        this.metalSectionModel.groupName = null;
        this.barcodeNo = "";
        if (this.formModel.voucherType === VoucherTypeName.PhysicalStock || this.formModel.voucherType === VoucherTypeName.StockJournal) {
          const detailedJson = JSON.stringify(this.getSaleInvoiceItemsDetailsModel);
          this.onItemListRowClickedResponse(detailedJson, false, this.getSaleInvoiceItemsDetailsModel.parentMetalId);
          const gridApiList = [this.gridApiGoldTable];
          for (const item of gridApiList) {
            const column = item.api?.setFocusedCell(0, 'grossWt');
            item.api?.startEditingCell({ rowIndex: 0, colKey: 'grossWt' });
            const inputField = document.querySelector('.ag-cell-edit-input') as HTMLInputElement;
            if (inputField) {
              inputField.focus(); // Focus on the input field
              inputField.setSelectionRange(0, inputField.value.length);
            }
          }
        }
      });

    //on add button reset scoll position in all grid
    const gridApiList = [this.gridApiGoldTable, this.gridApiDiamondTable, this.gridApiSilverTable, this.gridApiImmitationAndWatchesTable,];
    for (let item of gridApiList)
      this.getColumnState(item);
  }

  resetTabs() {
    this.navTabsComponent.resetTabColor();
  }

  getColumnState(gridApi) {
    if (gridApi) {
      const columnState = gridApi?.columnApi?.getColumnState();
      if (columnState && columnState?.length > 0) {
        const visibleColumnList = columnState.filter((x) => (x.hide != true) && (x.pinned != 'left'));
        const firstColumnState = visibleColumnList[0];
        const firstColumnId = firstColumnState?.colId;
        gridApi?.api?.ensureColumnVisible(firstColumnId);
      }
    }
  }

  stateWiseTaxList(data) {
    var applicableOnProducts = this.applicableOnProducts?.filter(a => a.extra2 == data[0].extra1)
    //  this.onTaxChangeResponse(applicableOnProducts);
  }

  resetInvoiceResponse(formName) {
    if (formName != undefined) {
      this.clearAllMetals();
      var previousVoucherType = this.formModel.voucherType;
      var previousInvoiceModel = this.formModel.invoiceModel;
      this.formModel = {};
      this.formModel.invoiceModel = {};
      this.formModel.invoiceModel.internalFix = previousInvoiceModel.internalFix;
      this.formModel.invoiceModel.metalFix = previousInvoiceModel.internalFix;
      this.formModel.invoiceModel.vat = previousInvoiceModel.vat;
      this.formModel.invoiceModel.voucherTypeId = previousInvoiceModel.voucherTypeId;
      this.formModel.invoiceModel.id = 0;
      this.formModel.voucherType = previousVoucherType;
    }
  }

  //#region barcode 
  getProductDataByBarcodeNo(barcodeNo, formName) {
    setTimeout(() => {
      this.fillPreviousRow();

      if (formName == undefined)
        formName = this.formModel.voucherType;
      if (barcodeNo) {
        this.transactionService.getPrductByBarcodeNo(barcodeNo, formName).subscribe({
          next: (res) => {
            if (res?.response?.detailedJson != '{"goldItem":[],"silverItem":[],"diamondItem":[],"stoneGemsItem":[],"imitationItem":[]}') {
              this.onItemListRowClickedResponse(res?.response?.detailedJson, true);
              this.barcodeNo = "";
            }
            const records = JSON.parse(res?.response?.detailedJson);
            this.selectTabAccordingBarcode(records)
          }, error: (err) => { }
        })
      }
      const navTabElement = document.querySelector('.nav-tabs') as any;
      navTabElement?.firstElementChild?.firstElementChild?.focus();
    }, 1000);
  }
  selectTabAccordingBarcode(records) {
    switch (true) {
      case records.goldItem != undefined && records.goldItem.length > 0: this.selectNavTab(1); break;
      case records.silverItem != undefined && records.silverItem.length > 0: this.selectNavTab(2); break;
      case records.diamondItem != undefined && records.diamondItem.length > 0: this.selectNavTab(4); break;
      case records.imitationItem != undefined && records.imitationItem.length > 0: this.selectNavTab(6); break;
      default: this.selectNavTab(1); break;
    }
  }
  selectNavTab(tab,disableOtherTab = false) {
    if (this.navTabsComponent == undefined)
      this.selectTab(tab);
    else
      this.navTabsComponent.selectTab(tab,disableOtherTab)
      this.selectTab(tab);
  }
  //#endregion

  handleError(arg0: any): any { }

  getAllMetals(): any {
    this.getSaleInvoiceItemsDetailsModel.goldItem = this.gridApiGoldTable?.api?.getModel()?.rowsToDisplay?.map(a => a.data);
    this.getSaleInvoiceItemsDetailsModel.silverItem = this.gridApiSilverTable?.api?.getModel()?.rowsToDisplay?.map(a => a.data);
    this.getSaleInvoiceItemsDetailsModel.diamondItem = this.gridApiDiamondTable?.api?.getModel()?.rowsToDisplay?.map(a => a.data);
    this.getSaleInvoiceItemsDetailsModel.imitationItem = this.gridApiImmitationAndWatchesTable?.api?.getModel()?.rowsToDisplay?.map(a => a.data);
    return this.getSaleInvoiceItemsDetailsModel;
  }

  getAllSelectedMetals(): any {
    this.getSaleInvoiceItemsDetailsModel.goldItem = this.gridApiGoldTable?.api?.getSelectedRows();
    this.getSaleInvoiceItemsDetailsModel.silverItem = this.gridApiSilverTable?.api?.getSelectedRows();
    this.getSaleInvoiceItemsDetailsModel.diamondItem = this.gridApiDiamondTable?.api?.getSelectedRows();
    this.getSaleInvoiceItemsDetailsModel.imitationItem = this.gridApiImmitationAndWatchesTable?.api?.getSelectedRows();
    return this.getSaleInvoiceItemsDetailsModel;
  }

  getAllActiveMetals(): any {
    this.getSaleInvoiceItemsDetailsModel.goldItem = this.gridApiGoldTable?.api?.getModel()?.rowsToDisplay?.map(a => a.data).filter(item => item.active == true);;
    this.getSaleInvoiceItemsDetailsModel.silverItem = this.gridApiSilverTable?.api?.getModel()?.rowsToDisplay?.map(a => a.data).filter(item => item.active == true);;
    this.getSaleInvoiceItemsDetailsModel.diamondItem = this.gridApiDiamondTable?.api?.getModel()?.rowsToDisplay?.map(a => a.data).filter(item => item.active == true);;
    this.getSaleInvoiceItemsDetailsModel.imitationItem = this.gridApiImmitationAndWatchesTable?.api?.getModel()?.rowsToDisplay?.map(a => a.data).filter(item => item.active == true);;
    return this.getSaleInvoiceItemsDetailsModel;
  }

  getAllMetalWithRowId(rowId): any {
    this.getSaleInvoiceItemsDetailsModel.goldItem = this.gridApiGoldTable?.api?.getModel()?.rowsToDisplay?.map(a => a.data).filter(item => item.rowId == rowId);;
    this.getSaleInvoiceItemsDetailsModel.silverItem = this.gridApiSilverTable?.api?.getModel()?.rowsToDisplay?.map(a => a.data).filter(item => item.rowId == rowId);;
    this.getSaleInvoiceItemsDetailsModel.diamondItem = this.gridApiDiamondTable?.api?.getModel()?.rowsToDisplay?.map(a => a.data).filter(item => item.rowId == rowId);;
    this.getSaleInvoiceItemsDetailsModel.imitationItem = this.gridApiImmitationAndWatchesTable?.api?.getModel()?.rowsToDisplay?.map(a => a.data).filter(item => item.rowId == rowId);;
    return this.getSaleInvoiceItemsDetailsModel;
  }

  getAllGrid(): any {
    this.gridApi.gridApiGoldTable = this.gridApiGoldTable
    this.gridApi.gridApiSilverTable = this.gridApiSilverTable
    this.gridApi.gridApiDiamondTable = this.gridApiDiamondTable
    this.gridApi.gridApiImmitationAndWatchesTable = this.gridApiImmitationAndWatchesTable
    return this.gridApi;
  }

  setAllMetals(records) {
    this.gridApiGoldTable.api.setRowData(records.goldItem);
    this.gridApiSilverTable.api.setRowData(records.silverItem);
    this.gridApiDiamondTable.api.setRowData(records.diamondItem);
    this.gridApiImmitationAndWatchesTable.api.setRowData(records.imitationItem);
    this.selectTabAccordingBarcode(records)
  }

  setColumnsVisible() {
    var columnnames = this.metalSectionModel.columnNamesToHideOrShow;
    var visible = this.metalSectionModel.columnOperationToHideOrShow;
    columnnames = columnnames == undefined ? ["requestedWt", "requestedPurityPer", "alloyWt"] : columnnames
    columnnames.forEach(element => {
      if (this.gridApiGoldTable != undefined)
        this.gridApiGoldTable.columnApi.setColumnVisible(element, visible);
      if (this.gridApiSilverTable != undefined)
        this.gridApiSilverTable.columnApi.setColumnVisible(element, visible);
      if (this.gridApiDiamondTable != undefined)
        this.gridApiDiamondTable.columnApi.setColumnVisible(element, visible);
      if (this.gridApiImmitationAndWatchesTable != undefined)
        this.gridApiImmitationAndWatchesTable.columnApi.setColumnVisible(element, visible);
    });
    if (visible == false)
      this.storageService.hideColumnsPermanent.next({ columnnames: columnnames, storageName: this.gridApi.storageName?.toString() })
    else
      this.storageService.hideColumnsPermanent.next([]);
  }


  addAllMetals(records, rowId, selectedTab = undefined) {
    const goldItem = records.goldItem.map((item, index) => ({ ...item, rowId: rowId }));
    const silverItem = records.silverItem.map((item, index) => ({ ...item, rowId: rowId }));
    const diamondItem = records.diamondItem.map((item, index) => ({ ...item, rowId: rowId }));
    const imitationItem = records.imitationItem.map((item, index) => ({ ...item, rowId: rowId }));
    this.agGridService.addNewRowsWithData(this.gridApiGoldTable, goldItem);
    this.agGridService.addNewRowsWithData(this.gridApiSilverTable, silverItem);
    this.agGridService.addNewRowsWithData(this.gridApiDiamondTable, diamondItem);
    this.agGridService.addNewRowsWithData(this.gridApiImmitationAndWatchesTable, imitationItem);
    if (selectedTab != undefined)
      this.selectTabAccordingBarcode(records)
  }

  maxRowId(): any {
    var arrayOfIndividualMax = [];
    var data = this.getAllMetals();
    var indexFromGold = data.goldItem.map(a => { return a.rowId == undefined ? 0 : a.rowId });
    const maxRowIdGold = indexFromGold.length == 0 ? 0 : Math.max(...indexFromGold);
    arrayOfIndividualMax.push(maxRowIdGold);
    var indexFromSilver = data.silverItem.map(a => { return a.rowId == undefined ? 0 : a.rowId });
    const maxRowIdSilver = indexFromSilver.length == 0 ? 0 : Math.max(...indexFromSilver);
    arrayOfIndividualMax.push(maxRowIdSilver);
    var indexFromDiamond = data.diamondItem.map(a => { return a.rowId == undefined ? 0 : a.rowId });
    const maxRowIdDiamond = indexFromDiamond.length == 0 ? 0 : Math.max(...indexFromDiamond);
    arrayOfIndividualMax.push(maxRowIdDiamond);
    var indexFromImitation = data.imitationItem.map(a => { return a.rowId == undefined ? 0 : a.rowId });
    const maxRowIdImitation = indexFromImitation.length == 0 ? 0 : Math.max(...indexFromImitation);
    arrayOfIndividualMax.push(maxRowIdImitation);
    const rowIndex = Math.max(...arrayOfIndividualMax);
    return (rowIndex ?? 0) + 1;
  }

  clearAllMetals() {
    this.gridApiGoldTable.api.setRowData([]);
    this.gridApiSilverTable.api.setRowData([]);
    this.gridApiDiamondTable.api.setRowData([]);
    this.gridApiImmitationAndWatchesTable.api.setRowData([]);
    this.formModel.invoiceModel.groupName = "";
  }

  onAddButtonPress(event: KeyboardEvent, nextElement: string) {
    if (event.key == 'Tab') {
      event.preventDefault();
      this.storageService.onAddButtonKeyPressed.next(event);
    }
    else if (event.key === 'Enter') {
      event.preventDefault();
      let element = document.getElementById(nextElement) as HTMLInputElement;
      // const element = this.renderer?.selectRootElement(nextInput);
      if (!element?.classList?.contains('pointer_none')) {
        element?.focus();
        element?.click();
      }
    }
  }

  setFooterCalcAndDisplay() {
    if (this.metalSectionModel.isGridFooter == true) {
      this.addFooterAndCalculation(this.gridApiGoldTable);
      this.addFooterAndCalculation(this.gridApiSilverTable);
      this.addFooterAndCalculation(this.gridApiDiamondTable);
      this.addFooterAndCalculation(this.gridApiImmitationAndWatchesTable);
    }
  }

  addFooterAndCalculation(params) {
    if (this.metalSectionModel?.isGridFooter) {
      const rowsdata = params?.api?.getModel()?.rowsToDisplay?.map(a => a?.data);
      const sumOfQuantity = (rowsdata?.map(a => Number(a?.quantity))?.filter(value => typeof value === 'number' && !isNaN(value))?.reduce((a, b) => (a || 0) + (b || 0), 0) || 0);
      const sumOfGrossWt = (rowsdata?.map(a => Number(a?.grossWt))?.filter(value => typeof value === 'number' && !isNaN(value))?.reduce((a, b) => (a || 0) + (b || 0), 0) || 0).toFixed(2);
      const sumOfNetWt: string = (rowsdata?.map(a => Number(a?.netWt))?.filter(value => typeof value === 'number' && !isNaN(value))?.reduce((a, b) => (a || 0) + (b || 0), 0) || 0).toFixed(2);
      const sumOfFinalWt: string = (rowsdata?.map(a => Number(a?.finalWt))?.filter(value => typeof value === 'number' && !isNaN(value))?.reduce((a, b) => (a || 0) + (b || 0), 0) || 0).toFixed(2);
      const sumOfAmount: string = (rowsdata?.map(a => Number(a?.amount))?.filter(value => typeof value === 'number' && !isNaN(value))?.reduce((a, b) => (a || 0) + (b || 0), 0) || 0).toFixed(2);
      const sumOfNetAmount: string = (rowsdata?.map(a => Number(a?.netAmount))?.filter(value => typeof value === 'number' && !isNaN(value))?.reduce((a, b) => (a || 0) + (b || 0), 0) || 0).toFixed(2);

      const totalsObject = {
        quantity: sumOfQuantity,
        grossWt: sumOfGrossWt,
        netWt: sumOfNetWt,
        finalWt: sumOfFinalWt,
        amount: sumOfAmount,
        netAmount: sumOfNetAmount,
        isFooterRow: true
      }
      params?.api?.setPinnedBottomRowData([totalsObject]);
      params?.api?.refreshCells();
    }

  }

  ngOnDestroy(): void {
    this.uploadImagesList = [];
    this.onItemListRowClickedSubscription.unsubscribe();
    this.resetInvoiceSubscription.unsubscribe();
    if (this.onAddRowButtonSubscription != undefined)
      this.onAddRowButtonSubscription.unsubscribe();
    this.onplusIconClickSubscription.unsubscribe();
    this.onNavTabKeyPressedSubscription.unsubscribe();
    this.barcodeScanValueSourceSubscription?.unsubscribe();
  }

  internalFixChecked(value) {
    this.formModel.invoiceModel.internalFix = value;
  }
  metalFixChecked(value) {
    this.formModel.invoiceModel.metalFix = value;
    this.metalFixUnfixCalc();
  }
  vatChecked(value) {
    this.formModel.invoiceModel.vat = value;
  }

  metalFixUnfixCalc() {
    this.selectAllRows();
    var allNodesGold = this.gridApiGoldTable.api.getSelectedNodes();
    allNodesGold.forEach(element => { this.calculateGoldSilverItem(element.data, MetalType.Gold); });
    var allNodesSilver = this.gridApiSilverTable.api.getSelectedNodes();
    allNodesSilver.forEach(element => { this.calculateGoldSilverItem(element.data, MetalType.Silver); });
    this.refreshAllCells(true);
    this.deselectAllRows();
  }

  KYCChecked(value) {
    this.formModel.invoiceModel.isKYC = value;
  }

  AMLChecked(value) {
    this.formModel.invoiceModel.isAML = value;
  }

  itemPresentInMetalList(): boolean {
    var getSaleInvoiceItemsDetailsModel: any = {};
    getSaleInvoiceItemsDetailsModel.goldItem = this.gridApiGoldTable?.api?.getModel()?.rowsToDisplay?.map(a => a.data).filter(item => item.productDetailId != 0 && item.productDetailId != undefined);
    getSaleInvoiceItemsDetailsModel.silverItem = this.gridApiSilverTable?.api?.getModel()?.rowsToDisplay?.map(a => a.data).filter(item => item.productDetailId != 0 && item.productDetailId != undefined);
    getSaleInvoiceItemsDetailsModel.diamondItem = this.gridApiDiamondTable?.api?.getModel()?.rowsToDisplay?.map(a => a.data).filter(item => item.productDetailId != 0 && item.productDetailId != undefined);
    getSaleInvoiceItemsDetailsModel.imitationItem = this.gridApiImmitationAndWatchesTable?.api?.getModel()?.rowsToDisplay?.map(a => a.data).filter(item => item.productDetailId != 0 && item.productDetailId != undefined);

    if (getSaleInvoiceItemsDetailsModel.goldItem?.length > 0
      || getSaleInvoiceItemsDetailsModel.silverItem?.length > 0
      || getSaleInvoiceItemsDetailsModel.diamondItem?.length > 0
      || getSaleInvoiceItemsDetailsModel.imitationItem?.length > 0
    ) return true;
    else return false;
  }

  getSelectedTab(): any { return this.tabId; }

  setSelectedTab(TabId): any { this.selectNavTab(TabId); }

  deselectAllRows() {
    this.gridApiGoldTable.api.deselectAll();
    this.gridApiSilverTable.api.deselectAll();
    this.gridApiDiamondTable.api.deselectAll();
    this.gridApiImmitationAndWatchesTable.api.deselectAll();
  }

  selectAllRows() {
    this.gridApiGoldTable.api.selectAll();
    this.gridApiSilverTable.api.selectAll();
    this.gridApiDiamondTable.api.selectAll();
    this.gridApiImmitationAndWatchesTable.api.selectAll();
  }

  //#region upload items images start
  async onSelect(event) {
    if (event.addedFiles.length > 1)
      await this.resourceMultipleFile(event.addedFiles);
    else
      await this.resourceFile(event.addedFiles[0]);
  }

  async resourceFile(item: any) {
    await this.processFile(item, true);
  }

  async resourceMultipleFile(items: any) {
    for (let index = 0; index < items.length; index++) {
      const isLastFile = index === items.length - 1;
      await this.processFile(items[index], isLastFile);
    }
  }

  async processFile(file: any, isLastFile: boolean) {
    console.log(file);
    try {
      const compressedFile = await this.compressImageService.compress(file).toPromise();
      const base64String = await this.readThis(compressedFile);
      if (base64String) {
        console.log(compressedFile);
        const img = { base64Resource: base64String };
        this.uploadImagesList.push(img);
        if (isLastFile)
          this.openAddImagePopup();
      } else console.error("Failed to get base64 string.");
    } catch (error) { console.error("Error processing file:", error); }
  }
  //#endregion

  openAddImagePopup(img?: any) {
    const modalOptions: NgbModalOptions = {
      ...commonModalOptions,
      size: "md",
      windowClass: "AddImagePopUp"
    };
    const modalRef = this.modalService.open(AddImageComponent, modalOptions);
    modalRef.componentInstance.imagesList = [...this.uploadImagesList];
    modalRef.result.then((selectedImages: any) => {
      if (selectedImages?.length) {
        this.uploadImagesList = [...selectedImages];
        this.isImageSaved = true;
      } else if (selectedImages && this.uploadImagesList && this.isImageSaved) {
        this.uploadImagesList = [...selectedImages];
      } else if (selectedImages) { } else {
        this.uploadImagesList = [];
      }
    });
  }

  //#region upload items images end


  uploadImages(event) {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      console.log(reader.result);
      this.openAddImagePopup(reader.result.toString());
    }
  }

  checkAnyGridHasRow() {
    const gridAPIs = [
      this.gridApiGoldTable?.api?.getModel()?.rowsToDisplay,
      this.gridApiSilverTable?.api?.getModel()?.rowsToDisplay,
      this.gridApiDiamondTable?.api?.getModel()?.rowsToDisplay,
      this.gridApiImmitationAndWatchesTable?.api?.getModel()?.rowsToDisplay
    ];

    return gridAPIs.some(rows => rows && rows.length > 0);
  }

  checkAnyGridHasRowWithTabs(): boolean {
    const gridApis = {
      1: this.gridApiGoldTable?.api,
      2: this.gridApiSilverTable?.api,
      4: this.gridApiDiamondTable?.api,
      6: this.gridApiImmitationAndWatchesTable?.api,
    };
    const currentApi = gridApis[this.tabId];
    const rowsToDisplay = currentApi?.getModel()?.rowsToDisplay;
    return rowsToDisplay && rowsToDisplay.length > 0;
  }

  getGridApiOnSelectdTab() {
    const gridApis = {
      1: this.gridApiGoldTable?.columnApi,
      2: this.gridApiSilverTable?.columnApi,
      4: this.gridApiDiamondTable?.columnApi,
      6: this.gridApiImmitationAndWatchesTable?.columnApi,
    };
    const displayedColumns = gridApis[this.tabId]?.getAllDisplayedColumns();;
    const displayedColDefs: ColDef[] = displayedColumns?.map(column => column?.getColDef());
    const headerNames = displayedColDefs?.map(colDef => colDef?.headerName).filter(name => name?.trim() !== '');
    // const fileName:any =  this.tabItems.find(x=>x.id==this.tabId).name;
    let tabNames: any = {
      1: 'Gold',
      2: 'Silver',
      4: 'Diamond',
      5: 'Stone',
      6: 'Imitation',
    }
    const fileName = tabNames[this.tabId] || '';
    return { headerNames, fileName };
  }

  onCommentChange(newComment: string) {
    this.comment = newComment;
    this.commentChange.emit(newComment);
  }

  onGroupNameChange(newGroupName: string) {
    this.groupName = newGroupName;
    this.groupNameChange.emit(newGroupName);
  }


  sendHeaderData() {
    this.sendDataToItemList.emit(this.getGridApiOnSelectdTab())
  }

  purchaseInvoiceNoChange(event) {
    this.formModel.invoiceModel.againstVoucherNo = event;
  }

  assignMoreSelectedItems(event, IsBarcodeScan = false, parentMetalId = undefined, result?: any) {
    this.onItemListRowClickedResponse(event, false, parentMetalId, result)
  }

}