<div class="row mx-0 h-100">
    <div class="col px-2 py-0 h-100">
      <select
        class="ag_grid_custom_dropdown cursorPointer ps-2"
        id="custom_select_options" 
        name="selcetedName"
        [(ngModel)]="value" 
        (ngModelChange)="selected($event)">
        <option *ngFor="let val of values" [value]="val.id">
          {{ val.name }}
        </option>
      </select>
    </div>
</div>