import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Optional, Output, SimpleChanges } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { AgGridService } from '../ag-grid.service';
import { DatePipe } from '@angular/common';
import {Router } from '@angular/router';
import { ControlContainer, NgForm } from '@angular/forms';

@Component({
  selector: 'app-input-datepicker-renderer',
  templateUrl: './input-datepicker-renderer.component.html',
  styleUrls: ['./input-datepicker-renderer.component.scss'],
  providers:[DatePipe],
  viewProviders: [
    {
      provide: ControlContainer,
      deps: [[Optional, NgForm]],
      useFactory: (ngForm: NgForm) => ngForm,
    },
  ],
})
export class InputDatepickerRendererComponent implements ICellRendererAngularComp {
  @Input() inputDate: string;
  @Input() isOnlyDateTime: boolean = true;
  @Input() isTodayDateDefault: boolean = true;
  @Input() id;
  @Input() selectClass:string='';
  @Input() isRequired:boolean=false;
  @Input() inputDatepickerName:string='datepickerInput';
  @Input() inputClass:string='';
  @Input() readOnly: boolean=false;
  @Input() isdisabled :boolean=false;

  params: any;
  minDate: string; // Set your min date here
  maxDate: string; // Set your max date here
  datePattern: RegExp = /^(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])-(19[1-9][0-9]|20[0-9]{2})$/;
  @Output() dateChanged: EventEmitter<string> = new EventEmitter<string>();

  constructor( public agGridService:AgGridService ,public datePipe : DatePipe , private router: Router, private cdr: ChangeDetectorRef ){
    this.minDate = '1900-01-01';
    this.maxDate = '2050-12-31'; 
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.intialDateApply();
  }

  intialDateApply(){
    this.inputDate = this.datePipe?.transform(this.inputDate, 'yyyy-MM-dd')
  }

  ngOnInit(): void { 
  if(this.isTodayDateDefault) this.getCurrentDate();
  }

  agInit(params: any): void {
    this.params = params;
    this.isTodayDateDefault= params?.isTodayDateDefault?? true;
    try {
      const dt = this.datePipe?.transform(params.value, 'yyyy-MM-dd');
      if (!dt) {
        throw new Error(`Invalid date format: ${params.value}`);
      }
      this.inputDate = dt;
    } catch (error) {
      console.error(`Error transforming date: ${error.message}`);
      this.inputDate = '';
    }
  }
  

  refresh(params: any): boolean {
    this.params = params;
    this.inputDate = params.value;
    return true;
  }

  getValue(): any {
    return this.inputDate;
  }

  isValidDate(dateValue: string): boolean {
    return this.datePattern.test(dateValue);
  }

  isDatepickerChangeByType: boolean = false;

  onkeyDown(event) {
    this.isDatepickerChangeByType = true;
    if(event.key =='Enter'){
      this.setDatepickerValue(event)
    }
    
  }

  onChange(event) {
    if(!this.isDatepickerChangeByType){
      this.setDatepickerValue(event)
    }
    this.isDatepickerChangeByType = false;
  };

  onBlur(event){
    this.setDatepickerValue(event)
  }

  setDatepickerValue(event, inputDate?){
    const date = event?.target?.value??inputDate;
    if(this.params){
        this.params?.node?.setDataValue(this.params?.colDef?.field, date);
        this.agGridService?.onChangeSelectedDates(date, this.params);
      
    } else{
      if(!this.isOnlyDateTime){
        this.dateChanged.emit(date);
      }else{
        const dt =  this.datePipe?.transform(date, 'yyyy-MM-ddTHH:mm:ss');
        this.dateChanged.emit(dt);
      }
    }   
  }

  getCurrentDate(){
    if (!this.inputDate) {
      const today = new Date();
      this.inputDate = this.datePipe?.transform(today, 'yyyy-MM-dd');
      this.setDatepickerValue(null, this.inputDate)
     }
  }

  getupdateCurrentDate(){
      const today = new Date();
      this.inputDate = this.datePipe?.transform(today, 'yyyy-MM-dd');
      this.setDatepickerValue(null, this.inputDate)
  }

  updateCurrentDate(Date){
    const currrentDate = Date;
    this.inputDate = this.datePipe?.transform(currrentDate, 'yyyy-MM-dd');
    this.setDatepickerValue(null, this.inputDate)
}


}