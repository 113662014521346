import { Injectable } from '@angular/core';
import { DataService } from '../../../../services/data.service';
import { tap,Observable } from 'rxjs';
import { ResponseModel } from './../../../../../shared/models/response-model.model';
import { InsertUpdateNewRateRequestModel } from './update-current-rate.model';
@Injectable({
  providedIn: 'root'
})
export class UpdateCurrentRateService {

  constructor(public service:DataService) { }

  insertUpdateNewRate(data:InsertUpdateNewRateRequestModel):Observable<ResponseModel>{
    let url = "api/app/rate/insertupdatenewrate";
    const json = JSON.stringify(data);
    console.log(json);
    return this.service.post(url,data, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

  getAllNewRate():Observable<ResponseModel>{
    let url = "api/app/rate/getnewgoldrates";
    return this.service.get(url, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }


}
