import { Component, OnInit, QueryList, Renderer2, ViewChild, ViewChildren } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AdministrationService } from 'src/app/components/administration/administration.service';
import { CommonService } from '../../services/common.service';
import { ToastrService } from 'ngx-toastr';
import { catchError } from 'rxjs';
import { GetAllLocationResponseModel } from 'src/app/components/administration/administration.model';
import { SendDataService } from '../../services/send-data.service';

@Component({
  selector: 'app-location',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.scss']
})
export class LocationComponent extends CommonService implements OnInit {
  isAdd                           : boolean = false;
  getAllLocationData              : any[]=[];

  copyGetAllLocationData          : any[]=[]
  editLocationData                = new GetAllLocationResponseModel();
  @ViewChild('administrationFormData') administrationFormData! : NgForm ;

constructor(
  public renderer:Renderer2,
  public administrationService : AdministrationService,
  public toaster: ToastrService,
  public sendDataService : SendDataService,
) 
{
  super(renderer)
}
ngOnInit(): void {
  this.getAllLocationList();
  }

add(){
  if(!this.sendDataService?.permissionVM?.mastersCanAdd) return;
  this.isAdd = true;
    const obj = {id:0, name:'',active:true}
    this.getAllLocationData?.push(obj);
    this.copyGetAllLocationData?.push(obj);
    setTimeout(()=>{
      const element = document.getElementById(`locationname${obj.id}`);
      element?.focus();
    },0)
}

cancel(){
  if(this.isAdd)
  {
    this.isAdd = false;
    this.getAllLocationData = this.getAllLocationData.filter((x)=>x.id != 0);
    this.copyGetAllLocationData = this.copyGetAllLocationData.filter((x)=>x.id != 0);
  }
  else if(this.editLocationData?.id && this.editLocationData?.isEdit){
    const index = this.copyGetAllLocationData.findIndex((x)=>x.id == this.editLocationData?.id);
    const singleData =JSON.parse(JSON.stringify(this.copyGetAllLocationData))[index];
    this.getAllLocationData[index] = singleData;
    this.editLocationData = new GetAllLocationResponseModel()
  }  
}


edit(item,formData){
  if(!this.sendDataService?.permissionVM?.mastersCanUpdate) return;
  item.isEdit = true;
  const controlObj = formData?.form?.controls;
  if(controlObj){
    const id = Object.keys(controlObj)[0];
    const element = document.getElementById(id);
    element?.focus();
  }
  this.editLocationData = {...item};
}

checkIsEdit(arr:any[]):boolean{
  return arr?.some((x)=>x.isEdit)
}

    // *********** LOCATION API START *********
    // **********get all location *********
    getAllLocationList() {
      this.administrationService.getAllLocation().subscribe({
        next:(res)=>{
          if(res.isSuccess){
            this.getAllLocationData = res.response??[]; 
            this.editLocationData = new GetAllLocationResponseModel();
            this.copyGetAllLocationData = JSON.parse(JSON.stringify(this.getAllLocationData))??[];
          }else{           
            this.getAllLocationData = [];
            this.copyGetAllLocationData = [];
          }  
        },
        error:(err)=>{
          this.getAllLocationData = [];
          this.copyGetAllLocationData = [];
        }
      })
    }

    // *************insert location **************
       InserLocationList(item) {
        const {id , ...rest}= item;
        this.administrationService.insertLocation(rest)
            .pipe(catchError((err) => this.handleError(err)))
            .subscribe((res) => {
              if(res.isSuccess){
                this.isAdd = false;
                this.getAllLocationList();
                this.toaster.success(res.message);
              }else{
                this.toaster.error(res.errors[0]);
              }  
          });
        }
    
    // **********update Location *********
    UpdateLocationList(item) {
      const {isEdit , ...rest} = item;
      this.administrationService.updateLocation(rest)
          .pipe(catchError((err) => this.handleError(err)))
          .subscribe((res) => {
            if(res.isSuccess){
              this.isAdd = false;
              this.getAllLocationList();
              this.toaster.success(res.message);
            }else{
              this.toaster.error(res.errors[0]);
            }  
        });
      } 

      // **********delete Location *********
      DeleteLocationList(id) {
       if(!this.sendDataService?.permissionVM?.mastersCanDelete) return;
        this.administrationService.deleteLocation(id)
            .pipe(catchError((err) => this.handleError(err)))
            .subscribe((res) => {
              if(res.isSuccess){
                this.getAllLocationList();
                this.toaster.success(res.message);
              }else{
                this.toaster.error(res.errors[0]);
              }  
        });
      }
          
}
