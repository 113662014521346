import { Injectable } from '@angular/core';
import { Observable, Subject, tap } from 'rxjs';
import { DataService } from './../../shared/services/data.service';
import { ResponseModel } from './../../shared/models/response-model.model';
import { GetAllManufacturingDepartmentRequestModel, InsertUpdateManufacturingDepartmentBreakTimingsRequestModel, ManufacturingDepartments } from './manufacturer.model';
import { GetallUserRequestModel } from '../administration/usermanagment/usermanagment.model';

@Injectable({
  providedIn: 'root'
})
export class ManufacturerService {
  public previouscellClickedId: number = null;
  constructor(public service: DataService) { }
  // **********Department service start***********//

  // getAllManufacturingDepartment
  getAllManufacturingDepartment(data:GetAllManufacturingDepartmentRequestModel):Observable<ResponseModel>{
    let url = "api/App/ManufacturingDepartment/getAllManufacturingDepartment";
    return this.service.post(url,data, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }
  
   // getAllManuacturingDepartmentBreakTimingsRequestModel
   getAllManufacturingBreakTimings(id:any):Observable<ResponseModel>{
    let url = `api/app/manufacturingdepartment/getmanufacturingbreaktimebydepartmentid?Id=${id}`;
    return this.service.get(url, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }
  // insertUpdateManufacturingDepartment
  insertUpdateManufacturingDepartmentBreakTimings(data:InsertUpdateManufacturingDepartmentBreakTimingsRequestModel):Observable<ResponseModel>{
    let url = "api/app/manufacturingdepartment/insertupdatemanufacturingbreaktime";
    return this.service.post(url,data, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }
    // deleteManufacturingDepartment
    deleteManufacturingDepartmentBreakTimings(id:number):Observable<ResponseModel>{
      let url = `api/app/manufacturingdepartment/deletemanufacturingbreaktime?Id=${id}`;
      return this.service.delete(url, true).pipe<ResponseModel>(
        tap((response: any) => {
          return response;
        })
      );
    }
  // insertManufacturingDepartment
  insertManufacturingDepartment(data:ManufacturingDepartments):Observable<ResponseModel>{
    let url = "api/app/manufacturingdepartment/insertmanufacturingdepartment";
    const json = JSON.stringify(data);
    console.log(json);
    return this.service.post(url,data, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }
 
  // deleteManufacturingDepartment
  deleteManufacturingDepartment(id:number):Observable<ResponseModel>{
    let url = `api/app/manufacturingdepartment/deletemanufacturingdepartment?Id=${id}`;
    return this.service.delete(url, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }
  // updateManufacturingDepartment
  updateManufacturingDepartment(data:ManufacturingDepartments):Observable<ResponseModel>{
    let url = "api/app/manufacturingdepartment/updatemanufacturingdepartment";
    const json = JSON.stringify(data);
    return this.service.put(url,data,true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

//get all user list
  getAllUserList():Observable<ResponseModel>{
    let url = "api/app/users/userdropdownlist";
    return this.service.get(url, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }




  getLedgerByManufacturingdepartmentId(Id:number): Observable<ResponseModel> {
    let url = `api/app/manufacturingdepartment/getledgersbymanufacturingdepartmentid?Id=${Id}`;
    const json = JSON.stringify(Id);
    console.log(json);
    return this.service.get(url, true ,false, false).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }


  printDepartment(data): Observable<ResponseModel> {
    let url = "api/app/manufacturingdepartment/printdepartmentdetailsbarcode";
    const json = JSON.stringify(data);
    console.log(json);
    return this.service.post(url,data, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }


// get user by department id
// getUserByManufacturingdepartmentId(Id:number){
//     let url = `api/app/manufacturingdepartment/getusersbymanufacturingdepartmentid?Id=${Id}`;
//     const json = JSON.stringify(Id);
//     return this.service.get(url,true).pipe<ResponseModel>(
//       tap((response: any) => {
//         return response;
//       })
//     );
//   }
  // **********Department service end***********//

//insertmanufacturingdepartmentusermapping

insertManufacturingDepartmentLedgerMapping(data:any):Observable<ResponseModel>{
  let url = "api/app/manufacturingdepartment/insertmanufacturingdepartmentledgermapping";
  const json = JSON.stringify(data);
  console.log(json);
  return this.service.post(url,data, true).pipe<ResponseModel>(
    tap((response: any) => {
      return response;
    })
  );
}



getDepartmentsWithLedgerList():Observable<ResponseModel>{
  let url = 'api/app/manufacturingprocess/getledgerwithdepartment';
  return this.service.get(url,true).pipe<ResponseModel>(tap((response: any) => {
  return response;
      })
    );
}



// Transaction insert

insertJobworkQueue(data:any):Observable<ResponseModel>{
  let url = "api/app/jobworkQueue/insertJobworkQueue";
  return this.service.post(url,data, true).pipe<ResponseModel>(
    tap((response: any) => {
      return response;
    })
  );

}
insertJobworkQueueComment(data:any):Observable<ResponseModel>{
  let url = "api/app/jobworkQueue/updatejobworkqueuemastercomment";
  return this.service.post(url,data, true).pipe<ResponseModel>(
    tap((response: any) => {
      return response;
    })
  );

}


getJobworkQueueById(data:any):Observable<ResponseModel>{
  let url = "api/app/jobworkqueue/getjobworkqueuebyid";
  const json = JSON.stringify(data);
  console.log(json);
  return this.service.post(url,data, true).pipe<ResponseModel>( tap((response: any) => {
      return response;
    })
  );
}

getJobworkQueueCommentByMasterId(id):Observable<ResponseModel>{
  let url = `api/app/jobworkqueue/getjobworkqueuemasterbycommentid?jobworkQueueMasterId=${id}`;
  const json = JSON.stringify(id);
  console.log(json);
  return this.service.get(url, true).pipe<ResponseModel>(
    tap((response: any) => {
      return response;
    })
  );
}

updateStatus(data:any):Observable<ResponseModel>{
  let url = "api/app/jobworkorder/updatestatus";
  return this.service.post(url,data, true).pipe<ResponseModel>( tap((response: any) => {
      return response;
    })
  );
}

getAllUserDeatailsbyId(id:number): Observable<ResponseModel> {
  let url = `api/app/users/getbyiduser?Id=${id}`;
  const json = JSON.stringify(id);
  console.log(json);
  return this.service.get(url, true).pipe<ResponseModel>(
    tap((response: any) => {
      return response;
    })
  );
}

getAllManufacturingProcess(getManufacturingProcessInOutQuery:any): Observable<ResponseModel> {
  let url = 'api/app/manufacturingprocess/getmanufacturingprocessinout';
  return this.service.post(url,getManufacturingProcessInOutQuery, true).pipe<ResponseModel>(tap((response: any) => {
  return response;
    })
  );
}


  // department update display order 

  updateDisplayOrder(data:any):Observable<ResponseModel>{
    let url = "api/app/manufacturingdepartment/updatedisplayorder";
    return this.service.put(url,data, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }


    // closingButton api calling
    closingService(data:any):Observable<ResponseModel>{
    let url = "api/app/JobworkQueue/close";
    const json = JSON.stringify(data);
    console.log(json);
    return this.service.post(url,data, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }
    //loss tracking

    getlossTracking(data):Observable<ResponseModel>{
      let url = `api/app/losstracking/getalllosstracking`;
      const json = JSON.stringify(data);
      return this.service.post(url,data,true).pipe<ResponseModel>(
        tap((response: any) => {
          return response;
        })
      )
  }

  // get all closing report data

  // getAllClosingRportData():Observable<ResponseModel>{
  //   let url = "api/app/jobworkqueueitem/getallclosingreport";
  //   return this.service.get(url, true).pipe<ResponseModel>(
  //     tap((response: any) => {
  //       return response;
  //     })
  //   );
  // }
  getAllClosingRportData(data): Observable<ResponseModel> {
    let url = "api/app/jobworkqueueitem/getallclosingreport";
    return this.service.post(url,data,true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

  deleteJobworkqueueTrackingtime(id):Observable<ResponseModel>{
    let url = `api/app/jobworkqueue/deletejobworkqueuetrackingtime?Id=${id}`;
    return this.service.delete(url, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

    
    getJobworkqueueBybarcodeNo(data:any):Observable<ResponseModel>{
      let url = "api/app/jobworkqueue/getjobworkqueuedatabybarcodeno";
      return this.service.post(url,data, true).pipe<ResponseModel>(
        tap((response: any) => {
          return response;
        })
      );
    }

    
  // department report
  getAllManufacturingDepartmentReport(data:any):Observable<ResponseModel>{
    let url = "api/app/manufacturingDepartment/GetAllManufacturingDepartmentReport";
    return this.service.post(url,data, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }


  // worklogreport 
  getAllWorkLogReport(data:any):Observable<ResponseModel>{
    let url = "api/app/manufacturingprocess/getallworklogreport";
    return this.service.post(url,data,true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

  Getworklogreportbyaccountledgerid(data:any):Observable<ResponseModel>{
    let url = "api/app/manufacturingprocess/getworklogreportbyaccountledgerid";
    return this.service.post(url,data, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

}
