import { Component, EventEmitter, Input, OnInit, Output, Directive, ViewChild } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { NgMultiSelectDropdownComponent } from '../ng-multi-select-dropdown/ng-multi-select-dropdown.component';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ],
})
export class InputComponent implements OnInit  {

  @Input() decimalPlaces: number =0; // Default to 0, indicating no decimal places by default
  @Input() isOnlyNumbers: boolean = false; // Default to false, indicating no onlyNumbers directive by default
  @Input() isOnlyAlphabets: boolean = false; // Default to false, indicating no onlyAlphabets directive by default
  @Input() isOnlyAlphabetsAndNumbers: boolean = false; // Default to false, indicating no onlyAlphabetsAndNumbers directive by default
  @Input() applyDecimalOrPercent: boolean = false; 
  @Input() isPercentApplicable: boolean = false; 
  @Input() isBlureApplicable: boolean = false; 
  @Input() decimalRestrictedDigit = 2; 
  @Input() applyOnlyOneNumber: boolean = false; 
  @Input() isAllowDecimal: boolean = false;
  
  @Input() inputType:string='text';
  @Input() inputName:string;
  @Input() inputPlaceholder:string='';
  @Input() inputLabel:string;
  @Input() inputId:string;
  @Input() inputValue:string='';
  @Input() isRequired:boolean;
  @Input() inputMaxlength:boolean=false;
  @Input() inputClass:string='';
  @Input() focusInputOtp:boolean = false;
  @Input() disabled :boolean;
  @Input() readOnly: boolean=false;
  @Input() maxDate:string;
  @Input() minDate:string;
  @Input() max:any;
  @Input() min:any;
  @Input() maxLength:any;
  @Input() minLength:any;
  @Input() isEyeIconShow:false;
  @Output() inputValueEmit:EventEmitter<string> = new EventEmitter();
  @Output() keydownEmit:EventEmitter<string> = new EventEmitter();
  @Output() inputOnBlurEmit:EventEmitter<any> = new EventEmitter();
  public show: boolean = false
  pattern: string;
  passwordPattern: string = '^(?=.*[a-zA-Z])(?=.*\\d)(?=.*[@$!%*?&])[a-zA-Z\\d@$!%*?&]{8,}$';
  emailPattern: string = "[-a-zA-Z0-9~!$%^&amp;*_=+}{'?]+(\\.[-a-zA-Z0-9~!$%^&amp;*_=+}{'?]+)*@([a-zA-Z0-9_][-a-zA-Z0-9_]*(\\.[-a-zA-Z0-9_]+)*\\.(com|in|org))(:[0-9]{1,5})?";
  @Input()isPassPattern:boolean=false;
  @Input()isEmailPattern:boolean=false;
  public passPattern = "^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[@#$%^&*_!])[A-Za-z0-9@#$%^&*_!]{8,}.*$";
  constructor() { }

  ngOnInit(): void {
    this.isEnablePattern();
  }


  showHidePass(){
    this.show = !this.show;
    if(this.show){
      this.inputType = 'text';
    }
    else{
      this.inputType = 'password';
    }
  }

  isEnablePattern(): void {
    if (this.inputName === 'email') {
      this.pattern = this.emailPattern;
    } else if (this.inputName === 'password') {
      this.pattern = this.passwordPattern;
    } else {
      this.pattern = null;
    }
  }

  focusInput(event){
    if(this.focusInputOtp){
      const inputField = document.getElementById(event?.target?.id) as HTMLInputElement;
      inputField.select();
    }
  }

  convertToLowerCase(event: KeyboardEvent): void {
    const target = event.target as HTMLInputElement;
    setTimeout(() => {
      const start = target.selectionStart;
      const end = target.selectionEnd;
      this.inputValue = target.value.toLowerCase();
      this.inputValueEmit.emit(this.inputValue);
      target.setSelectionRange(start, end);
    });
  }
}
