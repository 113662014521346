import { Component, OnInit, QueryList, Renderer2, ViewChild, ViewChildren } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AdministrationService } from 'src/app/components/administration/administration.service';
import { CommonService } from '../../services/common.service';
import { ToastrService } from 'ngx-toastr';
import { catchError } from 'rxjs';
import { GetAllPacketTypeResponseModel } from 'src/app/components/administration/administration.model';
import { SendDataService } from '../../services/send-data.service';

@Component({
  selector: 'app-packet-type',
  templateUrl: './packet-type.component.html',
  styleUrls: ['./packet-type.component.scss']
})
export class PacketTypeComponent extends CommonService implements OnInit {
  isAdd                             : boolean = false;
  getAllPacketTypeData              : any[]=[];

  copyGetAllPacketTypeData          : any[]=[];
  editPacketTypeData                = new GetAllPacketTypeResponseModel();
  @ViewChild('administrationFormData') administrationFormData! : NgForm ;
  
constructor(
  public renderer:Renderer2,
  public administrationService : AdministrationService,
  public toaster: ToastrService,
  public sendDataService : SendDataService,
) 
{
  super(renderer)
}

ngOnInit(): void {
  this.getAllPacketTypeList();
  }

add(){
  if(!this.sendDataService?.permissionVM?.mastersCanAdd) return; if(!this.sendDataService?.permissionVM?.mastersCanAdd) return;
  this.isAdd = true;
    const obj = {id:0, name:'',weight: undefined, active:true}
    this.getAllPacketTypeData?.push(obj);
    this.copyGetAllPacketTypeData?.push(obj);
    setTimeout(()=>{
      const element = document.getElementById(`packetName${obj.id}`);
      element?.focus();
    },0)
}


cancel(){
  if(this.isAdd)
  {
    this.isAdd = false;
    this.getAllPacketTypeData = this.getAllPacketTypeData.filter((x)=>x.id != 0);
    this.copyGetAllPacketTypeData = this.copyGetAllPacketTypeData.filter((x)=>x.id != 0);
  }
  else if(this.editPacketTypeData?.id && this.editPacketTypeData?.isEdit){
    const index = this.copyGetAllPacketTypeData.findIndex((x)=>x.id == this.editPacketTypeData?.id);
    const singleData =JSON.parse(JSON.stringify(this.copyGetAllPacketTypeData))[index];
    this.getAllPacketTypeData[index] = singleData;
    this.editPacketTypeData = new GetAllPacketTypeResponseModel();
  }  
}


edit(item,formData){
  if(!this.sendDataService?.permissionVM?.mastersCanUpdate) return;
  item.isEdit = true;
  item.isEdit = true;
  const controlObj = formData?.form?.controls;
  if(controlObj){
    const id = Object.keys(controlObj)[0];
    const element = document.getElementById(id);
    element?.focus();
  }
  this.editPacketTypeData = {...item};
}


checkIsEdit(arr:any[]):boolean{
  return arr?.some((x)=>x.isEdit)
}

  // *********** PacketType API START *********
    // **********get all PacketType *********
    getAllPacketTypeList() {
      this.administrationService.getAllPacketType().subscribe({
        next:(res)=>{
          if(res.isSuccess){
            this.getAllPacketTypeData = res.response??[];
            this.editPacketTypeData = new GetAllPacketTypeResponseModel();
            this.copyGetAllPacketTypeData = JSON.parse(JSON.stringify(this.getAllPacketTypeData))??[];
          }else{           
            this.getAllPacketTypeData = []; 
            this.copyGetAllPacketTypeData = [];
          }  
        },
        error:(err)=>{
          this.getAllPacketTypeData = [];
          this.copyGetAllPacketTypeData = [];
        }
      })
    }
  
    // *************insert PacketType **************
      InserPacketTypeList(item) {
        const {id , ...rest}= item;
        this.administrationService.insertPacketType(rest)
            .pipe(catchError((err) => this.handleError(err)))
            .subscribe((res) => {
              if(res.isSuccess){
                this.isAdd = false;
                this.getAllPacketTypeList();
                this.toaster.success(res.message);
              }else{
                this.toaster.error(res.errors[0]);
              }  
          });
      }
    
    // **********update PacketType *********
    UpdatePacketTypeList(item) {
      const {isEdit , ...rest} = item;
      this.administrationService.updatePacketType(rest)
          .pipe(catchError((err) => this.handleError(err)))
          .subscribe((res) => {
            if(res.isSuccess){
              this.isAdd = false;
              this.getAllPacketTypeList();
              this.toaster.success(res.message);
            }else{
              this.toaster.error(res.errors[0]);
            }  
        });
      } 

      // **********delete PacketType *********
      DeletePacketTypeList(id) {
        if(!this.sendDataService?.permissionVM?.mastersCanDelete) return;
        this.administrationService.deletePacketType(id)
            .pipe(catchError((err) => this.handleError(err)))
            .subscribe((res) => {
              if(res.isSuccess){
                this.getAllPacketTypeList();
                this.toaster.success(res.message);
              }else{
                this.toaster.error(res.errors[0]);
              }  
        });
      }

  
}
