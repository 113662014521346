import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { AgGridService } from '../../ag-grid/ag-grid.service';
import { DropdownModel, commonModalOptions } from '../../../models/common.model';
import { StorageService } from '../../../services/storage.service';
import { Filter } from '../ag-grid.model';
import { StorageName } from 'src/app/shared/enum/common-enum';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { MultiselectFilterComponent } from './pop-ups/multiselect-filter/multiselect-filter.component';
@Component({
  selector: 'app-column-filter',
  templateUrl: './column-filter.component.html',
  styleUrls: ['./column-filter.component.scss']
})
export class ColumnFilterComponent implements OnInit {
  @ViewChild('filterInput', { static: false }) filterInput: ElementRef;
  @Input() isFilter: boolean;
  @Input() isInputFilter: boolean;
  @Input() isPopUpInputFilter: boolean;
  @Input() isDropDownFilter: boolean;
  @Input() params: any;
  @Input() columnName: string;
  @Input() gridName: string;
  @Input() initialRows: string;
  @Input() filterStorageName: string;
  @Input() apiUrl : string;
  @Input() sortStorageName: string;
  @Input() paginationStorageName: string;
  @Input() idColumnName: string;
  @Input() valueColumnName: string;
  @Input() cellDisplayType: string;
  @Input() isDateFilterShow: boolean;
  @Input() isServerSidePagination: boolean;
  @Input() tableIndex: number;
  @Input() rows: any[];
  @Input() filterParams: any;
  @Input() isAllowNumber: boolean = false;
  timerId
   filterData: DropdownModel[];
  columnfilter = DropdownModel[3]=[
    { id: "", name: "" },
    { id: "All", name: "All" },
    { id: "true", name: "Yes" },
    { id: "false", name: "No" }
  ];

  ColumnFilterSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'id',
    textField: 'name',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 1,
    allowSearchFilter: true
  };

  isFilterPopUpOpen:boolean = false;
  existingRowsData: any;
  model: any = [];
  modelRollupLevel : string;
  inputValue: any;
  inputPopUpHeaderValue: any;
  filterList: Filter[]=[];
  modifiedDatalist:any=[]
  transactionGridUniqueData: any;
  constructor(
              public agGridService:AgGridService,
              public storageService:StorageService,
              public modalService:NgbModal
            ) {
   }
  ngOnInit(): void {
    this.filterData = this.filterParams;
    const data  = this.storageService.selectedMetalIdForFilter?.getValue();
    if(data?.length){
      const product =    data.filter((x)=>x.field == 'productId');
      const branch  =    data.filter((x)=>x.field == 'branchId');
      setTimeout(()=>{
        switch (this.filterParams) {
          case StorageName.PRODUCT_DROPDOWN:
            this.model = this.filterData?.filter(item1 =>product?.some(item2 => item1.id == item2.data));
            break;
            case StorageName.BRANCH_DROPDOWN:
              this.model = this.filterData?.filter(item1 => branch?.some(item2 =>item2.data.split(',').includes(item1.id)));
              break;
            }
      },1000);
}

    switch (this.filterParams) {
      case StorageName.PRODUCT_DROPDOWN:
      case StorageName.VOUCHER_TYPE_DROPDOWN:
      case StorageName.ACCOUNTGROUP_DROPDOWN:
      case StorageName.CAMPAIGNGROUP_DROPDOWN:
      case StorageName.INVOICE_NO_DROPDOWN:
      case StorageName.LEDGER_NAME_DROPDOWN:
      case StorageName.METAL_DROPDOWN:
      case StorageName.BRANCH_DROPDOWN:
      case StorageName.ACCOUNTLEDGER_DROPDOWN:
      case StorageName.TRANSACTION_TYPE_DROPDOWN:
      case StorageName.ROLE_DROPDOWN:
      case StorageName.USER_DROPDOWN:
      case StorageName.ALL_ACCOUNTLEDGER_DROPDOWN:
      case StorageName.PAGENAME_DROPDOWN:
      case StorageName.EVENTTYPE_DROPDOWN:
      case StorageName.STATUS_DROPDOWN:
      case StorageName.ITEM_CODE_DROPDOWN:
      case StorageName.STYLE_DROPDOWN:
      case StorageName.DIAMOND_CATEGORY_DROPDOWN:
      case StorageName.CALCULATION_TYPE_DROPDOWN:
      case StorageName.CUT_DROPDOWN:
      case StorageName.COLOR_DROPDOWN:
      case StorageName.SEIVE_SIZE_DROPDOWN:
      case StorageName.CARAT_DROPDOWN:
      case StorageName.SIZE_DROPDOWN:
      case StorageName.SHAPE_DROPDOWN:
      case StorageName.CLARITY_DROPDOWN:
      case StorageName.STATUSID_ENUM_DROPDOWN:
      case StorageName.JOBWORK_STATUS_DROPDOWN:
      case StorageName.DEPARTMENT_DROPDOWN:
      case StorageName.USER_DROPDOWN:
      case StorageName.LEDGERTYPE_DROPDOWN:
      case StorageName.SQL_TYPE_DROPDOWN:
      case StorageName.CATEGORY_DROPDOWN:
      case StorageName.BANK_ACCOUNT_DROPDOWN:
      case StorageName.PDC_STATUS_DROPDOWN:
      case StorageName.CLOSINGDATE_DROPDOWN:
      case StorageName.SALEORDERPROCESS_ORDERNO_DROPDOWN:
      case StorageName.LOCATION_DROPDOWN:
      case StorageName.CAMPAIGN_STATUS_DROPDOWN:
      case StorageName.SALEPERSON_DROPDOWN:
      case StorageName.USER_STATUS_DROPDOWN:
        this.filterData = this.storageService.retrieve(this.filterParams);
      break;
     
        default:
        // Handle default case if needed
        break;
    }

  //  this.setFilteredText(this.valueColumnName);
    // this.filterService.transactionGridUniqueData.subscribe(res=>{
    //   this.transactionGridUniqueData = res;
    // })
  }
  
  performFilterColumnwise(valueColumnName,filterData,textInput)
  {    

    // for decimal conversion
    let previousFilterList = this.storageService.retrieve(this.filterStorageName);
     // for decimal conversion
    this.filterList = [];
    let index =  previousFilterList?.findIndex(a=>a.name == valueColumnName);
      if(previousFilterList!=undefined)
          this.filterList = previousFilterList;
      if(index != undefined && index != -1)
      {
        this.filterList[index].type = this.cellDisplayType == undefined ? "textFilter" : this.cellDisplayType;
        this.filterList[index].textFilter = textInput;
        this.filterList[index].FilterData = filterData?.length == 0 ? undefined :  filterData;
      }
      else
      {
        if(filterData?.length == 0)
          filterData = undefined;
        this.filterList.push({ type: this.cellDisplayType == undefined ? "textFilter" : this.cellDisplayType,  name:valueColumnName, textFilter:textInput , FilterData : filterData });
      }
   //   this.inputValue = textInput;
      this.storageService.store(this.filterStorageName,this.filterList);

      if(this.isServerSidePagination)
        this.agGridService.onSortAndFilter(this.params,this.apiUrl, this.sortStorageName,this.filterStorageName,this.paginationStorageName,null,this.tableIndex)
      else
        this.agGridService.performFilter(this.params)
  }

  emitInputValueForPopUP(value){
    this.inputPopUpHeaderValue = value;
    // this.storageService.emitHeaderInputValue.emit(value);
  }
  updateInputFilter(valueColumnName,textInput){
    clearTimeout(this.timerId);
    // this.inputValue = textInput;
    this.timerId = setTimeout(() => {
      if(this.filterStorageName?.indexOf("TRANSFER") != -1)
        this.storageService.onFilter.next({valueColumnName : valueColumnName, filterData :undefined, textInput : textInput });
      else 
        this.performFilterColumnwise( valueColumnName, undefined,textInput);
    }, 100);
  }
  updateDropdownFilter(valueColumnName,filterStorageName,dropdownInput){
    let filterText = this.model?.toLowerCase();
   var tableFilterValues : any[]= [];
   tableFilterValues.push(filterText);
    if(this.sortStorageName?.indexOf("TRANSFER") != -1)
      this.storageService.onFilter.next({valueColumnName : valueColumnName, filterData :tableFilterValues, textInput : undefined });
    else
      this.performFilterColumnwise( valueColumnName, tableFilterValues,undefined);
  }

 
  updateFilter(valueColumnName,filterData) {
    this.performFilterColumnwise( valueColumnName, filterData.tableFilterValues,filterData.textFilterValue);
  }

  onClearFilter(name: string) {
    // this.filterInput.nativeElement.value = '';
    this.inputValue = undefined;
    this.performFilterColumnwise(name, undefined, undefined);
  }
  setFilteredText(colName)
  {

    this.inputValue ="";
    this.model ="";
  
  }
    
  setDropdown(colName) {

    this.inputValue ="";
    this.model ="";
  
  return this.model;
  }
  
  onselectItems(data: any[]) {
    const modifiedData = [];
    data?.forEach((item, index) => {
        modifiedData[index.toString()] = item?.id?.toString();
    });
    
    if(this.sortStorageName?.indexOf("TRANSFER") != -1)
    this.storageService.onFilter.next({valueColumnName : this.valueColumnName, filterData :modifiedData, textInput : undefined, tableIndex : this.tableIndex });
  else
  this.modifiedDatalist = modifiedData;

      this.performFilterColumnwise(this.valueColumnName, modifiedData, undefined);

}

onresetItem(){
  this.performFilterColumnwise(this.valueColumnName, undefined, undefined);
}

// allow only numbers
keyPressValue(event: KeyboardEvent) {
  console.log(this.params);

  if(this.params?.isAllowNumber){
    const pattern = /[0-9.]/;
    const inputChar = String.fromCharCode(event.charCode);
  
    if (!pattern.test(inputChar)) {
        // Do not allow letters e, E, +, -, . in the input field
        event.preventDefault();
    }
  }
  
}

openFilterPopUp(columnName , value , data){

  const modalOption: NgbModalOptions = {
    ...commonModalOptions,
  };
  const modalRef = this.modalService.open(MultiselectFilterComponent , modalOption);
  modalRef.componentInstance.data =  data;
  modalRef.componentInstance.columnName =  columnName;
  modalRef.componentInstance.params =  this.params;
  modalRef.result.then((result:[]) => {
      this.onselectItems(result);
      this.params.appliedData = JSON.parse(JSON.stringify(result));
  }, (reason) => {
  });
}

openFilterPopUpFromHeader(){
  
  this.openFilterPopUp(this.valueColumnName,'', this.filterData)

}

clearFilterFromHeader(){
  if(this.modifiedDatalist.length !==0 ){
  this.onselectItems([]);this.params.appliedData = []
  }

}

// // allow only numbers with single decimal point
// keyPressValue(event: KeyboardEvent) {
//   if(this.params?.isAllowNumber){
//   // Allow numbers and dot
//   const pattern = /[0-9.]/;

//   // Get the character based on the key pressed
//   const inputChar = event.key;

//   // Check if the pressed key is allowed
//     if (!pattern.test(inputChar)) {
//         // If it's not a number or dot, prevent the input
//         event.preventDefault();
//     } else if (inputChar === '.' && this.inputValue.includes('.')) {
//         // Prevent entering more than one dot
//         event.preventDefault();
//     }
//   }
// }

resetFilter(){
  this.inputValue ="";
}


}
