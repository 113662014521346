<div class="modal-header d-flex align-items-center justify-content-center position-relative">
    <div><span class="font-semibold text-primary">{{ modalTitle }}</span></div>
    <app-svg-icon [name]="'cross_button_border'" class="cross-button-border"></app-svg-icon>
    <app-svg-icon  [name]="'cross'" [ngClass]="currentHoverEffect" class="cross-button custom_focus" (keydown.enter)="onKeyDown($event)" (click)="activeModal.dismiss()"></app-svg-icon>
</div>
<form autocomplete="off" class="theme-form" ngNativeValidate #SaleOrderForm="ngForm">
    <div class="modal-body px-1 pb-0">
        <div class="row mx-0 gap-2 flex-fill mb-3">
            <div class="col p-0">
                <div class="h-200px position-relative">
                    <app-ag-grid
                    [dataModel]="agGridSaleOrderList"
                    [gridOptions]="gridOptions"
                    [rowData]="agGridSaleOrderRowData"
                    (gridReady)="onGridReady($event)"
                   ></app-ag-grid>     
                </div>
            </div>
        </div>
        <hr class="m-0">
        <div class="d-flex py-2  align-items-center justify-content-center cursorPointer text-primary f-14 font-semibold"  (click)="onApply()">
           <span  (keydown.enter)="onKeyDown($event)" class=" blue_button px-2 custom-style">Done</span>
        </div>
    </div>
</form>
