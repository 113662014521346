import { Component, ElementRef, Input, QueryList, Renderer2, ViewChild, ViewChildren } from '@angular/core';
import { NgbActiveModal, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { AgGridI, PaginationModel } from 'src/app/shared/common-components/ag-grid/ag-grid.model';
import { CommonService } from 'src/app/shared/services/common.service';
import { AgGridAccountGroupsGrid } from '../../financial-statement.model';
import { FinancialStatementService } from '../../financial-statement.service';
import { CommonFilterService } from 'src/app/shared/services/common-filter.service';
import { AgGridComponent } from 'src/app/shared/common-components/ag-grid/ag-grid.component';
import { StorageService } from 'src/app/shared/services/storage.service';
import { StorageName } from 'src/app/shared/enum/common-enum';
import { commonModalOptionsXl,ModalPopupSize } from 'src/app/shared/models/common.model';
import { LedgerDetailsComponent } from '../ledger-details/ledger-details.component';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { CellRendererComponent } from 'ag-grid-community/dist/lib/components/framework/componentTypes';
import { TextRendererComponent } from 'src/app/shared/common-components/ag-grid/text-renderer/text-renderer.component';
import { AgGridService } from 'src/app/shared/common-components/ag-grid/ag-grid.service';
import { DragDrop } from '@angular/cdk/drag-drop';


@Component({
  selector: 'app-account-groups-popup',
  templateUrl: './account-groups-popup.component.html',
  styleUrls: ['./account-groups-popup.component.scss']
})
export class AccountGroupsPopupComponent extends CommonService{
  @ViewChild('modalDialog') modalDialog: ElementRef;
  @ViewChildren(AgGridComponent) grids: QueryList<AgGridComponent>
  @Input() data:any={};
  gridApi                       : any;
  agGridAccountGroupsGrid       : AgGridI;
  apiRequestStorageName         = StorageName.APIREQUEST_FINANCIAL_STATEMENT_ACCOUNT_GROUPS_GRID;
  agGridAccountGroups           : any[] = [];
  @Input() fromDate;
  @Input() toDate;
  pagination = new PaginationModel();

  constructor(public notificationService: NotificationService,
    public commonFilterService:CommonFilterService,
    public modalService: NgbModal,
    public storageService:StorageService,
    public activeModal: NgbActiveModal, 
    public agGridService: AgGridService, 
    private financialStatementService:FinancialStatementService, 
    private el: ElementRef, private dragDrop: DragDrop,
    public renderer : Renderer2,) {
    super(renderer);
   
  }
  
  ngOnInit(): void {
    this.agGridAccountGroupsGrid  = AgGridAccountGroupsGrid;
    this.agGridAccountGroupsGrid.showPagination =false;
  }

  onGridReady(event ){
    this.gridApi  = event; 
    this.storageService.store(this.apiRequestStorageName, this.data);
    this.getAllAccountGroupsList();
  }

  getAllAccountGroupsList(){
    this.showLoading(this.gridApi);
    this.data = this.storageService.retrieve(this.apiRequestStorageName);
    this.financialStatementService.getallAccountGroups(this.data).subscribe({
      next:(res)=>{
       var columnDefs = this.agGridService.createColumnDefs(res.response.data,false,['accountGroupId','ledgerId','balance1']);
       this.gridApi.api.setColumnDefs(columnDefs);
        this.commonFilterService.processServersideApiResponse(res,this.grids.first,this.gridApi,this.pagination,this.data.getAll);
       
      },error:(err)=>{
        this.hideLoading(this.gridApi); 
        this.gridApi?.api?.setRowData([]);
      }
    })
  }

  handleTextClick(event){
    this.openLedgerDetailsPopup(event.data.id)
  }

  openLedgerDetailsPopup(id){
    var data ={accountGroupId:id}
    this.notificationService.openPopup(LedgerDetailsComponent, data, undefined, ModalPopupSize.XL).then(
      (resultData) => {
        if (resultData) {
        }
      }
    );
  }

  apply(){  
  }

}
