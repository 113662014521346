import { Component, Input } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { threadId } from 'worker_threads';
import { AgGridService } from '../ag-grid.service';

@Component({
  selector: 'app-numeric-cell-renderer',
  templateUrl: './numeric-cell-renderer.component.html',
  styleUrls: ['./numeric-cell-renderer.component.scss']
})
export class NumericCellRendererComponent implements ICellRendererAngularComp {
  @Input() params    : ICellRendererParams | any;
  value     : any;
  isCursor: boolean = false;
  isWeight  = false; 
  isAmt     = false;
  isPercent = false;
  @Input() isDirectRender = false
  constructor(public agGridService: AgGridService) {}

  agInit(params: ICellRendererParams<any, any, any>): void {
    this.setParamsAndAssign(params);
    this.isCursor = this.params?.isCursor??false;
  }

  ngOnChanges(){
    if(this.isDirectRender){
      this.setParamsAndAssign(this.params)
    }
  }

  setParamsAndAssign(params){
    this.params    = params;
    this.isWeight  = params?.isWeight ?? false;
    this.isAmt     = params?.isAmt ?? false;
    this.isCursor  = params?.isCursor ?? false;
    this.isPercent = params?.isPercent ?? false;

    this.formatValue();
  }

  refresh(params: ICellRendererParams<any, any, any>): boolean {
    this.setParamsAndAssign(params);
    this.isCursor = this.params?.isCursor??false;
    return true
  }

  onClick(event){
    this.isCursor ? this.agGridService.triggerOnNumberClick(this.params):null
  }

  formatValue() {
    this.value = this.params?.value ? this.params?.value : '0';
    this.value = this.isWeight ? parseFloat(this.value)?.toFixed(3) :
                  this.isAmt ? parseFloat(this.value)?.toFixed(2) : this.value;

    this.value = this.removeNegativeSign(this.value);
  }

  formattedValue(): string {
    if (this.isNegative()) {
      return `(-${this.removeNegativeSign(this.value)})`;
    } else {
      return this.isPercent ? `${this.value}%` : this.value;
    }
  }

  
  isNegative(): boolean {
    return this.params?.value < 0;
  }

  isNegativeFormatted(str: string | undefined): boolean {
    return typeof str === 'string' && /^\(-\d+\)$/.test(str);
  }

  isDecimal(value: number): boolean {
    return Number.isInteger(value) === false;
  }

  removeNegativeSign(value: string | number): string {
    const stringValue = value?.toString();
    return stringValue?.startsWith('-') ? `(${stringValue})` : stringValue;
  }
  
}