import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-toggle-switch',
  templateUrl: './toggle-switch.component.html',
  styleUrls: ['./toggle-switch.component.scss']
})
export class ToggleSwitchComponent {
  @Input() switchTopClass:string;
  @Input() switchStatus:boolean;
  @Input() id:any;
  @Input() onlySelfAccess:boolean;
  @Input() switchClass:string;
  @Output() changeStatus:EventEmitter<boolean> = new EventEmitter();
}
