import { Component, OnInit, Renderer2, ViewChild } from '@angular/core';
import { CommonService } from '../../services/common.service';
import { AdministrationService } from 'src/app/components/administration/administration.service';
import { ToastrService } from 'ngx-toastr';
import { NgForm } from '@angular/forms';
import { GetAllLoanProductTypeResponseModel } from 'src/app/components/administration/administration.model';
import { catchError } from 'rxjs';
import { SendDataService } from '../../services/send-data.service';

@Component({
  selector: 'app-loan-product-type',
  templateUrl: './loan-product-type.component.html',
  styleUrls: ['./loan-product-type.component.scss']
})
export class LoanProductTypeComponent extends CommonService implements OnInit {
  [x: string]: any;
  @ViewChild('administrationFormData') administrationFormData! : NgForm ;
  getAllLoanProductType           : any[]=[];
  copyGetAllLoanProductType       : any[]=[]
  editLoanProductType             = new GetAllLoanProductTypeResponseModel();
  isAdd                           : boolean = false;

  constructor(
    public renderer:Renderer2,
    public administrationService : AdministrationService,
    public toaster: ToastrService,
    public sendDataService : SendDataService,
  ) 
  {
    super(renderer)
  }
  ngOnInit(): void {
    this.getAllLoanProductTypeList();
  }

  add(){
    if(!this.sendDataService?.permissionVM?.mastersCanAdd) return;
    this.isAdd = true;
    const obj = {id:0, name:'',active:true}
    this.getAllLoanProductType?.push(obj);
    this.copyGetAllLoanProductType?.push(obj);
    setTimeout(()=>{
      const element = document.getElementById(`LoanProductTypename${obj.id}`);
      element?.focus();
    },0)
  }

  cancel(){
    if(this.isAdd)
    {
      this.isAdd = false;
      this.getAllLoanProductType = this.getAllLoanProductType.filter((x)=>x.id != 0);
      this.copyGetAllLoanProductType = this.copyGetAllLoanProductType.filter((x)=>x.id != 0);
    }
    else if(this.editLoanProductType?.id && this.editLoanProductType?.isEdit){
      const index = this.copyGetAllLoanProductType.findIndex((x)=>x.id == this.editLoanProductType?.id);
      const singleData =JSON.parse(JSON.stringify(this.copyGetAllLoanProductType))[index];
      this.getAllLoanProductType[index] = singleData;
      this.editLoanProductType = new GetAllLoanProductTypeResponseModel();
    }  
  }

  edit(item,formData){
    if(!this.sendDataService?.permissionVM?.mastersCanUpdate) return;
    item.isEdit = true;
    const controlObj = formData?.form?.controls;
    if(controlObj){
      const id = Object.keys(controlObj)[0];
      const element = document.getElementById(id);
      element?.focus();
    }
     this.editLoanProductType = {...item};
  }

  checkIsEdit(arr:any[]):boolean{
    return arr?.some((x)=>x.isEdit)
  }


  // *********** Loan Product Type API START *********

  // **********get all Loan Product Type*********  
  getAllLoanProductTypeList() {
    this.administrationService.getAllLoanProductType().subscribe({
      next:(res)=>{
        if(res.isSuccess){
            this.getAllLoanProductType = res.response??[];
            this.editLoanProductType = new GetAllLoanProductTypeResponseModel();
            this.copyGetAllLoanProductType = JSON.parse(JSON.stringify(this.getAllLoanProductType.filter((x)=>x.active == true)))??[];
          }else{           
          this.getAllLoanProductType = [];
          this.copyGetAllLoanProductType = [];
        }  
      },
      error:(err)=>{
        this.getAllLoanProductType = []; 
        this.copyGetAllLoanProductType = [];
      }
    })
  }

 // *************insert Loan Product Type**************
 insertLoanProductType(item) {
  const {id , ...rest} = item;
  this.administrationService.insertLoanProductType(rest)
      .pipe(catchError((err) => this.handleError(err)))
      .subscribe((res) => {
        if(res.isSuccess){
          this.isAdd = false;
          this.getAllLoanProductTypeList();
          this.toaster.success(res.message);
        }else{
          this.toaster.error(res.errors[0]);
        }  
    });
  }

  // **********update Loan Product Type *********
  updateLoanProductType(item) {
    const {isEdit , ...rest} = item;
    this.administrationService.updateLoanProductType(rest)
        .pipe(catchError((err) => this.handleError(err)))
        .subscribe((res) => {
          if(res.isSuccess){
            console.log(res?.message)
            this.isAdd = false;
            this.getAllLoanProductTypeList();
            this.toaster.success(res.message);
          }else{
            this.toaster.error(res.errors[0]);
          }  
      });
    }  
    
 // **********delete Loan Product Type *********
 deleteLoanProductType(id) {
  if(!this.sendDataService?.permissionVM?.mastersCanDelete) return;
  this.administrationService.deleteLoanProductType(id)
      .pipe(catchError((err) => this.handleError(err)))
      .subscribe((res) => {
        if(res.isSuccess){
          this.getAllLoanProductTypeList();
          this.toaster.success(res.message);
        }else{
          this.toaster.error(res.errors[0]);
        }  
  });
}
// *********** Loan Product Type API END *********

}
