import { DatePipe } from '@angular/common';
import { Component, OnChanges, EventEmitter, Input, Output, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { NgbCalendar, NgbDate, NgbDateParserFormatter, NgbDateStruct, NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';


export  class SelectedDates {
	startDate: NgbDate;
	endDate: NgbDate;
}
@Component({
  selector: 'app-icon-datepicker',
  templateUrl: './icon-datepicker.component.html',
  styleUrls: ['./icon-datepicker.component.scss'],
  viewProviders: [ DatePipe ],
  encapsulation: ViewEncapsulation.None,
})
export class IconDatepickerComponent implements OnChanges {
  @Input() datepickername;
  @Input() inputPlaceholder:string;
  @Input() inputDate: string = "";
  @Input() inputId:string;
  @Input() showdayIcon:boolean =false;
  @Input() showdateIcon:boolean =false;
  @Output() selectedDates: EventEmitter<SelectedDates> = new EventEmitter<SelectedDates>();
  minDate:any;
  maxDate:any;
  
    hoveredDate: NgbDate | null = null;
  
     fromDate: NgbDate | null;
     toDate: NgbDate | null;
     @Input() formatMMddyyyy:boolean=false;
     @Input() fromDateFromParent;
     @Input() toDateFromParent;
     @Input() datePickerLabel;
     @Input() isRequired;
     @Input() fromEdit:boolean=false;
     editSingleDate :NgbDate | null;
  
     valueFromEditBlockout:any;
  
    @ViewChild("datepicker") dp: NgbInputDatepicker;
    
    constructor(private calendar: NgbCalendar, public formatter: NgbDateParserFormatter,private datePipe: DatePipe,) {
      const current = new Date();
      this.minDate = { year: current.getFullYear() - 500, month: 1, day: 1 }; 
      this.maxDate = { year: current.getFullYear()};
      this.onClearPicker();
    }
  
    getMaxSelectableDate(): NgbDateStruct {
      const currentDate = new Date();
      const threeMonthsLater = new Date(currentDate.getFullYear(), currentDate.getMonth() + 3, currentDate.getDate());
      return {
        year: threeMonthsLater.getFullYear(),
        month: threeMonthsLater.getMonth() + 1, // Months are zero-based, so add 1
        day: threeMonthsLater.getDate()
      };
    }
  
    ngOnInit(): void {

    }

    ngOnChanges(changes: SimpleChanges): void {
      if(changes.fromDateFromParent?.currentValue || changes?.toDateFromParent?.currentValue){
        this.assignToDatePicker()
        }
    }
  

    ngAfterViewInIt(){

    }
  
    onDateSelection(date: NgbDate) {
      if (!this.fromDate && !this.toDate) {
        this.fromDate = date;
        this.editSingleDate = date;
      } 
      else if (this.fromDate && !this.toDate && date && date.after(this.fromDate)) {
        this.toDate = date;
      } 
      else {
        this.toDate = null;
        this.fromDate = date;
      }
      if(this.fromDate){
        let startDate = this.formatter.format(this.fromDate);
        const datefrom = this.datePipe.transform(startDate,this.formatMMddyyyy? 'dd/MM/yyyy':'dd/MM/yyyy');
        this.inputDate = `${datefrom}`;
        if(this.fromEdit){
         this.fromDate = null;
        }
      }
  
      if(this.fromDate && this.toDate){
      let startDate = this.formatter.format(this.fromDate);
        let endDate = this.formatter.format(this.toDate);
      const datefrom = this.datePipe.transform(startDate,this.formatMMddyyyy? 'dd/MM/yyyy':'dd/MM/yyyy');
      const dateTo = this.datePipe.transform(endDate, this.formatMMddyyyy? 'dd/MM/yyyy':'dd/MM/yyyy');
      this.inputDate = `${datefrom} - ${dateTo}`;
      }
      this.selectedDates.emit({startDate: this.editSingleDate,endDate: this.toDate});
    }
  
    isHovered(date: NgbDate) {
      return (
        this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate)
      );
    }
    isInside(date: NgbDate) {
      return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
    }
    isRange(date: NgbDate) {
      return (
        date.equals(this.fromDate) ||
        (this.toDate && date.equals(this.toDate)) ||
        this.isInside(date) ||
        this.isHovered(date)
      );
    }
    isStartDate(date: NgbDate) {
      return date.equals(this.fromDate);
    }
    isEndDate(date: NgbDate) {
      return date.equals(this.toDate);
    }

    onClearPicker() {
      this.inputDate = null;
      this.fromDate = null;
      this.toDate = null;
      this.editSingleDate=null;
      this.selectedDates.emit({
        startDate: this.editSingleDate,
        endDate: this.toDate
      });
     
    }
    onApply() {
      this.selectedDates.emit({
        startDate: this.editSingleDate,
        endDate: this.toDate
      });
      this.dp.close();
    }

    onDatepickerClosed() {
      console.log('Datepicker closed');
    }

    mouseEnter(){
      if(this.fromEdit){
        this.fromDate = null;
        }
    }


        // from parent (edit)start
	assignToDatePicker(){
		if(this.fromDateFromParent){
			const fromDateParse = this.formatter.parse(this.fromDateFromParent);
			const fromNgbDate = new NgbDate(fromDateParse.year, fromDateParse.month, fromDateParse.day);
			this.fromDate=fromNgbDate;
			this.editSingleDate = fromNgbDate;
			const startDate = this.formatter.format(fromNgbDate);
			const datefrom = this.datePipe.transform(startDate, 'MM/dd/yyyy');
			this.inputDate = `${datefrom}`;
		} ;
		 if(this.toDateFromParent){
			const toDateParse = this.formatter.parse(this.toDateFromParent);
			const toNgbDate= new NgbDate(toDateParse.year, toDateParse.month, toDateParse.day);
			this.toDate    = toNgbDate;
			const startDate  = this.formatter.format(this.fromDate);
			const endDate    = this.formatter.format(this.toDate);
			const datefrom = this.datePipe.transform(startDate, 'MM/dd/yyyy');
			const dateTo   = this.datePipe.transform(endDate, 'MM/dd/yyyy');
			this.inputDate = `${datefrom} - ${dateTo}`;
		}
	}
	 // from parent (edit)end



}
