import { Routes } from "@angular/router";

export const content: Routes = [
  {
    path: "dashboard",
    loadChildren: () => import("../../components/dashboard/dashboard.module").then((m) => m.DashboardModule),
  },
  {
    path: "Product",
    loadChildren: () => import("../../components/Product/product.module").then((m) => m.ProductModule),
  },
  {
    path: "Transaction",
    loadChildren: () => import("../../components/Transaction/transaction.module").then((m) => m.TransactionModule),
  },
  {
    path: "Financial-Statement",
    loadChildren: () => import("../../components/financial-statement/financial-statement.module").then((m) => m.FinancialStatementModule),
  },
  {
    path: "Report",
    loadChildren: () => import("../../components/Report/report.module").then((m) => m.ReportModule),
  },
  {
    path: "Inventory",
    loadChildren: () => import("../../components/inventory/inventory.module").then((m) => m.InventoryModule),
  },
  {
    path: "Settings",
    loadChildren: () => import("../../components/Settings/settings.module").then((m) => m.SettingsModule),
  },
  {
    path: "Administration",
    loadChildren: () => import("../../components/administration//administration.module").then((m) => m.AdministrationModule),
  },
  {
    path: "Manufacturer",
    loadChildren: () => import("../../components/manufacturer/manufacturer.module").then((m) => m.ManufacturerModule),
  },
  {   
    path: "add-update-profile",
    loadChildren: () => import("../../components/edit-profile/edit-profile.module").then((m) => m.EditProfileModule),
  },
  {   
    path: "Orders",
    loadChildren: () => import("../../components/orders/orders.module").then((m) => m.OrdersModule),
  },
  {   
    path: "company-list",
    loadChildren: () => import("../../components/company-list/company-list.module").then((m) => m.CompanyListModule),
  },
  {   
    path: "add-company",
    loadChildren: () => import("../../components/add-company/add-company.module").then((m) => m.AddCompanyModule),
  },
];
