export const environment = {
  production: false,
  // baseUrl:'http://103.118.16.132:1999/'
  baseUrl:"https://localhost:52001/",
};
  // baseUrl:"https://devapi.jewelsteps.com/"

//  export const baseUrl = "https://apisalehsallom.jewelsteps.com/";
//  export const baseUrl = "https://localhost:52001/";
//  export const baseUrl = "https://apiClassicJewellers.jewelsteps.com/";
//  export const baseUrl = "http://103.118.16.132:9099/";
//  export const baseUrl = "https://devapi.jewelsteps.com/";
