<span class="d-flex justify-content-center align-content-center images_renderer" *ngIf="!params?.data?.isFooterRow" [ngClass]="params?.data?.expandeble?'h-100':'h-25px'">
    <!-- <app-svg-icon [name]="'jewellery'" class="jewellery_img"></app-svg-icon> -->
    <img 
    (click)="onIconClick()" 
    [src]="displayImage?(isBase64Image(displayImage)):''" 
    class="h-100 w-100 d-flex justify-content-center align-items-center cursorPointer images_renderer" [src]="displayImage?(isBase64Image(displayImage)):''" 
    alt="Img"
    srcset="" 
    onerror="this.src = './assets/svg/no_image_available.svg'">
    <!-- <img *ngIf="isDisplayImageByUrl" (click)="onIconClick()" class="h-100 w-100 d-flex justify-content-center align-items-center cursorPointer" [src]="baseUrl+isDisplayImageByUrl" alt="Img" srcset="" onerror="this.src = '../../../../../assets/images/no-picture-available-icon-12.jpg'"> -->
    <!-- <img *ngIf="!imagesListData?.length" (click)="onIconClick()" class="h-100 w-100 d-flex justify-content-center align-items-center cursorPointer" src="../../../../../assets/images/no-picture-available-icon-12.jpg" alt="Img" srcset="" onerror="this.src = '../../../../../assets/images/no-picture-available-icon-12.jpg'"> -->
</span>