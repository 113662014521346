import { SendDataService } from 'src/app/shared/services/send-data.service';
import { ResponseModel } from '../../../../../shared/models/response-model.model';
import { DataService } from '../../../../../shared/services/data.service';
import { Injectable } from '@angular/core';
import { catchError, Observable, of, switchMap, tap, throwError } from 'rxjs';
import { StorageService } from 'src/app/shared/services/storage.service';
import { StorageName } from 'src/app/shared/enum/common-enum';
import { HttpStatusCode } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class PermissionsService {

  constructor(private service: DataService , public sendDataService : SendDataService , public storageService: StorageService , public toaster: ToastrService) {
   }

  // get preparePermissionMapping
  getAllPermissions(): Observable<ResponseModel> {
    let url = 'api/app/permission/preparepermissionmapping';
    return this.service.get(url).pipe<ResponseModel>(tap((response: any) => {
      return response;
    }));
  }

  // update permissionSave
  SavePermission(body: any): Observable<boolean> {
    let url = 'api/app/permission/permissionsave';
    return this.service.post(url, body).pipe<boolean>(tap((response: any) => {
      return response;
    }));
  }

    // get getPermissionAccessByUser
    getPermissionAccessByUser(): Observable<ResponseModel> {
      let url = 'api/app/permission/getpermissionaccessbyuser';
      return this.service.get(url).pipe<ResponseModel>(tap((response: any) => {
        return response;
      }));
    }


    getrefreshpermissions():Observable<ResponseModel>{
      let url = "api/app/permission/refreshpermissions";
      return this.service.get(url, true).pipe<ResponseModel>(tap((response: any) => {
          return response;
        })
      );
    }

}
