import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, Renderer2, ViewChild, ViewContainerRef } from '@angular/core';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { ICellEditorParams } from 'ag-grid-community';

@Component({
  selector: 'app-ag-grid-custom-input',
  templateUrl: './ag-grid-custom-input.component.html',
  styleUrls: ['./ag-grid-custom-input.component.scss'],
})

export class AgGridCustomInputComponent implements ICellEditorAngularComp, AfterViewInit {

  @ViewChild("el") public el:ElementRef;
  InputValue:any;

  decimalPlaces: number =0; // Default to 0, indicating no decimal places by default
  isOnlyNumbers: boolean = false; // Default to false, indicating no onlyNumbers directive by default
  isOnlyAlphabets: boolean = false; // Default to false, indicating no onlyAlphabets directive by default
  isOnlyAlphabetsAndNumbers: boolean = false; // Default to false, indicating no onlyAlphabetsAndNumbers directive by default

  inputType:string='text';
  inputName:string;
  inputValue:string;
  isRequired:boolean;
  inputMaxlength:any;
  disabled :boolean;
  readOnly: boolean=false;
  maxDate:string;
  minDate:string;  
  public params: ICellEditorParams;

  constructor(private renderer: Renderer2) {}

  agInit(params: ICellEditorParams): void {
    this.params = params;
    if(params?.colDef?.cellEditorParams != undefined){
      this.inputName = params?.colDef?.cellEditorParams?.inputName;
      this.decimalPlaces = params?.colDef?.cellEditorParams?.decimalPlaces;
      this.isOnlyNumbers = params?.colDef?.cellEditorParams?.isOnlyNumbers;
      this.isOnlyAlphabets = params?.colDef?.cellEditorParams?.isOnlyAlphabets;
      this.isOnlyAlphabetsAndNumbers = params?.colDef?.cellEditorParams?.isOnlyAlphabetsAndNumbers;
      this.inputMaxlength = params?.colDef?.cellEditorParams?.inputMaxlength;
    }
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
  }

  getValue() {
    return this.params.value;
  }
  isPopup?(): boolean {
    return false;
  }
  getPopupPosition?(): 'over' | 'under' {
    return 'under';
  }
  isCancelBeforeStart?(): boolean {
    return false;
  }
  isCancelAfterEnd?(): boolean {
    // this.onBlurInput(this.params.value??'');
    return false;
  }
  focusIn?(): void {
  }
  focusOut?(): void {
  }
  afterGuiAttached?(): void {
    // this.onBlurInput(this.params.value??'');
    this.el.nativeElement.focus();
    setTimeout(() => {
      this.renderer.selectRootElement(this.el.nativeElement).select(); // Select the text in the input
    });
    this.getValue();
  }

  setKeyPressValidation(e){
    if(this.decimalPlaces > 0){
      const keyCode = e.keyCode || e.which;
      const inputChar = String.fromCharCode(keyCode);
    
      if (keyCode === 8) {
        // Allow backspace
        return true;
      } else if (keyCode >= 48 && keyCode <= 57) {
        // Allow numbers (0-9)
        return true;
      } else if (inputChar === "." && e.target.value.indexOf(".") === -1) {
        // Allow a single decimal point if it doesn't already exist
        return true;
      } else {
        // Prevent any other characters from being entered
        e.preventDefault();
        return false;
      }
    }
    else if(this.isOnlyNumbers && this.decimalPlaces == 0){
      document.all ? e.keyCode : e.keyCode;
      return (e.keyCode == 8 || (e.keyCode >= 48 && e.keyCode <= 57));
    }
    else if(this.isOnlyAlphabetsAndNumbers){
      const inputValue = this.params?.value;
      this.params.value = this.params?.value?.replace(/[^a-zA-Z0-9 ]/g, '');
    }
    else if(this.isOnlyAlphabets){
      document.all ? e.keyCode : e.keyCode;
      return (e.keyCode === 8 || (e.keyCode >= 97 && e.keyCode <= 122) || (e.keyCode >= 65 && e.keyCode <= 90 || e.keyCode==32));
    }

  }

  // onBlurInput(value){
  //   if(this.readOnly ==false){
  //     if (value.toString().trim() === '' && this.decimalPlaces == 2) {
  //       this.params.value = '0.00';
  //     }
  //     else if (value.toString().trim() === '' && this.decimalPlaces == 3) {
  //       this.params.value = '0.000';
  //     }
  //     else {
  //       const parsedValue = parseFloat(value);
  //       if (!isNaN(parsedValue)) {
  //         const formattedValue = this.formatWithDecimalPlaces(parsedValue, this.decimalPlaces);
  //         this.params.value = formattedValue;
  //       } else {
  //         // Handle invalid input, e.g., reset it to '0.00' or any desired default
  //         this.params.value = '0.00';
  //       }
  //     }
  //   }
  // }

  formatWithDecimalPlaces(number: number, decimalPlaces: number): string {
    return number.toFixed(decimalPlaces);
  }
}
