<div class="d-flex align-items-center w-100 h-100" >
  <input
  #el
  autocomplete="off"
  [maxlength]="inputMaxlength"
  [(ngModel)]="params.value"
  [required]="isRequired"
  [disabled]="disabled"
  [readOnly]="readOnly"

  (keypress)="setKeyPressValidation($event)"

  >
</div>


