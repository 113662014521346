
export class UpdateCurrentRate {
}
export class InsertUpdateNewRateModel {
    data: InsertUpdateNewRateRequestModel[];
  }
  export class InsertUpdateNewRateRequestModel {
    id          ?: number;
    metalId     ?: number;
    caratId     ?: number;
    currentRate ?: number;
    newRate     ?: number;
    caratName   ?: string;
    metalExchangeRate?: number;
    active      ?: boolean = true;
  }
  

