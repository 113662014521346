<ng-container >
    <div class="modal-body p-2">
        <div class="d-flex justify-content-end"><app-svg-icon [name]="'cross'" tabindex="0" class="cross-button custom_focus cursorPointer" (click)="activeModal.dismiss()"></app-svg-icon></div>
        <div class="save_icon text-center d-flex justify-content-center align-items-center">
            <svg xmlns="http://www.w3.org/2000/svg" width="105.437" height="110.837" viewBox="0 0 105.437 110.837">
                <g id="Group_40875" data-name="Group 40875" transform="translate(-641.831 -231.219)">
                  <path id="Path_2958" data-name="Path 2958" d="M719.018,434.193a37.439,37.439,0,0,1-3.666-1.077c-2.945-1.139-5.855-2.373-8.76-3.613-1.558-.666-1.675-1.456-.381-2.588a51.92,51.92,0,0,0,10.565-12.484c1.561-2.616.27-5.538-2.6-5.841a3.878,3.878,0,0,0-3.883,2.2,41.946,41.946,0,0,1-9.761,11.2c-4.126,3.31-2.759,3.021-6.962.5-2.788-1.672-5.462-3.538-8.148-5.374-.431-.295-.977-.941-.906-1.32a2.046,2.046,0,0,1,1.18-1.248,26.08,26.08,0,0,0,7.776-4.6c1.809-1.533,2.133-3.605.833-5.266a3.709,3.709,0,0,0-5.427-.509c-3.666,3.235-8.119,4.411-12.742,5.186a2.287,2.287,0,0,1-1.674-.53q-4.522-4.1-8.932-8.318a6.019,6.019,0,0,1-1.748-6.571,6.612,6.612,0,0,1,5.337-4.687,50.924,50.924,0,0,0,25.836-12.877,1.889,1.889,0,0,1,2.519-.316q21.824,11.478,43.688,22.88a1.557,1.557,0,0,1,.9,2.275,151.3,151.3,0,0,1-14.7,28.675C725.538,432.667,723.006,434.141,719.018,434.193Z" transform="translate(-13.779 -92.138)" fill="#1492e6"/>
                  <path id="Path_2959" data-name="Path 2959" d="M815.892,231.219c10.016,0,16.181,9.412,12.184,17.612-4.237,8.69-8.77,17.242-13.41,25.724a4.076,4.076,0,0,0-.124,4.59,19.124,19.124,0,0,1,1.264,14.88c-.47,1.555-1.049,3.078-1.6,4.609-.568,1.578-1,1.74-2.5.954q-12.9-6.747-25.8-13.5-7.018-3.674-14.037-7.347c-1.7-.888-1.774-1.382-.493-2.852,2.346-2.695,4.628-5.444,7.735-7.347a22.442,22.442,0,0,1,11.259-3.448,2.027,2.027,0,0,0,2.053-1.283c4.34-8.491,8.732-16.955,13.107-25.428C807.9,233.8,811.65,231.4,815.892,231.219Z" transform="translate(-82.041)" fill="#0c71b4"/>
                  <path id="Path_2960" data-name="Path 2960" d="M693.14,323.25a5.384,5.384,0,1,1-10.767.022,5.457,5.457,0,0,1,5.453-5.426A5.4,5.4,0,0,1,693.14,323.25Z" transform="translate(-25.85 -55.234)" fill="#1492e6"/>
                  <path id="Path_2961" data-name="Path 2961" d="M647.2,374.51a5.387,5.387,0,1,1,5.426-5.406A5.4,5.4,0,0,1,647.2,374.51Z" transform="translate(0 -84.494)" fill="#1492e6"/>
                </g>
              </svg>
        </div>
        <div>
            <p class="title text-gray-color font-semibold f-16 m-0 text-center">New {{titleShow?'Catalog':'Invoice'}} </p>
            <p class="sub-info font-regular text-lighter-gray d-flex align-items-center f-14 mb-2 justify-content-center text-center">Are you sure want to clear data?</p>
        </div>
    </div>
    <div class="modal-footer border-0">
        <div class="col d-flex align-items-center justify-content-center cursorPointer m-0" (click)="onSave()"><span (keydown.enter)="onKeyDown($event)" tabindex="0" class="font-semibold text-color-danger px-2 custom_focus f-16">Yes</span></div>
        <div class="col d-flex align-items-center justify-content-center cursorPointer m-0" (click)="activeModal.close(false)"><span tabindex="0" (keydown.enter)="onKeyDown($event)" class="text-gray-color px-2 font-semibold custom_focus f-16">No</span></div>
    </div>
</ng-container>