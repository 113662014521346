import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ExcelConverterServiceService {
  downloadXlsx(excelFileUrl) {
    const excelurl = environment.baseUrl + excelFileUrl
    const splitArray = excelFileUrl.split('/');
        const fileName = splitArray[splitArray.length - 1];
        const element = document.createElement('a');
        element.setAttribute('id', new Date().toString());
        element.setAttribute('href', excelurl);
        // element.setAttribute('target', '_blank');
        element.setAttribute('download', fileName);
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
  }

}