import { Component, Input, Renderer2, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { User } from 'src/app/auth/auth/auth.model';
import { AgGridService } from 'src/app/shared/common-components/ag-grid/ag-grid.service';
import { RangeDatepickerComponent } from 'src/app/shared/common-components/range-datepicker/range-datepicker.component';
import { CommonService } from 'src/app/shared/services/common.service';
import { StorageService } from 'src/app/shared/services/storage.service';
import { CommonFilterService } from 'src/app/shared/services/common-filter.service';

@Component({
  selector: 'app-account-ledger-advanced-filter',
  templateUrl: './advanced-filter-popup.component.html',
  styleUrls: ['./advanced-filter-popup.component.scss']
})

export class AccountLedgerAdvancedFilterComponent{
  @Input() data : any = {};
  @ViewChild(RangeDatepickerComponent) rangeDatepickerComponent: RangeDatepickerComponent
  selectedBranchIds             = [];
  selectedGroupIds              = [];
  selectedLedgerIds = [];
  advanceFilterRequest :any = {}

  constructor
  (
  public activeModal    : NgbActiveModal,
  public renderer       : Renderer2,
  public agGridService       : AgGridService,
  public storageService       : StorageService,
  public commonFilterService       : CommonFilterService,
  
  ){
  }

  ngOnInit(): void {
    this.setAlreadyAppliedData();
   }


  setAlreadyAppliedData(){
    var previousAppliedFilters = this.storageService.retrieve(this.data.agGridDataModel.filterStorageName);
    if(previousAppliedFilters != undefined)
    {
      this.selectedBranchIds = this.commonFilterService.getPreviousAppliedFilter(previousAppliedFilters,"branchId",this.data.getAllDropdownModel.branchDropdown,"multiselectDropdown");
      this.advanceFilterRequest.selectedBranches = this.selectedBranchIds?.map((x) => x.id);
      
      this.selectedGroupIds = this.commonFilterService.getPreviousAppliedFilter(previousAppliedFilters,"accountgroupid",this.data.getAllDropdownModel.accountGroupDropdown,"multiselectDropdown");
      this.advanceFilterRequest.selectedGroups = this.selectedGroupIds?.map((x) => x.id);

      this.advanceFilterRequest.crordrid = this.commonFilterService.getPreviousAppliedFilter(previousAppliedFilters,"crordrid",undefined,"dropdown");
      this.advanceFilterRequest.accountledgerName = this.commonFilterService.getPreviousAppliedFilter(previousAppliedFilters,"accountledgername",undefined,"input");
      this.advanceFilterRequest.openingBalance = this.commonFilterService.getPreviousAppliedFilter(previousAppliedFilters,"openingBalance",undefined,"input");
      this.advanceFilterRequest.contact = this.commonFilterService.getPreviousAppliedFilter(previousAppliedFilters,"contact",undefined,"input");
    
      this.selectedLedgerIds = this.commonFilterService.getPreviousAppliedFilter(previousAppliedFilters,"accountLedgerId",this.data.getAllDropdownModel.allAccountLedgerDropdown,"multiselectDropdown");
      this.advanceFilterRequest.selectedLedgerIds = this.selectedLedgerIds?.map((x) => x.id);

      this.advanceFilterRequest.fromDate =  this.commonFilterService.getPreviousAppliedFilter(previousAppliedFilters,"fromDate",undefined,"input");
      this.advanceFilterRequest.toDate = this.commonFilterService.getPreviousAppliedFilter(previousAppliedFilters,"toDate",undefined,"input");
      this.rangeDatepickerComponent.onApply(false, this.advanceFilterRequest.fromDate, this.advanceFilterRequest.toDate);
    }
  }



  onClickAdvancedFilter() {
    this.storageService.removeItem(this.data.agGridDataModel.filterStorageName);
      this.commonFilterService.performFilterColumnwise('accountgroupid', this.advanceFilterRequest.selectedGroups, undefined, this.data.agGridDataModel.filterStorageName , '');
    
      this.commonFilterService.performFilterColumnwise('branchId', this.advanceFilterRequest.selectedBranches, undefined, this.data.agGridDataModel.filterStorageName , '');
    
      this.commonFilterService.performFilterColumnwise('accountLedgerId', this.advanceFilterRequest.selectedLedgerIds, undefined, this.data.agGridDataModel.filterStorageName , '');

    if(this.advanceFilterRequest?.openingBalance != undefined)
      this.commonFilterService.performFilterColumnwise('openingBalance', undefined, this.advanceFilterRequest?.openingBalance, this.data.agGridDataModel.filterStorageName , 'textFilter');
    
    if(this.advanceFilterRequest?.contact != undefined)
      this.commonFilterService.performFilterColumnwise('contact', undefined, this.advanceFilterRequest?.contact, this.data.agGridDataModel.filterStorageName , 'textFilter');
    
    if(this.advanceFilterRequest.crordrid != undefined)
      this.commonFilterService.performFilterColumnwise('crordrid', undefined, this.advanceFilterRequest?.crordrid, this.data.agGridDataModel.filterStorageName , 'textFilter');

    if(this.advanceFilterRequest?.fromDate != undefined)
      this.commonFilterService.performFilterColumnwise('fromDate', undefined, this.advanceFilterRequest?.fromDate, this.data.agGridDataModel.filterStorageName , 'dateFilter');

    if(this.advanceFilterRequest?.toDate != undefined)
      this.commonFilterService.performFilterColumnwise('toDate', undefined, this.advanceFilterRequest?.toDate, this.data.agGridDataModel.filterStorageName , 'dateFilter');

    var filterData = this.commonFilterService.processFilterData(this.data.agGridDataModel.filterStorageName)

    this.advanceFilterRequest.filters   = filterData;
    this.activeModal.close(this.advanceFilterRequest)
  }

  clearFields() {
    this.advanceFilterRequest = {}
    this.selectedBranchIds = [];
    this.selectedGroupIds = [];
    this.selectedLedgerIds = [];
    this.rangeDatepickerComponent?.onToday();
  }

}




