
<div class="row py-1">
    <div class="col-12 d-flex justify-content-between">
        <div class="col gap-2 d-flex align-items-center me-2"  *ngIf="metalSectionModel.isBarcodeInput">
                <div class="form-group d-flex gap-2" [ngClass]="'col-12'">
                    <label
                        class="d-flex align-items-center f-14 font-semibold text-gray-color text-nowrap mb-0">Barcode</label>
                    <div class="input-group w-100" >
                        <input class="form-control f-12 h-100 align-bar" id="barcode" type="text" placeholder=""  [(ngModel)]="metalSectionModel.barcodeNo" (keydown.enter)="getProductDataByBarcodeNo(metalSectionModel.barcodeNo)" aria-label="Barcode">
                        <div class="input-group-append ">
                            <span class="input-group-text px-2 cursorPointer  ">
                                <img src="./assets/images/svg-icon/barcode_icon.svg" alt="">
                            </span>
                        </div>
                    </div>
                    <div class="d-flex align-items-center cursorPointer" *ngIf="formName==storage.FORMNAME_TRANSACTION_SALESINVOICE">
                        <app-svg-icon name="fromDept"></app-svg-icon>
                    </div>
                </div>
        </div>
        <div class="w-220px ms-2 d-flex align-items-center justify-content-end">
            <div class="d-flex gap-3">
                <div class="d-flex align-items-center" *ngIf="metalSectionModel.isInternal_Fix">
                    <app-checkbox  (check)="vatChecked($event)" [status]="formModel?.invoiceModel?.vat" [disabled]="false"></app-checkbox>
                    <span class="text-primary f-12 font-semibold ps-2 cursorPointer" (click)="formModel.invoiceModel.vat = !formModel.invoiceModel.vat; vatChecked(formModel.invoiceModel.vat)">Vat</span>
                </div>
                <div class="d-flex align-items-center" *ngIf="metalSectionModel.isInternal_Fix">
                    <app-checkbox  (check)="metalFixChecked($event)" [status]="formModel?.invoiceModel?.metalFix" [disabled]="false"></app-checkbox>
                    <span class="text-primary f-12 font-semibold ps-2 cursorPointer" (click)="formModel.invoiceModel.metalFix = !formModel.invoiceModel.metalFix; metalFixChecked(formModel.invoiceModel.metalFix)">Metal Unfix</span>
                </div>
                <div class="d-flex align-items-center" *ngIf="metalSectionModel.isInternal_Fix">
                    <app-checkbox (check)="internalFixChecked($event)" [status]="formModel?.invoiceModel?.internalFix" [disabled]="false"></app-checkbox>
                    <span class="text-primary f-12 font-semibold ps-2 cursorPointer" (click)="formModel.invoiceModel.internalFix = !formModel.invoiceModel.internalFix; internalFixChecked(formModel.invoiceModel.internalFix)">UnFix</span>
                </div>
                <div class="d-flex align-items-center" *ngIf="metalSectionModel.isAML">
                    <app-checkbox (check)="AMLChecked($event)" [status]="formModel?.invoiceModel?.isAML" [disabled]="false"></app-checkbox>
                    <span class="text-primary f-12 font-semibold ps-2 cursorPointer" (click)="formModel.invoiceModel.isAML = !formModel.invoiceModel.isAML; AMLChecked(formModel.invoiceModel.isAML)">AML</span>
                </div>
                <div class="d-flex align-items-center" *ngIf="metalSectionModel.isKYC">
                    <app-checkbox (check)="KYCChecked($event)" [status]="formModel?.invoiceModel?.isKYC" [disabled]="false"></app-checkbox>
                    <span class="text-primary f-12 font-semibold ps-2 cursorPointer" (click)="formModel.invoiceModel.isKYC = !formModel.invoiceModel.isKYC; KYCChecked(formModel.invoiceModel.isKYC)">KYC</span>
                </div>
            </div>
        </div>
    </div>
</div>
<button class="dashed-button" (click)="openMetalSectionPopup(undefined)">
    <i class="fa fa-box"></i> Add Item (Shift + Q)
</button>
          
