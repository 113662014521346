<div class="mb-0 d-flex align-items-center commonCheckBox">
    <div class="d-flex align-items-center gap-2 {{departmentValue ? 'justify-content-start' : 'justify-content-center'}} ">
        <input 
        [class]="'form-check-input my-auto cursorPointer '+ checkBoxClass"
        type="checkbox"
        role="checkbox"
        [(ngModel)]="status"
        [disabled]="disabled"
        (click)="check.emit($event.target.checked)"
        id="{{dyanamicId?dyanamicId:lableName}}"
        [required]="isRequired">
        <label [class]="'form-check-label mb-0 cursorPointer '+ (departmentValue ? 'px-2' : '') + checkBoxLabelClass" *ngIf="lableName"  for="{{dyanamicId?dyanamicId:lableName}}" >
            {{lableName}}
        </label>
    </div>
</div>
