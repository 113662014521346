<div *ngIf="metalSectionModel.showMetalSelectionTabs" class="row py-1">
    <div class="col-12 d-flex justify-content-between">
        <div class="" [ngClass]="'col-12'">
            <app-nav-tabs [tabItems]="tabItems" (tabId)="selectTab($event)"></app-nav-tabs>
        </div>
    </div>
</div>
<div class="flex-fill content_tab">
    <div class="d-flex flex-column h-100" container="body">
        <div class="" id="Sales_Invoice position-relative">
            <div class=""*ngFor="let item of tabGroupList">
                <app-ag-grid 
                        [ngClass]="tabId == item.tabId ? 'd-block': 'd-none'"
                        [tableId]="item.dataModel.storageName"
                        [class]="'all_aggrid_tables position-relative d-block '+ gridDynamicHeightClass" 
                        [dataModel]="item.dataModel"
                        [gridOptions]="gridOptions"
                        [navigateGridOption]="gridOptionsPayment"
                        (onDeleteIconClick) = "deleteIconClick($event)"
                        (onEditIconClick) = "editIconClick($event)"
                        [columnSettings]="item.dataModel.columnSettings"
                        (gridReady)="onGridReady(item.tabIndex, $event)"
                        >
                </app-ag-grid>
            </div>
        </div>
    </div>
</div>