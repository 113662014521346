<!-- <div id="chart">
    <apx-chart
      [series]="chartOptions.series"
      [chart]="chartOptions.chart"
      [plotOptions]="chartOptions.plotOptions"
      [labels]="chartOptions.labels"
      [legend]="chartOptions.legend" 
      [colors]="chartOptions.colors"
      [stroke]="chartOptions.stroke"
    ></apx-chart>, 'width': 'calc(100% - 51.5%)'
  </div> -->
  <div 
     [ngStyle]="{'background-color': backGroundColor ? backGroundColor : 
                chartOptions?.title?.text === 'BlueBar' ? '#F1EFFF' :
                chartOptions?.title?.text === 'OrangeBar' ? ' #FFF6E6' :
                chartOptions?.title?.text === 'GreenBar' ? '#EEF8ED ' :
                chartOptions?.title?.text === 'RedBar' ? '#FFEFF1' : ''

      }"
     class="card mb-0  border-0" 
     >
    <div class="card-body p-2 gap-2 position-relative" >
      <div class="d-flex gap-2 widget-content">
        <div id="chart">
          <apx-chart
            [series]="chartOptions.series"
            [chart]="chartOptions.chart"
            [plotOptions]="chartOptions.plotOptions"
            [labels]="chartOptions.labels"
            [legend]="chartOptions.legend" 
            [colors]="chartOptions.colors"
            [stroke]="chartOptions.stroke"
          ></apx-chart>
        </div>       
         <div class="d-flex align-items-center">
          <div>
            <P class="m-0 f-18 font-bold "[ngStyle]="{'color': amountColor}">{{ammount? ammount : '0'}}</P>
            <p class="font-regular f-13 text-gray-color mb-0">{{totalCustomer? totalCustomer : 'NA'}}</p>
          </div>
        </div>
      </div>
      <div
        class="d-flex align-items-end position-absolute end-0 bottom-0 p-3" >
        <div class="d-flex align-items-center gap-1">
          <svg
           xmlns="http://www.w3.org/2000/svg" width="20" height="10" viewBox="0 0 22.986 15.317">
            <path id="Path_1408" data-name="Path 1408"
              d="M25.976,7.276a1,1,0,0,0,0-.268.817.817,0,0,0-.064-.217,1.4,1.4,0,0,0-.115-.179.957.957,0,0,0-.179-.217l-.153-.089a.881.881,0,0,0-.243-.128h-.255A.893.893,0,0,0,24.7,6H18.317a1.276,1.276,0,1,0,0,2.553h3.612l-5.106,6.012-5.514-3.28a1.276,1.276,0,0,0-1.634.281L3.294,19.223A1.278,1.278,0,0,0,5.26,20.857l5.68-6.816,5.45,3.268a1.276,1.276,0,0,0,1.621-.268l5.412-6.318v2.936a1.276,1.276,0,0,0,2.553,0Z"
              transform="translate(-2.998 -6)" 
              [ngStyle]="{'fill': color? color : 
                chartOptions?.title?.text === 'BlueBar' ? '#4F39CD' :
                chartOptions?.title?.text === 'OrangeBar' ? '#FCA100' :
                chartOptions?.title?.text === 'GreenBar' ? '#56B552' :
                chartOptions?.title?.text === 'RedBar' ? '#f1242e' : ''
               }"
               />
          </svg>
          <span class="f-10" 
          [ngStyle]="{'color': color? color : 
                chartOptions?.title?.text === 'BlueBar' ? '#4F39CD' :
                chartOptions?.title?.text === 'OrangeBar' ? '#FCA100' :
                chartOptions?.title?.text === 'GreenBar' ? '#56B552' :
                chartOptions?.title?.text === 'RedBar' ? '#f1242e' : ''
           }"
          >{{rate}}</span>
        </div>
      </div>
    </div>
  </div>
